import { Box, Typography, Button, Divider } from '@mui/material';

export type ProdEnv = 'personal' | 'hipaa';

const prodEnvs: { key: ProdEnv; title: string; description: string }[] = [
  {
    key: 'personal',
    title: 'App Store',
    description:
      "The primary user's app was downloaded and paid via the App Store.",
  },
  {
    key: 'hipaa',
    title: 'Pre-configured device',
    description:
      'The primary user recieved a pre-configured device from BoundaryCare.',
  },
];

interface EnvChooseProps {
  onChoose: (value: ProdEnv) => void;
  onCancel?: () => void;
  registrationPage?: boolean;
}

const EnvChoose = ({ onChoose }: EnvChooseProps) => {
  return (
    <Box
      maxHeight="100dvh"
      height="100%"
      width="100%"
      px={2}
      display="flex"
      flexDirection="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
    >
      <Typography color="primary">
        Help us identify your account type:
      </Typography>
      {prodEnvs.map((prodEnv) => (
        <Box
          key={prodEnv.key}
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          width="100%"
          maxWidth={({ spacing }) => spacing(56)}
        >
          <Divider flexItem />
          <Button
            fullWidth
            size="large"
            onClick={() => onChoose(prodEnv.key)}
            color={prodEnv.key === 'personal' ? 'secondary' : 'primary'}
          >
            {prodEnv.title}
          </Button>
          <Typography color="primary" textAlign="center">
            {prodEnv.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default EnvChoose;
