import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const fallDetection: IconifyJSON = {
  prefix: 'fall-detection',
  icons: {
    detected: {
      ...defaultSizes,
      body: '<path d="M14.6499 13.71L11.6499 14.91L18.0899 20.43L17.3599 21.83L8.92993 15L6.14993 9.99998L4.99993 12.22L7.15993 14.75L5.99993 15.73L3.17993 12.45L5.46993 7.84998C5.57286 7.62939 5.71519 7.42945 5.88993 7.25998C6.05249 7.10099 6.24213 6.9723 6.44993 6.87998C6.65179 6.77707 6.87354 6.71908 7.09993 6.70998C7.33264 6.70202 7.56522 6.72898 7.78993 6.78998L9.28993 7.28998C10.1606 7.58651 11.0878 7.67889 11.9999 7.55998C12.8896 7.45687 13.7338 7.11091 14.4399 6.55998L15.4399 7.69998C14.5933 8.36882 13.593 8.81567 12.5299 8.99998C11.5008 9.18154 10.4452 9.14749 9.42993 8.89998L11.6699 12.9H11.7299L14.5999 12L20.8199 14.26L20.1199 15.59L14.6499 13.71ZM7.28993 4.17998C7.31255 3.93684 7.28518 3.69165 7.20951 3.45948C7.13385 3.22732 7.01148 3.01309 6.84993 2.82998C6.69564 2.64109 6.50419 2.48593 6.28744 2.37411C6.07069 2.2623 5.83328 2.19622 5.58993 2.17998C5.10011 2.14392 4.61576 2.30201 4.24154 2.6201C3.86731 2.9382 3.63326 3.39074 3.58993 3.87998C3.55387 4.3698 3.71197 4.85414 4.03006 5.22837C4.34815 5.60259 4.8007 5.83665 5.28993 5.87998C5.53317 5.90442 5.77884 5.87791 6.01127 5.80216C6.2437 5.7264 6.45781 5.60305 6.63993 5.43998C6.82882 5.28569 6.98398 5.09423 7.09579 4.87749C7.20761 4.66074 7.27369 4.42332 7.28993 4.17998Z" fill="currentColor"/>',
    },
    ok: {
      ...defaultSizes,
      body: '<path d="M12 5.89995C11.4833 5.89995 11.046 5.72062 10.688 5.36195C10.3293 5.00395 10.15 4.56662 10.15 4.04995C10.15 3.54995 10.3293 3.11662 10.688 2.74995C11.046 2.38328 11.4833 2.19995 12 2.19995C12.5167 2.19995 12.954 2.38328 13.312 2.74995C13.6707 3.11662 13.85 3.54995 13.85 4.04995C13.85 4.56662 13.6707 5.00395 13.312 5.36195C12.954 5.72062 12.5167 5.89995 12 5.89995ZM9.4 21.75V8.69995C8.38333 8.61662 7.371 8.49995 6.363 8.34995C5.35433 8.19995 4.4 8.00828 3.5 7.77495L3.875 6.27495C5.14167 6.60828 6.46267 6.84562 7.838 6.98695C9.21267 7.12895 10.6 7.19995 12 7.19995C13.4 7.19995 14.7877 7.12895 16.163 6.98695C17.5377 6.84562 18.8583 6.60828 20.125 6.27495L20.5 7.77495C19.6 8.00828 18.646 8.19995 17.638 8.34995C16.6293 8.49995 15.6167 8.61662 14.6 8.69995V21.75H13.1V15.625H10.9V21.75H9.4Z" fill="currentColor"/>',
    },
  },
};
