import { Icon } from '@iconify/react';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { trash } from 'assets/iconify';
import { BasicModal } from 'components/BasicModal';
import { CompletionMessage } from 'components/CompletionMessage';
import { Mfa } from 'components/Mfa';
import { useDeletePhoneNumber } from 'pages/CaregiverSettings/hooks/use-delete-phone-number';

type Props = {
  id: number;
};

const DeleteButton = ({ id }: Props) => {
  const {
    handleDeletePhoneNumber,
    isLoading,
    handleFinishDeleteProcess,
    isConfirmationOpen,
    onConfirmationClose,
    isDeleting,
    wasDeleted,
    wasNotDeleted,
  } = useDeletePhoneNumber(id);

  const isMfaVisible =
    !isDeleting && !wasDeleted && !wasNotDeleted && !isLoading;
  const isLoadingVisible = isDeleting || isLoading;
  const isSuccessMessageVisible = wasDeleted || wasNotDeleted;

  return (
    <>
      <IconButton
        color="error"
        disabled={isLoading}
        sx={{ p: 0.5 }}
        onClick={handleDeletePhoneNumber}
      >
        <Icon icon={trash.icons.normal} />
      </IconButton>
      {isConfirmationOpen && (
        <BasicModal
          hideCloseButton={isSuccessMessageVisible || isLoadingVisible}
          open={isConfirmationOpen}
          onClose={() => onConfirmationClose()}
        >
          {isMfaVisible && (
            <Mfa ignoreMfaFlag onCompletion={handleFinishDeleteProcess} />
          )}
          {isLoadingVisible && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography textAlign="center" variant="h5">
                Verifying...
              </Typography>
              <Typography
                textAlign="center"
                color="blue.main"
                variant="body2"
                maxWidth="400px"
                pb={4}
                px={1}
              >
                This could take a while. Please wait until your information will
                be verified.
              </Typography>
              <CircularProgress
                sx={{
                  p: 1,
                  pb: 4,
                }}
                size={72}
              />
            </Box>
          )}
          {isSuccessMessageVisible && (
            <CompletionMessage
              sx={{ p: 0 }}
              onButtonClick={() => onConfirmationClose(true)}
              status={wasDeleted ? 'success' : 'error'}
              title={
                wasDeleted
                  ? 'Phone number deleted successfully!'
                  : 'Something went wrong...'
              }
              subtitle={
                wasDeleted ? (
                  <>
                    Without a phone number you can’t be added as a profile’s
                    emergency contact or choose to receive your{' '}
                    <strong>BoundaryCare</strong> alerts as text messages or
                    automated calls.
                  </>
                ) : (
                  'An unkown error occurred. Please try again later. If the problem persists, please contact support team.'
                )
              }
              buttonLabel={wasDeleted ? 'Continue' : 'Close'}
            />
          )}
        </BasicModal>
      )}
    </>
  );
};

export default DeleteButton;
