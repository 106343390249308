import { Icon } from '@iconify/react';
import { SxProps, Theme, Tooltip, TooltipProps, styled } from '@mui/material';
import { help } from 'assets/iconify';
import { ReactNode } from 'react';

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  minWidth: theme.spacing(3),
  minHeight: theme.spacing(3),
  color: theme.palette.blue.light,
}));

interface Props {
  message: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  tooltipSx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}

const HelpIcon = ({
  message,
  tooltipPlacement = 'top',
  tooltipSx = {},
  iconSx = {},
}: Props) => {
  return (
    <Tooltip
      PopperProps={{ sx: tooltipSx }}
      title={message}
      placement={tooltipPlacement}
      enterTouchDelay={0}
    >
      <StyledIcon
        sx={iconSx}
        icon={help.icons.normal}
        onClick={(e) => e.stopPropagation()}
      />
    </Tooltip>
  );
};

export default HelpIcon;
