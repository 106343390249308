import { Skeleton } from '@mui/material';
import { RecipientSetModal } from 'components/_extra';
import { useEditRecipientsSetModal } from 'pages/AlerterDetails/hooks/use-edit-recipients-set-modal';
import { GridWrapper } from 'components/_extra/generic';
import { EmptyList } from 'components/_extra/EmptyList';
import { reduceCapacity } from 'assets/iconify';
import CreateRecipientSetButton from 'pages/Alerters/components/RecipientSets/CreateRecipientSetButton';
import Card from 'pages/Alerters/components/RecipientSets/Card';
import { useDeleteRecipientManager } from 'hooks/use-delete-recipient-manager';
import { useCopyRecipientManager } from 'hooks/use-copy-recipient-manager';
import { useRecipientSets } from 'hooks/use-recipient-sets';

const SharedRecipientSets = () => {
  const {
    selectedIndex,
    isRecipientManagerLoading,
    isOpen,
    onClose,
    functionRef,
    handleClick: handleEdit,
  } = useEditRecipientsSetModal(true);
  const { sets, isLoading } = useRecipientSets(true);
  const deleteRecipientManager = useDeleteRecipientManager(true);
  const { handleCopy } = useCopyRecipientManager(true);

  return (
    <GridWrapper
      sx={{
        p: { newLg: 1 },
        gap: 3,
        gridTemplateColumns: sets.length === 0 ? '100%' : undefined,
      }}
    >
      {isLoading ? (
        <>
          <Skeleton variant="rounded" height={132} />
          <Skeleton variant="rounded" height={132} />
        </>
      ) : sets.length === 0 ? (
        <EmptyList
          title="Start adding recipient sets"
          icon={reduceCapacity.icons.normal}
          message={
            <>
              Use recipient sets to group the people who will receive
              notifications from <strong>BoundaryCare</strong> reminders, to set
              schedules and shifts, and to develop hierarchical systems. Create
              a few recipient sets and mix and match them with reminders as
              needed.
            </>
          }
        >
          <CreateRecipientSetButton />
        </EmptyList>
      ) : (
        sets.map((set, index) => (
          <Card
            key={set.id}
            title={set.name ?? 'Untitled'}
            onEdit={() => handleEdit(index)}
            alerters={set.alerters.map(({ name }) => name ?? 'Untitled')}
            recipientsCount={set.recipientsCount ?? 0}
            patientsCount={set.patientsCount ?? 0}
            defaultValues={set}
            onCopy={() => handleCopy(set.id)}
            onRemove={() =>
              deleteRecipientManager(
                set.id,
                set.name ?? 'Untitled',
                set.alerters.map(({ name }) => name ?? 'Untitled'),
              )
            }
            isReminder
            id={set.id}
          />
        ))
      )}
      {selectedIndex !== null && sets[selectedIndex] && (
        <RecipientSetModal
          isOpen={isOpen}
          hideCopy
          withScheduler={false}
          defaultValues={sets[selectedIndex]}
          onClose={onClose}
          submitFormRef={functionRef}
          selectedIndex={selectedIndex}
          isLoading={isRecipientManagerLoading}
        />
      )}
    </GridWrapper>
  );
};

export default SharedRecipientSets;
