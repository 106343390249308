import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import { BasicModal } from 'components/BasicModal';
import { useEffect, useState } from 'react';
import AccountNameSet from './AccountNameSet';
import { useAppSelector } from 'store';
import { useGetProfileQuery } from 'store/api/profiles.api';
import AddCaregiver from './AddCaregiver';

const AddCaregiverButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [shouldAskForName, setShouldAskForName] = useState(false);

  const accountId = useAppSelector((store) => store.user.accountId as number);

  const { data: accountData } = useGetProfileQuery(
    { id: accountId },
    { skip: !accountId },
  );

  useEffect(() => {
    if (accountData && !accountData.result.first_name) {
      setShouldAskForName(true);
    }
  }, [accountData]);

  const onNameSetSuccess = () => {
    setShouldAskForName(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          pr: {
            newLg: 1,
          },
          pt: {
            xxs: 1,
            sm: 2,
          },
        }}
      >
        <Button
          startIcon={<Icon icon="material-symbols:add-box-outline" />}
          onClick={() => setModalOpen(true)}
          sx={{
            flexGrow: {
              xxs: 1,
              sm: 'unset',
            },
          }}
        >
          Add Caregiver
        </Button>
      </Box>
      <BasicModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        disableEscapeKeyDown
        minHeight="200px"
      >
        {shouldAskForName ? (
          <AccountNameSet accountId={accountId} onSuccess={onNameSetSuccess} />
        ) : (
          <AddCaregiver onFinish={() => setModalOpen(false)} />
        )}
      </BasicModal>
    </>
  );
};

export default AddCaregiverButton;
