import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import theme from './styles/theme';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

import './index.scss';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import env from './env';
import { StyledToastContainer } from 'styles/ToastContainer';
import { ConfirmationServiceProvider } from 'components/_extra/ConfirmationDialog/ConfirmationService';
import isAppleDevice from 'utils/is-apple-device';
import { register } from 'utils/service-worker';

declare global {
  interface Document {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BCFcm: Record<string, any> | undefined;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const isNotAnAppleDevice = !isAppleDevice();
if (isNotAnAppleDevice) {
  document.body.classList.add('custom-scroll');
}

LicenseInfo.setLicenseKey(env.MUI_KEY);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ConfirmationServiceProvider>
          <CookiesProvider>
            <App />
            <StyledToastContainer position="bottom-right" />
          </CookiesProvider>
        </ConfirmationServiceProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);

register();
