import { useDeleteRecipientManagerMutation } from 'store/api/alerters.api';
import SharedAlertersDetails from 'components/_extra/Alerter/Cards/SharedAlertersDetails';
import { styled } from '@mui/material';
import { useConfirmation } from './use-confirmation';

const ListSymbol = styled('span')(({ theme }) => ({
  paddingRight: theme.spacing(1.25),
  paddingLeft: theme.spacing(1),
}));

export const useDeleteRecipientManager = (isReminder?: boolean) => {
  const [deleteRecipientManager] = useDeleteRecipientManagerMutation();
  const confirm = useConfirmation();

  const handleDelete = (id: number, title: string) => {
    confirm(
      {
        title: `Are you sure you want to delete "${title}"?`,
        message:
          'This recipient set is will be deleted immediately. You can’t undo this action.',
        confirmButtonLabel: 'Delete',
        rejectButtonLabel: 'Cancel',
        variant: 'danger',
      },
      () => {
        deleteRecipientManager({ id, isReminder });
      },
    );
  };

  const handleDeleteClick = (
    id: number,
    title: string,
    alerters?: string[],
  ) => {
    if (alerters && alerters.length > 0) {
      confirm(
        {
          title: `“${title}” recipient set is used by the following alerts:`,
          message: (
            <>
              <SharedAlertersDetails
                listSymbol={<ListSymbol>•</ListSymbol>}
                alerters={alerters}
                linkProps={{
                  color: 'error',
                  sx: {
                    pl: ({ spacing }) => spacing(3),
                    pb: ({ spacing }) => spacing(2),
                  },
                }}
              />
              <br />
              Unlink the recipient set from all of the alerts that are using it
              to proceed to the delete action. You can’t undo this action.
            </>
          ),
          confirmButtonLabel: 'Unlink',
          rejectButtonLabel: 'Cancel',
          variant: 'danger',
        },
        () => handleDelete(id, title),
      );
      return;
    }
    handleDelete(id, title);
  };

  return handleDeleteClick;
};
