import { FiltersToolbar } from 'components/_extra';
interface Props {
  routerTabState: 'search' | 'apple-watch';
}

const Toolbar = ({ routerTabState }: Props) => {
  return (
    <FiltersToolbar
      routerTabState={routerTabState}
      filtersIconUrl="/profiles/overview/apple-watch"
      searchIconUrl="/profiles/overview"
    />
  );
};

export default Toolbar;
