import { Tooltip } from '@mui/material';
import { copy } from 'assets/iconify';
import { MouseEventHandler } from 'react';
import { StyledIcon, IconOnlyButton } from './styled';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  toCopy?: string;
  disabled?: boolean;
}

const CopyButton = ({ onClick, toCopy, disabled }: Props) => {
  return (
    <Tooltip title={`Copy ${toCopy ?? ''}`} placement="top">
      <span>
        <IconOnlyButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onClick}
          disabled={disabled}
        >
          <StyledIcon icon={copy.icons.normal} />
        </IconOnlyButton>
      </span>
    </Tooltip>
  );
};

export default CopyButton;
