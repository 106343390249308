import { Icon, IconifyIcon, IconProps } from '@iconify/react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';

export interface IconButtonProps extends MuiIconButtonProps {
  icon: string | IconifyIcon;
  width?: string | number;
  height?: string | number;
  iconProps?: Partial<IconProps>;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  width,
  height,
  iconProps,
  ...rest
}) => (
  <MuiIconButton sx={{ p: 1.5 }} color="primary" {...rest}>
    <Icon icon={icon} width={width} height={height} {...iconProps} />
  </MuiIconButton>
);

export default IconButton;
