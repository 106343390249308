import { useParams } from 'react-router-dom';
import { useGetRegionsQuery } from 'store/api/safe-zones.api';
import { getZones } from 'utils/zones';

export const useSafeZoneList = (shouldSkipFetching?: boolean) => {
  const { profileId } = useParams<{ profileId: string }>();

  const { data, isLoading, isFetching } = useGetRegionsQuery(
    {
      id: Number(profileId),
    },
    {
      skip: shouldSkipFetching,
    },
  );

  return {
    zones: getZones(data),
    isLoading: isLoading || isFetching,
  };
};
