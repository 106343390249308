/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { palette } from 'styles/palette';

export type PointType = 'finished' | 'unfinished' | 'error';
const getColor = (pointType: PointType) => {
  switch (pointType) {
    case 'error':
      return palette.red.main;
    case 'finished':
      return palette.secondary.main;
    default:
      return palette.primary.main;
  }
};

const MARKER_OPTIONS = {
  draggable: true,
  animates: false,
  anchorOffset: new DOMPoint(0, -12),
} as mapkit.AnnotationConstructorOptions;

export const useMarkers = (
  map: mapkit.Map | null,
  handlePositionChange: () => void,
  handlePointClick: (index: number) => void,
  handlePointMove: (
    coordinate: mapkit.Coordinate | null,
    index?: number,
  ) => void,
) => {
  const factory = (
    _coordinate: mapkit.Coordinate,
    options: mapkit.AnnotationConstructorOptions,
  ) => {
    const index: number = options.data.index;
    const pointType: PointType = options.data.pointType;
    const div = document.createElement('div');
    div.className = 'zonePoint';
    div.innerHTML = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="${getColor(pointType)}"/>
      <circle cx="12" cy="12" r="10" fill="white"/>
    </svg>
    `;
    div.addEventListener('click', () => handlePointClick(index));

    return div;
  };

  const createNewMarker = (
    coordinate: mapkit.Coordinate,
    index: number,
    pointType?: PointType,
  ) => {
    if (map && coordinate) {
      const annotation = new mapkit.Annotation(coordinate, factory, {
        ...MARKER_OPTIONS,
        data: { index, pointType },
      });
      annotation.addEventListener('drag-end', handlePositionChange);
      annotation.addEventListener('dragging', (e: unknown) => {
        handlePointMove(
          (e as { coordinate: mapkit.Coordinate }).coordinate,
          index,
        );
      });
      annotation.addEventListener('drag-end', () => {
        handlePointMove(null);
      });
      map.addAnnotation(annotation);
      return map.annotations.slice(1);
    }

    toast.error('Something went wrong. Please refresh the page and try again.');
    return [];
  };

  const removeAllMarkers = () => {
    if (map) {
      map.annotations.slice(1).forEach((a) => {
        if (a.element.className === 'zonePoint') map.removeAnnotation(a);
      });
    }
  };

  const removeMarker = (index: number) => {
    if (map) {
      const markerToRemove = map.annotations.filter(
        ({ data }) => data.label !== 'searchPin',
      )[index + 1];

      markerToRemove.selected = false;
      map.removeAnnotation(markerToRemove);
      replaceMarkers(
        map.annotations
          .filter(({ data }) => data.label !== 'searchPin')
          .slice(1)
          .map((a) => a.coordinate),
      );
      return map.annotations
        .slice(1)
        .filter(({ data }) => data.label !== 'searchPin');
    }
    return [];
  };

  const replaceMarkers = (
    points: mapkit.Coordinate[],
    pointType?: PointType,
  ) => {
    removeAllMarkers();
    points.forEach((point, index) => createNewMarker(point, index, pointType));
    return (
      map?.annotations
        .slice(1)
        .filter(({ data }) => data.label !== 'searchPin') ?? []
    );
  };

  const addNewMarker = (point: mapkit.Coordinate) => {
    if (map) {
      createNewMarker(point, map?.annotations.length - 1, 'unfinished');
      return map.annotations
        .slice(1)
        .filter(({ data }) => data.label !== 'searchPin');
    }
    return [];
  };

  return {
    removeAllMarkers,
    addNewMarker,
    replaceMarkers,
    removeMarker,
  };
};
