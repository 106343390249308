import { DialogContent, Modal } from '@mui/material';
import { ModalWrapper } from 'pages/AlerterDetails/components/Triggers/styled';
import { useEffect, useState } from 'react';
import ImageUploadButton from './components/ImageUploadButton';
import ImageUploadModalContent from './components/ImageUploadModalContent';

const ImageUpload = ({
  onClear,
  isLoading,
  onAdd,
  defaultUrl,
  onError,
  readOnly,
}: {
  onAdd?: (imageFile: File | null, url?: string) => void;
  onClear?: () => void;
  isLoading?: boolean;
  defaultUrl?: string;
  onError?: () => void;
  readOnly?: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (defaultUrl) {
      setImageUrl(defaultUrl);
    }
  }, [defaultUrl]);

  return (
    <>
      <ImageUploadButton
        disabled={readOnly}
        previewUrl={imageUrl}
        file={imageFile}
        onClick={() => setIsModalOpen(true)}
        onError={onError}
        onClear={() => {
          onClear?.();
          setImageUrl('');
          setImageFile(null);
        }}
      />
      <Modal open={isModalOpen}>
        <DialogContent
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            px: {
              xxs: 0,
              sm: 2,
            },
          }}
        >
          <ModalWrapper
            sx={({ spacing }) => ({
              maxWidth: { xxs: '100%', sm: spacing(66) },
              width: '100%',
              height: { xxs: '100dvh', sm: 'unset' },
              maxHeight: { sm: 'unset' },
              borderRadius: { xxs: '0', sm: '8px' },
            })}
          >
            <ImageUploadModalContent
              file={imageFile}
              onAdd={(file, url) => {
                setImageFile(file);
                setImageUrl(url);
                onAdd?.(file, url);
              }}
              onClear={() => {
                onClear?.();
                setImageFile(null);
              }}
              isLoading={isLoading}
              closeModal={() => setIsModalOpen(false)}
              setImageFile={setImageFile}
            />
          </ModalWrapper>
        </DialogContent>
      </Modal>
    </>
  );
};

export default ImageUpload;
