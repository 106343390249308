import { Icon } from '@iconify/react';
import { Box, Button, Typography, useTheme } from '@mui/material';

interface Props {
  onReset: () => void;
}

const Finish = ({ onReset }: Props) => {
  const theme = useTheme();

  return (
    <Box>
      <Box width="min-content" display="flex" alignItems="center" mb={2}>
        <Icon
          icon="material-symbols:task-alt"
          color={theme.palette.secondary.main}
          height={theme.spacing(5)}
        />
        <Typography variant="h6" ml={1.5} noWrap>
          Sent succesfully!
        </Typography>
      </Box>
      <Button onClick={onReset}>Reset</Button>
    </Box>
  );
};

export default Finish;
