import { useAppSelector } from 'store';
import { useGetReminderTriggerQuery } from 'store/api/reminders.api';

export const useReminderTriggerList = () => {
  const { scheduleRules, triggerId } = useAppSelector(
    (state) => state.reminder,
  );
  const { isLoading } = useGetReminderTriggerQuery(triggerId, {
    skip: triggerId === null,
  });

  return {
    isLoading: isLoading || triggerId === null,
    triggers: scheduleRules,
  };
};
