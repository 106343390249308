import { Summary, AlertCard as Card } from 'components/_extra';
import {
  AlerterRecipientManagerSummary,
  SourceStreamType,
} from 'types/alerters';
import { useAlerterControls } from '../hooks/use-alerter-controls';
import React from 'react';
import { TriggerGroup } from 'utils/triggers';
import { useCopyAlerter } from '../hooks/use-copy-alerter';

interface Props {
  id: number;
  title: string;
  isActive?: boolean;
  schedulerSummary: string;
  triggerFragments: string[];
  group: TriggerGroup;
  sourceStreamType: SourceStreamType;
  triggerSummary: string;
  recipientManagerName: string;
  recipientManagers: AlerterRecipientManagerSummary[];
}
const AlertCard = ({
  title,
  isActive,
  group,
  id,
  recipientManagerName,
  triggerFragments,
  recipientManagers,
}: Props) => {
  const { handleEditAlert, handleRemoveAlert, handleToggleAlert } =
    useAlerterControls(id, group, title);
  const { handleClick: handleCopy } = useCopyAlerter(id);

  return (
    <Card
      recipientManagers={recipientManagers}
      title={title}
      isActive={isActive}
      onCopy={handleCopy}
      onEdit={handleEditAlert}
      onRemove={handleRemoveAlert}
      onSwitchToggle={handleToggleAlert}
      removeButtonLabel="Delete"
      childrenMaxWidth={60}
    >
      {triggerFragments.length > 0 && (
        <Summary
          minWidth={16.5}
          fullWidth
          shrink
          title="Conditions"
          details={
            <>
              {triggerFragments.map((fragment, index) => (
                <React.Fragment key={index}>
                  &#x2022; {fragment}
                  {index !== triggerFragments.length && <br />}
                </React.Fragment>
              ))}
            </>
          }
        />
      )}
      <Summary
        minWidth={12}
        fullWidth
        shrink
        title="Recipient Sets"
        details={recipientManagerName || '-'}
      />
    </Card>
  );
};
export default AlertCard;
