import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

interface WithErrorProps {
  dispatch: Dispatch;
  queryFulfilled: PromiseWithKnownReason<
    {
      data: unknown;
      meta: unknown;
    },
    QueryFulfilledRejectionReason<
      BaseQueryFn<
        AxiosRequestConfig<unknown>,
        unknown,
        unknown,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >
  >;
  successMessage?: string;
  errorMessage?: string;
}

async function withNotification({
  queryFulfilled,
  successMessage,
  errorMessage = 'An error has occurred. Please try again later.',
}: WithErrorProps) {
  try {
    const data = await queryFulfilled;

    if (!data.data) {
      throw true;
    }

    if (successMessage) {
      toast(successMessage, { type: 'success' });
    }
  } catch {
    toast(errorMessage, { type: 'error' });
  }
}

export default withNotification;
