import { styled } from '@mui/material';

export default styled('div')(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gap: theme.spacing(3),
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('newLg')]: {
    gridTemplateColumns: '1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));
