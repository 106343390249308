import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import { idCard } from 'assets/iconify';
import { BasicModal } from 'components/BasicModal';
import { CompletionMessage } from 'components/CompletionMessage';
import VInput from 'components/VInput';
import { PageContent } from 'components/_extra/generic';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateManagedProfileMutation } from 'store/api/admin.api';

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

type PatientCreationForm = {
  firstName: string;
  lastName?: string;
  thirdPartyIdentifier?: string;
};

const PatientCreationWizard = () => {
  const { register, handleSubmit, reset } = useForm<PatientCreationForm>();

  const [open, setOpen] = useState(false);

  const [createProfile, { isLoading, isSuccess, data }] =
    useCreateManagedProfileMutation();

  const onSubmit = (data: PatientCreationForm) => {
    createProfile({
      first_name: data.firstName,
      last_name: data.lastName,
      third_party_public_identifier: data.thirdPartyIdentifier,
    });
    setOpen(true);
  };

  return (
    <PageContent sx={{ height: '100vh' }}>
      <Tabs
        sx={{
          backgroundColor: ({ palette }) => palette.primary.main,
          minHeight: ({ spacing }) => spacing(7),
          px: {
            xxs: 0,
            md: 2,
          },
        }}
        value={0}
      >
        <Tab label="BC Identifier" value={0} />
      </Tabs>
      <Box
        p={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        overflow="auto"
        flexGrow={1}
      >
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection="column"
          border={({ palette }) => `1px solid ${palette.gray.light}`}
          height="100%"
          width="100%"
          borderRadius={2}
          p={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        >
          <StyledIcon icon={idCard.icons.normal} />
          <Typography
            textAlign="center"
            variant="h6"
            sx={{ my: 1, fontWeight: 500 }}
          >
            Create a BoundaryCare Profile
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="center"
            pb={3}
            sx={{ maxWidth: ({ spacing }) => spacing(75) }}
          >
            Enter basic information to start creating a new managed profile.
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            maxWidth={({ spacing }) => spacing(55)}
            columnGap={2}
            rowGap={2.5}
            py={2}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <VInput
              disableWhitespace
              containerSx={{ flexGrow: 1, flexShrink: 0 }}
              label="First name"
              placeholder="John"
              {...register('firstName')}
            />
            <VInput
              disableWhitespace
              containerSx={{ flexGrow: 1, flexShrink: 0 }}
              label="Last name"
              placeholder="Smith"
              {...register('lastName')}
            />
            <VInput
              disableWhitespace
              placeholder="Patient ID, insurance ID, billing ID or other ID types"
              label="Custom identifier"
              containerSx={{ flexGrow: 1 }}
              {...register('thirdPartyIdentifier')}
            />
            <Button
              sx={{ flexGrow: 1, mt: 2 }}
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Create Profile
            </Button>
          </Box>
        </Box>
      </Box>
      {open && (
        <BasicModal open title="Profile Created" hideCloseButton>
          {isLoading ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h5"
                color="primary"
                pb={1}
                px={1}
                textAlign="center"
              >
                Creating profile...
              </Typography>
              <Typography
                variant="body1"
                color="blue.main"
                textAlign="center"
                pb={4}
                px={1}
              >
                We are currently setting up new profile. Please stay on this
                page, as it may take a few moments.
              </Typography>
              <CircularProgress size={72} />
            </Box>
          ) : (
            <CompletionMessage
              status={isSuccess ? 'success' : 'error'}
              title={
                isSuccess
                  ? 'Profile created successfully!'
                  : 'Something went wrong...'
              }
              subtitle={
                isSuccess && data ? (
                  <>
                    Save this profile’s unique identifier for future reference:{' '}
                    <strong>{data.result.unique_public_identifier}</strong>. An
                    email has been sent to admins including its basic
                    information.
                  </>
                ) : (
                  <>
                    An unknown error occurred. Please try again later. <br /> If
                    the problem persist, please contact support team.
                  </>
                )
              }
              onButtonClick={() => {
                setOpen(false);
                if (isSuccess) reset();
              }}
              sx={{ p: 0 }}
              buttonLabel={isSuccess ? 'Got it' : 'Close'}
            />
          )}
        </BasicModal>
      )}
    </PageContent>
  );
};

export default PatientCreationWizard;
