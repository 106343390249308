import {
  AlerterResponse,
  CreateReminderParams,
  GetAlertersParams,
  ReminderSummary,
  GetReminderTriggerResponse,
  RawScheduleRule,
  UpdateReminderParams,
  RawReminderTrigger,
  ReminderEditScheduleRuleParams,
  AlerterTriggerUpdateParams,
  RawAlerterRuleResponse,
  MetricRule,
} from 'types/alerters';
import { bcApi } from './_api';
import {
  transformAlerterList,
  transformMetricDataToRaw,
} from 'utils/data-transformation';
import { transformCreateReminderParams } from 'utils/data-transformation/reminders';
import { TriggerGroup } from 'utils/triggers';

export const remindersApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getReminders: builder.query<
      Record<TriggerGroup | 'uncategorized', ReminderSummary[]>,
      GetAlertersParams
    >({
      query: ({ alerterSourceId, deviceId, patientProfileId, userId }) => ({
        url: '/alerters',
        method: 'GET',
        params: {
          is_reminder: true,
          alerter_source_id: alerterSourceId,
          device_id: deviceId,
          patient_profile_id: patientProfileId,
          user_id: userId,
        },
        transformResponse: (data: string) =>
          transformAlerterList(JSON.parse(data)),
      }),
      providesTags: ['RemindersList'],
    }),
    createReminder: builder.mutation<AlerterResponse, CreateReminderParams>({
      query: (data) => ({
        url: '/alerters',
        method: 'POST',
        data: transformCreateReminderParams(data),
      }),
      invalidatesTags: ['SharedRecipientSets', 'RemindersList'],
    }),
    getReminderInfo: builder.query<AlerterResponse, number>({
      query: (id) => ({
        url: `/alerters/${id}?is_reminder=true`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0.00001,
    }),
    deleteReminder: builder.mutation<AlerterResponse, number>({
      query: (id) => ({
        url: `/alerters/${id}?is_reminder=true`,
        method: 'DELETE',
      }),
    }),
    getReminderTrigger: builder.query<
      GetReminderTriggerResponse,
      number | null
    >({
      query: (id) => ({
        url: `/alerter_triggers/${id}`,
        method: 'GET',
        params: {
          is_reminder: true,
        },
      }),
      keepUnusedDataFor: 0.00001,
    }),
    addScheduleRuleToReminderTrigger: builder.mutation<
      { result: RawReminderTrigger },
      { id: number; rule: Pick<RawScheduleRule, 'serialized_schedule'> }
    >({
      query: ({ id, rule }) => ({
        url: `/alerter_triggers/${id}/add_rule`,
        method: 'PUT',
        data: { schedule_rule: rule, is_reminder: true },
      }),
    }),
    updateReminder: builder.mutation<AlerterResponse, UpdateReminderParams>({
      query: ({ id, ...reminder }) => ({
        url: `/alerters/${id}`,
        method: 'PUT',
        data: {
          is_reminder: true,
          custom_message_template: reminder.message,
          title: reminder.title,
          enabled: reminder.enabled,
          message_image: reminder.messageImage,
          message_image_external_url: reminder.messageImageUrl,
        },
      }),
      invalidatesTags: ['SharedRecipientSets', 'RemindersList'],
    }),
    updateRuleInReminderTrigger: builder.mutation<
      { result: RawScheduleRule },
      ReminderEditScheduleRuleParams
    >({
      query: ({ ruleId, enabled, serializedSchedule, title }) => ({
        url: `/alerter_triggers/rules/${ruleId}/update`,
        method: 'PUT',
        data: {
          is_reminder: true,
          schedule_rule: {
            enabled,
            title,
            serialized_schedule: serializedSchedule
              ? {
                  days_of_week: serializedSchedule?.days,
                  start_time: serializedSchedule?.startTime,
                }
              : undefined,
          },
        },
      }),
    }),
    updateConditionInReminder: builder.mutation<
      RawAlerterRuleResponse,
      AlerterTriggerUpdateParams
    >({
      query: ({ metricRule, metricRuleId }) => ({
        url: `/alerter_triggers/rules/${metricRuleId}/update`,
        method: 'PUT',
        data: {
          is_reminder: true,
          metric_rule: transformMetricDataToRaw(metricRule as MetricRule),
        },
      }),
      invalidatesTags: ['RemindersList'],
    }),
    removeRuleFromReminderTrigger: builder.mutation<
      { result: RawScheduleRule },
      { triggerId: number | null; scheduleRuleId: number }
    >({
      query: ({ scheduleRuleId, triggerId }) => ({
        url: `/alerter_triggers/${triggerId}/delete_rule`,
        method: 'DELETE',
        data: {
          is_reminder: true,
          schedule_rule_id: scheduleRuleId,
        },
      }),
    }),
    duplicateReminder: builder.mutation<AlerterResponse, { id: number }>({
      query: ({ id }) => ({
        url: `/alerters/${id}/duplicate`,
        method: 'POST',
        data: {
          is_reminder: true,
        },
      }),
    }),
  }),
});

export const {
  useGetRemindersQuery,
  useCreateReminderMutation,
  useGetReminderInfoQuery,
  useDeleteReminderMutation,
  useGetReminderTriggerQuery,
  useUpdateReminderMutation,
  useAddScheduleRuleToReminderTriggerMutation,
  useUpdateRuleInReminderTriggerMutation,
  useUpdateConditionInReminderMutation,
  useRemoveRuleFromReminderTriggerMutation,
  useDuplicateReminderMutation,
} = remindersApi;
