import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const battery: IconifyJSON = {
  prefix: 'battery',
  icons: {
    critical: {
      ...defaultSizes,
      body: '<path d="M8.34995 21.4C8.08329 21.4 7.86662 21.3123 7.69995 21.137C7.53328 20.9623 7.44995 20.75 7.44995 20.5V5.32498C7.44995 5.05831 7.53328 4.84164 7.69995 4.67498C7.86662 4.50831 8.08329 4.42498 8.34995 4.42498H10.15V2.59998H13.85V4.42498H15.65C15.9166 4.42498 16.1333 4.50831 16.3 4.67498C16.4666 4.84164 16.55 5.05831 16.55 5.32498V20.5C16.55 20.75 16.4666 20.9623 16.3 21.137C16.1333 21.3123 15.9166 21.4 15.65 21.4H8.34995ZM8.94995 17.9H15.05V5.89998H8.94995V17.9Z" fill="currentColor"/>',
    },
    low: {
      ...defaultSizes,
      body: '<path d="M8.34995 21.4C8.08329 21.4 7.86662 21.3123 7.69995 21.137C7.53328 20.9623 7.44995 20.75 7.44995 20.5V5.32498C7.44995 5.05831 7.53328 4.84164 7.69995 4.67498C7.86662 4.50831 8.08329 4.42498 8.34995 4.42498H10.15V2.59998H13.85V4.42498H15.65C15.9166 4.42498 16.1333 4.50831 16.3 4.67498C16.4666 4.84164 16.55 5.05831 16.55 5.32498V20.5C16.55 20.75 16.4666 20.9623 16.3 21.137C16.1333 21.3123 15.9166 21.4 15.65 21.4H8.34995ZM8.94995 13.9H15.05V5.89998H8.94995V13.9Z" fill="currentColor"/>',
    },
    normal: {
      ...defaultSizes,
      body: '<path d="M8.34995 21.4C8.08329 21.4 7.86662 21.3123 7.69995 21.137C7.53328 20.9623 7.44995 20.75 7.44995 20.5V5.32498C7.44995 5.05831 7.53328 4.84164 7.69995 4.67498C7.86662 4.50831 8.08329 4.42498 8.34995 4.42498H10.15V2.59998H13.85V4.42498H15.65C15.9166 4.42498 16.1333 4.50831 16.3 4.67498C16.4666 4.84164 16.55 5.05831 16.55 5.32498V20.5C16.55 20.75 16.4666 20.9623 16.3 21.137C16.1333 21.3123 15.9166 21.4 15.65 21.4H8.34995ZM8.94995 9.89998H15.05V5.89998H8.94995V9.89998Z" fill="currentColor"/>',
    },
    full: {
      ...defaultSizes,
      body: '<path d="M8.125 21.75C7.875 21.75 7.66667 21.6666 7.5 21.5C7.33333 21.3333 7.25 21.125 7.25 20.875V5.12495C7.25 4.87495 7.33333 4.66662 7.5 4.49995C7.66667 4.33328 7.875 4.24995 8.125 4.24995H10.15V2.44995H13.85V4.24995H15.875C16.125 4.24995 16.3333 4.33328 16.5 4.49995C16.6667 4.66662 16.75 4.87495 16.75 5.12495V20.875C16.75 21.125 16.6667 21.3333 16.5 21.5C16.3333 21.6666 16.125 21.75 15.875 21.75H8.125Z" fill="currentColor"/>',
    },
    charging: {
      ...defaultSizes,
      body: '<path d="M8.125 21.75C7.875 21.75 7.66667 21.6666 7.5 21.5C7.33333 21.3333 7.25 21.125 7.25 20.875V5.12495C7.25 4.87495 7.33333 4.66662 7.5 4.49995C7.66667 4.33328 7.875 4.24995 8.125 4.24995H10.15V2.44995H13.85V4.24995H15.875C16.125 4.24995 16.3333 4.33328 16.5 4.49995C16.6667 4.66662 16.75 4.87495 16.75 5.12495V20.875C16.75 21.125 16.6667 21.3333 16.5 21.5C16.3333 21.6666 16.125 21.75 15.875 21.75H8.125ZM11.25 19L14.575 12.75H12.75V7.99995L9.425 14.25H11.25V19Z" fill="currentColor"/>',
    },
  },
};
