import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 64, height: 64 };

export const addCiricle: IconifyJSON = {
  prefix: 'add-circle',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M29.9998 44.6664H33.9997V33.9997H44.6664V29.9998H33.9997V19.3332H29.9998V29.9998H19.3332V33.9997H29.9998V44.6664ZM32.0042 57.333C28.5004 57.333 25.207 56.6681 22.124 55.3384C19.0409 54.0086 16.3591 52.2039 14.0785 49.9244C11.7979 47.6448 9.99239 44.9641 8.66204 41.8824C7.33168 38.8008 6.6665 35.5081 6.6665 32.0042C6.6665 28.5004 7.33139 25.207 8.66117 22.124C9.99095 19.0409 11.7956 16.3591 14.0752 14.0785C16.3548 11.7979 19.0354 9.9924 22.1171 8.66204C25.1987 7.33168 28.4915 6.6665 31.9953 6.6665C35.4991 6.6665 38.7926 7.3314 41.8756 8.66117C44.9586 9.99095 47.6405 11.7956 49.921 14.0752C52.2017 16.3548 54.0071 19.0354 55.3375 22.1171C56.6679 25.1988 57.333 28.4915 57.333 31.9953C57.333 35.4991 56.6681 38.7925 55.3384 41.8756C54.0086 44.9586 52.2039 47.6405 49.9244 49.921C47.6448 52.2017 44.9641 54.0071 41.8824 55.3375C38.8008 56.6679 35.5081 57.333 32.0042 57.333Z" fill="#A3B2C6"/>',
    },
  },
};
