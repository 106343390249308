import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export default createDraftSafeSelector(
  (state: RootState) => state.alerter.trigger.rules,
  (rules) => {
    return rules
      .filter((rule) => rule.suppressMessage === false)
      .map((rule) => rule.id);
  },
);
