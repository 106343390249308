/* eslint-disable @typescript-eslint/no-explicit-any */
import { NewSafeZoneContext } from 'pages/PatientAccount/components/SafeZones/AddSafeZoneModal';
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RegionsType, SafeZone } from 'types/safe-zones';
import {
  addPointAndCreateNewPolygon,
  calculatePathCentroid,
  calculatePolygonCentroid,
  hasSelfIntersection,
  initMap,
} from 'utils/map';
import { useSaveNewRegion } from './use-save-new-region';
import { debounce } from '@mui/material';
import { useMarkers } from './use-markers';
import { useOverlay } from './use-overlay';

let map: mapkit.Map | null = null;

const canBeRegionSaved = (type: RegionsType, annotationCount: number) => {
  switch (type) {
    case RegionsType.CIRCLE_TYPE:
      return annotationCount > 1;
    case RegionsType.PATH_TYPE:
      return annotationCount > 2;
    case RegionsType.POLYGON_TYPE:
      return annotationCount > 3;
    default:
      return false;
  }
};

export const useDrawRegion = (
  region: mapkit.CoordinateRegion,
  rootRef: RefObject<HTMLDivElement>,
  editedSafeZone: SafeZone | null,
  handlePointMove: (
    coordinate: mapkit.Coordinate | null,
    index?: number,
  ) => void,
) => {
  const {
    radius,
    setSave,
    setIsSaveActive,
    setHasUnsavedChanges,
    setDiscard,
    type: selectedShape,
    onClose,
    setIsLoading,
    setRadius,
    setType: setSelectedShape,
    title,
    onSave,
  } = useContext(NewSafeZoneContext);

  const [isShapeSaved, setIsShapeSaved] = useState(!!editedSafeZone);
  const [isSelfIntersecting, setIsSelfIntersecting] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(
    !!editedSafeZone,
  );
  const isPolygonDone = useRef(
    editedSafeZone?.shapeType === RegionsType.POLYGON_TYPE,
  );
  const [offset, setOffset] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const radiusRef = useRef(radius);
  const shapeRef = useRef<RegionsType>(RegionsType.CIRCLE_TYPE);

  const updateOffset = () => {
    const x =
      (document.getElementById('basic-modal')?.getBoundingClientRect().left ??
        0) + (rootRef.current?.offsetLeft ?? 0);
    const y =
      (document.getElementById('basic-modal')?.getBoundingClientRect().top ??
        0) + (rootRef.current?.offsetTop ?? 0);

    setOffset({ x, y });
  };

  useEffect(() => {
    map = initMap(editedSafeZone ? undefined : region);
    updateOffset();
    return () => {
      map = null;
    };
  }, []);

  const handlePositionChange = () => {
    if (!map) {
      return;
    }

    const points = map.annotations
      .slice(1)
      .filter(({ data }) => data.label !== 'searchPin')
      .map((m) => m.coordinate);

    setIsShapeSaved(false);
    setHasUnsavedChanges(true);

    switch (shapeRef.current) {
      case RegionsType.PATH_TYPE:
        redrawPath(points, radiusRef.current);
        setIsSaveActive(true);
        break;
      case RegionsType.POLYGON_TYPE:
        const hasIntersection = hasSelfIntersection([...points, points[0]]);

        setIsSaveActive(isPolygonDone.current);
        setIsSelfIntersecting(hasIntersection);
        if (hasIntersection) {
          setIsSaveActive(false);
        }
        redrawPolygon(
          points,
          isPolygonDone.current,
          false,
          false,
          hasIntersection,
        );
        break;
      case RegionsType.CIRCLE_TYPE:
        setIsSaveActive(true);
        redrawCircle(points[0], radiusRef.current);
        break;
      default:
        break;
    }
  };

  const handlePointClick = (index: number) => {
    if (!map) return;
    if (
      index === 0 &&
      shapeRef.current === RegionsType.POLYGON_TYPE &&
      map &&
      map.annotations.length >= 4 &&
      !isPolygonDone.current
    ) {
      closePolygon();
      return;
    }

    setIsShapeSaved(false);

    const isAValidClosedPolygon =
      shapeRef.current === RegionsType.POLYGON_TYPE &&
      map.annotations.length > 4;
    if (
      isAValidClosedPolygon ||
      !isPolygonDone.current ||
      shapeRef.current !== RegionsType.POLYGON_TYPE
    ) {
      setHasUnsavedChanges(true);
      const points = removeMarker(index).map((m) => m.coordinate);

      if (shapeRef.current === RegionsType.CIRCLE_TYPE) {
        setIsSaveActive(false);
        removeAllMarkers();
        removeOverlays();
      }

      if (points.length === 0) {
        setIsClearButtonActive(false);
        return;
      }

      if (shapeRef.current === RegionsType.PATH_TYPE) {
        redrawPath(points, radius);
        setIsSaveActive(points.length >= 2);
        return;
      }
      if (shapeRef.current === RegionsType.POLYGON_TYPE) {
        const hasIntersection = hasSelfIntersection([...points, points[0]]);
        setIsSaveActive(!hasIntersection && isPolygonDone.current);
        setIsSelfIntersecting(hasIntersection);
        redrawPolygon(
          points,
          isPolygonDone.current,
          false,
          false,
          hasIntersection,
        );
        return;
      }
    }
  };

  const { addNewMarker, replaceMarkers, removeMarker, removeAllMarkers } =
    useMarkers(map, handlePositionChange, handlePointClick, handlePointMove);

  const {
    redrawCircle,
    redrawPath,
    redrawPolygon,
    removeOverlays,
    handleCurrentRegionZoom,
  } = useOverlay(map, replaceMarkers);

  const saveShape = () => {
    if (!map) return;
    setIsShapeSaved(true);
    setIsSaveActive(false);
    setHasUnsavedChanges(false);
    const points = map.annotations
      .slice(1)
      .filter(({ data }) => data.label !== 'searchPin')
      .map((a) => a.coordinate);
    switch (selectedShape) {
      case RegionsType.CIRCLE_TYPE:
        redrawCircle(points[0], radius, true, true);
        break;
      case RegionsType.PATH_TYPE:
        redrawPath(points, radius, true, true);
        break;
      case RegionsType.POLYGON_TYPE:
        redrawPolygon(points, true, true, true);
        break;
    }
  };

  const { handleSaveSafeZone, isLoading } = useSaveNewRegion({
    map,
    selectedShape,
    radius,
    saveShape,
    shapeId: editedSafeZone?.id,
    modifyExisting: !!editedSafeZone,
    onClose,
    setIsLoading,
    deactivateSaveButton: () => setIsSaveActive(false),
  });

  const externalSaveZone = useCallback(
    (title: string) => {
      if (!map) return;
      const points = map.annotations
        .filter(({ data }) => data.label !== 'searchPin')
        .slice(1)
        .map((a) => a.coordinate);

      const { latitude, longitude } =
        selectedShape === RegionsType.POLYGON_TYPE
          ? calculatePolygonCentroid(
              points.map((value) => [value.latitude, value.longitude]) || [],
            ) ?? { latitude: 0, longitude: 0 }
          : selectedShape === RegionsType.PATH_TYPE
          ? calculatePathCentroid(points || []) ?? {
              latitude: 0,
              longitude: 0,
            }
          : points[0];

      onSave?.({
        id: editedSafeZone?.id ?? Date.now(),
        title,
        shapeType: selectedShape,
        lat: latitude,
        lng: longitude,
        geofence: map?.annotations
          .slice(1)
          .filter(({ data }) => data.label !== 'searchPin')
          .map((m) => [m.coordinate.latitude, m.coordinate.longitude]),
        radius: radiusRef.current,
      });
      onClose();
    },
    [onSave, selectedShape, map, radiusRef, title],
  );

  const closePolygon = () => {
    if (map) {
      const points = map.annotations
        .slice(1)
        .filter(({ data }) => data.label !== 'searchPin')
        .map((m) => m.coordinate);
      isPolygonDone.current = true;
      const hasIntersection = hasSelfIntersection([...points, points[0]]);
      setIsSaveActive(!hasIntersection);
      setIsSelfIntersecting(hasIntersection);
      redrawPolygon(points, true, false, false, hasIntersection);
    }
  };

  const addPoint = (coordinate: mapkit.Coordinate) => {
    if (!map || map.annotations.length > 50 || isLoading) {
      return;
    }

    setHasUnsavedChanges(true);
    setIsShapeSaved(false);

    setIsClearButtonActive(true);

    if (shapeRef.current === RegionsType.CIRCLE_TYPE) {
      redrawCircle(coordinate, radiusRef.current);
      setIsSaveActive(true);
      return;
    }

    if (shapeRef.current === RegionsType.PATH_TYPE) {
      const newPoints = addNewMarker(coordinate).map((m) => m.coordinate);
      redrawPath(newPoints, radiusRef.current);

      if (newPoints.length >= 2) {
        setIsSaveActive(true);
      }
      return;
    }

    if (shapeRef.current === RegionsType.POLYGON_TYPE) {
      if (isPolygonDone.current) {
        const points = addPointAndCreateNewPolygon(
          map.annotations
            .slice(1)
            .filter(({ data }) => data.label !== 'searchPin')
            .map((m) => ({
              latitude: m.coordinate.latitude,
              longitude: m.coordinate.longitude,
            })),
          coordinate,
        ).map(
          ({ latitude, longitude }) =>
            new mapkit.Coordinate(latitude, longitude),
        );

        const hasIntersection = hasSelfIntersection([...points, points[0]]);

        setIsSaveActive(!hasIntersection);
        setIsSelfIntersecting(hasIntersection);

        redrawPolygon(points, true, false, false, hasIntersection);
        return;
      }

      const newMarkers = addNewMarker(coordinate);

      redrawPolygon(
        newMarkers.map((m) => m.coordinate),
        false,
      );
      return;
    }
  };

  const handleMapClick = (event: Event) => {
    const e = event as MouseEvent;
    e.stopPropagation();

    updateOffset();

    if (!e.shiftKey || !map) {
      return;
    }

    const domPoint = new DOMPoint(e.clientX, e.clientY);
    addPoint(map.convertPointOnPageToCoordinate(domPoint));
  };

  const handleLongPress = (e: mapkit.EventBase<mapkit.Map>) => {
    if (!map) return;
    const point = (e as any).pointOnPage as DOMPoint;
    addPoint(map?.convertPointOnPageToCoordinate(point));
  };

  const handleClearDrawing = () => {
    if (map && map.overlays[1]) {
      setIsShapeSaved(false);
      removeAllMarkers();
      removeOverlays();
      isPolygonDone.current = false;
      setIsSaveActive(false);
      setIsClearButtonActive(false);
      setHasUnsavedChanges(!!editedSafeZone);
    }
  };

  const drawExistingShape = () => {
    if (editedSafeZone && map) {
      setIsShapeSaved(true);
      setSelectedShape(editedSafeZone.shapeType);

      switch (editedSafeZone.shapeType) {
        case RegionsType.CIRCLE_TYPE:
          const circleCenter = new mapkit.Coordinate(
            editedSafeZone.lat,
            editedSafeZone.lng,
          );
          redrawCircle(circleCenter, editedSafeZone.radius, true, true);
          break;
        case RegionsType.PATH_TYPE:
          const pathPoints = editedSafeZone.geofence.map(
            ([lat, lng]) => new mapkit.Coordinate(lat, lng),
          );
          setTimeout(() => {
            redrawPath(pathPoints, editedSafeZone.radius, true, true);
          }, 500);
          break;
        case RegionsType.POLYGON_TYPE:
          const polygonPoints = editedSafeZone.geofence.map(
            ([lat, lng]) => new mapkit.Coordinate(lat, lng),
          );

          const hasIntersection = hasSelfIntersection([
            ...polygonPoints,
            polygonPoints[0],
          ]);

          setIsSelfIntersecting(hasIntersection);
          if (hasIntersection) {
            setIsSaveActive(false);
          }

          redrawPolygon(polygonPoints, true, true, true, hasIntersection);
          break;
      }
      setHasUnsavedChanges(false);
    }
  };

  useEffect(() => {
    if (editedSafeZone) {
      setIsShapeSaved(true);
    }

    if (map && map.annotations.length > 1) {
      if (selectedShape === RegionsType.CIRCLE_TYPE) {
        redrawCircle(map.annotations[1].coordinate, radiusRef.current);
        return;
      }

      if (selectedShape === RegionsType.PATH_TYPE) {
        redrawPath(
          map.annotations
            .slice(1)
            .filter(({ data }) => data.label !== 'searchPin')
            .map((m) => m.coordinate),
          radius,
        );
        return;
      }
    }
  }, [map === null]);

  const handleZoom = () => {
    if (!map) return;
    const markers = map?.annotations
      .filter(({ data }) => data.label !== 'searchPin')
      .slice(1);
    if (map && selectedShape === RegionsType.PATH_TYPE && markers.length > 0) {
      redrawPath(
        markers.map((m) => m.coordinate),
        radius,
        false,
        isShapeSaved,
      );
    }
  };

  const handleDiscard = useCallback(() => {
    if (editedSafeZone) {
      setRadius?.(editedSafeZone.radius);
      radiusRef.current = editedSafeZone.radius;
      isPolygonDone.current = !!editedSafeZone;
      setIsSaveActive(false);
      setIsShapeSaved(true);
      setIsClearButtonActive(!!editedSafeZone);
      setHasUnsavedChanges(false);
      handlePointMove(null);
      drawExistingShape();
      return;
    }
  }, [editedSafeZone, map, selectedShape]);

  const initiate = debounce(() => {
    radiusRef.current = editedSafeZone?.radius ?? radius;
    drawExistingShape();
  }, 1000);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Shift' && map) {
      document.getElementById('map')?.classList.remove('shift-pressed');
      window.removeEventListener('keyup', handleKeyUp);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Shift' && document) {
      document.getElementById('map')?.classList.add('shift-pressed');
      window.addEventListener('keyup', handleKeyUp);
    }
  };

  useEffect(() => {
    if (editedSafeZone) {
      setSelectedShape(editedSafeZone.shapeType);
    }
  }, [editedSafeZone]);

  useEffect(() => {
    if (map && editedSafeZone && selectedShape !== null) {
      initiate();
    }
  }, [map === null, editedSafeZone]);

  useEffect(() => {
    if (map) {
      map.addEventListener('zoom-end', handleZoom);
      map.addEventListener('long-press', handleLongPress);
      map.element.addEventListener('click', handleMapClick);
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('resize', updateOffset);
    }
    return () => {
      if (map) {
        map.removeEventListener('zoom-end', handleZoom);
        map.removeEventListener('long-press', handleLongPress);
        map.element.removeEventListener('click', handleMapClick);
      }
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', updateOffset);
    };
  }, [map, handleMapClick]);

  useEffect(() => {
    setSave(onSave ? externalSaveZone : handleSaveSafeZone);
  }, [onSave, selectedShape, radius, map]);

  useEffect(() => {
    setDiscard(handleDiscard);
  }, [handleDiscard]);

  useEffect(() => {
    if (!map) return;

    if (radius === radiusRef.current) return;
    radiusRef.current = radius;

    if (map.annotations[1]) {
      if (selectedShape === RegionsType.PATH_TYPE) {
        redrawPath(
          map.annotations.splice(1).map((a) => a.coordinate),
          radius,
        );
        return;
      }

      if (selectedShape === RegionsType.CIRCLE_TYPE) {
        redrawCircle(map.annotations[1].coordinate, radius);
      }
    }
  }, [radius]);

  useEffect(() => {
    if (
      title.length > 0 &&
      canBeRegionSaved(shapeRef.current, map?.annotations.length ?? 0)
    ) {
      setIsSaveActive(true);
      return;
    }
    setIsSaveActive(false);
  }, [title]);

  useEffect(() => {
    shapeRef.current = selectedShape;
  }, [selectedShape]);

  return {
    selectedShape,
    shapeRef,
    setSelectedShape,
    map,
    handleClearDrawing,
    handleSaveSafeZone,
    offset,
    handlePositionChange,
    handlePointClick,
    radiusRef,
    isClearButtonActive,
    isSelfIntersecting,
    handleCurrentRegionZoom,
    handleMapClick,
  };
};
