import Button, { ButtonProps } from '@mui/material/Button';
import { useEffect, useState } from 'react';

interface SubmitButtonProps extends ButtonProps {
  remainingTime?: number;
}

const SubmitButton = ({ remainingTime, ...rest }: SubmitButtonProps) => {
  const [seconds, setSeconds] = useState(remainingTime || 0);

  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return (
    <Button
      sx={{ m: 1 }}
      size="large"
      fullWidth
      disabled={seconds > 0}
      {...rest}
    >
      Send Code{seconds ? ` in ${seconds} seconds` : ''}
    </Button>
  );
};

export default SubmitButton;
