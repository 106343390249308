import { usePagination } from 'hooks/use-pagination';
import { routes } from 'nav';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { useLazyGetCustomRecordQuery } from 'store/api/dashboard.api';
import { setPaginationTotal } from 'store/reducers/filters/filters.slice';
import { PatientRecord } from 'types/patient';
import { useRecordsColumns } from './use-records-columns';

export const useRecordsTable = () => {
  const [isThrottling, setIsThrottling] = useState(true);
  const latestPageRef = useRef<number>(0);

  const {
    pageIndex: page,
    limit,
    offset,
    handlePaginationModelChange,
  } = usePagination(50, 0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { requestFields, columns } = useRecordsColumns();

  const [beginsAt, endsAt] = useAppSelector((state) => state.filters.dateRange);

  const { deviceId, profileId } = useAppSelector((state) => state.dashboard);

  if (!deviceId) {
    navigate(routes.private.patientsOverview.href);
  }

  const [
    fetchData,
    {
      data = {
        rows: [] as PatientRecord[],
        totalNumber: 0,
        displayedNumber: 0,
      },
      isFetching,
      isLoading,
    },
  ] = useLazyGetCustomRecordQuery({
    refetchOnReconnect: true,
  });

  const refetch = () => {
    fetchData({
      patient_profile_id: profileId,
      device_id: deviceId,
      begins_at: beginsAt ?? '',
      ends_at: endsAt ?? '',
      display_columns: requestFields,
      limit,
      offset,
    });

    setIsThrottling(false);
  };

  useEffect(() => {
    dispatch(setPaginationTotal(data.totalNumber));
  }, [data.totalNumber]);

  useEffect(() => {
    setIsThrottling(true);
    const lastRequest = setTimeout(refetch, 2000);
    if (page === latestPageRef.current) {
      handlePaginationModelChange({ page: 0, pageSize: limit });
      latestPageRef.current = 0;
    } else {
      latestPageRef.current = page;
    }
    return () => clearTimeout(lastRequest);
  }, [requestFields, beginsAt, endsAt, page, limit]);

  return {
    page,
    limit,
    columns,
    rows: data.rows,
    displayedNumber: data.displayedNumber,
    isLoading: isLoading || isFetching || isThrottling,
    handlePaginationModelChange,
    requestFields,
    totalNumber: data.totalNumber,
  };
};
