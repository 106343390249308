import { signal } from '@preact/signals-react';

export const isSidebarOpen = signal<boolean>(window.innerWidth >= 1300);

export const getSideBarState = () => isSidebarOpen.value;

export const toggleSidebar = () => {
  const mainClasslist = document.getElementById('main-content')?.classList;
  if (mainClasslist) {
    isSidebarOpen.value
      ? mainClasslist.remove('shrinked')
      : mainClasslist.add('shrinked');
  }
  isSidebarOpen.value = !isSidebarOpen.value;
};
