import config from 'env';
import { ProdEnv } from 'pages/Login/components/EnvChoose';
import { BCLocalStorage } from './storage';

const ALTERNATE_URL = config.REACT_APP_DOMAIN_ALTERNATE;

export const changeEnvironment = (env: ProdEnv, email: string) => {
  if (env === 'hipaa' && !config.NODE_ENV.includes('hipaa')) {
    BCLocalStorage.hipaa = true;
    if (!window.hasOwnProperty('cordova')) {
      window.location.href = `${ALTERNATE_URL}/login?email=${email}&redirected="true"`;
    }
    return;
  }

  if (env === 'personal' && config.NODE_ENV.includes('hipaa')) {
    BCLocalStorage.hipaa = false;
    if (!window.hasOwnProperty('cordova')) {
      window.location.href = `${ALTERNATE_URL}/login?email=${email}&redirected="true"`;
    }
  }
};
