import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const shield: IconifyJSON = {
  prefix: 'shield',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12 21.4807C9.83719 20.891 8.04649 19.6179 6.62791 17.6615C5.20932 15.7051 4.50003 13.5179 4.50003 11.1V5.34617L12 2.5385L19.5 5.34617V11.1C19.5 13.5179 18.7907 15.7051 17.3721 17.6615C15.9535 19.6179 14.1628 20.891 12 21.4807ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.375L12 4.13462L6.00001 6.375V11.1C6.00001 13.1167 6.56667 14.95 7.70001 16.6C8.83334 18.25 10.2667 19.35 12 19.9Z" fill="currentColor"/>',
    },
  },
};
