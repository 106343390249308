import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const notificationBell: IconifyJSON = {
  prefix: 'notification-bell',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M4.25 18.875V17.375H6.25V10.125C6.25 8.75838 6.671 7.55405 7.513 6.51205C8.35433 5.47072 9.43333 4.80005 10.75 4.50005V3.80005C10.75 3.45005 10.871 3.15405 11.113 2.91205C11.3543 2.67072 11.65 2.55005 12 2.55005C12.35 2.55005 12.6457 2.67072 12.887 2.91205C13.129 3.15405 13.25 3.45005 13.25 3.80005V4.50005C14.5667 4.80005 15.646 5.47072 16.488 6.51205C17.3293 7.55405 17.75 8.75838 17.75 10.125V17.375H19.75V18.875H4.25ZM12 21.8C11.5 21.8 11.075 21.625 10.725 21.275C10.375 20.925 10.2 20.5 10.2 20H13.8C13.8 20.5 13.625 20.925 13.275 21.275C12.925 21.625 12.5 21.8 12 21.8ZM7.75 17.375H16.25V10.125C16.25 8.94172 15.8373 7.93738 15.012 7.11205C14.1873 6.28738 13.1833 5.87505 12 5.87505C10.8167 5.87505 9.81267 6.28738 8.988 7.11205C8.16267 7.93738 7.75 8.94172 7.75 10.125V17.375Z" fill="currentColor"/>',
    },
    filled: {
      ...defaultSizes,
      body: '<path d="M4.25 18.875V17.375H6.25V10.125C6.25 8.75838 6.671 7.55405 7.513 6.51205C8.35433 5.47072 9.43333 4.80005 10.75 4.50005V3.80005C10.75 3.45005 10.871 3.15405 11.113 2.91205C11.3543 2.67072 11.65 2.55005 12 2.55005C12.35 2.55005 12.6457 2.67072 12.887 2.91205C13.129 3.15405 13.25 3.45005 13.25 3.80005V4.50005C14.5667 4.80005 15.646 5.47072 16.488 6.51205C17.3293 7.55405 17.75 8.75838 17.75 10.125V17.375H19.75V18.875H4.25ZM12 21.8C11.5 21.8 11.075 21.625 10.725 21.275C10.375 20.925 10.2 20.5 10.2 20H13.8C13.8 20.5 13.625 20.925 13.275 21.275C12.925 21.625 12.5 21.8 12 21.8Z" fill="currentColor"/>',
    },
  },
};
