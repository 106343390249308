import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirmation } from 'hooks/use-confirmation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import { useCreateReminderMutation } from 'store/api/reminders.api';
import { PatternType, SourceStreamType } from 'types/alerters';
import { getRecipientFieldsForAlertCreation } from 'utils/data-transformation';
import { getFirstName } from 'utils/get-first-name';
import * as yup from 'yup';

export interface CreateReminderForm {
  title: string;
  message: string;
  recipientSet: PatternType;
  streamType: SourceStreamType;
}

const schema = yup.object({
  title: yup.string().required('Title is required.').min(1),
  message: yup.string().required('Message is required.').min(1),
});

const DATA_SOURCES = [
  {
    value: 'any_wearable',
    label: 'Apple Watch',
  },
];

export const useCreateReminder = (onClose: () => void, title: string) => {
  const confirm = useConfirmation();
  const { handleSubmit, register, formState } = useForm<CreateReminderForm>({
    defaultValues: {
      title: 'Untitled',
      streamType: 'any_wearable',
      message: '',
      recipientSet: 'all_users',
    },
    resolver: yupResolver(schema),
  });

  const { profileId } = useParams();
  const { patientName } = useAppSelector((state) => state.dashboard);
  const [create] = useCreateReminderMutation();
  const [isDirty, setIsDirty] = useState(false);

  const setAsDirty = () => setIsDirty(true);

  const onSubmit = (data: CreateReminderForm) => {
    create({
      title: data.title,
      message: data.message,
      sourceId: parseInt(profileId ?? '0'),
      sourceType: 'PatientProfile',
      streamType: 'any_wearable',
      ...getRecipientFieldsForAlertCreation(
        data.recipientSet,
        true,
        title,
        getFirstName(patientName),
      ),
    })
      .then(() => {
        toast.success('The reminder was created successfully.');
      })
      .catch(() => {
        toast.error('An error occured.');
      });
    onClose();
  };

  const handleModalClose = () => {
    if (isDirty) {
      confirm(
        {
          title: 'Are you sure you want to cancel creating this reminder?',
          message: 'If you cancel now, you will lose all of your progress.',
          rejectButtonLabel: 'Go back',
          variant: 'danger',
        },
        onClose,
      );
      return;
    }
    onClose();
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    sources: DATA_SOURCES,
    errors: formState.errors,
    handleModalClose,
    setAsDirty,
  };
};
