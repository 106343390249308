import { useEffect, useState } from 'react';
import { Typography, Link } from '@mui/material';

interface Props {
  setTimer: (v: number) => void;
  onResend?: () => void;
  isCodeResent?: boolean;
}

const getInitialValue = () => {
  const timestamp = localStorage.getItem('resentAt');
  if (timestamp) {
    const difference = Math.floor(
      (new Date().getTime() - parseInt(timestamp)) / 1000,
    );
    if (difference <= 59) {
      return 60 - difference;
    }
    localStorage.removeItem('resentAt');
  }

  localStorage.setItem('resentAt', new Date().getTime().toString());
  return 59;
};

const ResendMessage = ({ setTimer, onResend, isCodeResent }: Props) => {
  const [canResend, setCanResend] = useState(false);
  const [counter, setCounter] = useState(getInitialValue());

  useEffect(() => {
    setTimeout(() => {
      if (counter >= 1) {
        setTimer(counter - 1);
        setCounter((counter) => counter - 1);
        if (counter === 1) {
          setCanResend(true);
          localStorage.removeItem('resentAt');
        }
        return;
      }
    }, 1000);
  }, [counter]);

  useEffect(() => {
    if (isCodeResent) {
      setCounter(59);
      setCanResend(false);
      localStorage.setItem('resentAt', new Date().getTime().toString());
    }
  }, [isCodeResent]);

  return (
    <Typography variant="body2" align="center">
      It may take a minute to receive your code. <br />
      Didn&apos;t receive the code? {}
      {canResend ? (
        onResend ? (
          <Link
            sx={{ color: ({ palette }) => palette.red.main }}
            onClick={onResend}
          >
            Resend it
          </Link>
        ) : null
      ) : (
        `Resend it in ${counter} seconds.`
      )}
    </Typography>
  );
};

export default ResendMessage;
