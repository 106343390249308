import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const devices: IconifyJSON = {
  prefix: 'devices',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M4.21156 5.25H12.2116V4.3077C12.2116 4.23077 12.1795 4.16024 12.1154 4.09613C12.0513 4.03203 11.9808 3.99998 11.9039 3.99998H4.51928C4.44235 3.99998 4.37183 4.03203 4.30773 4.09613C4.24362 4.16024 4.21156 4.23077 4.21156 4.3077V5.25ZM4.51928 21.5C4.01415 21.5 3.58658 21.325 3.23658 20.975C2.8866 20.625 2.71161 20.1974 2.71161 19.6923V4.3077C2.71161 3.80257 2.8866 3.375 3.23658 3.025C3.58658 2.675 4.01415 2.5 4.51928 2.5H11.9039C12.409 2.5 12.8365 2.675 13.1865 3.025C13.5365 3.375 13.7115 3.80257 13.7115 4.3077V8.08075C13.4244 8.23588 13.1574 8.40736 12.9106 8.59518C12.6638 8.78299 12.4308 8.99036 12.2116 9.21728V6.74995H4.21156V17.25H10.55C10.6577 17.5269 10.7798 17.7888 10.9164 18.0356C11.0529 18.2823 11.209 18.5205 11.3846 18.75H4.21156V19.6923C4.21156 19.7692 4.24362 19.8397 4.30773 19.9038C4.37183 19.9679 4.44235 20 4.51928 20H12.3462V19.7538C12.5 19.9282 12.6641 20.0871 12.8385 20.2307C13.0128 20.3743 13.2026 20.5025 13.4077 20.6153C13.2436 20.8833 13.0301 21.0977 12.7673 21.2586C12.5045 21.4195 12.2006 21.5 11.8558 21.5H4.51928ZM17.2115 18C18.1974 18 19.0369 17.6535 19.7298 16.9606C20.4228 16.2676 20.7692 15.4282 20.7692 14.4423C20.7692 13.4564 20.4228 12.617 19.7298 11.924C19.0369 11.2311 18.1974 10.8846 17.2115 10.8846C16.2256 10.8846 15.3862 11.2311 14.6933 11.924C14.0003 12.617 13.6538 13.4564 13.6538 14.4423C13.6538 15.4282 14.0003 16.2676 14.6933 16.9606C15.3862 17.6535 16.2256 18 17.2115 18ZM14.9615 21.5V18.9769C14.1192 18.5653 13.4407 17.9583 12.926 17.1557C12.4112 16.3532 12.1539 15.4487 12.1539 14.4423C12.1539 13.4359 12.4112 12.5314 12.926 11.7288C13.4407 10.9263 14.1192 10.3192 14.9615 9.9077V7.38463H19.4615V9.9077C20.3038 10.3192 20.9823 10.9263 21.4971 11.7288C22.0118 12.5314 22.2692 13.4359 22.2692 14.4423C22.2692 15.4487 22.0118 16.3532 21.4971 17.1557C20.9823 17.9583 20.3038 18.5653 19.4615 18.9769V21.5H14.9615Z" fill="currentColor"/>',
    },
    filled: {
      ...defaultSizes,
      body: '<path d="M17.2115 18C18.1974 18 19.0369 17.6535 19.7298 16.9606C20.4228 16.2676 20.7692 15.4282 20.7692 14.4423C20.7692 13.4564 20.4228 12.617 19.7298 11.924C19.0369 11.2311 18.1974 10.8846 17.2115 10.8846C16.2256 10.8846 15.3862 11.2311 14.6933 11.924C14.0003 12.617 13.6538 13.4564 13.6538 14.4423C13.6538 15.4282 14.0003 16.2676 14.6933 16.9606C15.3862 17.6535 16.2256 18 17.2115 18ZM4.51928 21.5C4.02217 21.5 3.59661 21.3229 3.24261 20.9689C2.88861 20.6149 2.71161 20.1894 2.71161 19.6923V4.3077C2.71161 3.81058 2.88861 3.38503 3.24261 3.03103C3.59661 2.67701 4.02217 2.5 4.51928 2.5H11.9039C12.401 2.5 12.8265 2.67701 13.1805 3.03103C13.5345 3.38503 13.7115 3.81058 13.7115 4.3077V8.08075C13.4244 8.23588 13.1574 8.40736 12.9106 8.59518C12.6638 8.78299 12.4308 8.99036 12.2116 9.21728V6.74995H4.21156V17.25H10.55C10.8141 17.959 11.1942 18.5974 11.6904 19.1654C12.1865 19.7333 12.759 20.2166 13.4077 20.6153C13.2428 20.8955 13.0216 21.1129 12.7442 21.2677C12.4668 21.4225 12.1706 21.5 11.8558 21.5H4.51928ZM14.9615 21.5V18.9769C14.0923 18.5487 13.4071 17.9291 12.9058 17.1182C12.4045 16.3073 12.1539 15.4153 12.1539 14.4423C12.1539 13.4692 12.4045 12.5772 12.9058 11.7663C13.4071 10.9554 14.0923 10.3359 14.9615 9.9077V7.38463H19.4615V9.9077C20.3307 10.3359 21.016 10.9554 21.5173 11.7663C22.0185 12.5772 22.2692 13.4692 22.2692 14.4423C22.2692 15.4153 22.0185 16.3073 21.5173 17.1182C21.016 17.9291 20.3307 18.5487 19.4615 18.9769V21.5H14.9615Z" fill="currentColor"/>',
    },
  },
};
