import { Box, CircularProgress } from '@mui/material';
import { useConfigCheck } from 'pages/PatientAccount/hooks/use-config-check';

const ConfigurationLoader = ({
  sentAt,
  handleNextStep,
}: {
  sentAt: string;
  handleNextStep: () => void;
}) => {
  useConfigCheck(handleNextStep, sentAt);

  return (
    <Box mx="auto" width="fit-content" p={1} pb={4}>
      <CircularProgress size="80px" />
    </Box>
  );
};

export default ConfigurationLoader;
