import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const expandMore: IconifyJSON = {
  prefix: 'expand-more',
  icons: {
    normal: {
      ...defaultSizes,
      body: `
      <mask id="mask0_8959_5283" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_8959_5283)">
      <path d="M12 15.0384L6.34616 9.38463L7.39999 8.3308L12 12.9308L16.6 8.3308L17.6538 9.38463L12 15.0384Z" fill="currentColor"/>
      </g>
      `,
    },
  },
};
