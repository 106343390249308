export const RegionsType = {
  CIRCLE_TYPE: 0,
  PATH_TYPE: 1,
  POLYGON_TYPE: 2,
} as const;

export type RegionsType = (typeof RegionsType)[keyof typeof RegionsType];

export const mappedFormattedRegions = {
  [RegionsType.CIRCLE_TYPE]: 'circleRegions',
  [RegionsType.PATH_TYPE]: 'pathRegions',
  [RegionsType.POLYGON_TYPE]: 'polygonRegions',
} as const;

export interface SafeZoneParams {
  id: number;
  title: string;
  lat: number;
  lng: number;
  radius?: number;
  shapeType: RegionsType;
  geofence?: number[][];
}

export interface SafeZone {
  id: number;
  accountId: number;
  label: string;
  notifications: boolean;
  lat: number;
  lng: number;
  radius: number;
  createdAt: string;
  updatedAt: string;
  shapeType: RegionsType;
  geofence: number[][];
  snapshotUrl: string | null;
}

export interface RawSafeZone {
  id: number;
  account_id: number;
  label: string;
  notifications: boolean;
  lat: number;
  lng: number;
  radius: number;
  created_at: string;
  updated_at: string;
  shape_type: RegionsType;
  geofence: string | null;
  preview_snapshot_url: string | null;
}

export interface CreateSafeZoneParams {
  accountId: number;
  name: string;
  notifications: boolean;
  shapeType: RegionsType;
  lat?: number;
  lng?: number;
  radius?: number;
  geofence?: number[][];
}

export interface EditSafeZoneParams extends Partial<CreateSafeZoneParams> {
  id: number;
}

export interface RawGetSafeZonesResponse {
  results: RawSafeZone[];
}

export interface SafeZoneResponse {
  result: RawSafeZone;
}

export interface FormattedRegions {
  circleRegions: SafeZone[];
  pathRegions: SafeZone[];
  polygonRegions: SafeZone[];
}

export interface Overlay {
  points: string[] | string;
  strokeColor?: string;
  lineWidth?: number;
  radius?: number;
  lineDash?: [number, number];
}

export interface CreateSnapshotArgs {
  lat: number;
  lng: number;
  // The format should be "[width]x[height]" (default: "600x400").
  // The range is between 50 and 640.
  size?: string;
  // Zoom level. The range is between 3 and 20.
  z?: number;
  scale?: 1 | 2 | 3;
  // Map type. One of the following values:
  t?: 'standard' | 'satellite' | 'mutedStandard' | 'hybrid';
  colorScheme?: 'light' | 'dark';
  overlays?: Overlay[];
}
