import { Icon } from '@iconify/react';
import { styled, Typography, useTheme } from '@mui/material';
import { DataCellProps } from 'models/population-overview.model';
import { getColorByStatus } from 'utils/get-color-by-status';

const TextWrapper = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const DataCell: React.FC<DataCellProps> = ({ icon, label, status }) => {
  const { palette } = useTheme();
  const { iconColor, textColor } = getColorByStatus(status, palette, true);

  return (
    <Typography
      display="flex"
      alignItems="center"
      py={2}
      gap={1}
      overflow="hidden"
      color={textColor}
    >
      <Icon
        color={iconColor}
        style={{ minWidth: '28px', minHeight: '28px' }}
        width={28}
        height={28}
        icon={icon}
      />
      <TextWrapper>{label}</TextWrapper>
    </Typography>
  );
};

export default DataCell;
