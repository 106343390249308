import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useSelectCredentials from './hooks/use-select-credentials';
import ConfirmationMethod from './components/ConfirmationMethod';
import { CredentialType } from 'models/auth.model';
import { Navigate } from 'react-router-dom';
import { routes } from 'nav';
import { CircularProgress } from '@mui/material';

const MfaLogin = () => {
  const {
    credentialList,
    selectedMethod,
    handleChange,
    handleSubmit,
    isLoading,
  } = useSelectCredentials();

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    );

  if (credentialList?.length === 0) {
    return <Navigate to={routes.mfa.setup.href} />;
  }

  return (
    <Box>
      <Typography variant="h5" textAlign="center">
        Verify it's you
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color="blue.main"
        mx={2}
        mb={4}
      >
        Choose one of the verification methods below:
      </Typography>
      <form>
        {credentialList?.map((option) => (
          <ConfirmationMethod
            key={option.id}
            identifier={option.identifier}
            schema={option.schema as CredentialType}
            value={option.id}
            checked={option.id === selectedMethod}
            handleChange={(e) => {
              handleChange(e, option);
            }}
          />
        ))}
        <Box p={1} pt={3}>
          <Button size="large" fullWidth onClick={handleSubmit}>
            Send Code
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default MfaLogin;
