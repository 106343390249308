import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { useUpdateAlerterChannelSetMutation } from 'store/api/alerters.api';
import { ChannelSet } from 'types/alerters';
import * as yup from 'yup';
import { transformChannelSet } from 'utils/data-transformation';
import {
  useGetReminderInfoQuery,
  useUpdateReminderMutation,
} from 'store/api/reminders.api';
import {
  setReminderSettingsAsModified,
  setReminderSettingsAsUntouched,
  updateReminderSettingsState,
} from 'store/reducers/reminder/reminder.slice';
import { SettingsContext } from '../ReminderDetails';
import { useNavigationBlocker } from 'hooks/use-navigation-blocker';
import { isFileTypeImage } from 'utils/is-file-type-image';
import { MixedSchema } from 'yup/lib/mixed';
import { fileToBase64 } from 'utils/file-to-base64';
import { caregiverRole } from 'services/caregiver-role';

export interface SettingsForm {
  title: string;
  message: string;
  channels: ChannelSet[];
  messageImage?: File;
  messageImageUrl: string | null;
}

const schema = yup.object({
  title: yup.string().required('Title is required.'),
  message: yup.string().required('Message is required.'),
  channels: yup
    .array()
    .typeError('At least one channel must be selected.')
    .min(1, 'At least one channel must be selected.'),
  messageImage: yup
    .mixed()
    .optional()
    .when('$file', (file: File | undefined, schema: MixedSchema) =>
      file
        ? schema.test(
            'is-valid-type',
            'Not a valid image type',
            (value: File) =>
              !!value && isFileTypeImage(value.name.toLowerCase(), 'image'),
          )
        : schema,
    ),
});

const getErrorBody = (condition: boolean, message: string) => {
  if (condition === true) {
    return {
      type: 'required',
      message,
    };
  }

  return undefined;
};

export const useSettings = () => {
  const [errors, setErrors] = useState<FieldErrorsImpl<SettingsForm>>({});

  const dispatch = useAppDispatch();
  const { reminderId } = useParams();

  const { setSubmit } = useContext(SettingsContext);

  const defaultSettings = useAppSelector((state) => state.reminder.settings);
  const { isLoading, isFetching } = useGetReminderInfoQuery(Number(reminderId));
  const [update] = useUpdateReminderMutation();
  const [updateChannels] = useUpdateAlerterChannelSetMutation();

  const methods = useForm<SettingsForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      channels: defaultSettings.channels,
      title: defaultSettings.title ?? '',
      message: defaultSettings.message ?? '',
      messageImageUrl: defaultSettings.messageImageUrl || '',
    },
    mode: 'onChange',
  });

  const discardChanges = () => {
    dispatch(setReminderSettingsAsUntouched());
    methods.reset({
      channels: defaultSettings.channels,
      title: defaultSettings.title ?? '',
      message: defaultSettings.message ?? '',
    });
  };

  useNavigationBlocker(methods.formState.isDirty, {
    title: 'You have unsaved changes.',
    message: 'Do you want to discard them?',
    onDiscard: discardChanges,
  });

  const onSubmit = async (data: SettingsForm) => {
    const base64Image = await fileToBase64(data.messageImage);

    onError({});

    update({
      title: data.title,
      message: data.message || '',
      id: Number(reminderId),
      messageImage:
        defaultSettings.messageImageUrl === data.messageImageUrl
          ? undefined
          : base64Image,
      messageImageUrl:
        defaultSettings.messageImageUrl === data.messageImageUrl
          ? undefined
          : data.messageImageUrl || null,
    })
      .unwrap()
      .then(({ result }) => {
        if (data) {
          toast('Alert settings were successfully updated.', {
            type: 'success',
          });
          dispatch(setReminderSettingsAsUntouched());
          dispatch(
            updateReminderSettingsState({
              channels: transformChannelSet(
                result.alerter_channel_set.channels,
              ),
              message: result.custom_message_template || '',
              title: result.title,
              messageImageUrl: result.message_image_url,
            }),
          );
        }
      })
      .catch(() => {
        toast('An error occured.', { type: 'error' });
      });

    if (
      defaultSettings.channelSetId &&
      data.channels.sort().join(',') !==
        [...defaultSettings.channels].sort().join(',')
    ) {
      updateChannels({
        channels: data.channels,
        id: defaultSettings.channelSetId,
      });
    }

    methods.reset({}, { keepValues: true });
  };

  const onError: SubmitErrorHandler<SettingsForm> = (errors) => {
    setErrors(errors);
  };

  useEffect(() => {
    setSubmit(() => {
      const { channels, title, message } = methods.getValues();

      const errors = {
        title: getErrorBody(title.length === 0, 'Title is required'),
        channels: getErrorBody(
          channels.length === 0,
          'At least one channel should be selected.',
        ),
        message: getErrorBody(
          message.length === 0,
          'Notification message is required.',
        ),
      };

      onError(errors);
      if (
        Object.keys(errors).filter((key) => errors[key as keyof typeof errors])
          .length > 0
      ) {
        return;
      }

      onSubmit(methods.getValues());
      return 0;
    });
  }, []);

  useEffect(() => {
    if (methods.formState.isDirty) {
      dispatch(setReminderSettingsAsModified());
    }
  }, [methods.formState.isDirty]);

  return {
    methods,
    isLoading: isLoading || isFetching,
    handleSubmit: methods.handleSubmit(onSubmit, onError),
    errors,
    discardChanges,
    readOnly: caregiverRole.value === 'viewer',
  };
};
