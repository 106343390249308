import { Box, Switch, Typography } from '@mui/material';
import { useMfaConfig } from '../hooks/use-mfa-config';
import MfaSetup from 'components/Mfa/MfaSetup';
import { BasicModal } from 'components/BasicModal';
import { Mfa } from 'components/Mfa';
import { HelpIcon } from 'components/HelpIcon';

const MfaToggle = () => {
  const {
    isMfaEnabled,
    handleEnable,
    handleDisable,
    isLoading,
    handleCredentialConfigureFinish,
    handleCredentialConfigureAbort,
    shouldConfigureCredentials,
    shouldPassMfa,
    handlePassMfaFinish,
    handlePassMfaAbort,
  } = useMfaConfig();

  const handleToggleSwitch = () => {
    if (isMfaEnabled) {
      handleDisable();
      return;
    }

    handleEnable();
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Typography color="primary" py={1}>
          Require 2FA
        </Typography>
        <HelpIcon message="Two-factor authentication (2FA) adds an extra layer of protection to your account by requiring you to enter a one-time passcode when accessing your account." />
      </Box>
      <Switch
        disabled={isLoading}
        checked={isMfaEnabled}
        onChange={handleToggleSwitch}
        color="secondary"
        sx={{ ml: 1 }}
      />
      <BasicModal
        open={shouldConfigureCredentials}
        onClose={handleCredentialConfigureAbort}
      >
        <MfaSetup onCompletion={handleCredentialConfigureFinish} activateMfa />
      </BasicModal>
      <BasicModal open={shouldPassMfa} onClose={handlePassMfaAbort}>
        <Mfa onCompletion={handlePassMfaFinish} activateMfa={false} />
      </BasicModal>
    </Box>
  );
};

export default MfaToggle;
