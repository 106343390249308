import { Icon } from '@iconify/react';
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { addKey, idCard } from 'assets/iconify';
import VInput from 'components/VInput';
import NavigationButtons from '../NavigationButtons';
import { useContext, useEffect, useState } from 'react';
import { ProfileConfiguratorContext } from '../ProfileConfigurator';
import { useCreateManagedProfileMutation } from 'store/api/admin.api';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import { useAdminStatusQuery } from 'store/api/auth.api';

const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  gap: theme.spacing(2),
  height: '100%',
}));

const Card = styled('div')(({ theme: { spacing, palette, breakpoints } }) => ({
  border: `1px solid ${palette.gray.light}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: spacing(1),
  padding: spacing(4),
  [breakpoints.down('newMd')]: {
    padding: spacing(3),
  },
  [breakpoints.down('sm')]: {
    padding: spacing(2),
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

const BCIdentifier = () => {
  const { accountId } = useAppSelector((state) => state.user);

  const { data: isAdmin } = useAdminStatusQuery(
    {
      userId: accountId as number,
    },
    {
      skip: !accountId,
    },
  );
  const { setProfileConfiguration, identifierErrorMessage } = useContext(
    ProfileConfiguratorContext,
  );

  const [identifier, setIdentifier] = useState('');

  const [create, { isLoading, isSuccess, data }] =
    useCreateManagedProfileMutation();

  const onNextClick = () => {
    setProfileConfiguration((prev) => ({
      ...prev,
      bcIdentifier: identifier,
    }));
    setIdentifier('');
  };

  const onGenerateClick = () => {
    create({});
    setIdentifier('Loading...');
  };

  useEffect(() => {
    if (isSuccess && data) {
      setIdentifier(data.result.unique_public_identifier);
      toast.success(
        'Identifier was successfully created and sent to your email address.',
      );
    }
  }, [isSuccess]);

  return (
    <Root>
      <Box
        sx={{
          pr: { xxs: 1.5, xs: 2, sm: 3, newMd: 4 },
          pt: { xxs: 1.5, xs: 2, sm: 3, newMd: 4 },
        }}
      >
        <Card>
          <StyledIcon icon={idCard.icons.normal} />
          <Typography
            textAlign="center"
            variant="h6"
            sx={{ my: 1, fontWeight: 500 }}
          >
            Configure a BoundaryCare Profile
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="center"
            sx={{ maxWidth: ({ spacing }) => spacing(75) }}
          >
            Enter the 10-digit configuration code (including any leading zeros)
            that is displayed on the screen of the <strong>BoundaryCare</strong>{' '}
            Watch you want to activate, or that was included in the{' '}
            <strong>BoundaryCare</strong> kit’s package.
          </Typography>
          <Box mt={3} mb={1} width="100%">
            <VInput
              label="BoundaryCare Identifier"
              fullWidth
              disabled={isLoading}
              placeholder="9876543210"
              disableWhitespace
              value={identifier}
              error={!!identifierErrorMessage}
              helperText={identifierErrorMessage}
              onChange={(e) => setIdentifier(e.target.value)}
              containerSx={{
                mx: 'auto',
                maxWidth: ({ spacing }) => spacing(54),
                width: '100%',
              }}
              endAdornment={
                isAdmin ? (
                  <span>
                    <Tooltip title={isSuccess ? '' : 'Create Id'}>
                      <span>
                        <IconButton
                          color="primary"
                          onClick={onGenerateClick}
                          disabled={isLoading || isSuccess}
                          sx={{
                            p: 0.5,
                            mr: -0.5,
                          }}
                        >
                          <Icon icon={addKey.icons.normal} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </span>
                ) : undefined
              }
            />
          </Box>
        </Card>
      </Box>
      <NavigationButtons
        hidePreviousButton
        nextButtonText="Continue"
        onNextClick={onNextClick}
        nextButtonDisabled={identifier.length !== 10 || isLoading}
      />
    </Root>
  );
};

export default BCIdentifier;
