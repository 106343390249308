import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const copy: IconifyJSON = {
  prefix: 'copy',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M5.75006 21.3076C5.24494 21.3076 4.81738 21.1326 4.46738 20.7826C4.11738 20.4326 3.94238 20.0051 3.94238 19.5V6.80774H5.44236V19.5C5.44236 19.5769 5.47441 19.6474 5.53851 19.7115C5.60261 19.7756 5.67312 19.8077 5.75006 19.8077H15.4423V21.3076H5.75006ZM9.25003 17.8077C8.7449 17.8077 8.31733 17.6327 7.96733 17.2827C7.61735 16.9327 7.44236 16.5051 7.44236 16V4.61541C7.44236 4.1103 7.61735 3.68274 7.96733 3.33274C8.31733 2.98274 8.7449 2.80774 9.25003 2.80774H17.6346C18.1397 2.80774 18.5673 2.98274 18.9173 3.33274C19.2673 3.68274 19.4423 4.1103 19.4423 4.61541V16C19.4423 16.5051 19.2673 16.9327 18.9173 17.2827C18.5673 17.6327 18.1397 17.8077 17.6346 17.8077H9.25003ZM9.25003 16.3077H17.6346C17.7115 16.3077 17.782 16.2757 17.8462 16.2115C17.9103 16.1474 17.9423 16.0769 17.9423 16V4.61541C17.9423 4.53848 17.9103 4.46796 17.8462 4.40386C17.782 4.33976 17.7115 4.30771 17.6346 4.30771H9.25003C9.1731 4.30771 9.10258 4.33976 9.03848 4.40386C8.97437 4.46796 8.94231 4.53848 8.94231 4.61541V16C8.94231 16.0769 8.97437 16.1474 9.03848 16.2115C9.10258 16.2757 9.1731 16.3077 9.25003 16.3077Z" fill="currentColor"/>',
    },
  },
};
