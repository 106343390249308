import { Box, Button, ModalProps, Typography, styled } from '@mui/material';
import { BasicModal } from 'components/BasicModal';
import { CustomPhoneInput } from 'components/CustomPhoneInput';
import { usePhoneNumberSetup } from 'pages/CaregiverSettings/hooks/use-phone-number-setup';
import CodeConfirmation from './CodeConfirmation';
import { CompletionMessage } from 'components/CompletionMessage';

type Props = Omit<ModalProps, 'children'> & {
  phoneNumber?: string;
  existingCredentialId?: number;
  onClose?: () => void;
};

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const getSuccessTitle = (isEdit: boolean) => {
  return isEdit
    ? 'Phone number updated successfully!'
    : 'Phone number added successfully!';
};

const getSuccessMessage = (isEdit: boolean) => {
  return isEdit ? (
    'Your old number is no longer associated with your BoundaryCare account'
  ) : (
    <>
      You can now be added as a profile’s emergency contact, and choose to
      receive your <strong>BoundaryCare</strong> alerts as text messages or
      automated calls.
    </>
  );
};

const NumberSetupModal = ({
  phoneNumber,
  existingCredentialId,
  ...props
}: Props) => {
  const {
    isLoading,
    isSubmitDisabled,
    handleChange,
    error,
    handleSubmit,
    wasConfirmationSent,
    phoneNumberRef,
    backToNumberEditing,
    resendSecondsLeft,
    canSend,
    credentialId,
    setResendSecondsLeft,
    handleNumberConfirmation,
    wasNumberConfirmed,
    couldNotSendCode,
    onConfirmationButtonClick,
  } = usePhoneNumberSetup(phoneNumber, existingCredentialId);

  return (
    <BasicModal
      hideCloseButton={wasNumberConfirmed || couldNotSendCode}
      {...props}
    >
      <Root>
        {wasNumberConfirmed || couldNotSendCode ? (
          <CompletionMessage
            sx={{ p: 0 }}
            status={wasNumberConfirmed ? 'success' : 'error'}
            title={
              wasNumberConfirmed
                ? getSuccessTitle(!!phoneNumber)
                : 'Something went wrong...'
            }
            subtitle={
              wasNumberConfirmed
                ? getSuccessMessage(!!phoneNumber)
                : 'An unknown error occurred. Please try again later. If the problem persist, please contact support team.'
            }
            onButtonClick={() => {
              onConfirmationButtonClick();
              props.onClose?.();
            }}
          />
        ) : wasConfirmationSent === false ? (
          <>
            <Typography variant="h5">
              {phoneNumber ? 'Update' : 'Add'} your phone number
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="blue.main"
              px={3}
              pb={4}
            >
              Provided phone number will be used as one of the two-factor
              authentication methods
            </Typography>
            <Box width="100%" p={1} pt={1.5}>
              <CustomPhoneInput
                onChange={(value) => handleChange(value)}
                containerProps={{ sx: { width: '100%' } }}
                label="Phone number"
                value={phoneNumberRef.current}
                error={
                  error
                    ? {
                        type: 'validate',
                        message: error,
                      }
                    : undefined
                }
              />
            </Box>
            <Typography variant="body2" color="red.main" px={1} pt={2}>
              We will send a 6 digit code on your phone number.
            </Typography>
            <Box p={1} pt={3} width="100%">
              <Button
                disabled={isLoading || isSubmitDisabled || !canSend}
                onClick={handleSubmit}
                fullWidth
              >
                {resendSecondsLeft
                  ? `Send Code in ${resendSecondsLeft} second${
                      resendSecondsLeft === 1 ? '' : 's'
                    }`
                  : 'Send Code'}
              </Button>
            </Box>
          </>
        ) : (
          <CodeConfirmation
            phoneNumber={phoneNumberRef.current}
            onPrevStep={backToNumberEditing}
            secondsLeft={resendSecondsLeft}
            credentialId={credentialId}
            setSecondsLeft={setResendSecondsLeft}
            onConfirmation={handleNumberConfirmation}
          />
        )}
      </Root>
    </BasicModal>
  );
};

export default NumberSetupModal;
