import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from '@mui/material';
import { ChannelSet } from 'types/alerters';
import { HelperText } from 'pages/AlerterDetails/components/Settings/styled';
import { HelpIcon } from 'components/HelpIcon';

interface Props {
  defaultChannels: ChannelSet[];
  disabled?: boolean;
  onChange: (channels: ChannelSet[]) => void;
  setOnReset?: (value: () => void) => void;
}

const Group = styled(FormGroup)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  paddingLeft: theme.spacing(1),
}));

const Channels = ({
  defaultChannels,
  disabled,
  onChange,
  setOnReset,
}: Props) => {
  const [channels, setChannels] = useState<ChannelSet[]>(defaultChannels);

  const resetChannels = () => {
    setChannels(defaultChannels);
  };

  useEffect(() => {
    setOnReset?.(resetChannels);
  }, [defaultChannels]);

  const handleChange = (value: ChannelSet, checked: boolean) => {
    if (checked) {
      updateValues(channels.concat(value));
      return;
    }
    if (value === ChannelSet.PUSH && !checked) {
      updateValues(
        channels.filter(
          (channel) => channel !== ChannelSet.CRITICAL && channel !== value,
        ),
      );
      return;
    }
    updateValues(channels.filter((c) => c !== value));
  };

  const updateValues = (values: ChannelSet[]) => {
    setChannels(values);
    onChange(values);
  };

  const isCriticalDisabled = !channels.includes(ChannelSet.PUSH);

  return (
    <Box p={2} px={{ xxs: 1, newMd: 2 }}>
      <Typography color="primary" variant="body1" mb={1}>
        Channels
      </Typography>
      <Group row>
        <FormControlLabel
          control={<Checkbox checked={channels.includes(ChannelSet.EMAIL)} />}
          label="Email"
          labelPlacement="end"
          disabled={disabled}
          onChange={(e) =>
            handleChange(
              ChannelSet.EMAIL,
              (e.target as HTMLInputElement).checked,
            )
          }
          value={ChannelSet.EMAIL}
        />
        <FormControlLabel
          label="SMS"
          labelPlacement="end"
          control={<Checkbox checked={channels.includes(ChannelSet.SMS)} />}
          disabled={disabled}
          onChange={(e) =>
            handleChange(ChannelSet.SMS, (e.target as HTMLInputElement).checked)
          }
          value={ChannelSet.SMS}
        />
        <FormControlLabel
          label="Automated Call"
          labelPlacement="end"
          control={
            <Checkbox checked={channels.includes(ChannelSet.AUTOMATED_CALL)} />
          }
          onChange={(e) =>
            handleChange(
              ChannelSet.AUTOMATED_CALL,
              (e.target as HTMLInputElement).checked,
            )
          }
          value={ChannelSet.AUTOMATED_CALL}
        />
        <Box
          sx={{
            marginTop: { xxs: 1, xs: 0 },
          }}
        >
          <FormControlLabel
            sx={{ display: 'flex' }}
            label="Push Notifications"
            labelPlacement="end"
            control={<Checkbox checked={channels.includes(ChannelSet.PUSH)} />}
            disabled={disabled}
            onChange={(e) =>
              handleChange(
                ChannelSet.PUSH,
                (e.target as HTMLInputElement).checked,
              )
            }
            value={ChannelSet.PUSH}
          />
          <Box display="flex">
            <FormControlLabel
              control={
                <Checkbox checked={channels.includes(ChannelSet.CRITICAL)} />
              }
              sx={{
                pl: 3,
                mr: 1,
                display: 'flex',
                maxHeight: ({ spacing }) => spacing(3),
              }}
              label="Critical Alerts"
              labelPlacement="end"
              disabled={isCriticalDisabled || disabled}
              onChange={(e) =>
                handleChange(
                  ChannelSet.CRITICAL,
                  (e.target as HTMLInputElement).checked,
                )
              }
              value={ChannelSet.CRITICAL}
            />
            <HelpIcon
              message="Critical alerts ignore the mute switch and Do Not Disturb; the system plays a critical alert's sound regardless of the device's mute or Do Not Disturb settings."
              tooltipPlacement="top-start"
            />
          </Box>
        </Box>
      </Group>
      {channels.length === 0 && (
        <HelperText error>At least one channel must be selected.</HelperText>
      )}
    </Box>
  );
};

export default Channels;
