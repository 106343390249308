import { Box, Typography, useTheme } from '@mui/material';
import TitleLogo from 'components/TitleLogo';
interface Props {
  inSidebar?: boolean;
  hideOnSmallScreens?: boolean;
}

const TopBarLogo = ({ inSidebar, hideOnSmallScreens }: Props) => {
  const theme = useTheme();
  const additionalSx = hideOnSmallScreens
    ? { display: { xxs: 'none', newXl: 'block' } }
    : {};

  return (
    <Box display="flex" alignItems="center" sx={{ userSelect: 'none' }}>
      <Box mr={1}>
        <TitleLogo size={32} />
      </Box>
      <Typography
        sx={{ pb: 0, ...additionalSx }}
        variant="h5"
        color={inSidebar ? theme.palette.common.white : 'primary'}
      >
        Boundary
      </Typography>
      <Typography
        sx={{ pb: 0, ...additionalSx }}
        variant="h5"
        color="secondary"
      >
        Care
      </Typography>
    </Box>
  );
};

export default TopBarLogo;
