import { Icon } from '@iconify/react';
import { Box, Typography, useTheme, styled } from '@mui/material';
import { CredentialType } from 'models/auth.model';
import { routes } from 'nav';
import { Link } from 'react-router-dom';

const hideIdentifier = (identifier: string, schema: CredentialType) => {
  if (schema === 'email') {
    const [firstPart, secondPart] = identifier.split('@');
    return `${firstPart[0]}*******${
      firstPart[firstPart.length - 1]
    }@${secondPart}`;
  }

  const hidden = identifier.replace(/[0-9]/g, '•').slice(1);

  return (
    hidden.slice(0, hidden.length - 3) + identifier.slice(hidden.length - 2)
  );
};

const getCodeDestination = (to: CredentialType) =>
  to === 'email' ? 'email address' : 'phone number';

interface IProps {
  identifier?: string;
  isEditable?: boolean;
  schema: CredentialType;
  remainingTime?: number;
}

const CustomLink = styled(Link)(({ theme: { palette, spacing } }) => ({
  color: palette.secondary.main,
  display: 'flex',
  alignItems: 'center',
  gap: spacing(0.5),
  fontSize: '14px',
  lineHeight: '16px',
}));

const Message: React.FC<IProps> = ({
  identifier,
  isEditable = false,
  schema,
  remainingTime,
}) => {
  const { palette } = useTheme();

  return (
    <Box pb={4}>
      <Typography textAlign="center" variant="h5">
        Enter the security code to continue
      </Typography>
      <Typography textAlign="center" variant="body1" color={palette.blue.main}>
        A security code has been sent to your {getCodeDestination(schema)}
      </Typography>
      <Box display="flex" justifyContent="center" gap={1}>
        {identifier && (
          <Typography textAlign="center" color={palette.black.main}>
            {hideIdentifier(identifier, schema)}
          </Typography>
        )}
        {isEditable && (
          <CustomLink
            to={routes.mfa.setup.href}
            state={{ identifier, schema, seconds: remainingTime }}
          >
            <Icon width={20} height={20} icon="material-symbols:edit" />
            Edit
          </CustomLink>
        )}
      </Box>
    </Box>
  );
};

export default Message;
