import { Device as RawDevice } from 'models/devices.model';

export interface Device extends Pick<RawDevice, 'identifiers'> {
  id: number;
  accountId: number;
  isActive: boolean;
  model: string;
  name: string;
  system: string;
  build?: string;
}

export const BatteryState = {
  CHARGING: 'charging',
  UNPLUGGED: 'unplugged',
  FULL: 'full',
} as const;

export type BatteryState = (typeof BatteryState)[keyof typeof BatteryState];
