import {
  Backdrop,
  Box,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import * as Styled from './ProfileLookupResults.styles';
import {
  useGetByIdQuery,
  useMdmPushMutation,
  useUpdatePatternTypesMutation,
  useUpdateTagsMutation,
} from 'store/api/devices.api';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { DevicePatternType } from 'models/devices.model';
import { toast } from 'react-toastify';
import {
  computeDeviceMgmtStatusFromTags,
  computeTagsFromDeviceMgmtStatus,
  DeviceMgmtMode,
  DeviceMgmtStatus,
  PatternTypeGroup,
} from './DeviceInfoModal.extra';

const Wrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  padding: ${({ theme }) => theme.spacing(2)};
  max-height: calc(100vh - ${({ theme }) => theme.spacing(4)});
  overflow-y: auto;
`;

interface Props {
  deviceId: number;
  isOpen: boolean;
  onClose: (...args: unknown[]) => void;
}

const DeviceInfoModal = (props: Props) => {
  const { deviceId, isOpen, onClose } = props;

  const { data: deviceData, isFetching: isLoading } = useGetByIdQuery(deviceId);
  const [updatePatternTypes, { isLoading: isUpdating }] =
    useUpdatePatternTypesMutation();
  const [updateTags, { isLoading: isTagsUpdating }] = useUpdateTagsMutation();
  const [mdmPush] = useMdmPushMutation();

  const [locationRefresh, setLocationRefresh] =
    useState<DevicePatternType>('default');
  const [heartRateRefresh, setHeartRateRefresh] =
    useState<DevicePatternType>('default');
  const [deviceMgmtStatus, setDeviceMgmtStatus] =
    useState<DeviceMgmtStatus>('released');

  useEffect(() => {
    if (deviceData) {
      setLocationRefresh(deviceData.location_refresh_pattern_type);
      setHeartRateRefresh(deviceData.heart_rate_refresh_pattern_type);
      setDeviceMgmtStatus(computeDeviceMgmtStatusFromTags(deviceData.tags));
    }
  }, [deviceData]);

  const handlePatternTypeUpdate = (
    lr: DevicePatternType,
    hrr: DevicePatternType,
  ) => {
    updatePatternTypes({
      deviceId,
      locationRefresh: lr,
      heartRateRefresh: hrr,
    }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        toast('There was an error. Please try again.', { type: 'error' });
        return;
      }

      toast('Pattern types saved succesfully!', { type: 'success' });
    });
  };

  const handleTagsUpdate = (tags: string[]) => {
    updateTags({ deviceId, tags }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        toast('There was an error. Please try again.', { type: 'error' });
        return;
      }

      mdmPush({ deviceId }).then(() => {
        toast('Pattern types saved succesfully!', { type: 'success' });
      });
    });
  };

  const onLocationPatternTypeChange = (v: DevicePatternType) => {
    setLocationRefresh(v);
    handlePatternTypeUpdate(v, heartRateRefresh);
  };

  const onHeartRatePatternTypeChange = (v: DevicePatternType) => {
    setHeartRateRefresh(v);
    handlePatternTypeUpdate(locationRefresh, v);
  };

  const onDeviceMgmtStatusChange = (v: DeviceMgmtStatus) => {
    setDeviceMgmtStatus(v);
    handleTagsUpdate(computeTagsFromDeviceMgmtStatus(v));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={isOpen}>
        <Wrapper elevation={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Additional device information
              <Typography variant="body2">Device ID: {deviceId}</Typography>
            </Typography>
            <IconButton onClick={onClose}>
              <Icon icon="material-symbols:close-rounded" />
            </IconButton>
          </Box>
          <Box position="relative">
            <Styled.LoadingOverlay isLoading={isLoading} />
          </Box>
          {!!deviceData && (
            <Grid
              container
              columnSpacing={2}
              rowSpacing={0.5}
              alignItems="center"
              mt={3}
            >
              <Grid item xs={4}>
                <Typography>Account ID:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{deviceData.account_id}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Name:</Typography>
              </Grid>
              <Grid item xs={8}>
                {deviceData.name}
              </Grid>
              <Grid item xs={4}>
                <Typography>Model:</Typography>
              </Grid>
              <Grid item xs={8}>
                {deviceData.model}
              </Grid>
              <Grid item xs={4}>
                <Typography>System:</Typography>
              </Grid>
              <Grid item xs={8}>
                {deviceData.system}
              </Grid>
              <Grid item xs={4}>
                <Typography>Build:</Typography>
              </Grid>
              <Grid item xs={8}>
                {deviceData.build}
              </Grid>
              <Grid item xs={4}>
                Active:
              </Grid>
              <Grid item xs={8}>
                {deviceData.active ? 'Yes' : 'No'}
              </Grid>
              <Grid item xs={4}>
                Identifiers:
              </Grid>
              <Grid item xs={8}>
                <Styled.JsonWrapper src={deviceData.identifiers} />
              </Grid>
              <Grid item xs={4}>
                Push configurations:
              </Grid>
              <Grid item xs={8}>
                <Styled.JsonWrapper src={deviceData.push_configurations} />
              </Grid>
              <Grid item xs={4}>
                Tags:
              </Grid>
              <Grid item xs={8}>
                <Styled.JsonWrapper src={deviceData.tags} />
              </Grid>
              <Grid item xs={4}>
                Location Refresh:
              </Grid>
              <Grid item xs={8}>
                <PatternTypeGroup
                  value={locationRefresh}
                  onChange={onLocationPatternTypeChange}
                  disabled={isUpdating}
                />
              </Grid>
              <Grid item xs={4}>
                Heart Rate Refresh:
              </Grid>
              <Grid item xs={8}>
                <PatternTypeGroup
                  value={heartRateRefresh}
                  onChange={onHeartRatePatternTypeChange}
                  disabled={isUpdating}
                />
              </Grid>
              <Grid item xs={4}>
                Device Mgmt Mode:
              </Grid>
              <Grid item xs={8}>
                <DeviceMgmtMode
                  value={deviceMgmtStatus}
                  onChange={onDeviceMgmtStatusChange}
                  disabled={isTagsUpdating}
                />
              </Grid>
            </Grid>
          )}
        </Wrapper>
      </Fade>
    </Modal>
  );
};

export default DeviceInfoModal;
