import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { PatientRecord } from 'types/patient';
import { FiltersAppleWatchKeys } from 'store/reducers/filters/types';
import { DataTableColumn } from 'types/dashboard';
import {
  batteryColumns,
  bloodOxygenColumns,
  commonColumnProperties,
  deviceColumns,
  fallDetectionColumns,
  heartRateColumns,
  safeZoneColumns,
  sleepColumns,
} from './columns';
import { GridColDef } from '@mui/x-data-grid-pro';

export const PatientRecordColumn = {
  TIMESTAMP: 'timestamp',
  ...DataTableColumn,
} as const;

export type PatientRecordColumn =
  (typeof PatientRecordColumn)[keyof typeof PatientRecordColumn];

export const columns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: PatientRecordColumn.TIMESTAMP,
    disableReorder: true,
    headerName: 'Timestamp',
    minWidth: 220,
    renderCell: ({ row }) => (
      <Typography variant="body1" color="primary">
        {dayjs(row.timestamp).format(`MMM DD [at] hh:mm:ssa`)}
      </Typography>
    ),
  },
  ...deviceColumns,
  ...batteryColumns,
  ...safeZoneColumns,
  ...bloodOxygenColumns,
  ...fallDetectionColumns,
  ...heartRateColumns,
  ...sleepColumns,
];

export const filterFieldnames: Partial<
  Record<FiltersAppleWatchKeys, DataTableColumn[]>
> = {
  device: [
    DataTableColumn.CONFIGURATION_OK,
    DataTableColumn.ACTIVE_DEVICE,
    DataTableColumn.DEVICE_WORN,
    DataTableColumn.DEVICE_LOCKED,
  ],
  safeZone: [DataTableColumn.SAFE_ZONE],
  battery: [DataTableColumn.BATTERY_LEVEL, DataTableColumn.BATTERY_STATE],
  fallDetection: [DataTableColumn.FALL_DETECTION],
  heartRate: [
    DataTableColumn.HEART_RATE,
    DataTableColumn.LOW_HEART_RATE,
    DataTableColumn.HIGH_HEART_RATE,
  ],
  heartRhythm: [DataTableColumn.IRREGULAR_HEART_RYTHM],
  bloodOxygen: [DataTableColumn.BLOOD_OXYGEN],
  sleepTime: [DataTableColumn.DAILY_SLEEP],
};
