import { PageContent } from 'components/_extra/generic';
import DevicesSearchBar, { searchTypes } from './components/DevicesSearchBar';
import { useSearchDevicesMutation } from 'store/api/admin.api';
import DevicesList from './components/DevicesList';
import { Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SearchDevicesParams } from 'models/admin.model';

const ManageDevices = () => {
  const [params, setParams] = useSearchParams();
  const [search, { data, isLoading }] = useSearchDevicesMutation();

  useEffect(() => {
    const searchBy = params.get('searchBy');
    if (
      searchBy &&
      searchTypes.includes(searchBy as keyof SearchDevicesParams) &&
      params.get('value')
    ) {
      search({ [searchBy]: params.get('value') });
      setParams({});
    }
  }, []);

  return (
    <>
      <PageContent sx={{ p: 2, gap: ({ spacing }) => spacing(2) }}>
        <DevicesSearchBar onSearch={search} />
      </PageContent>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {data && <DevicesList rows={data?.results || []} />}
    </>
  );
};

export default ManageDevices;
