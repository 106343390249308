import { PageContent } from 'components/_extra/generic';
import { useGetOrganizationsQuery } from 'store/api/organizations';
import { VLoader } from 'components/VLoader';
import SettingsForm from './components/SettingsForm';

const OrganizationSettings = () => {
  const { data, isLoading } = useGetOrganizationsQuery();

  if (isLoading || !data) return <VLoader />;

  return (
    <PageContent>
      <SettingsForm defaultValues={data[0]} />
    </PageContent>
  );
};

export default OrganizationSettings;
