import { useEffect } from 'react';
import { useConfirmation } from './use-confirmation';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

interface NavigationBlockerOptions {
  message: string;
  title: string;
  rejectButtonLabel?: string;
  confirmButtonLabel?: string;
  variant?: 'danger' | 'success' | 'default';
  onDiscard?: () => void;
}

export const useNavigationBlocker = (
  isDirty: boolean,
  {
    message,
    title,
    onDiscard,
    confirmButtonLabel,
    rejectButtonLabel,
    variant,
  }: NavigationBlockerOptions,
) => {
  const confirm = useConfirmation();

  const blocker = useBlocker(isDirty);

  const goBack = () => {
    blocker?.reset?.();
  };

  const discard = () => {
    onDiscard?.();
    blocker?.proceed?.();
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(
        {
          title,
          message,
          rejectButtonLabel: rejectButtonLabel ?? 'Go back',
          confirmButtonLabel: confirmButtonLabel ?? 'Discard',
          variant: variant ?? 'default',
        },
        discard,
        goBack,
      );
    }
  }, [blocker.state]);
};
