import { Tooltip, TooltipProps } from '@mui/material';
import { MouseEventHandler } from 'react';
import { IconOnlyButton, StyledIcon } from './styled';
import { close } from 'assets/iconify';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'medium' | 'small';
  tooltipText?: string;
  disabled?: boolean;
}

const WithTooltip = ({
  children,
  disabled,
  ...rest
}: TooltipProps & { disabled?: boolean }) => {
  if (disabled) return children;
  return <Tooltip {...rest}>{children}</Tooltip>;
};

const CancelButton = ({
  onClick,
  size = 'small',
  tooltipText = 'Cancel',
  disabled,
}: Props) => {
  return (
    <WithTooltip title={tooltipText} placement="top" disabled={disabled}>
      <IconOnlyButton
        size={size}
        disabled={disabled}
        variant="outlined"
        color="primary"
        onClick={onClick}
      >
        <StyledIcon icon={close.icons.normal} />
      </IconOnlyButton>
    </WithTooltip>
  );
};

export default CancelButton;
