import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const personAdd: IconifyJSON = {
  prefix: 'person-add',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M18.125 13.75V10.75H15.125V9.25002H18.125V6.25002H19.625V9.25002H22.625V10.75H19.625V13.75H18.125ZM9.00001 11.6923C8.03753 11.6923 7.21358 11.3496 6.52816 10.6642C5.84274 9.97876 5.50004 9.15481 5.50004 8.19232C5.50004 7.22982 5.84274 6.40587 6.52816 5.72047C7.21358 5.03506 8.03753 4.69235 9.00001 4.69235C9.96249 4.69235 10.7864 5.03506 11.4719 5.72047C12.1573 6.40587 12.5 7.22982 12.5 8.19232C12.5 9.15481 12.1573 9.97876 11.4719 10.6642C10.7864 11.3496 9.96249 11.6923 9.00001 11.6923ZM1.50006 19.3077V17.0846C1.50006 16.5949 1.63306 16.1413 1.89906 15.724C2.16509 15.3067 2.52054 14.9859 2.96541 14.7615C3.95388 14.2769 4.95099 13.9135 5.95676 13.6712C6.96253 13.4289 7.97694 13.3077 9.00001 13.3077C10.0231 13.3077 11.0375 13.4289 12.0433 13.6712C13.049 13.9135 14.0461 14.2769 15.0346 14.7615C15.4795 14.9859 15.8349 15.3067 16.1009 15.724C16.367 16.1413 16.5 16.5949 16.5 17.0846V19.3077H1.50006ZM3.00001 17.8077H15V17.0846C15 16.882 14.9414 16.6945 14.824 16.5221C14.7067 16.3497 14.5474 16.209 14.3462 16.1C13.4846 15.6756 12.6061 15.3541 11.7107 15.1355C10.8153 14.917 9.91171 14.8077 9.00001 14.8077C8.08831 14.8077 7.18475 14.917 6.28931 15.1355C5.39388 15.3541 4.51539 15.6756 3.65386 16.1C3.45258 16.209 3.29329 16.3497 3.17599 16.5221C3.05867 16.6945 3.00001 16.882 3.00001 17.0846V17.8077ZM9.00001 10.1923C9.55001 10.1923 10.0208 9.99649 10.4125 9.60482C10.8042 9.21316 11 8.74232 11 8.19232C11 7.64232 10.8042 7.17149 10.4125 6.77982C10.0208 6.38816 9.55001 6.19232 9.00001 6.19232C8.45001 6.19232 7.97918 6.38816 7.58751 6.77982C7.19584 7.17149 7.00001 7.64232 7.00001 8.19232C7.00001 8.74232 7.19584 9.21316 7.58751 9.60482C7.97918 9.99649 8.45001 10.1923 9.00001 10.1923Z" fill="currentColor"/>',
    },
    filled: {
      ...defaultSizes,
      body: '<path d="M18.125 13.75V10.75H15.125V9.25002H18.125V6.25002H19.625V9.25002H22.625V10.75H19.625V13.75H18.125ZM9.00001 11.6923C8.03753 11.6923 7.21358 11.3496 6.52816 10.6642C5.84274 9.97876 5.50004 9.15481 5.50004 8.19232C5.50004 7.22982 5.84274 6.40587 6.52816 5.72047C7.21358 5.03506 8.03753 4.69235 9.00001 4.69235C9.96249 4.69235 10.7864 5.03506 11.4719 5.72047C12.1573 6.40587 12.5 7.22982 12.5 8.19232C12.5 9.15481 12.1573 9.97876 11.4719 10.6642C10.7864 11.3496 9.96249 11.6923 9.00001 11.6923ZM1.50006 19.3077V17.0846C1.50006 16.5949 1.63306 16.1413 1.89906 15.724C2.16509 15.3067 2.52054 14.9859 2.96541 14.7615C3.95388 14.2769 4.95099 13.9135 5.95676 13.6712C6.96253 13.4289 7.97694 13.3077 9.00001 13.3077C10.0231 13.3077 11.0375 13.4289 12.0433 13.6712C13.049 13.9135 14.0461 14.2769 15.0346 14.7615C15.4795 14.9859 15.8349 15.3067 16.1009 15.724C16.367 16.1413 16.5 16.5949 16.5 17.0846V19.3077H1.50006Z" fill="currentColor"/>',
    },
  },
};
