import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { visibleColumnsSelector } from 'store/selectors';
import { DataTableColumn } from 'types/dashboard';
import {
  columns as ALL_COLUMNS,
  filterFieldnames,
} from 'utils/records-columns';

export const useRecordsColumns = () => {
  const columnCategories = useAppSelector(visibleColumnsSelector);
  const requestFields: DataTableColumn[] = useMemo(
    () =>
      columnCategories.reduce(
        (prev, current) => prev.concat(filterFieldnames[current] ?? []),
        [] as DataTableColumn[],
      ),
    [columnCategories.join()],
  );

  const visibleColumns = ['timestamp', ...requestFields];

  const columns = ALL_COLUMNS.filter((column) =>
    visibleColumns.includes(column.field as DataTableColumn),
  );

  return {
    visibleColumns,
    requestFields,
    columns,
  };
};
