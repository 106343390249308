import { SyntheticEvent, useState } from 'react';
import { useAppSelector } from 'store';
import { ReminderDetailsTabIndex } from '../ReminderDetails';
import { useDispatch } from 'react-redux';
import { useConfirmation } from 'hooks/use-confirmation';
import { setReminderSettingsAsUntouched } from 'store/reducers/reminder/reminder.slice';

export const useReminderDetailsTabs = () => {
  const areSettingsModified = useAppSelector(
    (state) => state.reminder.areSettingsModified,
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [submit, setSubmit] = useState<() => void | 0>(() => () => {});
  const [currentTab, setCurrentTab] = useState<ReminderDetailsTabIndex>(
    ReminderDetailsTabIndex.TRIGGERS,
  );

  const dispatch = useDispatch();
  const confirm = useConfirmation();

  const handleTabChange = (e: SyntheticEvent, tab: ReminderDetailsTabIndex) => {
    if (
      currentTab === ReminderDetailsTabIndex.SETTINGS &&
      areSettingsModified
    ) {
      confirm(
        {
          title: 'You have unsaved changes',
          message: 'Do you want to save or discard them?',
          confirmButtonLabel: 'Save',
          rejectButtonLabel: 'Discard',
        },
        () => {
          const result = submit();
          if (result === 0) {
            setCurrentTab(tab);
          }
        },
        () => {
          dispatch(setReminderSettingsAsUntouched());
          setCurrentTab(tab);
        },
      );
      return;
    }

    setCurrentTab(tab);
  };

  const handleSubmitChange = (value: () => void) => {
    setSubmit(() => value);
  };

  return {
    areSettingsModified,
    currentTab,
    handleSubmitChange,
    handleTabChange,
  };
};
