import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const layer: IconifyJSON = {
  prefix: 'layer',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M11.25 16V9.49998H8.25V8H15.7499V9.49998H12.7499V16H11.25ZM1.69232 22.3077V17.1923H3.5V6.80768H1.69232V1.69235H6.80765V3.50003H17.1923V1.69235H22.3076V6.80768H20.4999V17.1923H22.3076V22.3077H17.1923V20.5H6.80765V22.3077H1.69232ZM6.80765 19H17.1923V17.1923H19V6.80768H17.1923V5H6.80765V6.80768H4.99997V17.1923H6.80765V19Z" fill="currentColor"/>',
    },
  },
};
