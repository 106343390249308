import { Icon } from '@iconify/react';
import { Box, Checkbox, Tooltip, styled } from '@mui/material';
import { information } from 'assets/iconify';
import { TimeInput } from 'components/TimeInput';
import VInput from 'components/VInput';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { AlerterTemplateSummary } from 'types/alerters';
import { getPropsFromTemplateParams } from 'utils/get-props-from-template-params';

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  marginLeft: theme.spacing(1),
}));

const NoWrapLabel = styled('label')`
  white-space: nowrap;
`;

type Props = {
  template: AlerterTemplateSummary;
  isDefaultChecked?: boolean;
  defaultParams?: number[];
  onChange?: (identifier: string, checked: boolean) => void;
  onValueChange?: (identifier: string, values: Record<string, number>) => void;
};

const TemplateItem = ({
  template,
  isDefaultChecked,
  defaultParams,
  onChange,
  onValueChange,
}: Props) => {
  const [checked, setChecked] = useState(isDefaultChecked ?? false);
  const [values, setValues] = useState<number[]>(defaultParams ?? []);

  const extraParams = useMemo(
    () => getPropsFromTemplateParams(template.template_params),
    [template.identifier],
  );

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChange?.(template.identifier, e.target.checked);
    onValueChange?.(
      template.identifier,
      extraParams.reduce((acc, param, index) => {
        acc[param.key] = values[index];
        return acc;
      }, {} as Record<string, number>),
    );
  };

  const handleValueChange = (value: number, index: number) => {
    extraParams[index].key;
    onValueChange?.(
      template.identifier,
      extraParams.reduce((acc, param, i) => {
        acc[param.key] = i === index ? value : values[i];
        return acc;
      }, {} as Record<string, number>),
    );
    setValues((prev) => {
      const newValues = [...prev];
      newValues[index] = value;
      return newValues;
    });
  };

  useEffect(() => {
    if (!checked) {
      setValues(extraParams.map((param) => param.defaultValue));
    }
  }, [checked]);

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box
        width={({ spacing }) => spacing(40)}
        display="flex"
        alignItems="center"
      >
        <Checkbox
          checked={checked}
          onChange={handleCheck}
          id={`template_${template.identifier}`}
        />
        <NoWrapLabel htmlFor={`template_${template.identifier}`}>
          {template.title}
        </NoWrapLabel>
        <Tooltip title={template.description} placement="top-start">
          <StyledIcon icon={information.icons.normal} />
        </Tooltip>
      </Box>

      {extraParams.map((param, index) => {
        if (param.fieldType === 'minutes') {
          return (
            <Box key={param.key} display="flex" alignItems="center" gap={1}>
              <TimeInput
                readOnly={!checked}
                value={values[index] ?? param.defaultValue}
                onChange={(v) => handleValueChange(v, index)}
              />
              <NoWrapLabel sx={{ color: ({ palette }) => palette.blue.main }}>
                {param.label}
              </NoWrapLabel>
            </Box>
          );
        }

        return (
          <Box key={param.key} display="flex" alignItems="center" gap={1}>
            <VInput
              type="number"
              disableWhitespace
              readOnly={!checked}
              size="small"
              containerSx={{ width: ({ spacing }) => spacing(11) }}
              value={values[index] ?? param.defaultValue}
              onChange={(e) => handleValueChange(Number(e.target.value), index)}
            />
            <NoWrapLabel sx={{ color: ({ palette }) => palette.blue.main }}>
              {param.label}
            </NoWrapLabel>
          </Box>
        );
      })}
    </Box>
  );
};

export default TemplateItem;
