import { Box, Skeleton, SkeletonProps, styled } from '@mui/material';

import theme from 'styles/theme';
import pxToEm from 'utils/px-to-em';

interface MapProps {
  width: number | string;
  height: number | string;
  disabled?: boolean;
}

export const Map = styled(Box)<MapProps>`
  position: relative;
  width: ${({ width }) => pxToEm(width) || pxToEm(350)};
  height: ${({ height }) => pxToEm(height) || pxToEm(350)};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${theme.palette.gray.main};
  filter: ${({ disabled }) => disabled && 'blur(1px) brightness(80%)'};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  & .mk-map-view {
    border-radius: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const MapSkeleton = styled((props: SkeletonProps) => (
  <Skeleton {...props} />
))(() => ({
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: 1,
  transform: 'none',
}));

export const MapLastRefresh = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  left: ${({ theme }) => theme.spacing(1)};
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background: ${theme.palette.common.white};
  user-select: none;
`;

export const MapBottomContainer = styled(Box)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(4)};
  left: ${({ theme }) => theme.spacing(1)};
  z-index: 1;
  display: flex;
  align-items: end;
  flex-grow: 0;
`;
