/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from '@iconify/react';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import { BasicModal } from 'components/BasicModal';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { CompletionMessage } from 'components/CompletionMessage';
import { useConnectDeviceModal } from 'pages/PatientAccount/hooks/use-connect-device-modal';
import { createContext, memo, useMemo, useState } from 'react';
import { useDeviceSetupSteps } from '../../../hooks/use-device-setup-steps';
import { getSteps } from './steps';

export const NewDeviceContext = createContext({
  isError: false,
  deviceId: 0,
  setIsError: (_val: boolean) => {},
  setDeviceId: (_val: number) => {},
});

export const DeviceContext = createContext({
  deviceId: undefined,
  setDeviceId: undefined,
});

const ConnectDeviceModal = () => {
  const [isError, setIsError] = useState(false);
  const [sentAt, setSentAt] = useState('');
  const [deviceId, setDeviceId] = useState(0);

  const {
    handleButtonClick,
    handleModalClose,
    open,
    closeDialog,
    isDialogOpen,
    openDialog,
  } = useConnectDeviceModal();
  const {
    activeStep,
    handleNextStep,
    isComplete,
    resetActiveStep,
    refetchDevices,
    lastStep,
  } = useDeviceSetupSteps();

  const steps = useMemo(
    () => getSteps(handleNextStep, sentAt, setSentAt),
    [sentAt],
  );

  const closeModal = async () => {
    handleModalClose();
    closeDialog();
    resetActiveStep();
    setIsError(false);
    if (activeStep !== 0 && activeStep <= lastStep) {
      refetchDevices();
    }
  };

  const handleClose = () => {
    if (activeStep === 0) {
      closeModal();
      return;
    }
    openDialog();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          pr: {
            newLg: 1,
          },
        }}
      >
        <Button
          sx={{
            mt: {
              xxs: 3,
              sm: 4,
            },
            mb: 1,
            flexGrow: {
              xxs: 1,
              sm: 'unset',
            },
          }}
          startIcon={<Icon icon="material-symbols:add-box-outline" />}
          onClick={handleButtonClick}
        >
          Add Device
        </Button>
      </Box>
      {isComplete || isError ? (
        <Modal open={open} onClose={closeModal}>
          <Paper sx={{ maxWidth: '512px', width: '90%' }}>
            {isComplete ? (
              <CompletionMessage
                status="success"
                title="Device added successfully!"
                subtitle="The Apple Watch has been added and configured."
                onButtonClick={closeModal}
              />
            ) : (
              <CompletionMessage
                status="error"
                title="Something went wrong..."
                subtitle="Device configuration failed. Make sure your device is connected and try again. "
                onButtonClick={closeModal}
              />
            )}
          </Paper>
        </Modal>
      ) : (
        <BasicModal
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown
          minHeight="200px"
        >
          <NewDeviceContext.Provider
            value={{ isError, setIsError, deviceId, setDeviceId }}
          >
            <Typography variant="h5" align="center">
              {steps[activeStep].title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ px: 3, pb: 4, color: ({ palette }) => palette.blue.main }}
              align="center"
            >
              {steps[activeStep].subTitle}
            </Typography>
            {steps[activeStep].content}
          </NewDeviceContext.Provider>
        </BasicModal>
      )}
      <ConfirmationDialog
        open={isDialogOpen}
        onConfirm={closeModal}
        onReject={closeDialog}
        title="Are you sure you want to cancel your device configuration?"
        subtitle="If you cancel at this step, you will lose all the device setup progress."
        rejectButtonLabel="Go back"
        confirmButtonProps={{ color: 'red' }}
      />
    </>
  );
};

export default memo(ConnectDeviceModal);
