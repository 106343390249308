import { Box, Typography, styled } from '@mui/material';
import { WeekdayPicker } from 'components/WeekdayPicker';
import {
  ScheduleRuleValues,
  useScheduleRuleForm,
} from 'components/_extra/hooks/use-schedule-rule-form';
import TimePicker from 'pages/Alerters/components/TimePicker';
import { Ref } from 'react';

const Wrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(3),
  width: '100%',
}));

const ScheduleRuleForm = ({
  getValuesRef,
  defaultValues,
  id,
  onEdit,
}: {
  getValuesRef: Ref<{ getTriggerValues: () => ScheduleRuleValues }>;
  defaultValues?: ScheduleRuleValues;
  id?: number;
  onEdit?: () => void;
}) => {
  const { time, days, handleTimeChange, handleDaysChange, readOnly } =
    useScheduleRuleForm(id, getValuesRef, defaultValues);

  return (
    <Wrapper
      sx={{
        flexDirection: 'column',
        rowGap: 2,
        py: 1,
        px: {
          xxs: 0.5,
          sm: 1,
        },
      }}
    >
      <Box flexBasis={0} minWidth={({ spacing }) => spacing(15)}>
        <Typography variant="body1" color="primary" pb={1}>
          Time
        </Typography>
        <TimePicker
          value={time}
          onChange={handleTimeChange}
          onFocus={onEdit}
          sx={{ width: (theme) => theme.spacing(15) }}
          readOnly={readOnly}
        />
      </Box>
      <Box flexBasis={0} flexGrow={1} minWidth={({ spacing }) => spacing(25.5)}>
        <Typography variant="body1" color="primary" pb={1}>
          Repeat
        </Typography>
        <WeekdayPicker
          onFocus={onEdit}
          value={days}
          onChange={handleDaysChange}
          fullWidthSelector
          size="small"
          withPresets
          readOnly={readOnly}
        />
      </Box>
    </Wrapper>
  );
};

export default ScheduleRuleForm;
