import { Box, Skeleton } from '@mui/material';
import ConditionCard from './ConditionCard';
import { TransparentBackdrop } from 'pages/AlerterDetails/components/Triggers/styled';
import { useReminderConditionList } from 'pages/ReminderDetails/hooks/use-reminder-condition-list';
import { getSideBarState } from 'services/sidebar';
import { EmptyList } from 'components/_extra/EmptyList';
import AddConditionButton from './AddConditionButton';
import { list } from 'assets/iconify';

const ConditionList = () => {
  const isSidebarOpen = getSideBarState();
  const {
    isLoading,
    conditions,
    functionsRef,
    editedRule,
    handleBackdropClick,
  } = useReminderConditionList();

  return (
    <Box
      display="grid"
      gap={{ xxs: 2, sm: 3 }}
      width="100%"
      gridTemplateColumns={
        conditions.length === 0 && !isLoading
          ? '1fr'
          : {
              xxs: '1fr',
              newLg: '1fr 1fr',
              newXl: `repeat(${isSidebarOpen ? 1 : 2}, 1fr)`,
              new2Xl: `repeat(${isSidebarOpen ? 2 : 3}, 1fr)`,
              new3Xl: `repeat(${isSidebarOpen ? 3 : 4}, 1fr)`,
              new4Xl: `repeat(4, 1fr)`,
            }
      }
      pb={1}
    >
      {isLoading ? (
        <>
          <Skeleton variant="rounded" height={360} width="100%" />
          <Skeleton variant="rounded" height={360} width="100%" />
        </>
      ) : conditions.length > 0 ? (
        conditions.map(
          ({ title, id, comparator, metricType, enabled, value }) => (
            <ConditionCard
              key={id}
              id={id}
              title={title}
              comparator={comparator}
              metricType={metricType}
              enabled={enabled}
              value={value}
              functionsRef={functionsRef}
              onReset={functionsRef.current?.reset}
              sx={({ zIndex }) => ({
                zIndex: editedRule?.id === id ? zIndex.modal : 'auto',
              })}
            />
          ),
        )
      ) : (
        <EmptyList
          icon={list.icons.normal}
          title="The condition list is empty"
          message={
            <>
              Use condition to only send out your reminder under certain
              circumstances. For example, if you’re making a reminder to put the{' '}
              <strong>BoundaryCare</strong> watch on its charger at the end of
              the day, you can add a condition that will skip the reminder if
              the watch is already on its charger at the scheduled time.
            </>
          }
        >
          <AddConditionButton />
        </EmptyList>
      )}
      <TransparentBackdrop open={!!editedRule} onClick={handleBackdropClick} />
    </Box>
  );
};

export default ConditionList;
