import {
  Device,
  DevicePatternType,
  DevicesAssignToPatientReq,
  DevicesAssignToPatientRes,
  DevicesGetAllRes,
  DevicesGetOneRes,
  SynchronizeDeviceReq,
  UpdateDeviceReq,
} from 'models/devices.model';
import { Device as TransformedDevice } from 'types/device';
import { transformDeviceData } from 'utils/transform-device-data';
import { bcApi } from './_api';

export const devicesApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<TransformedDevice[], { id: number }>({
      query: ({ id }) => ({
        url: '/devices',
        method: 'GET',
        params: {
          account_id: id,
        },
      }),
      transformResponse: (res: DevicesGetAllRes) =>
        res.results.map((device) => transformDeviceData(device)),
    }),
    getById: builder.query<Device, number>({
      query: (id) => ({
        url: `/devices/${id}`,
        method: 'GET',
      }),
      transformResponse: (res: DevicesGetOneRes) => res.result,
      providesTags: ['DeviceData'],
    }),
    assignToPatient: builder.mutation<Device, DevicesAssignToPatientReq>({
      query: (body) => ({
        url: `/devices/${body.deviceId}/assign_account`,
        method: 'PUT',
        data: {
          account_id: Number(body.patientProfileId),
          skip_setup: body.skipSetup,
          bc_app_context: body.bc_app_context,
          remote_management:
            body.remoteManagement == 0 ? null : body.remoteManagement == 2,
        },
      }),
      transformResponse: (res: DevicesAssignToPatientRes) => res.result,
    }),
    synchronize: builder.mutation<Device, SynchronizeDeviceReq>({
      query: (device) => ({
        url: '/devices/synchronize',
        method: 'POST',
        data: device,
      }),
    }),
    retrieve: builder.mutation<{ result: Device }, { code: string }>({
      query: ({ code }) => ({
        url: `/devices/retrieve`,
        method: 'POST',
        data: {
          identifiers: {
            linking_token: code,
          },
        },
      }),
      invalidatesTags: ['DashboardData'],
    }),
    update: builder.mutation<Device, UpdateDeviceReq>({
      query: ({
        deviceId,
        accountId,
        heartRateRefreshPatternType,
        locationRefreshPatternType,
      }) => ({
        url: `devices/${deviceId}`,
        method: 'PUT',
        data: {
          account_id: accountId,
          heart_rate_refresh_pattern_type: heartRateRefreshPatternType,
          location_refresh_pattern_type: locationRefreshPatternType,
        },
      }),
      invalidatesTags: ['DeviceData'],
    }),
    updatePatternTypes: builder.mutation<
      unknown,
      {
        deviceId: number;
        locationRefresh: DevicePatternType;
        heartRateRefresh: DevicePatternType;
      }
    >({
      query: ({ deviceId, locationRefresh, heartRateRefresh }) => ({
        url: `devices/${deviceId}`,
        method: 'PUT',
        data: {
          location_refresh_pattern_type: locationRefresh,
          heart_rate_refresh_pattern_type: heartRateRefresh,
        },
      }),
    }),
    updateTags: builder.mutation<
      unknown,
      {
        deviceId: number;
        tags: string[];
      }
    >({
      query: ({ deviceId, tags }) => ({
        url: `devices/${deviceId}`,
        method: 'PUT',
        data: { tags },
      }),
    }),
    mdmPush: builder.mutation<unknown, { deviceId: number }>({
      query: ({ deviceId }) => ({
        url: `devices/${deviceId}/mdm_push`,
        method: 'PUT',
      }),
    }),
    deregister: builder.mutation<Device, { deviceId: number }>({
      query: ({ deviceId }) => ({
        url: `/devices/${deviceId}/deregister`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAllQuery,
  useLazyGetAllQuery,
  useGetByIdQuery,
  useLazyGetByIdQuery,
  useAssignToPatientMutation,
  useRetrieveMutation,
  useDeregisterMutation,
  useMdmPushMutation,
  useUpdateMutation,
  useUpdatePatternTypesMutation,
  useUpdateTagsMutation,
  useSynchronizeMutation,
} = devicesApi;
