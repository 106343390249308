import { FilterTabs } from 'components/Filters';
import type { FilterTab } from 'components/Filters';
export const FilterTabIndex = {
  APPLE_WATCH: 0,
  PATIENT_DETAILS: 1,
  SURVEYS: 2,
} as const;

export type FilterTabIndex =
  (typeof FilterTabIndex)[keyof typeof FilterTabIndex];

const tabs: FilterTab[] = [
  {
    name: 'Apple Watch',
    value: FilterTabIndex.APPLE_WATCH,
    href: '/profiles/overview/apple-watch',
  },
  {
    name: 'Patient Details',
    value: FilterTabIndex.PATIENT_DETAILS,
    href: '#',
    isDisabled: true,
  },
  {
    name: 'Surveys',
    value: FilterTabIndex.SURVEYS,
    href: '#',
    isDisabled: true,
  },
];

interface Props {
  value: number;
  onChange: (_e: React.SyntheticEvent, newValue: number) => void;
  modifiedTabIds?: (string | number)[];
}

const PatientsFilterTabs = ({
  value,
  onChange,
  modifiedTabIds = [],
}: Props) => {
  return (
    <FilterTabs
      tabs={tabs}
      onChange={onChange}
      value={value}
      modifiedTabIds={modifiedTabIds}
    />
  );
};

export default PatientsFilterTabs;
