/* eslint-disable @typescript-eslint/no-explicit-any */
import { getPixelsFromZoomLevelAndRadius } from 'components/Map/Map';
import { useMemo } from 'react';
import {
  getStyles,
  getPathStyles,
  getAdditionalPathStyles,
  getPolygonStyles,
} from 'utils/map-overlays-styles';
import { PointType } from './use-markers';

const getType = (isSaved?: boolean, isError?: boolean) => {
  if (isError) return 'error';
  if (isSaved) return 'finished';
  return 'unfinished';
};

export const useOverlay = (
  map: mapkit.Map | null,
  replacePoints: (points: mapkit.Coordinate[], pointType?: PointType) => void,
) => {
  const overlays = useMemo(() => map?.overlays.slice(1) ?? [], [map?.overlays]);

  const zoomToRegion = (
    points: mapkit.Coordinate[],
    radius?: number,
    shouldAnimate?: boolean,
  ) => {
    if (points.length === 1 && radius) {
      const latitudeDelta = radius / 111.32 / 300;
      const longitudeDelta =
        radius /
        (111.32 * Math.cos(points[0].latitude * (Math.PI / 180))) /
        300;

      const boundingRegion = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(points[0].latitude, points[0].longitude),
        new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta),
      );

      map?.setRegionAnimated(boundingRegion);
      return;
    }

    if (points) {
      let minLatitude = points[0].latitude;
      let maxLatitude = points[0].latitude;
      let minLongitude = points[0].longitude;
      let maxLongitude = points[0].longitude;

      points.forEach(({ latitude, longitude }) => {
        minLatitude = Math.min(minLatitude, latitude);
        maxLatitude = Math.max(maxLatitude, latitude);
        minLongitude = Math.min(minLongitude, longitude);
        maxLongitude = Math.max(maxLongitude, longitude);
      });

      const centerLatitude = (maxLatitude + minLatitude) / 2;
      const centerLongitude = (maxLongitude + minLongitude) / 2;

      const latitudeDelta = (maxLatitude - minLatitude) * 2;
      const longitudeDelta = (maxLongitude - minLongitude) * 2;

      const boundingRegion = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(centerLatitude, centerLongitude),
        new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta),
      );

      map?.setRegionAnimated(boundingRegion, !!shouldAnimate);
    }
  };

  const removeOverlays = () => {
    if (map) {
      map.removeOverlays(map.overlays.slice(1));
    }
  };

  const addCircle = (
    center: mapkit.Coordinate,
    radius: number,
    shouldZoom?: boolean,
    isDone?: boolean,
  ) => {
    map?.addOverlay(
      new mapkit.CircleOverlay(center, radius, {
        style: new mapkit.Style(getStyles(!!isDone)),
      }),
    );

    if (shouldZoom) {
      zoomToRegion([center], radius);
    }
  };

  const addPolygon = (
    points: mapkit.Coordinate[],
    isClosed?: boolean,
    shouldZoom?: boolean,
    isDone?: boolean,
    hasIntersection?: boolean,
  ) => {
    if (!map) return;

    if (shouldZoom) {
      zoomToRegion(points);
    }

    if (isClosed) {
      map?.addOverlay(
        new mapkit.PolygonOverlay(points, {
          style: new mapkit.Style(getStyles(!!isDone, hasIntersection)),
        }),
      );
      return;
    }

    map.addOverlay(
      new mapkit.PolylineOverlay(points, {
        style: new mapkit.Style(getPolygonStyles(false)),
      }),
    );

    if (points.length > 2)
      map.addOverlay(
        new mapkit.PolylineOverlay([points[0], points[points.length - 1]], {
          style: new mapkit.Style({
            ...getPolygonStyles(false),
            lineDash: [5, 10],
          }),
        }),
      );
  };

  const addPath = (
    points: mapkit.Coordinate[],
    radius: number,
    shouldZoom?: boolean,
    isDone?: boolean,
  ) => {
    if (shouldZoom) {
      zoomToRegion(points);
    }
    map?.addOverlays([
      new mapkit.PolylineOverlay(points, {
        selected: false,
        style: new mapkit.Style({
          lineWidth: getPixelsFromZoomLevelAndRadius(
            (map as any)._impl.zoomLevel,
            radius,
          ),
          ...getPathStyles(!!isDone),
        } as mapkit.StyleConstructorOptions),
      }),
      new mapkit.PolylineOverlay(points, {
        selected: false,
        style: new mapkit.Style(getAdditionalPathStyles(!!isDone)),
      }),
    ]);
  };

  const redrawCircle = (
    center: mapkit.Coordinate,
    radius: number,
    shouldZoom?: boolean,
    isDone?: boolean,
  ) => {
    if (!map) return;
    removeOverlays();
    replacePoints([center], getType(isDone));
    map.isZoomEnabled = false;
    addCircle(center, radius, shouldZoom, isDone);
    map.isZoomEnabled = true;
  };

  const redrawPath = (
    points: mapkit.Coordinate[],
    radius: number,
    shouldZoom?: boolean,
    isDone?: boolean,
  ) => {
    if (!map) return;
    removeOverlays();
    replacePoints(points, getType(isDone));
    map.isZoomEnabled = false;
    addPath(points, radius, shouldZoom, isDone);
    map.isZoomEnabled = true;
  };

  const redrawPolygon = (
    points: mapkit.Coordinate[],
    isClosed: boolean,
    shouldZoom?: boolean,
    isDone?: boolean,
    hasIntersection?: boolean,
  ) => {
    if (!map) return;
    removeOverlays();
    replacePoints(points, getType(isDone, hasIntersection && isClosed));
    map.isZoomEnabled = false;
    addPolygon(points, isClosed, shouldZoom, isDone, hasIntersection);
    map.isZoomEnabled = true;
  };

  const handleCurrentRegionZoom = () => {
    if (map?.overlays[1]) {
      zoomToRegion(
        map.annotations
          .slice(1)
          .filter(({ data }) => data.label !== 'searchPin')
          .map((a) => a.coordinate),
        (map.overlays[1] as mapkit.CircleOverlay).radius,
        true,
      );
    }
  };

  return {
    overlays,
    addCircle,
    addPath,
    addPolygon,
    removeOverlays,
    redrawCircle,
    redrawPath,
    redrawPolygon,
    handleCurrentRegionZoom,
  };
};
