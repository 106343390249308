import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'store';
import { useChangePasswordMutation } from 'store/api/auth.api';
import { setAuthSuccess } from 'store/reducers/user/user.slice';
import { useMemo } from 'react';

interface ISetPasswordForm {
  password: string;
  confirmedPassword: string;
}

const newPasswordSchema = yup.object({
  password: yup.string().required('Password is a required field.'),
  confirmedPassword: yup
    .string()
    .required('Confirm password is a required field.'),
});

export const useNewPasswordForm = () => {
  const dispatch = useAppDispatch();
  const credentialId = useAppSelector((state) => state.user.credentialId) || 0;

  const resetToken =
    useMemo(
      () => new URLSearchParams(location.search).get('token'),
      [location.search],
    ) || '';

  const [changePasswd, { isLoading }] = useChangePasswordMutation();

  const methods = useForm<ISetPasswordForm>({
    resolver: yupResolver(newPasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (data: ISetPasswordForm) => {
    if (data.confirmedPassword !== data.password) {
      methods.setError('confirmedPassword', {
        type: 'match',
        message: "The passwords don't match.",
      });

      return;
    }

    changePasswd({
      credentialId,
      newPassword: data.password,
      oneTimeToken: resetToken,
    }).then((response) => {
      const error = (response as { error: unknown }).error;

      if (!error) {
        dispatch(setAuthSuccess());
      }
    });
  };

  return {
    methods,
    onSubmit,
    isLoading,
  };
};
