import { PasswordStrength } from 'models/auth.model';

const LEVELS: PasswordStrength[] = [
  'too short',
  'weak',
  'ok',
  'good',
  'strong',
];

const regExpTest = (regExp: RegExp, string: string) =>
  new RegExp(regExp).test(string);

const hasUppercaseLetter = (string: string) => regExpTest(/[A-Z]+/, string);

const hasLowercaseLetter = (string: string) => regExpTest(/[a-z]+/, string);

const hasNumber = (string: string) => regExpTest(/[0-9]+/, string);

const hasSpecialCharacter = (string: string) =>
  regExpTest(/[$&+,:;=?@#|<>.^*()%!]+/, string);

const hasMinLength = (string: string, minLength: number) =>
  string.length >= minLength;

export const checkPasswordStrength = (password: string): PasswordStrength => {
  let score = 0;

  if (!hasMinLength(password, 8)) {
    return LEVELS[score];
  }
  score++;

  if (
    !hasNumber(password) ||
    !hasLowercaseLetter(password) ||
    !hasUppercaseLetter(password)
  ) {
    return LEVELS[score];
  }
  score++;

  if (hasSpecialCharacter(password)) {
    score++;
  }

  if (hasMinLength(password, 12)) {
    score++;
  }

  return LEVELS[score];
};
