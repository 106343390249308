import { Box, Paper, PaperProps } from '@mui/material';

const PageContent = ({ children, ...rest }: PaperProps) => {
  const sx = rest.sx ?? {};
  return (
    <Paper
      elevation={2}
      {...rest}
      sx={({ breakpoints, spacing }) => ({
        maxHeight: `calc(100dvh - ${spacing(14)})`,
        [breakpoints.down('newMd')]: {
          maxHeight: `calc(100dvh - ${spacing(12)})`,
        },
        ...(sx as object),
      })}
    >
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        maxHeight="inherit"
      >
        {children}
      </Box>
    </Paper>
  );
};

export default PageContent;
