import { memo, useEffect } from 'react';
import { Drawer as MuiDrawer, useMediaQuery, useTheme } from '@mui/material';
import { sidebarWidth } from '../Layout';
import { getSideBarState, toggleSidebar } from 'services/sidebar';
import Sidebar from './Sidebar';

const Drawer = () => {
  const theme = useTheme();
  const isSidebarOpen = getSideBarState();

  const isDown2Xl = useMediaQuery(theme.breakpoints.down('new2Xl'));
  const isDownXl = useMediaQuery(theme.breakpoints.down('newXl'));

  useEffect(() => {
    const mainClasslist = document.getElementById('main-content')?.classList;
    if (mainClasslist) {
      isSidebarOpen
        ? mainClasslist.add('shrinked')
        : mainClasslist.remove('shrinked');
    }
  }, []);

  useEffect(() => {
    if (isDown2Xl && isSidebarOpen) {
      toggleSidebar();
    }
  }, [isDown2Xl]);

  return (
    <MuiDrawer
      sx={{
        width: sidebarWidth,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
        },
      }}
      variant={isDownXl ? 'temporary' : 'persistent'}
      anchor="left"
      open={isSidebarOpen}
      onClose={toggleSidebar}
    >
      <Sidebar />
    </MuiDrawer>
  );
};

export default memo(Drawer);
