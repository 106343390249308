import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const editNotification: IconifyJSON = {
  prefix: 'edit-notification',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M11.9983 21.6923C11.5007 21.6923 11.0753 21.5153 10.7221 21.1613C10.3689 20.8073 10.1923 20.3817 10.1923 19.8846H13.8077C13.8077 20.3833 13.6305 20.8093 13.2762 21.1625C12.9218 21.5157 12.4959 21.6923 11.9983 21.6923ZM4.50003 18.8846V17.3847H6.30773V9.92307C6.30773 8.57821 6.72279 7.38911 7.55291 6.35578C8.38304 5.32244 9.44875 4.66155 10.75 4.3731V3.75002C10.75 3.40281 10.8714 3.10768 11.1143 2.86463C11.3571 2.62156 11.652 2.50002 11.9989 2.50002C12.3458 2.50002 12.641 2.62156 12.8846 2.86463C13.1282 3.10768 13.25 3.40281 13.25 3.75002V4.3731C13.4718 4.4231 13.6846 4.48207 13.8885 4.55002C14.0923 4.61797 14.2904 4.70323 14.4827 4.80578L13.3116 5.96153C13.1 5.88973 12.8888 5.83332 12.6779 5.7923C12.467 5.75127 12.241 5.73075 12 5.73075C10.8423 5.73075 9.85416 6.14005 9.03558 6.95865C8.21698 7.77723 7.80768 8.76537 7.80768 9.92307V17.3847H16.1923V14.5442L17.6923 13.0443V17.3847H19.5V18.8846H4.50003ZM12.1154 12.8846V10.2423L17.5442 4.8385C17.6686 4.71413 17.8029 4.62663 17.947 4.576C18.0912 4.52535 18.2353 4.50002 18.3795 4.50002C18.5367 4.50002 18.6888 4.52952 18.8358 4.5885C18.9827 4.64747 19.1163 4.73593 19.2365 4.85388L20.1615 5.7885C20.2692 5.91285 20.3525 6.04746 20.4115 6.19233C20.4705 6.33719 20.5 6.48207 20.5 6.62695C20.5 6.77182 20.4731 6.91924 20.4192 7.06922C20.3654 7.21922 20.2795 7.35641 20.1615 7.48078L14.7577 12.8846H12.1154ZM13.3077 11.6923H14.2577L17.5039 8.43078L17.0442 7.95578L16.5789 7.48653L13.3077 10.7423V11.6923ZM17.0442 7.95578L16.5789 7.48653L17.5039 8.43078L17.0442 7.95578Z" fill="currentColor"/>',
    },
  },
};
