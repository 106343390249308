import { useProfileData } from 'hooks/use-profile-data';
import { useWindowFocus } from 'hooks/use-window-focus';
import { routes } from 'nav';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAppBarTooltip, setAppbarTitle } from 'services/custom-appbar-title';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetAlerterEventsByWidgetsQuery } from 'store/api/alerters.api';
import { useGetDashboardDataQuery } from 'store/api/dashboard.api';
import {
  useGetLinkedProfilesQuery,
  useGetProfileQuery,
} from 'store/api/profiles.api';
import { useGetRegionsQuery } from 'store/api/safe-zones.api';
import { setName } from 'store/reducers/user/user.slice';
import { getDashboardWidgets } from 'utils/get-dashboard-data';

const useDashboard = (id: number, isInDashboard?: boolean) => {
  const navigate = useNavigate();
  const isFocused = useWindowFocus();

  const caregiverId = useAppSelector((state) => state.user.accountId);
  const patientName = useAppSelector((state) => state.dashboard.patientName);
  const dispatch = useAppDispatch();

  const {
    data,
    isFetching,
    isLoading: isDashboardLoading,
    error,
  } = useGetDashboardDataQuery(
    {
      account_id: Number(id),
    },
    {
      pollingInterval: isFocused ? 60000 : undefined,
      refetchOnReconnect: true,
    },
  );

  useGetAlerterEventsByWidgetsQuery(
    {
      profileId: Number(id),
    },
    {
      pollingInterval: isFocused ? 60000 : undefined,
      refetchOnReconnect: true,
    },
  );

  const { data: linkedProfilesData, isFetching: isLinkedProfilesFetching } =
    useGetLinkedProfilesQuery(
      { caregiverId: Number(caregiverId) },
      { skip: !caregiverId },
    );

  const { data: profileData, isFetching: isProfileDataFetching } =
    useGetProfileQuery({ id });
  const {
    data: regions = {
      circleRegions: [],
      pathRegions: [],
      polygonRegions: [],
    },
    isFetching: isLoadingRegions,
  } = useGetRegionsQuery({ id: Number(id) ?? 0 });

  useEffect(() => {
    if (error) {
      toast(
        'A patient with the provided identifier could not be found. Please try again.',
        { type: 'error' },
      );
      navigate(routes.private.patientsOverview.href);
    }
  }, [error]);

  const widgets = getDashboardWidgets(data, {
    isHealthDataCollected: profileData?.result.collects_health_data,
    isLocationDataCollected: profileData?.result.collects_location_data,
    hasSeizureDetection: profileData?.result.requests_seizure_monitoring,
  });

  const isLoading = isLinkedProfilesFetching || isFetching;
  const location = data?.location;
  const accountId = data?.accountId;
  const deviceId = data?.deviceId;
  const name = data?.name;

  useProfileData({ deviceId });

  const hasActiveSubscription = useMemo(() => {
    if (linkedProfilesData) {
      const matchedProfile = linkedProfilesData.results.find(
        (item) => item.account_id === Number(id),
      );

      if (matchedProfile) {
        return matchedProfile.lo_account_has_active_subscription;
      }

      return true;
    }

    return true;
  }, [linkedProfilesData]);

  useEffect(() => {
    if (data?.name) {
      dispatch(setName({ firstName: data.name }));
    }
  }, [data?.name]);

  useEffect(() => {
    setAppbarTitle(patientName ? `${patientName}'s Dashboard` : 'Dashboard');
  }, [patientName]);

  useEffect(() => {
    if (profileData?.result && isInDashboard) {
      setAppBarTooltip(
        <span>
          BoundaryCare identifier:{' '}
          {profileData?.result.unique_public_identifier} <br />
          Customer identifier:{' '}
          {profileData?.result.third_party_public_identifier}
        </span>,
      );
    }
    return () => {
      setAppBarTooltip(null);
    };
  }, [profileData]);

  return {
    isFetching: isLoading,
    isRegionsFetching: isLoadingRegions,
    isProfileDataFetching,
    hasActiveSubscription,
    widgets,
    location,
    regions,
    accountId,
    deviceId,
    name,
    isDashboardLoading,
    trackingExpiresOn: profileData?.result.emergency_tracking_expires,
    isLocationDisabled: !profileData?.result.collects_location_data,
    hasNoRecord: data === null,
  };
};

export default useDashboard;
