import { Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import CodeEnterMessage from 'components/Mfa/components/CodeEnterMessage';
import { useCodeInput } from 'hooks/use-code-input';
import CodeInput from 'pages/Mfa/components/CodeInput';

const CodeConfirmation = ({
  phoneNumber,
  onPrevStep,
  secondsLeft,
  setSecondsLeft,
  credentialId,
  onConfirmation,
}: {
  phoneNumber: string;
  onPrevStep?: () => void;
  secondsLeft?: number;
  setSecondsLeft?: (seconds: number) => void;
  credentialId?: number;
  onConfirmation?: () => void;
}) => {
  const {
    handleCodeResend,
    handleInputCompletion,
    error,
    handleSubmit,
    handleInputReset,
    isSubmitDisabled,
    isLoading,
  } = useCodeInput({
    identifier: phoneNumber,
    credentialId,
    onCompletion: onConfirmation,
    setRemainingSeconds: setSecondsLeft,
  });

  return (
    <>
      <Box display={isLoading ? 'none' : undefined}>
        <CodeEnterMessage
          onEditCredential={onPrevStep}
          type="phone"
          credential={phoneNumber}
        />
        <CodeInput
          onComplete={handleInputCompletion}
          onRemoving={handleInputReset}
          onSubmit={handleSubmit}
          error={
            error
              ? {
                  type: 'validate',
                  message: error,
                }
              : undefined
          }
        />
        <Typography variant="body2" align="center">
          It may take a minute to receive your code. <br />
          Didn&apos;t receive the code? {}
          {!secondsLeft ? (
            <Link
              sx={{ color: ({ palette }) => palette.red.main }}
              onClick={handleCodeResend}
            >
              Resend it
            </Link>
          ) : (
            `Resend it in ${secondsLeft} seconds.`
          )}
        </Typography>
        <Button
          size="large"
          sx={{ mt: 3 }}
          fullWidth
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>
      </Box>
      <Box
        display={isLoading ? 'flex' : 'none'}
        flexDirection="column"
        alignItems="center"
      >
        <Typography textAlign="center" variant="h5">
          Verifying...
        </Typography>
        <Typography
          textAlign="center"
          color="blue.main"
          variant="body2"
          maxWidth="400px"
          pb={4}
          px={1}
        >
          This could take a while. Please wait until your information will be
          verified.
        </Typography>
        <CircularProgress
          sx={{
            p: 1,
            pb: 4,
          }}
          size={72}
        />
      </Box>
    </>
  );
};

export default CodeConfirmation;
