import { appleWatch } from 'assets/iconify/apple-watch';
import { WornStatus } from 'models/dashboard.model';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';

export const getDeviceConfigStatus = (
  hasConfiguredDevice: boolean,
  wornStatus: WornStatus | undefined,
  isActive?: boolean,
  isLocked?: boolean,
): DataCellProps => {
  if (!isActive) {
    return {
      icon: appleWatch.icons.disconnected,
      label: 'Disconnected',
      status: DataStatus.NEGATIVE,
    };
  }

  if (hasConfiguredDevice && wornStatus === WornStatus.WORN) {
    return {
      icon: appleWatch.icons.ok,
      label: 'Connected',
      status: DataStatus.POSITIVE,
    };
  }

  if (wornStatus === WornStatus.NOT_WORN) {
    return {
      icon: appleWatch.icons['not-worn'],
      label: 'Not worn',
      status: DataStatus.NEGATIVE,
    };
  }

  if (isLocked) {
    return {
      icon: appleWatch.icons.locked,
      label: 'Locked',
      status: DataStatus.NEGATIVE,
    };
  }

  if (wornStatus === WornStatus.WORN) {
    return {
      icon: appleWatch.icons['on-wrist'],
      label: 'On wrist',
      status: DataStatus.POSITIVE,
    };
  }

  if (isActive) {
    return {
      icon: appleWatch.icons.connected,
      label: 'Active',
      status: DataStatus.POSITIVE,
    };
  }

  if (hasConfiguredDevice) {
    return {
      icon: appleWatch.icons.configured,
      label: 'Configured',
      status: DataStatus.POSITIVE,
    };
  }

  return {
    icon: appleWatch.icons.misconfigured,
    label: 'Misconfigured',
    status: DataStatus.NEGATIVE,
  };
};
