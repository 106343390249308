import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const chevronLeft: IconifyJSON = {
  prefix: 'chevron-left',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M14 17.6538L8.34616 12L14 6.34618L15.0538 7.4L10.4538 12L15.0538 16.6L14 17.6538Z" fill="currentColor"/>',
    },
  },
};
