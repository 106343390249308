import { Link } from '@mui/material';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';

const VerificationError: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      Please check if you typed your email correctly. <br />
      If you are not registered yet{' '}
      <Link
        color="secondary"
        fontWeight={600}
        onClick={() => navigate(routes.public.register.href)}
      >
        {' '}
        click here.
      </Link>
    </>
  );
};

export default VerificationError;
