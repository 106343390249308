import {
  Box,
  Tab,
  Tabs,
  buttonClasses,
  styled,
  tabClasses,
  tabsClasses,
} from '@mui/material';
import Alerts from './components/Alerts';
import { useEffect, useState } from 'react';
import { SharedRecipientSets } from './components/RecipientSets';
import { PageContent } from 'components/_extra/generic';
import { CreateAlertButton } from './components/CreateAlertButton';
import { useAlerters } from './hooks/use-alerters';
import { AlertHistory } from './components/AlertHistory';
import AlertHistoryTable from './components/AlertHistory/AlertHistoryTable';
import CreateRecipientSetButton from './components/RecipientSets/CreateRecipientSetButton';
import { useRecipientSets } from 'hooks/use-recipient-sets';
import { useSearchParams } from 'react-router-dom';

const AlertersTabIndex = {
  ALERTS: 0,
  RECIPIENTS_SETS: 1,
  HISTORY: 2,
} as const;

type AlertersTabIndex =
  (typeof AlertersTabIndex)[keyof typeof AlertersTabIndex];

const getTabPanelContent = (index: AlertersTabIndex) => {
  switch (index) {
    case AlertersTabIndex.ALERTS:
      return <Alerts />;
    case AlertersTabIndex.RECIPIENTS_SETS:
      return <SharedRecipientSets />;
    case AlertersTabIndex.HISTORY:
      return <AlertHistory />;
    default:
      <></>;
  }
};

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [`.${tabClasses.root}:not(.${tabClasses.selected})`]: {
    color: theme.palette.gray.light,
    opacity: 0.7,
  },
  [`.${tabsClasses.root}:has(> .${tabsClasses.scrollButtons})`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`.${tabsClasses.scrollButtons}`]: {
    color: theme.palette.common.white,
    [`&.${buttonClasses.disabled}`]: {
      opacity: 0.4,
    },
    svg: {
      padding: theme.spacing(0.5),
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.hover.dark,
      },
    },
  },
}));

const Alerters = () => {
  const [isViewCollapsed, setIsViewCollapsed] = useState(false);
  const [currentTab, setCurrentTab] = useState<AlertersTabIndex>(
    AlertersTabIndex.ALERTS,
  );
  const { alerterCount } = useAlerters();
  const { sets } = useRecipientSets();

  const toggleView = () => {
    setIsViewCollapsed((prev) => !prev);
  };

  const [params] = useSearchParams();

  useEffect(() => {
    if (params.has('historyFor')) {
      setCurrentTab(AlertersTabIndex.HISTORY);
    }
  }, []);

  return (
    <Box
      height={({ spacing }) => ({
        xxs: `calc(100dvh - ${spacing(12)})`,
        newMd: `calc(100dvh - ${spacing(14)})`,
      })}
      display="flex"
      flexDirection="column"
    >
      <PageContent>
        <Wrapper>
          <Tabs
            sx={{ px: { xxs: 0, newLg: 2 } }}
            variant="scrollable"
            allowScrollButtonsMobile
            value={currentTab}
            onChange={(e, tab) => {
              setCurrentTab(tab);
              setIsViewCollapsed(false);
            }}
          >
            <Tab
              sx={{ px: 2 }}
              value={AlertersTabIndex.ALERTS}
              label="Alert List"
            />
            <Tab
              value={AlertersTabIndex.RECIPIENTS_SETS}
              label="Recipient Sets"
            />
            <Tab
              sx={{ px: 2 }}
              value={AlertersTabIndex.HISTORY}
              label="Alert History"
            />
          </Tabs>
        </Wrapper>
        <Box
          sx={{
            padding: ({ spacing }) => {
              if (isViewCollapsed) {
                return 0;
              }
              const withoutPadding =
                (currentTab === AlertersTabIndex.ALERTS && alerterCount > 0) ||
                (currentTab === AlertersTabIndex.RECIPIENTS_SETS &&
                  sets.length > 0);
              return {
                xxs: spacing(2, 1.5, withoutPadding ? 0 : 2),
                xs: spacing(2, 2, withoutPadding ? 0 : 2),
                sm: spacing(3, 3, withoutPadding ? 0 : 3),
                newMd: spacing(4, 4, withoutPadding ? 0 : 4),
              };
            },
          }}
          overflow={isViewCollapsed ? 'hidden' : 'auto'}
          height={isViewCollapsed ? '0px' : '100%'}
        >
          {getTabPanelContent(currentTab)}
        </Box>
        {currentTab === AlertersTabIndex.ALERTS && (
          <Box
            padding={
              alerterCount > 0
                ? ({ spacing }) => ({
                    xxs: spacing(2, 1.5),
                    xs: spacing(2),
                    sm: spacing(2, 3, 3),
                    newLg: spacing(2, 4, 4),
                  })
                : 0
            }
            display="flex"
            justifyContent="end"
          >
            {alerterCount > 0 && <CreateAlertButton />}
          </Box>
        )}
        {currentTab === AlertersTabIndex.RECIPIENTS_SETS && (
          <Box
            padding={
              sets.length > 0
                ? ({ spacing }) => ({
                    xxs: spacing(2, 1.5),
                    xs: spacing(2),
                    sm: spacing(2, 3, 3),
                    newMd: spacing(2, 4, 4),
                  })
                : 0
            }
            mr={{ xxs: 0, newLg: 1 }}
            display="flex"
            justifyContent="end"
          >
            {sets.length > 0 && <CreateRecipientSetButton />}
          </Box>
        )}
      </PageContent>
      {currentTab === AlertersTabIndex.HISTORY && (
        <AlertHistoryTable
          toggleCollapse={toggleView}
          isViewCollapsed={isViewCollapsed}
        />
      )}
    </Box>
  );
};

export default Alerters;
