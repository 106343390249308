import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const imagePlaceholder: IconifyJSON = {
  prefix: 'image-placeholder',
  icons: {
    normal: {
      ...defaultSizes,
      body: `<mask id="mask0_9722_273203" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" />
      </mask>
      <g mask="url(#mask0_9722_273203)">
      <path d="M5.30773 20.5C4.8026 20.5 4.37503 20.325 4.02503 19.975C3.67503 19.625 3.50003 19.1974 3.50003 18.6923V5.30773C3.50003 4.8026 3.67503 4.37503 4.02503 4.02503C4.37503 3.67503 4.8026 3.50003 5.30773 3.50003H18.6923C19.1974 3.50003 19.625 3.67503 19.975 4.02503C20.325 4.37503 20.5 4.8026 20.5 5.30773V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.30773ZM6.75006 16.75H17.3269L14.0385 12.3654L11.2308 16.0192L9.23078 13.4616L6.75006 16.75ZM8.50001 9.74998C8.84616 9.74998 9.14102 9.62819 9.38461 9.38461C9.62819 9.14102 9.74998 8.84616 9.74998 8.50001C9.74998 8.15386 9.62819 7.85899 9.38461 7.61541C9.14102 7.37182 8.84616 7.25003 8.50001 7.25003C8.15386 7.25003 7.85899 7.37182 7.61541 7.61541C7.37182 7.85899 7.25003 8.15386 7.25003 8.50001C7.25003 8.84616 7.37182 9.14102 7.61541 9.38461C7.85899 9.62819 8.15386 9.74998 8.50001 9.74998Z" />
      </g>`,
    },
  },
};
