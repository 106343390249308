import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const sleepTime: IconifyJSON = {
  prefix: 'sleep-time',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12.0249 20.5C9.65824 20.5 7.6499 19.675 5.9999 18.025C4.3499 16.375 3.5249 14.3667 3.5249 12C3.5249 9.73338 4.29157 7.79172 5.8249 6.17505C7.35824 4.55838 9.24157 3.68338 11.4749 3.55005C11.6082 3.55005 11.7459 3.55405 11.8879 3.56205C12.0292 3.57072 12.1666 3.58338 12.2999 3.60005C11.7832 4.08338 11.3749 4.66238 11.0749 5.33705C10.7749 6.01238 10.6249 6.73338 10.6249 7.50005C10.6249 9.13338 11.1999 10.525 12.3499 11.675C13.4999 12.825 14.8916 13.4 16.5249 13.4C17.3082 13.4 18.0332 13.25 18.6999 12.95C19.3666 12.65 19.9332 12.2417 20.3999 11.725C20.4332 11.8584 20.4542 11.9957 20.4629 12.137C20.4709 12.279 20.4749 12.4167 20.4749 12.55C20.3416 14.7834 19.4666 16.6667 17.8499 18.2C16.2332 19.7334 14.2916 20.5 12.0249 20.5Z" fill="currentColor"/>',
    },
  },
};
