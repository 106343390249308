import { Icon } from '@iconify/react';
import { Button, Modal } from '@mui/material';
import { memo, useRef, useState } from 'react';
import { RecipientSetCreator } from 'components/_extra/Alerter/RecipientSetCreator';
import { caregiverRole } from 'services/caregiver-role';

const CreateRecipientSetButton = ({
  isReminder = false,
}: {
  isReminder?: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const functionRef = useRef<{ submitForm: () => void; isError: boolean }>(
    null,
  );

  return (
    <>
      <Button
        disabled={caregiverRole.value === 'viewer'}
        onClick={() => setIsModalOpen(true)}
        startIcon={<Icon icon="material-symbols:add-box-outline" />}
      >
        Add Set
      </Button>

      <Modal
        open={isModalOpen}
        disableEscapeKeyDown
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <RecipientSetCreator
          submitFormRef={functionRef}
          withScheduler={!isReminder}
          onClose={() => setIsModalOpen(false)}
        >
          <Button
            onClick={() => setIsModalOpen(false)}
            variant="icon-text"
            size="small"
            sx={({ palette }) => ({
              position: 'absolute',
              color: palette.blue.light,
              right: 10,
              top: 10,
              ':hover': {
                color: palette.blue.dark,
              },
            })}
          >
            <Icon icon="material-symbols:close" />
          </Button>
        </RecipientSetCreator>
      </Modal>
    </>
  );
};

export default memo(CreateRecipientSetButton);
