import { Icon } from '@iconify/react';
import { Box, Button, Modal } from '@mui/material';
import { ScheduleRuleForm } from 'components/_extra/Alerter/Cards/ScheduleRuleCard';
import {
  ModalHeader,
  ModalWrapper,
} from 'pages/AlerterDetails/components/Triggers/styled';
import { memo, useState } from 'react';
import { useAddTrigger } from '../hooks/use-add-trigger';
import { caregiverRole } from 'services/caregiver-role';
const AddTriggerButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const { handleSubmit, functionsRef } = useAddTrigger(handleClose);

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        startIcon={<Icon icon="material-symbols:add-box-outline" />}
        disabled={caregiverRole.value === 'viewer'}
      >
        Add Trigger
      </Button>
      <Modal open={isModalOpen}>
        <ModalWrapper>
          <ModalHeader>Trigger Setup</ModalHeader>
          <Box p={3}>
            <ScheduleRuleForm getValuesRef={functionsRef} />
            <Box display="flex" justifyContent="end" gap={2} pt={4}>
              <Button
                size="medium"
                color="primary"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                color="primary"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default memo(AddTriggerButton);
