import { Box, Switch, SxProps, Theme } from '@mui/material';
import { Wrapper } from '../styled';
import {
  CancelButton,
  RemoveButton,
  SaveButton,
} from 'components/_extra/Buttons';
import ScheduleRuleForm from './ScheduleRuleForm';
import { Ref } from 'react';
import { GetTriggerFormRef } from 'pages/ReminderDetails/hooks/use-add-trigger';
import { ScheduleRuleValues } from '..';
import { useScheduleRuleCard } from 'hooks/use-schedule-rule-card';

interface Props {
  id: number;
  isActive: boolean;
  onRemove?: () => void;
  onEdit?: () => void;
  onSave?: () => void;
  onBackClick?: () => void;
  getValuesRef: Ref<GetTriggerFormRef>;
  defaultValues?: ScheduleRuleValues;
  isSwitchDisabled?: boolean;
  onSwitchChange?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
}

const Card = ({
  id,
  getValuesRef,
  onEdit,
  onRemove,
  onSave,
  onBackClick,
  isActive,
  isSwitchDisabled,
  defaultValues,
  onSwitchChange,
  sx,
}: Props) => {
  const { isRuleDifferent, isEdited, readOnly } = useScheduleRuleCard(id);

  return (
    <Wrapper
      isActive={isEdited}
      isClickable={false}
      sx={{
        minWidth: ({ spacing }) => spacing(31.5),
        flexGrow: 1,
        flexBasis: 0,
        width: 'auto',
        zIndex: ({ zIndex }) => (isEdited ? zIndex.modal : 'auto'),
        ...sx,
      }}
    >
      <Box flexGrow={1}>
        <Box display="flex" alignItems="center" gap={4} mb={2}>
          <Box flexGrow={1}>
            <Box display="flex" alignItems="center" gap={2} maxHeight="24px">
              <Switch
                disabled={isSwitchDisabled || readOnly}
                size="small"
                color="secondary"
                checked={isActive}
                onChange={(e, checked) => onSwitchChange?.(checked)}
              />
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            {isEdited && isRuleDifferent ? (
              <>
                <SaveButton onClick={onSave} />
                <CancelButton onClick={onBackClick} tooltipText="Exit" />
              </>
            ) : (
              <RemoveButton
                disabled={!onRemove || readOnly}
                onClick={onRemove}
                tooltipText="Delete"
              />
            )}
          </Box>
        </Box>
        <ScheduleRuleForm
          id={id}
          defaultValues={defaultValues}
          getValuesRef={getValuesRef}
          onEdit={isEdited || readOnly ? undefined : onEdit}
        />
      </Box>
    </Wrapper>
  );
};

export default Card;
