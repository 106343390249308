import { Box, Button, CircularProgress, Typography } from '@mui/material';
import VInput from 'components/VInput';
import { useNavigationBlocker } from 'hooks/use-navigation-blocker';
import { usePatientInfo } from 'pages/PatientAccount/hooks/use-patient-info';
import { useMemo, useRef, useState } from 'react';
import { caregiverRole } from 'services/caregiver-role';
import { PhoneNumberDisplay } from './PhoneNumberDisplay';

const GeneralSettings = () => {
  const {
    profileId,
    firstName,
    lastName,
    isLoading,
    bcIdentifier,
    customIdentifier,
    handleUpdateProfile,
  } = usePatientInfo();

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const bcIdentifierRef = useRef<HTMLInputElement>(null);
  const customIdentifierRef = useRef<HTMLInputElement>(null);

  const [firstNameError, setFirstNameError] = useState('');
  const [isModified, setIsModified] = useState<boolean>(false);

  const readOnly = useMemo(() => {
    return caregiverRole.value === 'viewer';
  }, [caregiverRole.value]);

  const handleDiscardClick = () => {
    if (
      firstNameRef.current &&
      lastNameRef.current &&
      customIdentifierRef.current
    ) {
      firstNameRef.current.value = firstName ?? '';
      lastNameRef.current.value = lastName ?? '';
      customIdentifierRef.current.value = customIdentifier ?? '';
    }
    setIsModified(false);
  };

  useNavigationBlocker(isModified, {
    message: 'Are you sure you want to leave?',
    title: 'You have unsaved changes.',
    onDiscard: handleDiscardClick,
  });

  const watchForChanges = () => {
    setIsModified(true);
  };

  const handleUpdateClick = () => {
    if (firstNameRef.current && firstNameRef.current.value.length === 0) {
      setFirstNameError('First name is required.');
      return;
    }

    setFirstNameError('');
    setIsModified(false);
    handleUpdateProfile({
      firstName: firstNameRef.current?.value ?? '',
      lastName: lastNameRef.current?.value ?? '',
      identifier: customIdentifierRef.current?.value ?? '',
    });
  };

  return (
    <div>
      <Typography color="primary" fontWeight={600} mb={1}>
        Patient Profile
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Box maxWidth={({ spacing }) => spacing(67)}>
          <Box px={{ xxs: 1, newMd: 2 }} py={2}>
            <Box display="flex" gap={2} pb={3} flexWrap="wrap">
              <VInput
                onChange={watchForChanges}
                disableWhitespace
                label="First name"
                size="medium"
                error={firstNameError.length > 0}
                helperText={firstNameError}
                inputRef={firstNameRef}
                placeholder="Enter first name"
                defaultValue={firstName}
                readOnly={readOnly}
                containerSx={({ spacing }) => ({
                  minWidth: spacing(26),
                  flexBasis: 0,
                  flexGrow: 1,
                })}
              />
              <VInput
                onChange={watchForChanges}
                disableWhitespace
                label="Last name"
                size="medium"
                inputRef={lastNameRef}
                defaultValue={lastName}
                placeholder="Enter last name"
                readOnly={readOnly}
                containerSx={({ spacing }) => ({
                  minWidth: spacing(26),
                  flexBasis: 0,
                  flexGrow: 1,
                })}
              />
            </Box>
            <Box gap={2} pb={3} width="100%">
              <VInput
                onChange={watchForChanges}
                disableWhitespace
                label="BoundaryCare identifier"
                size="medium"
                defaultValue={bcIdentifier}
                inputRef={bcIdentifierRef}
                readOnly={true}
                fullWidth
                placeholder="BoundaryCare Identifier"
              />
            </Box>
            <VInput
              onChange={watchForChanges}
              disableWhitespace
              label="Custom identifier"
              size="medium"
              defaultValue={customIdentifier}
              inputRef={customIdentifierRef}
              readOnly={readOnly}
              width="100%"
              placeholder="Patient ID, insurance ID, billing ID or other ID types"
            />
            {profileId && (
              <Box pt={3}>
                <PhoneNumberDisplay profileId={parseInt(profileId)} />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            gap={2}
            sx={{
              pt: { xxs: 2, sm: firstNameError.length > 0 ? 1.125 : 2.5 },
              px: { xxs: 1, newMd: 2 },
            }}
            pb={2}
            justifyContent="end"
          >
            <Button
              variant="outlined"
              disabled={!isModified}
              color="primary"
              onClick={handleDiscardClick}
              sx={{
                flexBasis: { xxs: '50%', sm: 'unset' },
              }}
            >
              Discard
            </Button>
            <Button
              disabled={!isModified}
              color="primary"
              onClick={handleUpdateClick}
              sx={{
                flexBasis: { xxs: '50%', sm: 'unset' },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default GeneralSettings;
