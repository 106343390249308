import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { Filters } from 'store/reducers/filters/types';
import { RootState } from 'store/store';

const getIsVisible = (
  fieldName: keyof Filters['appleWatch'],
  state: RootState,
) => state.filters.filters.appleWatch[fieldName].isInvisible ?? false;

export default createDraftSafeSelector(
  (state: RootState) => getIsVisible('battery', state),
  (state: RootState) => getIsVisible('bloodOxygen', state),
  (state: RootState) => getIsVisible('device', state),
  (state: RootState) => getIsVisible('fallDetection', state),
  (state: RootState) => getIsVisible('heartRate', state),
  (state: RootState) => getIsVisible('heartRhythm', state),
  (state: RootState) => getIsVisible('respiratoryRate', state),
  (state: RootState) => getIsVisible('safeZone', state),
  (state: RootState) => getIsVisible('sleepTime', state),
  (state: RootState) => getIsVisible('seizureDetected', state),
  (state: RootState) => getIsVisible('helpRequested', state),
  (
    battery,
    bloodOxygen,
    device,
    fallDetection,
    heartRate,
    heartRhythm,
    respiratoryRate,
    safeZone,
    sleepTime,
    seizureDetected,
    helpRequested,
  ) => {
    const columns = {
      battery,
      bloodOxygen,
      device,
      fallDetection,
      heartRate,
      heartRhythm,
      respiratoryRate,
      safeZone,
      sleepTime,
      seizureDetected,
      helpRequested,
    } as const;

    return (Object.keys(columns) as (keyof typeof columns)[]).filter(
      (key) => !columns[key],
    );
  },
);
