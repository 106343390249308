import { useConfirmation } from 'hooks/use-confirmation';
import { IAccountRequest } from 'models/account.model';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { caregiverRole } from 'services/caregiver-role';
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from 'store/api/profiles.api';

export const useProfileConfig = (
  fieldName: keyof IAccountRequest,
  confirmationMessage?: string,
  shouldAskOnDisabling?: boolean,
) => {
  const confirm = useConfirmation();
  const { profileId } = useParams<{ profileId: string }>();

  const { data, isLoading } = useGetProfileQuery({ id: Number(profileId) });
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();

  const [value, setValue] = useState(false);

  const handleChange = (checked: boolean) => {
    if (!checked && shouldAskOnDisabling) {
      confirm(
        {
          title: 'Are you sure?',
          message: confirmationMessage,
        },
        () => {
          updateProfile({
            id: Number(profileId),
            [fieldName]: false,
          });
          setValue(false);
        },
      );

      return;
    }

    updateProfile({
      id: Number(profileId),
      [fieldName]: checked,
    });
    setValue(checked);
  };

  useEffect(() => {
    if (data) {
      setValue(!!data.result[fieldName]);
    }
  }, [data]);

  return {
    isLoading,
    isUpdating,
    value,
    handleChange,
    config: data?.result,
    readOnly: caregiverRole.value === 'viewer',
  };
};
