export const clearAllProdCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(
        /=.*/,
        '=;expires=' + new Date().toUTCString() + ';path=.boundarycare.com',
      );
  });
};
