import { GridColDef } from '@mui/x-data-grid-pro';
import { Device } from 'types/device';
import { commonColumnProperties } from './records-columns/columns';

export const adminDevicesColumns: GridColDef<Device>[] = [
  {
    ...commonColumnProperties,
    field: 'id',
    headerName: 'Device ID',
    width: 150,
  },
  {
    ...commonColumnProperties,
    field: 'active',
    headerName: 'Is active',
    width: 150,
    renderCell: (params) => {
      return params.value ? 'Yes' : 'No';
    },
  },
  {
    ...commonColumnProperties,
    field: 'account_id',
    headerName: 'Account ID',
    width: 150,
  },
  {
    ...commonColumnProperties,
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
  {
    ...commonColumnProperties,
    field: 'model',
    headerName: 'Model',
    width: 150,
  },
  {
    ...commonColumnProperties,
    field: 'system',
    headerName: 'System',
    width: 150,
  },
];
