import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import {
  safeZonesApi,
  useCreateRegionMutation,
  useEditRegionMutation,
} from 'store/api/safe-zones.api';
import { RegionsType, mappedFormattedRegions } from 'types/safe-zones';
import { calculatePathCentroid, calculatePolygonCentroid } from 'utils/map';

interface UseSafeZoneListArgs {
  selectedShape: RegionsType;
  radius: number;
  saveShape: () => void;
  setIsLoading: (value: boolean) => void;
  modifyExisting: boolean;
  shapeId?: number;
  deactivateSaveButton: () => void;
  onClose: () => void;
  map: mapkit.Map | null;
}

export const useSaveNewRegion = ({
  map,
  radius,
  saveShape,
  selectedShape,
  modifyExisting,
  shapeId,
  onClose,
  setIsLoading,
  deactivateSaveButton,
}: UseSafeZoneListArgs) => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const [create, createState] = useCreateRegionMutation();
  const [edit, editState] = useEditRegionMutation();
  const toastRef = useRef<Id>(0);

  const { isError, isLoading, isSuccess, data } = modifyExisting
    ? editState
    : createState;

  const handleSaveSafeZone = useCallback(
    (title: string) => {
      if (!map) return;

      const points = map.annotations
        .filter(({ data }) => data.label !== 'searchPin')
        .slice(1)
        .map((a) => a.coordinate);

      const { latitude, longitude } =
        selectedShape === RegionsType.POLYGON_TYPE
          ? calculatePolygonCentroid(
              points.map((value) => [value.latitude, value.longitude]) || [],
            ) ?? { latitude: 0, longitude: 0 }
          : selectedShape === RegionsType.PATH_TYPE
          ? calculatePathCentroid(points || []) ?? {
              latitude: 0,
              longitude: 0,
            }
          : points[0];

      if (map.overlays[1]) {
        modifyExisting && shapeId
          ? edit({
              id: shapeId,
              name: title,
              radius:
                selectedShape === RegionsType.CIRCLE_TYPE ? radius : undefined,
              shapeType: selectedShape,
              notifications: true,
              lat: latitude,
              lng: longitude,
              geofence: points?.map((point) => [
                point.latitude,
                point.longitude,
              ]),
              accountId: Number(profileId),
            })
          : create({
              name: title,
              radius,
              shapeType: selectedShape,
              notifications: true,
              lat: latitude,
              lng: longitude,
              geofence: points?.map((point) => [
                point.latitude,
                point.longitude,
              ]),
              accountId: Number(profileId),
            });
      }
    },
    [selectedShape, radius, map?.annotations],
  );

  useEffect(() => {
    setIsLoading(false);

    if (isSuccess && data) {
      onClose();

      if (toastRef.current) {
        toast.update(toastRef.current, {
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          render: `Safe zone was ${
            modifyExisting ? 'edited' : 'created'
          } successfully!`,
        });
      }

      saveShape();
      if (!modifyExisting) {
        dispatch(
          safeZonesApi.util.updateQueryData(
            'getRegions',
            { id: Number(profileId) },
            (draft) => {
              draft[mappedFormattedRegions[data.shapeType]] = [
                ...draft[mappedFormattedRegions[data.shapeType]],
                data,
              ];
            },
          ),
        );
      }
      return;
    }
    if (data) {
      dispatch(
        safeZonesApi.util.updateQueryData(
          'getRegions',
          { id: Number(profileId) },
          (draft) => {
            const length = draft[mappedFormattedRegions[data.shapeType]].length;
            for (let i = 0; i < length; i++) {
              if (
                draft[mappedFormattedRegions[data.shapeType]][i].id === data.id
              ) {
                draft[mappedFormattedRegions[data.shapeType]][i] = data;
              }
            }
          },
        ),
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    setIsLoading(false);
    if (isError) {
      if (toastRef.current) {
        toast.update(toastRef.current, {
          type: 'error',
          isLoading: false,
          autoClose: 2000,
          render: 'Something went wrong.',
        });
      }
    }
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      deactivateSaveButton();
      setIsLoading(true);
      toastRef.current = toast.loading('Saving the safe zone...');
    }
  }, [isLoading]);

  return {
    handleSaveSafeZone,
    isLoading,
  };
};
