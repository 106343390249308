import { Box, Switch, Typography, tooltipClasses } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { Controller, useFormContext } from 'react-hook-form';
import { Organization } from 'types/organizations';

interface Props {
  disabled?: boolean;
}

const Security = ({ disabled }: Props) => {
  const { control } = useFormContext<Organization>();

  return (
    <div>
      <Typography color="primary" fontWeight={600}>
        Security
      </Typography>
      <Box
        display="flex"
        py={2}
        px={{ xxs: 1, newMd: 2 }}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={({ spacing }) => spacing(67)}
      >
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="body1" color="primary">
            Force 2FA for All Users
          </Typography>
          <HelpIcon
            tooltipSx={{
              [`.${tooltipClasses.tooltip}`]: {
                maxWidth: ({ spacing }) => spacing(41.5),
              },
            }}
            tooltipPlacement="top-start"
            message="This option will enforce two-factor authentication (2FA) setup for all users invited to the current organization."
          />
        </Box>
        <Controller
          name="requiresMfa"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch disabled={disabled} checked={value} onChange={onChange} />
          )}
        />
      </Box>
    </div>
  );
};

export default Security;
