import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import {
  useDeliverCodeMutation,
  useGetMFACredentialsQuery,
} from 'store/api/auth.api';

export const useCredentialSelect = () => {
  const methods = useAppSelector((state) => state.mfa.availableMethods);

  const { isFetching } = useGetMFACredentialsQuery();
  const [deliverCode, { isLoading }] = useDeliverCodeMutation();

  const setActiveMethod = (methodId: number) => {
    deliverCode({ id: methodId }).then((response) => {
      if ((response as Record<string, unknown>).error) {
        toast('A server error has occured. Please try again.', {
          type: 'error',
        });
      }
    });
  };

  return { methods, isFetching, isLoading, setActiveMethod };
};
