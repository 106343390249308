import { InputBase, InputBaseProps, styled } from '@mui/material';
import { forwardRef, useRef } from 'react';
import MessageVariablesMenu from './MessageVariablesMenu';

type Props = InputBaseProps & { readOnly?: boolean };

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  &:focus-within {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Toolbar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.disabled.light};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const Input = styled(InputBase)`
  border-color: transparent;
  border: none;
  outline: none;
  padding: ${({ theme }) => theme.spacing(1.5)};
  &.read-only {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;

const CustomMessageField = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleVariableInsert = (value: string) => {
    const input = inputRef.current;
    if (!input || props.readOnly) return;

    const { selectionStart, selectionEnd } = input;
    const text = input.value;
    const newText = `${text.slice(0, selectionStart)}:${value}:${text.slice(
      selectionEnd,
    )}`;
    input.value = newText;
    input.selectionStart = selectionStart + value.length + 2;
    input.selectionEnd = input.selectionStart;

    input.focus();
  };

  return (
    <Root ref={ref}>
      <Toolbar>
        <MessageVariablesMenu
          onSelect={handleVariableInsert}
          readOnly={props.readOnly}
        />
      </Toolbar>
      <Input
        className={props.readOnly ? 'read-only' : ''}
        size="small"
        multiline
        rows={4}
        placeholder="Create your custom message..."
        {...props}
        inputRef={inputRef}
      />
    </Root>
  );
});

export default CustomMessageField;
