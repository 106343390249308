import { useTheme } from '@mui/material';
import StatusIcon from 'components/StatusIcon';
import VInput, { VInputProps } from 'components/VInput/VInput';
import { FieldError, useFormContext } from 'react-hook-form';
import ExistsError from './ExistsError';
import VerificationError from './VerificationError';

interface IProps extends VInputProps {
  isEmailValid?: boolean;
  validationError?: FieldError;
  showStatusIcon?: boolean;
}

const getErrorMessage = (errorType?: string): React.ReactNode => {
  switch (errorType) {
    case 'verify':
      return <VerificationError />;
    case 'exists':
      return <ExistsError />;
    case 'required':
      return 'Email address is required.';
    case 'email':
      return 'You should enter a valid email address.';
    default:
      return undefined;
  }
};

const EmailField: React.FC<IProps> = ({
  isEmailValid,
  showStatusIcon = false,
  validationError,
  ...rest
}) => {
  const { register } = useFormContext();
  const { palette } = useTheme();

  return (
    <VInput
      label="Email"
      size="large"
      placeholder="Enter your email adress"
      name="email"
      inputProps={{ ...register('email') }}
      endAdornment={
        showStatusIcon ? (
          <StatusIcon
            status={
              isEmailValid ? 'success' : !!validationError ? 'error' : undefined
            }
          />
        ) : undefined
      }
      helperText={getErrorMessage(validationError?.type)}
      formHelperTextProps={{
        sx: {
          color: ['verify', 'exists'].includes(validationError?.type || '')
            ? palette.primary.main
            : palette.grapeFruit.main,
        },
      }}
      error={!!validationError}
      {...rest}
    />
  );
};

export default EmailField;
