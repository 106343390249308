import { Icon } from '@iconify/react';
import {
  Checkbox as MuiCheckbox,
  Collapse,
  MenuItem as MuiMenuItem,
  Select,
  menuItemClasses,
  styled,
  Box,
} from '@mui/material';
import { arrowDropdown } from 'assets/iconify';
import { useEffect, useState } from 'react';

interface Option {
  label: string;
  value: string;
  children?: Option[];
}

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
  gap: theme.spacing(1),
  [`.${menuItemClasses.root}`]: {},
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  fill: theme.palette.primary.main,
  transform: 'rotate(-90deg)',
  transition: `all ${theme.transitions.duration.shortest}`,
  '&.expanded': {
    transform: 'rotate(0)',
  },
}));

const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

const Placeholder = styled('span')`
  color: ${({ theme }) => theme.palette.disabled.main};
`;

const ChildrenOptions = ({
  option,
  onChange,
  defaultSelected,
}: {
  option: Option & { children: Option[] };
  onChange: (value: string[], deleted?: string | string[]) => void;
  defaultSelected?: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>(defaultSelected || []);

  const handleToggleGroup = () => {
    setIsOpen((open) => !open);
  };

  const handleSelection = (value: string, isChecked?: boolean) => {
    if (isChecked) {
      setSelected((selected) => selected.filter((item) => item !== value));
      onChange(
        selected.filter((item) => item !== value),
        value,
      );
      return;
    }
    setSelected((selected) => [...selected, value]);
    onChange([...selected, value]);
  };

  const handleParentSelection = () => {
    if (selected.length === 0) {
      setSelected(option.children.map(({ value }) => value));
      onChange(option.children.map(({ value }) => value));
      return;
    }

    setSelected([]);
    onChange([], selected);
  };

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);

  return (
    <>
      <MenuItem sx={{ textTransform: 'capitalize' }}>
        <Box display="flex" alignItems="center">
          <StyledIcon
            className={isOpen ? 'expanded' : undefined}
            icon={arrowDropdown.icons.normal}
            onClick={handleToggleGroup}
          />
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length !== option.children?.length
            }
            checked={selected.length === option.children?.length}
            onClick={handleParentSelection}
          />
        </Box>
        {option.label}
      </MenuItem>
      <Collapse in={isOpen}>
        {option.children?.map(({ label, value }) => {
          const isChecked = selected.includes(value);
          return (
            <MenuItem
              key={value}
              sx={{ pl: 7, textTransform: 'capitalize' }}
              onClick={() => handleSelection(value, isChecked)}
            >
              <Checkbox checked={isChecked} /> {label}
            </MenuItem>
          );
        })}
      </Collapse>
    </>
  );
};

const NestedSelect = ({
  options,
  value,
  onChange,
  onClose,
}: {
  options: Option[];
  value: string[];
  onChange?: (values: string[]) => void;
  onClose?: () => void;
}) => {
  const handleSelectionChange = (
    newValue: string[],
    deleted?: string | string[],
  ) => {
    const finalNewValue = newValue.filter((item) => !value.includes(item));
    const finalCurrentValue = !deleted
      ? value
      : typeof deleted === 'string'
      ? value.filter((val) => val !== deleted)
      : value.filter((val) => !deleted.includes(val));
    onChange?.([...finalCurrentValue, ...finalNewValue]);
  };

  const allOptions = options.reduce((prev, curr) => {
    if (curr.children) {
      return [...prev, ...curr.children.map(({ value }) => value)];
    }
    return prev;
  }, [] as string[]);

  const handleAllSelection = () => {
    if (value.length === allOptions.length) {
      onChange?.([]);
      return;
    }
    onClose?.();
    onChange?.(allOptions);
  };

  return (
    <Select
      size="small"
      value={value}
      displayEmpty
      sx={{ width: ({ spacing }) => spacing(30) }}
      renderValue={(values) => {
        if (values.length === 0) return <Placeholder>Select items</Placeholder>;
        if (values.length === allOptions.length) return 'All';
        return `${values.length} items selected`;
      }}
      multiple
      MenuProps={{
        sx: { maxHeight: ({ spacing }) => spacing(50) },
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
        },
      }}
      onClose={() => onClose?.()}
    >
      <MenuItem sx={{ pl: 4 }}>
        <Checkbox
          onChange={handleAllSelection}
          checked={value.length === allOptions.length}
        />{' '}
        All
      </MenuItem>
      {options.map(({ label, value: optionValue, children }) => {
        const hasChildren = !!children && children.length > 0;

        const defaultSelected = allOptions
          .filter((item) => children?.map(({ value }) => value).includes(item))
          .filter((item) => value.includes(item));
        return (
          hasChildren && (
            <ChildrenOptions
              key={optionValue + '1'}
              defaultSelected={defaultSelected}
              option={{ label, value: optionValue, children }}
              onChange={handleSelectionChange}
            />
          )
        );
      })}
    </Select>
  );
};

export default NestedSelect;
