import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const liveTracking: IconifyJSON = {
  prefix: 'live-tracking',
  icons: {
    ping: {
      ...defaultSizes,
      body: '<path d="M5.275 18.725C4.425 17.8417 3.75 16.8294 3.25 15.688C2.75 14.546 2.5 13.3167 2.5 12C2.5 10.6834 2.75 9.45002 3.25 8.30002C3.75 7.15002 4.425 6.14169 5.275 5.27502L6.35 6.35002C5.61667 7.08336 5.04167 7.93336 4.625 8.90002C4.20833 9.86669 4 10.9 4 12C4 13.1167 4.20833 14.1584 4.625 15.125C5.04167 16.0917 5.61667 16.9334 6.35 17.65L5.275 18.725ZM8.1 15.9C7.61667 15.3834 7.22933 14.7957 6.938 14.137C6.646 13.479 6.5 12.7667 6.5 12C6.5 11.2334 6.646 10.5167 6.938 9.85002C7.22933 9.18336 7.61667 8.60002 8.1 8.10002L9.175 9.17502C8.80833 9.54169 8.52067 9.96669 8.312 10.45C8.104 10.9334 8 11.45 8 12C8 12.55 8.104 13.0667 8.312 13.55C8.52067 14.0334 8.80833 14.4584 9.175 14.825L8.1 15.9ZM12 13.5C11.5833 13.5 11.2293 13.354 10.938 13.062C10.646 12.7707 10.5 12.4167 10.5 12C10.5 11.5834 10.646 11.2294 10.938 10.938C11.2293 10.646 11.5833 10.5 12 10.5C12.4167 10.5 12.7707 10.646 13.062 10.938C13.354 11.2294 13.5 11.5834 13.5 12C13.5 12.4167 13.354 12.7707 13.062 13.062C12.7707 13.354 12.4167 13.5 12 13.5ZM15.9 15.9L14.825 14.825C15.1917 14.4584 15.4793 14.0334 15.688 13.55C15.896 13.0667 16 12.55 16 12C16 11.45 15.896 10.9334 15.688 10.45C15.4793 9.96669 15.1917 9.54169 14.825 9.17502L15.9 8.10002C16.3833 8.60002 16.7707 9.18336 17.062 9.85002C17.354 10.5167 17.5 11.2334 17.5 12C17.5 12.7667 17.354 13.479 17.062 14.137C16.7707 14.7957 16.3833 15.3834 15.9 15.9ZM18.725 18.725L17.65 17.65C18.3833 16.9167 18.9583 16.0667 19.375 15.1C19.7917 14.1334 20 13.1 20 12C20 10.8834 19.7917 9.84169 19.375 8.87502C18.9583 7.90836 18.3833 7.06669 17.65 6.35002L18.725 5.27502C19.575 6.14169 20.25 7.15002 20.75 8.30002C21.25 9.45002 21.5 10.6834 21.5 12C21.5 13.3167 21.25 14.546 20.75 15.688C20.25 16.8294 19.575 17.8417 18.725 18.725Z" fill="currentColor"/>',
    },
    center: {
      ...defaultSizes,
      body: '<path d="M11.25 22.2001V20.475C9.16672 20.2584 7.41238 19.4374 5.98705 18.012C4.56238 16.5874 3.74172 14.8334 3.52505 12.75H1.80005V11.25H3.52505C3.74172 9.16672 4.56238 7.41238 5.98705 5.98705C7.41238 4.56238 9.16672 3.74172 11.25 3.52505V1.80005H12.75V3.52505C14.8334 3.74172 16.5877 4.56238 18.0131 5.98705C19.4377 7.41238 20.2584 9.16672 20.475 11.25H22.2001V12.75H20.475C20.2584 14.8334 19.4377 16.5874 18.0131 18.012C16.5877 19.4374 14.8334 20.2584 12.75 20.475V22.2001H11.25ZM12 19C13.9334 19 15.5834 18.3167 16.95 16.95C18.3167 15.5834 19 13.9334 19 12C19 10.0667 18.3167 8.41672 16.95 7.05005C15.5834 5.68338 13.9334 5.00005 12 5.00005C10.0667 5.00005 8.41672 5.68338 7.05005 7.05005C5.68338 8.41672 5.00005 10.0667 5.00005 12C5.00005 13.9334 5.68338 15.5834 7.05005 16.95C8.41672 18.3167 10.0667 19 12 19ZM12 15.5C11.0334 15.5 10.2084 15.1584 9.52505 14.475C8.84171 13.7917 8.50005 12.9667 8.50005 12C8.50005 11.0334 8.84171 10.2084 9.52505 9.52505C10.2084 8.84171 11.0334 8.50005 12 8.50005C12.9667 8.50005 13.7917 8.84171 14.475 9.52505C15.1584 10.2084 15.5 11.0334 15.5 12C15.5 12.9667 15.1584 13.7917 14.475 14.475C13.7917 15.1584 12.9667 15.5 12 15.5Z" fill="currentColor"/>',
    },
  },
};
