import { Skeleton } from '@mui/material';
import { GridWrapper } from 'components/_extra/generic';
import Card from './Card';
import { useRecipientsSetModal } from 'pages/Alerters/hooks/use-recipient-set-modal';
import RecipientSetModal from './RecipientSetModal';
import { EmptyList } from 'components/_extra/EmptyList';
import CreateRecipientSetButton from './CreateRecipientSetButton';
import { reduceCapacity } from 'assets/iconify';
import { useDeleteRecipientManager } from 'hooks/use-delete-recipient-manager';
import { useCopyRecipientManager } from 'hooks/use-copy-recipient-manager';
import { useRecipientSets } from 'hooks/use-recipient-sets';

const RecipientsSets = () => {
  const { sets, isLoading } = useRecipientSets();
  const {
    functionRef,
    handleClick,
    handleClose,
    isOpen,
    isRecipientManagerLoading,
    selectedIndex,
    schedule,
  } = useRecipientsSetModal();
  const handleDelete = useDeleteRecipientManager();
  const { handleCopy } = useCopyRecipientManager();

  return (
    <GridWrapper
      sx={{
        p: { xxs: 0, newLg: 1 },
        gap: 3,
        gridTemplateColumns: sets.length === 0 ? '100%' : undefined,
      }}
    >
      {isLoading ? (
        <>
          <Skeleton variant="rounded" height={160} />
          <Skeleton variant="rounded" height={160} />
          <Skeleton variant="rounded" height={160} />
        </>
      ) : sets.length > 0 ? (
        sets.map((set, index) => (
          <Card
            title={set.name ?? 'Untitled'}
            key={set.id}
            id={set.id}
            patientsCount={set.patientsCount ?? 0}
            recipientsCount={set.recipientsCount ?? 0}
            onEdit={() => handleClick(index)}
            onCopy={() => handleCopy(set.id)}
            onRemove={handleDelete}
            defaultValues={set}
            alerters={set.alerters?.map((al) => al.name ?? 'Untitled')}
          />
        ))
      ) : (
        <EmptyList
          title="Start adding recipient sets"
          message={
            <>
              Use recipient sets to group the people who will receive
              notifications from <strong>BoundaryCare</strong> alerts, to set
              schedules and shifts, and to develop alerting hierarchies. Create
              a few recipient sets and mix and match them with alerts as needed.
            </>
          }
          icon={reduceCapacity.icons.normal}
        >
          <CreateRecipientSetButton />
        </EmptyList>
      )}
      <RecipientSetModal
        submitFormRef={functionRef}
        defaultValues={{ ...sets[selectedIndex ?? 0], ...schedule }}
        onClose={handleClose}
        index={selectedIndex}
        isLoading={isRecipientManagerLoading}
        isOpen={isOpen}
      />
    </GridWrapper>
  );
};

export default RecipientsSets;
