import { Icon } from '@iconify/react';
import {
  Box,
  Theme,
  Typography,
  styled,
  typographyClasses,
} from '@mui/material';
import { regions } from 'assets/iconify';
import { RegionsType } from 'types/safe-zones';

const Root = styled('div')(({ theme }) => ({
  ':hover': {
    cursor: 'pointer',
    '.shape-outline': {
      borderColor: theme.palette.primary.main,
    },
    [`.${typographyClasses.caption}`]: {
      color: theme.palette.primary.main,
    },
  },
}));

const ShapeOutline = styled('div')(
  ({ theme, isSelected }: { theme?: Theme; isSelected: boolean }) => ({
    border: `1px solid ${
      isSelected ? theme?.palette.blue.dark : theme?.palette.blue.light
    }`,
    outline: isSelected ? `1px solid ${theme?.palette.blue.dark}` : undefined,
    borderRadius: theme?.spacing(1),
    marginBottom: theme?.spacing(0.5),
    width: theme?.spacing(8),
    height: theme?.spacing(8),
    svg: {
      width: '100%',
      height: '100%',
    },
  }),
);

const getShapeData = (type: RegionsType) => {
  switch (type) {
    case RegionsType.CIRCLE_TYPE:
      return { name: 'Circle', icon: regions.icons.circle };
    case RegionsType.POLYGON_TYPE:
      return { name: 'Polygon', icon: regions.icons.polygon };
    case RegionsType.PATH_TYPE:
      return { name: 'Path', icon: regions.icons.path };
    default:
      return { name: 'Circle', icon: regions.icons.circle };
  }
};

const ShapeButton = ({
  type,
  isSelected,
  onClick,
}: {
  type: RegionsType;
  isSelected?: boolean;
  onClick?: () => void;
}) => {
  const { icon, name } = getShapeData(type);
  return (
    <Root onClick={onClick}>
      <ShapeOutline className="shape-outline" isSelected={!!isSelected}>
        <Icon icon={icon} />
      </ShapeOutline>
      <Box display="flex" justifyContent="center">
        <Typography
          variant="caption"
          fontWeight={isSelected ? 500 : 400}
          color={isSelected ? 'primary.main' : 'action.active'}
        >
          {name}
        </Typography>
      </Box>
    </Root>
  );
};

export default ShapeButton;
