import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import * as yup from 'yup';
import {
  FilterAppleWatchBatteryStatus,
  FilterAppleWatchBloodOxygenStatus,
  FilterAppleWatchDeviceStatus,
  FilterAppleWatchFallDetectionStatus,
  FilterAppleWatchHeartRateStatus,
  FilterAppleWatchHeartRhythmStatus,
  FilterAppleWatchRespiratoryRateStatus,
  FilterAppleWatchSafeZoneStatus,
  Filters,
  FiltersAppleWatchKeys,
} from 'store/reducers/filters/types';

const arraySchema = yup.array(yup.string());

const validateArrayAndReturn = (value?: unknown): string[] =>
  value
    ? arraySchema.isValidSync(value)
      ? (value as string[])
      : yup.string().isValidSync(value)
      ? [value]
      : []
    : [];

export const useFiltersQueryParams = () => {
  const location = useLocation();

  const currentQueryObj = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true, comma: true }),
    [location.search],
  );

  const visibleColumns = validateArrayAndReturn(currentQueryObj.visible);

  const appleWatchFilters: Filters['appleWatch'] = useMemo(
    () => ({
      device: {
        isInvisible: !visibleColumns.includes('device'),
        selected: validateArrayAndReturn(
          currentQueryObj.device,
        ) as FilterAppleWatchDeviceStatus[],
      },
      battery: {
        isInvisible: !visibleColumns.includes('battery'),
        selected: validateArrayAndReturn(
          currentQueryObj.battery,
        ) as FilterAppleWatchBatteryStatus[],
      },
      safeZone: {
        isInvisible: !visibleColumns.includes('safeZone'),
        selected: validateArrayAndReturn(
          currentQueryObj.safeZone,
        ) as FilterAppleWatchSafeZoneStatus[],
      },
      fallDetection: {
        isInvisible: !visibleColumns.includes('fallDetection'),
        selected: validateArrayAndReturn(
          currentQueryObj.fallDetection,
        ) as FilterAppleWatchFallDetectionStatus[],
      },
      heartRate: {
        isInvisible: !visibleColumns.includes('heartRate'),
        selected: validateArrayAndReturn(
          currentQueryObj.heartRate,
        ) as FilterAppleWatchHeartRateStatus[],
      },
      heartRhythm: {
        isInvisible: !visibleColumns.includes('heartRhythm'),
        selected: validateArrayAndReturn(
          currentQueryObj.heartRhythm,
        ) as FilterAppleWatchHeartRhythmStatus[],
      },
      bloodOxygen: {
        isInvisible: !visibleColumns.includes('bloodOxygen'),
        selected: validateArrayAndReturn(
          currentQueryObj.bloodOxygen,
        ) as FilterAppleWatchBloodOxygenStatus[],
      },
      respiratoryRate: {
        isInvisible: !visibleColumns.includes('respiratoryRate'),
        selected: validateArrayAndReturn(
          currentQueryObj.respiratoryRate,
        ) as FilterAppleWatchRespiratoryRateStatus[],
      },
      sleepTime: {
        isInvisible: !visibleColumns.includes('sleepTime'),
        selected: validateArrayAndReturn(currentQueryObj.sleepTime) as never[],
        exclusiveFor: 'history',
      },
      helpRequested: {
        isInvisible: !visibleColumns.includes('helpRequested'),
        selected: validateArrayAndReturn(
          currentQueryObj.helpRequested,
        ) as never[],
        exclusiveFor: 'profile-overview',
      },
      seizureDetected: {
        isInvisible: !visibleColumns.includes('seizureDetected'),
        selected: validateArrayAndReturn(
          currentQueryObj.seizureDetected,
        ) as never[],
        exclusiveFor: 'profile-overview',
      },
    }),
    [currentQueryObj],
  );

  const generateStringifiedParams = (newFilters: Filters['appleWatch']) => {
    const visible: string[] = [];
    const spreadItems: Record<string, string[]> = {};

    Object.keys(newFilters).forEach((item) => {
      if (!newFilters[item as FiltersAppleWatchKeys].isInvisible) {
        visible.push(item);
      }

      if (newFilters[item as FiltersAppleWatchKeys].selected.length) {
        spreadItems[item] = newFilters[item as FiltersAppleWatchKeys].selected;
      }
    });

    const newQueryObj = {
      visible,
      ...spreadItems,
    };

    return qs.stringify(newQueryObj, {
      addQueryPrefix: true,
      arrayFormat: 'comma',
      encode: false,
    });
  };

  // courtesy of Sorin
  const routerReplace = useCallback(
    (stringifiedParams: string) => {
      const newUrl = `${location.pathname}${stringifiedParams}`;
      window.history.replaceState(
        { ...window.history.state, as: newUrl, url: newUrl },
        '',
        newUrl,
      );
    },
    [location.pathname],
  );

  const rewriteAppleWatchFilters = (
    newFilters: Filters['appleWatch'],
  ): void => {
    routerReplace(generateStringifiedParams(newFilters));
  };

  return {
    appleWatchFilters,
    generateStringifiedParams,
    rewriteAppleWatchFilters,
    visibleColumns,
  };
};
