import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Input,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAssignToPatientMutation } from 'store/api/devices.api';
import { Icon } from '@iconify/react';
import VCheckBox from 'components/VCheckBox/VCheckBox';
import { toast } from 'react-toastify';
import { BCAppContext } from 'models/devices.model';

const Wrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  padding: ${({ theme }) => theme.spacing(2)};
`;

interface Props {
  deviceId: number;
  isOpen: boolean;
  onClose: (...args: unknown[]) => void;
}

const WatchAssignModal = (props: Props) => {
  const { deviceId, isOpen, onClose } = props;

  const [newprofileId, setNewprofileId] = useState('');
  const [skipSetup, setSkipSetup] = useState(false);
  const [remoteManagement, setRemoteManagement] = useState(0);
  const [environment, setEnvironment] = useState<BCAppContext | null>(null);

  const [assignReqFn, { isLoading }] = useAssignToPatientMutation();

  const handleConfirm = () => {
    assignReqFn({
      deviceId,
      patientProfileId: newprofileId,
      skipSetup,
      remoteManagement,
      bc_app_context: environment,
    }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        toast('The device could not be assigned. Please try again.', {
          type: 'error',
        });
        return;
      }

      toast('The device was assigned succesfully!', { type: 'success' });
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={isOpen}>
        <Wrapper elevation={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Assign watch to someone else
              <Typography variant="body2">Device ID: {deviceId}</Typography>
            </Typography>
            <IconButton onClick={onClose}>
              <Icon icon="material-symbols:close-rounded" />
            </IconButton>
          </Box>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={0.5}
            alignItems="center"
            mt={3}
          >
            <Grid item xs={6}>
              <Typography>Account ID to transfer to:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Input
                value={newprofileId}
                onChange={(e) => setNewprofileId(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Skip setup:</Typography>
            </Grid>
            <Grid item xs={6} my={2}>
              <VCheckBox
                value={skipSetup}
                onChangeValue={() => setSkipSetup((prev) => !prev)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>MDM Managed:</Typography>
            </Grid>
            <Grid item xs={6} my={2}>
              <Select
                value={remoteManagement}
                onChange={(e) => setRemoteManagement(e.target.value as number)}
              >
                <MenuItem value={0}>Ignore</MenuItem>
                <MenuItem value={1}>Turn off</MenuItem>
                <MenuItem value={2}>Turn on</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography>Environment:</Typography>
            </Grid>
            <Grid item xs={6} my={2}>
              <Select
                value={environment ?? 'ignore'}
                onChange={(e) => {
                  if (e.target.value === 'ignore') {
                    setEnvironment(null);
                    return;
                  }
                  setEnvironment(e.target.value as BCAppContext);
                }}
              >
                <MenuItem value="ignore">Ignore</MenuItem>
                <MenuItem value="staging">Staging</MenuItem>
                <MenuItem value="production">Production</MenuItem>
                <MenuItem value="hipaaStaging">Hipaa Staging</MenuItem>
                <MenuItem value="hipaaProduction">Hipaa Production</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="end" mt={2}>
            <Button disabled={isLoading} sx={{ py: 1 }} onClick={handleConfirm}>
              Save
            </Button>
          </Box>
        </Wrapper>
      </Fade>
    </Modal>
  );
};

export default WatchAssignModal;
