import { Icon, IconifyIcon } from '@iconify/react';
import { Box, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { DataStatus } from 'types/enum/data-status.enum';
import { getColorByStatus } from 'utils/get-color-by-status';
import { AcknowledgementStatus, DataDisplay } from './components';
import { WidgetBase, WidgetBaseProps } from 'components/WidgetBase';
import { AlerterEventsWidgets } from 'types/alerters';
import { routes } from 'nav';
import { useParams } from 'react-router-dom';
import { error } from 'assets/iconify';

export interface WidgetProps extends WidgetBaseProps {
  title: string;
  widgetType?: AlerterEventsWidgets;
  icon: IconifyIcon | string;
  status?: DataStatus;
  lastUpdate?: string | number;
  alertedAt?: number | string;
  label?: string;
  value?: number | string;
  unit?: string;
  goal?: string | number;
  acknowledgedAt?: number | string;
  fullWidth?: boolean;
  isLoading?: boolean;
  iconColor?: string;
  hasAlert?: boolean;
  disabledCategory?: string;
}

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  [theme.breakpoints.between('newLg', 'newXl')]: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

const SmallStyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const Widget = ({
  acknowledgedAt,
  alertedAt,
  title,
  icon,
  goal,
  status,
  label,
  lastUpdate,
  value,
  href,
  unit,
  fullWidth,
  hasAlert,
  isLoading,
  iconColor,
  widgetType,
  disabledCategory,
}: WidgetProps) => {
  const { profileId } = useParams();
  const { palette, spacing } = useTheme();
  const { textColor, iconColor: statusIconColor } = getColorByStatus(
    status ?? DataStatus.DISABLED,
    palette,
    hasAlert,
  );
  const padding = !value || (value && goal) ? spacing(0, 2) : spacing(2);

  return (
    <WidgetBase
      title={title}
      fullWidth={fullWidth}
      padding={padding}
      isLoading={isLoading}
      lastUpdate={lastUpdate}
      href={
        hasAlert && profileId && widgetType
          ? routes.private.alerter.href(profileId, widgetType)
          : href
      }
      sx={{ position: 'relative' }}
      headerContent={
        <AcknowledgementStatus
          alertedAt={alertedAt}
          acknowledgedAt={acknowledgedAt}
          lastUpdate={lastUpdate}
        />
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <StyledIcon icon={icon} color={iconColor ?? statusIconColor} />
        {!value && (
          <Typography textAlign="center" color={textColor} variant="body1">
            {label}
          </Typography>
        )}
      </Box>
      <DataDisplay
        value={value}
        goal={goal}
        unit={unit}
        label={label}
        textColor={textColor}
      />
      {disabledCategory && (
        <Box position="absolute" bottom={8} left={16}>
          <Tooltip
            title={
              <>
                Enable {disabledCategory} data collection in Profile Settings to
                view {title.toLowerCase()} information.
              </>
            }
          >
            <SmallStyledIcon icon={error.icons.normal} />
          </Tooltip>
        </Box>
      )}
    </WidgetBase>
  );
};

export default Widget;
