import {
  alpha,
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from '@mui/material';
import pxToEm from 'utils/px-to-em';

export const WeekdayPickerRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(2),
  [theme.breakpoints.up('xxs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('newMd')]: {
    flexDirection: 'row',
  },
}));

export const Group = styled(ToggleButtonGroup)(({ theme, size }) => ({
  display: 'flex',
  gap: theme.spacing(size === 'small' ? 0.75 : 1),
  [`.${toggleButtonClasses.root}`]: {
    '&:not(:first-of-type), &:first-of-type': {
      transition: 'background-color 0.2s',
      fontWeight: 400,
      backgroundColor: theme.palette.blue.light,
      color: theme.palette.blue.dark,
      margin: 0,
      borderRadius: '50%',
      width: theme.spacing(size === 'small' ? 3 : 4.5),
      height: theme.spacing(size === 'small' ? 3 : 4.5),
      border: 'none',
      [theme.breakpoints.up('xxs')]: {
        height: theme.spacing(3),
        width: theme.spacing(3),
      },
      [theme.breakpoints.up('xs')]: {
        height: theme.spacing(size === 'small' ? 3 : 4),
        width: theme.spacing(size === 'small' ? 3 : 4),
      },
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(size === 'small' ? 3 : 4.5),
        width: theme.spacing(size === 'small' ? 3 : 4.5),
      },
      ':hover': {
        backgroundColor: theme.palette.disabled.blue,
      },
      [`&.${toggleButtonClasses.disabled}`]: {
        color: theme.palette.disabled.blue,
        backgroundColor: theme.palette.disabled.light,
      },
    },
    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      ':hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      [`&.${toggleButtonClasses.disabled}`]: {
        backgroundColor: alpha(theme.palette.secondary.main, 0.5),
        color: theme.palette.common.white,
      },
    },
  },
}));

export const Item = styled(ToggleButton)(() => ({
  textTransform: 'capitalize',
  fontSize: pxToEm(14),
  lineHeight: 1,
}));
