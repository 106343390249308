import { useAppSelector } from 'store';
import { useGetProfileQuery } from 'store/api/profiles.api';

export const useAccountData = () => {
  const accountId = useAppSelector((state) => Number(state.user.accountId));
  const {
    data: accountData,
    isFetching,
    isLoading,
  } = useGetProfileQuery({
    id: accountId,
  });

  return {
    accountData,
    isLoading: isFetching || isLoading,
  };
};
