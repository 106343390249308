import { Box, Typography } from '@mui/material';
import { WeekdayPicker } from 'components/WeekdayPicker';
import { Day } from 'utils/weekdays';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import TimeRangePicker from 'pages/Alerters/components/TimeRange';
import { EditRecipientSetForm } from './RecipientSetEditor';

interface Props {
  defaultStartTime?: number | null;
  defaultEndTime?: number | null;
  defaultDays?: Day[];
  defaultIsOutsideRange?: boolean;
  error?: string;
  readOnly?: boolean;
}

const Schedule = ({
  defaultDays,
  defaultEndTime = null,
  defaultStartTime = null,
  defaultIsOutsideRange = false,
  readOnly,
}: Props) => {
  const { setValue } = useFormContext<EditRecipientSetForm>();

  const handleWeekdayChange = (value: number[]) => {
    setValue('days', value as Day[], { shouldDirty: true });
  };

  const handleStartChange = (value?: number, dirty?: boolean) => {
    if (typeof value !== 'undefined') {
      setValue('startTime', value, { shouldDirty: dirty });
    }
  };

  const handleEndChange = (value?: number, dirty?: boolean) => {
    if (typeof value !== 'undefined') {
      setValue('endTime', value, { shouldDirty: dirty });
    }
  };

  const handleOutsideRange = (value: boolean, dirty?: boolean) => {
    if (typeof value !== 'undefined') {
      setValue('isOutsideRange', value, { shouldDirty: dirty });
    }
  };

  useEffect(() => {
    setValue('days', defaultDays ?? []);
    setValue('startTime', defaultStartTime ?? 0);
    setValue('endTime', defaultEndTime ?? 86400);
    setValue('isOutsideRange', defaultIsOutsideRange ?? false);
  }, []);

  return (
    <Box>
      <Typography fontWeight={600} variant="body1" color="primary">
        Schedule
      </Typography>
      <Box
        display="flex"
        sx={{
          flexWrap: {
            xxs: 'wrap',
            newMd: 'nowrap',
          },
          columnGap: {
            xxs: 4,
            newMd: 2,
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            px: { xxs: 0, newMd: 2 },
          }}
        >
          <TimeRangePicker
            defaultRange={[defaultStartTime ?? 0, defaultEndTime ?? 86400]}
            onStartChange={(value) => handleStartChange(value, true)}
            onEndChange={(value) => handleEndChange(value, true)}
            onOutsideRangeChange={(value) => handleOutsideRange(value, true)}
            defaultIsOutsideRange={defaultIsOutsideRange}
            readOnly={readOnly}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            px: { xxs: 0, newMd: 2 },
            flexGrow: 1,
          }}
        >
          <Typography color="primary" pb={1.5}>
            Repeat
          </Typography>
          <WeekdayPicker
            withPresets
            defaultValue={defaultDays}
            onChange={handleWeekdayChange}
            readOnly={readOnly}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Schedule;
