import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { EmailField } from 'pages/Login/components';
import { FormProvider } from 'react-hook-form';
import LoginMessage from './components/LoginMessage';
import { useForgotForm } from './hooks/use-forgot-form';
import { useLocation } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const { methods, onSubmit, isLoading } = useForgotForm();
  const { state } = useLocation();

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Box px={1} pb={3}>
        <Typography variant="h5" align="center">
          Forgot Your Password?
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          color={theme.palette.blue.main}
          sx={{ maxWidth: 336, mx: 'auto' }}
        >
          Enter your email and we’ll send you a link to reset your password.
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <EmailField
            defaultValue={state?.email}
            placeholder="Enter your email"
            validationError={methods.formState.errors.email}
          />
          <Box p={1} pt={3}>
            <Button size="large" type="submit" fullWidth>
              Send Link
            </Button>
          </Box>
        </form>
      </FormProvider>
      <LoginMessage />
    </Box>
  );
};

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
