import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import {
  useGetAlerterInfoQuery,
  useGetAlerterSharedRecipientsQuery,
} from 'store/api/alerters.api';
import { CombinedRecipientManager } from 'store/reducers/alerter/alerter.slice';

export const useRecipientSets = (isReminder?: boolean) => {
  const { alertId, reminderId, profileId } = useParams();

  const activeSetsId = useAppSelector(
    (state) => state[isReminder ? 'reminder' : 'alerter'].activeRecipientSets,
  );

  const { isLoading, isFetching } = useGetAlerterInfoQuery(
    Number(isReminder ? reminderId : alertId),
    { skip: !alertId && !reminderId },
  );

  const { data, isLoading: areSharedLoading } =
    useGetAlerterSharedRecipientsQuery({
      patientProfileId: Number(profileId),
      isReminder,
    });

  const sortingOrder = useMemo(() => {
    if (!data) return [];
    const sorted: CombinedRecipientManager[] = [];
    Object.assign(sorted, data);
    sorted.sort((_, b) => (activeSetsId?.includes(b.id) ? 1 : -1));
    return sorted.map((set) => set.id);
  }, [data?.length]);

  return {
    sets: (data
      ? sortingOrder
          .map((id) => data.find((set) => set.id === id))
          .filter(Boolean)
      : []) as CombinedRecipientManager[],
    isLoading: isLoading || isFetching || areSharedLoading,
    activeSetsId,
  };
};
