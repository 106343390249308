import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAuthenticateMutation,
  useVerifyEmailMutation,
} from 'store/api/auth.api';
import { useUpdateProfileMutation } from 'store/api/profiles.api';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAuthenticateResponse, PasswordStrength } from 'models/auth.model';
import { routes } from 'nav';

export interface RegisterFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmedPassword: string;
  isAccepted: boolean;
}

const registerSchema = yup.object({
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  email: yup
    .string()
    .email('The value should be a valid email addresss')
    .required('Email address is required.'),
  password: yup.string().required('Password is required.'),
  confirmedPassword: yup.string(),
  isAccepted: yup
    .boolean()
    .oneOf([true], 'Please accept the terms to proceed.'),
});

export const useRegistrationForm = () => {
  const methods = useForm<RegisterFormData>({
    resolver: yupResolver(registerSchema),
    reValidateMode: 'onSubmit',
  });

  const navigate = useNavigate();

  const [authenticate, { isLoading: isAuthLoading }] =
    useAuthenticateMutation();
  const [verifyEmail, { isLoading: isEmailLoading }] = useVerifyEmailMutation();
  const [updateProfile] = useUpdateProfileMutation();

  const [errors, setErrors] = useState<FieldErrorsImpl<RegisterFormData>>({});

  const passwordStrength = useRef<PasswordStrength>('too short');

  const handlePasswordStrengthChange = (strength: PasswordStrength) => {
    passwordStrength.current = strength;
  };

  const checkPasswordSimilarity = () => {
    if (methods.getValues().confirmedPassword.length === 0) {
      return {
        type: 'required',
        message: 'Password confirmation is required.',
      };
    }

    if (
      methods.getValues().password !== methods.getValues().confirmedPassword
    ) {
      return {
        type: 'match',
        message: "The passwords don't match.",
      };
    }
  };

  const onSubmit = async (data: RegisterFormData) => {
    const localErrors: FieldErrorsImpl<RegisterFormData> = {};

    if (!isEmailLoading || isAuthLoading) {
      const passwordSimilarityError = checkPasswordSimilarity();
      if (passwordSimilarityError) {
        localErrors.confirmedPassword = passwordSimilarityError;
      }

      if (['too short', 'weak'].includes(passwordStrength.current)) {
        localErrors.password = {
          type: 'validation',
        };
      }

      const existingEmail = await verifyEmail({ email: data.email })
        .unwrap()
        .then((data) => {
          return data.result.exists;
        });

      if (existingEmail) {
        localErrors.email = {
          type: 'exists',
        };
      }

      if (Object.keys(localErrors).length > 0) {
        onError(localErrors);
        return;
      }

      onError({});

      authenticate({
        email: data.email,
        password: data.password,
      })
        .unwrap()
        .then(async (authData: IAuthenticateResponse) => {
          await updateProfile({
            first_name: data.firstName,
            last_name: data.lastName,
            id: authData.meta.credential.account_id,
          });

          navigate(routes.mfa.setup.href);
        });
    }
  };

  const onError: SubmitErrorHandler<RegisterFormData> = (errors) => {
    setErrors({ ...errors, confirmedPassword: checkPasswordSimilarity() });
  };

  return {
    methods,
    errors,
    onError,
    onSubmit,
    handlePasswordStrengthChange,
  };
};
