const STATUSES = {
  success: {
    title: 'Success',
    message: 'The device was successfully deregistered.',
    icon: 'material-symbols:check-circle-rounded',
  },
  error: {
    title: 'Error',
    message:
      'There is a problem with the device deregistration. Please try again later.',
    icon: 'material-symbols:error-circle-rounded',
  },
};

export type DeregistrationStatus = keyof typeof STATUSES;

export const getStatus = (status: DeregistrationStatus) => STATUSES[status];
