import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';
import { fallDetection, helpRequested } from 'assets/iconify';

export const getSeizureDetectedData = (
  requested: boolean | undefined,
): DataCellProps => {
  if (requested === true) {
    return {
      icon: fallDetection.icons.ok,
      status: DataStatus.NEGATIVE,
      label: 'Detected',
    };
  }

  return {
    icon: helpRequested.icons.normal,
    status: DataStatus.POSITIVE,
    label: 'None',
  };
};
