import { MetricRuleCard, MetricRuleForm } from 'components/_extra';
import { useTriggerCard } from 'pages/AlerterDetails/hooks/use-trigger-card';
import { MetricRule } from 'types/alerters';
import { useEnableSwitch } from 'pages/AlerterDetails/hooks/use-enable-switch';
import { Ref } from 'react';
import { FieldsData } from 'hooks/use-metric-rule';
import { SxProps, Theme } from '@mui/material';

interface Props extends MetricRule {
  title?: string;
  onReset?: () => void;
  functionsRef: Ref<{
    getFieldsData: () => FieldsData;
    reset: () => void;
  }>;
  sx: SxProps<Theme>;
}

const TriggerCard = ({ id, onReset, functionsRef, ...rest }: Props) => {
  const { handleSwitchToggle, isLoading: isSwitching } = useEnableSwitch(id);
  const {
    isEditable,
    handleRemoveButtonClick,
    handleEditButtonClick,
    handleBackButtonClick,
    handleSaveButtonClick,
    isSaveActive,
  } = useTriggerCard(id, onReset);

  return (
    <MetricRuleCard
      onSave={handleSaveButtonClick}
      onGoBack={handleBackButtonClick}
      onRemove={handleRemoveButtonClick}
      onSwitchToggle={handleSwitchToggle}
      isSaveActive={isSaveActive}
      isSwitchDisabled={isSwitching}
      isEdited={isEditable}
      {...rest}
    >
      <MetricRuleForm
        functionsRef={functionsRef}
        inCard
        id={id}
        onEdit={handleEditButtonClick}
        readOnly={!isEditable}
        defaultValues={{
          comparator: rest.comparator,
          metricType: rest.metricType,
          value: rest.value,
        }}
      />
    </MetricRuleCard>
  );
};

export default TriggerCard;
