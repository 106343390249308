import { useProfileData } from 'hooks/use-profile-data';
import { useParams } from 'react-router-dom';
import { useGetReminderInfoQuery } from 'store/api/reminders.api';

export const useReminderDetails = () => {
  const { reminderId } = useParams();

  useProfileData();

  useGetReminderInfoQuery(parseInt(reminderId ?? '0'), {
    skip: !reminderId,
  });
};
