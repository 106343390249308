import { debounce } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

const getSizes = (div: HTMLDivElement | null) => {
  if (div) {
    const contentHeight = div.clientHeight;
    const child = div.children[0];

    const childHeight = child ? child.clientHeight : 0;

    return {
      contentHeight,
      childHeight,
      isCollapsible: contentHeight > childHeight,
      collapsedSize: childHeight,
    };
  }

  return {
    contentHeight: 0,
    childHeight: 0,
    collapsedSize: 0,
    isCollapsible: false,
  };
};

export const useCollapse = () => {
  const [isExpanded, setExpanded] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  const handleHeightChange = debounce((newHeight: number) => {
    setHeight(newHeight);
  }, 200);

  useEffect(() => {
    if (!boxRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries, {}) => {
      for (const entry of entries) {
        handleHeightChange(entry.target.clientHeight);
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current && boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
        resizeObserver.disconnect();
      }
    };
  }, [boxRef.current]);

  const { contentHeight, isCollapsible, collapsedSize } = useMemo(
    () => getSizes(boxRef.current),
    [boxRef.current, height],
  );

  return {
    isExpanded,
    setExpanded,
    boxRef,
    isCollapsible,
    contentHeight,
    collapsedSize,
  };
};
