import { Link } from '@mui/material';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';

const PasswordError: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      Please check if you typed your password correctly. <br />
      If you forgot your password{' '}
      <Link
        color="secondary"
        fontWeight={600}
        onClick={() => navigate(routes.public.forgot.href)}
      >
        {' '}
        click here.
      </Link>
    </>
  );
};

export default PasswordError;
