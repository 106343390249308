import { IAccountRequest } from 'models/account.model';
import { useRef, useState } from 'react';
import { useUpdateProfileMutation } from 'store/api/profiles.api';
import { FieldValue, FieldName } from '../CaregiverSettings';
import { toast } from 'react-toastify';

export const useSaveChanges = (id: number | undefined) => {
  const [isSaveActive, setIsSaveActive] = useState(false);
  const [areControlsDisabled, setAreControlsDisabled] = useState(false);
  const [saveChannelSet, setSaveChannelSet] = useState<() => void>(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => () => {},
  );
  const [updateProfile] = useUpdateProfileMutation();
  const dataToUpdateRef = useRef<Partial<IAccountRequest>>({});
  const discardChangesRef = useRef<(() => void)[]>([]);

  const handleSaveClick = () => {
    if (typeof id === 'number') {
      saveChannelSet();
      updateProfile({
        id,
        ...dataToUpdateRef.current,
      });
      return;
    }
    toast.error('Something went rong. Please refresh the page and try again.');
  };

  const handlePropertyChange = (fieldName: FieldName, value: FieldValue) => {
    dataToUpdateRef.current = {
      ...dataToUpdateRef.current,
      [fieldName]: value,
    };
  };

  const toggleSaveButton = (value: boolean) => {
    if (value === true) {
      if (
        typeof dataToUpdateRef.current.first_name !== 'undefined' &&
        dataToUpdateRef.current.first_name.length === 0
      ) {
        setIsSaveActive(false);
        return;
      }
      setIsSaveActive(true);
      return;
    }
    setIsSaveActive(false);
  };

  const setDiscardChanges = (f: () => void) => {
    discardChangesRef.current.push(f);
  };

  const handleDiscardClick = () => {
    discardChangesRef.current.forEach((f) => f());
    discardChangesRef.current = [];
    dataToUpdateRef.current = {};
  };

  return {
    handleSaveClick,
    handleDiscardClick,
    setIsSaveActive: toggleSaveButton,
    isSaveActive,
    handlePropertyChange,
    setSaveChannelSet: (f: () => void) => {
      setSaveChannelSet(() => f);
    },
    setAreControlsDisabled,
    areControlsDisabled,
    setDiscardChanges,
    isDiscardDisabled: discardChangesRef.current?.length === 0,
  };
};
