export function findSmallestMissingTitle(titles: string[]): string {
  const sortedNumbers = (
    titles
      .map((title) => {
        const match = title.match(/Untitled (\d+)/);
        return match ? parseInt(match[1]) : null;
      })
      .filter((number) => number !== null) as number[]
  ).sort((a, b) => a - b);

  let smallestMissingNumber = 1;

  for (const number of sortedNumbers) {
    if (number === smallestMissingNumber) {
      smallestMissingNumber++;
    } else {
      break;
    }
  }

  return `Untitled ${smallestMissingNumber}`;
}
