import { Box, Divider, SxProps, Theme } from '@mui/material';
import { Devices } from './components/Devices';
import { Caregivers } from './components/Caregivers';
import { EmergencyManagement } from './components/EmergencyManagement';
import { GeneralSettings } from './components/GeneralSettings';
import { SafeZones } from './components/SafeZones';
import { PageContent } from 'components/_extra/generic';
import DataSettings from './components/DataSettings/DataSettings';

const dividerMb: SxProps<Theme> = { mb: { xxs: 3, sm: 4 } };

const PatientAccount = () => {
  return (
    <PageContent>
      <Box p={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }} sx={{ overflowY: 'auto' }}>
        <GeneralSettings />
        <Divider sx={{ mt: 2, ...dividerMb }} />
        <EmergencyManagement />
        <Divider sx={{ mt: 2, ...dividerMb }} />
        <DataSettings />
        <Divider sx={{ mt: 2, ...dividerMb }} />
        <SafeZones />
        <Divider
          sx={{
            mt: {
              xxs: 4,
              sm: 5,
            },
            ...dividerMb,
          }}
        />
        <Caregivers />
        <Divider
          sx={{
            mt: {
              xxs: 4,
              sm: 5,
            },
            ...dividerMb,
          }}
        />
        <Devices />
      </Box>
    </PageContent>
  );
};

export default PatientAccount;
