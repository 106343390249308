import VInput from 'components/VInput';
import { useDeviceCodeInput } from '../../../../hooks/use-device-code-input';
import { Box, Button } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const RetrieveCode = ({
  handleNextStep,
  setSentAt,
}: {
  handleNextStep: () => void;
  setSentAt: Dispatch<SetStateAction<string>>;
}) => {
  const { errors, handleSubmit, register } = useDeviceCodeInput(
    handleNextStep,
    setSentAt,
  );

  return (
    <form onSubmit={handleSubmit}>
      <VInput
        size="large"
        label="Code"
        placeholder="Enter your linking code"
        error={!!errors.linkingCode}
        helperText={errors.linkingCode?.message}
        blueHelper={errors.linkingCode?.type === 'request'}
        {...register('linkingCode')}
      />
      <Box sx={{ m: 1, mt: 3 }}>
        <Button type="submit" size="large" fullWidth>
          Connect
        </Button>
      </Box>
    </form>
  );
};

export default RetrieveCode;
