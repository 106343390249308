import { useConfirmation } from 'hooks/use-confirmation';
import { useRecipientSets } from 'hooks/use-recipient-sets';
import { useRef, useState } from 'react';
import { useAppSelector } from 'store';
import { useGetRecipientManagerQuery } from 'store/api/alerters.api';

export const useEditRecipientsSetModal = (isReminder?: boolean) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const functionRef = useRef<{ submitForm: () => void; isError: boolean }>(
    null,
  );

  const { sets: recipientSets } = useRecipientSets(isReminder);

  const wasModified = useAppSelector(
    (state) =>
      state[isReminder ? 'reminder' : 'alerter'].isModifiedRecipientSet,
  );
  const { isLoading } = useGetRecipientManagerQuery(
    recipientSets[selectedIndex ?? 0]?.id,
    {
      skip: !selectedIndex,
    },
  );

  const confirm = useConfirmation();

  const handleClick = (index: number) => {
    setIsOpen(true);
    setSelectedIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedIndex(null);
  };

  const onSubmit = () => {
    functionRef.current?.submitForm();
  };

  const confirmSubmit = () => {
    confirm(
      {
        title: 'You have unsaved changes',
        message: 'Do you want to save or discard them?',
        rejectButtonLabel: 'Discard',
        confirmButtonLabel: 'Save',
      },
      onSubmit,
      onClose,
    );
  };

  const confirmDiscard = () => {
    confirm(
      {
        title: 'You have unsaved changes',
        message: 'Do you want to discard your changes or go back to editing??',
        rejectButtonLabel: 'Go back',
        confirmButtonLabel: 'Discard',
        variant: 'danger',
      },
      onClose,
    );
  };

  const handleClose = () => {
    if (wasModified) {
      if (functionRef.current?.isError) {
        confirmDiscard();
        return;
      }
      confirmSubmit();
      return;
    }

    onClose();
  };

  return {
    isOpen,
    handleClick,
    handleClose,
    onClose,
    selectedIndex,
    functionRef,
    isRecipientManagerLoading: isLoading,
  };
};
