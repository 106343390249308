import { Skeleton } from '@mui/material';
import { CustomPhoneInput } from 'components/CustomPhoneInput';
import { useMemo } from 'react';
import { useGetAllCredentialsQuery } from 'store/api/auth.api';

export const PhoneNumberDisplay = ({ profileId }: { profileId: number }) => {
  const { data, isLoading } = useGetAllCredentialsQuery({
    id: profileId,
  });

  const phoneNumber = useMemo(() => {
    return data?.results.find((credential) => credential.schema === 'phone')
      ?.identifier;
  }, [profileId, data]);

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height={48} />;
  }

  return (
    <CustomPhoneInput
      label="Phone number"
      placeholder="No registered phone number"
      value={phoneNumber}
      readOnly
    />
  );
};
