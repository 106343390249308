/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigationBlocker } from 'hooks/use-navigation-blocker';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  alertersApi,
  useGetAlerterChannelSetQuery,
  useUpdateAlerterChannelSetMutation,
} from 'store/api/alerters.api';
import { ChannelSet } from 'types/alerters';
import { transformChannelSet } from 'utils/data-transformation';
import { CaregiverSettingsContext } from '../CaregiverSettings';
import { useAppDispatch } from 'store';

export const useNotificationsSettings = (channelSetId: number | undefined) => {
  const dispatch = useAppDispatch();
  const [onMuteReset, setOnMuteReset] = useState(() => () => {});
  const [onChannelsReset, setOnChannelsReset] = useState(() => () => {});

  const [isSaveActive] = useState(false);

  const handleChannelResetChange = (value: () => void) => {
    setOnChannelsReset(() => value);
  };

  const handleMuteResetChange = (value: () => void) => {
    setOnMuteReset(() => value);
  };

  const channelsRef = useRef<ChannelSet[]>([]);

  const [updateChannelSet, channelSetUpdateState] =
    useUpdateAlerterChannelSetMutation();
  const { data, isLoading } = useGetAlerterChannelSetQuery(channelSetId ?? 0, {
    skip: !channelSetId,
  });

  const {
    handlePropertyChange,
    setIsSaveActive,
    setSaveChannelSet,
    setAreControlsDisabled,
    setDiscardChanges,
  } = useContext(CaregiverSettingsContext);

  const channels = useMemo(() => {
    return transformChannelSet(data?.result.channels ?? []);
  }, [data?.result.channels]);

  const handleSaveChanges = () => {
    if (channelsRef.current.length === 0) {
      return;
    }

    setIsSaveActive(false);

    if (
      channelsRef.current.sort().join() !== [...channels].sort().join() &&
      channelSetId
    ) {
      updateChannelSet({
        channels: transformChannelSet(channelsRef.current),
        id: channelSetId,
      });
    }
  };

  const handleCancelClick = () => {
    onMuteReset();
    onChannelsReset();
    setIsSaveActive(false);
  };

  const handleChannelsChange = (channels: ChannelSet[]) => {
    channelsRef.current = channels;
    setDiscardChanges(onChannelsReset);
    setIsSaveActive(!(channels.length === 0));
    setAreControlsDisabled(channels.length === 0);
    setSaveChannelSet(handleSaveChanges);
  };

  const handleMuteSwitchChange = (value: boolean) => {
    handlePropertyChange('mute_all_alerters', value);
    setDiscardChanges(onMuteReset);
    setIsSaveActive(true);
  };

  useNavigationBlocker(isSaveActive, {
    message: 'Are you sure you want to discard them?',
    title: 'You have unsaved changes.',
    onDiscard: handleCancelClick,
  });

  useEffect(() => {
    if (channelSetUpdateState.data?.result.channels) {
      channelsRef.current = channelSetUpdateState.data.result.channels;
    }
  }, [channelSetUpdateState.data]);

  useEffect(() => {
    if (channelSetUpdateState.isSuccess && channelSetId) {
      dispatch(
        alertersApi.util.updateQueryData(
          'getAlerterChannelSet',
          channelSetId,
          () => channelSetUpdateState.data,
        ),
      );
    }
  }, [channelSetUpdateState.isSuccess]);

  return {
    isLoading,
    handleCancelClick,
    channels,
    handleChannelsChange,
    handleMuteSwitchChange,
    handleChannelResetChange,
    handleMuteResetChange,
    isSaveActive,
  };
};
