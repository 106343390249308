import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import * as icons from 'assets/iconify';
import {
  ImageUploadArea,
  StyledTypography,
  UploadButtonWrapper,
  UploadSeparator,
  UploadSeparatorLine,
} from './styled';
import theme from 'styles/theme';
import VInput from 'components/VInput';
import FileUpload from './FileUpload';
import { useState } from 'react';

const ImageUploadModalContent = ({
  closeModal,
  onAdd,
  onClear,
  isLoading,
  setImageFile,
}: {
  closeModal?: () => void;
  onUpload?: (imageFile: File) => void;
  onAdd?: (imageFile: File | null, imageUrl: string) => void;
  onClear?: () => void;
  isLoading?: boolean;
  file: File | null;
  setImageFile: (file: File | null) => void;
}) => {
  const [filePreview, setFilePreview] = useState<File | null>();
  const [url, setUrl] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  return (
    <ImageUploadArea>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        gap={2}
        sx={{
          justifyContent: 'center',
          flexGrow: { xxs: 1, sm: 'unset' },
        }}
      >
        <FileUpload
          onUpload={(file) => {
            setFilePreview(file);
            setUrl('');
            setIsButtonDisabled(false);
          }}
        />
        {filePreview && (
          <Box
            sx={({ spacing, palette }) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: spacing(0.5, 1, 0.5, 0.5),
              border: `1px solid ${palette.primary.main}`,
              borderRadius: '4px',
              backgroundColor: palette.primaryBackground.main,
            })}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Icon
                fill={theme.palette.primary.main}
                width={48}
                height={48}
                icon={icons.imagePlaceholder.icons.normal}
              />
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {filePreview.name}
              </Typography>
            </Box>
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                size="medium"
                sx={{
                  pr: 1,
                }}
                onClick={() => {
                  setFilePreview(null);
                  setIsButtonDisabled(true);
                  onClear?.();
                }}
              >
                <Icon
                  icon="material-symbols:cancel"
                  color={theme.palette.lightBlue.main}
                />
              </IconButton>
            )}
          </Box>
        )}
        <UploadSeparator>
          <UploadSeparatorLine />
          <StyledTypography color={theme.palette.divider}>or</StyledTypography>
          <UploadSeparatorLine />
        </UploadSeparator>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <InputLabel
            sx={({ palette }) => ({
              color: palette.primary.main,
              fontSize: 14,
              pb: 1,
              lineHeight: '20px',
            })}
          >
            Add from URL
          </InputLabel>
          <VInput
            size="small"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setFilePreview(null);
              setImageFile(null);
              if (e.target.value) {
                setIsButtonDisabled(false);
              } else {
                setIsButtonDisabled(true);
              }
            }}
            width="100%"
            placeholder="https://example.com/your-file.jpg"
            margin="none"
            containerSx={{
              marginTop: 0,
              marginBottom: 1,
              padding: 0,
            }}
            sx={() => ({
              marginTop: 0,
            })}
            leftIcon={
              <Box height={24} mr={1}>
                <Icon width={24} height={24} icon={icons.link.icons.normal} />
              </Box>
            }
            icon={
              url ? (
                <IconButton
                  size="medium"
                  onClick={() => {
                    setUrl('');
                  }}
                  sx={{
                    p: 0.5,
                    position: 'absolute',
                    right: 4,
                    top: 3,
                    backgroundColor: 'white !important',
                    ml: 0.5,
                  }}
                >
                  <Icon
                    icon="material-symbols:cancel"
                    color={theme.palette.lightBlue.main}
                  />
                </IconButton>
              ) : null
            }
          />
        </Box>
      </Box>
      <UploadButtonWrapper>
        <Button
          sx={{ flexBasis: '50%' }}
          variant="outlined"
          color="primary"
          onClick={() => {
            closeModal?.();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{ flexBasis: '50%' }}
          variant="contained"
          color="primary"
          onClick={() => {
            onAdd?.(filePreview || null, url);
            closeModal?.();
          }}
          disabled={isButtonDisabled}
        >
          Add
        </Button>
      </UploadButtonWrapper>
    </ImageUploadArea>
  );
};

export default ImageUploadModalContent;
