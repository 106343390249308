import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { RangeInput } from './components';
import { useDatePickerModal } from './hooks';
import { DateRange } from 'types/date-time';
import CalendarModal from './components/CalendarModal';
import dayjs from 'dayjs';
interface TimeRangeInputProps {
  onChange: (newValue: DateRange) => void;
  defaultRange?: DateRange;
  maxRange?: number;
  disableFuture?: boolean;
  labelPlacement?: 'top' | 'left';
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const TimeRangeInput = ({
  onChange,
  defaultRange = [null, null],
  disableFuture,
  maxRange,
  labelPlacement,
  size,
  disabled,
}: TimeRangeInputProps) => {
  const {
    value,
    setValue,
    open,
    onOpen,
    onClose,
    handleChange,
    onResetClick,
    onOkClick,
    minDate,
    maxDate,
    focusedInput,
    setFocusedInput,
  } = useDatePickerModal({ onChange, defaultRange, maxRange });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RangeInput
        startProps={{
          label: 'From:',
          value:
            value[0]?.format('MM/DD/YYYY') ??
            dayjs().subtract(1, 'days').format('MM/DD/YYYY'),
          size,
        }}
        endProps={{
          label: 'To:',
          value: value[1]?.format('MM/DD/YYYY') ?? dayjs().format('MM/DD/YYYY'),
          size,
        }}
        disabled={disabled}
        onClick={onOpen}
        labelPlacement={labelPlacement}
      />
      <CalendarModal
        onChange={handleChange}
        open={open}
        setValue={setValue}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        onResetClick={onResetClick}
        onClose={onClose}
        onOkClick={onOkClick}
        disableFuture={disableFuture}
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
      />
    </LocalizationProvider>
  );
};

export default TimeRangeInput;
