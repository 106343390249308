import { alpha } from '@mui/material';

const bc_palette = {
  'bc-dark-blue': '#0E3469',
  'bc-dark-blue-hover': '#062652',
  'bc-light-blue': '#C8D0DC',
  'bc-light-gray': '#E7EBF0',
  'bc-green': '#429580',
  'bc-green-hover': '#2A7260',
  'bc-red': '#EC745E',
  'bc-red-hover': '#D35D47',
  'bc-blue': '#6E85A5',
  'bc-yellow': '#FFC700',
  'bc-primary-200': '#A3B2C6',
};

export const palette = {
  common: {
    white: '#ffffff',
    black: '#000000',
  },
  primary: {
    main: bc_palette['bc-dark-blue'],
    light: bc_palette['bc-primary-200'],
  },
  secondary: {
    main: bc_palette['bc-green'],
    hover: bc_palette['bc-green-hover'],
  },
  grapeFruit: {
    main: bc_palette['bc-red'],
  },
  yellow: {
    main: bc_palette['bc-yellow'],
  },
  divider: bc_palette['bc-light-blue'],
  blue: {
    light: bc_palette['bc-light-blue'],
    main: bc_palette['bc-blue'],
    dark: bc_palette['bc-dark-blue'],
    darkHover: bc_palette['bc-dark-blue-hover'],
  },
  red: {
    main: bc_palette['bc-red'],
  },
  skin: {
    main: '#F6C3A2',
  },
  palePink: {
    main: '#FFE1CF',
  },
  black: {
    main: '#001500',
    light: '#787878',
  },
  gray: {
    light: bc_palette['bc-light-gray'],
    main: '#787878',
  },
  white: {
    main: '#fff',
  },
  paleWhite: {
    main: 'rgba(255, 255, 255, 0.7)',
  },
  paleGray: {
    main: 'rgba(255, 255, 255, 0.1)',
    light: '#D4D4D4',
  },
  backgroundWhite: {
    main: '#f7f7f7',
  },
  backgroundLinear: {
    main: 'linear-gradient(202.4deg, rgba(14, 52, 105, 0.73) -10.56%, rgba(43, 105, 118, 0.73) 60.52%, rgba(21, 65, 108, 0.73) 145.78%)',
  },
  borderGray: {
    main: alpha(bc_palette['bc-dark-blue'], 0.23),
  },
  lightBlue: {
    main: alpha(bc_palette['bc-blue'], 0.6),
    light: 'rgba(14, 52, 105, 0.05)', // "bc_dark_blue_0.05" in design
  },
  lastGray: {
    main: '#BDBDBD',
  },
  primaryBackground: {
    main: 'rgba(14, 52, 105, 0.04)',
    dark: 'rgba(14, 52, 105, 0.08)',
  },
  disabled: {
    main: alpha(bc_palette['bc-dark-blue'], 0.38),
    light: bc_palette['bc-light-gray'],
    blue: '#A6B4C8',
  },
  hover: {
    light: 'rgba(0, 0, 0, 0.05)', // "black-hover" in design
    main: 'rgba(0, 0, 0, 0.05)', // "black-hover" in design
    dark: 'rgba(255, 255, 255, 0.1)', // "white-hover" in design
    darkBlue: 'rgba(14, 52, 105, 0.08)', // "bc-dark-blue-0.08" in design
  },
  action: {
    selected: alpha(bc_palette['bc-dark-blue'], 0.12),
    active: alpha(bc_palette['bc-dark-blue'], 0.56),
    hover: alpha(bc_palette['bc-dark-blue'], 0.08),
  },
  text: {
    disabled: alpha(bc_palette['bc-dark-blue'], 0.38),
    secondary: alpha(bc_palette['bc-dark-blue'], 0.6),
  },
  error: {
    main: bc_palette['bc-red'],
    hover: bc_palette['bc-red-hover'],
  },
  ...bc_palette,
};
