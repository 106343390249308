import { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import {
  Box,
  CircularProgress,
  Fade,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { useIssuesAcknowledge } from '../hooks/use-issues-acknowledge';
import {
  AcknowledgeButton,
  ModalCloseBtn,
  StyledBackdrop,
} from './IssuesAcknowledge.styled';
import AlertEventItem from './AlertEventItem';

interface Props {
  id: number;
}

const IssuesAcknowledge = ({ id }: Props) => {
  const {
    hasIssues,
    acknowledge,
    isConfirmed,
    setConfirmed,
    isLoading,
    unacknowledgedAlerts,
  } = useIssuesAcknowledge(id);

  const selectedIdsRef = useRef<number[]>([]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
    setConfirmed(false);
  };

  const handleBtnClick = () => {
    if (!isModalOpen) {
      setModalOpen((prev) => !prev);
      return;
    }

    if (isLoading) {
      return;
    }

    acknowledge(selectedIdsRef.current);
  };

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      selectedIdsRef.current = [...selectedIdsRef.current, id];
      return;
    }

    selectedIdsRef.current = selectedIdsRef.current.filter((i) => i !== id);
  };

  const modalViewState = isLoading
    ? 'loading'
    : isConfirmed
    ? 'success'
    : 'idle';

  useEffect(() => {
    selectedIdsRef.current = unacknowledgedAlerts.map((alert) => alert.id);
  }, [unacknowledgedAlerts]);

  const renderModalView = () => {
    return (
      <>
        <Fade in={modalViewState === 'idle'} unmountOnExit exit={false}>
          <Box display="grid" gridTemplateRows="auto auto 1fr">
            <Typography variant="h6">Confirm the following:</Typography>
            <Typography variant="body1" mt={1} mb={2} color="blue.main">
              Make sure you have reviewed all the issues listed down below
              before acknowledging them.
            </Typography>
            <Box sx={{ overflowY: 'auto' }}>
              {unacknowledgedAlerts.map((alert) => (
                <AlertEventItem
                  onChange={handleCheckboxChange}
                  alertEvent={alert}
                  key={alert.id}
                />
              ))}
            </Box>
          </Box>
        </Fade>
        <Fade in={modalViewState === 'loading'} unmountOnExit exit={false}>
          <Box
            height={({ spacing }) => spacing(28)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <CircularProgress
              sx={{
                width: ({ spacing }) => spacing(9.5),
                height: ({ spacing }) => spacing(9.5),
              }}
              color="primary"
            />
          </Box>
        </Fade>
        <Fade in={modalViewState === 'success'} unmountOnExit exit={false}>
          <Box
            height={({ spacing }) => spacing(28)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={{
              '& svg': {
                color: ({ palette }) => palette.secondary.main,
              },
            }}
          >
            <Icon icon="material-symbols:task-alt" height={96} />
            <Typography variant="h6" mt={2}>
              Confirmed!
            </Typography>
          </Box>
        </Fade>
      </>
    );
  };

  if (!hasIssues) {
    return null;
  }

  return (
    <Box>
      {!isModalOpen && (
        <AcknowledgeButton isOpen={isModalOpen} onClick={handleBtnClick} />
      )}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        slots={{
          backdrop: StyledBackdrop,
        }}
      >
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          alignItems="end"
          sx={({ breakpoints, spacing }) => ({
            pointerEvents: 'none',
            bottom: spacing(6),
            right: spacing(6),
            [breakpoints.down('newMd')]: {
              bottom: spacing(4),
              right: spacing(4),
            },
            [breakpoints.down('sm')]: {
              bottom: spacing(3),
              right: spacing(3),
            },

            [breakpoints.down('xs')]: {
              bottom: spacing(2),
              right: spacing(2),
            },
          })}
        >
          <Paper
            sx={({ spacing, breakpoints }) => ({
              display: 'flex',
              p: 3,
              mb: 2,
              maxWidth: spacing(60),
              maxHeight: spacing(60),
              pointerEvents: 'auto',
              position: 'relative',
              width: `calc(100vw - ${spacing(6)})`,
              [breakpoints.down('xs')]: {
                width: `calc(100vw - ${spacing(4)})`,
              },
            })}
            elevation={6}
          >
            <Fade in={!isLoading}>
              <Box position="absolute" top={8} right={8}>
                <ModalCloseBtn width={20} onClick={handleModalClose} />
              </Box>
            </Fade>
            {renderModalView()}
          </Paper>
          <AcknowledgeButton
            isOpen={isModalOpen}
            onClick={handleBtnClick}
            sx={isConfirmed ? { visibility: 'hidden' } : {}}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default IssuesAcknowledge;
