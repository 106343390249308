import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setAvailableMethods } from 'store/reducers/mfa/mfa.slice';
import { CodeEnter } from './components';
import CredentialCreate from './components/CredentialCreate';

export interface MfaSetupProps {
  onCompletion: () => void;
  activateMfa?: boolean;
}

const MfaSetup = ({ onCompletion, activateMfa }: MfaSetupProps) => {
  const dispatch = useAppDispatch();

  const mfaState = useAppSelector((state) => state.mfa);

  useEffect(() => {
    dispatch(setAvailableMethods([]));
  }, []);

  if (mfaState.chosenMethodId) {
    return <CodeEnter onCompletion={onCompletion} activateMfa={activateMfa} />;
  }

  return <CredentialCreate />;
};

export default MfaSetup;
