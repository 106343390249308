import { useProfileData } from 'hooks/use-profile-data';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { useGetAlertersQuery } from 'store/api/alerters.api';
import { discardTriggerId } from 'store/reducers/alerter/alerter.slice';

export const useAlerters = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();

  useProfileData();

  const { data, isLoading } = useGetAlertersQuery(
    { patientProfileId: Number(profileId) },
    { skip: !profileId },
  );

  useEffect(() => {
    dispatch(discardTriggerId());
  }, []);

  return {
    alerters: data,
    isLoading,
    alerterCount: data
      ? Object.values(data).reduce((sum, curr) => {
          return sum + curr.length;
        }, 0)
      : 0,
  };
};
