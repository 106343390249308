import { ConfirmationOptions } from 'components/_extra/ConfirmationDialog/ConfirmationDialog';
import { ConfirmationServiceContext } from 'components/_extra/ConfirmationDialog/ConfirmationService';
import { useContext } from 'react';

export const useConfirmation = () => {
  const confirm = useContext(ConfirmationServiceContext);

  return (
    options: ConfirmationOptions,
    onConfirm: () => void,
    onReject?: () => void,
  ) => {
    confirm(options).then(onConfirm).catch(onReject);
  };
};
