import { Tooltip, Link, LinkProps } from '@mui/material';
import React, { ReactNode } from 'react';

const SharedAlertersDetails = ({
  alerters,
  listSymbol,
  linkProps = {},
}: {
  alerters: string[];
  listSymbol?: ReactNode;
  linkProps?: LinkProps;
}) => {
  const limit = 3;

  if (alerters.length === 0) {
    return <span>-</span>;
  }

  if (alerters.length === 4) {
    return (
      <>
        {alerters.map((value, index) => (
          <React.Fragment key={index}>
            {listSymbol}
            {value} <br />
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      {alerters.slice(0, limit).map((value, index) => (
        <React.Fragment key={index}>
          {listSymbol}
          {value} <br />
        </React.Fragment>
      ))}
      {alerters.length > limit && (
        <Tooltip
          placement="bottom-start"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
          title={alerters.slice(limit).map((value, index) => (
            <React.Fragment key={index}>
              {value} <br />
            </React.Fragment>
          ))}
        >
          <Link color="secondary" {...linkProps}>
            {alerters.length - limit} more
          </Link>
        </Tooltip>
      )}
    </>
  );
};

export default SharedAlertersDetails;
