import { useReminderTriggerList } from '../hooks/use-reminder-trigger-list';
import { Box, Skeleton } from '@mui/material';
import { ScheduleRuleCard } from 'components/_extra';
import { useEditTrigger } from '../hooks/use-edit-trigger';
import { useTriggerControls } from '../hooks/use-trigger-controls';
import { TransparentBackdrop } from 'pages/AlerterDetails/components/Triggers/styled';
import { EmptyList } from 'components/_extra/EmptyList';
import { list } from 'assets/iconify';
import AddTriggerButton from './AddTriggerButton';
import { getSideBarState } from 'services/sidebar';

const Triggers = () => {
  const { functionsRef } = useEditTrigger();
  const { triggers, isLoading } = useReminderTriggerList();
  const {
    handleRuleRemove,
    onSwitchChange,
    isSwitchDisabled,
    handleEditClick,
    handleSaveClick,
    handleCancelEditing,
    editedTrigger,
  } = useTriggerControls();

  const isSidebarOpen = getSideBarState();

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns={
          triggers.length === 0 || isLoading
            ? '1fr'
            : {
                xxs: '1fr',
                newMd: '1fr 1fr',
                newXl: `repeat(${isSidebarOpen ? 2 : 3}, 1fr)`,
                new2Xl: `repeat(${isSidebarOpen ? 3 : 4}, 1fr)`,
                new3Xl: `repeat(${isSidebarOpen ? 4 : 5}, 1fr)`,
                new4Xl: `repeat(5, 1fr)`,
              }
        }
        gap={{ xxs: 2, sm: 3 }}
        pb={1}
      >
        {isLoading ? (
          <>
            <Skeleton variant="rounded" height={264} width="100%" />
            <Skeleton variant="rounded" height={264} width="100%" />
          </>
        ) : triggers.length === 0 ? (
          <EmptyList
            icon={list.icons.normal}
            title="The trigger list is empty"
            message="Add one or more schedule triggers to deliver your reminder at a specific time during the day or week."
          >
            <AddTriggerButton />
          </EmptyList>
        ) : (
          triggers.map((rule) => (
            <ScheduleRuleCard
              key={rule.id + rule.serializedSchedule.startTime}
              id={rule.id}
              getValuesRef={functionsRef}
              isSwitchDisabled={isSwitchDisabled}
              isActive={rule.enabled}
              onSwitchChange={(checked: boolean) =>
                onSwitchChange(rule.id, checked)
              }
              onEdit={() => handleEditClick(rule.id)}
              onRemove={() => handleRuleRemove(rule.id)}
              onSave={() => handleSaveClick(rule.id)}
              onBackClick={handleCancelEditing}
              defaultValues={{
                days: rule.serializedSchedule.days,
                time: rule.serializedSchedule.startTime,
              }}
            />
          ))
        )}
      </Box>
      <TransparentBackdrop
        open={!!editedTrigger}
        onClick={handleCancelEditing}
      />
    </>
  );
};

export default Triggers;
