import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const locationOn: IconifyJSON = {
  prefix: 'location-on',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12.0017 11.8654C12.4993 11.8654 12.9247 11.6882 13.2779 11.3339C13.6311 10.9795 13.8077 10.5536 13.8077 10.056C13.8077 9.55841 13.6305 9.13302 13.2762 8.77982C12.9219 8.42662 12.4959 8.25002 11.9983 8.25002C11.5007 8.25002 11.0753 8.42719 10.7221 8.78152C10.3689 9.13586 10.1923 9.56181 10.1923 10.0594C10.1923 10.557 10.3695 10.9824 10.7238 11.3356C11.0782 11.6888 11.5041 11.8654 12.0017 11.8654ZM12 21.5096C9.48337 19.3288 7.59619 17.2993 6.33849 15.4211C5.08081 13.5429 4.45197 11.8186 4.45197 10.2481C4.45197 7.94041 5.19844 6.07215 6.69139 4.6433C8.18432 3.21445 9.95387 2.50002 12 2.50002C14.0462 2.50002 15.8157 3.21445 17.3086 4.6433C18.8016 6.07215 19.5481 7.94041 19.5481 10.2481C19.5481 11.8186 18.9192 13.5429 17.6615 15.4211C16.4038 17.2993 14.5167 19.3288 12 21.5096Z" fill="currentColor"/>',
    },
  },
};
