import React, { useMemo, useState } from 'react';
import { TabsUnstyled } from '@mui/base';
import { Box, Collapse, styled } from '@mui/material';
import { Icon } from '@iconify/react';
import {
  ToolbarBadge,
  ToolbarTab,
  ToolbarTabPanel,
  ToolbarTabsList,
  ToolbarWrapper,
} from './components/FiltersToolbar.styled';
import Search from './components/Search';
import FilterTabs, { FilterTabIndex } from './components/FilterTabs';
import Filters from './components/Filters';
import { useFilters } from './hooks/use-filters';
import { Link } from 'react-router-dom';
import { useFiltersQueryParams } from './hooks/use-filters-query-params';
import { personSearch } from 'assets/iconify';

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
}));

const DropdownWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3, 0, 3),
  width: '100%',
  [theme.breakpoints.down('newMd')]: {
    padding: theme.spacing(1, 2, 0, 2),
  },
  [theme.breakpoints.down('xxs')]: {
    padding: theme.spacing(1, 1.5, 0, 1.5),
  },
}));

/*
 * This was done to fix an issue where "ownerState" was passed as a prop to a
 * DOM element, making React complain in the console. BTW, it wasn't my choice
 * to make it this dirty.
 *
 * https://github.com/mui/material-ui/issues/32882
 */
const LinkWrapper = React.forwardRef((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ownerState, ...other } = props;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Link {...other} ref={ref} />;
});

interface Props {
  routerTabState: 'search' | 'apple-watch';
  withoutSearch?: boolean;
  searchIconUrl?: string;
  filtersIconUrl?: string;
  withoutChips?: boolean;
}

const Toolbar = ({
  routerTabState,
  withoutSearch,
  filtersIconUrl,
  searchIconUrl,
  withoutChips,
}: Props) => {
  const { filters, isUnset: areFiltersUnset } = useFilters(withoutChips);
  const { generateStringifiedParams } = useFiltersQueryParams();

  const stringifiedParams = useMemo(
    () => generateStringifiedParams(filters.appleWatch),
    [filters.appleWatch],
  );

  const [activeTab, setActiveTab] = useState(
    routerTabState === 'search' ? 0 : 1,
  );
  const [activeFilterTab, setActiveFilterTab] = useState<FilterTabIndex>(0);
  const [isSearchModified, setIsSearchModified] = useState(false);

  const handleSearchInputChange = (value: string) => {
    setIsSearchModified(!!value.length);
  };

  const isDropdownOpen = activeTab === 1;

  const modifiedTabIds = areFiltersUnset ? [] : [FilterTabIndex.APPLE_WATCH];

  return (
    <Wrapper>
      <TabsUnstyled value={activeTab} onChange={(_e, v) => setActiveTab(+v)}>
        <ToolbarWrapper isExpanded={isDropdownOpen}>
          <Box width="100%">
            {!withoutSearch && (
              <ToolbarTabPanel value={0}>
                <Search onChange={handleSearchInputChange} />
              </ToolbarTabPanel>
            )}
            <ToolbarTabPanel value={1}>
              <FilterTabs
                value={activeFilterTab}
                modifiedTabIds={modifiedTabIds}
                onChange={(_e, v) => setActiveFilterTab(v as FilterTabIndex)}
              />
            </ToolbarTabPanel>
          </Box>
          {!withoutSearch && (
            <ToolbarTabsList>
              <ToolbarBadge invisible={!isSearchModified}>
                <ToolbarTab
                  value={0}
                  component={LinkWrapper}
                  to={searchIconUrl ?? ''}
                >
                  <Icon icon={personSearch.icons.normal} />
                </ToolbarTab>
              </ToolbarBadge>
              <ToolbarBadge invisible={areFiltersUnset}>
                <ToolbarTab
                  value={1}
                  component={LinkWrapper}
                  to={`${filtersIconUrl ?? ''}${stringifiedParams}`}
                >
                  <Icon icon="material-symbols:tune" />
                </ToolbarTab>
              </ToolbarBadge>
            </ToolbarTabsList>
          )}
        </ToolbarWrapper>
      </TabsUnstyled>
      <Collapse in={isDropdownOpen}>
        <DropdownWrapper>
          <Filters withoutChips={withoutChips} tabIndex={activeFilterTab} />
        </DropdownWrapper>
      </Collapse>
    </Wrapper>
  );
};

export default Toolbar;
