import { Icon } from '@iconify/react';
import {
  ButtonProps,
  Button,
  styled,
  useTheme,
  alpha,
  Backdrop,
  BackdropProps,
  Box,
  Typography,
} from '@mui/material';
import IconButton, { IconButtonProps } from 'components/IconButton/IconButton';

export const AcknowledgeButton = styled(
  ({ isOpen, ...props }: ButtonProps & { isOpen?: boolean }) => {
    const theme = useTheme();

    return (
      <Button {...props}>
        <Icon
          icon="material-symbols:done-all"
          height={theme.spacing(2.5)}
          style={{ marginRight: theme.spacing() }}
        />
        {isOpen ? 'Confirm' : 'Acknowledge'}
      </Button>
    );
  },
)(({ theme, isOpen }) => {
  const backgroundColor = isOpen
    ? theme.palette.secondary.main
    : theme.palette.error.main;

  return {
    pointerEvents: 'auto',
    overflow: 'visible',
    position: 'relative',
    backgroundColor,
    width: theme.spacing(22),
    height: theme.spacing(6),
    zIndex: 0,

    '&:hover': {
      backgroundColor,
      filter: 'brightness(90%)',
    },
    ...(!isOpen && {
      '&:before': {
        zIndex: -1,
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'inherit',
        borderRadius: 'inherit',
        animation: 'pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite',
        transition: 'opacity .3s, transform .3s, -webkit-transform .3s',
      },
    }),
  };
});

export const StyledBackdrop = styled((props: BackdropProps) => (
  <Backdrop timeout={250} {...props} />
))(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  backdropFilter: 'blur(2px)',
}));

export const IssueItem = ({
  children,
  occurrences,
}: {
  children: string;
  occurrences?: number;
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" ml={2} mb={1}>
      <Icon
        icon="material-symbols:done"
        width={theme.spacing(3)}
        color={theme.palette.error.main}
        style={{ marginRight: theme.spacing(1) }}
      />
      <Typography variant="body2" color="error">
        {children}
      </Typography>
      {occurrences && occurrences > 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.error.main}
          borderRadius="50%"
          width={theme.spacing(2.5)}
          height={theme.spacing(2.5)}
          ml={1}
        >
          <Typography variant="caption" color={theme.palette.common.white}>
            {occurrences > 9 ? '9+' : occurrences}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export const ModalCloseBtn = styled((props: Omit<IconButtonProps, 'icon'>) => (
  <IconButton size="small" icon="material-symbols:close" {...props} />
))(({ theme }) => ({
  color: theme.palette.lightBlue.main,
  padding: theme.spacing(0.75),
  borderRadius: theme.spacing(0.625),
  transition: theme.transitions.create(['color', 'background-color']),
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
}));
