import {
  Box,
  Button,
  CircularProgress,
  Typography,
  styled,
} from '@mui/material';
import { PageContent } from 'components/_extra/generic';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetByIdQuery } from 'store/api/devices.api';
import WatchAssignModal from '../ProfileLookupResults/WatchAssignModal';
import { useState } from 'react';
import { routes } from 'nav';

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const DeviceData = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { deviceId } = useParams<{ deviceId: string }>();
  const { data, isLoading } = useGetByIdQuery(Number(deviceId), {
    skip: !deviceId,
  });

  const goToProfile = () => {
    if (data) {
      navigate(routes.admin.lookupResults.href(data.account_id));
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          mt: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return (
      <Typography variant="h6" textAlign="center">
        Device not found
      </Typography>
    );
  }

  return (
    <PageContent sx={{ p: 2 }}>
      <Grid>
        <Typography color="primary" fontWeight={600}>
          Device ID:
        </Typography>
        <span>{data.id}</span>
        <Typography color="primary" fontWeight={600}>
          Is active:
        </Typography>
        <span>{data.active ? 'Yes' : 'No'}</span>
        <Typography color="primary" fontWeight={600}>
          Account ID:
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
          {data.account_id}
          <Button size="small" variant="text" onClick={goToProfile}>
            See profile
          </Button>
        </Box>
        <Typography color="primary" fontWeight={600}>
          Name:
        </Typography>
        <span>{data.name}</span>
        <Typography color="primary" fontWeight={600}>
          Model:
        </Typography>
        <span>{data.model}</span>
        <Typography color="primary" fontWeight={600}>
          System:
        </Typography>
        <span>{data.system}</span>
        <Typography color="primary" fontWeight={600}>
          Actions:
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ width: 'fit-content' }}
          onClick={() => setIsOpen(true)}
        >
          Reassign device
        </Button>
      </Grid>
      <WatchAssignModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        deviceId={data?.id}
      />
    </PageContent>
  );
};

export default DeviceData;
