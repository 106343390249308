import config from 'env';
import { IAccountResponse } from 'models/account.model';
import {
  IAuthenticate,
  IAuthenticateResponse,
  IGetAdminStatus,
  IGetCredentialsOptionsResponse,
  IConfirmedCredentialResponse,
  IVerifyEmail,
  CredentialType,
  IVerifyEmailResponse,
  ICreateCredentialRequest,
  ICreateCredentialResponse,
  ISubmitPasswordResetTokenResponse,
  IChangePasswordRequest,
  IChangePasswordResponse,
} from 'models/auth.model';
import { bcApi } from './_api';
import { setCaregiverRole } from 'services/caregiver-role';

export const authApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyEmail: builder.mutation<IVerifyEmailResponse, IVerifyEmail>({
      query: (data) => ({
        baseURL: config.REACT_APP_API_URL,
        url: '/credentials/existance',
        method: 'POST',
        data: {
          schema: 'email',
          identifier: data.email,
        },
      }),
    }),
    verifyEmailHipaa: builder.mutation<IVerifyEmailResponse, IVerifyEmail>({
      query: (data) => ({
        baseURL: config.REACT_APP_API_URL_ALTERNATE,
        url: '/credentials/existance',
        method: 'POST',
        data: {
          schema: 'email',
          identifier: data.email,
        },
      }),
    }),
    getAllCredentials: builder.query<
      IGetCredentialsOptionsResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/credentials/${id}/all`,
        method: 'GET',
      }),
    }),
    authenticate: builder.mutation<IAuthenticateResponse, IAuthenticate>({
      query: (data) => ({
        url: '/credentials/authenticate',
        method: 'POST',
        data: {
          schema: 'email',
          identifier: data.email,
          secret: data.password,
        },
      }),
    }),
    validateCode: builder.mutation<
      IAuthenticateResponse,
      {
        code: string;
        identifier: string;
        credentialIdToReset?: number;
        activateMfa?: boolean;
      }
    >({
      query: ({ identifier, code, credentialIdToReset, activateMfa }) => ({
        url: '/credentials/authenticate',
        method: 'POST',
        data: {
          schema: 'quick-code',
          identifier,
          secret: code,
          credential_id_to_reset: credentialIdToReset,
          ...(activateMfa !== undefined &&
            (activateMfa ? { activate_mfa: true } : { deactivate_mfa: true })),
        },
      }),
      invalidatesTags: ['MfaCredentials', 'Credentials'],
    }),
    adminStatus: builder.query<boolean, IGetAdminStatus>({
      query: (data) => ({
        url: `/accounts/${data.userId}`,
        method: 'GET',
      }),
      transformResponse: (value: IAccountResponse) => {
        setCaregiverRole(value.result.is_admin === true ? 'admin' : 'viewer');
        return value.result.is_admin;
      },
    }),
    requestConfirmation: builder.mutation<
      IConfirmedCredentialResponse,
      { identifier: string; schema: CredentialType }
    >({
      query: ({ identifier, schema }) => ({
        url: '/credentials/request_credential_confirmation',
        method: 'POST',
        data: {
          identifier,
          schema,
        },
      }),
    }),
    getMFACredentials: builder.query<IGetCredentialsOptionsResponse, void>({
      providesTags: ['MfaCredentials'],
      query: () => ({
        url: '/credentials/mfa_credential_options',
        method: 'GET',
      }),
    }),
    getCredentials: builder.query<IGetCredentialsOptionsResponse, void>({
      query: () => ({
        url: '/credentials',
        method: 'GET',
      }),
      providesTags: ['Credentials'],
    }),
    deleteCredential: builder.mutation<unknown, { id: number }>({
      query: ({ id }) => ({
        url: `/credentials/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Credentials', 'MfaCredentials'],
    }),
    deliverCode: builder.mutation<IConfirmedCredentialResponse, { id: number }>(
      {
        query: ({ id }) => ({
          url: '/credentials/deliver_mfa_credential',
          method: 'POST',
          data: {
            mfa_credential_id: id,
          },
        }),
      },
    ),
    createCredential: builder.mutation<
      ICreateCredentialResponse,
      ICreateCredentialRequest
    >({
      query: ({ schema, identifier }) => ({
        url: '/credentials',
        method: 'POST',
        data: {
          schema,
          identifier,
        },
      }),
    }),
    submitPasswordResetToken: builder.mutation<
      ISubmitPasswordResetTokenResponse,
      { token: string }
    >({
      query: ({ token }) => ({
        url: '/credentials/authenticate_for_reset_secret',
        method: 'POST',
        data: {
          one_time_token: token,
        },
      }),
    }),
    changePassword: builder.mutation<
      IChangePasswordResponse,
      IChangePasswordRequest
    >({
      query: ({
        credentialId,
        oneTimeToken,
        newPassword,
        expireSessions,
        oldPassword,
      }) => ({
        url: `/credentials/${credentialId}`,
        method: 'PUT',
        data: {
          old_secret: oldPassword,
          new_secret: newPassword,
          expire_sessions: expireSessions || false,
          one_time_token: oneTimeToken,
        },
      }),
    }),
    requestPasswordReset: builder.mutation<
      { result: { reset: boolean } },
      { email: string }
    >({
      query: ({ email }) => ({
        url: '/credentials/deliver_one_time_reset_token_for_credential',
        method: 'POST',
        data: {
          schema: 'email',
          identifier: email,
        },
      }),
    }),
  }),
});

export const {
  useAdminStatusQuery,
  useAuthenticateMutation,
  useChangePasswordMutation,
  useCreateCredentialMutation,
  useDeleteCredentialMutation,
  useDeliverCodeMutation,
  useGetCredentialsQuery,
  useLazyGetCredentialsQuery,
  useGetMFACredentialsQuery,
  useLazyGetMFACredentialsQuery,
  useRequestConfirmationMutation,
  useRequestPasswordResetMutation,
  useSubmitPasswordResetTokenMutation,
  useValidateCodeMutation,
  useVerifyEmailMutation,
  useVerifyEmailHipaaMutation,
  useGetAllCredentialsQuery,
} = authApi;
