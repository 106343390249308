import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authApi } from 'store/api/auth.api';
import { profilesApi } from 'store/api/profiles.api';

export interface MfaMethod {
  id: number;
  type: 'email' | 'phone';
  credential: string;
}

interface MfaState {
  requiresMfa: boolean | null;
  availableMethods: MfaMethod[];
  chosenMethodId: number | null;
  codeRequestedAt: string | null;
}

const initialState: MfaState = {
  requiresMfa: null,
  availableMethods: [],
  chosenMethodId: null,
  codeRequestedAt: null,
};

export const mfaSlice = createSlice({
  name: 'mfa',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    resetChosenMethod: (state) => {
      state.chosenMethodId = null;
      state.codeRequestedAt = null;
    },
    setAvailableMethods: (state, action: PayloadAction<MfaMethod[]>) => {
      state.chosenMethodId = null;
      state.availableMethods = action.payload;
    },
    setChosenMethodId: (state, action: PayloadAction<number | null>) => {
      state.chosenMethodId = action.payload;
    },
    toggleMfa: (state, action: PayloadAction<boolean>) => {
      state.requiresMfa = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.authenticate.matchFulfilled,
        (state, action) => {
          state.requiresMfa =
            action.payload.meta.credential.is_confirmed !== null;
        },
      )
      .addMatcher(
        authApi.endpoints.submitPasswordResetToken.matchFulfilled,
        (state, action) => {
          state.requiresMfa =
            action.payload.meta.credential.is_confirmed !== null;
        },
      )
      .addMatcher(
        authApi.endpoints.getMFACredentials.matchFulfilled,
        (state, action) => {
          state.availableMethods = action.payload.results.map((item) => ({
            id: item.id,
            type: item.schema as 'email' | 'phone',
            credential: item.identifier,
          }));
        },
      )
      .addMatcher(
        authApi.endpoints.deliverCode.matchFulfilled,
        (state, action) => {
          state.chosenMethodId = action.meta.arg.originalArgs.id;
          state.codeRequestedAt = new Date().toISOString();
        },
      )
      .addMatcher(
        profilesApi.endpoints.getProfile.matchFulfilled,
        (state, action) => {
          state.requiresMfa = action.payload.result.requires_mfa;
        },
      )
      .addMatcher(
        profilesApi.endpoints.updateProfile.matchFulfilled,
        (state, action) => {
          state.requiresMfa = !!action.payload.result.requires_mfa;
        },
      );
  },
});

export const {
  resetState,
  resetChosenMethod,
  setAvailableMethods,
  setChosenMethodId,
  toggleMfa,
} = mfaSlice.actions;

export default persistReducer(
  { key: 'mfa', storage, whitelist: ['requiresMfa', 'codeRequestedAt'] },
  mfaSlice.reducer,
);
