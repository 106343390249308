export const DAYS_OF_THE_WEEK = [
  { value: 0, label: 'S', altLabel: 'Sun' },
  { value: 1, label: 'M', altLabel: 'Mon' },
  { value: 2, label: 'T', altLabel: 'Tue' },
  { value: 3, label: 'W', altLabel: 'Wed' },
  { value: 4, label: 'T', altLabel: 'Thu' },
  { value: 5, label: 'F', altLabel: 'Fri' },
  { value: 6, label: 'S', altLabel: 'Sat' },
] as const;

export const ALL_DAYS = DAYS_OF_THE_WEEK.map((d) => d.value);
export type Day = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const WEEKEND = [0, 6];
export const WEEKDAYS = [1, 2, 3, 4, 5];

export const PresetOption = {
  EVERYDAY: 'everyday',
  WEEKDAYS: 'weekdays',
  WEEKEND: 'weekend',
  CUSTOM: 'custom',
} as const;
export type PresetOption = (typeof PresetOption)[keyof typeof PresetOption];

export const getPreset = (option: PresetOption) => {
  switch (option) {
    case PresetOption.EVERYDAY:
      return ALL_DAYS;
    case PresetOption.WEEKDAYS:
      return WEEKDAYS;
    case PresetOption.WEEKEND:
      return WEEKEND;
    default:
      return [1];
  }
};

export const getMatchingPreset = (values: number[]) => {
  if (values.length === 7) return PresetOption.EVERYDAY;
  if (WEEKEND.every((e) => values.includes(e)) && values.length === 2)
    return PresetOption.WEEKEND;
  if (WEEKDAYS.every((e) => values.includes(e)) && values.length === 5)
    return PresetOption.WEEKDAYS;
  return PresetOption.CUSTOM;
};

const isInterval = (values: number[]) => {
  return values.reduce((value, curr, index, array) => {
    if (value && (index === 0 || array[index - 1] + 1 === curr)) {
      return true;
    }
    return false;
  }, true);
};

export const getDaysSummary = (values: Day[]): string => {
  const preset = getMatchingPreset(values);

  switch (preset) {
    case PresetOption.WEEKDAYS:
      return 'Mon-Fri';
    case PresetOption.WEEKEND:
      return 'Sat, Sun';
    case PresetOption.EVERYDAY:
      return 'Daily';
    default: {
      const sorted = [...values].sort();
      if (sorted.length > 2 && isInterval(sorted)) {
        return `${
          DAYS_OF_THE_WEEK.find((d) => d.value === sorted[0])?.altLabel
        }-${
          DAYS_OF_THE_WEEK.find((d) => d.value === sorted[sorted.length - 1])
            ?.altLabel
        }`;
      }
      return sorted
        .map((s) => DAYS_OF_THE_WEEK.find((d) => d.value === s)?.altLabel)
        .join(', ');
    }
  }
};
