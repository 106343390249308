import VInput, { VInputProps } from 'components/VInput/VInput';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import { Box, useTheme } from '@mui/material';
import StrengthBar from './StrengthBar';
import { PasswordStrength } from 'models/auth.model';
import { visibility } from 'assets/iconify';

interface IProps extends VInputProps {
  passwordStrength?: PasswordStrength | undefined;
}

interface EyeIconProps {
  isVisible: boolean;
  toggle: () => void;
}

const EyeIcon = ({ isVisible, toggle }: EyeIconProps) => {
  const { palette } = useTheme();

  return (
    <Icon
      height={24}
      width={24}
      cursor="pointer"
      onClick={toggle}
      color={palette.borderGray.main}
      icon={isVisible ? visibility.icons.shown : visibility.icons.hidden}
    />
  );
};

const VInputPassword = ({
  onChange,
  label,
  text,
  passwordStrength,
  name,
  value,
  onKeyDown,
  ...rest
}: IProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((value) => !value);
  };

  return (
    <Box>
      <VInput
        size="large"
        onChange={onChange}
        value={value}
        label={label || text}
        type={isPasswordVisible ? 'text' : 'password'}
        name={name}
        onKeyDown={onKeyDown}
        endAdornment={
          <EyeIcon
            isVisible={isPasswordVisible}
            toggle={togglePasswordVisibility}
          />
        }
        {...rest}
      />
      {!!passwordStrength && (
        <StrengthBar passwordStrength={passwordStrength} />
      )}
    </Box>
  );
};

VInputPassword.displayName = 'VInputPassword';

export default VInputPassword;
