import { Layout } from 'layouts/Public';
import { Navigate, NavigateProps } from 'react-router-dom';
import { useAppSelector } from 'store';

const renderNavigateProps = (redirectPath: string): NavigateProps => {
  const split = redirectPath.split('?');

  if (split.length === 2) {
    return {
      to: {
        pathname: split[0],
        search: split[1],
      },
    };
  }

  return {
    to: {
      pathname: split[0],
    },
  };
};

const PublicRoute = ({ redirectPath, outlet }: PublicRouteProps) => {
  const { isAuthenticated } = useAppSelector((state) => state.user);

  if (!isAuthenticated) {
    return <Layout>{outlet}</Layout>;
  } else {
    return <Navigate {...renderNavigateProps(redirectPath)} replace />;
  }
};

export default PublicRoute;

export type PublicRouteProps = {
  redirectPath: string;
  outlet: JSX.Element;
};
