import { Icon } from '@iconify/react';
import { Button, ButtonProps, styled } from '@mui/material';
import { liveTracking } from 'assets/iconify';
import { forwardRef } from 'react';

export const CenterButton = styled(
  forwardRef((props: ButtonProps, ref) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Button ref={ref as any} {...props}>
      <Icon
        width={20}
        height={20}
        icon={liveTracking.icons.center}
        color="white"
      />
    </Button>
  )),
)(({ theme }) => ({
  margin: 0,
  padding: 0,
  minWidth: 0,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));
