import { CredentialOption } from 'models/auth.model';
import { routes } from 'nav';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDeliverCodeMutation,
  useGetMFACredentialsQuery,
} from 'store/api/auth.api';

const findId = (creds: CredentialOption[] | undefined, identifier?: string) => {
  if (identifier && creds) {
    return creds?.findIndex((cred) => cred.identifier === identifier);
  }
  return -1;
};

const useSelectCredentials = () => {
  const { data, isLoading, isFetching } = useGetMFACredentialsQuery();
  const navigate = useNavigate();

  const credential = useRef<CredentialOption | undefined>(undefined);
  const [deliverCode, { isError, isSuccess }] = useDeliverCodeMutation();
  const [selectedMethod, setSelectedMethod] = useState<number | undefined>(
    undefined,
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newCredential: CredentialOption,
  ) => {
    setSelectedMethod(+event.target.value);
    credential.current = newCredential;
  };

  const handleSubmit = () => {
    if (credential.current?.id) {
      deliverCode({ id: credential.current.id });
    }
  };

  useEffect(() => {
    if (data?.results.length) {
      setSelectedMethod(data.results[0].id);
      credential.current = data.results[0];
    }
  }, [data?.results]);

  useEffect(() => {
    if (isError) {
      toast('An error occured. Try again later.', { type: 'error' });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && data) {
      const { id, identifier, schema } =
        data.results[findId(data.results, credential.current?.identifier)];

      navigate(routes.mfa.confirmIdentity.href, {
        state: {
          schema,
          identifier,
          credentialId: id,
        },
      });
    }
  }, [isSuccess]);

  return {
    credentialList: data?.results,
    handleChange,
    handleSubmit,
    selectedMethod,
    isLoading: isLoading || isFetching,
  };
};

export default useSelectCredentials;
