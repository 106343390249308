import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDuplicateRecipientManagerMutation } from 'store/api/alerters.api';

export const useCopyRecipientManager = (isReminder?: boolean) => {
  const { alertId, reminderId } = useParams();
  const [duplicate, { isSuccess, isError, isLoading, data }] =
    useDuplicateRecipientManagerMutation();

  const handleCopy = (id: number) => {
    duplicate({ id, alerterId: Number(isReminder ? reminderId : alertId) });
  };

  useEffect(() => {
    if (isError) {
      toast.error('Could not copy the recipient manager.');
    }

    if (isSuccess) {
      toast.success('Recipient manager copied successfully.');
    }
  }, [isError, isSuccess]);

  return {
    handleCopy,
    response: data,
    isLoading,
  };
};
