import { Link } from '@mui/material';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';

const ExistsError: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      There is an existing account associated with this email. <br />
      Please try to{' '}
      <Link
        color="secondary"
        fontWeight={600}
        onClick={() => navigate(routes.public.login.href)}
      >
        {' '}
        login
      </Link>
      .
    </>
  );
};

export default ExistsError;
