import { AlerterTemplateSummary } from 'types/alerters';

const transformValue = (value: string, fieldType: string, key: string) => {
  if (fieldType === 'minutes') {
    return parseInt(value) * 60;
  }

  if (fieldType === 'percentage') {
    return parseFloat(value) * 100;
  }

  if (key === 'speed') {
    return Math.round(parseInt(value) * 1.60934);
  }

  return parseInt(value);
};

export const getPropsFromTemplateParams = (
  templateParams: AlerterTemplateSummary['template_params'],
) => {
  if (!templateParams) {
    return [];
  }

  return templateParams.map((param) => {
    const key = Object.keys(param)[0];
    const { field_type, display_unit, default: defaultValue } = param[key];

    return {
      label: display_unit,
      defaultValue: transformValue(defaultValue ?? '0', field_type, key),
      fieldType: field_type,
      key,
    };
  });
};
