import { useConfirmation } from 'hooks/use-confirmation';
import { SafeZones } from 'pages/PatientAccount/components/SafeZones';
import { useEffect, useState } from 'react';
import { SafeZoneParams } from 'types/safe-zones';

type Props = {
  defaultSafeZones?: SafeZoneParams[];
  updateSafeZoneList?: (safeZones: SafeZoneParams[]) => void;
};

const ConfiguratorSafeZones = ({
  defaultSafeZones,
  updateSafeZoneList,
}: Props) => {
  const [zones, setZones] = useState<SafeZoneParams[]>(defaultSafeZones ?? []);
  const confirm = useConfirmation();

  const handleZoneSave = (zone: SafeZoneParams) => {
    if (zones.some((z) => z.id === zone.id)) {
      setZones(zones.map((z) => (z.id === zone.id ? zone : z)));
      return;
    }
    setZones([...zones, zone]);
  };

  const handleDeleteZone = (id: number) => {
    confirm(
      {
        title: `Are you sure you want to delete “${
          zones.find((zone) => zone.id === id)?.title
        }” from the safe zone list?`,
        message:
          'This safe zone will be deleted immediately. You can’t undo this action.',
        rejectButtonLabel: 'Cancel',
        confirmButtonLabel: 'Delete',
        variant: 'danger',
      },
      () => setZones(zones.filter((zone) => zone.id !== id)),
    );
  };

  useEffect(() => {
    if (updateSafeZoneList) {
      updateSafeZoneList(zones);
    }
  }, [zones]);

  return (
    <SafeZones
      safeZones={zones.map((zone) => ({
        ...zone,
        radius: zone.radius ?? 0,
        geofence: zone.geofence ?? [],
        snapshotUrl: '',
        accountId: 0,
        createdAt: '',
        updatedAt: '',
        label: zone.title,
        notifications: false,
      }))}
      onZoneDelete={handleDeleteZone}
      onZoneSave={handleZoneSave}
      listButtonToLeft
    />
  );
};

export default ConfiguratorSafeZones;
