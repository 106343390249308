import { Box, Button } from '@mui/material';
import Message from './components/Message';
import { CredentialType } from 'models/auth.model';
import CodeInput from './components/CodeInput';
import ResendMessage from './components/ResendMessage';
import { useCodeInput } from './hooks/use-code-input';

const Mfa = () => {
  const {
    error,
    state,
    onCompleteInput,
    disableSubmitButton,
    isDisabled,
    isEditable,
    onSubmit,
    updateTimer,
    remainingTime,
    onResend,
    isCodeResent,
  } = useCodeInput();

  return (
    <>
      <Message
        isEditable={isEditable}
        identifier={state.identifier}
        schema={(state.schema as CredentialType) || 'email'}
        remainingTime={remainingTime}
      />
      <CodeInput
        error={error}
        onComplete={onCompleteInput}
        onRemoving={disableSubmitButton}
        onSubmit={onSubmit}
      />
      <ResendMessage
        onResend={onResend}
        setTimer={updateTimer}
        isCodeResent={isCodeResent}
      />
      <Box p={1} pt={3}>
        <Button size="large" fullWidth onClick={onSubmit} disabled={isDisabled}>
          Continue
        </Button>
      </Box>
    </>
  );
};

export default Mfa;
