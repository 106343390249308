import { Button } from '@mui/material';
import { useAppSelector } from 'store';

const SaveButton = ({ onClick }: { onClick: () => void }) => {
  const isDisabled = !useAppSelector(
    (state) => state.alerter.editedAlerter.isModifiedSettings,
  );

  return (
    <Button
      disabled={isDisabled}
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      Save
    </Button>
  );
};

export default SaveButton;
