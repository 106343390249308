import { Icon } from '@iconify/react';
import { Box, Button, Modal } from '@mui/material';
import {
  ModalHeader,
  ModalWrapper,
} from 'pages/AlerterDetails/components/Triggers/styled';
import { memo, useState } from 'react';
import CreateReminderForm from './CreateReminderForm';
import { caregiverRole } from 'services/caregiver-role';

const CreateReminderButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        sx={{ mr: { xxs: 0, newLg: 1 } }}
        disabled={caregiverRole.value === 'viewer'}
        startIcon={<Icon icon="material-symbols:add-box-outline" />}
      >
        Create Reminder
      </Button>
      <Modal open={isModalOpen}>
        <ModalWrapper>
          <ModalHeader>Reminder Setup</ModalHeader>
          <Box p={3}>
            <CreateReminderForm onClose={handleClose} />
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default memo(CreateReminderButton);
