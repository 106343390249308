import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { devicesApi, useDeregisterMutation } from 'store/api/devices.api';
import { toast } from 'react-toastify';

export const useDeregisterDevice = (id: number) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const dispatch = useAppDispatch();

  const [
    deregister,
    {
      isLoading: isDeregistrationLoading,
      isSuccess: isDeregistered,
      originalArgs,
      isError,
    },
  ] = useDeregisterMutation();

  const deregisterDevice = () => {
    deregister({ deviceId: selectedId });
    setIsDialogOpen(false);
  };

  const handleDeregisterClick = (id: number) => {
    setIsDialogOpen(true);
    setSelectedId(id);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (isDeregistered) {
      dispatch(
        devicesApi.util.updateQueryData('getAll', { id }, (devices) => {
          return devices.filter(
            (device) => device.id !== originalArgs?.deviceId,
          );
        }),
      );
      toast('Device successfully deregistered.', { type: 'success' });
      return;
    }
    if (isError) {
      toast('Deregistration failed.', { type: 'error' });
    }
  }, [isDeregistered, isError]);

  return {
    isDeregistered,
    isDialogOpen,
    setIsDialogOpen,
    deregisterDevice,
    handleCancel,
    handleDeregisterClick,
    isDeregistrationLoading,
    selectedId,
  };
};
