import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 64, height: 64 };

export const regions: IconifyJSON = {
  prefix: 'regions',
  icons: {
    circle: {
      ...defaultSizes,
      body: `<path d="M53.3996 32.0001C53.3996 43.819 43.8185 53.4001 31.9996 53.4001C20.1807 53.4001 10.5996 43.819 10.5996 32.0001C10.5996 20.1812 20.1807 10.6001 31.9996 10.6001C43.8185 10.6001 53.3996 20.1812 53.3996 32.0001Z" fill="#429580" fill-opacity="0.2" stroke="#429580" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M32.0008 35.1998C33.7681 35.1998 35.2008 33.7671 35.2008 31.9998C35.2008 30.2325 33.7681 28.7998 32.0008 28.7998C30.2335 28.7998 28.8008 30.2325 28.8008 31.9998C28.8008 33.7671 30.2335 35.1998 32.0008 35.1998Z" fill="#429580"/>`,
    },
    polygon: {
      ...defaultSizes,
      body: `<path d="M12.8008 19.2L48.0008 16V51.2L19.2008 48L12.8008 19.2Z" fill="#429580" fill-opacity="0.2" stroke="#429580" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M48.0008 19.1998C49.7681 19.1998 51.2008 17.7671 51.2008 15.9998C51.2008 14.2325 49.7681 12.7998 48.0008 12.7998C46.2335 12.7998 44.8008 14.2325 44.8008 15.9998C44.8008 17.7671 46.2335 19.1998 48.0008 19.1998Z" fill="#429580"/>
      <path d="M48.0008 54.4C49.7681 54.4 51.2008 52.9673 51.2008 51.2C51.2008 49.4327 49.7681 48 48.0008 48C46.2335 48 44.8008 49.4327 44.8008 51.2C44.8008 52.9673 46.2335 54.4 48.0008 54.4Z" fill="#429580"/>
      <path d="M19.2 51.1998C20.9673 51.1998 22.4 49.7671 22.4 47.9998C22.4 46.2325 20.9673 44.7998 19.2 44.7998C17.4327 44.7998 16 46.2325 16 47.9998C16 49.7671 17.4327 51.1998 19.2 51.1998Z" fill="#429580"/>
      <path d="M12.7996 22.4C14.5669 22.4 15.9996 20.9673 15.9996 19.2C15.9996 17.4327 14.5669 16 12.7996 16C11.0323 16 9.59961 17.4327 9.59961 19.2C9.59961 20.9673 11.0323 22.4 12.7996 22.4Z" fill="#429580"/>`,
    },
    path: {
      ...defaultSizes,
      body: `<path opacity="0.2" d="M46.3996 17.6001L17.5996 24.0001L35.1996 48.0001" stroke="#429580" stroke-width="20" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
      <path d="M46.3996 17.6001L17.5996 24.0001L35.1996 48.0001" stroke="#429580" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 6" fill="transparent"/>
      <path d="M17.6004 27.1998C19.3677 27.1998 20.8004 25.7671 20.8004 23.9998C20.8004 22.2325 19.3677 20.7998 17.6004 20.7998C15.8331 20.7998 14.4004 22.2325 14.4004 23.9998C14.4004 25.7671 15.8331 27.1998 17.6004 27.1998Z" fill="#429580"/>
      <path d="M46.3992 20.7999C48.1665 20.7999 49.5992 19.3672 49.5992 17.5999C49.5992 15.8326 48.1665 14.3999 46.3992 14.3999C44.6319 14.3999 43.1992 15.8326 43.1992 17.5999C43.1992 19.3672 44.6319 20.7999 46.3992 20.7999Z" fill="#429580"/>
      <path d="M35.2 51.1998C36.9673 51.1998 38.4 49.7671 38.4 47.9998C38.4 46.2325 36.9673 44.7998 35.2 44.7998C33.4327 44.7998 32 46.2325 32 47.9998C32 49.7671 33.4327 51.1998 35.2 51.1998Z" fill="#429580"/>`,
    },
  },
};
