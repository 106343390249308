import { Backdrop, Paper, outlinedInputClasses, styled } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

export const FormWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr',
  padding: theme.spacing(1),
}));

export const FlexLine = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'start',
  width: '100%',
  gap: theme.spacing(3),
  div: {
    // flexGrow: 1,
    flexBasis: 'calc(50% - 12px)',
  },
}));

export const InputBox = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  alignSelf: 'end',
  color: theme.palette.action.active,
  [`.${outlinedInputClasses.root}`]: {
    minWidth: theme.spacing(12),
    width: 'min-content',
  },
}));

export const ModalWrapper = styled(Paper)(({ theme }) => ({
  width: '90%',
  maxWidth: theme.spacing(50),
}));

export const ModalHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0, 3),
  height: theme.spacing(6.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 500,
}));

export const ModalContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflowY: 'auto',
}));

export const CardControlsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}));

export const TransparentBackdrop = styled(Backdrop)`
  background-color: transparent;
  backdrop-filter: unset;
  z-index: ${zIndex.drawer};
`;
