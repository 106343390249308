import { usePagination } from 'hooks/use-pagination';
import { useParams } from 'react-router-dom';
import {
  alerterHistoryFilterCriteria,
  getAlertIds,
  getDateRangeEnd,
  getDateRangeStart,
  getWidgets,
} from 'services/alert-history-filters';
import { useSearchAlerterEventsQuery } from 'store/api/alerters.api';

// If the alertId is provided, the hook will fetch the alert history for that specific alert. (Used in the AlertDetails page)
export const useAlerterHistory = (alertId?: string) => {
  const { profileId } = useParams();

  const {
    pageIndex: page,
    limit,
    offset,
    handlePaginationModelChange,
  } = usePagination(25, 0);

  const { data = { count: 0, events: [] }, isFetching } =
    useSearchAlerterEventsQuery({
      profileId: Number(profileId),
      offset,
      limit,
      beginsAt: getDateRangeStart()?.toISOString(),
      endsAt: getDateRangeEnd()?.toISOString(),
      ...(alertId
        ? { alerterIds: [alertId] }
        : alerterHistoryFilterCriteria.value === 'group' &&
          getAlertIds()?.length > 0
        ? { alerterIds: getAlertIds() }
        : { widgets: getWidgets() }),
    });

  return {
    rows: data.events,
    rowCount: data.count,
    isLoading: isFetching,
    limit,
    page,
    handlePaginationModelChange,
  };
};
