import * as Styled from './VButton.styles';
import { IVButtonProps } from './VButton.types';

const VButtonView: React.FC<IVButtonProps> = (props) => {
  return (
    <Styled.VButton
      alignSelf={props.alignSelf}
      borderColor={props.borderColor}
      width={props.width}
      borderRadius={props.borderRadius}
      bgcolor={props.bgcolor}
      type={props.type}
      onClick={props.onClick}
      margin={props.margin}
      disabled={props.disabled}
      style={props.style}
    >
      {props.isLoading ? (
        <Styled.CircularProgress size={20} color="primary" thickness={5} />
      ) : (
        props.icon
      )}
      <Styled.ButtonText color={props.textColor} fontWeight={props.fontWeight}>
        {props.text}
      </Styled.ButtonText>
    </Styled.VButton>
  );
};

VButtonView.displayName = 'VButtonView';

export default VButtonView;
