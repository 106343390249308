import { Dispatch, SetStateAction, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import { useSetEmergencyContactMutation } from 'store/api/account.api';
import { profilesApi } from 'store/api/profiles.api';

export const useSetEmergencyContact = (
  setCurrentId: Dispatch<SetStateAction<number>>,
  profileId: number,
) => {
  const dispatch = useAppDispatch();
  const [setContact, { data, isSuccess, isError, isLoading }] =
    useSetEmergencyContactMutation();

  useEffect(() => {
    if (data?.result.subscriber_is_emergency_contact === true && isSuccess) {
      toast('Emergency contact set successfully!', { type: 'success' });
      setCurrentId(data.result.subscribing_account_id);
      dispatch(
        profilesApi.util.updateQueryData(
          'getLinkedCaregivers',
          { profileId },
          (response) => {
            response.results.forEach((caregiver) => {
              if (caregiver.id === data.result.id) {
                caregiver.subscriber_is_emergency_contact = true;
                return;
              }
              caregiver.subscriber_is_emergency_contact = false;
            });
          },
        ),
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast('An error occured. Please try again.', { type: 'error' });
    }
  }, [isError]);

  return [setContact, isLoading] as const;
};
