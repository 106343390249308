import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const bloodOxygen: IconifyJSON = {
  prefix: 'blood-oxygen',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12.2885 19.5C12.0436 19.5 11.835 19.4137 11.6625 19.2413C11.4901 19.0689 11.4039 18.8602 11.4039 18.6154V14.6923C11.4039 14.4474 11.4901 14.2388 11.6625 14.0663C11.835 13.8939 12.0436 13.8077 12.2885 13.8077H14.7116C14.9564 13.8077 15.1651 13.8939 15.3375 14.0663C15.5099 14.2388 15.5962 14.4474 15.5962 14.6923V18.6154C15.5962 18.8602 15.5099 19.0689 15.3375 19.2413C15.1651 19.4137 14.9564 19.5 14.7116 19.5H12.2885ZM12.5962 18.3077H14.4039V15H12.5962V18.3077ZM17.0962 21.8462V19.2885C17.0962 19.0372 17.1808 18.8269 17.35 18.6577C17.5192 18.4885 17.7295 18.4039 17.9808 18.4039H20.1154V17.3462H17.0962V16.1539H20.4231C20.6744 16.1539 20.8846 16.2385 21.0538 16.4077C21.2231 16.5769 21.3077 16.7872 21.3077 17.0385V18.7115C21.3077 18.9628 21.2231 19.1731 21.0538 19.3423C20.8846 19.5115 20.6744 19.5962 20.4231 19.5962H18.2885V20.6539H21.3077V21.8462H17.0962ZM9.21156 21.4596C7.25131 21.2711 5.64266 20.4577 4.38561 19.0192C3.12856 17.5808 2.50003 15.841 2.50003 13.8C2.50003 12.2487 3.12086 10.5484 4.36253 8.69908C5.6042 6.84971 7.48336 4.83465 10 2.6539C11.9372 4.32183 13.4971 5.90579 14.6798 7.40578C15.8625 8.90576 16.6647 10.309 17.0865 11.6154H11.0192C10.5205 11.6154 10.0946 11.792 9.74136 12.1452C9.38816 12.4984 9.21156 12.9243 9.21156 13.423V21.4596Z" fill="currentColor"/>',
    },
  },
};
