import { Breakpoint, Grid, Skeleton } from '@mui/material';

const AlertCategoryLoader = ({
  breakpoints,
}: {
  breakpoints: Partial<Record<Breakpoint, number>>;
}) => {
  return (
    <div>
      <Skeleton variant="text" width={72} sx={{ mb: 2 }} />
      <Grid container spacing={{ xxs: 2, sm: 3 }}>
        <Grid item {...breakpoints}>
          <Skeleton variant="rounded" height={155} width="100%" />
        </Grid>
        <Grid item {...breakpoints}>
          <Skeleton variant="rounded" height={155} width="100%" />
        </Grid>
        <Grid item {...breakpoints}>
          <Skeleton variant="rounded" height={155} width="100%" />
        </Grid>
      </Grid>
    </div>
  );
};

export default AlertCategoryLoader;
