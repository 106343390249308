/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Tooltip } from '@mui/material';
import {
  Map as StyledMap,
  MapBottomContainer,
  MapTopContainer,
} from './Map.styles';
import { useEffect, useRef, useState } from 'react';
import { LocationStats } from 'types/patient';
import { CenterButton } from './CenterButton';
import {
  getPixelsFromZoomLevelAndRadius,
  handleMapZoomEvent,
} from 'components/Map/Map';
import DrawButton from './DrawButton';
import { useDrawRegion } from '../hooks/use-draw-region';
import { RegionsType, SafeZone } from 'types/safe-zones';
import Overlay, { Coordinate } from './Overlay';
import HintBox from './HintBox';

type Props = {
  height?: number | string;
  width?: number | string;
  disabled?: boolean;
  hideBcControls?: boolean;
  location?: LocationStats;
  dontHighlightLocation?: boolean;
  editedZone: null | SafeZone;
  hideCenterPoint?: boolean;
};

const getNeighborPoints = (index: number, map: mapkit.Map) => {
  const annotations = map.annotations
    .slice(1)
    .filter(({ data }) => data.label !== 'searchPin');

  const total = annotations.length;

  const previousIndex = index === 0 ? total - 1 : index - 1;
  const nextIndex = index === total - 1 ? 0 : index + 1;

  const prev = map.convertCoordinateToPointOnPage(
    annotations[previousIndex].coordinate,
  );

  const next = map.convertCoordinateToPointOnPage(
    annotations[nextIndex].coordinate,
  );

  return [prev, next];
};

const Map = ({ width = '100%', height = '100%', ...props }: Props) => {
  const mapCoordonate = [
    props.location?.lat || 40.7441857,
    props.location?.lng || -74.0319788,
  ];

  const center = new mapkit.Coordinate(mapCoordonate[0], mapCoordonate[1]);
  const span = new mapkit.CoordinateSpan(0.005, 0.005);
  const region = new mapkit.CoordinateRegion(center, span);
  const rootRef = useRef<HTMLDivElement>(null);

  const handlePointMove = (
    coordinate: mapkit.Coordinate | null,
    index?: number,
  ) => {
    if (!map || !coordinate) {
      setLines([]);
      setNewCircle(null);
      return;
    }

    const pageCoordinate = map.convertCoordinateToPointOnPage(coordinate);

    if (shapeRef.current === RegionsType.CIRCLE_TYPE) {
      setNewCircle(
        pageCoordinate
          ? {
              center: pageCoordinate,
              radius: getPixelsFromZoomLevelAndRadius(
                (map as any)._impl.zoomLevel,
                radiusRef.current,
              ),
            }
          : null,
      );
      return;
    }

    if (map.annotations.length > 1 && typeof index === 'number') {
      if (shapeRef.current === RegionsType.POLYGON_TYPE && map.overlays[2]) {
        map.removeOverlay(map.overlays[2]);
      }

      const points = map.annotations
        .filter(({ data }) => data.label !== 'searchPin')
        .slice(1);
      const [prev, next] = getNeighborPoints(index, map);

      const newLines: [Coordinate, Coordinate][] = [
        [pageCoordinate, prev],
        [pageCoordinate, next],
      ];

      if (shapeRef.current === RegionsType.PATH_TYPE && index === 0) {
        newLines.splice(0, 1);
      }

      if (
        shapeRef.current === RegionsType.PATH_TYPE &&
        index === points.length - 1
      ) {
        newLines.splice(1, 1);
      }

      setLines(newLines);
    }
  };

  const {
    map,
    selectedShape,
    setSelectedShape,
    handleClearDrawing,
    offset,
    radiusRef,
    shapeRef,
    isSelfIntersecting,
    isClearButtonActive,
    handleMapClick,
    handleCurrentRegionZoom,
  } = useDrawRegion(region, rootRef, props.editedZone, handlePointMove);

  const [isZoomEventSet, setZoomEventSet] = useState(false);
  const [lines, setLines] = useState<[Coordinate, Coordinate][]>([]);
  const [newCircle, setNewCircle] = useState<{
    center: Coordinate;
    radius: number;
  } | null>(null);

  const getProfileCircle = () => {
    const largeCircleStyle = new (mapkit as any).Style({
      strokeOpacity: 0.22,
      fillColor: 'red',
      strokeColor: 'red',
    });

    const coordinate = new mapkit.Coordinate(
      props.location?.lat || 0,
      props.location?.lng || 0,
    );

    addAnotation(coordinate);

    const largeRadius = props.location?.accuracy || 0;

    const largeCircle = new mapkit.CircleOverlay(coordinate, largeRadius);

    largeCircle.style = largeCircleStyle;

    return largeCircle;
  };

  const addAnotation = (coordinate: mapkit.Coordinate) => {
    const factory = (
      _coordinate: mapkit.Coordinate,
      options: mapkit.AnnotationConstructorOptions,
    ) => {
      const div = document.createElement('div');

      div.textContent = options.title || null;
      div.className = 'circleAnnotation';
      div.addEventListener('click', handleMapClick);

      return div;
    };

    const options = {
      calloutEnabled: false,
    };

    const annotation = new mapkit.Annotation(coordinate, factory, {
      ...options,
      visible: !props.hideCenterPoint,
    });

    if (map) {
      map.addAnnotation(annotation);
    }
  };

  const handleCenterProfileButtonClick = () => {
    if (map) {
      map.setRegionAnimated(region, true);
    }
  };

  useEffect(() => {
    if (map) {
      map.overlays = [];
      map.annotations = [];
      map.setRegionAnimated(region, true);
      map.addOverlay(getProfileCircle());

      if (!isZoomEventSet) {
        map.addEventListener('zoom-end', (e) => handleMapZoomEvent(e));

        setZoomEventSet(true);
      }
    }

    return function cleanup() {
      map?.removeEventListener('zoom-end', (_t, e) => handleMapZoomEvent(e));
      setZoomEventSet(false);
    };
  }, [props.location, map === null]);

  useEffect(() => {
    if (map && props.location && !props.editedZone) {
      map.setRegionAnimated(region, true);
    }
  }, [props.location]);

  return (
    <Box
      position="relative"
      overflow="hidden"
      ref={rootRef}
      sx={{
        height: {
          xxs: '100%',
          newLg: height,
        },
      }}
    >
      <StyledMap width={width} height="100%" id="map" disabled={props.disabled}>
        {!props.hideBcControls && (
          <>
            <MapTopContainer>
              <HintBox selectedType={selectedShape} />
            </MapTopContainer>
            <MapBottomContainer>
              <DrawButton
                selectedShape={selectedShape}
                setSelectedShape={setSelectedShape}
                showClear={isClearButtonActive}
                handleClearDrawing={handleClearDrawing}
              />
              <Tooltip
                title={`Center on ${isClearButtonActive ? 'zone' : 'patient'}`}
                placement="top"
              >
                <CenterButton
                  onClick={
                    isClearButtonActive
                      ? handleCurrentRegionZoom
                      : handleCenterProfileButtonClick
                  }
                />
              </Tooltip>
            </MapBottomContainer>
          </>
        )}
      </StyledMap>
      <Overlay
        width={map?.element.clientWidth ?? 0}
        height={map?.element.clientHeight ?? 0}
        lines={lines}
        offset={offset}
        newCircle={newCircle}
        error={isSelfIntersecting}
      />
    </Box>
  );
};

export default Map;
