import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export default createDraftSafeSelector(
  (state: RootState) => state.reminder.editedSchedule,
  (editedSchedule) => {
    if (!editedSchedule) {
      return false;
    }

    const oldRule = editedSchedule.originalRule;
    const newRule = editedSchedule.newRule;

    return !(
      oldRule.startTime === newRule.startTime &&
      [...oldRule.days].sort().join('') === [...newRule.days].sort().join('')
    );
  },
);
