import { Box, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import ImageUpload from 'components/_extra/ImageUpload/ImageUpload';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import { useFormContext } from 'react-hook-form';
import theme from 'styles/theme';

const AddImage = ({ readOnly }: { readOnly?: boolean }) => {
  const { setValue, getValues } = useFormContext<SettingsForm>();

  const handleFile = (file: File | null, url?: string) => {
    setValue('messageImage', file ?? undefined, { shouldDirty: true });
    setValue('messageImageUrl', url ?? undefined, { shouldDirty: true });
  };

  return (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(1),
        marginTop: spacing(1.5),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Typography color={theme.palette.primary.main}>Image</Typography>
        <HelpIcon
          message="Upload an image to display on recipients’ Apple Watches  when this alert fires."
          tooltipPlacement="top-start"
          tooltipSx={{
            maxWidth: ({ spacing }) => spacing(29),
          }}
        />
      </Box>
      <ImageUpload
        defaultUrl={getValues('messageImageUrl')}
        onAdd={handleFile}
        readOnly={readOnly}
        onClear={() => {
          setValue('messageImageUrl', '', { shouldDirty: true });
        }}
      />
    </Box>
  );
};

export default AddImage;
