import { battery } from 'assets/iconify/battery';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';

interface Return extends DataCellProps {
  value?: string;
}

export const getBatteryLevelStatus = (
  value: number | undefined,
  isCharging?: boolean,
): Return => {
  if (typeof value !== 'number') {
    return {
      icon: battery.icons.full,
      status: DataStatus.DISABLED,
      value: `No data`,
      label: '',
    };
  }

  if (isCharging) {
    return {
      icon: battery.icons.charging,
      status: DataStatus.POSITIVE,
      value: `${Math.floor(value * 100)}%`,
      label: 'Charging',
    };
  }

  if (value >= 0.3) {
    return {
      icon: battery.icons[value >= 0.95 ? 'full' : 'normal'],
      status: DataStatus.POSITIVE,
      value: `${Math.floor(value * 100)}%`,
      label: value >= 0.95 ? 'Full' : 'Good',
    };
  }

  return {
    icon: battery.icons[value < 0.15 ? 'critical' : 'low'],
    status: DataStatus.NEGATIVE,
    value: `${Math.floor(value * 100)}%`,
    label: value < 0.15 ? 'Charge!' : 'Low',
  };
};
