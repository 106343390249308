import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  autocompleteClasses,
  debounce,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { cancel, locationOn, search } from 'assets/iconify';
import { MapLocation, useMapSearch } from 'hooks/use-map-search';
import { memo, useEffect, useRef, useState } from 'react';
import pxToEm from 'utils/px-to-em';

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.blue.main,
  minWidth: theme.spacing(3),
  height: theme.spacing(3),
}));

const CancelIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.blue.light,
}));

const EndAdornment = ({
  wasEdited,
  onClearClick,
  onSearchClick,
  isLoading,
}: {
  wasEdited?: boolean;
  onClearClick: () => void;
  onSearchClick: () => void;
  isLoading?: boolean;
}) => {
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      {wasEdited && (
        <>
          <IconButton onClick={onClearClick} sx={{ p: 0.5 }}>
            <CancelIcon icon={cancel.icons.normal} />
          </IconButton>
          <Divider
            orientation="vertical"
            sx={({ spacing }) => ({ height: spacing(3) })}
          />
        </>
      )}
      {isLoading ? (
        <Box p={0.75} display="flex" alignItems="center">
          <CircularProgress
            sx={{ color: ({ palette }) => palette.blue.main }}
            size={20}
          />
        </Box>
      ) : (
        <IconButton onClick={onSearchClick} sx={{ p: 0.5 }}>
          <StyledIcon icon={search.icons.normal} />
        </IconButton>
      )}
    </Box>
  );
};

const StyledInput = styled(TextField)(({ theme }) => ({
  '.location-search-input': {
    padding: theme.spacing(0.5) + '!important',
  },
  input: {
    padding: theme.spacing(0),
    height: theme.spacing(3),
  },
}));

const StyledListItem = styled('li')(({ theme }) => ({
  maxWidth: '100%',
  svg: {
    minWidth: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    color: theme.palette.lastGray.main,
  },
}));

const StyledSpan = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: pxToEm(14),
  color: theme.palette.primary.main,
}));

const SearchInput = () => {
  const [hasValue, setHasValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    searchForLocation,
    setLocations,
    handleResultClick,
    locations,
    isLoading,
    searchPin,
  } = useMapSearch();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleEmptySearch = () => {
    if (searchPin) {
      window.mapkit.maps[window.mapkit.maps.length - 1].removeAnnotation(
        searchPin,
      );
    }
    setHasValue(false);
    setIsOpen(false);
    setLocations([]);
  };

  const handleSearch = debounce((value: string) => {
    searchForLocation(value);
    handleOpen();
    setHasValue(value.length > 0);
  }, 300);

  const handleOpen = () => {
    setIsOpen(hasValue);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onResultClick = (location: MapLocation | null) => {
    handleClose();
    handleResultClick(location);
  };

  useEffect(() => {
    if (!hasValue && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [hasValue]);

  return (
    <Autocomplete
      options={locations}
      filterOptions={(options) => options}
      open={isOpen}
      value={null}
      openOnFocus={false}
      onOpen={handleOpen}
      clearOnBlur={false}
      onClose={handleClose}
      noOptionsText="No place suggestion"
      clearIcon={<CancelIcon icon={cancel.icons.normal} />}
      isOptionEqualToValue={(option, value) =>
        option.coordinate.equals(value.coordinate)
      }
      sx={({ spacing }) => ({
        maxWidth: { newLg: spacing(60) },
        flexGrow: 1,
        [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon}`]:
          {
            [`.${inputBaseClasses.root}`]: {
              paddingRight: spacing(0.75),
            },
          },
      })}
      onInputChange={(_, newInputValue) => {
        handleSearch(newInputValue);
      }}
      onChange={(_, v) => onResultClick(v as MapLocation)}
      renderInput={(params) => (
        <StyledInput
          placeholder="Find address (e.g. Washington)"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <EndAdornment
                onClearClick={handleEmptySearch}
                onSearchClick={() => onResultClick(locations[0])}
                wasEdited={hasValue}
                isLoading={isLoading}
              />
            ),
            size: 'small',
            className: 'location-search-input',
          }}
          inputRef={inputRef}
        />
      )}
      ListboxProps={{
        sx: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: hasValue ? 'block' : 'hidden',
        },
        children: isLoading ? 'Loading...' : undefined,
      }}
      renderOption={(props, option) => {
        const value = `${option.coordinate.longitude},${option.coordinate.longitude}`;
        return (
          <StyledListItem key={value} {...props}>
            <Icon icon={locationOn.icons.normal} />
            <StyledSpan>{option.label}</StyledSpan>
          </StyledListItem>
        );
      }}
    />
  );
};

export default memo(SearchInput);
