import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirmation } from 'hooks/use-confirmation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import {
  alertersApi,
  useCreateAlerterFromTemplateMutation,
  useCreateAlerterMutation,
  useGetAvailableTemplatesQuery,
} from 'store/api/alerters.api';
import { AlerterTemplateSummary, SourceStreamType } from 'types/alerters';
import { getRecipientFieldsForAlertCreation } from 'utils/data-transformation';
import * as yup from 'yup';

interface TemplateAlerterForm {
  template: string;
  source: SourceStreamType;
  recipientSet: number | string;
  title: string;
}

const schema = yup.object({
  title: yup.string().required('Title is required.'),
});

const DATA_SOURCES = [
  {
    value: 'any_wearable',
    label: 'Apple Watch',
  },
];

const transformTemplates = (templates: AlerterTemplateSummary[]) =>
  templates.map(({ description, identifier, title }) => ({
    value: identifier,
    label: title,
    description,
  }));

export const useTemplateForm = (
  onClose: () => void,
  nextManagerTitle: string,
) => {
  const [isDirty, setIsDirty] = useState(false);
  const { register, handleSubmit, reset, formState, setValue } =
    useForm<TemplateAlerterForm>({ resolver: yupResolver(schema) });

  const { profileId } = useParams();
  const dispatch = useAppDispatch();
  const confirm = useConfirmation();

  const [createBlankAlerter] = useCreateAlerterMutation();
  const [createAlerter] = useCreateAlerterFromTemplateMutation();
  const { data: availableTemplates, isLoading: isTemplateLoading } =
    useGetAvailableTemplatesQuery();

  const setAsDirty = () => {
    setIsDirty(true);
  };

  const onSubmit = (data: TemplateAlerterForm) => {
    (data.template === 'none' ? createBlankAlerter : createAlerter)({
      title: data.title,
      template: data.template,
      alerterSource: {
        patientProfileId: Number(profileId),
        sourceType: 'PatientProfile',
        streamType: data.source,
      },
      ...getRecipientFieldsForAlertCreation(
        data.recipientSet,
        false,
        nextManagerTitle,
      ),
    })
      .unwrap()
      .then((data) => {
        toast.success('The alert was created successfully!');
        if (data) {
          dispatch(
            alertersApi.util.updateQueryData(
              'getAlerters',
              { patientProfileId: Number(profileId) },
              (result) => {
                result[data.result.display_group || 'uncategorized'] = [
                  ...(result[data.result.display_group || 'uncategorized'] ??
                    []),
                  {
                    id: data.result.id,
                    enabled: data.result.enabled,
                    userRecipientCount: data.result.user_recipients_count,
                    patientsRecipientCount:
                      data.result.patient_profile_recipients_count,
                    group: data.result.display_group,
                    widgetTargets: data.result.generated_widget_targets,
                    schedulerSummary: '',
                    recipientManagers:
                      data.result.alerter_recipient_managers.map((manager) => ({
                        id: manager.id,
                        name: manager.name ?? '',
                        scheduler: {
                          days: [],
                          startTime: 0,
                          endTime: 0,
                        },
                      })),
                    sourceStreamType: data.result.alerter_source.stream_type,
                    triggerSummaryFragments: [],
                    defaultWidgetTargets: data.result.generated_widget_targets,
                    recipientManagerName:
                      data.result.alerter_recipient_manager.name ?? '',
                    title: data.result.title,
                    triggerSummary: '',
                  },
                ];
              },
            ),
          );
        }
      })
      .catch(() => {
        toast('An error occured. Please try again.', { type: 'error' });
      });

    onClose();
    reset();
  };

  const handleCancel = () => {
    if (!isDirty) {
      onClose();
      return;
    }

    confirm(
      {
        title: 'Are you sure you want to cancel creating this alert?',
        message: 'If you cancel now, you will lose all of your progress.',
        rejectButtonLabel: 'Go Back',
        variant: 'danger',
      },
      onClose,
    );
  };

  return {
    templateOptions: availableTemplates?.results
      ? transformTemplates(availableTemplates.results)
      : [],
    defaultTemplate: 'none',
    isLoading: isTemplateLoading,
    sourceOptions: DATA_SOURCES,
    defaultSource: 'any_wearable',
    register,
    handleSubmit: handleSubmit(onSubmit),
    handleCancel,
    setValue,
    setAsDirty,
    titleError: formState.errors.title?.message,
  };
};
