import { General, HelperText } from '../styled';
import {
  Box,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { memo } from 'react';
import TargetWidget from './TargetWidget';
import Frequency from './Frequency';

const GeneralFields = ({
  error,
  readOnly,
}: {
  error?: string;
  readOnly?: boolean;
}) => {
  const { register } = useFormContext();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      py={2}
      px={{ xxs: 0, newLg: 2 }}
    >
      <General>
        <div>
          <Typography color="primary" pb={1.5}>
            Title
          </Typography>
          <OutlinedInput
            size="small"
            sx={{ minWidth: ({ spacing }) => spacing(30) }}
            error={!!error}
            readOnly={readOnly}
            {...register('title')}
          />
          <HelperText error>{error}</HelperText>
        </div>
        <div>
          <Typography color="primary" pb={1.5}>
            Data Source
          </Typography>
          <Select
            readOnly
            size="medium"
            sx={{ minWidth: ({ spacing }) => spacing(30) }}
            defaultValue="apple-watch"
          >
            <MenuItem value="apple-watch">Apple Watch</MenuItem>
          </Select>
        </div>
      </General>
      <TargetWidget readOnly={readOnly} />
      <Frequency readOnly={readOnly} />
    </Box>
  );
};

export default memo(GeneralFields);
