import { Icon } from '@iconify/react';
import { Box, Checkbox, Collapse, Typography, styled } from '@mui/material';
import { arrowDropdown } from 'assets/iconify';
import { ChangeEvent, useState } from 'react';
import { AlerterEvent } from 'types/alerters';
import { formatMetricValue, getTriggerGroup } from 'utils/triggers';

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  fill: theme.palette.primary.main,
  cursor: 'pointer',
  transform: 'rotate(-90deg)',
  transition: `all ${theme.transitions.duration.shortest}`,
  '&.expanded': {
    transform: 'rotate(0)',
  },
}));

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

type Props = {
  alertEvent: AlerterEvent;
  onChange?: (id: number, isChecked: boolean) => void;
};

const AlertEventItem = ({ alertEvent, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(alertEvent.id, e.target.checked);
    }
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        <StyledIcon
          icon={arrowDropdown.icons.normal}
          className={isOpen ? 'expanded' : ''}
          onClick={() => setIsOpen((open) => !open)}
        />
        <Checkbox
          color="error"
          defaultChecked
          onChange={handleCheckboxChange}
        />
        <Typography fontWeight={600} color="red.main">
          {alertEvent.title}
        </Typography>
      </Box>
      <Collapse in={isOpen}>
        <Box
          component="ul"
          pl={7.5}
          sx={{
            color: ({ palette }) => palette.blue.main,
            li: {
              listStyle: 'disc',
            },
          }}
        >
          {alertEvent.metricRulesTriggers.map((metricRuleTrigger) => {
            const { value, unit } = formatMetricValue(
              metricRuleTrigger.value,
              metricRuleTrigger.metricType,
              metricRuleTrigger.comparator,
            );
            return (
              <li key={metricRuleTrigger.metricType + alertEvent.id}>
                <Typography textTransform="capitalize" color="blue.main">
                  {getTriggerGroup(metricRuleTrigger.metricType)} /{' '}
                  <StyledSpan>
                    <strong>
                      {metricRuleTrigger.metricType.replace(/_/g, ' ')}: {value}
                    </strong>
                    {' '}
                    {unit}
                  </StyledSpan>
                </Typography>
              </li>
            );
          })}
        </Box>
      </Collapse>
    </div>
  );
};

export default AlertEventItem;
