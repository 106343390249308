import { useForm, SubmitErrorHandler, FieldErrorsImpl } from 'react-hook-form';
import { useLinkLovedOneMutation } from 'store/api/account.api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import {
  hasNameSelector,
  patientsOverviewFiltersSelector,
} from 'store/selectors';
import { useUpdateProfileMutation } from 'store/api/profiles.api';
import { useLazyFetchDataQuery } from 'store/api/pop-ov.api';
import { useColumns } from './use-columns';

interface FormData {
  code: string;
  firstName?: string;
}

const addUserSchema = yup.object({
  code: yup
    .string()
    .required('Invitation code is required.')
    .min(6, 'Invitation code must be 6 letters long.')
    .max(6, 'Invitation code must be 6 letters long.')
    .matches(/^[a-zA-Z]+$/, 'Invitation code must contain only letters.'),
});

export const useAddProfile = (onClose: () => void) => {
  const [updateProfile, { isLoading: isProfileLoading }] =
    useUpdateProfileMutation();
  const [addProfile, { isSuccess, isLoading, isError: requestError, reset }] =
    useLinkLovedOneMutation();
  const [fetchPatientsOverview] = useLazyFetchDataQuery();

  const { register, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(addUserSchema),
  });

  const { requestFields } = useColumns();

  const [errors, setErrors] = useState<FieldErrorsImpl<FormData>>({});

  const { limit, page } = useAppSelector((state) => state.filters.pagination);
  const accountId = useAppSelector((state) => state.user.accountId);
  const filters = useAppSelector(patientsOverviewFiltersSelector);
  const hasName = useAppSelector(hasNameSelector);

  const onSubmit = ({ code, firstName }: FormData) => {
    if (!hasName) {
      if (firstName && firstName?.length === 0) {
        onError({
          firstName: {
            message: 'First name is required!',
            type: 'required',
          },
        });
        return;
      }
    }

    addProfile({ code });
    if (firstName && accountId) {
      updateProfile({ id: accountId, first_name: firstName });
    }
  };

  const onError: SubmitErrorHandler<FormData> = (errors) => {
    setErrors(errors);
  };

  useEffect(() => {
    if (requestError) {
      onError({
        code: {
          type: 'request',
          message: 'Please check if you typed your code correctly.',
        },
      });
    }
  }, [requestError]);

  const onCompletion = () => {
    onClose();
    reset();
    fetchPatientsOverview({
      filters,
      limit,
      offset: limit * page,
      fields: requestFields,
    });
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit, onError),
    isLoading: isLoading || isProfileLoading,
    isSuccess,
    errors,
    hasName,
    onCompletion,
  };
};
