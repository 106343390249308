import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { AlerterEvent } from 'types/alerters';
import { commonColumnProperties } from './records-columns/columns';
import dayjs from 'dayjs';

export const ALERT_HISTORY_COLUMNS: GridColDef<AlerterEvent>[] = [
  {
    ...commonColumnProperties,
    field: 'triggeredAt',
    headerName: 'Timestamp',
    minWidth: 180,
    renderCell: ({ row }) => (
      <Typography variant="body1" color="primary">
        {dayjs(row.triggeredAt ?? row.createdAt).format('MMM D [a]t hh:mm:ssa')}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'alerter_title',
    headerName: 'Title',
    minWidth: 300,
    renderCell: ({ row }) => {
      return <Typography variant="body1">{row.title}</Typography>;
    },
  },
  {
    ...commonColumnProperties,
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Typography
        variant="body1"
        color={row.acknowledgedAt ? 'secondary' : 'error'}
      >
        {row.acknowledgedAt ? 'Acknowledged' : 'Alerted'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'acknowledgedAt',
    headerName: 'Acknowledged time',
    minWidth: 200,
    renderCell: ({ row }) => (
      <Typography
        variant="body1"
        color={row.acknowledgedAt ? 'secondary' : 'primary'}
      >
        {row.acknowledgedAt
          ? dayjs(row.acknowledgedAt).format('MMM d [a]t hh:mm:ssa')
          : '-'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'acknowledgedBy',
    headerName: 'Acknowledged by',
    minWidth: 200,
    renderCell: ({ row }) => (
      <Typography
        variant="body1"
        color={row.acknowledgedAt ? undefined : 'primary'}
      >
        {row.acknowledgingAlerterRecipientName ?? '-'}
      </Typography>
    ),
  },
];
