import { useState } from 'react';
import {
  alerterHistoryFilterCriteria,
  getAlertIds,
  getDateRangeEnd,
  getDateRangeStart,
  getWidgets,
} from 'services/alert-history-filters';
import { useAppSelector } from 'store';
import { getAlertsHistoryCsv } from 'utils/get-alerts-history-csv';

export const useCsvDownload = (alertId?: string) => {
  const { profileId, patientName } = useAppSelector((state) => state.dashboard);
  const { token } = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const beginsAt = getDateRangeStart()?.toISOString();
  const endsAt = getDateRangeEnd()?.toISOString();

  const handleDownload = async () => {
    setIsLoading(true);
    const ids = alertId
      ? { alerterIds: [alertId] }
      : alerterHistoryFilterCriteria.value === 'group' &&
        getAlertIds()?.length > 0
      ? { alerterIds: getAlertIds() }
      : { widgets: getWidgets() };

    const data = await getAlertsHistoryCsv({
      profileId,
      beginsAt,
      endsAt,
      ...ids,
      token,
    });
    setIsLoading(false);

    downloadFile(data);
  };

  const downloadFile = (data?: Blob) => {
    if (data) {
      const hiddenElement = document.createElement('a');
      const url = window.URL || window.webkitURL;
      const blobCsv = url.createObjectURL(data);
      hiddenElement.href = blobCsv;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${patientName?.replace(' ', '_') ?? profileId}${
        beginsAt && endsAt
          ? `_${beginsAt.slice(0, 10)}_${endsAt.slice(0, 10)}`
          : ''
      }_alert-history.csv`;
      hiddenElement.click();
      hiddenElement.remove();
    }
  };

  return {
    handleDownload,
    disabled: isLoading,
  };
};
