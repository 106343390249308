import { PropsWithChildren } from 'react';
import AuthLayoutView from './AuthLayout.view';

const AuthLayout = ({ children }: PropsWithChildren) => {
  return <AuthLayoutView>{children}</AuthLayoutView>;
};

AuthLayout.displayName = 'AuthLayout';

export default AuthLayout;
