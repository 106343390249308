import { Icon } from '@iconify/react';
import {
  alpha,
  Box,
  Chip as MuiChip,
  chipClasses,
  ChipProps,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FilterItemChip, FilterItemChipColor } from '../Filters.types';
import * as icons from 'assets/iconify';
import { FiltersAppleWatchKeys } from 'store/reducers/filters/types';

const Chip = styled(
  (
    props: ChipProps & {
      color?: FilterItemChipColor;
      isActive?: boolean;
    },
  ) => <MuiChip {...props} />,
  { shouldForwardProp: (prop) => prop !== 'isActive' },
)(({ theme, color, isActive }) => ({
  backgroundColor: isActive
    ? alpha(theme.palette[color || 'primary'].main, 0.8)
    : theme.palette.gray.light,
  color: !isActive
    ? theme.palette[color || 'primary'].main
    : theme.palette.common.white,
  marginTop: theme.spacing(),
  marginRight: theme.spacing(),
  borderRadius: theme.spacing(),
  userSelect: 'none',
  fontWeight: 400,
  fontSize: theme.spacing(1.75),

  [`&.${chipClasses.clickable}:hover`]: {
    backgroundColor: isActive
      ? alpha(theme.palette[color || 'primary'].main, 0.7)
      : alpha(theme.palette.gray.light, 0.7),
  },
}));

export interface FilterItemProps {
  key: FiltersAppleWatchKeys;
  isInvisible?: boolean;
  onVisibilityToggle?: React.MouseEventHandler<HTMLDivElement>;
  name?: string;
  chips?: FilterItemChip[];
  activeChips?: string[];
  withoutChips?: boolean;
  withoutBorder?: boolean;
  exclusiveFor?: 'history' | 'profile-overview';
  onChipToggle?: (value: string) => void;
}

const FilterItem = ({
  isInvisible,
  onVisibilityToggle,
  name = 'Filter Item',
  chips = [],
  activeChips = [],
  onChipToggle,
  withoutChips,
  withoutBorder,
}: FilterItemProps) => {
  const theme = useTheme();

  return (
    <Box padding={1}>
      <Box
        display="flex"
        alignItems="center"
        color={theme.palette.primary.main}
        pr={withoutChips ? 2 : 0}
        onClick={onVisibilityToggle}
        width="fit-content"
        borderRight={
          withoutChips
            ? `${theme.spacing(0.125)} solid ${theme.palette.divider}`
            : undefined
        }
        sx={{ cursor: 'pointer' }}
      >
        <Tooltip title="Show/hide column" placement="top">
          <IconButton
            size="small"
            sx={{ mr: 1, p: 0, ':hover': { backgroundColor: 'transparent' } }}
          >
            <Icon
              icon={icons.visibility.icons[isInvisible ? 'hidden' : 'shown']}
              color={theme.palette.primary.main}
              width={theme.spacing(3)}
              height={theme.spacing(3)}
            />
          </IconButton>
        </Tooltip>
        <Typography
          variant="body2"
          sx={{ userSelect: 'none', fontWeight: 500 }}
        >
          {name}
        </Typography>
      </Box>
      {!withoutChips && (
        <Box
          paddingRight={1}
          borderRight={
            withoutBorder
              ? undefined
              : `${theme.spacing(0.125)} solid ${theme.palette.divider}`
          }
        >
          {chips.map((item) => (
            <Chip
              key={item.value}
              label={item.displayName}
              color={item.color}
              isActive={activeChips.includes(item.value)}
              onClick={() => onChipToggle?.(item.value)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FilterItem;
