import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useAppDispatch } from 'store';
import { bcApi } from 'store/api/_api';
import {
  useCreateCredentialMutation,
  useDeleteCredentialMutation,
  useRequestConfirmationMutation,
} from 'store/api/auth.api';

const LOCAL_STORAGE_CODE_KEY = 'lastPhoneCodeSentAt';

export const usePhoneNumberSetup = (
  oldPhoneNumber?: string,
  oldCredentialId?: number,
) => {
  const dispatch = useAppDispatch();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState<string>('');
  const phoneNumberRef = useRef<string>(oldPhoneNumber || '');
  const latestPhoneNumber = useRef<string>(oldPhoneNumber || '');
  const [resendSecondsLeft, setResendSecondsLeft] = useState(0);
  const [canSend, setCanSend] = useState<boolean>(true);
  const [wasNumberConfirmed, setWasNumberConfirmed] = useState<boolean>(false);

  const [wasConfirmationSent, setWasConfirmationSent] =
    useState<boolean>(false);

  const [
    createCredential,
    { isLoading: isCreateLoading, data, isError, isSuccess, originalArgs },
  ] = useCreateCredentialMutation();

  const [
    requestConfirmation,
    {
      isLoading: isDeliverLoading,
      isSuccess: wasCodeSent,
      isError: couldNotSendCode,
    },
  ] = useRequestConfirmationMutation();

  const [deleteOldCredential] = useDeleteCredentialMutation();

  const handleChange = (value: string) => {
    phoneNumberRef.current = value;
    setCanSend(!resendSecondsLeft && value !== latestPhoneNumber.current);
    setIsSubmitDisabled(
      !value ||
        latestPhoneNumber.current.replaceAll(' ', '').trim() ===
          value.replaceAll(' ', '').trim(),
    );
  };

  const handleCreateCredential = () => {
    if (!isValidPhoneNumber(phoneNumberRef.current)) {
      setError('This phone number is not valid. Please use a correct format.');
      return;
    }

    setError('');

    createCredential({
      schema: 'phone',
      identifier: phoneNumberRef.current,
    });

    latestPhoneNumber.current = phoneNumberRef.current;
  };

  const backToNumberEditing = () => {
    setWasConfirmationSent(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (resendSecondsLeft > 0) {
        setResendSecondsLeft((prev) => prev - 1);
        return;
      }
      setCanSend(true);
    }, 1000);
  }, [resendSecondsLeft]);

  useEffect(() => {
    if (isError && originalArgs?.identifier === phoneNumberRef.current) {
      setError(
        'This phone number is already in use. Please use a different one.',
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setCanSend(false);
      if (data?.result.identifier) {
        setTimeout(() => {
          requestConfirmation({
            schema: 'phone',
            identifier: data.result.identifier,
          });
          localStorage.setItem(LOCAL_STORAGE_CODE_KEY, data.result.identifier);
        }, 1000);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (wasCodeSent) {
      setWasConfirmationSent(true);
      setResendSecondsLeft(60);
    }
  }, [wasCodeSent]);

  useEffect(() => {
    const lastCodeSentAt = localStorage.getItem(LOCAL_STORAGE_CODE_KEY);
    if (lastCodeSentAt && dayjs().diff(dayjs(lastCodeSentAt), 'minutes') > 14) {
      setWasConfirmationSent(true);
    }
  }, []);

  const handleNumberConfirmation = () => {
    setWasNumberConfirmed(true);
    if (oldPhoneNumber && oldCredentialId) {
      deleteOldCredential({
        id: oldCredentialId,
      });
    }
  };

  const onConfirmationButtonClick = () => {
    dispatch(bcApi.util.invalidateTags(['ProfileData']));
  };

  return {
    isLoading: isCreateLoading || isDeliverLoading,
    isSubmitDisabled,
    handleChange,
    handleSubmit: handleCreateCredential,
    error,
    wasConfirmationSent,
    phoneNumberRef,
    backToNumberEditing,
    resendSecondsLeft,
    canSend,
    credentialId: data?.result.id,
    setResendSecondsLeft,
    wasNumberConfirmed,
    handleNumberConfirmation,
    couldNotSendCode,
    onConfirmationButtonClick,
  };
};
