import { useConfirmation } from 'hooks/use-confirmation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import {
  safeZonesApi,
  useDeleteRegionMutation,
} from 'store/api/safe-zones.api';
import { SafeZone, mappedFormattedRegions } from 'types/safe-zones';

export const useSafeZoneCard = (zone: SafeZone) => {
  const { profileId } = useParams<{ profileId: string }>();
  const { label } = zone;
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const [
    deleteZone,
    { isLoading: isDeleting, isSuccess: wasDeleted, isError: isDeleteError },
  ] = useDeleteRegionMutation();

  const onDelete = () => {
    deleteZone(zone.id);
  };
  useEffect(() => {
    if (wasDeleted && !isDeleting) {
      toast.success('Safe zone was deleted successfully');
      dispatch(
        safeZonesApi.util.updateQueryData(
          'getRegions',
          { id: Number(profileId) },
          (draft) => {
            draft[mappedFormattedRegions[zone.shapeType]] = draft[
              mappedFormattedRegions[zone.shapeType]
            ].filter((item) => item.id !== zone.id);
          },
        ),
      );
    }
  }, [wasDeleted]);

  useEffect(() => {
    if (isDeleteError && !isDeleting) {
      toast.error('Failed to delete safe zone');
    }
  }, [isDeleteError]);

  const handleDelete = () => {
    confirm(
      {
        title: `Are you sure you want to delete “${label}” from the safe zone list?`,
        message:
          'This safe zone will be deleted immediately. You can’t undo this action.',
        rejectButtonLabel: 'Cancel',
        confirmButtonLabel: 'Delete',
        variant: 'danger',
      },
      onDelete,
    );
  };

  return {
    handleDelete,
  };
};
