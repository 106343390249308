import { Icon } from '@iconify/react';
import { useTheme } from '@mui/material';
import { errorFilled } from 'assets/iconify';
export interface StatusIconProps {
  status: 'error' | 'success' | undefined;
  size?: string | number;
}

const StatusIcon: React.FC<StatusIconProps> = ({ status, size = '24' }) => {
  const { palette } = useTheme();

  if (status === undefined) {
    return null;
  }

  return (
    <Icon
      icon={
        status === 'success'
          ? 'material-symbols:check-circle'
          : errorFilled.icons.normal
      }
      height={size}
      width={size}
      color={
        status === 'success' ? palette.secondary.main : palette.grapeFruit.main
      }
    />
  );
};

export default StatusIcon;
