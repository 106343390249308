import { useRef } from 'react';
import { GetTriggerFormRef } from './use-add-trigger';

export const useEditTrigger = () => {
  const functionsRef = useRef<GetTriggerFormRef>(null);

  return {
    functionsRef,
  };
};
