import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';
import { helpRequested } from 'assets/iconify';

export const getHelpRequestStatus = (
  requested: boolean | undefined,
): DataCellProps => {
  if (requested === true) {
    return {
      icon: helpRequested.icons.alerted,
      status: DataStatus.NEGATIVE,
      label: 'Requested',
    };
  }

  return {
    icon: helpRequested.icons.normal,
    status: DataStatus.POSITIVE,
    label: 'None',
  };
};
