import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box, Button } from '@mui/material';
import VInput from 'components/VInput';
import { useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUpdateProfileMutation } from 'store/api/profiles.api';
import * as yup from 'yup';

interface Form {
  firstName: string;
  lastName?: string;
}

const formSchema = yup.object({
  firstName: yup.string().required('The first name is required.'),
  lastName: yup.string(),
});

interface Props {
  accountId: number;
  onSuccess?: () => void;
}

const AccountNameSet = ({ accountId, onSuccess }: Props) => {
  const [errors, setErrors] = useState<FieldErrorsImpl<Form>>({});

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const { register, handleSubmit } = useForm<Form>({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Form> = (formData) => {
    updateProfile({
      id: accountId,
      first_name: formData.firstName,
      last_name: formData.lastName,
    }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        toast('A server error has occured. Please try again.', {
          type: 'error',
        });
        return;
      }

      onSuccess?.();
    });
  };

  const onError: SubmitErrorHandler<Form> = (errors) => {
    setErrors(errors);
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Add a caregiver
      </Typography>
      <Typography
        variant="body1"
        sx={{ px: 3, pb: 4, color: ({ palette }) => palette.blue.main }}
        align="center"
      >
        To be able to send invitation to another caregiver please provide at
        least one of the following data.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <VInput
          size="large"
          label="First Name"
          placeholder="Enter the first name"
          disabled={isLoading}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          blueHelper={errors.firstName?.type === 'request'}
          {...register('firstName')}
        />
        <VInput
          size="large"
          label="Last Name"
          placeholder="Enter the last name"
          disabled={isLoading}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          blueHelper={errors.lastName?.type === 'request'}
          {...register('lastName')}
        />
        <Box sx={{ m: 1, mt: 3 }}>
          <Button type="submit" size="large" fullWidth disabled={isLoading}>
            Submit
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AccountNameSet;
