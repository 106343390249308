import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const notificationActive: IconifyJSON = {
  prefix: 'notification-active',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M2.61542 9.91345C2.61542 8.43912 2.92856 7.07823 3.55484 5.8308C4.18111 4.58337 5.02628 3.53017 6.09037 2.6712L7.01537 3.83848C6.12435 4.55899 5.41827 5.44233 4.89712 6.48848C4.37597 7.53463 4.11539 8.67628 4.11539 9.91345H2.61542ZM19.8846 9.91345C19.8846 8.67628 19.624 7.53302 19.1029 6.48367C18.5817 5.43431 17.8756 4.54938 16.9846 3.82888L17.9096 2.6712C18.9737 3.53017 19.8189 4.58337 20.4451 5.8308C21.0714 7.07823 21.3846 8.43912 21.3846 9.91345H19.8846ZM4.50002 18.8846V17.3847H6.30772V9.92307C6.30772 8.57821 6.72278 7.38911 7.55289 6.35578C8.38303 5.32244 9.44873 4.66155 10.75 4.3731V3.75002C10.75 3.40387 10.8718 3.10901 11.1154 2.86543C11.359 2.62183 11.6538 2.50002 12 2.50002C12.3461 2.50002 12.641 2.62183 12.8846 2.86543C13.1282 3.10901 13.25 3.40387 13.25 3.75002V4.3731C14.5513 4.66155 15.617 5.32244 16.4471 6.35578C17.2772 7.38911 17.6923 8.57821 17.6923 9.92307V17.3847H19.5V18.8846H4.50002ZM12 21.6923C11.5013 21.6923 11.0753 21.5157 10.7221 21.1625C10.3689 20.8093 10.1923 20.3833 10.1923 19.8846H13.8077C13.8077 20.3833 13.6311 20.8093 13.2779 21.1625C12.9247 21.5157 12.4987 21.6923 12 21.6923ZM7.80767 17.3847H16.1923V9.92307C16.1923 8.76537 15.783 7.77723 14.9644 6.95865C14.1458 6.14005 13.1577 5.73075 12 5.73075C10.8423 5.73075 9.85415 6.14005 9.03557 6.95865C8.21697 7.77723 7.80767 8.76537 7.80767 9.92307V17.3847Z" fill="currentColor"/>',
    },
  },
};
