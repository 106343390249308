import { Typography, Box } from '@mui/material';
import { LoginForm } from './components';
import TitleLogo from 'components/TitleLogo';
import { useEffect } from 'react';
import { BCLocalStorage } from '../../utils/storage';

const Login: React.FC = () => {
  useEffect(() => {
    BCLocalStorage.hipaa = false;
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="center" pb={4} px={1} gap={1}>
        <Typography color="primary" variant="h3">
          Login
        </Typography>
        <TitleLogo />
      </Box>
      <LoginForm />
    </>
  );
};

Login.displayName = 'Login';

export default Login;
