import { styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  min-width: 256px;
  max-width: 512px;
  width: auto;

  .Toastify__toast {
    font-family: 'Euclid Flex', 'euclid';
    font-size: 18px;
  }
`;
