import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 48, height: 48 };

export const cloudUpload: IconifyJSON = {
  prefix: 'cloud-upload',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M22.5 38.9999H13C10.2359 38.9999 7.8782 38.0397 5.9269 36.1192C3.97563 34.1987 3 31.8564 3 29.0923C3 26.6461 3.78333 24.4705 5.35 22.5654C6.91667 20.6603 8.91538 19.5333 11.3462 19.1846C11.9872 16.1949 13.4904 13.75 15.8558 11.85C18.2211 9.95 20.9359 9 24 9C27.6179 9 30.689 10.2622 33.2134 12.7866C35.7377 15.3109 36.9999 18.382 36.9999 22V23H37.6153C39.723 23.1641 41.4807 24.0115 42.8884 25.5423C44.2961 27.0731 44.9999 28.8923 44.9999 31C44.9999 33.2307 44.2243 35.1217 42.673 36.673C41.1217 38.2243 39.2307 38.9999 37 38.9999H25.4999V24.4307L29.2 28.0692L31.3076 25.9807L24 18.6731L16.6923 25.9807L18.8 28.0692L22.5 24.4307V38.9999Z" fill="#A3B2C6"/>',
    },
  },
};
