import { useEffect, useState } from 'react';
import {
  useAcknowledgeAlerterEventMutation,
  useGetAlerterEventsByWidgetsQuery,
} from 'store/api/alerters.api';
import { AlerterEvent } from 'types/alerters';
import { getUnacknowledgedEvents } from 'utils/get-unacknowledged-events';

export const useIssuesAcknowledge = (id: number) => {
  const { data: alertEvents } = useGetAlerterEventsByWidgetsQuery({
    profileId: id,
  });

  const [hasIssues, setHasIssues] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);

  const [unacknowledgedAlerts, setUnacknowledgedAlerts] = useState<
    AlerterEvent[]
  >([]);

  const [acknowledgeEvents, { isLoading, isSuccess }] =
    useAcknowledgeAlerterEventMutation();

  useEffect(() => {
    if (alertEvents) {
      const unacknowledgedAlerts = getUnacknowledgedEvents(alertEvents.results);
      if (unacknowledgedAlerts.length > 0) {
        setHasIssues(true);
        setUnacknowledgedAlerts(unacknowledgedAlerts);
        return;
      }
      setHasIssues(false);
    }
  }, [alertEvents]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setConfirmed(true);
    }
  }, [isSuccess]);

  const acknowledge = (ids: number[]) => {
    acknowledgeEvents({
      ids,
    });
  };

  return {
    acknowledge,
    hasIssues,
    isConfirmed,
    setConfirmed,
    isLoading,
    unacknowledgedAlerts,
  };
};
