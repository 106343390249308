import { GridEventListener } from '@mui/x-data-grid-pro';
import { usePagination } from 'hooks/use-pagination';
import { useWindowFocus } from 'hooks/use-window-focus';
import { routes } from 'nav';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { useLazyGetDashboardDataQuery } from 'store/api/dashboard.api';
import { useFetchDataQuery } from 'store/api/pop-ov.api';
import { updateSelectedUser } from 'store/reducers/dashboard/dashboard.slice';
import { patientsOverviewFiltersSelector } from 'store/selectors';

export interface UsePatientsOverviewTableArgs {
  visibleColumns?: string[];
  requestFields: string[];
}

export const usePatientsOverviewTable = ({
  requestFields,
}: UsePatientsOverviewTableArgs) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(patientsOverviewFiltersSelector);
  const profileId = useAppSelector((state) => state.dashboard.profileId);
  const navigate = useNavigate();
  const [getData] = useLazyGetDashboardDataQuery();

  const isFocused = useWindowFocus();

  const {
    limit,
    offset,
    handlePaginationModelChange,
    pageIndex: page,
  } = usePagination(25, 0);

  const {
    data = { rows: [], totalNumber: 0, displayedNumber: 0 },
    isFetching,
    isLoading,
    refetch,
    isUninitialized,
  } = useFetchDataQuery(
    {
      fields: requestFields,
      filters,
      limit,
      offset,
    },
    {
      pollingInterval: isFocused ? 60000 : undefined,
      refetchOnFocus: true,
    },
  );

  const handleRefetch = useCallback(() => {
    if (isUninitialized) return;
    refetch();
  }, [refetch, isUninitialized]);

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    const selected = data.rows.find((profile) => profile.id === id);

    if (selected) {
      dispatch(
        updateSelectedUser({
          accountId: selected.accountId,
          name: selected.name,
          deviceId: selected.deviceId,
        }),
      );
    }

    navigate(routes.private.dashboard.href(id));
  };

  useEffect(() => {
    if (data.rows.length !== 0 && !profileId) {
      getData({ account_id: data.rows[0].accountId });
      dispatch(
        updateSelectedUser({
          accountId: data.rows[0].accountId,
          name: data.rows[0].name,
          deviceId: data.rows[0].deviceId,
        }),
      );
    }
  }, [data.rows.length === 0]);

  useEffect(() => {
    window.addEventListener('notification', handleRefetch);
    return () => {
      window.removeEventListener('notification', handleRefetch);
    };
  }, [handleRefetch]);

  return {
    ...data,
    isLoading: isFetching || isLoading,
    page,
    rowsPerPage: limit,
    handlePaginationModelChange,
    handleRowClick,
  };
};
