import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useLazyGetProfileQuery } from 'store/api/profiles.api';
import { setName } from 'store/reducers/user/user.slice';

export const useAddProfileModal = () => {
  const dispatch = useAppDispatch();
  const accoundId = useAppSelector((state) => state.user.accountId);

  const [open, setOpen] = useState(false);
  const [getProfile, { data, isLoading }] = useLazyGetProfileQuery();

  const handleClick = () => {
    if (accoundId) {
      getProfile({ id: accoundId });
    }

    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      dispatch(
        setName({
          firstName: data.result.first_name,
          lastName: data.result.last_name,
        }),
      );
    }
  }, [data]);

  return {
    open,
    handleClick,
    handleModalClose,
    isLoading,
  };
};
