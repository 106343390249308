import { alpha, styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import { palette } from 'styles/palette';

const Canvas = styled('canvas')(() => ({
  position: 'absolute',
  top: 0,
  pointerEvents: 'none',
  left: 0,
}));

export type Coordinate = {
  x: number;
  y: number;
};

const Overlay = ({
  lines,
  offset,
  width,
  height,
  newCircle,
  error,
}: {
  lines: [Coordinate, Coordinate][];
  offset: Coordinate;
  width: number;
  height: number;
  newCircle: { center: Coordinate; radius: number } | null;
  error?: boolean;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (context === null) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = alpha(
      error ? palette.error.main : palette.primary.main,
      error ? 0.95 : 0.7,
    );
    context.setLineDash([5, 10]);
    context.lineWidth = 3;

    lines.forEach((line) => {
      const [start, end] = line;
      context.beginPath();
      context.moveTo(start.x - offset.x, start.y - offset.y);
      context.lineTo(end.x - offset.x, end.y - offset.y);
      context.stroke();
    });
  }, [lines]);

  useEffect(() => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (context === null) return;

    context.clearRect(0, 0, canvas.width, canvas.height);

    if (newCircle) {
      const radius = newCircle.radius / 2;
      const centerX = newCircle.center.x - offset.x - (radius / 100) * 6;
      const centerY = newCircle.center.y - offset.y - (radius / 100) * 6;

      context.beginPath();
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      context.fillStyle = alpha(
        error ? palette.error.main : palette.primary.main,
        0.18,
      );
      context.fill();

      context.strokeStyle = error ? palette.error.main : palette.primary.main;
      context.setLineDash([5, 5]);
      context.lineWidth = 3;
      context.stroke();
    }
  }, [newCircle]);

  return <Canvas width={width} height={height} ref={canvasRef} />;
};

export default Overlay;
