import { IAccountRelationships } from 'models/profiles.model';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store';
import { useGetLinkedCaregiversQuery } from 'store/api/profiles.api';
import { useSetEmergencyContact } from './use-set-emergency-contact';
import { useRemoveEmergencyContact } from './use-remove-emergency-contact';
import { useProfileConfig } from './use-profile-config';
import { useAdminStatusQuery } from 'store/api/auth.api';

const getListOfPossibleContacts = (data: IAccountRelationships) => {
  const { accounts, results } = data;

  return results
    .filter((caregiver) => caregiver.subscriber_can_be_emergency_contact)
    .map((caregiver) => {
      return {
        id: caregiver.id,
        name: accounts[caregiver.subscribing_account_id].first_name,
      };
    });
};

export const useEmergencyContact = () => {
  const [currentId, setCurrentId] = useState<number>(0);

  const {
    handleChange: handleToggleChange,
    value: toggleValue,
    config,
    readOnly,
  } = useProfileConfig('show_call_emergency_contact_button');

  const profileId = useAppSelector((state) => state.dashboard.profileId);
  const accountId = useAppSelector((state) => state.user.accountId as number);

  const { data: isAdmin = false } = useAdminStatusQuery({
    userId: accountId as number,
  });

  const { data, isLoading } = useGetLinkedCaregiversQuery({ profileId });

  const [removeContact, isRemoveLoading] = useRemoveEmergencyContact(
    setCurrentId,
    profileId,
  );
  const [setContact, isSetLoading] = useSetEmergencyContact(
    setCurrentId,
    profileId,
  );

  const contacts = data ? getListOfPossibleContacts(data) : [];

  const canBeEdited = useMemo(() => {
    if (isAdmin) {
      return true;
    }

    return data?.results.find((c) => c.subscribing_account_id === accountId)
      ?.subscriber_is_primary;
  }, [accountId, data]);

  const handleChange = (newId: number) => {
    if (Number.isNaN(newId)) {
      return;
    }

    if (newId === 0) {
      if (currentId !== 0) {
        removeContact({ id: currentId });
      }
      return;
    }

    if (newId && newId !== currentId) {
      setContact({ id: newId });
    }
  };

  useEffect(() => {
    if (data?.results) {
      setCurrentId(
        data.results.find(
          (caregiver) => caregiver.subscriber_is_emergency_contact,
        )?.id ?? 0,
      );
    }
  }, [data?.results]);

  const onToggleChange = (checked: boolean) => {
    if (checked && contacts.length === 0) {
      return;
    }

    if (checked && !currentId) {
      handleChange(contacts[0].id);
    }

    if (!checked && currentId) {
      handleChange(0);
    }

    handleToggleChange(checked);
  };

  return {
    currentContact: contacts.find((c) => c.id === currentId),
    contacts,
    handleChange,
    handleEmergencySwitchChange: onToggleChange,
    isLoading: isLoading || isSetLoading || isRemoveLoading,
    isMutating: isSetLoading && isRemoveLoading,
    canBeEdited,
    isActive: toggleValue,
    config,
    readOnly: readOnly || contacts.length === 0,
  };
};
