import { Box, Grid, Typography } from '@mui/material';
import Alerter from './AlertCard';
import { useAlerters } from '../hooks/use-alerters';
import { TriggerGroup } from 'utils/triggers';
import { getSideBarState } from 'services/sidebar';
import { EmptyList } from 'components/_extra/EmptyList';
import { CreateAlertButton } from './CreateAlertButton';
import { notificationActive } from 'assets/iconify';
import AlertCategoryLoader from './AlertCategoryLoader';

const Alerts = () => {
  const { alerters, isLoading, alerterCount } = useAlerters();
  const isSidebarOpen = getSideBarState();
  const breakpoints = isSidebarOpen
    ? { xxs: 12, newXl: 12, new3Xl: 6, new4Xl: 4 }
    : { xxs: 12, newXl: 6, new3Xl: 4, new4Xl: 4 };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      gap={3}
      pb={alerterCount > 0 ? 1 : 0}
    >
      {isLoading ? (
        <>
          <AlertCategoryLoader breakpoints={breakpoints} />
          <AlertCategoryLoader breakpoints={breakpoints} />
        </>
      ) : !!alerters && alerterCount > 0 ? (
        (
          [
            TriggerGroup.HEALTH,
            TriggerGroup.LOCATION,
            TriggerGroup.ACTIVITY,
            TriggerGroup.DEVICE,
            'uncategorized',
          ] as TriggerGroup[]
        ).map(
          (key) =>
            alerters[key]?.length > 0 && (
              <div key={key}>
                <Typography
                  color="primary"
                  pb={{ xxs: 1, newLg: 2 }}
                  fontWeight={600}
                  textTransform="capitalize"
                >
                  {key}
                </Typography>
                <Box px={{ xxs: 0, newLg: 1 }}>
                  <Grid container spacing={{ xxs: 2, sm: 3 }}>
                    {alerters[key].map(
                      ({
                        title,
                        enabled,
                        id,
                        group,
                        schedulerSummary,
                        sourceStreamType,
                        recipientManagerName,
                        triggerSummary,
                        triggerSummaryFragments,
                        recipientManagers,
                      }) => (
                        <Grid key={id} item {...breakpoints}>
                          <Alerter
                            id={id}
                            title={title}
                            isActive={enabled}
                            group={group}
                            schedulerSummary={schedulerSummary}
                            sourceStreamType={sourceStreamType}
                            triggerSummary={triggerSummary}
                            recipientManagerName={recipientManagerName}
                            triggerFragments={triggerSummaryFragments}
                            recipientManagers={recipientManagers}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Box>
              </div>
            ),
        )
      ) : (
        <EmptyList
          icon={notificationActive.icons.normal}
          title="Start creating alerts"
          message={
            <>
              Create alerts to get notified when <strong>BoundaryCare</strong>{' '}
              detects an event you want to know about, like a fall, leaving a
              safe zone, or a high heart rate. Tailor alerts to your needs by
              customizing when, where, and how you want them to appear for
              caregivers.
            </>
          }
        >
          <CreateAlertButton />
        </EmptyList>
      )}
    </Box>
  );
};

export default Alerts;
