import {
  Box,
  MenuItem,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import {
  BorderWrapper,
  SelectWrapper,
} from 'pages/PatientAccount/components/DataSettings/styled';
import { INTERVAL_OPTIONS } from 'utils/interval-options';

const options = INTERVAL_OPTIONS;

type Props = {
  switchProps?: SwitchProps;
  selectProps?: SelectProps;
};

const HealthData = ({ selectProps, switchProps }: Props) => {
  return (
    <Box
      px={{ xxs: 1, newMd: 2 }}
      pt={1}
      pb={2}
      display="flex"
      flexDirection="column"
      gap={1}
      maxWidth={536}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1" color="primary" py={1}>
            Health Data
          </Typography>
          <HelpIcon
            message={
              <>
                When disabled, <strong>BoundaryCare</strong> will not attempt to
                collect or display any health data for this patient. You must
                launch <strong>BoundaryCare</strong> on the Apple Watch for
                changes to this setting to take effect.
              </>
            }
            tooltipPlacement="top-start"
          />
        </Box>
        <Switch
          {...switchProps}
          size="medium"
          sx={{
            ml: 2,
          }}
        />
      </Box>
      <BorderWrapper>
        <SelectWrapper>
          <Typography variant="body2" color="primary">
            Refresh Rate
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Select
              {...selectProps}
              size="small"
              sx={({ spacing }) => ({
                width: spacing(21.25),
                maxHeight: spacing(4),
              })}
            >
              {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <HelpIcon
              tooltipPlacement="top-start"
              message="Update most health data and fire any related alerts within the selected interval of an actual change."
            />
          </Box>
        </SelectWrapper>
      </BorderWrapper>
    </Box>
  );
};

export default HealthData;
