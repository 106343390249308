import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Rule from './Rule';
import { Ref } from 'react';
import SafeZoneSelector from './SafeZoneSelector';
import { MetricData } from 'store/reducers/alerter/alerter.slice';
import { Comparator } from 'utils/triggers';
import { FieldsData, useMetricRule } from 'hooks/use-metric-rule';
import { HelpIcon } from 'components/HelpIcon';
import { caregiverRole } from 'services/caregiver-role';

export interface MetricRuleFormProps {
  inCard?: boolean;
  readOnly?: boolean;
  id?: number;
  isInReminder?: boolean;
  onChange?: () => void;
  functionsRef: Ref<{
    reset?: () => void;
    getFieldsData: () => FieldsData;
  }>;
  defaultValues?: MetricData;
  onEdit?: () => void;
}

const MetricRuleForm = ({
  inCard,
  readOnly = false,
  id,
  onChange,
  isInReminder = false,
  functionsRef,
  defaultValues = {
    comparator: Comparator.CHANGE,
    metricType: 'device_connected',
    value: null,
  },
  onEdit,
}: MetricRuleFormProps) => {
  const {
    groups,
    selectedGroup,
    types,
    selectedType,
    handleGroupChange,
    handleTypeChange,
    setValue,
    setRule,
    setZones,
    defaultComparator,
    defaultValue,
    zones,
    value,
    rule,
    isSafeZoneEmpty,
    isAdmin,
  } = useMetricRule(id, defaultValues, functionsRef, isInReminder, onChange);

  const isDisabled = caregiverRole.value === 'viewer';

  const handleEdit = () => {
    if (readOnly) {
      onEdit?.();
    }
  };

  return (
    <>
      <div>
        <Typography pb={1} color="primary">
          Group
        </Typography>
        <Select
          sx={{ width: '100%' }}
          size="medium"
          value={selectedGroup}
          onChange={handleGroupChange}
          onOpen={isDisabled ? undefined : handleEdit}
          readOnly={isDisabled}
        >
          {groups.map(({ label, value }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <Typography pb={1} color="primary">
          Type
        </Typography>
        <Select
          sx={{ width: '100%' }}
          size="medium"
          value={selectedType.value}
          onChange={handleTypeChange}
          onOpen={isDisabled ? undefined : handleEdit}
          readOnly={isDisabled}
        >
          {types.map(({ label, value, adminOnly }) => {
            const isDisabled = adminOnly && !isAdmin;
            return (
              <MenuItem value={value} key={value} disabled={isDisabled}>
                {label}
                {isDisabled && (
                  <HelpIcon
                    message="This metric type is available only for admins."
                    tooltipPlacement="top-start"
                    iconSx={{ ml: 1, pointerEvents: 'auto' }}
                  />
                )}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <Rule
        defaultComparator={defaultComparator}
        defaultValue={defaultValue as number | undefined}
        inCard={inCard}
        selectedType={selectedType}
        setRule={setRule}
        setValue={setValue}
        rule={rule}
        value={value}
        onFocus={isDisabled ? undefined : handleEdit}
        readOnly={isDisabled}
        alternativeComparatorLabels={selectedType.alternativeComparatorLabels}
      />
      {selectedType.value === 'safe_zone' && (
        <Box gridColumn="1 / 3">
          <Typography pb={1} color="primary">
            Safe Zones
          </Typography>
          <SafeZoneSelector
            onOpen={isDisabled ? undefined : handleEdit}
            id={id}
            readOnly={isDisabled}
            setValue={setZones}
            value={zones === null ? [-1] : zones}
          />
          {isSafeZoneEmpty && (
            <FormHelperText error>Safe Zones field is required.</FormHelperText>
          )}
        </Box>
      )}
    </>
  );
};
export default MetricRuleForm;
