import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import RegisteredDevices from './RegisteredDevices';

const Devices = () => {
  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="start">
      <Typography color="primary" fontWeight={600}>
        Devices
      </Typography>
      <RegisteredDevices />
    </Box>
  );
};

export default memo(Devices);
