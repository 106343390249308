import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { BasicModal } from 'components/BasicModal';
import { useEffect, useState } from 'react';
import {
  useLazyGetLinkedCaregiversQuery,
  useLazyGetLinkedProfilesQuery,
} from 'store/api/profiles.api';
import { IProfilesAccount } from 'models/profiles.model';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';

interface Props {
  accountId: number;
  isLovedOne?: boolean;
  isOpen: boolean;
  onClose: (...args: unknown[]) => void;
}

const AssociatedAccounts = ({
  accountId,
  isLovedOne,
  isOpen,
  onClose,
}: Props) => {
  const { spacing } = useTheme();
  const navigate = useNavigate();

  const [profiles, setProfiles] = useState<IProfilesAccount[]>([]);

  const [
    getCaregivers,
    { data: caregiverData, isLoading: isCaregiverDataLoading },
  ] = useLazyGetLinkedCaregiversQuery();
  const [getProfiles, { data: profileData, isLoading: isProfileDataLoading }] =
    useLazyGetLinkedProfilesQuery();

  useEffect(() => {
    if (isOpen) {
      if (isLovedOne) {
        getCaregivers({ profileId: accountId });
      }
      if (!isLovedOne) {
        getProfiles({ caregiverId: accountId });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (caregiverData) {
      setProfiles(Object.values(caregiverData.accounts));
    }
  }, [caregiverData]);

  useEffect(() => {
    if (profileData) {
      setProfiles(Object.values(profileData.accounts));
    }
  }, [profileData]);

  const onAccountClick = (id: number) => {
    onClose();
    navigate(routes.admin.lookupResults.href(id));
  };

  const isLoading = isCaregiverDataLoading || isProfileDataLoading;

  return (
    <BasicModal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      maxWidth={spacing(40)}
      p={1.5}
    >
      <Typography variant="h6">
        Associated {isLovedOne ? 'caregivers' : 'loved ones'}
      </Typography>
      <Box>
        {!isLoading ? (
          <List>
            {profiles.map((profile) => (
              <ListItem disablePadding key={profile.id}>
                <ListItemButton onClick={() => onAccountClick(profile.id)}>
                  <ListItemText
                    primary={profile.first_name}
                    secondary={'ID: ' + profile.id}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={spacing(8)} sx={{ m: 4 }} />
          </Box>
        )}
      </Box>
    </BasicModal>
  );
};

export default AssociatedAccounts;
