const PATHNAME_KEY = 'lastPathname';

/**
 * This function writes the given pathname to the local storage, to use it on redirect after login.
 * @param pathname
 */
export const setLastPathname = (pathname: string, search?: string) => {
  localStorage.setItem(PATHNAME_KEY, JSON.stringify({ pathname, search }));
};

export const getLastPathname = (): { pathname?: string; search?: string } => {
  try {
    return JSON.parse(localStorage.getItem(PATHNAME_KEY) || '{}');
  } catch {
    return {};
  }
};

export const removeLastPathname = () => {
  localStorage.removeItem(PATHNAME_KEY);
};

export class BCLocalStorage {
  static HIPAA_KEY = 'hipaa';

  static get hipaa(): boolean {
    try {
      return +(localStorage.getItem(BCLocalStorage.HIPAA_KEY) || '') === 1
        ? true
        : false || false;
    } catch {
      return false;
    }
  }

  static set hipaa(value: boolean) {
    localStorage.setItem(BCLocalStorage.HIPAA_KEY, value ? '1' : '0');
  }
}
