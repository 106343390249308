import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { routes } from 'nav';
import {
  useRequestPasswordResetMutation,
  useVerifyEmailMutation,
} from 'store/api/auth.api';

const forgotSchema = yup.object({
  email: yup.string().email().required(),
});

export const useForgotForm = () => {
  const [isInTransition, setIsInTransition] = useState(false);
  const navigate = useNavigate();
  const methods = useForm<{
    email: string;
  }>({
    resolver: yupResolver(forgotSchema),
    reValidateMode: 'onSubmit',
  });

  const email = useRef('');

  const [requestPasswordReset, { isLoading, isSuccess }] =
    useRequestPasswordResetMutation();
  const [verifyEmail, emailCheck] = useVerifyEmailMutation();

  const onSubmit = (data: { email: string }) => {
    const forgotFor = localStorage.getItem('forgotFor');
    const sentAt = localStorage.getItem('sentForgotAt');
    const difference = Math.floor(
      (new Date().getTime() - Number(sentAt)) / 1000,
    );

    if (!isLoading) {
      setIsInTransition(true);
      email.current = data.email;
      if (
        forgotFor === data.email &&
        (difference < 60 || Number.isNaN(difference)) &&
        emailCheck.data?.result.exists
      ) {
        navigate(routes.public.passwordResetSuccess.href, {
          state: { email: email.current },
        });
        return;
      }
      verifyEmail({ email: data.email });
      localStorage.setItem('sentForgotAt', new Date().getTime().toString());
      localStorage.setItem('forgotFor', data.email);
      localStorage.removeItem('resentAt');
    }
  };

  useEffect(() => {
    if (!emailCheck.data) return;

    if (emailCheck.data.result.exists && !emailCheck.isLoading) {
      requestPasswordReset({ email: methods.getValues().email });
      return;
    }

    if (!emailCheck.data.result.exists && !emailCheck.isLoading) {
      methods.setError('email', { type: 'verify' }, { shouldFocus: true });
      setIsInTransition(false);
    }
  }, [emailCheck.data]);

  useEffect(() => {
    if (isSuccess) {
      navigate(routes.public.passwordResetSuccess.href, {
        state: { email: email.current },
      });
    }
  }, [isSuccess]);

  return {
    methods,
    onSubmit,
    isLoading: isLoading || emailCheck.isLoading || isInTransition,
  };
};
