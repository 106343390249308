import { FormGroup, FormHelperText, Typography, styled } from '@mui/material';

export const Root = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: theme.spacing(90.5),
}));

export const General = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  flexWrap: 'wrap',
  gap: theme.spacing(3),
  [theme.breakpoints.down('newLg')]: {
    padding: theme.spacing(2, 0),
  },
}));

export const MessageOptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('newLg')]: {
    padding: theme.spacing(2, 0),
  },
}));

export const GeneratedNotificationMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.active,
}));

export const ChannelsWrapper = styled(FormGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'start',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.down('newLg')]: {
    padding: theme.spacing(2, 0),
  },
}));

export const HelperText = styled(FormHelperText)(({ theme }) => ({
  padding: theme.spacing(0, 1.75),
}));
