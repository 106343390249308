import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { useGetAlerterSharedRecipientsQuery } from 'store/api/alerters.api';
import {
  getAlerterPresets,
  getReminderPresets,
  getSharedRecipientsOptions,
} from 'utils/data-transformation';
import { getFirstName } from 'utils/get-first-name';
import { findSmallestMissingTitle } from 'utils/name-assign';

export const useRecipientManagerOptions = (isReminder?: boolean) => {
  const { profileId } = useParams();
  const { patientName } = useAppSelector((state) => state.dashboard);

  const { data, isLoading } = useGetAlerterSharedRecipientsQuery(
    {
      patientProfileId: parseInt(profileId ?? '0'),
      lightweight: true,
      isReminder,
    },
    { skip: !profileId },
  );

  const recipientSetOptions = getSharedRecipientsOptions(data);
  const nextTitle = findSmallestMissingTitle(
    recipientSetOptions.map((option) => option.label),
  );

  return {
    recipientSetOptions,
    presets: isReminder
      ? getReminderPresets(getFirstName(patientName))
      : getAlerterPresets(getFirstName(patientName)),
    isLoading,
    nextTitle,
  };
};
