import { RefreshPatternType } from 'models/devices.model';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { useGetByIdQuery, useUpdateMutation } from 'store/api/devices.api';

const options = [
  {
    label: '5 minutes',
    value: 'continuous',
  },
  {
    label: '5 - 10 minutes',
    value: 'hybrid',
  },
  {
    label: '15 - 20 minutes',
    value: 'default',
  },
];

export const useUpdateIntervals = (intervalFor: 'location' | 'health') => {
  const { deviceId } = useAppSelector((state) => state.dashboard);

  const { data, isLoading } = useGetByIdQuery(deviceId, { skip: !deviceId });

  const [updateDevice, { isLoading: isUpdating }] = useUpdateMutation();
  const [selectedInterval, setSelectedInterval] =
    useState<RefreshPatternType>('continuous');

  const handleChange = (value: string) => {
    updateDevice({
      deviceId,
      locationRefreshPatternType:
        intervalFor === 'health' ? undefined : (value as RefreshPatternType),
      heartRateRefreshPatternType:
        intervalFor === 'health' ? (value as RefreshPatternType) : undefined,
    });
    setSelectedInterval(value as RefreshPatternType);
  };

  useEffect(() => {
    if (data) {
      setSelectedInterval(
        data[
          intervalFor === 'health'
            ? 'heart_rate_refresh_pattern_type'
            : 'location_refresh_pattern_type'
        ],
      );
    }
  }, [data]);

  return {
    handleChange,
    selectedInterval,
    options,
    isUpdating,
    isLoading,
  };
};
