import { caregiverRole } from 'services/caregiver-role';
import { useAppSelector } from 'store';
import { useAdminStatusQuery } from 'store/api/auth.api';
import { useGetLinkedProfilesQuery } from 'store/api/profiles.api';

export const useCaregiverPermission = () => {
  const accountId = useAppSelector((state) => state.user.accountId);
  const profileId = useAppSelector((state) => state.dashboard.profileId);

  const { data } = useGetLinkedProfilesQuery(
    { caregiverId: accountId ?? 0 },
    { skip: !accountId },
  );

  const { data: isAdmin = false } = useAdminStatusQuery({
    userId: accountId as number,
  });

  return {
    canManageDevices:
      (!!data?.results.find((patient) => patient.account_id === profileId)
        ?.subscriber_can_manage_devices &&
        caregiverRole.value !== 'viewer') ||
      isAdmin,
  };
};
