import {
  Box,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { BorderWrapper, SelectWrapper } from './styled';
import { useProfileConfig } from 'pages/PatientAccount/hooks/use-profile-config';
import { useUpdateIntervals } from 'pages/PatientAccount/hooks/use-update-intervals';

const LocationData = () => {
  const { handleChange, value, isLoading, isUpdating, readOnly } =
    useProfileConfig(
      'collects_location_data',
      'Disabling this feature will stop the collection of location data. Are you sure you want to continue?',
      true,
    );

  const {
    handleChange: handleIntervalChange,
    isLoading: isIntervalLoading,
    isUpdating: isIntervalUpdating,
    selectedInterval,
    options,
  } = useUpdateIntervals('location');

  return (
    <Box
      px={{ xxs: 1, newMd: 2 }}
      pt={2}
      pb={1}
      display="flex"
      flexDirection="column"
      gap={1}
      maxWidth={536}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1" color="primary" py={1}>
            Location Data
          </Typography>
          <HelpIcon
            tooltipPlacement="top-start"
            message={
              <>
                When disabled, <strong>BoundaryCare</strong> will not collect or
                display any location data for the patient. This disables all
                safe zone alerts, emergency tracking, and 911 address
                information. You must relaunch <strong>BoundaryCare</strong> on
                the Apple Watch for changes to this setting to fully take
                effect.
              </>
            }
          />
        </Box>
        {isLoading ? (
          <Skeleton variant="rounded" width={58} height={28} />
        ) : (
          <Switch
            size="medium"
            checked={value}
            onChange={(_, checked) => {
              handleChange(checked);
            }}
            disabled={readOnly || isUpdating}
            sx={{
              ml: 2,
            }}
          />
        )}
      </Box>
      <BorderWrapper>
        <SelectWrapper>
          <Typography variant="body2" color="primary">
            Refresh Rate
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Select
              size="small"
              sx={({ spacing }) => ({
                width: spacing(21.25),
                maxHeight: spacing(4),
              })}
              onChange={(e) => {
                handleIntervalChange(e.target.value);
              }}
              disabled={isIntervalUpdating}
              readOnly={readOnly}
              value={selectedInterval}
              renderValue={(value) => {
                if (isIntervalLoading) return 'Loading...';
                return options.find((option) => option.value === value)?.label;
              }}
            >
              {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <HelpIcon
              tooltipPlacement="top-start"
              message={`Update location data and fire any related alerts within ${
                options.find(({ value }) => value === selectedInterval)?.label
              } of an actual change.`}
            />
          </Box>
        </SelectWrapper>
      </BorderWrapper>
    </Box>
  );
};

export default LocationData;
