import { useConfirmation } from 'hooks/use-confirmation';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useRemoveRuleFromAlerterTriggerMutation,
  useUpdateRuleInAlerterTriggerMutation,
} from 'store/api/alerters.api';
import {
  cancelEditing,
  editRule,
  removeRule,
  resetEditedRule,
  setEmptyZonesFlag,
} from 'store/reducers/alerter/alerter.slice';
import { isRuleDifferentSelector } from 'store/selectors';

export const useTriggerCard = (id: number, onReset?: () => void) => {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const [
    remove,
    { isError: wasNotRemoved, isSuccess: wasRemoved, isLoading: isRemoving },
  ] = useRemoveRuleFromAlerterTriggerMutation();
  const [
    edit,
    { isError: isEditError, isSuccess: wasEdited, isLoading: isEditLoading },
  ] = useUpdateRuleInAlerterTriggerMutation();

  const isSaveActive = useAppSelector(isRuleDifferentSelector('alerter'));
  const {
    editedRule,
    id: triggerId,
    rules,
  } = useAppSelector((state) => state.alerter.trigger);

  const handleEditButtonClick = () => {
    dispatch(editRule(id));
  };

  const handleDiscard = () => {
    dispatch(resetEditedRule(id));
    onReset?.();
  };

  const handleBackButtonClick = () => {
    if (isSaveActive) {
      confirm(
        {
          title: 'You have unsaved changes',
          message: 'Do you want to save or discard them?',
          rejectButtonLabel: 'Go back',
          confirmButtonLabel: 'Discard',
        },
        handleDiscard,
      );
      return;
    }
    dispatch(cancelEditing());
  };

  const handleRemoveButtonClick = () => {
    const title = rules.find((rule) => rule.id === id)?.title;

    confirm(
      {
        title: `Are you sure you want to delete “${title}” from the condition list?`,
        message:
          'This condition will be deleted immediately. You can’t undo this action.',
        rejectButtonLabel: 'Cancel',
        variant: 'danger',
      },
      () => {
        remove({ triggerId, metricRuleId: id });
      },
    );
  };

  const handleSaveButtonClick = () => {
    if (
      Array.isArray(editedRule?.newRule.value) &&
      editedRule?.newRule.value.length === 0
    ) {
      dispatch(setEmptyZonesFlag());
      return;
    }

    if (editedRule) {
      edit({
        metricRuleId: editedRule.id,
        metricRule: editedRule.newRule,
      });
    }

    dispatch(cancelEditing());
  };

  useEffect(() => {
    if (!isRemoving && wasNotRemoved) {
      toast.error('An error occured.');
    }

    if (!isRemoving && wasRemoved) {
      toast.success('The condition was successfully removed.');
      dispatch(removeRule(id));
    }
  }, [isRemoving]);

  useEffect(() => {
    if (!isEditLoading && wasEdited) {
      toast.success('The condition was successfully updated!');
      return;
    }

    if (!isEditLoading && isEditError) {
      toast.error('An error occured.');
    }
  }, [isEditLoading]);

  return {
    isEditable: editedRule?.id === id,
    handleBackButtonClick,
    handleEditButtonClick,
    handleRemoveButtonClick,
    handleSaveButtonClick,
    isSaveActive,
  };
};
