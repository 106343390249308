import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const arrowLeft: IconifyJSON = {
  prefix: 'arrow-left',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12.1073 20.5187L4.49194 12.9033L12.1073 5.28796L13.1765 6.34179L7.36494 12.1533H19.7226V13.6533H7.36494L13.1765 19.4648L12.1073 20.5187Z" fill="currentColor"/>',
    },
  },
};
