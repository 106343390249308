import { Box, Skeleton, Typography } from '@mui/material';
import { Root } from './styled';
import GeneralFields from './components/GeneralFields';
import MessageField from './components/MessageField';
import Channels from './components/Channels';
import { FormProvider } from 'react-hook-form';
import { memo } from 'react';
import SaveButton from './components/SaveButton';
import DiscardButton from './components/DiscardButton';
import { useSettings } from 'pages/ReminderDetails/hooks/use-settings';

const Settings = () => {
  const { isLoading, methods, handleSubmit, errors, discardChanges, readOnly } =
    useSettings();

  return (
    <FormProvider {...methods}>
      <Root onSubmit={handleSubmit}>
        <div>
          <Typography color="primary" fontWeight={600}>
            General
          </Typography>
          {isLoading ? (
            <Skeleton sx={{ m: 2 }} variant="rounded" height={108} />
          ) : (
            <GeneralFields readOnly={readOnly} error={errors.title?.message} />
          )}
        </div>
        <div>
          <Typography color="primary" fontWeight={600}>
            Message
          </Typography>
          {isLoading ? (
            <Skeleton sx={{ m: 2 }} variant="rounded" height={320} />
          ) : (
            <MessageField readOnly={readOnly} error={errors.message?.message} />
          )}
        </div>
        <div>
          <Typography color="primary" fontWeight={600}>
            Channels
          </Typography>
          {isLoading ? (
            <Skeleton sx={{ m: 2 }} variant="rounded" height={108} />
          ) : (
            <Channels readOnly={readOnly} error={errors.channels?.message} />
          )}
        </div>
        <Box display="flex" justifyContent="end" gap={2} pt={2}>
          <DiscardButton onClick={discardChanges} />
          <SaveButton />
        </Box>
      </Root>
    </FormProvider>
  );
};

export default memo(Settings);
