import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const success: IconifyJSON = {
  prefix: 'success',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12.4059 21.835C11.092 21.835 9.85698 21.5857 8.70085 21.087C7.5447 20.5884 6.53901 19.9116 5.6838 19.0568C4.82856 18.2019 4.15151 17.1967 3.65262 16.0411C3.15374 14.8854 2.9043 13.6507 2.9043 12.3367C2.9043 11.0228 3.15367 9.78777 3.65242 8.63163C4.15117 7.47548 4.82805 6.4698 5.68305 5.61458C6.53805 4.75935 7.54346 4.08229 8.6993 3.58341C9.85511 3.08452 11.0901 2.83508 12.4043 2.83508C13.4577 2.83508 14.4544 2.99342 15.3943 3.31008C16.3343 3.62675 17.1966 4.06842 17.9812 4.63508L16.8966 5.74468C16.2504 5.29982 15.5505 4.95366 14.7968 4.70621C14.0431 4.45877 13.2456 4.33506 12.4043 4.33506C10.1876 4.33506 8.30011 5.11422 6.74177 6.67256C5.18344 8.23089 4.40427 10.1184 4.40427 12.3351C4.40427 14.5517 5.18344 16.4392 6.74177 17.9976C8.30011 19.5559 10.1876 20.3351 12.4043 20.3351C14.6209 20.3351 16.5084 19.5559 18.0668 17.9976C19.6251 16.4392 20.4043 14.5517 20.4043 12.3351C20.4043 11.9838 20.3812 11.6364 20.335 11.2928C20.2889 10.9493 20.2196 10.6153 20.1273 10.2908L21.3388 9.06968C21.5222 9.58378 21.6622 10.1126 21.759 10.6563C21.8558 11.1999 21.9042 11.7595 21.9042 12.3351C21.9042 13.6492 21.6549 14.8842 21.1562 16.04C20.6576 17.1959 19.9808 18.2013 19.126 19.0563C18.2711 19.9113 17.2659 20.5882 16.1103 21.0869C14.9547 21.5857 13.7199 21.835 12.4059 21.835ZM10.985 16.5889L7.08122 12.6851L8.13505 11.6312L10.985 14.4812L20.8504 4.60048L21.9042 5.65431L10.985 16.5889Z" fill="currentColor"/>',
    },
  },
};
