import { Icon } from '@iconify/react';
import { FormGroup, FormHelperText, Typography, styled } from '@mui/material';

export const Root = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxHeight: '100%',
  maxWidth: theme.spacing(90.5),
}));

export const General = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));

export const MessageOptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

export const GeneratedNotificationMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.active,
}));

export const ChannelsWrapper = styled(FormGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'start',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.down('newLg')]: {
    padding: theme.spacing(2, 0),
  },
}));

export const HelperText = styled(FormHelperText)(({ theme }) => ({
  padding: theme.spacing(0, 1.75),
}));

export const AddImageIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
}));

export const SuppressMessageWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5, 0, 0.5, 5),
  display: 'flex',
  gap: theme.spacing(1),
}));
