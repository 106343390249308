import { Icon } from '@iconify/react';
import { Chip, Fade, IconButton, Paper, Popper, Tooltip } from '@mui/material';
import { layer } from 'assets/iconify';
import { useEffect, useState } from 'react';

type Props = {
  readOnly?: boolean;
  onSelect?: (
    value: 'patient_first_name' | 'patient_last_name' | 'event_time',
  ) => void;
};

const MessageVariablesMenu = ({ readOnly, onSelect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSelect = (
    value: 'patient_first_name' | 'patient_last_name' | 'event_time',
  ) => {
    onSelect?.(value);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (readOnly) {
      setAnchorEl(null);
    }
  }, [readOnly]);

  return (
    <>
      <Tooltip
        title="Insert text variables"
        placement="top-start"
        PopperProps={{ sx: { display: !anchorEl ? undefined : 'none' } }}
      >
        <span>
          <IconButton
            onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            sx={{
              p: 0.5,
              borderRadius: 0.5,
              ':hover': {
                backgroundColor: ({ palette }) => palette.hover.darkBlue,
              },
            }}
            disabled={readOnly}
            color="primary"
          >
            <Icon icon={layer.icons.normal} />
          </IconButton>
        </span>
      </Tooltip>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="top-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper elevation={8} sx={{ p: 2, mb: 2, display: 'flex', gap: 2 }}>
              <Chip
                clickable
                label="First Name"
                onClick={() => handleSelect('patient_first_name')}
              />
              <Chip
                clickable
                label="Last Name"
                onClick={() => handleSelect('patient_last_name')}
              />
              <Chip
                clickable
                label="Timestamp"
                onClick={() => handleSelect('event_time')}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default MessageVariablesMenu;
