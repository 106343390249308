import { Theme, outlinedInputClasses, styled } from '@mui/material';

export const CardWrapper = styled('div')(
  ({ theme, isEdited }: { theme?: Theme; isEdited: boolean }) => ({
    border: `${isEdited ? 2 : 1}px solid ${
      isEdited ? theme?.palette.secondary.main : theme?.palette.borderGray.main
    }`,
    borderRadius: theme?.spacing(1),
    height: '100%',
    padding: theme?.spacing(isEdited ? 1.875 : 2),
    [theme?.breakpoints.down('sm') ?? '']: {
      padding: theme?.spacing(isEdited ? 1.375 : 1.5),
    },
  }),
);

export const GeneralControls = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
}));

export const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    padding: theme.spacing(0.5),
  },
}));

export const FlexLine = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'start',
  width: '100%',
  gap: theme.spacing(3),
  div: {
    // flexGrow: 1,
    flexBasis: 'calc(50% - 12px)',
  },
}));

export const InputBox = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  alignSelf: 'end',
  color: theme.palette.action.active,
  [`.${outlinedInputClasses.root}`]: {
    minWidth: theme.spacing(12),
    maxWidth: theme.spacing(12),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
  },
}));

export const CardControlsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
