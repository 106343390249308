import { safeZone } from 'assets/iconify/safe-zone';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';

export const getZoneStatus = (isInside?: boolean): DataCellProps => {
  if (isInside === true) {
    return {
      icon: safeZone.icons.inside,
      status: DataStatus.POSITIVE,
      label: 'Inside',
    };
  }

  if (isInside === false) {
    return {
      icon: safeZone.icons.outside,
      status: DataStatus.NEGATIVE,
      label: 'Outside',
    };
  }

  return {
    icon: safeZone.icons.disabled,
    status: DataStatus.DISABLED,
    label: 'Disabled',
  };
};
