import { Box, Skeleton, Typography, styled } from '@mui/material';
import { Root } from './styled';
import GeneralFields from './components/GeneralFields';
import MessageField from './components/MessageField';
import Channels from './components/Channels';
import { useSettings } from 'pages/AlerterDetails/hooks/use-settings';
import { FormProvider } from 'react-hook-form';
import { memo, useRef } from 'react';
import SaveButton from './components/SaveButton';
import DiscardButton from './components/DiscardButton';

const HiddenButton = styled('button')`
  visibility: hidden;
  overflow: hidden;
  border: 0;
  width: 0;
  height: 0;
`;

const Settings = () => {
  const submitRef = useRef<HTMLButtonElement>(null);
  const { isLoading, methods, handleSubmit, errors, discardChanges, readOnly } =
    useSettings();

  const clickSubmit = () => {
    submitRef.current?.click();
  };

  return (
    <FormProvider {...methods}>
      <Box display="flex" flexDirection="column" maxHeight="100%">
        <Root onSubmit={handleSubmit}>
          <div>
            <Typography color="primary" fontWeight={600}>
              General
            </Typography>
            {isLoading ? (
              <Skeleton variant="rounded" height={108} />
            ) : (
              <GeneralFields
                readOnly={readOnly}
                error={errors.title?.message}
              />
            )}
          </div>
          <div>
            <Typography color="primary" fontWeight={600}>
              Notification Message
            </Typography>
            {isLoading ? (
              <Skeleton sx={{ m: 2 }} variant="rounded" height={320} />
            ) : (
              <MessageField
                readOnly={readOnly}
                error={errors.customMessage?.message}
              />
            )}
          </div>
          <div>
            <Typography color="primary" fontWeight={600}>
              Channels
            </Typography>
            {isLoading ? (
              <Skeleton sx={{ m: 2 }} variant="rounded" height={108} />
            ) : (
              <Channels readOnly={readOnly} error={errors.channels?.message} />
            )}
            <HiddenButton type="submit" ref={submitRef} />
          </div>
        </Root>
        <Box display="flex" justifyContent="end" gap={2} pt={2}>
          <DiscardButton onClick={discardChanges} />
          <SaveButton onClick={clickSubmit} />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default memo(Settings);
