import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from '@mui/material';
import {
  GeneratedNotificationMessage,
  HelperText,
  MessageOptionWrapper,
  SuppressMessageWrapper,
} from '../styled';
import { useAppSelector } from 'store';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import CustomMessageField from 'components/_extra/Alerter/CustomMessageField';
import AddImage from './AddImage';
import MetricRuleChip from './MetricRuleChip';
import { unsupressedTriggersIdsSelector } from 'store/selectors';
import { HelpIcon } from 'components/HelpIcon';
import { usePreviewMessageMutation } from 'store/api/alerters.api';

const MessageField = ({
  error,
  readOnly,
}: {
  error?: string;
  readOnly?: boolean;
}) => {
  const { register, getValues, setValue, formState } =
    useFormContext<SettingsForm>();
  const generatedMessage = useAppSelector(
    (state) => state.alerter.settings.generatedMessage,
  );
  const areSettingsModfied = useAppSelector(
    (state) => state.alerter.editedAlerter.isModifiedSettings,
  );
  const { rules: metricRules, id: triggerId } = useAppSelector(
    (state) => state.alerter.trigger,
  );
  const defaultUnsupressedRules = useAppSelector(
    unsupressedTriggersIdsSelector,
  );

  const [unsuppressedRules, setUnsuppressedRules] = useState<number[]>([]);
  const [selected, setSelected] = useState<'generated' | 'custom'>(
    getValues().messageType,
  );

  const [
    getNewPreview,
    { data: messagePreview, isLoading: isMessagePreviewLoading },
  ] = usePreviewMessageMutation();

  const isSuppressedListModified = useMemo(() => {
    return (
      unsuppressedRules.sort().toString() !==
      defaultUnsupressedRules.sort().toString()
    );
  }, [unsuppressedRules]);

  const customMessageInputRef = useRef<HTMLInputElement>(null);

  const updateValue = (value: 'generated' | 'custom') => {
    setSelected(value);
    setValue('messageType', value, { shouldDirty: true });
  };

  const handleChange = (value: 'generated' | 'custom') => {
    updateValue(value);
    if (value === 'custom') {
      customMessageInputRef.current?.focus();
    }
  };

  const handleMessageFieldClick = () => {
    if (readOnly) return;
    if (selected === 'generated') {
      updateValue('custom');
    }
  };

  useEffect(() => {
    if (getValues().messageType !== selected) {
      setSelected(getValues().messageType);
    }
  }, [getValues().messageType]);

  useEffect(() => {
    if (defaultUnsupressedRules) {
      setUnsuppressedRules(defaultUnsupressedRules);
    }
  }, [defaultUnsupressedRules]);

  const handleRuleClick = (ruleId: number) => {
    const index = unsuppressedRules.indexOf(ruleId);
    if (index === -1) {
      setUnsuppressedRules([...unsuppressedRules, ruleId]);
    } else {
      setUnsuppressedRules(unsuppressedRules.filter((rule) => rule !== ruleId));
    }
  };

  useEffect(() => {
    setValue('unsuppressedRules', unsuppressedRules, {
      shouldDirty:
        unsuppressedRules.sort().toString() !==
        defaultUnsupressedRules.sort().toString(),
    });

    if (isSuppressedListModified) {
      getNewPreview({
        triggerId,
        rules: unsuppressedRules,
      });
    }
  }, [unsuppressedRules]);

  useEffect(() => {
    if (!areSettingsModfied) {
      setUnsuppressedRules(defaultUnsupressedRules);
    }
  }, [areSettingsModfied]);

  return (
    <Box px={{ xxs: 0, newLg: 2 }} py={2}>
      <RadioGroup>
        <MessageOptionWrapper sx={{ pb: 3, gap: 0 }}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              sx={{ pl: ({ spacing }) => spacing(1.5), mr: 1 }}
              value="generated"
              control={<Radio />}
              disabled={readOnly}
              label="Generated"
              labelPlacement="end"
              checked={selected === 'generated'}
              onChange={() => handleChange('generated')}
            />
            <HelpIcon
              message={
                <>
                  This message can be customized by <br />
                  selecting this alert's data types.
                </>
              }
              tooltipPlacement="top-start"
            />
          </Box>
          <SuppressMessageWrapper>
            {metricRules.map((rule) => (
              <MetricRuleChip
                disabled={selected === 'custom' || readOnly}
                key={rule.id}
                value={rule.id}
                selected={unsuppressedRules.includes(rule.id)}
                onClick={(ruleId) => handleRuleClick(ruleId)}
                label={rule.title}
              />
            ))}
          </SuppressMessageWrapper>
          <HelperText error sx={{ pl: 5 }}>
            {formState.errors.unsuppressedRules?.message}
          </HelperText>
          <GeneratedNotificationMessage px={1.5} pt={1.5}>
            {isMessagePreviewLoading ? (
              <Skeleton
                variant="text"
                sx={{ maxWidth: ({ spacing }) => spacing(50) }}
              />
            ) : isSuppressedListModified && unsuppressedRules.length > 0 ? (
              messagePreview?.result
            ) : (
              generatedMessage
            )}
          </GeneratedNotificationMessage>
        </MessageOptionWrapper>
        <MessageOptionWrapper>
          <FormControlLabel
            sx={{ pl: ({ spacing }) => spacing(1.5) }}
            value="custom"
            control={<Radio />}
            label="Custom"
            disabled={readOnly}
            labelPlacement="end"
            checked={selected === 'custom'}
            onChange={() => handleChange('custom')}
          />
          <div>
            <CustomMessageField
              readOnly={selected === 'generated' || readOnly}
              inputRef={customMessageInputRef}
              error={!!error}
              fullWidth
              onClick={handleMessageFieldClick}
              {...register('customMessage')}
            />
            <HelperText error>{error}</HelperText>
          </div>
          <div>
            <AddImage readOnly={readOnly} />
          </div>
        </MessageOptionWrapper>
      </RadioGroup>
    </Box>
  );
};

export default memo(MessageField);
