import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import { persistor } from 'store';

const LoginMessage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    navigate(routes.public.login.href);
  };

  return (
    <Typography variant="body2" align="center" sx={{ px: 1, pt: 4 }}>
      <Link color="secondary" fontWeight={600} onClick={handleClick}>
        Go back to login
      </Link>
    </Typography>
  );
};

export default LoginMessage;
