import { Icon } from '@iconify/react';
import { Typography, styled } from '@mui/material';

export const FileUploadArea = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderStyle: 'dashed',
  borderWidth: '1px',
  color: theme.palette.primary.light,
  padding: theme.spacing(3, 4),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  width: '100%',
  backgroundColor: theme.palette.primaryBackground.main,
  ':hover': {
    backgroundColor: theme.palette.primaryBackground.dark,
  },
}));

export const ImageUploadArea = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('xxs')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
  width: '100%',
  height: '100%',
  gap: theme.spacing(2),
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.17px',
  textAlign: 'center',
}));

export const UploadSeparator = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const UploadSeparatorLine = styled('div')(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.palette.divider,
  flexGrow: 0.5,
}));

export const UploadButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
}));

export const ImageUploadPreview = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
  minWidth: '100%',
  minHeight: '100%',
  objectFit: 'cover',
}));

export const ImagePreviewWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const ImageButtonWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  width: theme.spacing(20),
  height: theme.spacing(20),
  borderRadius: theme.spacing(1),
}));

export const FallbackIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.error.main,
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
}));
