import { Box, Button, FormHelperText, Typography } from '@mui/material';
import * as yup from 'yup';
import VInputPassword from 'components/VInputPassword/VInputPassword';
import { PasswordStrength } from 'models/auth.model';
import { ChangeEvent, useMemo, useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useAppSelector } from 'store';
import { useChangePasswordMutation } from 'store/api/auth.api';
import { checkPasswordStrength } from 'utils/password-check';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

interface IChangePasswordForm {
  password: string;
  oldPassword?: string;
  confirmPassword: string;
}

const changePasswordSchema = yup.object({
  password: yup.string().required('The password is required.'),
  oldPassword: yup.string(),
  confirmPassword: yup.string(),
});

interface Props {
  onSuccess?: () => void;
}

const ChangePasswordForm = ({ onSuccess }: Props) => {
  const { register, handleSubmit } = useForm<IChangePasswordForm>({
    resolver: yupResolver(changePasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const credentialId = useAppSelector((state) => state.user.credentialId);
  const requiresMfa = useAppSelector((state) => state.mfa.requiresMfa);

  const [strength, setStrength] = useState<PasswordStrength>('too short');
  const [errors, setErrors] = useState<FieldErrorsImpl<IChangePasswordForm>>(
    {},
  );

  const [changePasswd, { isLoading }] = useChangePasswordMutation();

  const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStrength(checkPasswordStrength(e.target.value));
  };

  const onSubmit: SubmitHandler<IChangePasswordForm> = (formData) => {
    const errorObj: FieldErrorsImpl<IChangePasswordForm> = {};

    if (formData.password !== formData.confirmPassword) {
      errorObj.confirmPassword = {
        type: 'match',
        message: "The passwords don't match.",
      };
    }
    if (requiresMfa === false && !formData.confirmPassword.length) {
      errorObj.confirmPassword = {
        type: 'required',
        message: 'The old password is required.',
      };
    }

    if (Object.keys(errorObj).length > 0) {
      onError(errorObj);
      return;
    }

    if (credentialId) {
      changePasswd({
        credentialId,
        newPassword: formData.password,
        oldPassword: formData.oldPassword,
      }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          toast('A server error has occured. Please try again.', {
            type: 'error',
          });
          return;
        }

        onSuccess?.();
      });
    }
  };

  const onError: SubmitErrorHandler<IChangePasswordForm> = (errors) => {
    setErrors(errors);
  };

  const isButtonDisabled = useMemo(
    () => isLoading || ['too short', 'weak'].includes(strength),
    [strength, isLoading],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Typography variant="h5" textAlign="center">
        Create your new password
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color="blue.main"
        mx={2}
        mb={4}
      >
        Your new password must be different from any of the previous used
        passwords.
      </Typography>
      {requiresMfa === false && (
        <VInputPassword
          inputProps={{ ...register('oldPassword') }}
          label="Old Password"
          size="large"
          error={!!errors.oldPassword}
          helperText={errors.oldPassword?.message || undefined}
        />
      )}
      <VInputPassword
        inputProps={{ ...register('password') }}
        label="Password"
        size="large"
        onChange={handlePassChange}
        placeholder="Create your password"
        passwordStrength={strength}
        error={!!errors.password}
        helperText={errors.password?.message || undefined}
      />
      {['too short', 'weak'].includes(strength) && (
        <FormHelperText sx={{ px: 2, pb: 1 }}>
          The password must have a minimum of 8 characters and must contain at
          least 1 number, 1 uppercase and 1 lowercase letter.
        </FormHelperText>
      )}
      <VInputPassword
        inputProps={{ ...register('confirmPassword') }}
        label="Confirm Password"
        size="large"
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message || undefined}
      />
      <Box sx={{ p: 1, pt: 3 }}>
        <Button fullWidth type="submit" disabled={isButtonDisabled}>
          Change Password
        </Button>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
