import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import {
  remindersApi,
  useDuplicateReminderMutation,
} from 'store/api/reminders.api';

export const useCopyReminder = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();

  const lastCopiedId = useRef<number>(0);
  const [duplicate, { isSuccess, isError, data }] =
    useDuplicateReminderMutation();

  const handleCopy = (id: number) => {
    lastCopiedId.current = id;
    duplicate({ id });
  };

  useEffect(() => {
    if (isError) {
      toast.error('Could not copy the reminder.');
    }

    if (isSuccess) {
      toast.success('The reminder was copied successfully.');
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (data && profileId) {
      dispatch(
        remindersApi.util.updateQueryData(
          'getReminders',
          {
            patientProfileId: parseInt(profileId),
          },
          (result) => {
            const toCopy = result[
              data.result.display_group || 'uncategorized'
            ].find((alerter) => alerter.id === lastCopiedId.current);

            if (toCopy) {
              result[data.result.display_group || 'uncategorized'].push({
                ...toCopy,
                id: data.result.id,
                title: data.result.title,
              });
            }
          },
        ),
      );
    }
  }, [data]);

  return handleCopy;
};
