import { useProfileData } from 'hooks/use-profile-data';
import { routes } from 'nav';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAppbarTitle } from 'services/custom-appbar-title';
import { useAppSelector } from 'store';
import { useGetAlerterInfoQuery } from 'store/api/alerters.api';

export const useAlerterDetails = () => {
  const { alertId, profileId } = useParams();
  const navigate = useNavigate();

  useProfileData();

  const areSettingsModified = useAppSelector(
    (state) => state.alerter.editedAlerter.isModifiedSettings,
  );
  const { settings } = useAppSelector((state) => state.alerter);

  const { isError, isLoading, error } = useGetAlerterInfoQuery(Number(alertId));

  useEffect(() => {
    if (!isLoading && isError) {
      if ((error as { status: number } | undefined)?.status === 404) {
        navigate(routes.private.alerter.href(Number(profileId)));
        toast(`Alerter with the id ${alertId} wasn't found`, { type: 'error' });
        return;
      }
      toast(`An error occured. Try again.`, { type: 'error' });
    }
  }, [isError]);

  useEffect(() => {
    setAppbarTitle(settings.title ?? '');
  }, [settings.title]);

  return {
    areSettingsModified,
  };
};
