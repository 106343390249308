/* eslint-disable @typescript-eslint/ban-types */
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  checkboxClasses,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  TimeOption,
  timePickerOptions as options,
} from 'utils/get-timpicker-options';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: theme.spacing(15),
  fontSize: 16,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [`.${inputBaseClasses.root}`]: {
    maxHeight: theme.spacing(5),
    padding: theme.spacing(0),
    [`.${inputBaseClasses.input}`]: {
      padding: theme.spacing(1, 0, 1, 1.5),
      height: theme.spacing(3),
    },
  },
}));

interface Props {
  defaultRange?: [number | null, number | null];
  onStartChange?: (value?: number) => void;
  onEndChange?: (value?: number) => void;
  onOutsideRangeChange?: (value: boolean) => void;
  startTimeError?: string;
  endTimeError?: string;
  defaultIsOutsideRange?: boolean;
  readOnly?: boolean;
}

const TimeRangePicker = ({
  defaultRange,
  onStartChange,
  onEndChange,
  onOutsideRangeChange,
  defaultIsOutsideRange,
  readOnly,
}: Props) => {
  const [startHour, setStartHour] = useState<TimeOption | null>(
    (defaultRange && options.find((o) => o.value === defaultRange[0])) ?? {
      value: 0,
      label: '12:00am',
    },
  );
  const [endHour, setEndHour] = useState<TimeOption | null>(
    (defaultRange && options.find((o) => o.value === defaultRange[1])) ?? {
      value: 86400,
      label: '12:00am',
    },
  );

  const [isAllDayChecked, setIsAllDayChecked] = useState(false);

  const handleStartHourChange = (
    _: ChangeEvent<{}>,
    selectedOption: TimeOption | null,
  ) => {
    setStartHour(selectedOption);
    onStartChange?.(selectedOption?.value);
    if (selectedOption && selectedOption.value > (endHour?.value || 0)) {
      setEndHour(null);
      onEndChange?.();
    }
  };

  const handleEndHourChange = (
    _: ChangeEvent<{}>,
    selectedOption: TimeOption | null,
  ) => {
    onEndChange?.(selectedOption?.value);
    setEndHour(selectedOption);
  };

  const handleAllDayCheckboxChange = (checked: boolean) => {
    setIsAllDayChecked(checked);
    if (checked) {
      setStartHour({ value: 0, label: '12:00am' });
      onStartChange?.(0);
      setEndHour({ value: 86400, label: '12:00am' });
      onEndChange?.(86400);
      onOutsideRangeChange?.(false);
      return;
    }

    setStartHour({ value: 32400, label: '9:00am' });
    onStartChange?.(32400);
    setEndHour({ value: 64800, label: '6:00pm' });
    onEndChange?.(64800);
  };

  useEffect(() => {
    if (endHour?.value === 86400 && startHour?.value === 0) {
      handleAllDayCheckboxChange(true);
    }
  }, []);

  return (
    <div>
      <FormControlLabel
        onChange={(e, value) => handleAllDayCheckboxChange(value)}
        control={<Checkbox />}
        label="All day"
        checked={isAllDayChecked}
        disabled={readOnly}
        labelPlacement="end"
        sx={{
          [`.${checkboxClasses.root}`]: {
            paddingY: 0,
          },
          pb: 1.5,
        }}
      />
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Autocomplete
          disableClearable
          openOnFocus
          ListboxProps={{
            sx: { maxHeight: ({ spacing }) => spacing(20) },
          }}
          options={options}
          getOptionLabel={(option) => option.label}
          onChange={handleStartHourChange}
          value={startHour ?? undefined}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disabled={isAllDayChecked || readOnly}
          renderInput={(props) => (
            <StyledTextField
              multiline={false}
              {...props}
              inputProps={{ ...props.inputProps }}
            />
          )}
        />
        -
        <Autocomplete
          disableClearable
          openOnFocus
          ListboxProps={{ sx: { maxHeight: ({ spacing }) => spacing(20) } }}
          options={[
            ...options.filter(
              (option) => option.value > (startHour?.value || 0),
            ),
            { label: '12:00am', value: 86400 },
          ]}
          getOptionLabel={(option) => option.label}
          onChange={handleEndHourChange}
          value={endHour ?? undefined}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          disabled={isAllDayChecked || readOnly}
          renderInput={(props) => (
            <StyledTextField
              multiline={false}
              {...props}
              inputProps={{ ...props.inputProps }}
            />
          )}
        />
      </Box>

      <FormControlLabel
        control={<Checkbox defaultChecked={defaultIsOutsideRange} />}
        label="Outside range"
        labelPlacement="end"
        sx={{
          color: ({ palette }) => palette.primary.main,
          pl: ({ spacing }) => spacing(1.5),
        }}
        disabled={isAllDayChecked || readOnly}
        onChange={(e, value) => onOutsideRangeChange?.(value)}
      />
    </div>
  );
};

export default TimeRangePicker;
