import { Icon } from '@iconify/react';
import { Box, InputBaseProps, Typography, useTheme } from '@mui/material';
import VInput from 'components/VInput';
import * as icons from 'assets/iconify';

interface Props extends InputBaseProps {
  label?: string;
  focused?: boolean;
  labelPlacement?: 'top' | 'left';
  disabled?: boolean;
}

const Input = ({
  label,
  focused,
  labelPlacement = 'left',
  disabled,
  ...rest
}: Props) => {
  const { palette } = useTheme();

  return (
    <Box
      display="flex"
      sx={{
        pointerEvents: disabled ? 'none' : undefined,
        ...(labelPlacement === 'left'
          ? {
              alignItems: 'center',
            }
          : {
              flexDirection: 'column',
            }),
      }}
      onClick={rest.onClick}
    >
      {label && (
        <Typography
          color="primary"
          variant={labelPlacement === 'top' ? 'body1' : 'body2'}
          sx={{ p: labelPlacement === 'top' ? 0 : 1, pb: 1 }}
        >
          {label}
        </Typography>
      )}
      <VInput
        readOnly
        onClick={(e) => e.preventDefault()}
        width={164}
        placeholder="mm/dd/yyyy"
        disabled={disabled}
        disableWhitespace={labelPlacement === 'top'}
        endAdornment={
          <Icon
            color={palette.primary.main}
            icon={icons.calendar.icons.normal}
          />
        }
        {...rest}
        sx={{
          color: palette.primary.main + '!important',
          fontWeight: 500,
          borderColor: focused ? palette.primary.main : undefined,
          [`&.Mui-readOnly`]: {
            cursor: 'pointer',
            input: {
              cursor: 'pointer',
            },
          },
          ...(rest.sx ?? {}),
        }}
      />
    </Box>
  );
};

export default Input;
