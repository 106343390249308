import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { setPagination } from 'store/reducers/filters/filters.slice';

export const usePagination = (
  defaultPageLimit: number,
  defaultPageIndex: number,
) => {
  // pageIndex = pageNr - 1
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);
  const [limit, setLimit] = useState(defaultPageLimit);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setPagination({
        limit: defaultPageLimit,
        page: defaultPageIndex,
      }),
    );
  }, []);

  const handlePaginationModelChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    setPageIndex(page);
    setLimit(pageSize);
    dispatch(setPagination({ page, limit: pageSize }));
  };

  return {
    limit,
    pageIndex,
    offset: limit * pageIndex,
    handlePaginationModelChange,
  };
};
