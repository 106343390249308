const imageFileExtensions: { [key: string]: string[] } = {
  image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
};

export function isFileTypeImage(fileName: string, fileType: string): boolean {
  const extension = fileName?.split('.').pop();
  return (
    (!!fileName &&
      !!extension &&
      imageFileExtensions[fileType]?.includes(extension)) ??
    false
  );
}
