import { AlertCard, AlertCardProps, Summary } from 'components/_extra';
import { useCardControls } from '../hooks/use-card-controls';
import React from 'react';
import { TriggerGroup } from 'utils/triggers';
import { AlerterRecipientManagerSummary } from 'types/alerters';

interface Props extends AlertCardProps {
  id: number;
  recipientManagerName: string;
  recipientManagers: AlerterRecipientManagerSummary[];
  triggerSummaryFragments: string[];
  isActive?: boolean;
  group: TriggerGroup;
}

const Card = ({
  id,
  title,
  recipientManagerName,
  isActive,
  onCopy,
  group,
  triggerSummaryFragments,
  recipientManagers,
}: Props) => {
  const { handleEditAlert, handleSwitch, handleRemove } = useCardControls(
    id,
    group,
  );

  return (
    <AlertCard
      recipientManagers={recipientManagers}
      isActive={isActive}
      title={title}
      onEdit={() => handleEditAlert(title)}
      onRemove={() => handleRemove(id, title)}
      onCopy={onCopy}
      isReminder
      onSwitchToggle={(e, checked) => handleSwitch(checked)}
    >
      <Summary
        title="Conditions"
        noWrap
        details={
          <>
            {triggerSummaryFragments.length === 0
              ? '-'
              : triggerSummaryFragments.map((fragment, index) => (
                  <React.Fragment key={index}>
                    {fragment}{' '}
                    {index !== triggerSummaryFragments.length && <br />}
                  </React.Fragment>
                ))}
          </>
        }
      />
      <Summary
        title="Recipient Sets"
        noWrap
        details={recipientManagerName.length ? recipientManagerName : '-'}
      />
    </AlertCard>
  );
};

export default Card;
