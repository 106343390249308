import styled from '@emotion/styled';

import {
  CircularProgress as MuiCircularProgress,
  css,
  Typography,
} from '@mui/material';

import theme from 'styles/theme';

import pxToEm from 'utils/px-to-em';

type VButtonProps = {
  bgcolor?: string;
  borderRadius?: number;
  width?: number | string;
  borderColor?: string;
  alignSelf?:
    | 'auto'
    | 'baseline'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'unset'
    | 'strech'
    | 'inherit';
  margin?: string;
};

type ButtonTextProps = {
  fontWeight?: number;
  color?: string;
};

export const VButton = styled.button<VButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${pxToEm(8)};
  word-wrap: normal;
  border-radius: ${(props) =>
    props.borderRadius ? pxToEm(props.borderRadius) : pxToEm(6)};
  background-color: ${({ bgcolor }) => bgcolor || 'transparent'};
  width: ${(props) => pxToEm(props.width)};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor} ` : 'none'};
  ${(props) =>
    props.margin &&
    css`
      margin: ${pxToEm(props.margin)} 0;
    `};
  cursor: pointer;
  font-size: ${pxToEm(14)};
  padding: ${pxToEm(15)} ${pxToEm(8)};
  font-family: inherit;

  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `};

  &:disabled {
    cursor: not-allowed;
    filter: brightness(110%) opacity(90%);
  }
`;

export const ButtonText = styled(Typography)<ButtonTextProps>`
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) => props.color || theme.palette.white.main};
`;

export const CircularProgress = styled(MuiCircularProgress)`
  margin-right: 2px;

  .MuiCircularProgress-svg {
    color: whitesmoke;
  }
`;
