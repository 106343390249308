import { Divider, Typography, alpha, styled } from '@mui/material';
import React from 'react';
import { AlerterEvent } from 'types/alerters';
import { formatMetricValue, getRuleLabel } from 'utils/triggers';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.04),
  paddingLeft: theme.spacing(8),
  [theme.breakpoints.down('newMd')]: {
    paddingLeft: theme.spacing(1.5),
  },
}));

const MetricRuleDetails = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  padding: theme.spacing(0, 1),
}));

const AlerterEventDetailPanel = ({ row }: { row: AlerterEvent }) => {
  return (
    <Root>
      <MetricRuleDetails>
        <Typography px={1.5} py={2} variant="subtitle2" color="text.primary">
          Condition Type
        </Typography>
        <Typography px={1.5} py={2} variant="subtitle2" color="text.primary">
          Rule
        </Typography>
        <Typography px={1.5} py={2} variant="subtitle2" color="text.primary">
          Value
        </Typography>
        {row.metricRulesTriggers.map(
          ({ triggeredValue, metricType, comparator }) => {
            const { unit, value: metricValue } = formatMetricValue(
              triggeredValue,
              metricType,
              comparator,
              true,
            );
            return (
              <React.Fragment key={metricType}>
                <Divider sx={{ gridColumn: '1 / 4' }}></Divider>
                <Typography px={1.5} py={1} textTransform="capitalize">
                  {metricType.replaceAll('_', ' ')}
                </Typography>
                <Typography px={1.5} py={1}>
                  {getRuleLabel(comparator)}
                </Typography>
                <Typography px={1.5} py={1}>
                  {metricValue} {unit}
                </Typography>
              </React.Fragment>
            );
          },
        )}
        <Typography></Typography>
      </MetricRuleDetails>
    </Root>
  );
};

export default AlerterEventDetailPanel;
