import { list } from 'assets/iconify';
import TriggerCard from './TriggerCard';
import { TransparentBackdrop } from './styled';
import { Box, Skeleton } from '@mui/material';
import { EmptyList } from 'components/_extra/EmptyList';
import { useTriggerList } from 'pages/AlerterDetails/hooks/use-trigger-list';
import { getSideBarState } from 'services/sidebar';
import AddTriggerButton from './AddTriggerButton';

const TriggerList = () => {
  const isSidebarOpen = getSideBarState();
  const { handleBackdropClick, editedRule, rules, isLoading, functionsRef } =
    useTriggerList();

  return (
    <Box
      display="grid"
      gap={{ xxs: 2, sm: 3 }}
      width="100%"
      gridTemplateColumns={
        rules.length === 0 && !isLoading
          ? '1fr'
          : {
              xxs: '1fr',
              newLg: '1fr 1fr',
              newXl: `repeat(${isSidebarOpen ? 1 : 2}, 1fr)`,
              new2Xl: `repeat(${isSidebarOpen ? 2 : 3}, 1fr)`,
              new3Xl: `repeat(${isSidebarOpen ? 3 : 4}, 1fr)`,
              new4Xl: `repeat(4, 1fr)`,
            }
      }
      pb={1}
    >
      {isLoading ? (
        <>
          <Skeleton variant="rounded" height={264} width="100%" />
          <Skeleton variant="rounded" height={264} width="100%" />
        </>
      ) : rules.length > 0 ? (
        rules.map(({ title, id, comparator, metricType, enabled, value }) => (
          <TriggerCard
            id={id}
            key={id}
            title={title}
            comparator={comparator}
            metricType={metricType}
            enabled={enabled}
            value={value}
            functionsRef={functionsRef}
            onReset={functionsRef.current?.reset}
            sx={({ zIndex }) => ({
              zIndex: editedRule?.id === id ? zIndex.modal : 'auto',
            })}
          />
        ))
      ) : (
        <EmptyList
          icon={list.icons.normal}
          title="The condition list is empty"
          message="Add at least one condition that will cause this alert to send a notification. Conditions can be combined to create more refined alerts for your needs. For example, combine a heart rate condition with a safe zone condition to only send heart rate alerts to caregivers when an individual is away from home."
        >
          <AddTriggerButton />
        </EmptyList>
      )}
      <TransparentBackdrop open={!!editedRule} onClick={handleBackdropClick} />
    </Box>
  );
};

export default TriggerList;
