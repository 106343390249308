import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  styled,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import ReactJson from 'react-json-view';
import theme from 'styles/theme';
import VCheckBox from 'components/VCheckBox/VCheckBox';
import VInput from 'components/VInput';
import { VInputProps } from 'components/VInput/VInput';

const LoadingOverlayWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 70%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  z-index: 1000;
`;

export const PageTitle = styled(Typography)``;

export const Wrapper = styled(Box)`
  margin-top: 30px;
  width: 100%;
  padding: 30px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const TimePickerWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 10,

  [theme.breakpoints.down(theme.breakpoints.values['md'] + 1)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const TimeInputWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 4px',

  [theme.breakpoints.down(theme.breakpoints.values['md'] + 1)]: {
    padding: '0px',
  },
}));

export const AccountInfoGridItem: React.FC<React.PropsWithChildren> = (
  props,
) => (
  <Grid item container md={12} lg={6}>
    <Grid item container lg={12} xl={8} alignItems="center">
      {props.children}
      <Divider color="gray" style={{ width: '100%' }} />
    </Grid>
  </Grid>
);

export const RecordsInfoGridItem: React.FC<React.PropsWithChildren> = (
  props,
) => (
  <Grid item container md={12} lg={6}>
    <Grid item container lg={12} xl={10} alignItems="center">
      {props.children}
      <Divider color="gray" style={{ width: '100%' }} />
    </Grid>
  </Grid>
);

export const RecordsInfoCategory: React.FC<React.PropsWithChildren> = (
  props,
) => (
  <Grid item container xs={12} marginBottom={1}>
    <Typography variant="h6">{props.children}</Typography>
  </Grid>
);

export const LoadingOverlay: React.FC<{ isLoading?: boolean }> = (props) =>
  props.isLoading ? (
    <LoadingOverlayWrapper>
      <CircularProgress />
    </LoadingOverlayWrapper>
  ) : null;

export const JsonWrapper: React.FC<{
  src: Record<string, unknown> | unknown[];
}> = (props) => (
  <Box maxHeight={120} overflow="auto">
    <ReactJson displayDataTypes={false} src={props.src} />
  </Box>
);

export const Checkbox = styled(VCheckBox)`
  margin: 12px 0px;
`;

export const Input = (props: VInputProps) => (
  <VInput {...props} containerSx={{}} style={{ maxHeight: 40 }} />
);

export const TextField = styled(MuiTextField)(({ theme }) => ({
  height: theme.spacing(5),
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiInputBase-root': {
    border: 0,
  },
}));
