import {
  IPopOvFetchResponse,
  IPopOvFetchRequest,
} from 'models/population-overview.model';
import { bcApi } from './_api';
import qs from 'qs';
import { getLastHeartAck } from 'utils/datetime-tools';
import { PatientData } from 'types/patient';
import { transformPatientData } from 'utils/transform-patient-data';

interface PopOvData {
  rows: PatientData[];
  totalNumber: number;
  displayedNumber: number;
}

export const popOvApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchData: builder.query<PopOvData, IPopOvFetchRequest>({
      query: (data) => ({
        url: '/records/synthesized/search',
        method: 'GET',
        params: data,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { encode: false, arrayFormat: 'brackets' }),
        },
      }),
      transformResponse: (response: IPopOvFetchResponse) => {
        const meta = Object.values(response.meta.accounts).map((item) => ({
          id: item.id,
          name: `${item.first_name ?? ''} ${item.last_name ?? ''}`.trimEnd(),
          identifier: item.id,
          lastHeartAck: getLastHeartAck(
            item.last_irregular_heart_ack,
            item.last_low_heart_ack,
            item.last_high_heart_ack,
          ),
        }));

        return {
          totalNumber: response.meta.total_connected_profiles,
          displayedNumber: response.meta.total_displayed_results,
          rows: response.results.map((item, index) => {
            const { id, name, ...rest } = meta[index];

            return {
              ...rest,
              ...transformPatientData({
                id,
                accountId: item.account_id,
                deviceId: item.device_id,
                name,
                healthRecord: item.health_record,
                settingsRecord: item.settings_record,
                usageRecord: item.usage_record,
                deviceInfoRecord: item.device_info_record,
                helpRequest: item.last_help_requested_event,
                seizureDetected: item.last_seizure_detection_event,
              }),
            };
          }),
        };
      },
    }),
  }),
});

export const { useFetchDataQuery, useLazyFetchDataQuery } = popOvApi;
