import { Palette } from '@mui/material';
import { DataStatus } from 'types/enum/data-status.enum';

export const getColorByStatus = (
  status: DataStatus,
  palette: Palette,
  hasAlert?: boolean,
) => {
  if (!hasAlert && status !== DataStatus.DISABLED) {
    return {
      iconColor: palette.primary.main,
      textColor: palette.primary.main,
    };
  }

  if (status === 'positive') {
    return {
      iconColor: palette.secondary.main,
      textColor: palette.secondary.main,
    };
  }

  if (status === 'negative') {
    return {
      iconColor: palette.red.main,
      textColor: palette.red.main,
    };
  }

  return {
    iconColor: palette.blue.light,
    textColor: palette.primary.main,
  };
};
