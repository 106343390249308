import { CustomRecord } from 'models/dashboard.model';
import { PatientRecord } from 'types/patient';
import { postgresAgeToMin } from './postgres-age-to-min';

export const transformCustomRecords = (
  response: CustomRecord,
  id: number,
): PatientRecord => {
  const {
    timestamp,
    lat,
    lng,
    any_device_active,
    configuration_ok,
    lo_in_zone,
    device_worn,
    battery_level,
    battery_state,
    blood_oxygen_level,
    device_locked,
    fall,
    heart_rate,
    high_heart_rate_event,
    irregular_heart_rhythm_event,
    low_heart_rate_event,
    sleep_analysis,
  } = response;

  return {
    id: timestamp + id,
    timestamp,
    coordinates: {
      lat,
      lng,
    },
    isAnyDeviceActive: any_device_active,
    isConfigurationOk: configuration_ok,
    isInSafeZone: lo_in_zone,
    deviceWorn: device_worn,
    deviceLocked: device_locked,
    batteryLevel: battery_level
      ? Math.round(Number(battery_level) * 100)
      : null,
    batteryState: battery_state,
    fallDetection: !!fall,
    bloodOxygen: blood_oxygen_level
      ? `${Math.round(Number(blood_oxygen_level) * 100)}%`
      : null,
    heartRate: heart_rate ? Math.round(Number(heart_rate)) : null,
    highHeartRate: !!high_heart_rate_event,
    lowHeartRate: !!low_heart_rate_event,
    irregulatHeartRhythm: !!irregular_heart_rhythm_event,
    dailySleepTime: sleep_analysis ? postgresAgeToMin(sleep_analysis) : null,
  };
};
