import { Typography } from '@mui/material';
import HealthData from './HealthData';
import LocationData from './LocationData';

const DataSettings = () => {
  return (
    <div>
      <Typography color="primary" fontWeight={600}>
        Data Settings
      </Typography>
      <LocationData />
      <HealthData />
    </div>
  );
};

export default DataSettings;
