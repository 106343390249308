/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, CircularProgress, Paper } from '@mui/material';
import { Account, Notifications } from './components';
import { useAccountData } from './hooks/use-account-data';
import { createContext } from 'react';

import { useSaveChanges } from './hooks/use-save-changes';
import { IAccountRequest } from 'models/account.model';
import Security from './components/Security';
import { PageContent } from 'components/_extra/generic';

export type FieldName = keyof IAccountRequest;
export type FieldValue = number | string | boolean;

export const CaregiverSettingsContext = createContext<{
  setIsSaveActive: (value: boolean) => void;
  setAreControlsDisabled: (value: boolean) => void;
  setSaveChannelSet: (f: () => void) => void;
  setDiscardChanges: (f: () => void) => void;
  handlePropertyChange: (fieldName: FieldName, value: FieldValue) => void;
}>({
  setIsSaveActive: () => {},
  setAreControlsDisabled: () => {},
  setSaveChannelSet: () => {},
  handlePropertyChange: () => {},
  setDiscardChanges: () => {},
});

const CaregiverSettings = () => {
  const { accountData, isLoading } = useAccountData();
  const {
    handleDiscardClick,
    handleSaveClick,
    isSaveActive,
    setIsSaveActive,
    setSaveChannelSet,
    handlePropertyChange,
    areControlsDisabled,
    setAreControlsDisabled,
    setDiscardChanges,
    isDiscardDisabled,
  } = useSaveChanges(accountData?.result.id);

  return (
    <PageContent>
      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" py={10}>
            <CircularProgress />
          </Box>
        ) : (
          <CaregiverSettingsContext.Provider
            value={{
              setIsSaveActive,
              handlePropertyChange,
              setSaveChannelSet,
              setAreControlsDisabled,
              setDiscardChanges,
            }}
          >
            <Box
              flexGrow={1}
              overflow="auto"
              sx={{
                paddingX: { xxs: 1.5, xs: 2, sm: 3, newMd: 4 },
                paddingTop: { xxs: 2, sm: 3, newMd: 4 },
              }}
            >
              <Box
                maxWidth={({ spacing }) => spacing(72)}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <Account
                  defaultFirstName={accountData?.result.first_name}
                  defaultLastName={accountData?.result.last_name}
                  defaultPhoneNumber={
                    accountData?.result.preferred_phone_number
                  }
                  phoneNumberCredentialId={
                    accountData?.result.preferred_phone_number_credential_id
                  }
                />
                <Notifications
                  channelSetId={accountData?.result.alerter_channel_set_id}
                  isMute={accountData?.result.mute_all_alerters}
                />
                <Security />
              </Box>
            </Box>
            <Box
              display="flex"
              pt={2}
              gap={2}
              pr={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
              pb={{ xxs: 2, sm: 3, newMd: 4 }}
              justifyContent="flex-end"
            >
              <Button
                disabled={isDiscardDisabled}
                onClick={handleDiscardClick}
                color="primary"
                variant="outlined"
              >
                Discard
              </Button>
              <Button
                disabled={!isSaveActive || areControlsDisabled}
                color="primary"
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Box>
          </CaregiverSettingsContext.Provider>
        )}
      </Paper>
    </PageContent>
  );
};

export default CaregiverSettings;
