import { Skeleton } from '@mui/material';
import Card from './Card';
import { getDaysSummary } from 'utils/weekdays';
import { RecipientManagerModal } from '../RecipientManagerModal';
import { useEditRecipientsSetModal } from '../../hooks/use-edit-recipients-set-modal';
import { parseSecondsToString } from 'utils/time-format';
import { useRecipientSetSwitch } from 'pages/AlerterDetails/hooks/use-recipient-set-switch';
import { GridWrapper } from 'components/_extra/generic';
import { useCopyRecipientManager } from 'hooks/use-copy-recipient-manager';
import { useRecipientSets } from 'hooks/use-recipient-sets';
import { EmptyList } from 'components/_extra/EmptyList';
import { reduceCapacity } from 'assets/iconify';
import CreateRecipientSetButton from 'pages/Alerters/components/RecipientSets/CreateRecipientSetButton';

const RecipientsSets = () => {
  const { sets, isLoading, activeSetsId } = useRecipientSets();
  const {
    handleClick: handleEdit,
    isOpen,
    handleClose,
    onClose,
    selectedIndex,
    functionRef,
    isRecipientManagerLoading,
  } = useEditRecipientsSetModal();
  const handleSwitchToggle = useRecipientSetSwitch();
  const { handleCopy } = useCopyRecipientManager();

  return (
    <GridWrapper
      sx={{
        gap: 3,
        gridTemplateColumns:
          sets.length === 0 && !isLoading ? '100%' : undefined,
      }}
    >
      {isLoading ? (
        <>
          <Skeleton variant="rounded" height={136} />
          <Skeleton variant="rounded" height={136} />
        </>
      ) : sets.length > 0 ? (
        sets.map(
          (
            {
              name,
              days,
              startTime,
              endTime,
              id,
              recipientsCount,
              patientsCount,
              alerters,
            },
            index,
          ) => (
            <Card
              key={id}
              id={id}
              title={name ?? 'Untitled'}
              hours={
                startTime && endTime
                  ? `${parseSecondsToString(
                      startTime,
                    )} to ${parseSecondsToString(endTime)}`
                  : undefined
              }
              days={getDaysSummary(days)}
              recipientsCount={recipientsCount}
              patientsCount={patientsCount}
              isActive={activeSetsId?.includes(id)}
              alerters={alerters?.map(({ name }) => name ?? 'Untitled')}
              onSwitchToggle={handleSwitchToggle}
              onCopy={() => handleCopy(id)}
              onEdit={() => handleEdit(index)}
            />
          ),
        )
      ) : (
        <EmptyList
          title="Start adding recipient sets"
          message={
            <>
              Use recipient sets to group the people who will receive
              notifications from <strong>BoundaryCare</strong> alerts, to set
              schedules and shifts, and to develop alerting hierarchies. Create
              a few recipient sets and mix and match them with alerts as needed.
            </>
          }
          icon={reduceCapacity.icons.normal}
        >
          <CreateRecipientSetButton />
        </EmptyList>
      )}
      {selectedIndex !== null && (
        <RecipientManagerModal
          isOpen={isOpen}
          onClose={onClose}
          handleClose={handleClose}
          index={selectedIndex}
          submitFormRef={functionRef}
          isLoading={isRecipientManagerLoading}
        />
      )}
      {isLoading && <Skeleton variant="rounded" height={136} />}
    </GridWrapper>
  );
};

export default RecipientsSets;
