import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const edit: IconifyJSON = {
  prefix: 'edit',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M3.50003 20.5V17.1154L16.6904 3.93081C16.8416 3.79346 17.0086 3.68732 17.1913 3.61241C17.374 3.53749 17.5657 3.50003 17.7662 3.50003C17.9666 3.50003 18.1609 3.53561 18.3488 3.60678C18.5368 3.67793 18.7032 3.79106 18.8481 3.94618L20.0692 5.18271C20.2243 5.32757 20.3349 5.49427 20.4009 5.68281C20.467 5.87132 20.5 6.05984 20.5 6.24836C20.5 6.44944 20.4656 6.64134 20.397 6.82406C20.3283 7.00679 20.219 7.17377 20.0692 7.32498L6.88461 20.5H3.50003ZM17.5519 7.69423L19 6.25576L17.7443 5.00001L16.3058 6.44808L17.5519 7.69423Z"  fill="currentColor"/>',
    },
  },
};
