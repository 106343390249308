import { Box, Button, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useDeliverCodeMutation,
  useValidateCodeMutation,
} from 'store/api/auth.api';
import { resetChosenMethod, toggleMfa } from 'store/reducers/mfa/mfa.slice';
import CodeEnterMessage from './CodeEnterMessage';
import CodeInput from 'pages/Mfa/components/CodeInput';

interface Props {
  onCompletion?: () => void;
  includeCredentialId?: boolean;
  activateMfa?: boolean;
}

const CodeEnter = ({
  onCompletion,
  includeCredentialId,
  activateMfa,
}: Props) => {
  const dispatch = useAppDispatch();

  const [code, setCode] = useState<string | null>(null);
  const [resendSecondsLeft, setResendSecondsLeft] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const credentialId = useAppSelector((state) => state.user.credentialId);
  const codeRequestedAt = useAppSelector((state) => state.mfa.codeRequestedAt);
  const chosenMethod = useAppSelector((state) =>
    state.mfa.availableMethods.find(
      (item) => item.id === state.mfa.chosenMethodId,
    ),
  );

  const [validateCode, { isLoading }] = useValidateCodeMutation();
  const [deliverCode, { isLoading: isDeliverLoading }] =
    useDeliverCodeMutation();

  useEffect(() => {
    setTimeout(() => {
      if (resendSecondsLeft > 0) {
        setResendSecondsLeft((prev) => prev - 1);
      }
    }, 1000);
  }, [resendSecondsLeft]);

  useEffect(() => {
    if (codeRequestedAt) {
      const seconds = dayjs(codeRequestedAt)
        .add(60, 'seconds')
        .diff(dayjs(), 'seconds');

      setResendSecondsLeft(seconds);
    }
  }, [codeRequestedAt]);

  const handleInputCompletion = (value: string) => {
    setCode(value);
    setError(null);
  };
  const handleInputReset = () => {
    setCode(null);
  };

  const handleResetMethod = () => {
    dispatch(resetChosenMethod());
  };
  const handleCodeResend = () => {
    if (credentialId) {
      deliverCode({ id: credentialId });
    }
  };
  const handleSubmit = () => {
    if (code && chosenMethod) {
      validateCode({
        code,
        identifier: chosenMethod?.credential,
        ...(includeCredentialId &&
          credentialId && { credentialIdToReset: credentialId }),
        activateMfa,
      }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          setError(
            'Make sure you typed your code correctly. If your code has expired resend a new one.',
          );
          toast('A server error has occured. Please try again.', {
            type: 'error',
          });
          return;
        }

        dispatch(toggleMfa(true));
        onCompletion?.();
      });
    }
  };

  const canResend = resendSecondsLeft <= 0;
  const isDisabled = !code || isLoading || isDeliverLoading;

  return chosenMethod ? (
    <Box>
      <CodeEnterMessage
        type={chosenMethod.type}
        credential={chosenMethod.credential}
      />
      <CodeInput
        onComplete={handleInputCompletion}
        onRemoving={handleInputReset}
        onSubmit={handleSubmit}
        error={
          error
            ? {
                type: 'validate',
                message: error,
              }
            : undefined
        }
      />
      <Typography variant="body2" align="center">
        It may take a minute to receive your code. <br />
        Didn&apos;t receive the code? {}
        {canResend ? (
          <Link
            sx={{ color: ({ palette }) => palette.red.main }}
            onClick={handleCodeResend}
          >
            Resend it
          </Link>
        ) : (
          `Resend it in ${resendSecondsLeft} seconds.`
        )}
      </Typography>
      <Typography variant="body2" align="center" mt={2}>
        <Link
          sx={{ color: ({ palette }) => palette.secondary.main }}
          onClick={handleResetMethod}
          fontWeight={600}
        >
          Try another method
        </Link>
      </Typography>
      <Button
        size="large"
        sx={{ mt: 3 }}
        fullWidth
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        Submit
      </Button>
    </Box>
  ) : null;
};

export default CodeEnter;
