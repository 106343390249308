import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  Filters,
  FilterAppleWatchBatteryStatus,
  FilterAppleWatchBloodOxygenStatus,
  FilterAppleWatchDeviceStatus,
  FilterAppleWatchFallDetectionStatus,
  FilterAppleWatchHeartRateStatus,
  FilterAppleWatchSafeZoneStatus,
} from 'store/reducers/filters/types';

const getSelected = (
  fieldName: keyof Filters['appleWatch'],
  state: RootState,
) => state.filters.filters.appleWatch[fieldName].selected;

const transformToParamString = (values: string[]) =>
  values.length === 0 ? undefined : values.join('|');

const HEART_RATE_STATUSES = {
  low: 'active_low_heart_rate_alert',
  high: 'active_high_heart_rate_alert',
  irregular: 'active_irregular_heart_rhythm_alert',
  disabled: 'disabled',
};
const getHeartRateStatuses = (statuses: FilterAppleWatchHeartRateStatus[]) =>
  statuses.map((status) => HEART_RATE_STATUSES[status]);

export default createDraftSafeSelector(
  (state: RootState) => state.filters.searchPhrase,
  (state: RootState) =>
    getSelected('battery', state) as FilterAppleWatchBatteryStatus[],
  (state: RootState) =>
    getSelected('bloodOxygen', state) as FilterAppleWatchBloodOxygenStatus[],
  (state: RootState) =>
    getSelected('device', state) as FilterAppleWatchDeviceStatus[],
  (state: RootState) =>
    getSelected(
      'fallDetection',
      state,
    ) as FilterAppleWatchFallDetectionStatus[],
  (state: RootState) =>
    getSelected('heartRate', state) as FilterAppleWatchHeartRateStatus[],
  (state: RootState) =>
    getSelected('heartRhythm', state) as FilterAppleWatchHeartRateStatus[],
  (state: RootState) =>
    getSelected('safeZone', state) as FilterAppleWatchSafeZoneStatus[],
  (state: RootState) =>
    getSelected(
      'helpRequested',
      state,
    ) as FilterAppleWatchFallDetectionStatus[],
  (state: RootState) =>
    getSelected(
      'seizureDetected',
      state,
    ) as FilterAppleWatchFallDetectionStatus[],
  (
    searchPhrase,
    battery,
    bloodOxygen,
    device,
    fallDetection,
    heartRate,
    heartRhythm,
    safeZone,
    helpRequested,
    seizureDetected,
  ) => {
    return {
      identifier_string: searchPhrase,
      battery_level: transformToParamString(battery),
      blood_oxygen: transformToParamString(bloodOxygen),
      device_status: transformToParamString(device),
      active_fall_alert:
        fallDetection.length === 1
          ? fallDetection.includes('detected')
          : undefined,
      heart_rate: transformToParamString(
        getHeartRateStatuses([...heartRate, ...heartRhythm]),
      ),
      safe_zone: transformToParamString(safeZone),
      active_help_requested_alert:
        helpRequested.length === 1
          ? helpRequested.includes('detected')
          : undefined,
      active_seizure_detection_alert:
        seizureDetected.length === 1
          ? seizureDetected.includes('detected')
          : undefined,
    };
  },
);
