import {
  selectClasses,
  styled,
  tablePaginationClasses,
  tableRowClasses,
} from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

export const StyledDataGrid = styled(DataGridPro)(
  ({ theme: { spacing, palette, shadows, transitions, breakpoints } }) => ({
    border: 'none',
    position: 'relative',
    height: 'inherit !important',
    [`.${gridClasses.main}`]: {
      overflowY: 'auto',
    },
    [`.${gridClasses.columnHeaders}`]: {
      borderRadius: 0,
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: palette.blue.dark,
      backgroundColor: '#dee2e9',
      [`.${gridClasses.columnHeader}`]: {
        backgroundColor: '#dee2e9',
        '&:last-of-type': {
          paddingRight: spacing(2.5),
        },
      },
      [`.${gridClasses.columnHeaderTitle}`]: {
        fontWeight: 600,
      },
    },
    [`.${tableRowClasses.hover}`]: {
      backgroundColor: palette.lightBlue.light,
    },

    [`.${gridClasses.pinnedColumns}, .${gridClasses.pinnedColumnHeaders}`]: {
      transition: `boxShadow ${transitions.duration.complex}`,
      boxShadow: 'none',
      '&.scrolled': {
        boxShadow: shadows[3],
      },
    },

    '.fit-content': {
      minWidth: 'fit-content',
    },

    '.MuiDataGrid-selectedRowCount	': {
      width: 'auto',
    },

    '.MuiDataGrid-columnSeparator': {
      visibility: 'hidden !important',
    },

    [`.${gridClasses.row}:not(:last-of-type)`]: {
      borderBottom: `1px solid ${palette.gray.light}`,
    },

    '.MuiDataGrid-cell': {
      padding: spacing(1.5),
      zIndex: 1,
      borderColor: 'transparent',
      borderBottom: 'none',
      '&.capitalized': {
        textTransform: 'capitalize',
      },
      ':focus': {
        outline: 'none',
      },
      ':focus-within': {
        outline: 'none',
      },
      ':active': {
        outline: 'none',
      },
      ':first-of-type': {
        paddingLeft: spacing(2.5),
      },
      ':last-of-type': {
        paddingRight: spacing(2.5),
      },
    },

    '.MuiDataGrid-columnHeader': {
      padding: spacing(2, 1.5),
      ':focus': {
        outline: 'none',
      },
      ':first-of-type': {
        paddingLeft: spacing(2.5),
      },
      ':last-of-type': {
        paddingRight: spacing(2.5),
      },
    },

    [`.${gridClasses.footerContainer}`]: {
      borderColor: palette.gray.light,
    },

    [`.${gridClasses.rowCount}`]: {
      color: palette.primary.main,
    },

    '.MuiTablePagination': {
      '&-root': {
        color: palette.primary.dark,
        padding: spacing(0.75, 2) + '!important',
        '.MuiInputBase-root': {
          border: 'none',
          padding: spacing(0, 0, 0, 1),
          margin: 0,
          width: 'min-content',
          '.MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
          '.MuiSvgIcon-root': {
            color: palette.blue.dark,
          },
        },

        '.MuiToolbar-root': {
          minHeight: 'auto',
        },

        '.MuiTablePagination-displayedRows': {
          marginLeft: spacing(2),
        },
        [breakpoints.down('newMd')]: {
          padding: spacing(0.75, 1) + '!important',
        },
      },

      '&-actions': {
        marginLeft: spacing(2),
        '.Mui-disabled': {
          color: palette.blue.light,
        },
      },
    },
    [breakpoints.down('newMd')]: {
      [`.${tablePaginationClasses.selectLabel}`]: {
        display: 'none',
      },
      [`.${tablePaginationClasses.root}`]: {
        padding: spacing(0, 0.5) + ' !important',
      },
    },
    [breakpoints.down('sm')]: {
      [`.${selectClasses.icon}`]: {
        display: 'none',
      },
      [`.${tablePaginationClasses.select}`]: {
        display: 'none',
      },
    },
  }),
);
