import { RecipientManagerCard, Summary } from 'components/_extra';
import SharedAlertersDetails from 'components/_extra/Alerter/Cards/SharedAlertersDetails';

interface Props {
  id: number;
  title: string;
  isActive?: boolean;
  recipientsCount?: number;
  patientsCount?: number;
  alerters?: string[];
  days: string;
  hours?: string;
  disableCopy?: boolean;
  onEdit?: () => void;
  onCopy?: () => void;
  onSwitchToggle?: (checked: boolean, id: number) => void;
}

const Card = ({
  id,
  title,
  isActive,
  recipientsCount = 0,
  patientsCount = 0,
  days,
  alerters = [],
  hours,
  onEdit,
  onCopy,
  onSwitchToggle,
}: Props) => {
  return (
    <RecipientManagerCard
      title={title}
      isActive={isActive}
      onCopy={onCopy}
      onEdit={onEdit}
      hideDelete
      onSwitchToggle={(checked) => onSwitchToggle?.(checked, id)}
    >
      <Summary
        title="Schedule"
        details={
          <>
            {hours}
            {!!hours && <br />}
            {days}
          </>
        }
      />
      <Summary
        title="Recipients"
        details={
          <>
            {recipientsCount} Caregiver{recipientsCount !== 1 && 's'}
            {patientsCount > 0 ? (
              <>
                <br />
                {patientsCount} Patient{patientsCount !== 1 && 's'}
              </>
            ) : null}
          </>
        }
      />

      <Summary
        title="Used by"
        details={<SharedAlertersDetails alerters={alerters} />}
      />
    </RecipientManagerCard>
  );
};
export default Card;
