import { fallDetection } from 'assets/iconify/fall-detection';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';
import { helpRequested } from 'assets/iconify';

export const getFallDetectionStatus = (
  hasUnreviewedFall: boolean | undefined,
): DataCellProps => {
  if (hasUnreviewedFall === true) {
    return {
      icon: fallDetection.icons.detected,
      status: DataStatus.NEGATIVE,
      label: 'Detected',
    };
  }

  if (hasUnreviewedFall === false) {
    return {
      icon: helpRequested.icons.normal,
      status: DataStatus.POSITIVE,
      label: 'None',
    };
  }

  return {
    icon: fallDetection.icons.detected,
    status: DataStatus.NEGATIVE,
    label: 'Disabled',
  };
};
