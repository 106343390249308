import { useConfirmation } from 'hooks/use-confirmation';
import { routes } from 'nav';
import { ChangeEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAppbarTitle } from 'services/custom-appbar-title';
import { useAppDispatch } from 'store';
import {
  alertersApi,
  useDeleteAlerterMutation,
  useUpdateAlerterMutation,
} from 'store/api/alerters.api';
import { TriggerGroup } from 'utils/triggers';

export const useAlerterControls = (
  id: number,
  group: TriggerGroup,
  title?: string,
) => {
  const { profileId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const [
    deleteAlerter,
    {
      isSuccess: wasDeleted,
      isLoading: isDeletionLoading,
      isError: deletionFailed,
    },
  ] = useDeleteAlerterMutation();
  const [
    editAlerter,
    {
      isError: updateFailed,
      isSuccess: wasUpdated,
      isLoading: isUpdating,
      data,
    },
  ] = useUpdateAlerterMutation();

  const handleRemoveAlert = () => {
    confirm(
      {
        title: `Are you sure you want to delete “${title}” from the alert list?`,
        message:
          'This alert will be deleted immediately. You can’t undo this action.',
        variant: 'danger',
        rejectButtonLabel: 'Cancel',
      },
      () => {
        deleteAlerter(id);
      },
    );
  };

  const handleEditAlert = () => {
    setAppbarTitle(title ?? '');
    navigate(routes.private.alerterDetails.href(Number(profileId), id));
  };

  const handleToggleAlert = (
    _: ChangeEvent<HTMLInputElement>,
    value: boolean,
  ) => {
    editAlerter({
      id,
      enabled: value,
    });
  };

  useEffect(() => {
    if (!isDeletionLoading && wasDeleted) {
      toast.success('Alert was deleted succesfully!');
      dispatch(
        alertersApi.util.updateQueryData(
          'getAlerters',
          { patientProfileId: Number(profileId) },
          (response) => {
            response[group ?? 'uncategorized'] = response[
              group ?? 'uncategorized'
            ]?.filter((alerter) => alerter.id !== id);
          },
        ),
      );
      return;
    }

    if (!isDeletionLoading && deletionFailed) {
      toast.error('An error occured.');
    }
  }, [isDeletionLoading]);

  useEffect(() => {
    if (!isUpdating && wasUpdated) {
      dispatch(
        alertersApi.util.updateQueryData(
          'getAlerters',
          { patientProfileId: Number(profileId) },
          (response) => {
            const index = response[group].findIndex((alert) => alert.id === id);
            if (typeof index !== 'undefined' && data) {
              response[group][index].enabled = data.result.enabled;
            }
          },
        ),
      );
      return;
    }

    if (!isUpdating && updateFailed) {
      toast.error('An error occured.');
    }
  }, [isUpdating]);

  return {
    handleRemoveAlert,
    handleEditAlert,
    handleToggleAlert,
  };
};
