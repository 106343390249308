import { General, HelperText } from '../styled';
import { MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { memo } from 'react';

const GeneralFields = ({
  error,
  readOnly,
}: {
  error?: string;
  readOnly?: boolean;
}) => {
  const { register } = useFormContext();

  return (
    <div>
      <General>
        <div>
          <Typography color="primary" pb={1.5}>
            Title
          </Typography>
          <OutlinedInput
            size="small"
            sx={{ minWidth: ({ spacing }) => spacing(27.5) }}
            error={!!error}
            readOnly={readOnly}
            {...register('title')}
          />
          <HelperText error>{error}</HelperText>
        </div>
        <div>
          <Typography color="primary" pb={1.5}>
            Data Source
          </Typography>
          <Select
            readOnly
            size="medium"
            sx={{ minWidth: ({ spacing }) => spacing(27.5) }}
            defaultValue="apple-watch"
          >
            <MenuItem value="apple-watch">Apple Watch</MenuItem>
          </Select>
        </div>
      </General>
    </div>
  );
};

export default memo(GeneralFields);
