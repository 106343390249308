import { Box } from '@mui/material';
import { EditableMap } from 'components/_extra/EditableMap';
import { useParams } from 'react-router-dom';
import { useGetDashboardDataQuery } from 'store/api/dashboard.api';
import { SafeZone } from 'types/safe-zones';

const EditMap = ({ editedZone }: { editedZone: null | SafeZone }) => {
  const { profileId } = useParams();
  const { data } = useGetDashboardDataQuery(
    { account_id: parseInt(profileId ?? '0') },
    { skip: !profileId },
  );

  return (
    <Box flexGrow={1}>
      <EditableMap
        editedZone={editedZone}
        height="450px"
        hideCenterPoint={!profileId}
        location={data?.location ?? undefined}
      />
    </Box>
  );
};

export default EditMap;
