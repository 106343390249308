import { Box, Skeleton, Typography, styled } from '@mui/material';
import { EditButton, RemoveButton } from 'components/_extra';
import { useSafeZoneCard } from 'pages/PatientAccount/hooks/use-safe-zone-card';
import { useEffect, useState } from 'react';
import { caregiverRole } from 'services/caregiver-role';
import { SafeZone } from 'types/safe-zones';
import noPreviewSrc from 'assets/no-zone-preview.png';

const Wrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.disabled.light}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  transform: 'translate(0, -5%)',
  width: '110%',
  height: theme.spacing(45),
  objectFit: 'cover',
  userSelect: 'none',
  clipPath: 'polygon(0 0, 100% 0, 100% 75%, 0% 75%)',
}));

const ZoneCard = ({
  zone,
  onEdit,
  onDelete,
}: {
  zone: SafeZone;
  onEdit: () => void;
  onDelete?: (id: number) => void;
}) => {
  const [isLoading, setIsLoading] = useState(!!zone.snapshotUrl);
  const { handleDelete } = useSafeZoneCard(zone);

  const handleLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (zone.snapshotUrl) {
      const img = new Image();
      img.src = zone.snapshotUrl ?? '';
      img.onload = handleLoad;
      return () => {
        img.onload = null;
      };
    }
  }, [zone.snapshotUrl]);

  return (
    <Wrapper>
      <Header>
        <Typography
          flexGrow={1}
          variant="body1"
          color="primary"
          fontWeight={600}
        >
          {zone.label}
        </Typography>
        {!(caregiverRole.value === 'viewer') && (
          <>
            <EditButton onClick={onEdit} />
            <RemoveButton
              onClick={onDelete ? () => onDelete(zone.id) : handleDelete}
              tooltipText="Delete"
            />
          </>
        )}
      </Header>
      <Box
        height={({ spacing }) => spacing(30)}
        position="relative"
        overflow="hidden"
        borderRadius={({ spacing }) => spacing(0.5)}
      >
        {!isLoading ? (
          zone.snapshotUrl ? (
            <StyledImg
              draggable={false}
              src={zone.snapshotUrl}
              alt={`${zone.label} safe zone.`}
            />
          ) : (
            <img
              draggable={false}
              src={noPreviewSrc}
              alt="No preview available."
              style={{
                objectFit: 'cover',
                height: '100%',
                maxWidth: '100%',
                objectPosition: 'center',
              }}
            />
          )
        ) : (
          <Skeleton
            variant="rounded"
            sx={({ spacing }) => ({ height: spacing(30) })}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default ZoneCard;
