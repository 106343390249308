import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { WornStatus } from 'models/dashboard.model';
import { DataTableColumn } from 'types/dashboard';
import { PatientRecord } from 'types/patient';

export const commonColumnProperties: Partial<GridColDef> = {
  editable: false,
  filterable: false,
  sortable: false,
  hideable: false,
  flex: 1,
  disableColumnMenu: true,
  cellClassName: 'capitalized',
};

export const deviceColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.CONFIGURATION_OK,
    headerName: 'Configuration',
    minWidth: 150,
    renderCell: ({ row }) =>
      typeof row.isConfigurationOk === 'boolean' && (
        <Typography color={row.isConfigurationOk ? 'secondary' : 'error'}>
          {row.isConfigurationOk ? 'Ok' : 'Not ok'}
        </Typography>
      ),
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.DEVICE_LOCKED,
    headerName: 'Locked',
    minWidth: 150,
    renderCell: ({ row }) => {
      return (
        <Typography color={row.deviceLocked ? 'secondary' : 'error'}>
          {row.deviceLocked}
        </Typography>
      );
    },
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.DEVICE_WORN,
    headerName: 'Device Worn',
    minWidth: 150,
    renderCell: ({ row }) => {
      return (
        <Typography
          color={row.deviceWorn === WornStatus.WORN ? 'secondary' : 'error'}
        >
          {row.deviceWorn?.replace('_', ' ')}
        </Typography>
      );
    },
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.ACTIVE_DEVICE,
    headerName: 'Active Device',
    minWidth: 150,
    renderCell: ({ row }) =>
      typeof row.isAnyDeviceActive === 'boolean' && (
        <Typography color={row.isAnyDeviceActive ? 'secondary' : 'error'}>
          {row.isAnyDeviceActive ? 'yes' : 'no'}
        </Typography>
      ),
  },
];

export const batteryColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.BATTERY_LEVEL,
    headerName: 'Battery Level',
    minWidth: 130,
    renderCell: ({ row }) => (
      <Typography
        color={
          row.batteryLevel && row.batteryLevel > 30 ? 'secondary' : 'error'
        }
      >
        {row.batteryLevel}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.BATTERY_STATE,
    headerName: 'Battery State',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Typography color="primary">
        {row.batteryState && row.batteryState.replaceAll('"', '')}
      </Typography>
    ),
  },
];

export const safeZoneColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.SAFE_ZONE,
    headerName: 'Safe Zone',
    minWidth: 125,
    renderCell: ({ row }) =>
      typeof row.isInSafeZone === 'boolean' && (
        <Typography color={row.isInSafeZone ? 'secondary' : 'error'}>
          {row.isInSafeZone ? 'inside' : 'outside'}
        </Typography>
      ),
  },
];

export const bloodOxygenColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.BLOOD_OXYGEN,
    headerName: 'Blood Oxygen',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Typography color="primary">{row.bloodOxygen}</Typography>
    ),
  },
];

export const fallDetectionColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.FALL_DETECTION,
    headerName: 'Falls',
    minWidth: 100,
    renderCell: ({ row }) => (
      <Typography color="error">{row.fallDetection && 'Fell'}</Typography>
    ),
  },
];

export const heartRateColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.HEART_RATE,
    headerName: 'Heart Rate',
    minWidth: 130,
    renderCell: ({ row }) => (
      <Typography color="primary">{row.heartRate}</Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.LOW_HEART_RATE,
    headerName: 'Low HR Alert',
    minWidth: 130,
    renderCell: ({ row }) => (
      <Typography color="error" fontWeight={600}>
        {row.lowHeartRate && 'low'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.HIGH_HEART_RATE,
    headerName: 'High HR Alert',
    minWidth: 130,
    renderCell: ({ row }) => (
      <Typography color="error" fontWeight={600}>
        {row.highHeartRate && 'high'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: DataTableColumn.IRREGULAR_HEART_RYTHM,
    minWidth: 130,
    headerName: 'A-Fib Alert',
    renderCell: ({ row }) => (
      <Typography color="error" fontWeight={600}>
        {row.irregulatHeartRhythm && 'irregular'}
      </Typography>
    ),
  },
];

export const sleepColumns: GridColDef<PatientRecord>[] = [
  {
    ...commonColumnProperties,
    field: DataTableColumn.DAILY_SLEEP,
    headerName: 'Sleep time',
    minWidth: 130,
    renderCell: ({ row }) => (
      <Typography color="primary">
        {row.dailySleepTime
          ? `${Math.floor(row.dailySleepTime / 60)}h ${
              row.dailySleepTime % 60
            }min`
          : '-'}
      </Typography>
    ),
  },
];
