import { Button } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useAppSelector } from 'store';

const DiscardButton = ({ onClick }: { onClick: MouseEventHandler }) => {
  const isDisabled = !useAppSelector(
    (state) => state.reminder.areSettingsModified,
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={isDisabled}
      onClick={onClick}
    >
      Discard
    </Button>
  );
};

export default DiscardButton;
