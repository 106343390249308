import {
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useGetCredentialsForAccountQuery,
  useAddConfirmedCredentialMutation,
} from 'store/api/admin.api';
import { CustomPhoneInput } from 'components/CustomPhoneInput';
import { useDeleteCredentialMutation } from 'store/api/auth.api';

interface Props {
  profileId: number;
}

const PhoneNumber = ({ profileId }: Props) => {
  const theme = useTheme();

  const [credentialId, setCredentialId] = useState<number | null>(null);
  const [hasAPhoneNumber, setHasAPhoneNumber] = useState(true);
  const [phoneNo, setPhoneNo] = useState('');

  const { data, isFetching, isLoading } = useGetCredentialsForAccountQuery({
    accountId: profileId,
  });
  const [deleteCredential, { isLoading: isDeleteLoading }] =
    useDeleteCredentialMutation();
  const [addConfirmedCredential, { isLoading: isAddCredentialLoading }] =
    useAddConfirmedCredentialMutation();

  useEffect(() => {
    if (data) {
      const numbersOnly = data.results.filter(
        (item) => item.schema === 'phone',
      );

      if (numbersOnly.length > 0) {
        setPhoneNo(numbersOnly[0].identifier);
        setCredentialId(numbersOnly[0].id);
        setHasAPhoneNumber(true);
        return;
      }
      setHasAPhoneNumber(false);
    }
  }, [data]);

  const handleUpdate = () => {
    if (credentialId) {
      deleteCredential({ id: credentialId }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          toast('There was an error. Please try again.', { type: 'error' });
          return;
        }
      });
    }

    if (phoneNo.length) {
      addConfirmedCredential({
        accountId: profileId,
        schema: 'phone',
        identifier: phoneNo,
      }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          toast('There was an error. Please try again.', { type: 'error' });
          return;
        }

        toast('Phone number saved succesfully!', { type: 'success' });
      });
    }
  };

  const isUpdateLoading = isDeleteLoading || isAddCredentialLoading;

  return !(isFetching || isLoading) ? (
    <Grid container rowGap={1} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6" my={1}>
          Phone Number
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {hasAPhoneNumber && phoneNo.length === 0 ? (
          <Skeleton variant="rounded" height={64} width={160} />
        ) : (
          <CustomPhoneInput
            containerProps={{ sx: { mr: 1 } }}
            value={phoneNo}
            onChange={(value) => setPhoneNo(value)}
            disabled={false}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <Button fullWidth onClick={handleUpdate} disabled={isUpdateLoading}>
          Save
        </Button>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress size={theme.spacing(8)} sx={{ m: 4 }} />
  );
};

export default PhoneNumber;
