export const INTERVAL_OPTIONS = [
  {
    label: '5 minutes',
    value: 'continuous',
  },
  {
    label: '5 - 10 minutes',
    value: 'hybrid',
  },
  {
    label: '15 - 20 minutes',
    value: 'default',
  },
];
