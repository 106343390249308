import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { BasicModal } from 'components/BasicModal';
import VInput from 'components/VInput';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useLazyGetCredentialsForAccountQuery,
  useSendEquipmentDispatchEmailMutation,
} from 'store/api/admin.api';
import { useLazyGetLinkedCaregiversQuery } from 'store/api/profiles.api';
import CaregiverTable from '../CaregiverTable';
import {
  CaregiverDraft,
  convertFormToServerPayload,
  WizardForm,
} from '../types';

interface Props {
  accountId: number;
  firstName: string;
  lastName: string;
  onFinish: () => void;
  onGoBack: () => void;
}

const Form = ({
  accountId,
  firstName,
  lastName,
  onFinish,
  onGoBack,
}: Props) => {
  const { spacing } = useTheme();

  const [caregiverModalOpen, setCaregiverModalOpen] = useState(false);
  const [draftCaregivers, setDraftCaregivers] = useState<CaregiverDraft[]>([]);
  const [trackingService, setTrackingService] = useState<'fedex' | 'ups'>(
    'ups',
  );

  const [getCaregivers, { isLoading: isGetCaregiversLoading }] =
    useLazyGetLinkedCaregiversQuery();
  const [getCredentials] = useLazyGetCredentialsForAccountQuery();
  const [sendEmail, { isLoading }] = useSendEquipmentDispatchEmailMutation();

  const {
    handleSubmit: handleCaregiverFormSubmit,
    register: caregiverFormRegister,
    reset: caregiverFormReset,
  } = useForm<Omit<CaregiverDraft, 'id'>>();

  const {
    handleSubmit: handleWizardFormSubmit,
    register: wizardFormRegister,
    control: wizardFormControl,
  } = useForm<WizardForm>({
    defaultValues: { delay: true },
  });

  useEffect(() => {
    if (accountId >= 0) {
      getCaregivers({ profileId: accountId })
        .unwrap()
        .then(async (result) => {
          const draftCaregivers = await Promise.all(
            Object.values(result.accounts).map(async (item) => {
              return getCredentials({ accountId: item.id })
                .unwrap()
                .then((result) => {
                  const emailCredential = result.results.find(
                    (i) => i.schema === 'email',
                  );

                  return {
                    id: item.id,
                    firstName: item.first_name,
                    lastName: item.last_name,
                    email: emailCredential?.identifier || '',
                  };
                });
            }),
          );

          setDraftCaregivers(draftCaregivers);
        });
    }
  }, [accountId]);

  const onRowDelete = (id: number | string) => {
    setDraftCaregivers((prev) => prev.filter((item) => item.id !== id));
  };

  const onCaregiverSubmit = (data: Omit<CaregiverDraft, 'id'>) => {
    setDraftCaregivers((prev) => [
      ...prev,
      { ...data, id: new Date().toISOString() },
    ]);

    caregiverFormReset();
    setCaregiverModalOpen(false);
  };

  const onSubmit = (data: WizardForm) => {
    sendEmail({
      payload: convertFormToServerPayload(
        data,
        draftCaregivers,
        accountId,
        trackingService,
      ),
    }).then((response) => {
      if ((response as Record<string, unknown>).error) {
        return toast('A server error has occured. Please try again.', {
          type: 'error',
        });
      }

      onFinish();
    });
  };

  return (
    <>
      <form onSubmit={handleWizardFormSubmit(onSubmit)}>
        <Grid container maxWidth={spacing(54)}>
          <Grid item xs={6}>
            <VInput
              readOnly
              label="First name"
              size="medium"
              defaultValue={firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <VInput
              readOnly
              label="Last name"
              size="medium"
              defaultValue={lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Controller
                name="delay"
                control={wizardFormControl}
                render={({ field: { value, ...field } }) => (
                  <Checkbox {...field} checked={!!value} disabled={isLoading} />
                )}
              />
              <Typography variant="body2">
                Wait 24 hours to send shipped email
              </Typography>
            </Box>
          </Grid>
          <Box display="flex" alignItems="center" mb={2} mt={2}>
            <Typography variant="body1" fontWeight={600} color="primary" mr={2}>
              Caregivers
            </Typography>
            <Button
              size="small"
              onClick={() => setCaregiverModalOpen(true)}
              disabled={isGetCaregiversLoading || isLoading}
            >
              Add a caregiver
            </Button>
          </Box>
          <Grid container maxWidth={spacing(80)} mb={2}>
            <Grid item xs={12}>
              <CaregiverTable
                rows={draftCaregivers}
                onRowDelete={onRowDelete}
                isLoading={isGetCaregiversLoading}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <VInput
              startAdornment={
                <Icon
                  icon={
                    trackingService === 'fedex' ? 'cib:fedex' : 'fa6-brands:ups'
                  }
                  width={24}
                  height={24}
                  style={{ marginRight: 8 }}
                />
              }
              label="Tracking number"
              size="medium"
              disabled={isLoading}
              {...wizardFormRegister('trackingNumber')}
            />
          </Grid>
          <Grid item xs={4} display="flex" alignItems="end">
            <Select
              sx={{ mb: 1, mr: 1, height: ({ spacing }) => spacing(5.25) }}
              fullWidth
              disabled={isLoading}
              value={trackingService}
              onChange={(e) =>
                setTrackingService(e.target.value as 'fedex' | 'ups')
              }
            >
              <MenuItem value="fedex">FedEx</MenuItem>
              <MenuItem value="ups">UPS</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} display="flex">
            <Button type="submit" disabled={isLoading}>
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={onGoBack}
              sx={{ ml: 1 }}
              disabled={isLoading}
            >
              Go back
            </Button>
          </Grid>
        </Grid>
      </form>
      <BasicModal
        open={caregiverModalOpen}
        onClose={() => setCaregiverModalOpen(false)}
        p={2}
        maxWidth={spacing(60)}
      >
        <Typography variant="body1" fontWeight={600} color="primary" mb={1}>
          Add a caregiver
        </Typography>
        <form onSubmit={handleCaregiverFormSubmit(onCaregiverSubmit)}>
          <Grid container>
            <Grid item xs={6}>
              <VInput
                label="First name"
                size="medium"
                {...caregiverFormRegister('firstName')}
              />
            </Grid>
            <Grid item xs={6}>
              <VInput
                label="Last name"
                size="medium"
                {...caregiverFormRegister('lastName')}
              />
            </Grid>
            <Grid item xs={6}>
              <VInput
                label="Email"
                size="medium"
                {...caregiverFormRegister('email')}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
              <Button type="submit">Add</Button>
            </Grid>
          </Grid>
        </form>
      </BasicModal>
    </>
  );
};

export default Form;
