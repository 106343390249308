import { Icon } from '@iconify/react';
import { Button, styled } from '@mui/material';
import { chevronLeft } from 'assets/iconify';

type Props = {
  hidePreviousButton?: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  nextButtonDisabled?: boolean;
  previousButtonDisabled?: boolean;
  nextButtonText?: string;
  hideNextIcon?: boolean;
  hidePreviousIcon?: boolean;
};

const Root = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const MirroredIcon = styled(Icon)`
  transform: rotate(180deg);
`;

const NavigationButtons = ({
  hidePreviousButton,
  nextButtonDisabled,
  nextButtonText,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  hideNextIcon,
  hidePreviousIcon,
}: Props) => {
  return (
    <Root sx={{ pr: { xxs: 1.5, xs: 2, sm: 3, newMd: 4 } }}>
      {!hidePreviousButton && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onPreviousClick}
          disabled={previousButtonDisabled}
          startIcon={
            hidePreviousIcon ? undefined : (
              <Icon icon={chevronLeft.icons.normal} />
            )
          }
        >
          Previous Step
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={onNextClick}
        disabled={nextButtonDisabled}
        endIcon={
          hideNextIcon ? undefined : (
            <MirroredIcon icon={chevronLeft.icons.normal} />
          )
        }
      >
        {nextButtonText || 'Next Step'}
      </Button>
    </Root>
  );
};

export default NavigationButtons;
