import { gridClasses, useGridApiRef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect } from 'react';

export const useTableScroll = () => {
  const apiRef = useGridApiRef();

  const content = apiRef.current.rootElementRef?.current?.querySelector(
    `.${gridClasses.virtualScroller}`,
  );
  const [pinnedColumns, pinnedColumnHeaders] = [
    apiRef.current.rootElementRef?.current?.querySelector(
      `.${gridClasses.pinnedColumns}`,
    ),
    apiRef.current.rootElementRef?.current?.querySelector(
      `.${gridClasses.pinnedColumnHeaders}`,
    ),
  ];

  const handleScroll = useCallback(() => {
    if (content) {
      if (content.scrollLeft > 0) {
        pinnedColumns?.classList.add('scrolled');
        pinnedColumnHeaders?.classList.add('scrolled');
        return;
      }
      pinnedColumns?.classList.remove('scrolled');
      pinnedColumnHeaders?.classList.remove('scrolled');
    }
  }, [content]);

  useEffect(() => {
    if (content) {
      content.addEventListener('scroll', handleScroll);
      return () => content.removeEventListener('scroll', handleScroll);
    }
  }, [content]);

  return {
    apiRef,
  };
};
