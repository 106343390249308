export type TimeOption = {
  label: string;
  value: number;
};

const hours = Array.from({ length: 24 }, (_, i) => i);
const minutes = ['00', '15', '30', '45'];

export const timePickerOptions = hours.reduce((acc, hour) => {
  minutes.forEach((minute) => {
    const value = hour * 3600 + parseInt(minute) * 60;
    const label = `${
      hour <= 12 ? (hour === 0 ? 12 : hour) : hour - 12
    }:${minute}${hour < 12 ? 'am' : 'pm'}`;

    acc.push({ value, label });
  });
  return acc;
}, [] as TimeOption[]);
