import { Box, MenuItem, Radio, Select, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import { Controller, useFormContext } from 'react-hook-form';
import { AlerterEventsWidgets } from 'types/alerters';
import { HelperText } from '../styled';
import { useAppSelector } from 'store';
import { useParams } from 'react-router-dom';
import { useGetAlerterInfoQuery } from 'store/api/alerters.api';

const TargetWidget = ({ readOnly }: { readOnly?: boolean }) => {
  const { alertId } = useParams();

  const { data, isLoading } = useGetAlerterInfoQuery(Number(alertId));

  const { setValue, control } = useFormContext<SettingsForm>();
  const { defaultTargetWidget } = useAppSelector(
    (state) => state.alerter.settings,
  );

  const handleSelectChange = (value: AlerterEventsWidgets) => {
    setValue(
      'targetWidgetType',
      value === defaultTargetWidget ? 'default' : 'custom',
    );
  };

  const handleWidgetTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === 'custom') {
      setValue('targetWidget', '');
    }

    if (event.target.value === 'default') {
      setValue('targetWidget', defaultTargetWidget as AlerterEventsWidgets, {
        shouldDirty: true,
      });
    }

    setValue('targetWidgetType', event.target.value as 'default' | 'custom');
  };

  if (!isLoading && data?.result.available_widget_targets.length === 0) {
    return null;
  }

  return (
    <Box maxWidth={({ spacing }) => spacing(30)}>
      <Box display="flex" alignItems="center" gap={2} pb={1.5}>
        <Typography color="primary">Target Widget</Typography>
        <HelpIcon
          message="This alert will be shown in the dashboard widget you have selected."
          tooltipPlacement="top-start"
          tooltipSx={{
            maxWidth: ({ spacing }) => spacing(29),
          }}
        />
      </Box>
      <Controller
        name="targetWidgetType"
        render={({ field }) => (
          <Box display="flex" alignItems="center" gap={2} pb={1.5}>
            <Box display="flex" alignItems="center">
              <Radio
                id="default-widget"
                value="default"
                checked={field.value === 'default'}
                onChange={handleWidgetTypeChange}
                disabled={readOnly}
              />
              <Typography component="label" htmlFor="default-widget">
                Default
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Radio
                id="custom-widgets"
                value="custom"
                checked={field.value === 'custom'}
                onChange={handleWidgetTypeChange}
                disabled={readOnly}
              />
              <Typography component="label" htmlFor="custom-widgets">
                Custom
              </Typography>
            </Box>
          </Box>
        )}
      />
      <Controller
        control={control}
        name="targetWidget"
        render={({ field, fieldState }) => (
          <div>
            <Select
              variant="outlined"
              fullWidth
              size="small"
              displayEmpty
              readOnly={readOnly}
              error={!!fieldState.error}
              sx={{
                textTransform: 'capitalize',
                color: ({ palette }) =>
                  field.value === '' ? palette.disabled.main : undefined,
              }}
              {...field}
              renderValue={(value) => {
                if (isLoading) return 'Loading...';
                if (value.length === 0) {
                  return 'Select a widget';
                }
                return value.replaceAll('_', ' ');
              }}
              disabled={isLoading}
              onChange={(e) => {
                field.onChange(e);
                handleSelectChange(e.target.value as AlerterEventsWidgets);
              }}
            >
              <MenuItem disabled value="">
                Select widget
              </MenuItem>
              {data?.result.available_widget_targets.map((widget) => (
                <MenuItem
                  key={widget}
                  value={widget}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {widget.replaceAll('_', ' ')}
                </MenuItem>
              ))}
            </Select>
            <HelperText error>{fieldState.error?.message}</HelperText>
          </div>
        )}
      />
    </Box>
  );
};

export default TargetWidget;
