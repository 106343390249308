import {
  Badge,
  badgeClasses,
  BadgeProps,
  Box,
  BoxProps,
  styled,
} from '@mui/material';
import {
  TabPanelUnstyled,
  tabPanelUnstyledClasses,
  TabsListUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
  TabUnstyledProps,
} from '@mui/base';

export const ToolbarWrapper = styled(
  (props: BoxProps & { isExpanded?: boolean }) => <Box {...props} />,
  { shouldForwardProp: (prop) => prop !== 'isExpanded' },
)(({ theme, isExpanded }) => ({
  padding: theme.spacing(0, 3),
  borderRadius: !isExpanded ? theme.spacing(1) : theme.spacing(1, 1, 0, 0),
  backgroundColor: theme.palette.blue.dark,
  display: 'flex',
  justifyContent: 'flex-end',
  transition: theme.transitions.create('border-radius'),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
  },
}));

export const ToolbarTab = styled(
  <C extends React.ElementType>(props: TabUnstyledProps<C>) => (
    <TabUnstyled {...props} />
  ),
)`
  cursor: pointer;
  font-size: ${({ theme }) => theme.spacing(3)};
  background-color: transparent;
  padding: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  border: none;
  transition: ${({ theme }) =>
    theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.leavingScreen,
    })};

  &:hover {
    background-color: ${({ theme }) => theme.palette.hover.dark};
  }

  &.${tabUnstyledClasses.selected} {
    cursor: default;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transition: ${({ theme }) =>
      theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.leavingScreen,
      })};
  }
`;

export const ToolbarTabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  height: 100%;
  max-height: ${({ theme }) => theme.spacing(7)};
  font-size: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};

  &.${tabPanelUnstyledClasses.hidden} {
    display: none;
  }
`;

export const ToolbarTabsList = styled(TabsListUnstyled)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.palette.hover.dark};
  padding-left: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

export const ToolbarBadge = styled((props: BadgeProps) => (
  <Badge color="error" variant="dot" overlap="circular" {...props} />
))(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: theme.spacing(0.75),
  },
}));
