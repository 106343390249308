import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { HelpIcon } from 'components/HelpIcon';
import { useEmergencyContact } from 'pages/PatientAccount/hooks/use-emergency-contact';
import { useRef, useState } from 'react';

const TOOLTIP_TEXT =
  'Only caregivers marked as “Manager” can change emergency contact settings. This option cannot be active at the same time as the Request Help Button. Add a phone number to a caregiver’s profile to allow them to be selected for an emergency contact.';

const EmergencyContact = () => {
  const idRef = useRef<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openConfirmationDialog = (e?: SelectChangeEvent<number>) => {
    idRef.current = e ? Number(e.target.value) : 0;
    setIsDialogOpen(true);
  };

  const {
    currentContact,
    contacts,
    handleChange,
    isActive,
    handleEmergencySwitchChange,
    isLoading,
    canBeEdited,
    config,
    readOnly,
  } = useEmergencyContact();

  const confirmChange = () => {
    if (typeof idRef.current === 'number') {
      handleChange(idRef.current);
    }
    setIsDialogOpen(false);
  };

  return (
    <div>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography color="primary" py={1}>
            Emergency Call Button
          </Typography>
          <HelpIcon message={TOOLTIP_TEXT} />
        </Box>
        {isLoading ? (
          <Skeleton variant="rounded" width={58} height={28} />
        ) : (
          <Switch
            size="medium"
            checked={isActive}
            disabled={config?.show_request_help_button || readOnly}
            onChange={(_, isChecked) => handleEmergencySwitchChange(isChecked)}
            sx={{
              ml: 2,
            }}
          />
        )}
      </Box>
      <Box display="flex" gap={1} alignItems="center">
        <Select
          size="medium"
          readOnly={!canBeEdited || readOnly}
          disabled={!isActive}
          value={currentContact?.id ?? ''}
          displayEmpty
          onChange={openConfirmationDialog}
          sx={({ spacing }) => ({
            maxWidth: spacing(30.5),
            maxHeight: spacing(4),
            flexGrow: 1,
          })}
          renderValue={(value: number | string) => {
            if (isLoading) {
              return 'Loading...';
            }

            if (typeof value === 'string') {
              return 'None';
            }
            return contacts.find((c) => c.id === value)?.name;
          }}
        >
          {contacts.length > 0 ? (
            contacts.map((contact) => (
              <MenuItem value={contact.id} key={contact.id}>
                {contact.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No options</MenuItem>
          )}
        </Select>
      </Box>
      <ConfirmationDialog
        open={isDialogOpen}
        onConfirm={confirmChange}
        onReject={() => setIsDialogOpen(false)}
        title={
          idRef.current === 0
            ? 'Are you sure you want to remove the emergency contact?'
            : 'Are you sure you want to change the emergency contact?'
        }
      />
    </div>
  );
};

export default EmergencyContact;
