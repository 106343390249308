import { Box, Grid, Typography } from '@mui/material';
import { useRemindersList } from '../hooks/use-reminders-list';
import Card from './Card';
import { useCopyReminder } from '../hooks/use-copy-reminder';
import { getSideBarState } from 'services/sidebar';
import { EmptyList } from 'components/_extra/EmptyList';
import CreateReminderButton from './CreateReminderButton';
import { editNotification } from 'assets/iconify';
import AlertCategoryLoader from 'pages/Alerters/components/AlertCategoryLoader';
import { TriggerGroup } from 'utils/triggers';

const RemindersList = () => {
  const { reminders, isLoading, reminderCount } = useRemindersList();
  const handleCopy = useCopyReminder();

  const isSidebarOpen = getSideBarState();
  const breakpoints = isSidebarOpen
    ? { xxs: 12, newXl: 12, new3Xl: 6, new4Xl: 4 }
    : { xxs: 12, newXl: 6, new3Xl: 4, new4Xl: 4 };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      gap={3}
      pb={reminderCount > 0 ? 1 : 0}
    >
      {isLoading ? (
        <>
          <AlertCategoryLoader breakpoints={breakpoints} />
          <AlertCategoryLoader breakpoints={breakpoints} />
        </>
      ) : !!reminders && reminderCount > 0 ? (
        (
          [
            TriggerGroup.HEALTH,
            TriggerGroup.LOCATION,
            TriggerGroup.ACTIVITY,
            TriggerGroup.DEVICE,
            'uncategorized',
          ] as TriggerGroup[]
        ).map(
          (key) =>
            reminders[key]?.length > 0 && (
              <div key={key}>
                <Typography
                  color="primary"
                  pb={{ xxs: 1, newLg: 2 }}
                  fontWeight={600}
                  textTransform="capitalize"
                >
                  {key}
                </Typography>
                <Box px={{ xxs: 0, newLg: 1 }}>
                  <Grid container spacing={{ xxs: 2, sm: 3 }}>
                    {reminders[key].map(
                      ({
                        title,
                        enabled,
                        id,
                        group,
                        recipientManagerName,
                        triggerSummaryFragments,
                        recipientManagers,
                      }) => (
                        <Grid key={id} item {...breakpoints}>
                          <Card
                            id={id}
                            title={title}
                            isActive={enabled}
                            group={group}
                            recipientManagers={recipientManagers}
                            recipientManagerName={recipientManagerName}
                            triggerSummaryFragments={triggerSummaryFragments}
                            onCopy={() => handleCopy(id)}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Box>
              </div>
            ),
        )
      ) : (
        <EmptyList
          icon={editNotification.icons.normal}
          title="Start creating reminders"
          message="Reminders are short messages that can be delivered to patients, caregivers, or both at specified times during the day and week."
        >
          <CreateReminderButton />
        </EmptyList>
      )}
    </Box>
  );
};

export default RemindersList;
