import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { resetChosenMethod } from 'store/reducers/mfa/mfa.slice';
import { CodeEnter, CredentialSelect } from './components';
import dayjs from 'dayjs';

export interface MfaProps {
  onCompletion: () => void;
  obfuscateCredential?: boolean;
  includeCredentialId?: boolean;
  activateMfa?: boolean;
  ignoreMfaFlag?: boolean;
}

const Mfa = ({
  onCompletion,
  includeCredentialId,
  activateMfa,
  ignoreMfaFlag,
}: MfaProps) => {
  const dispatch = useAppDispatch();

  const mfaState = useAppSelector((state) => state.mfa);

  useEffect(() => {
    if (
      !mfaState.codeRequestedAt ||
      dayjs().diff(dayjs(mfaState.codeRequestedAt), 'minutes') > 14
    ) {
      dispatch(resetChosenMethod());
    }
  }, []);

  useEffect(() => {
    if (mfaState.requiresMfa === false && !ignoreMfaFlag) {
      onCompletion();
    }
  }, [mfaState.requiresMfa]);

  if (mfaState.chosenMethodId) {
    return (
      <CodeEnter
        onCompletion={onCompletion}
        includeCredentialId={includeCredentialId}
        activateMfa={activateMfa}
      />
    );
  }

  return <CredentialSelect />;
};

export default Mfa;
