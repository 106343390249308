import { FilterTabIndex } from './FilterTabs';
import { useFilters } from '../hooks/use-filters';
import { Filters } from 'components/Filters';
import { FilterItem } from 'store/reducers/filters/types';

interface Props {
  tabIndex: FilterTabIndex;
  withoutChips?: boolean;
  extraFilters?: Record<string, FilterItem<never>>[];
}

const PatientsFilters = ({ tabIndex, withoutChips }: Props) => {
  const { isUnset, appleWatchFiltersProps, resetFilters } =
    useFilters(withoutChips);

  const getFilters = () => {
    switch (tabIndex) {
      case FilterTabIndex.APPLE_WATCH: {
        return withoutChips
          ? appleWatchFiltersProps.filter(
              ({ exclusiveFor }) => exclusiveFor !== 'profile-overview',
            )
          : appleWatchFiltersProps.filter(
              ({ exclusiveFor }) => exclusiveFor !== 'history',
            );
      }

      default: {
        return [];
      }
    }
  };

  return (
    <Filters
      withoutChips={withoutChips}
      filters={getFilters()}
      isUnset={isUnset}
      resetFilters={resetFilters}
    />
  );
};

export default PatientsFilters;
