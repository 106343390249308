import { CombinedRecipientManager } from 'store/reducers/alerter/alerter.slice';
import {
  AlerterEvent,
  AlerterRecipientSet,
  AlerterSource,
  AlerterSummary,
  ChannelSet,
  CreateAlerterParams,
  GetAlertersResponse,
  MetricRule,
  MetricRuleTrigger,
  PatternType,
  RawAlerterEvent,
  RawAlerterRecipientSet,
  RawAlerterSource,
  RawAlerterSummary,
  RawAlerterTrigger,
  RawMetricRule,
  RawMetricRuleTrigger,
  RawRecipient,
  SerializedSchedule,
  SharedAlerterRecipientManagerResponse,
  TriggerValue,
} from 'types/alerters';
import { getLocalInterval } from 'utils/timezones';
import {
  getComparatorWithValue,
  getHumanFriendlyComparator,
} from 'utils/triggers';

export const getAlerterPresets = (name?: string) => [
  { value: 'all_users', label: 'All Caregivers Daily' },
  { value: 'all_patients', label: `${name ?? 'Patient'}'s watch Daily` },
];

export const getReminderPresets = (name?: string) => [
  { value: 'all_users', label: 'All Caregivers' },
  { value: 'all_patients', label: `${name ?? 'Patient'}'s watch` },
  {
    value: 'everyone',
    label: `${name ?? 'Patient'} and their Caregivers`,
  },
];

export const transformCreateAlerterParams = (params: CreateAlerterParams) => {
  return {
    title: params.title,
    alerter_source: transformSourceToRaw(params.alerterSource),
    alerter_recipient_manager_id: params.alerterRecipientManagerId,
    alerter_recipient_manager: params.alerterRecipientManager
      ? {
          name: params.alerterRecipientManager.name,
          attempt_count: params.alerterRecipientManager?.attemptCount,
          attempt_spacing: params.alerterRecipientManager?.attemptSpacing,
          recipient_sets: params.alerterRecipientManager?.sets.map((set) => ({
            priority: set.priority,
            recipients: set.recipients.map((rec) => ({
              pattern_type: rec.patternType,
            })),
          })),
        }
      : undefined,
  };
};

export const transformSourceToRaw = (
  params?: AlerterSource,
): RawAlerterSource | undefined => {
  if (params)
    return {
      patient_profile_id: params.patientProfileId,
      user_id: params.userId,
      device_id: params.deviceId,
      source_type: params.sourceType,
      stream_type: params.streamType,
    };
  return undefined;
};

export const transformMetricDataToRaw = ({
  metricType,
  ...rest
}: Omit<MetricRule, 'id'> & { zones?: number[] | null }): Partial<
  Omit<RawMetricRule, 'id' | 'value'>
> & { value?: TriggerValue } => {
  return {
    ...getComparatorWithValue({
      comparator: rest.comparator,
      value: rest.value as number | undefined,
      zones: rest.zones,
    }),
    suppress_message: rest.suppressMessage,
    metric_type: metricType,
    enabled: rest.enabled,
  };
};

export const transformRuleFromRaw = ({
  comparator,
  value,
  metric_type,
  name,
  suppress_message,
  ...rest
}: RawMetricRule) => ({
  ...rest,
  ...getHumanFriendlyComparator({
    comparator,
    value,
    metric: metric_type,
  }),
  metricType: metric_type,
  title: name,
  suppressMessage: suppress_message,
});

export const getRulesFromRawAlerterTrigger = (trigger: RawAlerterTrigger) =>
  trigger.metric_rules.map((value) => transformRuleFromRaw(value));

export const transformRecipientSet = (
  data: RawAlerterRecipientSet,
): AlerterRecipientSet => ({
  id: data.id,
  priority: data.priority,
  recipientSetId: data.alerter_recipient_set_id,
  recipients: data.alerter_recipients.map(
    ({
      id,
      pattern_type,
      recipient_type,
      recipient_display_name,
      alerter_channel_set_id,
      alerter_recipient_set_id,
      recipient_id,
    }) => ({
      id,
      name: recipient_display_name,
      channelSetId: alerter_channel_set_id ?? undefined,
      recipientId: recipient_id,
      recipientSetId: alerter_recipient_set_id,
      recipientType: recipient_type,
      patternType: pattern_type,
    }),
  ),
});

export const transformRecipient = ({
  display_name,
  pattern_type,
  recipient_id,
  recipient_type,
}: RawRecipient) => ({
  id: recipient_id,
  name: display_name,
  recipientType: recipient_type,
  patternType: pattern_type,
});

export const transformSharedRecipientManager = ({
  results,
  meta,
}: SharedAlerterRecipientManagerResponse): CombinedRecipientManager[] => {
  const mappedAlerterNames = meta.alerters.reduce((prev, curr) => {
    if (!prev[curr.id]) {
      return {
        ...prev,
        [curr.id]: curr.title,
      };
    }
    return prev;
  }, {} as Record<number, string>);

  const mappedSchedulers = meta.alerter_schedulers.reduce((prev, curr) => {
    if (!prev[curr.id]) {
      return {
        ...prev,
        [curr.id]: getLocalInterval(
          curr.serialized_schedule.start_time,
          curr.serialized_schedule.end_time,
          curr.serialized_schedule.days_of_week,
        ),
      };
    }
    return prev;
  }, {} as Record<number, SerializedSchedule>);

  return results.map(
    ({
      alerter_scheduler,
      alerter_recipient_sets,
      alerters,
      attempt_count,
      attempt_spacing,
      patient_profile_recipients_count,
      user_recipients_count,
      id,
      name,
    }) => ({
      id,
      name,
      attemptCount: attempt_count,
      attemptSpacing: attempt_spacing / 60,
      isOutsideRange: false,
      alerters: alerters.map(({ id }) => ({
        id,
        name: mappedAlerterNames[id],
      })),
      schedulerId: alerter_scheduler.id,
      patientsCount: patient_profile_recipients_count,
      recipientsCount: user_recipients_count,
      sets: alerter_recipient_sets?.map((set) => transformRecipientSet(set)),
      ...mappedSchedulers[alerter_scheduler.id],
    }),
  );
};

export const transformChannelSet = (
  rawChannels: (ChannelSet | 'push_critical')[],
): ChannelSet[] => {
  if (rawChannels.includes('push_critical')) {
    return [
      ...rawChannels.filter((c) => c !== 'push_critical'),
      'push',
      'critical',
    ] as ChannelSet[];
  }

  return rawChannels as ChannelSet[];
};

export const transformAlerterSummary = (
  alerter: RawAlerterSummary,
): AlerterSummary => {
  return {
    id: alerter.id,
    title: alerter.title,
    enabled: alerter.enabled,
    sourceStreamType: alerter.alerter_source_stream_type,
    triggerSummary: alerter.alerter_trigger_summary,
    schedulerSummary: alerter.alerter_scheduler_summary,
    userRecipientCount: alerter.user_recipients_count,
    group: alerter.display_group || 'uncategorized',
    patientsRecipientCount: alerter.patient_profile_recipients_count,
    triggerSummaryFragments: alerter.alerter_trigger_summary_fragments,
    recipientManagerName: alerter.alerter_recipient_manager_name,
    defaultWidgetTargets: alerter.generated_widget_targets,
    widgetTargets:
      alerter.custom_widget_targets ?? alerter.generated_widget_targets ?? [],
    recipientManagers: alerter.alerter_recipient_managers.map((manager) => ({
      id: manager.id,
      name: manager.name,
      scheduler: getLocalInterval(
        manager.alerter_scheduler_hash?.start_time ?? 0,
        manager.alerter_scheduler_hash?.end_time ?? 0,
        manager.alerter_scheduler_hash?.days_of_week ?? [],
      ),
    })),
  };
};

export const transformAlerterList = (data: GetAlertersResponse) => {
  return data.results
    .map((alerter) => transformAlerterSummary(alerter))
    .reduce((prev, curr) => {
      prev[curr.group ?? 'uncategorized'] =
        prev[curr.group ?? 'uncategorized'] || [];
      prev[curr.group ?? 'uncategorized'].push(curr);
      return prev;
    }, Object.create(null));
};

export const getSharedRecipientsOptions = (
  data: CombinedRecipientManager[] | undefined,
) => {
  if (typeof data === 'undefined') return [];

  return data.map(({ id, name }) => ({
    value: id,
    label: name ?? 'Untitled',
  }));
};

export const getRecipientFieldsForAlertCreation = (
  value: string | number,
  isReminder?: boolean,
  title?: string,
  patientName?: string,
) => {
  if (typeof value === 'number') {
    return {
      alerterRecipientManagerId: value,
    };
  }

  const options = isReminder
    ? getReminderPresets(patientName)
    : getReminderPresets(patientName);
  const preset = options.find((opt) => opt.value === value);

  if (preset) {
    const recipients =
      preset.value === 'everyone'
        ? [
            { patternType: 'all_users' as PatternType },
            { patternType: 'all_patients' as PatternType },
          ]
        : [
            {
              patternType: preset.value as PatternType,
            },
          ];
    return {
      alerterRecipientManager: {
        name: title ?? preset.label,
        attemptCount: 1,
        attemptSpacing: 120,
        isShared: true,
        sets: [
          {
            priority: 10,
            recipients,
          },
        ],
      },
    };
  }

  return {};
};

export const transformMetricRuleTrigger = (
  trigger: RawMetricRuleTrigger,
): MetricRuleTrigger => ({
  comparator: trigger.comparator,
  metricType: trigger.metric_type,
  triggeredAt: trigger.triggered_at,
  triggeredValue: trigger.triggered_value,
  value: trigger.value,
});

export const transformAlerterEvent = (event: RawAlerterEvent): AlerterEvent => {
  return {
    id: event.id,
    title: event.title ?? event.alerter_title ?? '',
    acknowledgedAt: event.acknowledged_at,
    acknowledgingAlerterRecipientId: event.acknowledging_alerter_recipient_id,
    acknowledgingAlerterRecipientName:
      event.acknowledging_alerter_recipient_name,
    createdAt: event.created_at,
    isActive: !!event.is_active,
    messageText: event.message_text,
    metricRulesTriggers: event.metric_rules_triggers.map((trigger) => {
      return transformMetricRuleTrigger(trigger);
    }),
    requiresAcknowledgement: event.requires_acknowledgement,
    triggeredAt: event.triggered_at,
  };
};
