import { Box, FormHelperText, Typography, styled } from '@mui/material';
import IntervalSelector from './IntervalSelector';
import RepeatCount from './RepeatCount';
import Hierarchy from './Hierarchy';
import { useFormContext } from 'react-hook-form';
import { EditRecipientSetForm } from './RecipientSetEditor';
import { AlerterRecipientSet } from 'types/alerters';
import { ChangeEvent, useState } from 'react';

const Bold = styled('span')`
  font-weight: 600;
`;

interface RecipientsProps {
  defaultSpacing?: number;
  defaultCount?: number;
  defaultSets?: AlerterRecipientSet[];
  readOnly?: boolean;
}

const Recipients = ({
  defaultCount = 1,
  defaultSpacing = 2,
  defaultSets = [],
  readOnly = false,
}: RecipientsProps) => {
  const { register, formState } = useFormContext<EditRecipientSetForm>();
  const [repeatCount, setRepeatCount] = useState(defaultCount);

  const handleCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value) {
      setRepeatCount(value);
    }
  };

  return (
    <Box maxWidth="100%">
      <Typography fontWeight={600} variant="body1" color="primary">
        Recipient Hierarchy
      </Typography>
      <Box
        display="flex"
        sx={{
          flexDirection: { xxs: 'column', newXl: 'row' },
          gap: { newXl: 2 },
        }}
      >
        <Box flexGrow={1}>
          <Hierarchy
            error={formState.errors.recipients?.message}
            sets={defaultSets}
            readOnly={readOnly}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            px: { xxs: 0, newMd: 2 },
          }}
        >
          <Box
            display="flex"
            gap={2}
            sx={({ spacing }) => ({
              maxWidth: { newXl: spacing(37) },
            })}
          >
            <RepeatCount
              inputProps={register('count', { onChange: handleCountChange })}
              defaultValue={defaultCount}
              error={formState.errors.count?.message}
              readOnly={readOnly}
            />
            <IntervalSelector
              inputProps={register('spacing')}
              defaultValue={defaultSpacing}
              error={formState.errors.spacing?.message}
              readOnly={readOnly}
            />
          </Box>
          <FormHelperText sx={{ maxWidth: ({ spacing }) => spacing(37) }}>
            BoundaryCare will make{' '}
            <Bold>
              {repeatCount} attempt{repeatCount > 1 ? 's' : ''}
            </Bold>{' '}
            to deliver an alert to a level in the hierarchy before continuing to
            the next level. The time interval controls the spacing between each
            attempt and level.
          </FormHelperText>
        </Box>
      </Box>
    </Box>
  );
};

export default Recipients;
