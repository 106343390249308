import { useEffect, useState } from 'react';
import { DateRange } from 'types/date-time';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { FocusedInputIndex } from '../components/CalendarModal';

interface Args {
  onChange: (value: DateRange) => void;
  defaultRange: DateRange;
  maxRange?: number;
}

export const useDatePickerModal = ({
  onChange,
  defaultRange,
  maxRange,
}: Args) => {
  const [value, setValue] = useState<DateRange>(defaultRange);
  const [open, setOpen] = useState(false);
  const [minDate, setMinDate] = useState<Dayjs | null>(null);
  const [maxDate, setMaxDate] = useState<Dayjs | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputIndex>(
    FocusedInputIndex.START,
  );

  const handleChange = (newValue: MuiDateRange<unknown>) => {
    const [min, max] = newValue as DateRange;

    setFocusedInput((focusedInput) =>
      focusedInput === FocusedInputIndex.START
        ? FocusedInputIndex.END
        : FocusedInputIndex.START,
    );

    setValue(() => {
      if (max && min?.isAfter(max)) {
        return [max.startOf('day'), min.endOf('day')];
      }
      if (min && max) {
        return [min.startOf('day'), max.endOf('day')];
      }
      if (min) {
        return [min.startOf('day'), min.endOf('day')];
      }
      if (max) {
        return [max.startOf('day'), max.endOf('day')];
      }
      return [dayjs().startOf('day'), dayjs().endOf('day')];
    });
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOkClick = () => {
    onChange(value);
    onClose();
  };

  const onResetClick = () => {
    setValue([dayjs().subtract(1, 'day'), dayjs()]);
  };

  useEffect(() => {
    if (maxRange) {
      setMaxDate(
        value[0]
          ? dayjs(value[0]).add(maxRange, 'days').subtract(1, 'second')
          : null,
      );
      setMinDate(
        value[1] ? dayjs(value[1]).subtract(maxRange - 1, 'days') : null,
      );
      return;
    }

    setMaxDate(null);
    setMinDate(null);
  }, [maxRange, value]);

  return {
    value,
    setValue,
    open,
    onOpen,
    onClose,
    onOkClick,
    onResetClick,
    handleChange,
    maxDate,
    minDate,
    focusedInput,
    setFocusedInput,
  };
};
