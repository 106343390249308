import { Button, CircularProgress, Modal } from '@mui/material';
import {
  RecipientSetEditor,
  RecipientSetEditorProps,
} from '../RecipientSetEditor';
import { Icon } from '@iconify/react';
import { Ref } from 'react';

type Props = RecipientSetEditorProps & {
  isOpen: boolean;
  onClose: () => void;
  selectedIndex: null | number;
  isLoading?: boolean;
  submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
  hideCopy?: boolean;
};

const RecipientSetModal = ({
  isOpen,
  onClose,
  isLoading,
  selectedIndex,
  ...rest
}: Props) => {
  return (
    <Modal
      open={isOpen}
      disableEscapeKeyDown
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <>
        {selectedIndex !== null &&
          (isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <RecipientSetEditor {...rest}>
                <Button
                  onClick={onClose}
                  variant="icon-text"
                  size="small"
                  sx={({ palette }) => ({
                    position: 'absolute',
                    color: palette.blue.light,
                    right: 10,
                    top: 10,
                    ':hover': {
                      color: palette.blue.dark,
                    },
                  })}
                >
                  <Icon icon="material-symbols:close" />
                </Button>
              </RecipientSetEditor>
            </>
          ))}
      </>
    </Modal>
  );
};

export default RecipientSetModal;
