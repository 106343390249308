/* eslint-disable @typescript-eslint/no-empty-function */
import { styled } from '@mui/material';
import {
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

const Cell = styled('input')(({ theme }) => ({
  fontSize: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.spacing(5),
  height: theme.spacing(6),
  margin: theme.spacing(1),
  color: theme.palette.black.main,
  border: `1px solid ${theme.palette.borderGray.main}`,
  textAlign: 'center',
  borderRadius: '4px',
  outline: 'none',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
  '&.error': {
    borderColor: theme.palette.grapeFruit.main,
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0.5),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(3),
    width: theme.spacing(4),
    height: theme.spacing(5),
  },
}));

interface IProps {
  onChange?: (value: string | null) => void;
  index: number;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>, index: number) => void;
  onSubmit?: () => void;
  isError?: boolean;
  disabled?: boolean;
  externalValue?: string | null;
}

const CodeCell = ({
  onChange = () => {},
  onPaste,
  index,
  onSubmit,
  isError = false,
  disabled = false,
  externalValue,
}: IProps) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (new RegExp('^[0-9]*$').test(e.key)) {
      setValue(e.key);
      onChange(e.key);
      if (inputRef.current?.nextElementSibling) {
        (inputRef.current.nextElementSibling as HTMLInputElement).focus();
      }
    }

    if (e.key === 'Backspace') {
      setValue('');
      onChange(null);
      if (
        inputRef.current &&
        (inputRef.current.previousElementSibling as HTMLInputElement)?.value
          ?.length > 0
      ) {
        (inputRef.current.previousElementSibling as HTMLInputElement).focus();
      }
    }

    if (e.key === 'Enter' && index === 5) {
      onSubmit?.();
    }
  };

  useEffect(() => {
    setValue(externalValue || '');
  }, [externalValue]);

  return (
    <Cell
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      onPaste={(e) => onPaste?.(e, index)}
      value={value}
      onKeyUp={handleKeyUp}
      className={isError ? 'error' : undefined}
      disabled={disabled}
      ref={inputRef}
      id={`code-input-${index}`}
      autoComplete="off"
      type="tel"
    />
  );
};

export default CodeCell;
