import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import {
  Button,
  ButtonProps,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Map,
  MapSkeleton,
  MapLastRefresh,
  MapBottomContainer,
} from './Map.styles';
import theme from 'styles/theme';
import TrackingButton, { TrackingButtonProps } from './TrackingButton';
import { TrackingStatus } from 'types/tracking';
import dayjs from 'dayjs';
import * as icons from 'assets/iconify';

const CenterButton = styled(
  React.forwardRef((props: ButtonProps, ref) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Button ref={ref as any} {...props}>
      <Icon
        icon={icons.liveTracking.icons.center}
        color="white"
        height={theme.spacing(2.5)}
      />
    </Button>
  )),
)(({ theme }) => ({
  margin: 0,
  padding: 0,
  minWidth: 0,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));

type Props = {
  patientName?: string;
  lastRefresh?: string;
  isLoading?: boolean;
  height: number | string;
  width: number | string;
  disabled?: boolean;
  onCenterProfileButtonClick: () => void;
  trackingButtonProps?: TrackingButtonProps;
  hideBcControls?: boolean;
};

const MapView: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isTracking =
    props.trackingButtonProps?.status === TrackingStatus.TRACKING;

  const [trackingTick, setTrackingTick] = useState(0);

  useEffect(() => {
    const trackingTickInterval = setInterval(() => {
      if (isTracking) {
        setTrackingTick((prev) => prev + 1);
      }
    }, 1000);

    return () => clearInterval(trackingTickInterval);
  }, [trackingTick, isTracking]);

  return (
    <Map
      width={props.width}
      height={props.height}
      color={theme.palette.grapeFruit.main}
      id="map"
      disabled={props.disabled}
    >
      {props.isLoading && (
        <>
          <MapSkeleton height={props.height} />
        </>
      )}
      {props.lastRefresh && (
        <MapLastRefresh>
          <Typography
            key={trackingTick}
            variant="caption"
            color={isTracking ? 'error' : 'primary'}
          >
            Last Location Update: {dayjs(props.lastRefresh).fromNow()}
          </Typography>
        </MapLastRefresh>
      )}
      {!props.hideBcControls && (
        <MapBottomContainer>
          <TrackingButton {...props.trackingButtonProps} />
          <Tooltip
            title={`Center${
              props.patientName ? ` on ${props.patientName}` : ''
            }`}
            placement="top"
          >
            <CenterButton onClick={props.onCenterProfileButtonClick} />
          </Tooltip>
        </MapBottomContainer>
      )}
    </Map>
  );
};

MapView.displayName = 'MapView';

export default MapView;
