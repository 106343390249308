import { Icon } from '@iconify/react';
import { Typography, styled } from '@mui/material';
import { noNetwork } from 'assets/iconify';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: theme.palette.disabled.main,
  svg: {
    color: theme.palette.disabled.main,
  },
}));

const NoNetwork = () => {
  return (
    <Wrapper>
      <Icon icon={noNetwork.icons.normal} width={96} height={96} />
      <Typography mt={2} variant="h5">
        No internet connection.
      </Typography>
      <Typography pb={8} variant="body1" color="disabled.main">
        Please check your internet connection and try again.
      </Typography>
    </Wrapper>
  );
};

export default NoNetwork;
