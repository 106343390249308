import { Icon as UnstyledIcon } from '@iconify/react';
import { Link as MuiLink, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { routes } from 'nav';
import { Link } from 'react-router-dom';
import { DeregistrationStatus, getStatus } from 'utils/deregistration-status';

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: '512px',
  display: 'grid',
  gridTemplateColumns: '120px auto',
  columnGap: theme.spacing(2),
  alignItems: 'center',
}));

const Icon = styled(UnstyledIcon)(() => ({
  width: '100%',
  gridRow: 'span 3',
  height: 'auto',
}));

const DeregistrationMessage: React.FC<{
  status: DeregistrationStatus;
}> = ({ status }) => {
  const { palette } = useTheme();
  const content = getStatus(status);
  const color =
    status === 'success' ? palette.secondary.main : palette.grapeFruit.main;

  return (
    <Wrapper>
      <Icon icon={content.icon} color={color} />
      <Typography variant="h3" color={color}>
        {content.title}
      </Typography>
      <Typography variant="h6" color="primary" sx={{ gridColumn: 2 }}>
        {content.message}
      </Typography>
      <MuiLink
        to={routes.private.patientsOverview.href}
        fontWeight={600}
        variant="h6"
        component={Link}
        color="primary"
        sx={{ gridColumn: 2, pt: 2 }}
      >
        Go to Patients Overview.
      </MuiLink>
    </Wrapper>
  );
};

export default DeregistrationMessage;
