import { useState } from 'react';
import { useAppSelector } from 'store';
import { useLazyGetAllQuery } from 'store/api/devices.api';

const LAST_STEP = 2;

export const useDeviceSetupSteps = () => {
  const [activeStep, setActiveStep] = useState(0);

  const id = useAppSelector((state) => state.dashboard.profileId);
  const [refetchData] = useLazyGetAllQuery();

  const refetchDevices = () => {
    if (id) {
      refetchData({ id }, false);
    }
  };

  const handleNextStep = () => {
    setActiveStep((step) => {
      if (step === LAST_STEP) {
        refetchDevices();
      }
      return step + 1;
    });
  };

  const resetActiveStep = () => {
    setActiveStep(0);
  };

  return {
    activeStep,
    handleNextStep,
    resetActiveStep,
    isComplete: activeStep > LAST_STEP,
    refetchDevices,
    lastStep: LAST_STEP,
  };
};
