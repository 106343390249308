import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  ButtonProps,
  Fade,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { TrackingStatus } from 'types/tracking';
import * as icons from 'assets/iconify';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(23),
  marginRight: theme.spacing(2),
}));

const CountdownWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(0.5),
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CountdownButton = styled(
  ({ status, ...props }: ButtonProps & { status: TrackingStatus }) => {
    const theme = useTheme();

    let btnText: string;

    switch (status) {
      case TrackingStatus.CONNECTING: {
        btnText = 'Connecting';
        break;
      }
      case TrackingStatus.IDLE: {
        btnText = 'Live Tracking';
        break;
      }
      case TrackingStatus.OFFLINE: {
        btnText = 'Device offline';
        break;
      }
      case TrackingStatus.TRACKING: {
        btnText = 'Stop tracking';
        break;
      }
      default: {
        btnText = 'Live Tracking';
        break;
      }
    }

    return (
      <Button {...props}>
        <Icon
          icon={icons.liveTracking.icons.ping}
          height={theme.spacing(2.5)}
          style={{ marginRight: theme.spacing(1) }}
        />
        {btnText}
        {status === TrackingStatus.CONNECTING && (
          <Box className="ellipsis-animation" width={theme.spacing(1)} />
        )}
      </Button>
    );
  },
)(({ theme, status }) => {
  const isConnecting = status === TrackingStatus.CONNECTING;
  const backgroundColor =
    status === TrackingStatus.TRACKING || isConnecting
      ? theme.palette.error.main
      : theme.palette.secondary.main;

  const hoverBackgroundColor =
    status === TrackingStatus.TRACKING || isConnecting
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (theme.palette.error as any).hover
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (theme.palette.secondary as any).hover;

  return {
    margin: 0,
    backgroundColor,
    transition: theme.transitions.create(['filter', 'background-color']),
    ...(isConnecting && {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }),

    '&:hover': {
      backgroundColor: hoverBackgroundColor,
    },
    '&:disabled': {
      backgroundColor: theme.palette.disabled.light,
      color: theme.palette.disabled.blue,
      opacity: 1,
    },
  };
});

export interface TrackingButtonProps {
  status?: TrackingStatus;
  endingIn?: string | null;
  onTrackingStart?: () => void;
  onTrackingEnd?: () => void;
}

const TrackingButton = ({
  status = TrackingStatus.IDLE,
  endingIn,
  onTrackingStart,
  onTrackingEnd,
}: TrackingButtonProps) => {
  const handleButtonPress = () => {
    if (status === TrackingStatus.IDLE) {
      onTrackingStart?.();
    }

    if (status === TrackingStatus.TRACKING) {
      onTrackingEnd?.();
    }
  };

  const isTracking = status === TrackingStatus.TRACKING;
  const isDisabled = status === TrackingStatus.OFFLINE;

  return (
    <Wrapper>
      <Fade in={isTracking && !!endingIn}>
        <CountdownWrapper>
          <Typography variant="body2" color="error">
            Ending {dayjs().to(dayjs(endingIn))}.
          </Typography>
        </CountdownWrapper>
      </Fade>
      <CountdownButton
        onClick={handleButtonPress}
        status={status}
        disabled={isDisabled}
      />
    </Wrapper>
  );
};

export default TrackingButton;
