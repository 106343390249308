import { IAccountResponse } from 'models/account.model';
import { ICredentialsFindResponse, ISearchByUpiRes } from 'models/admin.model';
import { bcApi } from './_api';

export const adminSearchApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    searchByEmail: builder.query<ICredentialsFindResponse, { email: string }>({
      query: ({ email }) => ({
        url: '/credentials/find',
        method: 'POST',
        data: { schema: 'email', identifier: email },
      }),
    }),
    searchByImei: builder.query<
      { results: { account_id: number }[] },
      { imei: string }
    >({
      query: ({ imei }) => ({
        url: '/devices/search',
        method: 'POST',
        data: { imei },
      }),
    }),
    searchByName: builder.query<ISearchByUpiRes, { name: string }>({
      query: ({ name }) => ({
        url: '/accounts/search',
        method: 'POST',
        data: { name },
      }),
    }),
    searchByPhone: builder.query<ICredentialsFindResponse, { phone: string }>({
      query: ({ phone }) => ({
        url: '/credentials/find',
        method: 'POST',
        data: { schema: 'phone', identifier: phone },
      }),
    }),
    searchByProfileId: builder.query<IAccountResponse, { profileId: number }>({
      query: ({ profileId }) => ({
        url: `/accounts/${profileId}`,
        method: 'GET',
      }),
    }),
    searchByTpUpi: builder.query<ISearchByUpiRes, { tpUpi: string }>({
      query: ({ tpUpi }) => ({
        url: '/accounts/search',
        method: 'POST',
        data: { third_party_public_identifier: tpUpi },
      }),
    }),
    searchByUpi: builder.query<ISearchByUpiRes, { upi: string }>({
      query: ({ upi }) => ({
        url: '/accounts/search',
        method: 'POST',
        data: { unique_public_identifier: upi },
      }),
    }),
  }),
});

export const {
  useLazySearchByEmailQuery,
  useLazySearchByImeiQuery,
  useLazySearchByNameQuery,
  useLazySearchByPhoneQuery,
  useLazySearchByProfileIdQuery,
  useLazySearchByTpUpiQuery,
  useLazySearchByUpiQuery,
  useSearchByEmailQuery,
  useSearchByImeiQuery,
  useSearchByNameQuery,
  useSearchByPhoneQuery,
  useSearchByProfileIdQuery,
  useSearchByTpUpiQuery,
  useSearchByUpiQuery,
} = adminSearchApi;
