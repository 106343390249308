import { ChangeEventHandler, RefObject, useContext, useState } from 'react';
import { CaregiverSettingsContext } from '../CaregiverSettings';

export const useNameInputs = (
  defaultFirstName: string,
  defaultLastName: string,
  firstNameInputRef: RefObject<HTMLInputElement>,
  lastNameInputRef: RefObject<HTMLInputElement>,
) => {
  const [firstNameError, setFirstNameError] = useState<null | string>(null);
  const { handlePropertyChange, setIsSaveActive, setDiscardChanges } =
    useContext(CaregiverSettingsContext);

  const changeFirstName: ChangeEventHandler<HTMLInputElement> = (e) => {
    handlePropertyChange('first_name', e.target.value);
    setFirstNameError(
      e.target.value.length === 0 ? 'First name is required.' : null,
    );
    setDiscardChanges(
      () =>
        firstNameInputRef.current &&
        (firstNameInputRef.current.value = defaultFirstName),
    );
    setIsSaveActive(!(e.target.value.length === 0));
  };

  const changeLastName: ChangeEventHandler<HTMLInputElement> = (e) => {
    handlePropertyChange('last_name', e.target.value);
    setDiscardChanges(
      () =>
        lastNameInputRef.current &&
        (lastNameInputRef.current.value = defaultLastName),
    );
    setIsSaveActive(true);
  };

  return {
    changeFirstName,
    changeLastName,
    firstNameError,
  };
};
