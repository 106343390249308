import { group, trash } from 'assets/iconify';
import { EmptyList } from 'components/_extra/EmptyList';
import AddCaregiver from './AddCaregiver';
import { Box, IconButton, Typography } from '@mui/material';
import NavigationButtons from '../NavigationButtons';
import { useContext, useState } from 'react';
import { ProfileConfiguratorContext } from '../ProfileConfigurator';
import { Caregiver } from '../hooks/use-add-caregiver';
import { commonColumnProperties } from 'utils/records-columns/columns';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Table } from 'components/Table';
import { Icon } from '@iconify/react';
import { useConfirmation } from 'hooks/use-confirmation';
import { toast } from 'react-toastify';

const getAlertHistoryColumns = (
  onRemove: (id: number) => void,
): GridColDef<Caregiver>[] => [
  {
    ...commonColumnProperties,
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 120,
    maxWidth: 180,
    renderCell: ({ row }) => (
      <Typography color="primary" variant="body1">
        {row.firstName}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 120,
    maxWidth: 180,
    renderCell: ({ row }) => (
      <Typography color="primary" variant="body1">
        {row.lastName ?? '-'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'email',
    headerName: 'Email',
    minWidth: 320,
    maxWidth: 360,
    renderCell: ({ row }) => (
      <Typography color="primary" textTransform="lowercase" variant="body1">
        {row.email}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'isManager',
    headerName: 'Role',
    minWidth: 180,
    maxWidth: 180,
    renderCell: ({ row }) => (
      <Typography color="primary" variant="body1">
        {row.isManager ? 'Manager' : 'Viewer'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'phoneNumber',
    headerName: 'Phone Number',
    minWidth: 180,
    maxWidth: 180,
    renderCell: ({ row }) => (
      <Typography color="primary" variant="body1">
        {row.phoneNumber ? formatPhoneNumberIntl(row.phoneNumber) : '-'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'requiresMfa',
    headerName: '2FA',
    minWidth: 180,
    maxWidth: 180,
    renderCell: ({ row }) => (
      <Typography color={row.requiresMfa ? 'error' : 'primary'}>
        {row.requiresMfa ? 'Required' : 'Not required'}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'remove',
    headerName: '',
    minWidth: 48,
    maxWidth: 48,
    renderCell: ({ row }) => (
      <IconButton
        sx={{ p: 0 }}
        color="error"
        onClick={() => onRemove?.(row.id)}
      >
        <Icon icon={trash.icons.normal} />
      </IconButton>
    ),
  },
];

const CaregiverGroup = () => {
  const confirm = useConfirmation();
  const { handleNext, profileConfiguration, setProfileConfiguration } =
    useContext(ProfileConfiguratorContext);

  const [caregivers, setCaregivers] = useState<Caregiver[]>(
    profileConfiguration.caregivers ?? [],
  );
  const handleNextStep = () => {
    setProfileConfiguration((prev) => ({
      ...prev,
      caregivers,
    }));
    handleNext();
  };

  const onNextStepClick = () => {
    if (caregivers.length === 0) {
      confirm(
        {
          message:
            'The current profile will not be monitored due to the absence of a caregiver. However, they can be added later.',
          title:
            'Are you sure you want to proceed without adding any caregivers to the group?',
          rejectButtonLabel: 'Go back',
          confirmButtonLabel: 'Continue',
        },
        handleNextStep,
      );
      return;
    }
    handleNextStep();
  };

  const noCaregivers = caregivers.length === 0;
  const nextId = noCaregivers ? 0 : (caregivers.at(-1) as Caregiver).id + 1;

  const handleNewCaregiver = (data: Caregiver) => {
    setCaregivers((prev) => [...prev, { ...data, id: nextId }]);
    toast.success('Caregiver was successfully added.');
  };

  const handleRemoveCaregiver = (id: number) => {
    const caregiverToRemove = caregivers.find(
      (caregiver) => caregiver.id === id,
    );

    confirm(
      {
        title: `Are you sure you want to remove “${caregiverToRemove?.firstName}” from the caregiver group?`,
        message:
          "If you remove this caregiver, they will no longer be part of the current  profile's caregiver group. However, they can be added again later.",
        confirmButtonLabel: 'Remove',
        variant: 'danger',
        rejectButtonLabel: 'Cancel',
      },
      () => {
        setCaregivers((prev) =>
          prev.filter((caregiver) => caregiver.id !== id),
        );
        toast.success('Caregiver was successfully removed.');
      },
    );
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={2}>
      <Box
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="column"
        pr={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        pt={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
      >
        {noCaregivers ? (
          <EmptyList
            title="Caregiver group is empty"
            message={
              <>
                Add caregivers who can receive alerts, view data, and/or manage
                profile settings. Any caregivers not already registered with
                <strong> BoundaryCare</strong> will receive an email explaining
                how to set up their new <strong>BoundaryCare</strong> account.
              </>
            }
            icon={group.icons.normal}
          >
            <AddCaregiver onCreate={handleNewCaregiver} />
          </EmptyList>
        ) : (
          <Box
            position="relative"
            border={({ palette }) => `1px solid ${palette.gray.light}`}
            borderRadius={2}
            overflow="hidden"
            maxHeight="100%"
          >
            <Table
              columns={getAlertHistoryColumns(handleRemoveCaregiver)}
              rows={caregivers}
              pagination={false}
              rowHeight={52}
              sx={{ maxHeight: '100%', overflowY: 'auto' }}
            />
            <AddCaregiver
              onCreate={handleNewCaregiver}
              isInTable
              existingCaregivers={caregivers.map((c) => c.email)}
            />
          </Box>
        )}
      </Box>
      <NavigationButtons onNextClick={onNextStepClick} previousButtonDisabled />
    </Box>
  );
};

export default CaregiverGroup;
