import {
  Box,
  IconButtonProps,
  IconButton as MuiIconButton,
  Tab as MuiTab,
  TabProps,
  Tabs,
  buttonClasses,
  styled,
  tabClasses,
  tabsClasses,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { arrowLeft } from 'assets/iconify';
import { Link, LinkProps, useParams } from 'react-router-dom';
import { routes } from 'nav';
import { useReminderDetails } from './hooks/use-reminder-details';
import Triggers from './components/Triggers';
import { Settings } from './components/Settings';
import { useReminderDetailsTabs } from './hooks/use-reminder-details-tabs';
import { createContext } from 'react';
import { RecipientSets } from './components/RecipientSets';
import { PageContent } from 'components/_extra/generic';
import { AddConditionButton, ConditionsList } from './components/Conditions';
import AddTriggerButton from './components/AddTriggerButton';
import {
  scheduleTriggersSelector,
  triggerCountSelector,
} from 'store/selectors';
import { useAppSelector } from 'store';
import CreateRecipientSetButton from 'pages/Alerters/components/RecipientSets/CreateRecipientSetButton';
import { useRecipientSets } from 'hooks/use-recipient-sets';

export const ReminderDetailsTabIndex = {
  TRIGGERS: 0,
  CONDITIONS: 1,
  RECIPIENTS_SETS: 2,
  SETTINGS: 3,
} as const;

export type ReminderDetailsTabIndex =
  (typeof ReminderDetailsTabIndex)[keyof typeof ReminderDetailsTabIndex];

const getTabPanelContent = (index: ReminderDetailsTabIndex) => {
  switch (index) {
    case ReminderDetailsTabIndex.TRIGGERS:
      return <Triggers />;
    case ReminderDetailsTabIndex.CONDITIONS:
      return <ConditionsList />;
    case ReminderDetailsTabIndex.RECIPIENTS_SETS:
      return <RecipientSets />;
    case ReminderDetailsTabIndex.SETTINGS:
      return <Settings />;
    default:
      <></>;
  }
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  [`.${tabClasses.root}:not(.${tabClasses.selected})`]: {
    color: theme.palette.gray.light,
    opacity: 0.7,
  },
  [`.${tabsClasses.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('newLg')]: {
    [`.${tabsClasses.root}`]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  [`.${tabsClasses.root}:has(> .${tabsClasses.scrollButtons})`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`.${tabsClasses.scrollButtons}`]: {
    color: theme.palette.common.white,

    [`&.${buttonClasses.disabled}`]: {
      opacity: 0.4,
    },
    svg: {
      padding: theme.spacing(0.5),
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.hover.dark,
      },
    },
  },
}));

const BackButtonWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  borderRight: `1px solid ${theme.palette.hover.dark}`,
  'a:hover': {
    backgroundColor: theme.palette.hover.dark,
  },
}));

const IconButton = (props: IconButtonProps & { to?: LinkProps['to'] }) => (
  <MuiIconButton {...props} />
);

const Tab = styled(
  <C extends React.ElementType>(
    props: TabProps<C, { isModified?: boolean }>,
  ) => <MuiTab {...props} />,
  { shouldForwardProp: (prop) => prop !== 'isModified' },
)(({ theme, isModified }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  ...(isModified && {
    '&::before': {
      position: 'absolute',
      left: 0,
      content: '""',
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.error.main,
    },
  }),
}));

export const SettingsContext = createContext({
  setSubmit: (arg: () => void) => {
    arg();
  },
});

const ReminderDetails = () => {
  const { profileId } = useParams();
  const {
    areSettingsModified,
    currentTab,
    handleSubmitChange,
    handleTabChange,
  } = useReminderDetailsTabs();

  const isListEmpty =
    useAppSelector(
      currentTab === ReminderDetailsTabIndex.CONDITIONS
        ? triggerCountSelector('reminder')
        : scheduleTriggersSelector,
    ) === 0;
  useReminderDetails();

  const { sets } = useRecipientSets(true);

  return (
    <PageContent>
      <Wrapper>
        <BackButtonWrapper>
          <IconButton
            LinkComponent={Link}
            to={routes.private.reminders.href(profileId ?? 0)}
            sx={({ palette }) => ({ color: palette.common.white })}
          >
            <Icon icon={arrowLeft.icons.normal} />
          </IconButton>
        </BackButtonWrapper>
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={currentTab}
          onChange={handleTabChange}
        >
          <Tab value={ReminderDetailsTabIndex.TRIGGERS} label="Schedule" />
          <Tab value={ReminderDetailsTabIndex.CONDITIONS} label="Conditions" />
          <Tab
            value={ReminderDetailsTabIndex.RECIPIENTS_SETS}
            label="Recipient Sets"
          />
          <Tab
            isModified={areSettingsModified}
            value={ReminderDetailsTabIndex.SETTINGS}
            label="Settings"
          />
        </Tabs>
      </Wrapper>
      <SettingsContext.Provider value={{ setSubmit: handleSubmitChange }}>
        <Box
          sx={{
            overflowY: 'auto',
            padding: ({ spacing }) => {
              const withoutPadding =
                ((currentTab === ReminderDetailsTabIndex.CONDITIONS ||
                  currentTab === ReminderDetailsTabIndex.TRIGGERS) &&
                  !isListEmpty) ||
                (currentTab === ReminderDetailsTabIndex.RECIPIENTS_SETS &&
                  sets.length !== 0);
              return {
                xxs: spacing(2, 1.5, withoutPadding ? 0 : 2),
                xs: spacing(2, 2, withoutPadding ? 0 : 2),
                sm: spacing(3, 3, withoutPadding ? 0 : 3),
                newMd: spacing(4, 4, withoutPadding ? 0 : 4),
              };
            },
          }}
        >
          {getTabPanelContent(currentTab)}
        </Box>
        {currentTab === ReminderDetailsTabIndex.CONDITIONS && (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding={({ spacing }) => ({
              xxs: isListEmpty ? 0 : spacing(2, 1.5),
              xs: isListEmpty ? 0 : spacing(2),
              sm: isListEmpty ? 0 : spacing(2, 3, 3),
              newMd: isListEmpty ? 0 : spacing(2, 4, 4),
            })}
          >
            {!isListEmpty && <AddConditionButton />}
          </Box>
        )}
        {currentTab === ReminderDetailsTabIndex.TRIGGERS && (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding={({ spacing }) => ({
              xxs: isListEmpty ? 0 : spacing(2, 1.5),
              xs: isListEmpty ? 0 : spacing(2),
              sm: isListEmpty ? 0 : spacing(2, 3, 3),
              newMd: isListEmpty ? 0 : spacing(2, 4, 4),
            })}
          >
            {!isListEmpty && <AddTriggerButton />}
          </Box>
        )}
        {currentTab === ReminderDetailsTabIndex.RECIPIENTS_SETS && (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding={({ spacing }) => ({
              xxs: sets.length === 0 ? 0 : spacing(2, 1.5),
              xs: sets.length === 0 ? 0 : spacing(2),
              sm: sets.length === 0 ? 0 : spacing(2, 3, 3),
              newMd: sets.length === 0 ? 0 : spacing(2, 4, 4),
            })}
          >
            {sets.length !== 0 && <CreateRecipientSetButton isReminder />}
          </Box>
        )}
      </SettingsContext.Provider>
    </PageContent>
  );
};

export default ReminderDetails;
