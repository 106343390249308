import {
  CreateSafeZoneParams,
  CreateSnapshotArgs,
  EditSafeZoneParams,
  FormattedRegions,
  RawGetSafeZonesResponse,
  SafeZone,
  SafeZoneResponse,
} from 'types/safe-zones';
import { bcApi } from './_api';
import { RegionsType } from 'types/safe-zones';
import { transformSafeZone } from 'utils/zones';
import { stringify } from 'qs';

export const safeZonesApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query<FormattedRegions, { id: number }>({
      query: (data) => ({
        url: '/regions',
        method: 'GET',
        params: { account_id: data.id, request_snapshot_previews: true },
      }),
      transformResponse: (response: RawGetSafeZonesResponse) => {
        const regions = response.results.map((region) =>
          transformSafeZone(region),
        );

        return {
          circleRegions: regions.filter(
            (region) => region.shapeType === RegionsType.CIRCLE_TYPE,
          ),
          pathRegions: regions.filter(
            (region) => region.shapeType === RegionsType.PATH_TYPE,
          ),
          polygonRegions: regions.filter(
            (region) => region.shapeType === RegionsType.POLYGON_TYPE,
          ),
        };
      },
      providesTags: ['SafeZones'],
    }),
    createRegion: builder.mutation<SafeZone, CreateSafeZoneParams>({
      query: ({ shapeType, accountId, geofence, ...rest }) => ({
        url: '/regions',
        method: 'POST',
        data: {
          shape_type: shapeType,
          requires_snapshot_preview: true,
          account_id: accountId,
          geofence: geofence?.map((v) => v.join(',')).join('|'),
          ...rest,
        },
      }),
      transformResponse: (response: SafeZoneResponse) => {
        return transformSafeZone(response.result);
      },
    }),
    editRegion: builder.mutation<SafeZone, EditSafeZoneParams>({
      query: ({ shapeType, geofence, accountId, id, ...rest }) => ({
        url: `/regions/${id}`,
        method: 'PUT',
        data: {
          shape_type: shapeType,
          account_id: accountId,
          requires_snapshot_preview: true,
          geofence: geofence?.map((v) => v.join(',')).join('|'),
          ...rest,
        },
      }),
      transformResponse: (response: SafeZoneResponse) => {
        return transformSafeZone(response.result);
      },
      invalidatesTags: ['SafeZones'],
    }),
    deleteRegion: builder.mutation<SafeZone, number>({
      query: (id) => ({
        url: `/regions/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: SafeZoneResponse) => {
        return transformSafeZone(response.result);
      },
    }),
    createRegionSnapshot: builder.mutation<
      { result: string },
      CreateSnapshotArgs
    >({
      query: ({ lat, lng, overlays, ...rest }) => ({
        url: '/regions/sign_apple_maps_snapshot_url',
        method: 'POST',
        data: {
          url: `snapshot?${stringify(
            {
              center: `${lat},${lng}`,
              overlays: JSON.stringify(overlays),
              ...rest,
            },
            {
              arrayFormat: 'indices',
            },
          )}`,
        },
      }),
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useDeleteRegionMutation,
  useCreateRegionMutation,
  useEditRegionMutation,
  useCreateRegionSnapshotMutation,
} = safeZonesApi;
