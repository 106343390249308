import {
  Box,
  Checkbox,
  FormHelperText,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { FieldError, useFormContext } from 'react-hook-form';

const PolicyCheck: React.FC<{ validationError?: FieldError }> = ({
  validationError,
}) => {
  const { palette, spacing } = useTheme();
  const { register } = useFormContext();

  return (
    <>
      <Box py={1} height={spacing(5)} display="flex" alignItems="center">
        <Checkbox id="policy-check" {...register('isAccepted')} />
        <Typography htmlFor="policy-check" component="label" variant="body2">
          I accept the{' '}
          <Link
            color={palette.red.main}
            target="_blank"
            href="https://www.boundarycare.com/privacy-policy"
          >
            {' '}
            terms and privacy policy
          </Link>
        </Typography>
      </Box>
      {validationError && (
        <FormHelperText sx={{ color: palette.grapeFruit.main, pl: 2, mb: 1 }}>
          {validationError?.message}
        </FormHelperText>
      )}
    </>
  );
};

export default PolicyCheck;
