import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { PasswordStrength } from 'models/auth.model';
import { palette } from 'styles/palette';

interface IProps {
  passwordStrength: PasswordStrength;
}

const Lines = styled.div`
  display: flex;
  gap: 8px;
  &.weak {
    & ~ p {
      color: ${palette.grapeFruit.main};
    }
    & div:nth-of-type(-n + 1) {
      background-color: ${palette.grapeFruit.main};
    }
  }

  &.ok {
    & ~ p {
      color: ${palette.yellow.main};
    }
    & div:nth-of-type(-n + 2) {
      background-color: ${palette.yellow.main};
    }
  }

  &.good {
    & ~ p {
      color: ${palette.primary.main};
    }
    & div:nth-of-type(-n + 3) {
      background-color: ${palette.primary.main};
    }
  }
  &.strong {
    & ~ p {
      color: ${palette.secondary.main};
    }
    & div:nth-of-type(-n + 4) {
      background-color: ${palette.secondary.main};
    }
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  text-align: right;
  color: ${palette.borderGray.main};
`;

const LevelLine = styled.div`
  flex-grow: 1;
  height: 4px;
  border-radius: 2px;
  background-color: ${palette.borderGray.main};
`;

const StrengthBar: React.FC<IProps> = ({ passwordStrength }) => {
  return (
    <Box px={1}>
      <Lines
        className={passwordStrength !== 'too short' ? passwordStrength : ''}
      >
        <LevelLine />
        <LevelLine />
        <LevelLine />
        <LevelLine />
      </Lines>
      <StyledTypography>{passwordStrength}</StyledTypography>
    </Box>
  );
};

export default StrengthBar;
