import { Button, styled } from '@mui/material';
import General from './General';
import Security from './Security';
import AlertIntegration from './AlertIntegrations';
import { Organization } from 'types/organizations';
import { FormProvider } from 'react-hook-form';
import { useOrganizationSettings } from '../hooks/use-organization-settings';
import { useAdminStatusQuery } from 'store/api/auth.api';
import { useAppSelector } from 'store';

const FormFields = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const Form = styled('form')(({ theme }) => ({
  maxHeight: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  [theme.breakpoints.down('newMd')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: theme.spacing(2),
}));

interface Props {
  defaultValues: Organization;
}

const SettingsForm = ({ defaultValues }: Props) => {
  const { accountId } = useAppSelector((state) => state.user);
  const { data: isAdmin } = useAdminStatusQuery({
    userId: accountId as number,
  });

  const { methods, handleSubmit, isLoading, isDirty, resetOrganizationForm } =
    useOrganizationSettings(defaultValues);

  const shouldDisableControls =
    isLoading || !(defaultValues.role === 'admin' || isAdmin) || !isDirty;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit}>
        <FormFields>
          <General disabled={isLoading} />
          <Security disabled={isLoading} />
          <AlertIntegration disabled={isLoading} />
        </FormFields>
        <ButtonsWrapper>
          <Button
            disabled={shouldDisableControls}
            onClick={resetOrganizationForm}
            color="primary"
            variant="outlined"
          >
            Discard
          </Button>
          <Button
            disabled={shouldDisableControls}
            type="submit"
            color="primary"
          >
            Save
          </Button>
        </ButtonsWrapper>
      </Form>
    </FormProvider>
  );
};

export default SettingsForm;
