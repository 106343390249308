import { useEffect, useMemo, useState } from 'react';
import { Box, Skeleton, Slider, styled, Typography } from '@mui/material';
import { Map } from 'components/Map';
import { WidgetBase } from 'components/WidgetBase';
import { useFetchHistoryQuery } from 'store/api/history.api';
import { LocationStats } from 'types/patient';
import { FormatDate } from 'types/enum/date-format.enum';
import { useTracking } from '../hooks/use-tracking';
import { FormattedRegions } from 'types/safe-zones';
import dayjs from 'dayjs';
import pxToEm from 'utils/px-to-em';

const SliderTextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

const NoWrap = styled('span')`
  white-space: nowrap;
`;

const NoLocationLong = styled('span')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const NoLocationShort = styled('span')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const formatLastUpdate = (date?: string) => {
  const now = dayjs();
  const inputDate = dayjs(date);
  const isToday = now.isSame(inputDate, 'day');
  const firstPart = `${isToday ? 'Today' : 'Yesterday'} at `;
  const secondPart = inputDate.format('h:mmA');

  return (
    <>
      {firstPart}
      <NoWrap>{secondPart}</NoWrap>
    </>
  );
};
const formatLastUpdateSlider = (date?: string) => dayjs(date).format('LT');

interface Props {
  patientName?: string;
  accountId?: number;
  deviceId?: number;
  isLoading?: boolean;
  isRegionsLoading?: boolean;
  location?: LocationStats;
  regions?: FormattedRegions;
  trackingExpiresOn?: string;
}

const MapWidget = ({
  patientName,
  accountId,
  deviceId,
  isLoading,
  isRegionsLoading,
  location,
  regions,
  trackingExpiresOn,
}: Props) => {
  const {
    status: trackingStatus,
    location: trackingLocation,
    endingOn: trackingEndingOn,
    onTrackingStart,
    onTrackingEnd,
  } = useTracking({ accountId, trackingEndingOn: trackingExpiresOn });

  const [selectedLocation, setSelectedLocation] = useState(location);
  const [lastUpdate, setLastUpdate] = useState(location?.lastUpdate);

  useEffect(() => {
    setSelectedLocation(location);
    setLastUpdate(location?.lastUpdate);
  }, [location]);

  useEffect(() => {
    if (trackingLocation) {
      setSelectedLocation(trackingLocation);
    }
  }, [trackingLocation]);

  const sliderFromDate = useMemo(
    () => dayjs().subtract(12, 'hours').format(FormatDate.HISTORY_DATE_TIME),
    [],
  );
  const sliderToDate = useMemo(
    () => dayjs().format(FormatDate.HISTORY_DATE_TIME),
    [],
  );

  const {
    data: historyData = [],
    isFetching: isHistoryFetching,
    isSuccess: isHistoryFetchSuccess,
    isLoading: isHistoryLoading,
  } = useFetchHistoryQuery(
    {
      account_id: Number(accountId),
      device_id: Number(deviceId),
      from: sliderFromDate,
      to: sliderToDate,
    },
    { skip: !accountId && !deviceId },
  );

  const [sliderValue, setSliderValue] = useState(0);
  const [isHistoryDisabled, setHistoryDisabled] = useState(false);

  useEffect(() => {
    if (isHistoryFetchSuccess) {
      setSliderValue(historyData.length || 2);

      if (!historyData.length) {
        setHistoryDisabled(true);
        setSliderValue(2);
        setLastUpdate(undefined);
      }
    }
  }, [isHistoryFetchSuccess]);

  const handleSliderUpdate = (value: number) => {
    setSliderValue(value);

    const recordData =
      historyData[value - 1].location_record.record_data.primary_location;

    setLastUpdate(recordData.timestamp);
    setSelectedLocation((prev) => ({
      ...prev,
      lat: recordData.lat,
      lng: recordData.lng,
    }));
  };

  const isMapLoading = isLoading;
  const isAllLoading = isLoading || isHistoryLoading || isHistoryFetching;
  const maxSliderLength = historyData.length || 2;

  return (
    <WidgetBase unlockedHeight sx={{ overflow: 'visible' }}>
      <Box width="100%">
        <Map
          patientName={patientName}
          location={selectedLocation}
          isLoading={isMapLoading}
          isRegionsLoading={isRegionsLoading}
          regions={regions}
          height={360}
          trackingButtonProps={{
            status: trackingStatus,
            endingIn: trackingEndingOn,
            onTrackingStart,
            onTrackingEnd,
          }}
        />
        {!isAllLoading ? (
          <Box px={1.25}>
            <SliderTextWrapper>
              <Typography variant="subtitle2" whiteSpace="nowrap">
                Location History
              </Typography>
              <Typography
                color="primary"
                fontWeight={500}
                textAlign="right"
                sx={({ breakpoints, spacing }) => ({
                  pl: 2,
                  [breakpoints.down('sm')]: {
                    lineHeight: spacing(2.5),
                    fontSize: pxToEm(14),
                  },
                })}
              >
                {lastUpdate ? (
                  formatLastUpdate(lastUpdate)
                ) : (
                  <>
                    <NoLocationLong>
                      No location history available
                    </NoLocationLong>
                    <NoLocationShort>Not available</NoLocationShort>
                  </>
                )}
              </Typography>
            </SliderTextWrapper>
            <Slider
              min={1}
              max={maxSliderLength}
              step={1}
              value={sliderValue}
              onChange={(_e, v) => handleSliderUpdate(+v)}
              disabled={isHistoryDisabled}
              valueLabelDisplay="auto"
              valueLabelFormat={formatLastUpdateSlider(lastUpdate)}
            />
          </Box>
        ) : (
          <Skeleton sx={{ height: ({ spacing }) => spacing(9.375) }} />
        )}
      </Box>
    </WidgetBase>
  );
};

export default MapWidget;
