import { Layout } from 'layouts/Public';
import { Navigate, NavigateProps } from 'react-router-dom';
import { useAppSelector } from 'store';

const renderNavigateProps = (redirectPath: string): NavigateProps => {
  const split = redirectPath.split('?');

  if (split.length === 2) {
    return {
      to: {
        pathname: split[0],
        search: split[1],
      },
    };
  }

  return {
    to: {
      pathname: split[0],
    },
  };
};

const MfaRoute = ({ outlet }: { outlet: React.ReactNode }) => {
  const { isMfaPassed, mfaPassRedirectRoute } = useAppSelector(
    (state) => state.user,
  );

  if (!isMfaPassed) {
    return <Layout>{outlet}</Layout>;
  } else {
    return <Navigate {...renderNavigateProps(mfaPassRedirectRoute)} replace />;
  }
};

export default MfaRoute;
