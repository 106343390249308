import { Box } from '@mui/system';
import { DateRangeInput } from 'components/DateRangeInput';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store';
import { setDateRange } from 'store/reducers/filters/filters.slice';
import { DateRange, Timestamp } from 'types/date-time';

const transformTimestampsToDayjs = (
  range: [Timestamp, Timestamp],
): DateRange => {
  return range.map((timestamp) => {
    if (timestamp.length === 0) {
      return null;
    }
    return dayjs(timestamp);
  }) as DateRange;
};

const DatePicker = () => {
  const dispatch = useAppDispatch();

  const { dateRange } = useAppSelector((state) => state.filters);

  const handleRangeChange = (newRange: DateRange) => {
    dispatch(
      setDateRange([
        newRange[0]?.toISOString() ?? dayjs().subtract(1, 'days').toISOString(),
        newRange[1]?.toISOString() ?? dayjs().toISOString(),
      ]),
    );
  };

  return (
    <Box py={2}>
      <DateRangeInput
        labelPlacement="top"
        disableFuture
        defaultRange={transformTimestampsToDayjs(dateRange)}
        onChange={handleRangeChange}
        maxRange={30}
        size="small"
      />
    </Box>
  );
};

export default DatePicker;
