import { ScheduleRuleValues } from 'components/_extra';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import { useAddScheduleRuleToReminderTriggerMutation } from 'store/api/reminders.api';
import { getUTCDays } from 'utils/timezones';

export interface GetTriggerFormRef {
  getTriggerValues: () => ScheduleRuleValues;
}

export const useAddTrigger = (onClose: () => void) => {
  const [add] = useAddScheduleRuleToReminderTriggerMutation();

  const functionsRef = useRef<GetTriggerFormRef>(null);
  const id = useAppSelector((state) => state.reminder.triggerId);

  const handleSubmit = () => {
    const values = functionsRef.current?.getTriggerValues();

    if (values && id) {
      add({
        id,
        rule: {
          serialized_schedule: getUTCDays(values.time, values.days),
        },
      });
      onClose();
      return;
    }
    toast.error('Something went wrong.');
  };

  return {
    handleSubmit,
    functionsRef,
  };
};
