import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useGetDashboardDataQuery } from 'store/api/dashboard.api';

export interface MapLocation {
  coordinate: mapkit.Coordinate;
  label: string;
}

const mapkit = window.mapkit;

const getLocationName = (location: {
  country?: string;
  name: string;
  formattedAddress: string;
}) => {
  const { country, formattedAddress, name } = location;
  const parts = [name, formattedAddress];
  if (country) {
    parts.push(country);
  }
  return parts.join(', ');
};

const search = new mapkit.Search();

export const useMapSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState<MapLocation[]>([]);
  const searchPin = useRef<mapkit.Annotation | null>(null);
  const { profileId } = useParams();
  const { data } = useGetDashboardDataQuery(
    { account_id: parseInt(profileId ?? '0') },
    { skip: !profileId },
  );

  const searchForLocation = (query: string, withoutRegion?: boolean) => {
    if (query.length === 0) return;
    setIsLoading(true);

    return search.search(
      query,
      (error, data) => {
        if (error) {
          return;
        }

        if (data.places.length === 0 && !withoutRegion) {
          searchForLocation(query, true);
        }

        setLocations(
          data.places.map((place) => ({
            coordinate: place.coordinate,
            label: getLocationName({
              country: place.country || '',
              formattedAddress: place.formattedAddress,
              name: place.name,
            }),
          })),
        );
        setIsLoading(false);
      },
      {
        coordinate: data?.location
          ? new mapkit.Coordinate(data.location.lat, data.location.lng)
          : undefined,
        includeAddresses: true,
        includePointsOfInterest: false,
        region:
          data?.location && !withoutRegion
            ? new mapkit.CoordinateRegion(
                new mapkit.Coordinate(data.location.lat, data.location.lng),
                new mapkit.CoordinateSpan(1, 1),
              )
            : undefined,
      } as mapkit.SearchOptions,
    );
  };

  const handleResultClick = (location: MapLocation | null) => {
    if (!location) {
      return;
    }

    if (searchPin.current) {
      window.mapkit.maps[window.mapkit.maps.length - 1].removeAnnotation(
        searchPin.current,
      );
    }

    window.mapkit.maps[window.mapkit.maps.length - 1].setRegionAnimated(
      new mapkit.CoordinateRegion(
        location.coordinate,
        new mapkit.CoordinateSpan(0.002, 0.002),
      ),
      true,
    );

    searchPin.current = window.mapkit.maps[
      window.mapkit.maps.length - 1
    ].addAnnotation(
      new mapkit.MarkerAnnotation(location.coordinate, {
        enabled: false,
        data: {
          label: 'searchPin',
        },
      }),
    );
  };

  return {
    searchForLocation,
    handleResultClick,
    locations,
    setLocations,
    isLoading,
    searchPin: searchPin.current,
  };
};
