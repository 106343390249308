import { Icon } from '@iconify/react';
import { Button, IconButton } from '@mui/material';
import { download } from 'assets/iconify/download';
import { useCsvDownload } from '../hooks';

const ExportCsvButton = () => {
  const { handleDownload, disabled } = useCsvDownload();

  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={handleDownload}
        disabled={disabled}
        startIcon={<Icon icon={download.icons.normal} width={20} height={20} />}
        sx={{ px: { xxs: 1, sm: 2 }, display: { xxs: 'none', newMd: 'flex' } }}
      >
        Export CSV
      </Button>
      <IconButton
        color="secondary"
        onClick={handleDownload}
        disabled={disabled}
        sx={{ display: { xxs: 'flex', newMd: 'none' } }}
      >
        <Icon icon={download.icons.normal} width={24} height={24} />
      </IconButton>
    </>
  );
};

export default ExportCsvButton;
