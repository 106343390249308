import { ISettingsRecord } from 'models/dashboard.model';

export const getLatestHeartAck = (
  settingsRecordData: ISettingsRecord['record_data'],
) => {
  const {
    high_heart_rate_event: highHeartRateLastAck,
    low_heart_rate_event: lowHeartRateLastAck,
  } = settingsRecordData.last_health_acks;

  return [highHeartRateLastAck, lowHeartRateLastAck]
    .sort()
    .reverse()
    .filter((e) => e)[0];
};
