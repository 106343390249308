import { styled } from '@mui/material';

export const SelectWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const BorderWrapper = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.borderGray.main}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));
