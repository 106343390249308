export interface Point {
  x: number;
  y: number;
}

export class Region {
  length!: number;
  area!: number;
  centroid!: Point;

  constructor(readonly points: Point[]) {
    this.length = points.length;
    this.area = this.calcArea();
    this.centroid = this.calcCentroid();
  }

  private calcArea(): number {
    let area = 0,
      i,
      j,
      point1,
      point2;

    for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
      point1 = this.points[i];
      point2 = this.points[j];
      area += point1.x * point2.y;
      area -= point1.y * point2.x;
    }

    area /= 2;

    return area;
  }

  private calcCentroid(): Point {
    let x = 0,
      y = 0,
      i,
      j,
      f,
      point1,
      point2;

    for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
      point1 = this.points[i];
      point2 = this.points[j];
      f = point1.x * point2.y - point2.x * point1.y;
      x += (point1.x + point2.x) * f;
      y += (point1.y + point2.y) * f;
    }

    f = this.area * 6;

    return {
      x: x / f,
      y: y / f,
    };
  }
}
