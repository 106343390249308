import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const energyBurn: IconifyJSON = {
  prefix: 'energy-burn',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M4.5 13.9999C4.5 12.5299 4.82 11.2099 5.48 10.0299C6.13 8.84991 6.87 7.82991 7.69 6.96991C8.51 6.10991 9.31 5.41991 10.06 4.90991C10.82 4.38991 11.3 4.05991 11.5 3.90991V6.30991C11.5 7.07991 11.76 7.67991 12.28 8.12991C12.8 8.57991 13.38 8.80991 14.03 8.80991C14.33 8.80991 14.62 8.75991 14.89 8.64991C15.16 8.53991 15.43 8.36991 15.68 8.13991L16.11 7.68991C17.14 8.35991 17.97 9.24991 18.59 10.3699C19.21 11.4899 19.51 12.6999 19.51 14.0199C19.51 15.5199 19.11 16.8699 18.32 18.0599C17.53 19.2499 16.5 20.1399 15.23 20.7199C15.55 20.3699 15.8 19.9699 15.98 19.5199C16.16 19.0699 16.26 18.5899 16.26 18.0699C16.26 17.5199 16.16 16.9899 15.95 16.4799C15.74 15.9699 15.44 15.5199 15.04 15.1199L12.02 12.1699L9.02 15.1199C8.62 15.5399 8.31 15.9899 8.1 16.4799C7.88 16.9699 7.77 17.4999 7.77 18.0699C7.77 18.5899 7.86 19.0699 8.05 19.5199C8.24 19.9699 8.48 20.3699 8.8 20.7199C7.53 20.1399 6.5 19.2499 5.71 18.0599C4.92 16.8699 4.52 15.5199 4.52 14.0199L4.5 13.9999Z" fill="currentColor"/>',
    },
  },
};
