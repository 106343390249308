import { Icon } from '@iconify/react';
import { Menu, MenuItem, Typography, styled } from '@mui/material';
import { arrowDropdown } from 'assets/iconify';
import { useRef, useState } from 'react';
import pxToEm from 'utils/px-to-em';

const TriggerWrapper = styled('div')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: space-between;
  width: ${({ theme }) => theme.spacing(15)};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  transition: background 0.15s;
  :hover {
    background-color: ${({ theme }) => theme.palette.hover.light};
  }
  svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Capitalize = styled('span')`
  text-transform: capitalize;
`;

const Option = styled(MenuItem)(({ theme }) => ({
  fontSize: pxToEm(14),
  padding: theme.spacing(0, 1),
  height: theme.spacing(3),
  color: theme.palette.primary.main,
}));

const FilterTypeSelect = ({
  onOptionChange,
  value,
}: {
  onOptionChange?: (value: 'widget' | 'group') => void;
  value: 'widget' | 'group';
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = () => {
    setAnchorEl(triggerRef.current);
  };

  const handleSelectValue = (value: 'widget' | 'group') => {
    onOptionChange?.(value);
    setAnchorEl(null);
  };

  return (
    <>
      <TriggerWrapper ref={triggerRef} onClick={handleClick}>
        <Typography variant="body1">
          by <Capitalize>{value}</Capitalize>
        </Typography>
        <Icon width={24} height={24} icon={arrowDropdown.icons.normal} />
      </TriggerWrapper>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        MenuListProps={{
          sx: {
            width: ({ spacing }) => spacing(15),
            py: 0.5,
          },
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Option onClick={() => handleSelectValue('group')}>by Group</Option>
        <Option onClick={() => handleSelectValue('widget')}>by Widget</Option>
      </Menu>
    </>
  );
};

export default FilterTypeSelect;
