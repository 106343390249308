import { Box, Switch, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { Controller, useFormContext } from 'react-hook-form';
import { Organization } from 'types/organizations';

interface Props {
  disabled?: boolean;
}

const AlertIntegrations = ({ disabled }: Props) => {
  const { control } = useFormContext<Organization>();

  return (
    <div>
      <Typography color="primary" fontWeight={600}>
        Alert Integrations
      </Typography>
      <Box
        display="flex"
        py={2}
        px={{ xxs: 1, newMd: 2 }}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={({ spacing }) => spacing(67)}
      >
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="body1" color="primary">
            NOSS
          </Typography>
          <HelpIcon
            tooltipPlacement="top-start"
            message="When active, this option will copy all alert events to this organization’s integration endpoint."
          />
        </Box>
        <Controller
          name="specialAlerterChannels.nossXml.active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch disabled={disabled} checked={value} onChange={onChange} />
          )}
        />
      </Box>
    </div>
  );
};

export default AlertIntegrations;
