import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import { error } from 'assets/iconify/error';
import { ReactNode } from 'react';

export interface ConfirmationOptions {
  title?: string;
  message?: ReactNode;
  variant?: 'danger' | 'default' | 'success';
  confirmButtonLabel?: string;
  rejectButtonLabel?: string;
  hideRejectButton?: boolean;
}

interface Props extends ConfirmationOptions {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
}

const getButtonColor = (variant: 'default' | 'danger' | 'success') => {
  switch (variant) {
    case 'danger':
      return 'red';
    case 'success':
      return 'secondary';
    default:
      return 'primary';
  }
};

const ConfirmationDialog = ({
  variant = 'default',
  confirmButtonLabel = 'Yes',
  message,
  rejectButtonLabel = 'No',
  title,
  isOpen,
  onConfirm,
  onReject,
  hideRejectButton,
}: Props) => {
  const { palette, spacing } = useTheme();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ sx: { p: 3, maxWidth: spacing(80) }, elevation: 6 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box display="flex" columnGap={1.5} pr={2}>
          <Icon
            width={spacing(12)}
            icon={error.icons.normal}
            color={palette.red.main}
          />
          <Box flex={1} sx={{ px: 1, pb: 2 }}>
            <Typography variant="h6" sx={{ pb: 1 }}>
              {title}
            </Typography>
            {message && (
              <Typography color={palette.blue.main} variant="body1" pb={0.75}>
                {message}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        {!hideRejectButton && (
          <Button
            color="primary"
            variant="outlined"
            sx={{ mr: 1 }}
            onClick={onReject}
          >
            {rejectButtonLabel ?? 'No'}
          </Button>
        )}
        <Button onClick={onConfirm} color={getButtonColor(variant)}>
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
