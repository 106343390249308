import { Tooltip, TooltipProps } from '@mui/material';
import { MouseEventHandler } from 'react';
import { IconOnlyButton, StyledIcon } from './styled';
import { check } from 'assets/iconify';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'medium' | 'small';
  toSave?: string;
  disabled?: boolean;
}

const WithTooltip = ({
  children,
  disabled,
  ...rest
}: TooltipProps & { disabled?: boolean }) => {
  if (disabled) return children;
  return <Tooltip {...rest}>{children}</Tooltip>;
};

const SaveButton = ({ onClick, size = 'small', toSave, disabled }: Props) => {
  return (
    <WithTooltip
      title={`Save ${toSave ?? ''}`}
      placement="top"
      disabled={disabled}
    >
      <IconOnlyButton
        size={size}
        disabled={disabled}
        variant="outlined"
        color="secondary"
        onClick={onClick}
      >
        <StyledIcon icon={check.icons.normal} />
      </IconOnlyButton>
    </WithTooltip>
  );
};

export default SaveButton;
