import { Box, Typography } from '@mui/material';

const SubscriptionRequired = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      <Typography
        variant="h6"
        mt={2}
        sx={{ fontSize: ({ spacing }) => spacing(3) }}
      >
        BoundaryCare Premium
      </Typography>
      <Typography textAlign="center" color="primary" mt={3}>
        A paid BoundaryCare subscription is required to use the web interface.
        Sign up for Premium through the BoundaryCare app or contact{' '}
        <a href={'mailto:' + 'info@boundarycare.com'}>info@boundarycare.com</a>{' '}
        to add this profile to your personal or organization&apos;s account.
      </Typography>
    </Box>
  );
};

export default SubscriptionRequired;
