import { useForm } from 'react-hook-form';
import {
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationsSettingsMutation,
} from 'store/api/organizations';
import { Organization } from 'types/organizations';

export const useOrganizationSettings = (organization: Organization) => {
  const [updateAddress, { isLoading }] = useUpdateOrganizationAddressMutation();
  const [updateSettings] = useUpdateOrganizationsSettingsMutation();

  const methods = useForm<Organization>({
    defaultValues: organization,
  });

  const onSubmit = (data: Organization) => {
    if (
      data.address &&
      Object.values(data.address).filter(
        (value) => value !== null || value?.length !== 0,
      ).length > 0
    ) {
      updateAddress({ ...data.address, id: organization.id });
    }
    updateSettings({
      id: organization.id,
      requiresMfa: data.requiresMfa,
      nossActive: data.specialAlerterChannels.nossXml.active,
    });
  };

  const resetOrganizationForm = () => {
    methods.reset();
  };

  return {
    methods,
    handleSubmit: methods.handleSubmit(onSubmit),
    isLoading,
    isDirty: methods.formState.isDirty,
    resetOrganizationForm,
  };
};
