import { Input, Paper, Theme, styled } from '@mui/material';
import { HTMLProps } from 'react';

export const StyledInput = styled(Input)(({ theme }) => ({
  // width: theme.spacing(27.5),
  fontSize: theme.spacing(2.5),
  lineHeight: theme.spacing(4),
  padding: 0,
}));

export const FlexWrapper = styled('div')<{ gap?: number }>(
  ({ theme, gap = 3 }: { theme: Theme; gap?: number }) => ({
    display: 'flex',
    fleWrap: 'wrap',
    gap: theme.spacing(gap),
    padding: theme.spacing(0),
  }),
);

export const Root = styled(Paper)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('xxs')]: {
    width: '100%',
    height: '100dvh',
  },
  [theme.breakpoints.up('newMd')]: {
    maxHeight: '90vh',
    height: 'unset',
    borderRadius: '8px',
    maxWidth: theme.spacing(89),
  },
  [theme.breakpoints.up('newLg')]: {
    width: '90%',
    maxWidth: theme.spacing(115),
  },
  padding: 0,
}));

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('newMd')]: {
    maxHeight: `calc(90vh - ${theme.spacing(10)})`,
  },
  overflow: 'auto',
  padding: theme.spacing(2, 2, 0, 2),
}));

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('xxs')]: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(2, 3, 3, 3),
  },
  [theme.breakpoints.up('newMd')]: {
    padding: theme.spacing(2, 4, 4, 4),
  },
}));

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2, 0, 2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const LevelLabel = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disabled,
    ...props
  }: HTMLProps<HTMLDivElement> & { isError?: boolean; disabled?: boolean }) => (
    <div {...props} />
  ),
)(
  ({
    theme,
    isError,
    disabled,
  }: {
    theme: Theme;
    isError?: boolean;
    disabled?: boolean;
  }) => ({
    width: theme.spacing(5),
    minWidth: theme.spacing(5),
    maxWidth: theme.spacing(5),
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    maxHeight: theme.spacing(5),
    [theme.breakpoints.up('newMd')]: {
      borderRadius: theme.spacing(0.5) + '!important',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(5) + '!important',
      minWidth: theme.spacing(5) + '!important',
    },
    [theme.breakpoints.up('xxs')]: {
      borderRadius: theme.spacing(0.5, 0, 0, 0.5),
      maxWidth: theme.spacing(3.5),
      minWidth: theme.spacing(3.5),
    },
    border: `1px solid ${
      theme.palette[isError ? 'red' : disabled ? 'disabled' : 'primary'].main
    }`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color:
      theme.palette[isError ? 'red' : disabled ? 'disabled' : 'primary'].main,
  }),
);
