import {
  Organization,
  OrganizationAddress,
  OrganizationsResponse,
  RawOrganization,
} from 'types/organizations';
import { bcApi } from './_api';
import {
  transformAddressArgs,
  transformOrganizationResponse,
} from 'utils/data-transformation/organizations';

export const organizationApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<Organization[], void>({
      query: () => ({
        url: '/organizations',
        method: 'GET',
      }),
      transformResponse: ({ results, meta }: OrganizationsResponse) =>
        results.map((org) => transformOrganizationResponse(org, meta)),
    }),
    getOrganizationById: builder.query<Organization, number>({
      query: (id) => ({
        url: `/organizations/${id}`,
        method: 'GET',
      }),
      transformResponse: ({ result }: { result: RawOrganization }) =>
        transformOrganizationResponse(result),
    }),
    updateOrganizationAddress: builder.mutation<
      unknown,
      { id: number } & Partial<OrganizationAddress>
    >({
      query: ({ id, ...address }) => ({
        url: `/organizations/${id}/update_organization_address`,
        method: 'PUT',
        data: transformAddressArgs(address),
      }),
    }),
    updateOrganizationsSettings: builder.mutation<
      unknown,
      Pick<Organization, 'id' | 'requiresMfa'> & { nossActive?: boolean }
    >({
      query: ({ id, requiresMfa, nossActive }) => ({
        url: `/organizations/${id}`,
        method: 'PUT',
        data: {
          requires_mfa: requiresMfa,
          special_alerter_channels: {
            noss_xml: {
              active: nossActive,
            },
          },
        },
      }),
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationsSettingsMutation,
} = organizationApi;
