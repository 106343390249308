import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { dashboardApi } from 'store/api/dashboard.api';

interface DashboardState {
  profileId: number;
  deviceId: number;
  patientName?: string;
  isTracking: boolean;
}

const initialState: DashboardState = {
  profileId: 0,
  deviceId: 0,
  isTracking: false,
};

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startTracking: (state) => {
      state.isTracking = true;
    },
    stopTracking: (state) => {
      state.isTracking = false;
    },
    updateSelectedUser: (
      state,
      action: PayloadAction<{
        name: string;
        accountId: number;
        deviceId: number;
      }>,
    ) => {
      state.profileId = action.payload.accountId;
      state.deviceId = action.payload.deviceId;
      state.patientName = action.payload.name;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getDashboardData.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          state.deviceId = action.payload.deviceId;
          state.profileId = action.payload.accountId;
        }
      },
    );
  },
});

export const { startTracking, stopTracking, resetState, updateSelectedUser } =
  DashboardSlice.actions;
export default persistReducer(
  {
    key: 'dashboard',
    storage,
    whitelist: ['deviceId', 'profileId', 'patientName'],
  },
  DashboardSlice.reducer,
);
