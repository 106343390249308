import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useFetchSubscriptionInfoQuery,
  useUpdateSubsctiptionInfoMutation,
} from 'store/api/admin.api';
import DatePicker from 'react-datepicker';
import * as Styled from './ProfileLookupResults.styles';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const isoDateToString = (date?: string) =>
  dayjs(date).format('MMMM DD YYYY, hh:mm:ss').toString();

interface Props {
  profileId: number;
}

const PaymentSubscription = ({ profileId }: Props) => {
  const theme = useTheme();

  const [expiryDate, setExpiryDate] = useState<string | null>(null);

  const { data, isFetching } = useFetchSubscriptionInfoQuery({
    profileId,
  });
  const [updateSubscriptionInfo, { isLoading: isUpdateLoading }] =
    useUpdateSubsctiptionInfoMutation();

  useEffect(() => {
    if (data) {
      setExpiryDate(data.result.expires_date);
    }
  }, [data]);

  const handleUpdate = () => {
    if (data) {
      updateSubscriptionInfo({
        subscriptionId: data.result.id,
        expires_date: expiryDate || undefined,
      }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          toast('There was an error. Please try again.', { type: 'error' });
          return;
        }

        toast('Subscription data saved succesfully!', { type: 'success' });
      });
    }
  };

  return !isFetching ? (
    data ? (
      <Grid container rowGap={1} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" my={1}>
            Payment Subscription
          </Typography>
        </Grid>
        <Grid item xs={6}>
          Payer Account ID
        </Grid>
        <Grid item xs={6}>
          {data.result.payer_account_id}
        </Grid>
        <Grid item xs={6}>
          Billing Source
        </Grid>
        <Grid item xs={6}>
          {data.result.billing_source}
        </Grid>
        <Grid item xs={6}>
          Original Purchase Date
        </Grid>
        <Grid item xs={6}>
          {isoDateToString(data.result.original_purchase_date)}
        </Grid>
        <Grid item xs={6}>
          Expiry Date
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            selected={expiryDate ? new Date(expiryDate) : null}
            onChange={(date) => setExpiryDate(date ? date.toISOString() : null)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            customInput={<Styled.TextField size="small" margin="dense" />}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleUpdate} disabled={isUpdateLoading}>
            Update
          </Button>
        </Grid>
      </Grid>
    ) : (
      <>
        <Typography variant="h6" my={1}>
          Payment Subscription
        </Typography>
        <Typography>No data for this profile.</Typography>
      </>
    )
  ) : (
    <CircularProgress size={theme.spacing(8)} sx={{ m: 4 }} />
  );
};

export default PaymentSubscription;
