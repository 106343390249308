import { signal } from '@preact/signals-react';
import { ReactNode } from 'react';

export const appBarTitle = signal('');

export const getCustomAppbarTitle = () => appBarTitle.value;

export const setAppbarTitle = (value: string) => {
  appBarTitle.value = value;
};

export const appBarTooltip = signal<ReactNode>(null);

export const getCustomAppbarTooltip = () => appBarTooltip.value;

export const setAppBarTooltip = (value: string | ReactNode) => {
  appBarTooltip.value = value;
};
