import { CircularProgress, styled } from '@mui/material';
import DeregistrationMessage from './DeregistrationMessage';
import { useDeregistration } from './hooks/use-deregistration';

const Wrapper = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ConfirmDeregistration = () => {
  const { isLoading, status } = useDeregistration();

  return (
    <Wrapper>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DeregistrationMessage status={status} />
      )}
    </Wrapper>
  );
};

export default ConfirmDeregistration;
