import { CredentialType } from 'models/auth.model';
import { routes } from 'nav';
import { useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useDeliverCodeMutation,
  useRequestConfirmationMutation,
  useValidateCodeMutation,
} from 'store/api/auth.api';
import { resetChosenMethod } from 'store/reducers/mfa/mfa.slice';
import { setAuthSuccess } from 'store/reducers/user/user.slice';

interface MfaRouterState {
  identifier?: string;
  schema?: CredentialType;
  isEditable?: boolean;
  credentialId?: number;
}

export const useCodeInput = () => {
  const mfaPassRedirectRoute = useAppSelector(
    (state) => state.user.mfaPassRedirectRoute,
  );

  const [validate, validationState] = useValidateCodeMutation();
  const [deliverCode, deliverState] = useDeliverCodeMutation();
  const [requestConfirmation, requestState] = useRequestConfirmationMutation();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as MfaRouterState;

  const finalCode = useRef('');
  const remainingSeconds = useRef(60);

  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState<FieldError | undefined>(undefined);

  const onSubmit = () => {
    if (state.identifier && finalCode.current) {
      validate({
        code: finalCode.current,
        identifier: state.identifier,
      }).then(() => {
        if (mfaPassRedirectRoute === routes.private.patientsOverview.href) {
          dispatch(setAuthSuccess());
          dispatch(resetChosenMethod());
        }
      });
    }
  };

  const enableSubmitButton = () => {
    setIsDisabled(false);
  };

  const disableSubmitButton = () => {
    setIsDisabled(true);
  };

  const onCompleteInput = (code: string) => {
    enableSubmitButton();
    finalCode.current = code;
  };

  useEffect(() => {
    if (validationState.isError) {
      setError({
        type: 'validate',
        message:
          'Make sure you typed your code correctly. If your code has expired resend a new one.',
      });
    }
  }, [validationState.isError]);

  useEffect(() => {
    if (requestState.isError || deliverState.isError) {
      toast.error('An error occured. Please try again.');
    }
  }, [requestState.isError, deliverState.isError]);

  const updateTimer = (seconds: number) => {
    remainingSeconds.current = seconds;
  };

  const onResend = () => {
    if (state.credentialId) {
      deliverCode({
        id: state.credentialId,
      });
      return;
    }

    if (state.identifier && state.schema) {
      requestConfirmation({
        identifier: state.identifier,
        schema: state.schema,
      });
      return;
    }

    setError({
      type: 'state',
      message: 'An error occured. Please try again later.',
    });
  };

  return {
    onCompleteInput,
    disableSubmitButton,
    onSubmit,
    isDisabled,
    onResend,
    isEditable: state.isEditable,
    updateTimer,
    state,
    error,
    remainingTime: remainingSeconds.current,
    isCodeResent: requestState.isSuccess || deliverState.isSuccess,
  };
};
