import { Params } from 'react-router-dom';

/**
 * Replaces a pathname that has params in it with a version that has the params replaced
 * with their react-router-dom param names.
 *
 * @example <caption>Returns `'/patients/:id/dashboard'`</caption>
 * pathnameToRouterPath('/patients/1/dashboard', { id: 1 })
 *
 * @param pathname the pathname from useLocation
 * @param params the params from useParams
 * @returns the react-router-dom router path
 */
export const pathnameToRouterPath = (
  pathname: string,
  params?: Params,
): string => {
  if (params && Object.keys(params).length > 0) {
    let newPathname = '';

    Object.keys(params).forEach((paramKey) => {
      newPathname = pathname.replace(
        params[paramKey] as string,
        `:${paramKey}`,
      );
    });

    return newPathname;
  }

  return pathname;
};
