import {
  Button,
  CircularProgress,
  OutlinedInput,
  Typography,
  styled,
} from '@mui/material';
import { useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  useAddConfirmedCredentialMutation,
  useGetCredentialsForAccountQuery,
} from 'store/api/admin.api';
import {
  useDeleteCredentialMutation,
  useVerifyEmailMutation,
} from 'store/api/auth.api';

const Root = styled('div')`
  padding-top: ${({ theme }) => theme.spacing(1)};
  flex-grow: 1;
`;

const InputWrapper = styled('form')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const EmailAddress = ({ profileId }: { profileId: number }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { data, isLoading } = useGetCredentialsForAccountQuery({
    accountId: profileId,
  });

  const [deleteCredential, { isLoading: isDeleteLoading }] =
    useDeleteCredentialMutation();
  const [addConfirmedCredential, { isLoading: isAddCredentialLoading }] =
    useAddConfirmedCredentialMutation();
  const [verify, { isLoading: isVerifying }] = useVerifyEmailMutation();

  const existingEmail = useMemo(() => {
    if (!data) return undefined;
    const credential = data.results.find(({ schema }) => schema === 'email');
    return credential;
  }, [!!data]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleSave = () => {
    const newEmail = inputRef.current?.value;

    if (typeof newEmail === 'undefined') {
      toast.error('Please refresh the page and try again.');
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    verify({ email: newEmail })
      .unwrap()
      .then(({ result }) => {
        if (result.exists) {
          toast.error('This email is already used by another account.');
          return;
        }

        if (existingEmail?.identifier === newEmail) {
          toast.info('You entered the same email');
        }

        if (existingEmail) {
          deleteCredential({ id: existingEmail.id });
        }
        addConfirmedCredential({
          accountId: profileId,
          schema: 'email',
          identifier: newEmail,
        })
          .unwrap()
          .then(({ result }) => {
            if (result.success) {
              toast.success('The email address was successfully updated.');
              return;
            }
            toast.error('Something went wrong');
          });
      });
  };

  return (
    <Root>
      <Typography variant="h6" mb={1}>
        Email
      </Typography>
      <InputWrapper>
        <OutlinedInput
          size="small"
          inputRef={inputRef}
          sx={{ width: '100%', mb: 2 }}
          defaultValue={existingEmail?.identifier}
          disabled={isDeleteLoading || isAddCredentialLoading || isVerifying}
        />
        <Button
          onClick={handleSave}
          disabled={isDeleteLoading || isAddCredentialLoading || isVerifying}
        >
          Save
        </Button>
      </InputWrapper>
    </Root>
  );
};

export default EmailAddress;
