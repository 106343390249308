import { useConfirmation } from 'hooks/use-confirmation';
import { FieldsData } from 'hooks/use-metric-rule';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetAlerterTriggerQuery } from 'store/api/alerters.api';
import { cancelEditing } from 'store/reducers/alerter/alerter.slice';
import { isRuleDifferentSelector } from 'store/selectors';
import triggerIdSelector from 'store/selectors/trigger-id.selector';

export const useTriggerList = () => {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const isTriggerModified = useAppSelector(isRuleDifferentSelector('alerter'));
  const { rules, editedRule } = useAppSelector(
    (state) => state.alerter.trigger,
  );

  const triggerId = useAppSelector(triggerIdSelector);
  const { data, isLoading, isFetching } = useGetAlerterTriggerQuery(triggerId, {
    skip: triggerId === 0,
  });

  const functionsRef = useRef<{
    getFieldsData: () => FieldsData;
    reset: () => void;
  }>(null);

  const discard = () => {
    functionsRef.current?.reset();
    dispatch(cancelEditing());
  };

  const handleBackdropClick = () => {
    if (isTriggerModified) {
      confirm(
        {
          title: 'You have unsaved changes',
          message: 'Do you want to save or discard them?',
          rejectButtonLabel: 'Go Back',
          confirmButtonLabel: 'Discard',
        },
        discard,
      );
      return;
    }
    dispatch(cancelEditing());
  };

  return {
    handleBackdropClick,
    rules,
    editedRule,
    isLoading: isLoading || isFetching || typeof data === 'undefined',
    functionsRef,
  };
};
