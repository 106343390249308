import { Box, Skeleton, Switch, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { useProfileConfig } from 'pages/PatientAccount/hooks/use-profile-config';

const RequestHelp = () => {
  const { handleChange, isLoading, isUpdating, value, config, readOnly } =
    useProfileConfig('show_request_help_button');

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Typography color="primary" py={1}>
          Request Help Button
        </Typography>
        <HelpIcon message="Display a button on the Watch screen that the patient can tap to request assistance from caregivers. An alert will be sent to all caregivers, or those you’ve specifically selected in the Alerts section. This option cannot be active at the same time with Emergency Call Button." />
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width={58} height={28} />
      ) : (
        <Switch
          size="medium"
          checked={value}
          onChange={(_, checked) => {
            handleChange(checked);
          }}
          disabled={
            readOnly || isUpdating || config?.show_call_emergency_contact_button
          }
          sx={{
            ml: 2,
          }}
        />
      )}
    </Box>
  );
};

export default RequestHelp;
