import { ButtonProps, Tooltip } from '@mui/material';
import { edit } from 'assets/iconify';
import { MouseEventHandler } from 'react';
import { StyledIcon, IconOnlyButton } from './styled';

interface Props extends ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  toEdit?: string;
}

const EditButton = ({ onClick, toEdit, ...rest }: Props) => {
  return (
    <Tooltip title={`Edit ${toEdit ?? ''}`} placement="top">
      <span>
        <IconOnlyButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onClick}
          {...rest}
        >
          <StyledIcon icon={edit.icons.normal} />
        </IconOnlyButton>
      </span>
    </Tooltip>
  );
};

export default EditButton;
