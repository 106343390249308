import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRegionsQuery } from 'store/api/safe-zones.api';
import { SafeZone } from 'types/safe-zones';

export const useSafeZonesSelector = (
  id: number | undefined,
  value: number[],
  setValue: Dispatch<SetStateAction<number[]>>,
) => {
  const { profileId } = useParams();

  const {
    data: regions = {
      circleRegions: [],
      pathRegions: [],
      polygonRegions: [],
    },
    isFetching,
    isLoading,
  } = useGetRegionsQuery({ id: Number(profileId) ?? 0 });

  const handleChange = (
    selected: (SafeZone | { id: number; label: string })[],
  ) => {
    if (selected.findIndex((option) => option.id === -1) !== -1) {
      setValue([-1]);
      return;
    }
    setValue(selected.map((s) => s.id));
  };

  useEffect(() => {
    if (Array.isArray(value)) {
      setValue(value);
    }
  }, [value]);

  return {
    options: [
      { label: 'All zones', id: -1 },
      ...regions.circleRegions,
      ...regions.pathRegions,
      ...regions.polygonRegions,
    ],
    handleChange,
    isLoading: isLoading || isFetching,
  };
};
