import { Box } from '@mui/material';
import { HelperText } from '../styled';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SettingsForm } from 'pages/ReminderDetails/hooks/use-settings';
import CustomMessageField from 'components/_extra/Alerter/CustomMessageField';
import AddImage from 'pages/AlerterDetails/components/Settings/components/AddImage';

const MessageField = ({
  error,
  readOnly,
}: {
  error?: string;
  readOnly?: boolean;
}) => {
  const { register } = useFormContext<SettingsForm>();

  return (
    <Box p={2} px={{ xxs: 0, newLg: 2 }}>
      <CustomMessageField
        placeholder="Create your custom message..."
        {...register('message')}
        readOnly={readOnly}
        error={!!error}
        fullWidth
      />
      <HelperText error>{error}</HelperText>
      <div>
        <AddImage readOnly={readOnly} />
      </div>
    </Box>
  );
};

export default memo(MessageField);
