import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpdateRuleInAlerterTriggerMutation } from 'store/api/alerters.api';

export const useEnableSwitch = (id: number) => {
  const [update, { isError, isLoading }] =
    useUpdateRuleInAlerterTriggerMutation();

  const handleSwitchToggle = (isChecked: boolean) => {
    update({
      metricRuleId: id,
      metricRule: {
        enabled: isChecked,
      },
    });
  };

  useEffect(() => {
    if (isError && !isLoading) {
      toast('An error occured. Please try again.', { type: 'error' });
    }
  }, [isError]);

  return { handleSwitchToggle, isLoading };
};
