import {
  Box,
  Button,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  styled,
} from '@mui/material';
import { useCreateReminder } from '../hooks/use-create-reminder';
import { HelperText } from 'pages/AlerterDetails/components/Settings/styled';
import { useRecipientManagerOptions } from 'hooks/use-recipient-manager-options';
import { StyledSelect } from 'pages/Alerters/components/CreateAlertButton/TemplateForm';

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CreateReminderForm = ({ onClose }: { onClose: () => void }) => {
  const { recipientSetOptions, presets, isLoading, nextTitle } =
    useRecipientManagerOptions(true);
  const {
    handleSubmit,
    register,
    sources,
    errors,
    handleModalClose,
    setAsDirty,
  } = useCreateReminder(onClose, nextTitle);

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <Typography pb={1} color="primary">
          Title
        </Typography>
        <OutlinedInput
          fullWidth
          size="small"
          defaultValue="Untitled"
          placeholder="Add title"
          inputProps={{ ...register('title', { onChange: setAsDirty }) }}
          error={!!errors.title}
        />
        <HelperText error>{errors.title?.message}</HelperText>
      </div>

      <div>
        <Typography pb={1} color="primary">
          Message
        </Typography>
        <OutlinedInput
          fullWidth
          size="small"
          placeholder="Create your custom message..."
          multiline
          rows={4}
          inputProps={{ ...register('message', { onChange: setAsDirty }) }}
          error={!!errors.message}
        />
        <HelperText error>{errors.message?.message}</HelperText>
      </div>
      <div>
        <StyledSelect
          label="Recipient Set"
          defaultValue={recipientSetOptions[0]?.value ?? presets[0].value}
          inputProps={{ ...register('recipientSet', { onChange: setAsDirty }) }}
          isLoading={isLoading}
        >
          <ListSubheader>New from presets</ListSubheader>
          {presets.map(({ label, value }) => (
            <MenuItem sx={{ px: 3 }} key={value} value={value}>
              {label}
            </MenuItem>
          ))}

          {recipientSetOptions.length > 0 && (
            <ListSubheader>Existing Sets</ListSubheader>
          )}
          {recipientSetOptions.map(({ label, value }) => (
            <MenuItem sx={{ px: 3 }} key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </div>
      <div>
        <Typography pb={1} color="primary">
          Data Source
        </Typography>
        <Select
          sx={{ width: '100%' }}
          size="medium"
          defaultValue={sources[0].value}
          readOnly
          inputProps={{ ...register('streamType', { onChange: setAsDirty }) }}
        >
          {sources.map(({ label, value }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Box display="flex" justifyContent="end" gap={2} pt={1}>
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button size="medium" color="primary" type="submit" variant="contained">
          Save
        </Button>
      </Box>
    </Form>
  );
};

export default CreateReminderForm;
