import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export default function (reduxPath: 'alerter' | 'reminder') {
  return createDraftSafeSelector(
    (state: RootState) => state[reduxPath].trigger.editedRule,
    (editedRule) => {
      if (!editedRule) {
        return false;
      }

      const oldRule = editedRule.originalRule;
      const newRule = editedRule.newRule;

      return !(
        oldRule.comparator === newRule.comparator &&
        oldRule.metricType === newRule.metricType &&
        oldRule.value === newRule.value
      );
    },
  );
}
