import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const helpRequested: IconifyJSON = {
  prefix: 'help-requested',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M10.1154 21.75V14.9615H8.23077V9.0192C8.23077 8.5221 8.40778 8.09654 8.7618 7.74252C9.1158 7.38852 9.54136 7.21152 10.0385 7.21152H13.9615C14.4586 7.21152 14.8842 7.38852 15.2382 7.74252C15.5922 8.09654 15.7692 8.5221 15.7692 9.0192V14.9615H13.8846V21.75L10.1154 21.75ZM12 5.90383C11.4923 5.90383 11.0577 5.72305 10.6961 5.3615C10.3346 4.99997 10.1538 4.56536 10.1538 4.05768C10.1538 3.54998 10.3346 3.11536 10.6961 2.75383C11.0577 2.39229 11.4923 2.21152 12 2.21152C12.5077 2.21152 12.9423 2.39229 13.3038 2.75383C13.6654 3.11536 13.8461 3.54998 13.8461 4.05768C13.8461 4.56536 13.6654 4.99997 13.3038 5.3615C12.9423 5.72305 12.5077 5.90383 12 5.90383Z" fill="currentColor"/>',
    },
    alerted: {
      ...defaultSizes,
      body: '<path d="M9.40387 21.75V8.55382C8.02184 8.23717 6.89909 7.51987 6.03563 6.40192C5.17218 5.28399 4.7116 4.01542 4.6539 2.5962H6.15385C6.21797 3.88978 6.68624 4.98241 7.55867 5.87407C8.43111 6.76572 9.49488 7.21155 10.75 7.21155H13.25C13.6923 7.21155 14.0885 7.28559 14.4385 7.43367C14.7884 7.58174 15.1192 7.81154 15.4307 8.12307L19.8115 12.5038L18.7577 13.5576L14.5961 9.3961V21.75H13.0962V15.8077H10.9039V21.75L9.40387 21.75ZM12 5.88457C11.4885 5.88457 11.0529 5.70477 10.6933 5.34515C10.3337 4.98555 10.1539 4.54998 10.1539 4.03845C10.1539 3.5269 10.3337 3.09132 10.6933 2.73172C11.0529 2.37211 11.4885 2.1923 12 2.1923C12.5115 2.1923 12.9471 2.37211 13.3067 2.73172C13.6663 3.09132 13.8462 3.5269 13.8462 4.03845C13.8462 4.54998 13.6663 4.98555 13.3067 5.34515C12.9471 5.70477 12.5115 5.88457 12 5.88457Z" fill="currentColor"/>',
    },
  },
};
