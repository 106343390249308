import { Icon } from '@iconify/react';
import { Box, IconButton, Typography, alpha, styled } from '@mui/material';
import { cancel, information } from 'assets/iconify';
import { useState } from 'react';
import { RegionsType } from 'types/safe-zones';
import isMobileDevice from 'utils/is-mobile-device';

const Root = styled('div')`
  position: 'relative';
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.8)};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

const ExpandedContent = () => {
  const isMobile = isMobileDevice();
  const addPointInstructions = isMobile
    ? 'Long press on the map'
    : 'Hold the shift key and click the map';

  const movePointInstructions = isMobile
    ? 'Tap and hold a point'
    : 'Click and hold a point';

  const deletePointInstructions = isMobile ? 'Tap' : 'Click';

  return (
    <>
      <br />
      {addPointInstructions} to <strong>add</strong> a point. <br />{' '}
      {movePointInstructions} to <strong>drag</strong> it to another part of the
      map. <br /> {deletePointInstructions} on a point to{' '}
      <strong>delete</strong> it.
    </>
  );
};

const getText = (selected: RegionsType) => {
  switch (selected) {
    case RegionsType.CIRCLE_TYPE:
      return (
        <>
          <strong>“Circle”</strong> shape selected.
        </>
      );
    case RegionsType.PATH_TYPE:
      return (
        <>
          <strong>“Path”</strong> shape selected.
        </>
      );

    case RegionsType.POLYGON_TYPE:
      return (
        <>
          <strong>“Polygon”</strong> shape selected.
        </>
      );
    default:
      return null;
  }
};

const HintBox = ({
  selectedType,
  defaultExpanded = true,
}: {
  selectedType: RegionsType;
  defaultExpanded?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <Root>
      <Box
        display="flex"
        gap={1}
        alignItems={isExpanded ? 'flex-start' : 'center'}
      >
        <Typography
          variant="caption"
          color="primary"
          sx={{ pt: isExpanded ? 0.25 : 0 }}
        >
          {getText(selectedType)}
          {isExpanded && <ExpandedContent />}
        </Typography>
        {!isExpanded && (
          <IconButton
            sx={{ p: 0.25 }}
            onClick={() => setIsExpanded(true)}
            color="primary"
          >
            <Icon width={20} height={20} icon={information.icons.normal} />
          </IconButton>
        )}
        {isExpanded && (
          <IconButton
            sx={{
              position: 'absolute',
              top: ({ spacing }) => spacing(0.5),
              right: ({ spacing }) => spacing(0.5),
              p: 0.25,
              color: ({ palette }) => palette.blue.light,
            }}
            onClick={() => setIsExpanded(false)}
          >
            <Icon width={20} height={20} icon={cancel.icons.normal} />
          </IconButton>
        )}
      </Box>
    </Root>
  );
};

export default HintBox;
