import { signal } from '@preact/signals-react';
import { Dayjs } from 'dayjs';
import { AlerterEventsWidgets } from 'types/alerters';

export const ALL_WIDGETS: AlerterEventsWidgets[] = [
  'battery',
  'blood_oxygen',
  'daily_calorie_burn',
  'daily_sleep_time',
  'daily_step_count',
  'device',
  'fall',
  'heart_rate',
  'heart_rhythm',
  'help_requested',
  'safe_zone',
  'motion_disorder',
];

export const widgets = signal<AlerterEventsWidgets[]>([]);
export const alertIds = signal<string[]>([]);
export const alerterHistoryFilterCriteria = signal<'group' | 'widget'>('group');
export const dateRange = signal<[Dayjs | null, Dayjs | null]>([null, null]);

export const setAlertHistoryFilterCriteria = (value: 'group' | 'widget') =>
  (alerterHistoryFilterCriteria.value = value);

export const setWidgets = (newWidgets: AlerterEventsWidgets[]) => {
  widgets.value = newWidgets;
};
export const setAlertIds = (newAlertIds: string[]) => {
  alertIds.value = newAlertIds;
};
export const getAlertIds = () => alertIds.value;
export const getWidgets = () =>
  widgets.value.length > 0 ? widgets.value : ALL_WIDGETS;

export const setDateRange = (newDateRange: [Dayjs | null, Dayjs | null]) => {
  dateRange.value = newDateRange;
};

export const getDateRangeStart = () => dateRange.value[0];
export const getDateRangeEnd = () => dateRange.value[1];
