import { Typography, useTheme } from '@mui/material';
import { BasicModal } from 'components/BasicModal';
import { Map } from 'components/Map';
import { LocationStats } from 'types/patient';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  location?: LocationStats;
}

const MinimapModal = ({ isOpen, location, onClose }: Props) => {
  const { spacing } = useTheme();

  return (
    <BasicModal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      maxWidth={spacing(70)}
      p={1.5}
    >
      <Typography variant="h6" mb={1}>
        Location preview
      </Typography>
      <Map hideBcControls location={location} />
    </BasicModal>
  );
};

export default MinimapModal;
