import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { FormProvider } from 'react-hook-form';
import { useLoginForm } from 'pages/Login/hooks/use-login-form';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import Button from '@mui/material/Button';
import RegistrationMessage from './RegistrationMessage';
import RememberMeCheckbox from './RememberMeCheckbox';
import ForgotLink from './ForgotLink';
import { Box, CircularProgress, Link, Modal, styled } from '@mui/material';
import { routes } from 'nav';
import EnvChoose from './EnvChoose';

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  max-width: 512px;
`;

const LoginForm = () => {
  const navigate = useNavigate();
  const {
    methods,
    onSubmit,
    errors,
    onError,
    isEmailValid,
    goBack,
    isEnvChooseOpen,
    onEnvChoose,
    onEnvChooseCancel,
    isReady,
  } = useLoginForm();

  const isAuth = useAppSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (isAuth) {
      navigate(routes.private.patientsOverview.href);
    }
  }, [isAuth]);

  if (!isReady) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <EmailField
            showStatusIcon
            readOnly={isEmailValid}
            isEmailValid={isEmailValid}
            validationError={errors.email}
          />
          {isEmailValid && (
            <>
              <PasswordField autoFocus validationError={errors.password} />
              <RememberMeCheckbox />
            </>
          )}
          <Button size="large" type="submit" sx={{ m: 1, mt: 3 }}>
            {isEmailValid ? 'Login' : 'Continue'}
          </Button>
          <ForgotLink email={methods.getValues().email} />
          {!isEmailValid && <RegistrationMessage />}
        </StyledForm>
        {isEmailValid && (
          <Box display="flex" justifyContent="center">
            <Link
              onClick={goBack}
              color="secondary"
              textAlign="center"
              fontWeight={600}
              fontSize={14}
              sx={{
                pt: 4,
              }}
            >
              Use another account
            </Link>
          </Box>
        )}
      </FormProvider>
      <Modal open={isEnvChooseOpen}>
        <Box
          sx={{
            p: ({ spacing }) => spacing(2),
            backgroundColor: ({ palette }) => palette.white.main,
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            overflowY: 'auto',
            flexShrink: '0',
            outline: 'none',
          }}
        >
          <EnvChoose onChoose={onEnvChoose} onCancel={onEnvChooseCancel} />
        </Box>
      </Modal>
    </>
  );
};

export default LoginForm;
