import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Timestamp } from 'types/date-time';
import { deepEqual } from 'utils/deep-equal';

import { Filters, FiltersAppleWatchKeys } from './types';

interface FiltersState {
  areFiltersModified: boolean;
  dateRange: [Timestamp, Timestamp];
  filters: Filters;
  pagination: {
    limit: number;
    total: number;
    page: number;
  };
  searchPhrase: string;
}

const defaultFilterItem = {
  selected: [],
  isInvisible: false,
};

const DEFAULT_FILTERS: Filters = {
  appleWatch: {
    device: defaultFilterItem,
    battery: defaultFilterItem,
    safeZone: defaultFilterItem,
    fallDetection: defaultFilterItem,
    heartRate: defaultFilterItem,
    heartRhythm: defaultFilterItem,
    bloodOxygen: defaultFilterItem,
    respiratoryRate: defaultFilterItem,
    sleepTime: { ...defaultFilterItem, exclusiveFor: 'history' },
    helpRequested: { ...defaultFilterItem, exclusiveFor: 'profile-overview' },
    seizureDetected: { ...defaultFilterItem, exclusiveFor: 'profile-overview' },
  },
  patientDetails: undefined,
  surveys: undefined,
};

const equalsWithDefault = (filters: Filters) => {
  const difference = (
    Object.keys(filters.appleWatch) as FiltersAppleWatchKeys[]
  ).find(
    (key) =>
      !deepEqual(
        filters.appleWatch[key].selected,
        DEFAULT_FILTERS.appleWatch[key].selected,
      ),
  );

  return !difference;
};

const getFiltersWithDefaultSelection = (filters: Filters): Filters => {
  const appleWatch = filters.appleWatch;

  (Object.keys(filters.appleWatch) as FiltersAppleWatchKeys[]).forEach(
    (key) => {
      appleWatch[key] = {
        ...filters.appleWatch[key],
        selected: DEFAULT_FILTERS.appleWatch[key].selected as never[],
      };
    },
  );

  return {
    ...filters,
    appleWatch,
  };
};

const initializeDateRange = (): [Timestamp, Timestamp] => {
  const today = dayjs();
  return [
    today.subtract(1, 'day').startOf('day').toISOString(),
    today.endOf('day').toISOString(),
  ];
};

const initialState: FiltersState = {
  filters: DEFAULT_FILTERS,
  areFiltersModified: false,
  searchPhrase: '',
  pagination: {
    limit: 0,
    page: 0,
    total: 0,
  },
  dateRange: initializeDateRange(),
};

export const PatientsOverviewSlice = createSlice({
  name: 'patientsOverview',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<Filters>) {
      state.filters = action.payload;
      state.areFiltersModified = !equalsWithDefault(action.payload);
    },
    resetFilters(state) {
      state.filters = getFiltersWithDefaultSelection(state.filters);
      state.areFiltersModified = false;
    },
    setSearchPhrase(state, action: PayloadAction<string>) {
      state.searchPhrase = action.payload;
    },
    setPagination(
      state,
      { payload }: PayloadAction<{ limit?: number; page?: number }>,
    ) {
      state.pagination.limit = payload.limit ?? state.pagination.limit;
      state.pagination.page = payload.page ?? state.pagination.page;
    },
    setPaginationTotal(state, { payload }: PayloadAction<number>) {
      state.pagination.total = payload;
    },
    setDateRange(state, action: PayloadAction<[Timestamp, Timestamp]>) {
      state.dateRange = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setFilters,
  resetFilters,
  setSearchPhrase,
  setDateRange,
  setPagination,
  setPaginationTotal,
  resetState,
} = PatientsOverviewSlice.actions;

export default PatientsOverviewSlice.reducer;
