import { useParams } from 'react-router-dom';
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from 'store/api/profiles.api';

const PERSEVERATIVE_MOTION_DURATION_OPTIONS = [
  { label: '30 seconds', value: 30 },
  { label: '45 seconds', value: 45 },
  { label: '1 minute', value: 60 },
  { label: '1 min 15 sec', value: 75 },
  { label: '1 min 30 sec', value: 90 },
  { label: '1 min 45 sec', value: 105 },
  { label: '2 minutes', value: 120 },
];

export const usePerseverativeMotion = () => {
  const { profileId } = useParams<{ profileId: string }>();

  const { data, isLoading } = useGetProfileQuery({ id: Number(profileId) });
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();

  const handleChange = (value: number) => {
    updateProfile({
      id: Number(profileId),
      perseverative_motion_target_duration: value,
    });
  };

  return {
    isActive: data?.result.requests_perseverative_motion_monitoring ?? false,
    isLoading,
    isUpdating,
    handleChange,
    persevarativeMotionDuration:
      data?.result.perseverative_motion_target_duration ?? 60,
    options: PERSEVERATIVE_MOTION_DURATION_OPTIONS,
  };
};
