import { useContext, useState } from 'react';
import {
  DEFAULT_ALERT_TEMPLATES,
  ProfileConfiguratorContext,
} from '../ProfileConfigurator';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import NavigationButtons from '../NavigationButtons';
import { useAlertSetup } from '../hooks/use-alert-setup';
import TemplateItem from './TemplateItem';
import { getSideBarState } from 'services/sidebar';
import { Icon } from '@iconify/react';
import { replay } from 'assets/iconify';

const extractValues = (templateParams?: Record<string, number>) => {
  if (!templateParams) return;
  return Object.values(templateParams);
};

const AlertSetup = () => {
  const {
    handleNext,
    handlePrevious,
    profileConfiguration: profileSettings,
    setProfileConfiguration: setProfileSettings,
  } = useContext(ProfileConfiguratorContext);
  const { templates, isLoading } = useAlertSetup();

  const isSidebarOpen = getSideBarState();

  const [isRestoring, setIsRestoring] = useState(false);
  const [checkedTemplates, setCheckedTemplates] = useState<string[]>(
    profileSettings.alertTemplates.map(
      (template) => template.alertTemplateType,
    ) ?? [],
  );
  const [templateValues, setTemplateValues] = useState<
    Record<string, Record<string, number>>
  >(
    profileSettings.alertTemplates.reduce((acc, template) => {
      if (template.templateParams) {
        return {
          ...acc,
          [template.alertTemplateType]: template.templateParams,
        };
      }
      return acc;
    }, {}) ?? {},
  );

  const handleChange = (identifier: string, checked: boolean) => {
    if (checked) {
      setCheckedTemplates([...checkedTemplates, identifier]);
      return;
    }

    setCheckedTemplates(
      checkedTemplates.filter((template) => template !== identifier),
    );
    setTemplateValues((prev) => {
      const newValues = { ...prev };
      delete newValues[identifier];
      return newValues;
    });
  };

  const handleNumericValueChange = (
    identifier: string,
    values: Record<string, number>,
  ) => {
    setTemplateValues({
      ...templateValues,
      [identifier]: values,
    });
  };

  const saveChanges = () => {
    setProfileSettings((profileConfig) => ({
      ...profileConfig,
      alertTemplates: checkedTemplates.map((template) => ({
        alertTemplateType: template,
        templateParams: templateValues[template],
      })),
    }));
  };

  const onRestoreDefaults = () => {
    setIsRestoring(true);
    setCheckedTemplates(
      DEFAULT_ALERT_TEMPLATES.map((template) => template.alertTemplateType) ??
        [],
    );

    setTemplateValues(
      DEFAULT_ALERT_TEMPLATES.reduce((acc, template) => {
        if (template.templateParams) {
          return {
            ...acc,
            [template.alertTemplateType]: template.templateParams,
          };
        }
        return acc;
      }, {}) ?? {},
    );

    setTimeout(() => {
      setIsRestoring(false);
    }, 500);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={2}>
      <Box
        flexGrow={1}
        overflow="auto"
        pr={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        pt={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
      >
        <Typography pb={1} color="primary" fontWeight={600} variant="body1">
          Alert Templates
        </Typography>
        {isLoading || isRestoring ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            display="grid"
            gridTemplateColumns={{
              xxs: 'auto',
              new2Xl: isSidebarOpen ? 'auto' : '1fr 1fr',
              new4Xl: '1fr 1fr',
            }}
            border={({ palette }) => `1px solid ${palette.gray.light}`}
            p={1.5}
            borderRadius={({ spacing }) => spacing(1)}
            rowGap={1}
          >
            {templates.map((template) => (
              <TemplateItem
                key={template.identifier}
                onChange={handleChange}
                onValueChange={handleNumericValueChange}
                template={template}
                defaultParams={
                  extractValues(
                    profileSettings.alertTemplates.find(
                      (alertTemplate) =>
                        alertTemplate.alertTemplateType === template.identifier,
                    )?.templateParams,
                  ) ?? []
                }
                isDefaultChecked={checkedTemplates.includes(
                  template.identifier,
                )}
              />
            ))}
          </Box>
        )}
        <Button
          sx={{ mt: 1 }}
          variant="text"
          size="small"
          startIcon={<Icon icon={replay.icons.normal} />}
          onClick={onRestoreDefaults}
        >
          Restore defaults
        </Button>
      </Box>
      <NavigationButtons
        onNextClick={() => {
          saveChanges();
          handleNext();
        }}
        onPreviousClick={() => {
          saveChanges();
          handlePrevious();
        }}
      />
    </Box>
  );
};

export default AlertSetup;
