import { DataGridProProps } from '@mui/x-data-grid-pro';
import { StyledDataGrid } from './Table.styled';

const Table = (props: DataGridProProps) => {
  return (
    <StyledDataGrid
      autoHeight
      pagination
      disableDensitySelector
      disableRowSelectionOnClick
      disableColumnResize
      rowHeight={64}
      columnHeaderHeight={52}
      {...props}
    />
  );
};

export default Table;
