import { usePerseverativeMotion } from 'pages/PatientAccount/hooks/use-perseverative-motion';
import { SelectWrapper } from './styled';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';

const PerseverativeMotionData = ({ readOnly }: { readOnly?: boolean }) => {
  const {
    isActive,
    isUpdating,
    isLoading,
    options,
    persevarativeMotionDuration,
    handleChange,
  } = usePerseverativeMotion();

  if (!isActive) {
    return null;
  }

  return (
    <SelectWrapper>
      <Typography variant="body2" color="primary">
        Perseveration Duration
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Select
          size="small"
          sx={({ spacing }) => ({
            width: spacing(21.25),
            maxHeight: spacing(4),
          })}
          onChange={(e) => {
            handleChange(Number(e.target.value));
          }}
          disabled={isUpdating}
          readOnly={readOnly}
          value={persevarativeMotionDuration}
          renderValue={(value) => {
            if (isLoading) return 'Loading...';
            return options.find((option) => option.value === value)?.label;
          }}
        >
          {options.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <HelpIcon
          tooltipPlacement="top-start"
          message="When monitoring for perseverative motion, the perseveration must last at least this long before any notifications will be sent."
        />
      </Box>
    </SelectWrapper>
  );
};

export default PerseverativeMotionData;
