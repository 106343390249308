import * as icons from 'assets/iconify';
import { Icon } from '@iconify/react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { errorFilled } from 'assets/iconify';

import {
  FallbackIcon,
  ImageButtonWrapper,
  ImagePreviewWrapper,
  ImageUploadPreview,
} from './styled';
import { useState } from 'react';

const ImageUploadButton = ({
  onClick,
  file,
  onClear,
  previewUrl,
  onError,
  disabled,
}: {
  onClear?: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  file: File | null;
  previewUrl?: string;
  onError?: () => void;
  disabled?: boolean;
}) => {
  const [imgError, setImgError] = useState(false);
  const imgHandlers = {
    onError: () => {
      setImgError(true);
      onError?.();
    },
    onLoad: () => setImgError(false),
  };
  return (
    <ImagePreviewWrapper>
      <ImageButtonWrapper>
        <IconButton
          disabled={disabled}
          sx={({ palette, spacing }) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 0,
            borderRadius: spacing(1.5),
            borderStyle: previewUrl || file ? 'none' : 'dashed',
            borderWidth: '6px',
            gap: 2,
            width: 170,
            height: 170,
            transform: 'translateX(-5px) translateY(-5px)',
            borderColor: palette.primary.light,
            backgroundColor: palette.primaryBackground.main,
            ':hover': {
              backgroundColor: palette.primaryBackground.dark,
            },
          })}
          onClick={onClick}
        >
          {!file && !previewUrl ? (
            <>
              <Icon
                width={64}
                height={64}
                icon={icons.addCiricle.icons.normal}
              />
              <Typography
                variant="h6"
                fontWeight="500"
                fontSize={20}
                lineHeight="32px"
                letterSpacing="0.15px"
                sx={({ palette }) => ({ color: palette.primary.light })}
              >
                Add Image
              </Typography>
            </>
          ) : file ? (
            <ImageUploadPreview
              {...imgHandlers}
              src={URL.createObjectURL(file)}
            />
          ) : (
            <ImageUploadPreview {...imgHandlers} src={previewUrl} />
          )}
          {imgError && (
            <Box sx={{ position: 'absolute', bottom: 4, right: 10 }}>
              <Tooltip
                placement="top"
                title={
                  <>Image didn't load correctly, please try adding it again.</>
                }
              >
                <FallbackIcon icon={errorFilled.icons.normal} />
              </Tooltip>
            </Box>
          )}
        </IconButton>
      </ImageButtonWrapper>
      {(file || previewUrl) && (
        <Tooltip title="Delete">
          <span>
            <IconButton
              sx={({ palette }) => ({
                maxHeight: 40,
                p: 1,
                transform: 'translateY(-15%)',
                ':hover': {
                  color: palette.error.light,
                },
              })}
              disabled={disabled}
              onClick={() => {
                onClear?.();
                setImgError(false);
              }}
            >
              <Icon width={24} height={24} icon={icons.cancel.icons.normal} />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </ImagePreviewWrapper>
  );
};

export default ImageUploadButton;
