import { Icon } from '@iconify/react';
import { Box, Button, DialogContent, Modal } from '@mui/material';
import { useAddRuleToTrigger } from 'pages/AlerterDetails/hooks/use-add-rule-to-trigger';
import { MetricRuleForm } from 'components/_extra';
import { useAppSelector } from 'store';
import {
  ModalContent,
  ModalHeader,
  ModalWrapper,
} from 'pages/AlerterDetails/components/Triggers/styled';
import { caregiverRole } from 'services/caregiver-role';

const AddConditionButton = () => {
  const triggerId = useAppSelector((state) => state.reminder.triggerId);
  const {
    functionRef,
    handleSave,
    handleClick,
    handleClose,
    handleFormChange,
    isOpen,
  } = useAddRuleToTrigger(triggerId ?? 0, true);

  return (
    <>
      <Button
        startIcon={<Icon icon="material-symbols:add-box-outline" />}
        onClick={handleClick}
        disabled={caregiverRole.value === 'viewer'}
      >
        Add Condition
      </Button>

      <Modal open={isOpen} onClose={handleClose}>
        <DialogContent
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ModalWrapper>
            <ModalHeader>Condition Setup</ModalHeader>
            <ModalContent>
              <MetricRuleForm
                functionsRef={functionRef}
                onChange={handleFormChange}
              />
              <Box display="flex" justifyContent="end" gap={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </ModalContent>
          </ModalWrapper>
        </DialogContent>
      </Modal>
    </>
  );
};

export default AddConditionButton;
