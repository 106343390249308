import { Box, Typography } from '@mui/material';
import ChangePassword from './ChangePassword';
import MfaToggle from './MfaToggle';

const Security = () => {
  return (
    <Box>
      <Typography variant="body1" fontWeight={600} color="primary">
        Security
      </Typography>
      <Box
        px={{ xxs: 1, newMd: 2 }}
        py={2}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <MfaToggle />
        <ChangePassword />
      </Box>
    </Box>
  );
};

export default Security;
