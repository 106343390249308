import { Button, ButtonProps } from '@mui/material';

const SaveButton = (props: ButtonProps) => {
  return (
    <Button color="primary" variant="contained" type="submit" {...props}>
      Save
    </Button>
  );
};

export default SaveButton;
