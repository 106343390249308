import { ConfirmationOptions } from 'components/_extra/ConfirmationDialog/ConfirmationDialog';
import { useConfirmation } from 'hooks/use-confirmation';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { bcApi } from 'store/api/_api';
import {
  useDeleteCredentialMutation,
  useLazyGetCredentialsQuery,
} from 'store/api/auth.api';
import { useGetLinkedProfilesQuery } from 'store/api/profiles.api';

const IS_EMERGENCY_PHONE_NUMBER: ConfirmationOptions = {
  title: 'This action cannot be completed',
  message: (
    <>
      Current phone number is in use as an “<strong>Emergency Contact</strong>”.
      Please contact your manager or technical support for further assistance.
    </>
  ),
  hideRejectButton: true,
  confirmButtonLabel: 'Got it',
};

const IS_THE_ONLY_CREDENTIAL: ConfirmationOptions = {
  title: 'This action cannot be completed',
  message:
    "Unfortunately, you can't delete your phone number as it's the only credential associated with your account.",
  hideRejectButton: true,
  confirmButtonLabel: 'Got it',
};

export const useDeletePhoneNumber = (id: number) => {
  const dispatch = useAppDispatch();
  const [
    deleteCredential,
    {
      isSuccess: wasDeleted,
      isError: wasNotDeleted,
      isLoading: isDeleting,
      reset,
    },
  ] = useDeleteCredentialMutation();
  const [getCredentials] = useLazyGetCredentialsQuery();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const caregiverId = useAppSelector((state) => state.user.accountId) as number;
  const { isLoading, data: relationships } = useGetLinkedProfilesQuery(
    { caregiverId },
    { skip: !caregiverId },
  );
  const confirm = useConfirmation();

  const handleDeletePhoneNumber = async () => {
    let credentials: {
      id: number;
      identifier: string;
      schema: string;
    }[] = [];

    const toastId = toast.loading('Loading...');

    await getCredentials()
      .unwrap()
      .then((result) => {
        credentials = result.results
          .filter(({ is_confirmed }) => is_confirmed)
          .map((item) => ({
            id: item.id,
            schema: item.schema,
            identifier: item.identifier,
          }));
      });

    toast.dismiss(toastId);

    if (credentials.length === 1) {
      confirm(IS_THE_ONLY_CREDENTIAL, () => {
        // do nothing
      });
      return;
    }

    const isEmergencyPhoneNumber = relationships?.results.some(
      (item) => item.subscriber_is_emergency_contact,
    );

    if (isEmergencyPhoneNumber) {
      confirm(IS_EMERGENCY_PHONE_NUMBER, () => {
        // do nothing
      });
      return;
    }

    setIsConfirmationOpen(true);
  };

  const handleFinishDeleteProcess = () => {
    deleteCredential({ id });
  };

  return {
    isLoading,
    handleFinishDeleteProcess,
    handleDeletePhoneNumber,
    isConfirmationOpen,
    onConfirmationClose: (shouldReset?: boolean) => {
      setIsConfirmationOpen(false);
      if (shouldReset) {
        dispatch(bcApi.util.invalidateTags(['ProfileData']));
        reset();
      }
    },
    isDeleting,
    wasDeleted,
    wasNotDeleted,
  };
};
