import { ReactNode, Ref } from 'react';
import { RecipientSetModal } from 'components/_extra/Alerter/RecipientSetModal';
import { useRecipientSets } from 'hooks/use-recipient-sets';

interface Props {
  children?: ReactNode;
  submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
  index: number;
  onClose?: () => void;
  handleClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
}

const EditAlerter = ({
  children,
  index,
  submitFormRef,
  handleClose,
  isOpen,
  isLoading,
}: Props) => {
  const { sets } = useRecipientSets();
  const defaultValues = sets[index];

  return (
    <RecipientSetModal
      selectedIndex={index}
      withScheduler
      defaultValues={defaultValues}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleClose}
      submitFormRef={submitFormRef}
    >
      {children}
    </RecipientSetModal>
  );
};

export default EditAlerter;
