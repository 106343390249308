import { Icon } from '@iconify/react';
import { Box, Link, Typography, styled, useTheme } from '@mui/material';
import { edit } from 'assets/iconify';
import { CredentialType } from 'models/auth.model';

const hideIdentifier = (identifier: string, schema: CredentialType) => {
  if (schema === 'email') {
    const [firstPart, secondPart] = identifier.split('@');
    return `${firstPart[0]}*******${
      firstPart[firstPart.length - 1]
    }@${secondPart}`;
  }

  const hidden = identifier.replace(/[0-9]/g, '•').slice(1);

  return (
    hidden.slice(0, hidden.length - 3) + identifier.slice(hidden.length - 2)
  );
};

const getCodeDestination = (to: CredentialType) =>
  to === 'email' ? 'email address' : 'phone number';

const CustomLink = styled(Link)(({ theme: { palette, spacing } }) => ({
  color: palette.secondary.main,
  display: 'flex',
  alignItems: 'center',
  gap: spacing(0.5),
  fontSize: '14px',
  lineHeight: '16px',
}));

interface IProps {
  credential?: string;
  type: CredentialType;
  obfuscateCredential?: boolean;
  onEditCredential?: () => void;
}

const CodeEnterMessage: React.FC<IProps> = ({
  credential,
  type,
  obfuscateCredential,
  onEditCredential,
}) => {
  const { palette } = useTheme();

  return (
    <Box pb={4}>
      <Typography textAlign="center" variant="h5">
        Enter the security code to continue
      </Typography>
      <Typography
        textAlign="center"
        variant="body1"
        color={palette.blue.main}
        mx={2}
      >
        A security code has been sent to your {getCodeDestination(type)}.
      </Typography>
      <Box display="flex" justifyContent="center" gap={1}>
        {credential && (
          <Typography textAlign="center" color={palette.black.main}>
            {obfuscateCredential
              ? hideIdentifier(credential, type)
              : credential}
          </Typography>
        )}
        {onEditCredential && (
          <CustomLink onClick={onEditCredential}>
            <Icon width={20} height={20} icon={edit.icons.normal} />
            Edit
          </CustomLink>
        )}
      </Box>
    </Box>
  );
};

export default CodeEnterMessage;
