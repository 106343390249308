import { Icon } from '@iconify/react';
import { Box, Button, Checkbox, FormLabel, Typography } from '@mui/material';
import { personAdd } from 'assets/iconify';
import { BasicModal } from 'components/BasicModal';
import VInput from 'components/VInput';
import { useState } from 'react';
import { Caregiver, useAddCaregiver } from '../hooks/use-add-caregiver';
import { CustomPhoneInput } from 'components/CustomPhoneInput';

const AddCaregiver = ({
  onCreate,
  isInTable,
  existingCaregivers,
}: {
  onCreate: (data: Caregiver) => void;
  isInTable?: boolean;
  existingCaregivers?: string[];
}) => {
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const { formState, handleSubmit, register, setValue } = useAddCaregiver(
    onCreate,
    () => setOpen(false),
    () => setPhoneNumber(''),
    existingCaregivers,
  );

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
    setValue('phoneNumber', value, { shouldDirty: true });
  };

  return (
    <>
      <Button
        startIcon={<Icon icon={personAdd.icons.filled} />}
        onClick={() => setOpen(true)}
        variant={isInTable ? 'text' : 'contained'}
        size={isInTable ? 'small' : 'medium'}
        sx={
          isInTable
            ? ({ spacing }) => ({
                position: 'absolute',
                left: spacing(1.5),
                bottom: spacing(1),
              })
            : undefined
        }
      >
        Add Caregiver
      </Button>
      {open && (
        <BasicModal open={open} onClose={() => setOpen(false)}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h5" textAlign="center">
              Add a caregiver
            </Typography>
            <Typography
              variant="body1"
              color="blue.main"
              textAlign="center"
              pb={4}
              px={2}
            >
              Enter the caregiver’s information. If a caregiver has already
              registered with the provided email address, they will be
              automatically added to this individual’s caregiver group.
            </Typography>
            <VInput
              label="Email"
              placeholder="example@mail.com"
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
              disableWhitespace
              containerSx={{ pb: 1, px: 1, pt: 1.5 }}
              {...register('email')}
            />
            <VInput
              label="First Name"
              placeholder="John"
              error={!!formState.errors.firstName}
              helperText={formState.errors.firstName?.message}
              disableWhitespace
              containerSx={{ pb: 1, px: 1, pt: 1.5 }}
              {...register('firstName')}
            />
            <VInput
              label="Last Name"
              placeholder="Smith"
              disableWhitespace
              containerSx={{ pb: 1, px: 1, pt: 1.5 }}
              {...register('lastName')}
            />
            <CustomPhoneInput
              label="Phone Number"
              value={phoneNumber}
              containerProps={{
                mt: 1.5,
                mx: 1,
              }}
              onChange={handlePhoneNumberChange}
            />
            <Box display="flex" flexWrap="wrap" m={1} mt={2} columnGap={2}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  id="caregiverRequiresMfa"
                  {...register('requiresMfa', { shouldUnregister: true })}
                />
                <FormLabel
                  htmlFor="caregiverRequiresMfa"
                  sx={{ color: ({ palette }) => palette.primary.main }}
                >
                  Required 2FA
                </FormLabel>
              </Box>
              <Box display="flex" alignItems="center">
                <Checkbox
                  id="isCaregiverManager"
                  {...register('isManager', { shouldUnregister: true })}
                />
                <FormLabel
                  htmlFor="isCaregiverManager"
                  sx={{ color: ({ palette }) => palette.primary.main }}
                >
                  Manager Access
                </FormLabel>
              </Box>
            </Box>
            <Box sx={{ pt: 3, px: 1, pb: 1 }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                disabled={!formState.isDirty}
              >
                Add
              </Button>
            </Box>
          </form>
        </BasicModal>
      )}
    </>
  );
};

export default AddCaregiver;
