import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const close: IconifyJSON = {
  prefix: 'close',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M6.40002 18.6538L5.34619 17.6L10.9462 12L5.34619 6.4L6.40002 5.34618L12 10.9462L17.6 5.34618L18.6538 6.4L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.40002 18.6538Z" fill="currentColor"/>',
    },
  },
};
