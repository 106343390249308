import dayjs from 'dayjs';
import { FormatDate } from '../types/enum/date-format.enum';

const formatTimeUnits = (unit: string, amount: number) =>
  `${amount} ${amount !== 1 ? `${unit}s` : unit}`;

export const formatIncidentDate = (
  isoDate: string | undefined,
  now: number,
): string => {
  if (typeof isoDate === 'undefined') {
    return '';
  }

  if (!isoDate.length) {
    return '';
  }

  const fallDate = new Date(isoDate);
  const minutesFromFall = Math.floor((now - fallDate.getTime()) / 60000);
  const hoursFromFall = Math.floor(minutesFromFall / 60);

  if (hoursFromFall < 24) {
    const onlyMinutes = minutesFromFall - hoursFromFall * 60;

    return `${
      hoursFromFall ? formatTimeUnits('hour', hoursFromFall) + ' ' : ''
    }${onlyMinutes ? formatTimeUnits('minute', onlyMinutes) + ' ' : ''}ago`;
  }

  return dayjs(isoDate).format(FormatDate.INCIDENT_DATE_TIME);
};

export const shortFormatIncidentDate = (isoDate: string): string => {
  if (!isoDate.length) {
    return '';
  }

  return dayjs(isoDate).format(FormatDate.SHORT_INCIDENT_DATE_TIME);
};

export const getLastHeartAck = (...args: (string | undefined)[]): string => {
  return (
    [...args]
      .filter((value) => value !== undefined)
      .sort()
      .pop() || ''
  );
};

/**
 * A function that checks if first passed (ISO string format) date was before the second one.
 *
 * @param firstEvent
 * @param secondEvent
 * @returns boolean
 */
export const wasBefore = (
  firstEvent: number | string | undefined,
  secondEvent: number | string | undefined,
): boolean => {
  if (!!firstEvent && !!secondEvent) {
    return dayjs(firstEvent).isBefore(secondEvent);
  }

  return false;
};
