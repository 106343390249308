import { RecipientManagerCard, Summary } from 'components/_extra';
import SharedAlertersDetails from 'components/_extra/Alerter/Cards/SharedAlertersDetails';
import { CombinedRecipientManager } from 'store/reducers/alerter/alerter.slice';
import { AlerterRecipientManager } from 'types/alerters';
import { getDaysSummary } from 'utils/weekdays';

type Props = {
  id: number;
  title: string;
  isActive?: boolean;
  onEdit?: () => void;
  onCopy?: () => void;
  onRemove?: (id: number, title: string, alerts?: string[]) => void;
  alerters: string[];
  defaultValues?: CombinedRecipientManager | AlerterRecipientManager;
  recipientsCount: number;
  patientsCount: number;
  isReminder?: boolean;
} & (
  | {
      defaultValues: AlerterRecipientManager;
      isReminder?: true;
    }
  | {
      defaultValues: CombinedRecipientManager;
      isReminder?: false;
    }
);

const Card = ({
  title,
  onEdit,
  alerters,
  defaultValues,
  recipientsCount,
  patientsCount,
  onRemove,
  onCopy,
  isActive,
  isReminder,
}: Props) => {
  return (
    <RecipientManagerCard
      withoutSwitch
      title={title}
      isActive={isActive}
      onCopy={onCopy}
      onEdit={onEdit}
      onDelete={() => onRemove?.(defaultValues.id, title, alerters)}
    >
      {!isReminder && 'days' in defaultValues && (
        <Summary
          title="Schedule"
          details={getDaysSummary(defaultValues.days)}
        />
      )}
      <Summary
        title="Recipients"
        details={
          <>
            {recipientsCount} Caregiver{recipientsCount !== 1 && 's'}
            {patientsCount > 0 ? (
              <>
                <br />
                {patientsCount} Patient{patientsCount !== 1 && 's'}
              </>
            ) : null}
          </>
        }
      />
      <Summary
        title="Used by"
        details={<SharedAlertersDetails alerters={alerters} />}
      />
    </RecipientManagerCard>
  );
};
export default Card;
