import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type Caregiver = {
  id: number;
  email: string;
  firstName: string;
  lastName?: string;
  phoneNumber?: string;
  requiresMfa?: boolean;
  isManager?: boolean;
};

const schema = yup.object({
  email: yup
    .string()
    .email('The email address is invalid.')
    .required('Email address is required.'),
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string(),
  phoneNumber: yup.string(),
  requiresMfa: yup.boolean(),
  isManager: yup.boolean(),
});

export const useAddCaregiver = (
  onCreate: (data: Caregiver) => void,
  onClose?: () => void,
  resetExternal?: () => void,
  existingCaregivers?: string[],
) => {
  const { handleSubmit, formState, register, setValue, reset, setError } =
    useForm<Caregiver>({
      resolver: yupResolver(schema),
    });

  const onSubmit = (data: Caregiver) => {
    if (existingCaregivers && existingCaregivers.includes(data.email)) {
      setError('email', {
        type: 'manual',
        message: 'There is already a caregiver with this email.',
      });
      return;
    }
    onCreate(data);
    onClose?.();
    resetExternal?.();
    reset();
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    formState,
    register,
    setValue,
  };
};
