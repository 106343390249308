import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const stepCount: IconifyJSON = {
  prefix: 'step-count',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M6.38989 1.5C5.61989 1.5 4.53989 1.8 3.71989 3.23C3.16989 4.2 2.88989 5.3 2.88989 6.5C2.88989 7.61 3.05989 8.66 3.38989 9.64C3.71989 10.61 4.07989 11.37 4.47989 11.96L4.85989 12.52L5.51989 12.37L7.96989 11.82L8.45989 11.71L8.65989 11.25C8.89989 10.69 9.15989 10 9.42989 9.19C9.71989 8.31 9.86989 7.4 9.86989 6.5C9.86989 5.3 9.58989 4.19 9.03989 3.23C8.21989 1.8 7.13989 1.5 6.36989 1.5H6.38989ZM8.83989 13.18L8.16989 13.33L5.88989 13.83L5.09989 14V14.8C5.09989 15.75 5.29989 16.57 5.69989 17.24C6.17989 18.05 6.98989 18.5 7.96989 18.5C8.56989 18.5 9.10989 18.26 9.51989 17.8C9.89989 17.37 10.0999 16.82 10.0999 16.17C10.0999 15.75 9.99989 15.31 9.80989 14.85C9.63989 14.46 9.43989 14.09 9.20989 13.74L8.82989 13.17L8.83989 13.18ZM17.6099 5.5C16.8399 5.5 15.7599 5.8 14.9399 7.23C14.3899 8.2 14.1099 9.3 14.1099 10.5C14.1099 11.4 14.2599 12.31 14.5499 13.19C14.8199 14 15.0799 14.69 15.3199 15.25L15.5199 15.71L16.0099 15.82L18.4599 16.37L19.1199 16.52L19.4999 15.96C19.8999 15.36 20.2599 14.6 20.5899 13.64C20.9199 12.66 21.0899 11.61 21.0899 10.5C21.0899 9.3 20.8099 8.19 20.2599 7.23C19.4399 5.8 18.3599 5.5 17.5899 5.5H17.6099ZM15.1599 17.18L14.7799 17.75C14.5499 18.1 14.3399 18.47 14.1799 18.86C13.9899 19.32 13.8899 19.76 13.8899 20.18C13.8899 20.83 14.0899 21.38 14.4699 21.81C14.8799 22.27 15.4099 22.51 16.0199 22.51C16.9999 22.51 17.8099 22.06 18.2899 21.25C18.6899 20.58 18.8899 19.76 18.8899 18.81V18.01L18.0999 17.84L15.8199 17.34L15.1499 17.19L15.1599 17.18Z" fill="currentColor"/>',
    },
  },
};
