import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  MenuItem,
  TextField,
  Tooltip,
  autocompleteClasses,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { RemoveButton } from 'components/_extra';
import { LevelLabel } from './RecipientSetEditor.styled';
import { Fragment, SyntheticEvent, useRef } from 'react';
import { AlerterRecipient } from 'types/alerters';
import { HelpIcon } from 'components/HelpIcon';
import { areRecipientsEqual } from 'pages/Alerters/utils/hierarchy';

const getNameList = (options: AlerterRecipient[], limit: number) => {
  return (
    <>
      {options.slice(limit).map(({ name }, index) => (
        <Fragment key={index}>
          {name} <br />
        </Fragment>
      ))}
    </>
  );
};

const compare = (a: AlerterRecipient, b: AlerterRecipient) => {
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  minWidth: 'min-content',
  flexWrap: 'nowrap',
  [`.${inputBaseClasses.root}`]: {
    maxHeight: theme.spacing(5),
    flexWrap: 'nowrap',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('newMd')]: {
      borderRadius: theme.spacing(0.5) + '!important',
    },
    [theme.breakpoints.up('xxs')]: {
      borderRadius: '0',
    },
    height: theme.spacing(5),
    [`.${inputBaseClasses.input}`]: {
      height: theme.spacing(3),
      whiteSpace: 'no-wrap',
      textOverflow: 'ellipsis',
    },
  },
}));

interface Props {
  level: number;
  recipients: AlerterRecipient[];
  selectedRecipients: AlerterRecipient[];
  isRemoveDisabled?: boolean;
  onChange?: (selected: AlerterRecipient[], level: number) => void;
  onRemove?: () => void;
  error?: string;
  readOnly?: boolean;
}

const RecipientLevel = ({
  level,
  recipients,
  isRemoveDisabled,
  selectedRecipients,
  onChange,
  onRemove,
  error: errorMessage,
  readOnly,
}: Props) => {
  const handleChange = (_: SyntheticEvent, selected: AlerterRecipient[]) => {
    onChange?.(selected, level);
  };
  const inputRef = useRef<HTMLDivElement>(null);

  const handleRemove = () => {
    onRemove?.();
  };

  const error = selectedRecipients.length === 0 ? errorMessage : undefined;

  const areAllCaregiversSelected =
    selectedRecipients.findIndex((rec) => rec.patternType === 'all_users') !==
    -1;

  const areAllPatientsSelected =
    selectedRecipients.findIndex(
      (rec) => rec.patternType === 'all_patients',
    ) !== -1;

  const { caregivers, groups, patients } = [
    ...selectedRecipients,
    ...recipients,
  ].reduce(
    (prev, curr) => {
      if (!!curr.patternType) {
        return {
          ...prev,
          groups: prev.groups.concat(curr),
        };
      }

      if (curr.recipientType === 'User') {
        return {
          ...prev,
          caregivers: prev.caregivers.concat(curr),
        };
      }

      if (curr.recipientType === 'PatientProfile') {
        return {
          ...prev,
          patients: prev.patients.concat(curr),
        };
      }

      return prev;
    },

    {
      groups: [],
      caregivers: [],
      patients: [],
    } as Record<'groups' | 'caregivers' | 'patients', AlerterRecipient[]>,
  );

  const options = [
    ...groups.sort(compare),
    ...caregivers.sort(compare),
    ...patients.sort(compare),
  ];

  return (
    <Box
      display="flex"
      alignItems="start"
      width="100%"
      overflow="hidden"
      sx={{
        gap: { newMd: 2 },
      }}
    >
      <LevelLabel disabled={readOnly} isError={!!error}>
        L{level}
      </LevelLabel>
      <Autocomplete
        options={options}
        isOptionEqualToValue={(option, value) =>
          areRecipientsEqual(option, value)
        }
        value={selectedRecipients}
        getOptionLabel={(option) => option.name}
        multiple
        groupBy={({ patternType, recipientType }) =>
          !!patternType
            ? 'Groups'
            : recipientType === 'User'
            ? 'Caregivers'
            : 'Patients'
        }
        disableListWrap
        ChipProps={{ size: 'small' }}
        disableCloseOnSelect
        disabled={readOnly}
        placeholder="Select recipients..."
        onChange={handleChange}
        renderOption={(props, option, { selected }) => {
          const isReadOnly =
            (areAllCaregiversSelected && option.recipientType === 'User') ||
            (areAllPatientsSelected &&
              option.recipientType === 'PatientProfile');
          const isSelected = selected || isReadOnly;
          return (
            <MenuItem
              {...props}
              sx={{
                pointerEvents: isReadOnly ? 'none' : 'all',
                [`&[aria-selected="true"].Mui-focused:not(:hover)`]: {
                  backgroundColor: ({ palette }) =>
                    palette.common.white + '!important',
                },
              }}
              selected={isSelected}
            >
              <Checkbox
                readOnly={isReadOnly}
                sx={{ mr: 1, py: 0 }}
                checked={isSelected}
                size="small"
              />
              {option.name}
              {option.patternType === 'all_users' && (
                <Box ml={1} display="flex" alignContent="center">
                  <HelpIcon message="This option will select all existing caregivers, including any caregivers added afterwards." />
                </Box>
              )}
            </MenuItem>
          );
        }}
        renderTags={(options, getTagProps) => {
          const numTags = options.length;

          const tagLimit =
            options.length >= 2
              ? options[0].name.length + options[1].name.length > 30
                ? 1
                : 2
              : 2;

          return (
            <>
              {options.slice(0, tagLimit).map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={`option.id${index}`}
                  size="small"
                  color={
                    !!option.patternType
                      ? 'secondary'
                      : option.recipientType === 'PatientProfile'
                      ? 'error'
                      : 'default'
                  }
                  label={option.name}
                />
              ))}

              {numTags > tagLimit && (
                <span>
                  <Tooltip
                    title={getNameList(options, tagLimit)}
                    PopperProps={{ sx: { pb: 0.5 } }}
                  >
                    <Chip
                      sx={{ m: '3px' }}
                      size="small"
                      label={`+${numTags - tagLimit}`}
                    />
                  </Tooltip>
                </span>
              )}
            </>
          );
        }}
        renderInput={(props) => (
          <StyledTextField
            error={!!error}
            ref={inputRef}
            helperText={error}
            {...props}
          />
        )}
        fullWidth
        sx={({ spacing }) => ({
          [`.${autocompleteClasses.inputRoot}`]: {
            maxWidth: {
              newXl: spacing(46),
            },
            flexWrap: 'nowrap',
          },
        })}
      />
      <RemoveButton
        onClick={handleRemove}
        disabled={isRemoveDisabled || readOnly}
        size="medium"
        buttonSx={({ spacing }) => ({
          borderRadius: {
            xxs: spacing(0, 0.5, 0.5, 0),
            newMd: spacing(0.5),
          },
        })}
      />
    </Box>
  );
};

export default RecipientLevel;
