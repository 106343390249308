import { Icon } from '@iconify/react';
import { Button, IconButton, useTheme } from '@mui/material';
import { useAddProfileModal } from 'pages/PatientsOverview/hooks/use-add-profile-modal';
import { memo } from 'react';
import AddProfileModal from './AddProfileModal';

const AddProfileButton = ({ isInTable }: { isInTable?: boolean }) => {
  const { spacing } = useTheme();
  const { handleClick, handleModalClose, isLoading, open } =
    useAddProfileModal();

  const sx = isInTable
    ? { position: 'absolute', left: spacing(2), bottom: spacing(1.25) }
    : {};

  return (
    <>
      <Button
        size={isInTable ? 'small' : 'large'}
        variant={isInTable ? 'text' : 'contained'}
        startIcon={<Icon icon="material-symbols:person-add-outline" />}
        sx={{
          ...sx,
          display: isInTable
            ? { xxs: 'none', sm: 'inline-flex' }
            : 'inline-flex',
        }}
        onClick={handleClick}
      >
        Add Profile
      </Button>
      {isInTable && (
        <IconButton
          size="small"
          color="secondary"
          onClick={handleClick}
          sx={{
            ...sx,
            p: 0.5,
            ml: 0.75,
            display: { xxs: 'flex', sm: 'none' },
          }}
        >
          <Icon icon="material-symbols:person-add-outline" />
        </IconButton>
      )}
      <AddProfileModal
        open={open}
        isLoading={isLoading}
        onClose={handleModalClose}
      />
    </>
  );
};

export default memo(AddProfileButton);
