import { useGetProfileQuery } from 'store/api/profiles.api';
import { useAppSelector } from 'store';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Route, routes } from 'nav';
import { pathnameToRouterPath } from 'utils/pathname-to-router-path';
import {
  getCustomAppbarTitle,
  getCustomAppbarTooltip,
} from 'services/custom-appbar-title';

const getViewTitle = (path: string) => {
  let viewTitle = '';

  Object.values(routes).forEach((item) => {
    Object.values(item).forEach((subItem: Route) => {
      const routerPath = subItem.routerPath || subItem.href;

      if (routerPath === path) {
        viewTitle = subItem.name || '';
      }
    });
  });

  if (viewTitle.length === 0) {
    viewTitle = getCustomAppbarTitle();
  }

  return viewTitle;
};

export const useAppbar = () => {
  const accountId = useAppSelector((store) => store.user.accountId);
  const { pathname } = useLocation();
  const params = useParams();

  const { data } = useGetProfileQuery(
    { id: accountId || 0 },
    { skip: !accountId },
  );

  const viewTitle = useMemo(
    () => getViewTitle(pathnameToRouterPath(pathname, params)),
    [pathname, getCustomAppbarTitle()],
  );

  const tooltipContent = getCustomAppbarTooltip();

  const avatarText = useMemo(() => {
    const defaultName = 'BC';

    if (!data) {
      return defaultName;
    }

    const firstName = data.result.first_name as string | null | undefined;
    const lastName = data.result.last_name as string | null | undefined;

    if (!firstName || firstName.length < 1) {
      return defaultName;
    }

    return `${firstName[0]}${
      lastName && lastName.length > 0 ? lastName[0] : ''
    }`;
  }, [data]);

  return {
    avatarText,
    viewTitle,
    tooltipContent,
  };
};
