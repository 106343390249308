import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useDeliverCodeMutation,
  useValidateCodeMutation,
} from 'store/api/auth.api';

export const useCodeInput = ({
  credentialId,
  identifier,
  onCompletion,
  setRemainingSeconds,
}: {
  credentialId?: number;
  identifier: string;
  onCompletion?: () => void;
  setRemainingSeconds?: (seconds: number) => void;
}) => {
  const [code, setCode] = useState<string | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState<string>('');

  const [validateCode, { isLoading }] = useValidateCodeMutation();
  const [deliverCode, { isLoading: isDeliverLoading }] =
    useDeliverCodeMutation();

  const handleInputCompletion = (value: string) => {
    setCode(value);
    if (value.length === 6) {
      setIsSubmitDisabled(false);
    }
  };

  const handleCodeResend = () => {
    if (!credentialId) {
      return;
    }

    deliverCode({
      id: credentialId,
    }).then(() => {
      toast.success('Code has been resent.');
      setRemainingSeconds?.(60);
    });
  };

  const handleSubmit = () => {
    if (!code) {
      return;
    }

    validateCode({
      code,
      identifier,
    }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        setError(
          'Make sure you typed your code correctly. If your code has expired resend a new one.',
        );
        return;
      }

      onCompletion?.();
    });
  };

  const handleInputReset = () => {
    setCode(null);
    setIsSubmitDisabled(true);
  };

  return {
    code,
    handleInputCompletion,
    handleCodeResend,
    error,
    handleSubmit,
    handleInputReset,
    isSubmitDisabled,
    isLoading: isLoading || isDeliverLoading,
  };
};
