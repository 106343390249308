import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const replay: IconifyJSON = {
  prefix: 'replay',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12 21.5C10.8205 21.5 9.71542 21.2769 8.68475 20.8307C7.65392 20.3846 6.75517 19.7782 5.9885 19.0115C5.22183 18.2448 4.61542 17.3461 4.16925 16.3152C3.72308 15.2846 3.5 14.1795 3.5 13H5C5 14.95 5.67917 16.6042 7.0375 17.9625C8.39583 19.3208 10.05 20 12 20C13.95 20 15.6042 19.3208 16.9625 17.9625C18.3208 16.6042 19 14.95 19 13C19 11.05 18.3208 9.39582 16.9625 8.03749C15.6042 6.67916 13.95 5.99999 12 5.99999H11.7345L13.323 7.58849L12.2692 8.67299L8.84625 5.24049L12.2885 1.80774L13.3422 2.89224L11.7345 4.49999H12C13.1795 4.49999 14.2846 4.72307 15.3152 5.16924C16.3461 5.61541 17.2448 6.22182 18.0115 6.98849C18.7782 7.75516 19.3846 8.65391 19.8307 9.68474C20.2769 10.7154 20.5 11.8205 20.5 13C20.5 14.1795 20.2769 15.2846 19.8307 16.3152C19.3846 17.3461 18.7782 18.2448 18.0115 19.0115C17.2448 19.7782 16.3461 20.3846 15.3152 20.8307C14.2846 21.2769 13.1795 21.5 12 21.5Z" fill="currentColor"/>',
    },
  },
};
