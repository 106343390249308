import { Icon } from '@iconify/react';
import { FileUploadArea, StyledTypography } from './styled';
import * as icons from 'assets/iconify';
import { Box, Link } from '@mui/material';
import { DragEvent, useRef, useState } from 'react';
import theme from 'styles/theme';
import { toast } from 'react-toastify';

const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

const FileUpload = ({ onUpload }: { onUpload?: (file: File) => void }) => {
  const [dragOver, setDragOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const uploadFile = (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      toast.error('File exceeds size limit');
      return;
    }
    onUpload?.(file);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      uploadFile(file);
      e.dataTransfer.clearData();
      clearInput();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      uploadFile(file);
      clearInput();
    }
  };

  return (
    <FileUploadArea
      onDragOver={(e) => {
        e.preventDefault();
        setDragOver(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setDragOver(false);
      }}
      onDrop={handleDrop}
      style={{
        backgroundColor: dragOver
          ? theme.palette.primaryBackground.dark
          : theme.palette.primaryBackground.main,
        padding: 20,
      }}
    >
      <input
        accept="image/*"
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Icon
        style={{
          transform: dragOver ? 'translateY(-4px)' : undefined,
          transition: '0.2s ease-in-out',
        }}
        width={48}
        height={48}
        icon={icons.cloudUpload.icons.normal}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <StyledTypography>
          Drag & drop or{' '}
          <Link onClick={() => inputRef.current?.click()}>Choose file</Link> for
          upload
        </StyledTypography>
        <StyledTypography>Maximum: 10MB</StyledTypography>
      </Box>
    </FileUploadArea>
  );
};

export default FileUpload;
