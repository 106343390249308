/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EditRecipientSetForm } from 'components/_extra/Alerter';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useGetAllPossibleRecipientsQuery,
  useGetPossibleRecipientsQuery,
} from 'store/api/alerters.api';
import {
  AlerterRecipient,
  AlerterRecipientSet,
  PatternType,
} from 'types/alerters';
import {
  areRecipientsEqual,
  getLayersFromSet,
  selectAllFromGroup,
} from '../utils/hierarchy';
import { useParams } from 'react-router-dom';

export interface HierarchyLayer {
  recipients: AlerterRecipient[];
  selected: AlerterRecipient[];
  layerId: number;
  id?: number;
}

const LAYERS_MAX = 5;

export const useHierarchy = (sets: AlerterRecipientSet[]) => {
  const { setValue } = useFormContext<EditRecipientSetForm>();
  const { profileId } = useParams();

  const [layers, setLayers] = useState<HierarchyLayer[]>(
    sets.map((set) => getLayersFromSet(set)),
  );

  const id = sets[0].id ?? 0;
  const { data: currentPossibleRecipients } = useGetPossibleRecipientsQuery(
    id,
    {
      skip: !id || id === 0,
    },
  );

  const { data: allPossibleRecipients } = useGetAllPossibleRecipientsQuery(
    Number(profileId),
    {
      skip: !profileId || id !== 0,
    },
  );

  const possibleRecipients = currentPossibleRecipients ?? allPossibleRecipients;

  useEffect(() => {
    setLayers((data) =>
      data.map((d) => ({
        ...d,
        recipients:
          possibleRecipients?.filter(
            (rec) =>
              !!rec.name &&
              d.selected.findIndex(({ id }) => rec.id === id) === -1,
          ) ?? [],
      })),
    );
  }, [possibleRecipients]);

  const availableRecipients = useRef<AlerterRecipient[]>([]);

  useEffect(() => {
    if (possibleRecipients) {
      availableRecipients.current.push(
        ...possibleRecipients.filter((rec) => !!rec.name),
      );
    }
  }, [possibleRecipients]);

  const addNewLayer = useCallback(() => {
    setLayers((layers) => [
      ...layers,
      {
        recipients: availableRecipients.current,
        level: layers.length + 1,
        selected: [],
        layerId: layers[layers.length - 1].layerId + 1,
      },
    ]);
  }, []);

  useEffect(() => {
    const values = layers.map((layer, index) => {
      return {
        recipients: layer.selected.map((recipient) => {
          const type: PatternType | 'User' | 'PatientProfile' =
            recipient.recipientType ?? recipient.patternType;
          return {
            id: recipient.id,
            recipientId: recipient.recipientId,
            type,
          };
        }),
        priority: (index + 1) * 10,
        id: layer.id,
      };
    });

    setValue('recipients', values);
  }, [layers]);

  const handleRecipientsSelection = (
    newSelected: AlerterRecipient[],
    level: number,
  ) => {
    const index = level - 1;
    setValue('recipients', [], { shouldDirty: true });

    if (newSelected.length < layers[index].selected.length) {
      const toExclude = layers[index].selected.filter(
        (rec) =>
          !newSelected.find((selected) => areRecipientsEqual(selected, rec)),
      );

      if (toExclude.length > 0) {
        setLayers((layers) =>
          layers.map((layer, i) => {
            if (i !== index) {
              return {
                ...layer,
                recipients: [...layer.recipients, ...toExclude],
              };
            }
            return {
              ...layer,
              selected: layer.selected.filter((el) =>
                newSelected.find((sel) => areRecipientsEqual(el, sel)),
              ),
            };
          }),
        );
        availableRecipients.current.push(...toExclude);
        layers[index].recipients.push(...toExclude);
      }

      return;
    }

    const newAdded = availableRecipients.current.find(
      (rec) => !!newSelected.find((s) => areRecipientsEqual(s, rec)),
    );

    if (!!newAdded?.patternType) {
      selectAllFromGroup(newAdded, index, setLayers);
      availableRecipients.current.filter(
        (rec) => rec.patternType !== newAdded.patternType,
      );
      return;
    }

    if (newAdded) {
      availableRecipients.current = availableRecipients.current.filter(
        (rec) => !areRecipientsEqual(rec, newAdded),
      );
      setLayers((layers) => {
        return layers.map((layer, index) => {
          if (index !== level - 1) {
            return {
              ...layer,
              recipients: layer.recipients.filter(
                (rec) => !areRecipientsEqual(rec, newAdded),
              ),
            };
          }

          return {
            ...layer,
            recipients: layer.recipients.filter(
              (rec) => !areRecipientsEqual(rec, newAdded),
            ),
            selected: [...layer.selected, newAdded],
          };
        });
      });

      return;
    }

    toast.error('something went wrong');
  };

  const handleRemove = (index: number) => {
    if (availableRecipients) {
      availableRecipients.current.concat(layers[index].selected);
    }
    setLayers((layers) => {
      const newLayers = layers.slice();
      newLayers.splice(index, 1);
      return newLayers;
    });
    setValue('recipients', [], { shouldDirty: true });
  };

  return {
    layers,
    addNewLayer,
    isAddNewLayerDisabled:
      layers.length >= LAYERS_MAX ||
      layers[layers.length - 1]?.selected.length < 1,
    handleRecipientsSelection,
    handleRemove,
  };
};
