import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { memo, useEffect, useRef } from 'react';
import CaregiverItem from './CaregiverItem';
import { AddCaregiverButton } from './AddCaregiverButton';
import { useGetLinkedCaregiversQuery } from 'store/api/profiles.api';
import { useAppSelector } from 'store';
import { useUpdatePrimaryCaregiversMutation } from 'store/api/account.api';
import { toast } from 'react-toastify';
import { GridWrapper } from '../generic';
import { caregiverRole } from 'services/caregiver-role';

const Caregivers = () => {
  const theme = useTheme();
  const primaryCaregiversRef = useRef<number[]>([]);

  const profileId = useAppSelector((state) => state.dashboard.profileId);

  const {
    data = { accounts: {}, results: {} },
    isFetching,
    refetch,
  } = useGetLinkedCaregiversQuery({
    profileId,
  });
  const [updateCaregivers, { isLoading: isRoleUpdateLoading }] =
    useUpdatePrimaryCaregiversMutation();

  useEffect(() => {
    if (data) {
      primaryCaregiversRef.current = Object.values(data.results)
        .filter((item) => item.subscriber_is_primary)
        .map((item) => item.subscribing_account_id);
    }
  }, [data]);

  const handleCaregiverDelete = () => {
    refetch();
  };

  const handleRoleChange = (caregiverId: number, role: 'viewer' | 'admin') => {
    if (data) {
      if (role === 'viewer') {
        primaryCaregiversRef.current.push(caregiverId);
      } else {
        primaryCaregiversRef.current = primaryCaregiversRef.current.filter(
          (item) => item !== caregiverId,
        );
      }

      toast.promise(
        updateCaregivers({
          caregiverIds: primaryCaregiversRef.current,
          profileId,
        }).unwrap,
        {
          pending: 'Updating the role...',
          success: 'The role was updated succesfully!',
          error: 'Could not update the role. Please try again.',
        },
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Typography color="primary" fontWeight={600}>
        Caregiver Group
      </Typography>
      <GridWrapper sx={{ px: { xxs: 0, newLg: 1 }, py: 2 }}>
        {isFetching
          ? [1, 2].map((key) => (
              <Skeleton
                key={key}
                variant="rounded"
                height={theme.spacing(8)}
                sx={{
                  m: 1,
                  width: '100%',
                  borderRadius: ({ spacing }) => spacing(1),
                }}
              />
            ))
          : Object.values(data.accounts).map((item) => {
              const relationshipObj = Object.values(data.results).find(
                (jtem) => jtem.subscribing_account_id === item.id,
              );

              if (relationshipObj) {
                return (
                  <CaregiverItem
                    key={item.id}
                    id={relationshipObj.id}
                    accountId={relationshipObj.subscribing_account_id}
                    name={[item.first_name, item.last_name].filter((name) => name).join(' ')}
                    isAdmin={relationshipObj.subscriber_is_primary}
                    isDisabled={isRoleUpdateLoading}
                    isCurrentUserAdmin={caregiverRole.value !== 'viewer'}
                    onDelete={handleCaregiverDelete}
                    onRoleChange={(role) =>
                      handleRoleChange(
                        relationshipObj.subscribing_account_id,
                        role,
                      )
                    }
                  />
                );
              }
            })}
      </GridWrapper>
      {!(caregiverRole.value === 'viewer') && <AddCaregiverButton />}
    </Box>
  );
};

export default memo(Caregivers);
