import { StyledInput } from './RecipientSetEditor.styled';
import { useFormContext } from 'react-hook-form';
import { HelperText } from 'pages/AlerterDetails/components/Settings/styled';
import { EditRecipientSetForm } from './RecipientSetEditor';

interface Props {
  defaultTitle?: string;
  usedIn?: string[];
  readOnly?: boolean;
}

const General = ({ defaultTitle, readOnly }: Props) => {
  const { register, formState } = useFormContext<EditRecipientSetForm>();

  const titleError = formState.errors.name?.message;

  return (
    <div>
      <StyledInput
        size="medium"
        defaultValue={defaultTitle}
        placeholder="Add title"
        readOnly={readOnly}
        sx={{ ml: 2, mb: !!titleError ? 0 : 2, mt: 1.25 }}
        error={!!titleError}
        {...register('name')}
      />
      {titleError && (
        <HelperText sx={{ px: 2 }} error>
          {titleError}
        </HelperText>
      )}
    </div>
  );
};

export default General;
