import { Box, MenuItem, Select, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { TimeInput } from 'components/TimeInput';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CooldownType } from 'types/alerters';

const Frequency = ({ readOnly }: { readOnly?: boolean }) => {
  const { control, getValues, setValue, formState } =
    useFormContext<SettingsForm>();
  const [cooldownType, setCooldownType] = useState<CooldownType>(
    getValues('cooldownType'),
  );

  const handleTimeInputChange = (minutes: number) => {
    setValue('cooldownTime', minutes, { shouldDirty: true });
  };

  return (
    <div>
      <Box display="flex" alignItems="center" gap={2} pb={1.5}>
        <Typography color="primary">Alert Frequency</Typography>
        <HelpIcon
          message={
            <>
              Set how often this alert should fire events:
              <br />
              <strong>Always:</strong> Fire every time it receives new data,
              even if it's repetitive. <br />
              <strong>Once:</strong> Fire only once when a new event is
              detected.
              <br />
              <strong>Cooldown:</strong> Wait at least 30 minutes after being
              fired before firing again.
            </>
          }
          tooltipPlacement="top-start"
          tooltipSx={{
            '& > div': {
              maxWidth: ({ spacing }) => spacing(52),
            },
          }}
        />
      </Box>
      <Box display="flex" gap={3}>
        <Controller
          name="cooldownType"
          control={control}
          render={({ field }) => (
            <Select
              variant="outlined"
              fullWidth
              size="small"
              readOnly={readOnly}
              sx={{ maxWidth: ({ spacing }) => spacing(30) }}
              {...field}
              value={cooldownType}
              onChange={(e) => {
                setCooldownType(e.target.value as CooldownType);
                field.onChange(e);
                if (e.target.value === 'time') {
                  setValue('cooldownTime', 10);
                }
              }}
            >
              <MenuItem value="none">Always</MenuItem>
              <MenuItem value="reset">Once</MenuItem>
              <MenuItem value="time">Cooldown</MenuItem>
            </Select>
          )}
        />
        {cooldownType === 'time' && (
          <Box display="flex" alignItems="center" gap={1}>
            <TimeInput
              onChange={handleTimeInputChange}
              defaultValue={formState.defaultValues?.cooldownTime ?? 600}
              readOnly={readOnly}
            />
            <Typography color="text.secondary">HH:MM</Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Frequency;
