import { yupResolver } from '@hookform/resolvers/yup';
import { EditRecipientSetForm } from 'components/_extra';
import { useForm } from 'react-hook-form';
import { useCreateAlerterRecipientManagerMutation } from 'store/api/alerters.api';
import { recipientMangerSchema } from './use-edit-recipients-set';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getUTCInterval } from 'utils/timezones';

export const useCreateRecipientManager = (
  isReminder?: boolean,
  onClose?: () => void,
) => {
  const { profileId } = useParams();
  const [create, { isLoading, isError, isSuccess }] =
    useCreateAlerterRecipientManagerMutation();

  useEffect(() => {
    if (isError && !isLoading) {
      toast.error('Failed to create recipient manager. Please try again.');
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      onClose?.();
      toast.success('Recipient manager created successfully.');
    }
  }, [isSuccess]);

  const formMethods = useForm<EditRecipientSetForm>({
    resolver: yupResolver(recipientMangerSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (data: EditRecipientSetForm) => {
    if (data.recipients[0].recipients.length === 0) {
      toast.error('Please add at least one recipient.');
      return;
    }

    create({
      is_reminder: isReminder,
      name: data.name,
      patient_profile_id: Number(profileId),
      attempt_count: data.count,
      attempt_spacing: data.spacing * 60,
      recipient_sets: data.recipients.map((set) => ({
        priority: set.priority,
        recipients: set.recipients.map((r) => ({
          recipient_type: r.type,
          recipient_id: r.id,
        })),
      })),
      ...(isReminder
        ? {}
        : {
            alerter_scheduler: {
              invert_schedule: data.isOutsideRange,
              serialized_schedule: getUTCInterval(
                data.startTime ?? 0,
                data.endTime ?? 86400,
                data.days,
              ),
            },
          }),
    });
  };

  return {
    formMethods,
    handleSubmit: formMethods.handleSubmit(onSubmit),
  };
};
