import { Box, Button, Typography, useTheme } from '@mui/material';
import { VLoader } from 'components/VLoader';
import { IAuthenticateResponse } from 'models/auth.model';
import { routes } from 'nav';
import LoginMessage from 'pages/ForgotPassword/components/LoginMessage';
import { PasswordRegistration } from 'pages/Registration/components';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import { useSubmitPasswordResetTokenMutation } from 'store/api/auth.api';
import { useNewPasswordForm } from './hooks/use-new-password-form';

const SetNewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const requiresMfa = useAppSelector((state) => state.mfa.requiresMfa);
  const { isMfaPassed, token: authToken } = useAppSelector(
    (state) => state.user,
  );

  const resetToken = useMemo(
    () => new URLSearchParams(location.search).get('token'),
    [location.search],
  );

  const [submitResetToken, { isLoading, isError }] =
    useSubmitPasswordResetTokenMutation();

  const { methods, onSubmit } = useNewPasswordForm();

  useEffect(() => {
    localStorage.removeItem('sentForgotAt');
    localStorage.removeItem('forgotFor');
    if (resetToken && !authToken) {
      submitResetToken({ token: resetToken }).then((response) => {
        const data = (response as { data: IAuthenticateResponse }).data;

        if (data && (requiresMfa ?? true) && !isMfaPassed) {
          navigate(routes.mfa.selectCredential.href, {
            replace: true,
            state: { forgot: true },
          });
        }
      });
    }
  }, []);

  if (isLoading) {
    return (
      <Box position="relative">
        <VLoader isLoading />
      </Box>
    );
  }

  if (!resetToken && !authToken) {
    navigate(routes.public.login.href);
  }

  if (isError) {
    toast('The token is invalid or has expired. Please try again.', {
      type: 'error',
    });

    navigate(routes.public.login.href);
  }

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={1}
        pb={4}
      >
        <Typography variant="h5" align="center">
          Create your new password
        </Typography>
        <Typography color={palette.blue.main}>
          Your new password must be different from previously used passwords.
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PasswordRegistration errors={methods.formState.errors} />
          <Box p={1} pt={3}>
            <Button size="large" fullWidth type="submit">
              Reset Password
            </Button>
          </Box>
        </form>
      </FormProvider>
      <LoginMessage />
    </Box>
  );
};

SetNewPassword.displayName = 'SetNewPassword';

export default SetNewPassword;
