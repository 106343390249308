import { debounce } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import {
  useLinkAlerterRecipientManagerMutation,
  useUnlinkAlerterRecipientManagerMutation,
} from 'store/api/alerters.api';

export const useRecipientSetSwitch = (isReminder?: boolean) => {
  const { alertId, reminderId } = useParams();
  const alertName =
    useAppSelector(
      (state) => state[isReminder ? 'reminder' : 'alerter'].settings.title,
    ) ?? '';
  const [unlink] = useUnlinkAlerterRecipientManagerMutation();
  const [link] = useLinkAlerterRecipientManagerMutation();

  const handleSwitchToggle = debounce(
    (checked: boolean, recipientManagerId: number) => {
      const id = parseInt((isReminder ? reminderId : alertId) ?? '');
      if (isNaN(id)) return;

      if (checked === false) {
        unlink({
          recipientManagerId,
          alerterId: id,
          isReminder,
        });
        return;
      }

      if (checked === true) {
        link({
          recipientManagerId,
          alerterId: id,
          isReminder,
          alertName,
        });
      }
    },
    250,
  );

  return handleSwitchToggle;
};
