import {
  AlerterRecipientManager,
  CreateReminderParams,
  SharedAlerterRecipientManagerResponse,
} from 'types/alerters';
import { transformRecipientSet } from './alerters';

export const transformCreateReminderParams = ({
  streamType,
  message,
  alerterRecipientManager,
  alerterRecipientManagerId,
  sourceId,
  sourceType,
  title,
}: CreateReminderParams) => {
  const source = !!streamType
    ? {
        patient_profile_id:
          sourceType === 'PatientProfile' ? sourceId : undefined,
        source_type: sourceType,
        stream_type: streamType,
      }
    : undefined;

  return {
    title,
    custom_message_template: message,
    alerter_source: source,
    alerter_recipient_manager: alerterRecipientManager
      ? {
          name: alerterRecipientManager.name,
          attempt_count: alerterRecipientManager?.attemptCount,
          attempt_spacing: alerterRecipientManager?.attemptSpacing,
          recipient_sets: alerterRecipientManager?.sets.map((set) => ({
            priority: set.priority,
            recipients: set.recipients.map((rec) => ({
              pattern_type: rec.patternType,
            })),
          })),
        }
      : undefined,
    alerter_recipient_manager_id: alerterRecipientManagerId,
    is_reminder: true,
  };
};

export const transformReminderSharedRecipientManager = ({
  results,
  meta,
}: SharedAlerterRecipientManagerResponse): AlerterRecipientManager[] => {
  const mappedAlerterNames = meta.alerters.reduce((prev, curr) => {
    if (!prev[curr.id]) {
      return {
        ...prev,
        [curr.id]: curr.title,
      };
    }
    return prev;
  }, {} as Record<number, string>);

  return results.map(
    ({
      alerter_scheduler,
      alerter_recipient_sets,
      alerters,
      attempt_count,
      attempt_spacing,
      patient_profile_recipients_count,
      user_recipients_count,
      id,
      name,
    }) => ({
      id,
      name,
      attemptCount: attempt_count,
      attemptSpacing: attempt_spacing / 60,
      isOutsideRange: false,
      alerters: alerters.map(({ id }) => ({
        id,
        name: mappedAlerterNames[id],
      })),
      schedulerId: alerter_scheduler.id,
      patientsCount: patient_profile_recipients_count,
      recipientsCount: user_recipients_count,
      sets: alerter_recipient_sets.map((set) => transformRecipientSet(set)),
    }),
  );
};
