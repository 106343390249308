import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
  Box,
  Button,
  useTheme,
  CircularProgress,
  Link,
  Switch,
} from '@mui/material';
import { CompletionMessage } from 'components/CompletionMessage';
import VInput from 'components/VInput';
import { useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useAppSelector } from 'store';
import { useSendInviteToCaregiverMutation } from 'store/api/account.api';
import * as yup from 'yup';

interface Form {
  email: string;
  message?: string;
  isManager?: boolean;
}

const formSchema = yup.object({
  email: yup
    .string()
    .email('The email is not valid.')
    .required('The email is required.'),
  message: yup.string(),
});

interface Props {
  onFinish?: () => void;
}

const AddCaregiver = ({ onFinish }: Props) => {
  const theme = useTheme();

  const profileId = useAppSelector((state) => state.dashboard.profileId);

  const [errors, setErrors] = useState<FieldErrorsImpl<Form>>({});
  const [isRequestFinished, setRequestFinished] = useState(false);

  const [sendInvite, { isLoading, isError }] =
    useSendInviteToCaregiverMutation();

  const { register, handleSubmit } = useForm<Form>({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Form> = (formData) => {
    sendInvite({
      email: formData.email,
      profileId,
      message: formData.message,
      subscriber_is_primary: formData.isManager,
    }).then(() => setRequestFinished(true));
  };

  const onError: SubmitErrorHandler<Form> = (errors) => {
    setErrors(errors);
  };

  if (isRequestFinished) {
    return isError ? (
      <CompletionMessage
        padding={0}
        onButtonClick={onFinish}
        status="error"
        title="Something went wrong..."
        buttonLabel="Close"
        subtitle={
          <div>
            The invitation could not be sent. Make sure you are connected to the
            network and{' '}
            <Link color="secondary" onClick={() => setRequestFinished(false)}>
              try again
            </Link>
            .
          </div>
        }
      />
    ) : (
      <CompletionMessage
        padding={0}
        onButtonClick={onFinish}
        status="success"
        title="Invitation sent successfully!"
        subtitle="Your recipient will receive the instructions shortly."
      />
    );
  }

  return !isLoading ? (
    <>
      <Typography variant="h5" align="center">
        Add a caregiver
      </Typography>
      <Typography
        variant="body1"
        sx={{ px: 3, pb: 4, color: ({ palette }) => palette.blue.main }}
        align="center"
      >
        We'll send an email with instructions for joining the caregiver group.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <VInput
          size="large"
          label="Email"
          placeholder="Enter the recipient's email address"
          disabled={isLoading}
          error={!!errors.email}
          helperText={errors.email?.message}
          blueHelper={errors.email?.type === 'request'}
          {...register('email')}
        />
        <VInput
          size="large"
          label="Message"
          placeholder="Type your message..."
          multiline
          inputProps={{
            style: { height: theme.spacing(15), overflowY: 'auto' },
          }}
          disabled={isLoading}
          error={!!errors.message}
          helperText={errors.message?.message}
          blueHelper={errors.message?.type === 'request'}
          {...register('message')}
        />
        <Box display="flex" mt={2}>
          <Switch {...register('isManager')} />
          <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
            Is Manager
          </Typography>
        </Box>
        <Box sx={{ m: 1, mt: 3 }}>
          <Button type="submit" size="large" fullWidth disabled={isLoading}>
            Submit
          </Button>
        </Box>
      </form>
    </>
  ) : (
    <Box mt={5.5} display="flex" justifyContent="center">
      <CircularProgress size={theme.spacing(9.5)} color="primary" />
    </Box>
  );
};

export default AddCaregiver;
