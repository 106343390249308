import { Box, Paper } from '@mui/material';
import { ConnectProfile, Table } from './components';
import { Toolbar } from './components';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect } from 'react';
import {
  useGetLinkedProfilesQuery,
  useLazyGetLinkedProfilesQuery,
} from 'store/api/profiles.api';
import { AddProfileButton } from './components/AddProfile';
import { useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import { setHasBeenRedirectedOnLogin } from 'store/reducers/user/user.slice';

interface Props {
  tabState: 'search' | 'apple-watch';
}

const PatientsOverview = ({ tabState }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const caregiverId = useAppSelector((state) => Number(state.user.accountId));
  const hasProfiles = useAppSelector((state) => state.user.hasProfiles);
  const hasSingleProfile = useAppSelector(
    (state) => state.user.hasSingleProfile,
  );
  const hasBeenRedirectedOnLogin = useAppSelector(
    (state) => state.user.hasBeenRedirectedOnLogin,
  );

  const { data: linkedProfiles } = useGetLinkedProfilesQuery({ caregiverId });
  const [triggerLinkedProfilesFetch] = useLazyGetLinkedProfilesQuery();

  useEffect(() => {
    if (hasBeenRedirectedOnLogin === null) {
      dispatch(setHasBeenRedirectedOnLogin(false));
    }

    // This is done for checking if the user has any profiles. "hasProfiles" will
    // flip accordingly (in the reducer) and ConnectProfile will be rendered if true.
    triggerLinkedProfilesFetch({ caregiverId });
  }, []);

  useEffect(() => {
    if (
      linkedProfiles &&
      linkedProfiles.results.length > 0 &&
      hasSingleProfile &&
      !hasBeenRedirectedOnLogin
    ) {
      dispatch(setHasBeenRedirectedOnLogin(true));
      navigate(
        routes.private.dashboard.href(linkedProfiles.results[0].account_id),
      );
    }
  }, [hasSingleProfile, linkedProfiles]);

  if (!hasProfiles) {
    return <ConnectProfile />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Paper elevation={2}>
        <Toolbar routerTabState={tabState} />
      </Paper>
      <Paper elevation={2} sx={{ overflow: 'hidden', position: 'relative' }}>
        <Table />
        <AddProfileButton isInTable />
      </Paper>
    </Box>
  );
};

export default PatientsOverview;
