import { Dispatch, SetStateAction } from 'react';
import {
  AlerterRecipient,
  AlerterRecipientSet,
  PatternType,
} from 'types/alerters';
import { HierarchyLayer } from '../hooks/use-hierarchy';

export const selectAllFromGroup = (
  newAdded: AlerterRecipient,
  index: number,
  setLayers: Dispatch<SetStateAction<HierarchyLayer[]>>,
) => {
  const group = newAdded.patternType;
  const recipientType = group === 'all_users' ? 'User' : 'PatientProfile';

  setLayers((layers) => {
    const { fromGroup, others } = layers[index].selected.reduce(
      (prev, curr) => {
        if (curr.recipientType === recipientType) {
          return {
            ...prev,
            fromGroup: prev.fromGroup.concat(curr),
          };
        }

        return { ...prev, others: prev.others.concat(curr) };
      },
      {
        fromGroup: [] as AlerterRecipient[],
        others: [] as AlerterRecipient[],
      },
    );

    return layers.map((layer, i) => {
      if (i !== index) {
        return {
          ...layer,
          recipients: [
            ...layer.recipients.filter(
              (rec) => rec.patternType !== newAdded.patternType,
            ),
            ...fromGroup,
          ],
        };
      }

      return {
        ...layer,
        recipients: layer.recipients
          .filter(({ patternType }) => patternType !== group)
          .concat(...fromGroup),
        selected: [newAdded, ...others],
      };
    });
  });
  return;
};

export const getLayersFromSet = (set: AlerterRecipientSet) => ({
  id: set.id,
  layerId: set.id,
  selected: set.recipients,
  recipients: [],
});

type RecipientEssential = {
  id: number | null;
  patternType: PatternType | null;
};
export const areRecipientsEqual = (
  first: RecipientEssential,
  second: RecipientEssential,
) => {
  return first.id === second.id && first.patternType === second.patternType;
};
