import {
  Content,
  Root,
  Wrapper,
  ButtonsWrapper,
} from '../RecipientSetEditor/RecipientSetEditor.styled';
import General from '../RecipientSetEditor/General';
import Schedule from '../RecipientSetEditor/Schedule';
import Recipients from '../RecipientSetEditor/Recipients';
import { FormProvider } from 'react-hook-form';
import { ReactNode, Ref, forwardRef } from 'react';
import SaveButton from '../RecipientSetEditor/SaveButton';
import { useCreateRecipientManager } from 'hooks/use-create-recipient-manager';
import { ALL_DAYS } from 'utils/weekdays';

export type RecipientSetEditorProps = {
  children?: ReactNode;
  withScheduler?: boolean;
  submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
  onClose?: () => void;
};

const RecipientSetCreator = forwardRef<HTMLDivElement, RecipientSetEditorProps>(
  ({ children, withScheduler, onClose }, ref) => {
    const { formMethods, handleSubmit } = useCreateRecipientManager(
      !withScheduler,
      onClose,
    );

    return (
      <Root ref={ref}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit}>
            <Wrapper
              sx={{
                px: { xxs: 0, sm: 1, newMd: 2 },
              }}
            >
              <General defaultTitle={'Untitled'} usedIn={[]} />
              <Content
                sx={{
                  px: { xxs: 1, xs: 2 },
                }}
              >
                {withScheduler && (
                  <Schedule
                    defaultDays={ALL_DAYS}
                    defaultStartTime={null}
                    defaultEndTime={null}
                  />
                )}
                <Recipients
                  defaultSets={[
                    {
                      id: 0,
                      priority: 10,
                      recipients: [],
                      recipientManagerId: 0,
                      recipientSetId: 0,
                    },
                  ]}
                  defaultCount={1}
                  defaultSpacing={2}
                />
              </Content>
              {children}
            </Wrapper>
            <ButtonsWrapper>
              <SaveButton />
            </ButtonsWrapper>
          </form>
        </FormProvider>
      </Root>
    );
  },
);

export default RecipientSetCreator;
