import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import {
  alertersApi,
  useDuplicateAlerterMutation,
} from 'store/api/alerters.api';

export const useCopyAlerter = (id: number) => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();

  const [duplicate, { isSuccess, isError, data }] =
    useDuplicateAlerterMutation();

  const handleClick = () => {
    duplicate({ id });
  };

  useEffect(() => {
    if (isError) {
      toast.error('Could not copy the alert.');
    }

    if (isSuccess) {
      toast.success('Alerter copied successfully.');
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (data) {
      dispatch(
        alertersApi.util.updateQueryData(
          'getAlerters',
          {
            patientProfileId: parseInt(profileId ?? '0'),
          },
          (result) => {
            const toCopy = result[
              data.result.display_group || 'uncategorized'
            ].find((alerter) => alerter.id === id);

            if (toCopy) {
              result[data.result.display_group || 'uncategorized'].push({
                ...toCopy,
                id: data.result.id,
                title: data.result.title,
              });
            }
          },
        ),
      );
    }
  }, [data]);

  return {
    handleClick,
  };
};
