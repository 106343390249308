import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const cancel: IconifyJSON = {
  prefix: 'cancel',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M8.4 16.65L12 13.05L15.6 16.65L16.65 15.6L13.05 12L16.65 8.4L15.6 7.35L12 10.95L8.4 7.35L7.35 8.4L10.95 12L7.35 15.6L8.4 16.65ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5Z" fill="currentColor"/>',
    },
  },
};
