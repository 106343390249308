import { InputBase, inputBaseClasses, styled } from '@mui/material';

export const StyledVInput = styled(InputBase)(({ theme }) => ({
  boxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  MozBoxSizing: 'border-box',
  fontSize: 16,
  padding: theme.spacing(1.5, 2),
  border: `1px solid ${theme.palette.borderGray.main}`,
  color: theme.palette.black.main,
  svg: {
    minWidth: '24px',
  },
  [`&.${inputBaseClasses.sizeSmall}`]: {
    padding: theme.spacing(0.875, 1.5),
    borderRadius: theme.spacing(0.5),
  },
  [`&.${inputBaseClasses.root}:not(.${inputBaseClasses.multiline})`]: {
    maxHeight: theme.spacing(6),
  },
  '&.Mui-error': {
    borderColor: theme.palette.grapeFruit.main,
  },
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused.MuiInputBase-readOnly': {
    borderColor: theme.palette.borderGray.main,
  },
  '&.MuiInputBase-readOnly': {
    color: theme.palette.blue.main,
  },
  '& .MuiInputBase-input': {
    height: '24px',
    padding: 0,
    '&::placeholder': {
      fontWeight: 400,
      color: theme.palette.disabled.main,
      opacity: 1,
    },
  },
  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
    {
      ' -webkit-appearance': 'none',
      margin: 0,
    },
  [`&.${inputBaseClasses.adornedEnd}`]: {
    svg: {
      width: 24,
      height: 24,
    },
  },
}));
