import { Constants } from 'types/enum/constants.enum';

const pxToEm = (
  size?: number | string,
  asNumber = false,
  defaultEmValue = Constants.EM_VALUE,
): string | number | undefined => {
  // EM_VALUE (16.0) - is the value of 1 em this is adjustable
  if (size == null || isNaN(Number(size))) {
    return size;
  }

  if (asNumber) {
    return Number(size) / Number(defaultEmValue);
  }

  return `${Number(size) / Number(defaultEmValue)}em`;
};

export default pxToEm;
