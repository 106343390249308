import { Box, Skeleton, Switch, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { useProfileConfig } from 'pages/PatientAccount/hooks/use-profile-config';

const ProminentButton = () => {
  const { handleChange, isLoading, isUpdating, value, readOnly } =
    useProfileConfig('use_large_request_help_button');

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Typography color="primary" py={1}>
          Use Prominent Button
        </Typography>
        <HelpIcon message="Use a bright red, full-screen button on the watch for help requests." />
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width={58} height={28} />
      ) : (
        <Switch
          size="medium"
          checked={value}
          onChange={(_, checked) => {
            handleChange(checked);
          }}
          disabled={readOnly || isUpdating}
          sx={{
            ml: 2,
          }}
        />
      )}
    </Box>
  );
};

export default ProminentButton;
