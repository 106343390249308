import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 20, height: 20 };

export const information: IconifyJSON = {
  prefix: 'information',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M9.37565 13.9585H10.6256V9.16681H9.37565V13.9585ZM10.0006 7.74054C10.1913 7.74054 10.3512 7.67604 10.4802 7.54704C10.6092 7.41804 10.6737 7.25818 10.6737 7.06748C10.6737 6.87678 10.6092 6.71693 10.4802 6.58791C10.3512 6.45891 10.1913 6.39441 10.0006 6.39441C9.80992 6.39441 9.65007 6.45891 9.52107 6.58791C9.39207 6.71693 9.32757 6.87678 9.32757 7.06748C9.32757 7.25818 9.39207 7.41804 9.52107 7.54704C9.65007 7.67604 9.80992 7.74054 10.0006 7.74054ZM10.002 17.9168C8.90708 17.9168 7.87789 17.709 6.91444 17.2935C5.95098 16.8779 5.11292 16.3139 4.40023 15.6016C3.68754 14.8892 3.12332 14.0515 2.70759 13.0885C2.29185 12.1255 2.08398 11.0965 2.08398 10.0015C2.08398 8.90659 2.29176 7.8774 2.70732 6.91395C3.12287 5.9505 3.68683 5.11243 4.39919 4.39975C5.11157 3.68705 5.94927 3.12284 6.9123 2.7071C7.87531 2.29137 8.90429 2.0835 9.99924 2.0835C11.0942 2.0835 12.1234 2.29127 13.0868 2.70683C14.0503 3.12239 14.8883 3.68634 15.601 4.39871C16.3137 5.11108 16.8779 5.94878 17.2937 6.91181C17.7094 7.87482 17.9173 8.9038 17.9173 9.99875C17.9173 11.0937 17.7095 12.1229 17.2939 13.0863C16.8784 14.0498 16.3144 14.8879 15.6021 15.6005C14.8897 16.3132 14.052 16.8774 13.089 17.2932C12.126 17.7089 11.097 17.9168 10.002 17.9168ZM10.0006 16.6668C11.8617 16.6668 13.4381 16.021 14.7298 14.7293C16.0215 13.4376 16.6673 11.8613 16.6673 10.0001C16.6673 8.13903 16.0215 6.56264 14.7298 5.27098C13.4381 3.97931 11.8617 3.33348 10.0006 3.33348C8.13952 3.33348 6.56313 3.97931 5.27146 5.27098C3.9798 6.56264 3.33396 8.13903 3.33396 10.0001C3.33396 11.8613 3.9798 13.4376 5.27146 14.7293C6.56313 16.021 8.13952 16.6668 10.0006 16.6668Z" fill="currentColor"/>',
    },
  },
};
