import {
  Box,
  Button,
  CircularProgress,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomPhoneInput } from 'components/CustomPhoneInput';
import VInput from 'components/VInput';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useCredentialCreate } from '../hooks/use-credential-create';
import { useAppSelector } from 'store';

const VERIFICATION_METHODS = {
  email: 'email',
  phone: 'phone number',
};

const CredentialCreate = () => {
  const theme = useTheme();

  const {
    isFetching,
    isDisabled,
    checkedIndex,
    potentialMethods,
    draftInputs,
    handleInputChange,
    handleMethodClick,
    handleSendCode,
    phoneInputError,
  } = useCredentialCreate();

  const [resendSecondsLeft, setResendSecondsLeft] = useState(0);

  const codeRequestedAt = useAppSelector((state) => state.mfa.codeRequestedAt);

  useEffect(() => {
    setTimeout(() => {
      if (resendSecondsLeft > 0) {
        setResendSecondsLeft((prev) => prev - 1);
      }
    }, 1000);
  }, [resendSecondsLeft]);

  useEffect(() => {
    if (codeRequestedAt) {
      const seconds = dayjs(codeRequestedAt)
        .add(60, 'seconds')
        .diff(dayjs(), 'seconds');

      setResendSecondsLeft(seconds);
    }
  }, [codeRequestedAt]);

  const canResend = resendSecondsLeft <= 0;

  return (
    <Box>
      <Typography variant="h5" textAlign="center">
        Setup two-factor authentication
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color={theme.palette.blue.main}
        mx={2}
        mb={4}
      >
        Choose one of the verification methods below:
      </Typography>
      {!isFetching ? (
        <>
          {potentialMethods.map((method, index) => (
            <Box display="flex" py={2} alignItems="center" key={index}>
              <Radio
                color="secondary"
                name="mfa"
                value="email"
                checked={index === checkedIndex}
                onChange={() => handleMethodClick(index)}
              />
              <Box pl={1} width="100%">
                <Typography variant="body2">
                  Your current{' '}
                  {VERIFICATION_METHODS[
                    method.type as keyof typeof VERIFICATION_METHODS
                  ] || ''}
                </Typography>
                {!!method.credential ? (
                  <Typography variant="body1" color={theme.palette.blue.main}>
                    {method.credential}
                  </Typography>
                ) : method.type === 'email' ? (
                  <VInput
                    size="large"
                    containerSx={{ width: '100%', mt: 1 }}
                    value={draftInputs[index]}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    disabled={isDisabled}
                  />
                ) : (
                  <CustomPhoneInput
                    containerProps={{ sx: { mt: 1 } }}
                    value={draftInputs[index]}
                    onChange={(value) => handleInputChange(value, index)}
                    disabled={isDisabled}
                    error={phoneInputError}
                  />
                )}
              </Box>
            </Box>
          ))}
          <Typography
            variant="body2"
            textAlign="center"
            mt={2}
            mx={2}
            color="error"
          >
            {potentialMethods && checkedIndex !== null ? (
              'We will send a 6 digit code to your ' +
              (VERIFICATION_METHODS[
                potentialMethods[checkedIndex]
                  .type as keyof typeof VERIFICATION_METHODS
              ] || 'chosen method') +
              '.'
            ) : (
              <>&nbsp;</>
            )}
          </Typography>
          <Button
            fullWidth
            sx={{ mt: 3 }}
            disabled={isDisabled || !canResend}
            onClick={handleSendCode}
          >
            Send code {!canResend && `in ${resendSecondsLeft} seconds`}
          </Button>
        </>
      ) : (
        <Box my={6} display="flex" justifyContent="center">
          <CircularProgress size={theme.spacing(9.5)} color="primary" />
        </Box>
      )}
    </Box>
  );
};

export default CredentialCreate;
