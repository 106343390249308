import { Icon } from '@iconify/react';
import { Button, Paper, Typography, useTheme } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ onClose?: () => void }>;

const ModalLayout = forwardRef<HTMLDivElement, Props>(
  ({ children, onClose }, ref) => {
    const { palette } = useTheme();

    return (
      <Paper ref={ref} sx={{ p: 4, maxWidth: '512px', position: 'relative' }}>
        <Button
          onClick={onClose}
          variant="icon-text"
          size="small"
          sx={{
            position: 'absolute',
            color: palette.blue.light,
            right: 10,
            top: 10,
            ':hover': {
              color: palette.blue.dark,
            },
          }}
        >
          <Icon icon="material-symbols:close" />
        </Button>
        <Typography variant="h5" align="center">
          Add a profile
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ px: 2, pb: 4, color: ({ palette }) => palette.blue.main }}
        >
          You should have received an invitation code from another caregiver or
          your organization.
        </Typography>
        {children}
      </Paper>
    );
  },
);

export default ModalLayout;
