import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const mail: IconifyJSON = {
  prefix: 'mail',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M4.30773 19.5C3.8026 19.5 3.37503 19.325 3.02503 18.975C2.67503 18.625 2.50003 18.1974 2.50003 17.6923V6.30773C2.50003 5.80259 2.67503 5.37502 3.02503 5.02502C3.37503 4.67502 3.8026 4.50002 4.30773 4.50002H19.6923C20.1974 4.50002 20.625 4.67502 20.975 5.02502C21.325 5.37502 21.5 5.80259 21.5 6.30773V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.30773ZM12 12.5577L4.00001 7.44227V17.6923C4.00001 17.782 4.02886 17.8557 4.08656 17.9134C4.14426 17.9711 4.21798 18 4.30773 18H19.6923C19.782 18 19.8558 17.9711 19.9135 17.9134C19.9712 17.8557 20 17.782 20 17.6923V7.44227L12 12.5577ZM12 11L19.8462 6H4.15386L12 11ZM4.00001 7.44227V6V17.6923C4.00001 17.782 4.02886 17.8557 4.08656 17.9134C4.14426 17.9711 4.21798 18 4.30773 18H4.00001V7.44227Z" fill="currentColor"/>',
    },
    filled: {
      ...defaultSizes,
      body: '<path d="M4.30773 19.5C3.8026 19.5 3.37503 19.325 3.02503 18.975C2.67503 18.625 2.50003 18.1974 2.50003 17.6923V6.30773C2.50003 5.80259 2.67503 5.37502 3.02503 5.02502C3.37503 4.67502 3.8026 4.50002 4.30773 4.50002H19.6923C20.1974 4.50002 20.625 4.67502 20.975 5.02502C21.325 5.37502 21.5 5.80259 21.5 6.30773V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.30773ZM12 12.5577L20 7.44227L19.8462 6L12 11L4.15386 6L4.00001 7.44227L12 12.5577Z" fill="currentColor"/>',
    },
  },
};
