import { Icon } from '@iconify/react';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import theme from 'styles/theme';
import { trash } from 'assets/iconify';

interface ItemProps {
  id: number;
  name: string;
  model: string;
  system: string;
  isActive: boolean;
  canBeModified?: boolean;
  onDeleteButtonClick: (id: number) => void;
}

const Item = ({
  id,
  name,
  model,
  system,
  isActive,
  canBeModified,
  onDeleteButtonClick,
}: ItemProps) => {
  const { spacing, palette } = useTheme();

  const deviceState = [
    {
      label: 'Status',
      value: isActive ? 'Active' : 'Inactive',
    },
    {
      label: 'OS',
      value: system && system.replace('OS', 'OS '),
    },
    {
      label: 'Device Type',
      value:
        model &&
        (model.toLowerCase().includes('watch')
          ? 'Smart Watch'
          : 'Healthcare Device'),
    },
  ];

  const handleDelete = () => {
    onDeleteButtonClick(id);
  };

  return (
    <Box
      display="flex"
      gap={3}
      border={`1px solid ${palette.borderGray.main}`}
      borderRadius={spacing(1)}
      minWidth="100%"
      sx={{
        p: { xxs: 1.5, sm: 2 },
      }}
    >
      <Box flexGrow={1} overflow="hidden">
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {name || 'Device'}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" columnGap={3} rowGap={2} flexWrap="wrap">
          {deviceState.map((item) => (
            <Box key={item.label}>
              <Typography variant="body2">{item.label}</Typography>
              <Typography variant="body2" color={palette.blue.main}>
                {item.value ?? 'Unknown'}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {canBeModified && (
        <Box>
          <Tooltip title="Remove device" placement="top">
            <Button
              size="small"
              variant="outlined"
              color="red"
              onClick={handleDelete}
              sx={{ maxWidth: spacing(4), p: spacing(0.5) }}
            >
              <Icon
                width={theme.spacing(2.5)}
                height={theme.spacing(2.5)}
                icon={trash.icons.normal}
              />
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Item;
