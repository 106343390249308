import {
  useCallback,
  useState,
  MouseEvent,
  HTMLProps,
  useRef,
  useEffect,
} from 'react';
import { Group, Item, WeekdayPickerRoot } from './WeekdayPicker.styled';
import { ALL_DAYS, DAYS_OF_THE_WEEK, Day } from 'utils/weekdays';
import PresetSelect from './components/PresetSelect';
import { Id, toast } from 'react-toastify';

interface WeekdayPickerProps {
  onChange?: (value: Day[]) => void;
  onFocus?: () => void;
  withPresets?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: Day[];
  checkboxProps?: HTMLProps<HTMLInputElement>;
  size?: 'small' | 'medium';
  fullWidthSelector?: boolean;
  value?: Day[];
}

const WeekdayPicker = ({
  disabled,
  onChange,
  withPresets,
  defaultValue,
  readOnly,
  size = 'medium',
  fullWidthSelector,
  onFocus,
  value,
}: WeekdayPickerProps) => {
  const [checkedElements, setCheckedElements] = useState<number[]>(
    value ?? (!defaultValue || defaultValue.length === 0)
      ? ALL_DAYS
      : defaultValue,
  );

  const toastId = useRef<Id>();

  const handleItemToggle = useCallback((e: MouseEvent, value: number[]) => {
    if (value.length === 0) {
      if (!toast.isActive(toastId.current ?? 0)) {
        toastId.current = toast.info('At least one selected day is mandatory.');
      }
      return;
    }
    setCheckedElements(value);
    onChange?.(value as Day[]);
  }, []);

  const handlePresetChange = useCallback((newValues: number[]) => {
    setCheckedElements(newValues);
    onChange?.(newValues as Day[]);
  }, []);

  useEffect(() => {
    if (value) {
      setCheckedElements(value);
    }
  }, [value]);

  return (
    <WeekdayPickerRoot onFocus={onFocus}>
      {withPresets && (
        <PresetSelect
          disabled={disabled}
          readOnly={readOnly}
          checkedElements={checkedElements}
          fullWidth={fullWidthSelector}
          onChange={handlePresetChange}
        />
      )}
      <Group
        size={size}
        disabled={disabled || readOnly}
        value={checkedElements}
        onChange={handleItemToggle}
        defaultValue={defaultValue?.map((v) => v.toString())}
      >
        {DAYS_OF_THE_WEEK.map(({ value, label }) => (
          <Item value={value} key={value}>
            {label}
          </Item>
        ))}
      </Group>
    </WeekdayPickerRoot>
  );
};

export default WeekdayPicker;
