import { ReactNode, Ref } from 'react';
import { RecipientSetModal as Modal } from 'components/_extra/Alerter/RecipientSetModal';
import { CombinedRecipientManager } from 'store/reducers/alerter/alerter.slice';

interface Props {
  children?: ReactNode;
  submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
  index: number | null;
  onClose: () => void;
  defaultValues: CombinedRecipientManager;
  isOpen: boolean;
  isLoading?: boolean;
}

const RecipientSetModal = ({
  children,
  defaultValues,
  isOpen,
  index,
  submitFormRef,
  isLoading,
  onClose,
}: Props) => {
  return (
    <Modal
      selectedIndex={index}
      isOpen={isOpen}
      withScheduler
      defaultValues={defaultValues}
      submitFormRef={submitFormRef}
      onClose={onClose}
      isLoading={isLoading}
    >
      {children}
    </Modal>
  );
};

export default RecipientSetModal;
