import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const link: IconifyJSON = {
  prefix: 'link',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M10.8077 16.5383H7.03845C5.78282 16.5383 4.7125 16.0958 3.8275 15.211C2.9425 14.3261 2.5 13.2559 2.5 12.0004C2.5 10.7449 2.9425 9.67455 3.8275 8.7893C4.7125 7.90405 5.78282 7.46143 7.03845 7.46143H10.8077V8.96138H7.03845C6.1987 8.96138 5.48235 9.25785 4.8894 9.8508C4.29645 10.4438 3.99998 11.1601 3.99998 11.9999C3.99998 12.8396 4.29645 13.556 4.8894 14.1489C5.48235 14.7419 6.1987 15.0383 7.03845 15.0383H10.8077V16.5383ZM8.25 12.7498V11.2499H15.75V12.7498H8.25ZM13.1923 16.5383V15.0383H16.9615C17.8013 15.0383 18.5176 14.7419 19.1106 14.1489C19.7035 13.556 20 12.8396 20 11.9999C20 11.1601 19.7035 10.4438 19.1106 9.8508C18.5176 9.25785 17.8013 8.96138 16.9615 8.96138H13.1923V7.46143H16.9615C18.2171 7.46143 19.2875 7.90387 20.1725 8.78875C21.0575 9.67363 21.5 10.7438 21.5 11.9993C21.5 13.2548 21.0575 14.3252 20.1725 15.2104C19.2875 16.0957 18.2171 16.5383 16.9615 16.5383H13.1923Z" fill="#A3B2C6"/>',
    },
  },
};
