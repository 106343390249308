import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
  useTheme,
} from '@mui/material';
import { error } from 'assets/iconify/error';

interface Props extends Omit<DialogProps, 'children'> {
  onConfirm: () => void;
  onReject: () => void;
  title: string;
  subtitle?: string;
  confirmButtonProps?: Omit<ButtonProps, 'children' | 'onClick'>;
  rejectButtonProps?: Omit<ButtonProps, 'children' | 'onClick'>;
  confirmButtonLabel?: string;
  rejectButtonLabel?: string;
}

const ConfirmationDialog = ({
  title,
  subtitle,
  onConfirm,
  onReject,
  confirmButtonLabel,
  rejectButtonLabel,
  confirmButtonProps,
  rejectButtonProps,
  ...dialogProps
}: Props) => {
  const { palette, spacing } = useTheme();

  return (
    <Dialog
      {...dialogProps}
      PaperProps={{ sx: { p: 2, maxWidth: spacing(70) } }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box display="flex" alignItems="center" gap={1} pr={4}>
          <Box>
            <Icon
              width={spacing(12)}
              icon={error.icons.normal}
              color={palette.red.main}
            />
          </Box>
          <Box sx={{ pt: 0, px: 1, py: 2 }}>
            <Typography variant="h6" sx={{ pb: 1 }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography color={palette.blue.main} variant="body1">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          color="primary"
          variant="outlined"
          {...rejectButtonProps}
          sx={{ mr: 1 }}
          onClick={onReject}
        >
          {rejectButtonLabel ?? 'No'}
        </Button>
        <Button {...confirmButtonProps} onClick={onConfirm}>
          {confirmButtonLabel ?? 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
