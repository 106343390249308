import { Paper } from '@mui/material';
import { Table } from 'components/Table';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';
import { Device } from 'types/device';
import { adminDevicesColumns } from 'utils/admin-devices-columns';

type Props = {
  rows: Device[];
};

const DevicesList = ({ rows }: Props) => {
  const navigate = useNavigate();
  const handleRowClick = ({ row }: { row: Device }) => {
    navigate(routes.admin.deviceData.href(row.id));
  };

  return (
    <Paper sx={{ mt: 3 }}>
      <Table
        onRowClick={handleRowClick}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
        columns={adminDevicesColumns}
        rows={rows}
        hideFooter
      />
    </Paper>
  );
};

export default DevicesList;
