import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const expandContent: IconifyJSON = {
  prefix: 'expand-content',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M5.50003 18.5V13H7.00001V17H11V18.5H5.50003ZM17 11V7H13V5.50002H18.5V11H17Z" fill="currentColor" />',
    },
  },
};
