import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  useCreateCredentialMutation,
  useDeleteCredentialMutation,
  useGetCredentialsQuery,
  useRequestConfirmationMutation,
} from 'store/api/auth.api';
import { useGetProfileQuery } from 'store/api/profiles.api';
import {
  setAvailableMethods,
  setChosenMethodId,
} from 'store/reducers/mfa/mfa.slice';
import { FieldError } from 'react-hook-form';

interface PotentialMfaMethod {
  type: 'email' | 'phone';
  credential: string | null;
}

export const useCredentialCreate = () => {
  const dispatch = useAppDispatch();

  const [checkedIndex, setCheckedIndex] = useState<number | null>(null);
  const [phoneInputError, setPhoneInputError] = useState<
    FieldError | undefined
  >(undefined);
  const [potentialMethods, setPotentialMethods] = useState<
    PotentialMfaMethod[]
  >([]);
  const [draftInputs, setDraftInputs] = useState<string[]>([]);

  const accountId = useAppSelector((state) => Number(state.user.accountId));
  const email = useAppSelector((state) => state.user.email);

  const { data: profileData, isFetching } = useGetProfileQuery({
    id: accountId,
  });
  const { data: allCredentials } = useGetCredentialsQuery();
  const [createCredential, { isLoading: isCreateLoading }] =
    useCreateCredentialMutation();
  const [
    requestCredentialConfirmation,
    { isLoading: isRequestConfirmationLoading },
  ] = useRequestConfirmationMutation();
  const [deleteCredential, { isLoading: isDeleteCredentialLoading }] =
    useDeleteCredentialMutation();

  useEffect(() => {
    setPotentialMethods([
      {
        type: 'email',
        credential: email || null,
        // credential: null,
      },
      {
        type: 'phone',
        credential: profileData?.result.preferred_phone_number || null,
        // credential: null,
      },
    ]);
    setDraftInputs(['', '']);
    setCheckedIndex(0);
  }, [profileData]);

  const handleInputChange = (value: string, index: number) => {
    setDraftInputs((prev) => {
      const cast = [...prev];
      cast[index] = value;
      return cast;
    });
  };

  const handleMethodClick = (methodId: number) => {
    setCheckedIndex(methodId);
  };

  const handleSendCode = async () => {
    if (checkedIndex !== null) {
      const newCredential = {
        schema: potentialMethods[checkedIndex].type,
        identifier:
          potentialMethods[checkedIndex].credential ||
          draftInputs[checkedIndex],
      };

      const existingCredential = allCredentials?.results.find(
        (item) =>
          item.identifier === newCredential.identifier &&
          item.schema === newCredential.schema,
      );

      if (existingCredential) {
        await deleteCredential({ id: existingCredential.id }).unwrap();
      }

      if (
        newCredential.schema === 'phone' &&
        newCredential.identifier.length === 0
      ) {
        setPhoneInputError({
          type: 'required',
          message: 'The phone number is required.',
        });
        return;
      }

      if (
        newCredential.schema === 'phone' &&
        !isValidPhoneNumber(newCredential.identifier)
      ) {
        setPhoneInputError({
          type: 'validate',
          message:
            'This phone number is not valid. Please use a correct format.',
        });
        return;
      }

      setPhoneInputError(undefined);

      createCredential(newCredential)
        .unwrap()
        .then(async ({ result }) => {
          const credential = {
            id: result.id,
            type: result.schema as 'email' | 'phone',
            credential: result.identifier,
          };

          dispatch(setAvailableMethods([credential]));

          await requestCredentialConfirmation({
            schema: credential.type,
            identifier: credential.credential,
          }).unwrap();

          dispatch(setChosenMethodId(credential.id));
        })
        .catch(() => {
          if (newCredential.schema === 'phone') {
            toast.error(
              'This phone number is already in use for another account.',
            );
            return;
          }
          toast.error('An error has occurred. Please try again.');
        });
    }
  };

  const isDisabled =
    isCreateLoading ||
    isRequestConfirmationLoading ||
    isDeleteCredentialLoading;

  return {
    isFetching,
    isDisabled,
    checkedIndex,
    potentialMethods,
    draftInputs,
    handleInputChange,
    handleMethodClick,
    handleSendCode,
    phoneInputError,
  };
};
