import { useConfirmation } from 'hooks/use-confirmation';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useRemoveRuleFromReminderTriggerMutation,
  useUpdateRuleInReminderTriggerMutation,
} from 'store/api/reminders.api';
import {
  cancelReminderTriggerEditing,
  editReminderTrigger,
} from 'store/reducers/reminder/reminder.slice';
import isScheduleRuleDifferentSelector from 'store/selectors/is-schedule-rule-different.selector';
import { getUTCValue } from 'utils/timezones';

export const useTriggerControls = () => {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const triggerId = useAppSelector((state) => state.reminder.triggerId);
  const editedTrigger = useAppSelector(
    (state) => state.reminder.editedSchedule,
  );
  const isRuleDifferent = useAppSelector(isScheduleRuleDifferentSelector);

  const [remove, { isError, isLoading }] =
    useRemoveRuleFromReminderTriggerMutation();
  const [edit, { isLoading: isSwitchDisabled, isError: updateFailed }] =
    useUpdateRuleInReminderTriggerMutation();

  const removeRule = (id: number) => {
    remove({
      scheduleRuleId: id,
      triggerId,
    });
  };

  const handleEditClick = (id: number) => {
    dispatch(editReminderTrigger(id));
  };

  const handleSaveClick = (id: number) => {
    edit({
      ruleId: id,
      serializedSchedule: {
        days: [...(editedTrigger?.newRule.days ?? [])].sort(),
        startTime: getUTCValue(editedTrigger?.newRule.startTime ?? 0),
      },
    });
    dispatch(cancelReminderTriggerEditing(true));
  };

  const handleRuleRemove = (id: number) => {
    confirm(
      {
        title: 'Are you sure you want to delete the trigger from the list?',
        message:
          'This trigger item will be deleted immediately. You can’t undo this action.',
        variant: 'danger',
        rejectButtonLabel: 'Cancel',
      },
      () => removeRule(id),
    );
  };

  const cancelEditing = () => {
    dispatch(cancelReminderTriggerEditing(false));
  };

  const handleCancelEditing = () => {
    if (isRuleDifferent) {
      confirm(
        {
          title: 'You have unsaved changes',
          message: 'Do you want to discard them?',
          rejectButtonLabel: 'Go back',
          confirmButtonLabel: 'Discard',
        },
        cancelEditing,
      );
      return;
    }
    cancelEditing();
  };

  useEffect(() => {
    if (!isLoading && isError) {
      toast.error('An error occured!');
    }
  }, [isError]);

  useEffect(() => {
    if (updateFailed && !isSwitchDisabled) {
      toast.error('An error occured.');
    }
  }, [updateFailed]);

  const onSwitchChange = (id: number, checked: boolean) => {
    edit({
      ruleId: id,
      enabled: checked,
    });
  };

  return {
    handleRuleRemove,
    handleEditClick,
    handleSaveClick,
    onSwitchChange,
    handleCancelEditing,
    isSwitchDisabled,
    editedTrigger,
  };
};
