import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const collapseContent: IconifyJSON = {
  prefix: 'collapse-content',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M11 13V18.5H9.50003V14.5H5.50003V13H11ZM14.5 5.50002V9.50002H18.5V11H13V5.50002H14.5Z" fill="currentColor" /> ',
    },
  },
};
