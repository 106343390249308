import { EmergencyContact } from './EmergencyContact';
import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { RequestHelp } from './RequestHelp';
import { ProminentButton } from './ProminentButton';

const EmergencyManagement = () => {
  return (
    <div>
      <Typography color="primary" fontWeight={600}>
        Emergency Management
      </Typography>
      <Box
        px={{ xxs: 1, newMd: 2 }}
        py={2}
        display="flex"
        flexDirection="column"
        gap={1}
        maxWidth={536}
      >
        <RequestHelp />
        <EmergencyContact />
        <ProminentButton />
      </Box>
    </div>
  );
};

export default memo(EmergencyManagement);
