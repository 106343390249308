import { Link } from '@mui/material';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';

const ForgotLink = ({ email }: { email?: string }) => {
  const navigate = useNavigate();

  const handleNavigateToForgot = () => {
    navigate(routes.public.forgot.href, { state: { email } });
  };

  return (
    <Link
      variant="body2"
      onClick={handleNavigateToForgot}
      sx={{ ml: 'auto', px: 1 }}
    >
      Forgot Password?
    </Link>
  );
};

export default ForgotLink;
