import { Box, Skeleton } from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useCaregiverPermission } from 'pages/PatientAccount/hooks/use-caregiver-permissions';
import { useDeregisterDevice } from 'pages/PatientAccount/hooks/use-deregister-device';
import { memo } from 'react';
import { useAppSelector } from 'store';
import { useGetAllQuery } from 'store/api/devices.api';
import { ConnectDeviceModal } from './ConnectDeviceModal';
import Item from './RegisteredItem';
import { GridWrapper } from '../generic';

const RegisteredDevices = () => {
  const { profileId } = useAppSelector((state) => state.dashboard);

  const { data = [], isFetching } = useGetAllQuery({
    id: profileId,
  });

  const {
    isDialogOpen,
    deregisterDevice,
    handleDeregisterClick,
    handleCancel,
    selectedId,
  } = useDeregisterDevice(profileId);

  const { canManageDevices } = useCaregiverPermission();

  const selectedName = data?.find((item) => item.id === selectedId)?.name;

  return (
    <Box width="100%" px={{ xxs: 0, newLg: 1 }} py={1}>
      <GridWrapper>
        {isFetching
          ? [1, 2].map((key) => (
              <Skeleton
                key={key}
                variant="rounded"
                height={127}
                sx={{
                  m: 1,
                  width: '100%',
                  borderRadius: ({ spacing }) => spacing(1),
                }}
              />
            ))
          : data.map((item) => (
              <Item
                id={item.id}
                key={item.id}
                name={item.name}
                canBeModified={canManageDevices}
                model={item.model}
                system={item.system}
                isActive={item.isActive}
                onDeleteButtonClick={handleDeregisterClick}
              />
            ))}
      </GridWrapper>
      {canManageDevices && (
        <div>
          <ConnectDeviceModal />
        </div>
      )}
      <ConfirmationDialog
        open={isDialogOpen}
        onConfirm={deregisterDevice}
        onReject={handleCancel}
        rejectButtonLabel="Cancel"
        confirmButtonProps={{ color: 'red' }}
        title={
          selectedName
            ? `Are you sure you want to remove “${selectedName}” device?`
            : 'Are you sure you want to remove the selected device?'
        }
        subtitle="If you will remove this device, it will no longer report data for patient and you won’t receive any alerts from it."
      />
    </Box>
  );
};

export default memo(RegisteredDevices);
