import { useState } from 'react';
import { Id, toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { useLazyGetMFACredentialsQuery } from 'store/api/auth.api';
import { useUpdateProfileMutation } from 'store/api/profiles.api';
import { toggleMfa } from 'store/reducers/mfa/mfa.slice';

let disabledToastId: Id | null = null;

export const useMfaConfig = () => {
  const dispatch = useAppDispatch();
  const accountId = useAppSelector((state) => Number(state.user.accountId));
  const reduxMfaFlag = useAppSelector((state) => state.mfa.requiresMfa);

  const [isMfaEnabled, setMfaEnabled] = useState(!!reduxMfaFlag);
  const [shouldConfigureCredentials, setShouldConfigureCredentials] =
    useState(false);
  const [shouldPassMfa, setShouldPassMfa] = useState(false);

  const [getMfaCredentials, { isLoading: isMfaCredentialsLoading }] =
    useLazyGetMFACredentialsQuery();
  const [updateProfile, { isLoading: isProfileUpdateLoading }] =
    useUpdateProfileMutation();

  const handleEnable = () => {
    getMfaCredentials()
      .unwrap()
      .then((result) => {
        if (result.results.length > 0) {
          setMfaEnabled(true);
          toast
            .promise(
              updateProfile({ id: accountId, requires_mfa: true }).unwrap,
              {
                pending: 'Enabling 2FA...',
                success: '2FA was enabled succesfully.',
              },
            )
            .catch(() => {
              setMfaEnabled(false);
            });

          return;
        }

        setShouldConfigureCredentials(true);
      })
      .catch(() => {
        toast.error(
          'Could not fetch the credential options. Please try again.',
        );
      });
  };

  const handleDisable = () => {
    setShouldPassMfa(true);
  };

  const handleCredentialConfigureFinish = () => {
    setShouldConfigureCredentials(false);
    setMfaEnabled(true);
    toast.success('2FA was enabled succesfully.');
  };

  const handleCredentialConfigureAbort = () => {
    setShouldConfigureCredentials(false);
    setMfaEnabled(false);
  };

  const handlePassMfaFinish = () => {
    setShouldPassMfa(false);
    setMfaEnabled(false);
    dispatch(toggleMfa(false));
    if (!disabledToastId) {
      disabledToastId = toast.success('2FA was disabled succesfully.');
    }

    setTimeout(() => {
      disabledToastId = null;
    }, 2000);
  };

  const handlePassMfaAbort = () => {
    setShouldPassMfa(false);
    setMfaEnabled(true);
  };

  const isLoading = isMfaCredentialsLoading || isProfileUpdateLoading;

  return {
    isMfaEnabled,
    handleEnable,
    handleDisable,
    isLoading,
    shouldConfigureCredentials,
    handleCredentialConfigureFinish,
    handleCredentialConfigureAbort,
    shouldPassMfa,
    handlePassMfaFinish,
    handlePassMfaAbort,
  };
};
