import { CredentialType } from 'models/auth.model';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  useRequestConfirmationMutation,
  useCreateCredentialMutation,
} from 'store/api/auth.api';
import { FieldError } from 'react-hook-form';
import { routes } from 'nav';
import { toast } from 'react-toastify';
import { useGetProfileQuery } from 'store/api/profiles.api';
import { setAuthSuccess } from 'store/reducers/user/user.slice';

export const useMFASetup = (
  oldSchema?: CredentialType,
  oldIdentifier?: string,
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accountId, email } = useAppSelector((state) => state.user);

  const [createCredential] = useCreateCredentialMutation();
  const [requestConfirmation, { isSuccess, isError, data }] =
    useRequestConfirmationMutation();
  const { data: patientData, isLoading: isAccountLoading } = useGetProfileQuery(
    { id: Number(accountId) },
    { skip: !accountId },
  );

  const [error, setError] = useState<FieldError | undefined>(undefined);

  const phoneNumber = useRef(oldSchema === 'phone' ? oldIdentifier || '' : '');
  const identifier = useRef('');

  const submitRequest = (identifier: string, schema: CredentialType) => {
    requestConfirmation({
      schema,
      identifier,
    });
  };

  const [selectedSchema, setSelectedSchema] = useState<CredentialType>(
    oldSchema || 'email',
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSchema(event.target.value as CredentialType);
  };

  const onSubmit = async () => {
    identifier.current =
      selectedSchema === 'email' && email ? email : phoneNumber.current;

    if (selectedSchema === 'phone') {
      if (identifier.current?.length === 0) {
        setError({
          type: 'required',
          message: 'Phone number is required.',
        });
        return;
      }

      if (!isValidPhoneNumber(identifier.current)) {
        if (identifier.current[1] === '0') {
          setError({
            type: 'validate',
            message: 'Please select your country code.',
          });
          return;
        }

        setError({
          type: 'validate',
          message: 'Make sure you entered your phone number correctly.',
        });
        return;
      }

      await createCredential({
        schema: 'phone',
        identifier: identifier.current,
      });
    }

    submitRequest(identifier.current, selectedSchema);
  };

  const handlePhoneNumberChange = (phone: string) => {
    phoneNumber.current = phone;
  };

  useEffect(() => {
    if (data?.result.code_sent) {
      navigate(routes.mfa.confirmIdentity.href, {
        state: {
          schema: selectedSchema,
          identifier: identifier.current,
          isEditable: true,
        },
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error('An error occured. Please try again.');
    }
  }, [isError]);

  const handleSkipMfaSetup = () => {
    dispatch(setAuthSuccess());
    navigate(routes.private.patientsOverview.href, { replace: true });
  };

  return {
    onSubmit,
    email,
    selectedSchema,
    handleRadioChange,
    error,
    handlePhoneNumberChange,
    defaultPhoneNumber: phoneNumber.current,
    canSkipMfa: patientData?.result.requires_mfa === false,
    isLoading: isAccountLoading,
    handleSkipMfaSetup,
  };
};
