import { Typography } from '@mui/material';
import EmptyList from './EmptyList';
import { useSafeZoneList } from 'pages/PatientAccount/hooks/use-safe-zone-list';
import ZonesList from './ZonesList';
import { SafeZone, SafeZoneParams } from 'types/safe-zones';
import { useMemo } from 'react';

type Props = {
  onZoneSave?: (zone: SafeZoneParams) => void;
  onZoneDelete?: (id: number) => void;
  safeZones?: SafeZone[];
  listButtonToLeft?: boolean;
};

const SafeZones = ({
  onZoneSave,
  safeZones,
  onZoneDelete,
  listButtonToLeft,
}: Props) => {
  const { zones: fetchedZones, isLoading } = useSafeZoneList(!!safeZones);

  const zones = useMemo(
    () => safeZones ?? fetchedZones,
    [safeZones, fetchedZones],
  );

  return (
    <div>
      <Typography color="primary" fontWeight={600} mb={1}>
        Safe Zones
      </Typography>
      {zones.length === 0 && !isLoading ? (
        <EmptyList onSave={onZoneSave} />
      ) : (
        <ZonesList
          onSave={onZoneSave}
          zones={zones}
          isLoading={isLoading}
          onDelete={onZoneDelete}
          buttonToLeft={listButtonToLeft}
        />
      )}
    </div>
  );
};

export default SafeZones;
