import { Button, ButtonProps } from '@mui/material';
import { useCopyRecipientManager } from 'hooks/use-copy-recipient-manager';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLinkAlerterRecipientManagerMutation } from 'store/api/alerters.api';

const MakeCopyButton = ({
  recipientId,
  ...rest
}: Omit<ButtonProps, 'onClick'> & { recipientId: number }) => {
  const { handleCopy, response, isLoading } = useCopyRecipientManager();
  const [link] = useLinkAlerterRecipientManagerMutation();
  const { alertId } = useParams();

  useEffect(() => {
    if (!isLoading && response && alertId) {
      link({
        recipientManagerId: response.result.id,
        alerterId: parseInt(alertId),
      });
    }
  }, [isLoading]);

  return (
    <Button
      onClick={() => handleCopy(recipientId)}
      variant="outlined"
      sx={{
        minWidth: 'fit-content',
      }}
      {...rest}
    >
      Make Copy
    </Button>
  );
};

export default MakeCopyButton;
