import { Box, Paper, Skeleton, styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { MapWidget, SubscriptionRequired, WidgetsGrid } from './components';
import IssuesAcknowledge from './components/IssuesAcknowledge';
import useDashboard from './hooks/use-dashboard';
import { useIssuesAcknowledge } from './hooks/use-issues-acknowledge';
import { Link } from 'react-router-dom';
import { routes } from 'nav';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Dashboard = () => {
  const profileId = Number(useParams().profileId);
  const { hasIssues } = useIssuesAcknowledge(profileId);

  const {
    accountId,
    deviceId,
    isRegionsFetching,
    hasActiveSubscription,
    location,
    regions,
    widgets,
    name,
    trackingExpiresOn,
    isDashboardLoading,
    hasNoRecord,
    isLocationDisabled,
  } = useDashboard(profileId, true);

  if (!hasActiveSubscription) {
    return <SubscriptionRequired />;
  }

  if (hasNoRecord) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: (t) => t.spacing(30) }}
      >
        <Typography variant="h5">No record found for this patient.</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={
        hasIssues
          ? ({ breakpoints, spacing }) => ({
              paddingBottom: spacing(13),
              [breakpoints.down('newMd')]: {
                paddingBottom: spacing(11),
              },
              [breakpoints.down('sm')]: {
                paddingBottom: spacing(10),
              },

              [breakpoints.down('xs')]: {
                paddingBottom: spacing(9),
              },
            })
          : undefined
      }
    >
      <Box width="100%">
        {isDashboardLoading ? (
          <Skeleton variant="rounded" height={465} />
        ) : isLocationDisabled ? (
          <Paper elevation={2}>
            <Box
              height="100%"
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h5" pb={2} pt={3}>
                Location Data Disabled
              </Typography>
              <Typography variant="body1" textAlign="center" pb={3}>
                Enable location data collection in{' '}
                <StyledLink to={routes.private.patientAccount.href(profileId)}>
                  Profile Settings{' '}
                </StyledLink>
                to view location information.
              </Typography>
            </Box>
          </Paper>
        ) : location ? (
          <MapWidget
            patientName={name}
            accountId={accountId}
            deviceId={deviceId}
            isRegionsLoading={isRegionsFetching}
            regions={regions}
            trackingExpiresOn={trackingExpiresOn}
            location={location}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: (t) => t.spacing(30) }}
          >
            <Typography variant="h5">
              No location record for this patient.
            </Typography>
          </Box>
        )}
      </Box>
      <WidgetsGrid isFetching={isDashboardLoading} widgets={widgets} />
      <Box
        position="absolute"
        sx={({ breakpoints, spacing }) => ({
          bottom: spacing(6),
          right: spacing(6),
          [breakpoints.down('newMd')]: {
            bottom: spacing(4),
            right: spacing(4),
          },
          [breakpoints.down('sm')]: {
            bottom: spacing(3),
            right: spacing(3),
          },

          [breakpoints.down('xs')]: {
            bottom: spacing(2),
            right: spacing(2),
          },
        })}
      >
        {hasIssues && <IssuesAcknowledge id={profileId} />}
      </Box>
    </Box>
  );
};

export default Dashboard;
