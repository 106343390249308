import { Theme, Typography, styled } from '@mui/material';

export const Wrapper = styled('div')(
  ({
    theme,
    isActive,
    isClickable = true,
  }: {
    theme?: Theme;
    isActive: boolean;
    isClickable?: boolean;
  }) => ({
    width: '100%',
    display: 'flex',
    height: '100%',
    gap: theme?.spacing(3),
    padding: theme?.spacing(isActive ? 1.875 : 2),
    borderRadius: theme?.spacing(1),
    border: isActive
      ? `2px solid ${theme?.palette.secondary.main}`
      : `1px solid ${theme?.palette.borderGray.main}`,
    cursor: isClickable ? 'pointer' : 'auto',
    '&.with-hover-effect': {
      transition: `all ${theme?.transitions.duration.short}ms ease-out`,
      ':hover': {
        borderColor: theme?.palette.common.white,
        boxShadow: theme?.shadows[4],
      },
    },
    [theme?.breakpoints.down('sm') ?? '']: {
      padding: theme?.spacing(isActive ? 1.375 : 1.5),
    },
  }),
);

export const Title = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
