import { useProfileData } from 'hooks/use-profile-data';
import { useParams } from 'react-router-dom';
import { useGetRemindersQuery } from 'store/api/reminders.api';

export const useRemindersList = () => {
  const { profileId } = useParams();

  useProfileData();

  const { data, isLoading } = useGetRemindersQuery(
    { patientProfileId: Number(profileId) },
    { skip: !profileId },
  );

  return {
    reminders: data,
    reminderCount: data
      ? Object.values(data).reduce((sum, curr) => {
          return sum + curr.length;
        }, 0)
      : 0,
    isLoading,
  };
};
