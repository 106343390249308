import {
  Box,
  BoxProps,
  Paper as MuiPaper,
  PaperProps,
  Skeleton as MuiSkeleton,
  SkeletonProps,
  styled,
  Theme,
} from '@mui/material';

const commonStyleRules = (theme: Theme, unlockedHeight: boolean) => ({
  ...(!unlockedHeight && {
    height: theme.spacing(23),
  }),
  ...(unlockedHeight && {
    minHeight: theme.spacing(23),
  }),
  borderRadius: theme.spacing(1),
});

export const Paper = styled(
  (props: PaperProps & { unlockedHeight?: boolean }) => <MuiPaper {...props} />,
  { shouldForwardProp: (prop) => prop !== 'unlockedHeight' },
)(({ theme, unlockedHeight }) => ({
  padding: theme.spacing(2),
  ...commonStyleRules(theme, !!unlockedHeight),
}));

export const ContentWrapper = styled(
  (props: BoxProps & { unlockedHeight?: boolean }) => <Box {...props} />,
  { shouldForwardProp: (prop) => prop !== 'unlockedHeight' },
)(
  ({
    theme,
    padding,
    unlockedHeight,
  }: {
    theme?: Theme;
    padding: string;
    unlockedHeight?: boolean;
  }) => ({
    padding,
    ...(!unlockedHeight && {
      height: theme?.spacing(14),
    }),
    ...(unlockedHeight && {
      minHeight: theme?.spacing(14),
    }),
    ...(theme
      ? {
          [theme.breakpoints.between('newLg', 'newXl')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }
      : {}),
    borderRadius: theme?.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme?.spacing(2),
  }),
);

export const Skeleton = styled(
  (props: SkeletonProps & { unlockedHeight?: boolean }) => (
    <MuiSkeleton {...props} />
  ),
  { shouldForwardProp: (prop) => prop !== 'unlockedHeight' },
)(({ theme, unlockedHeight }) => ({
  ...commonStyleRules(theme, !!unlockedHeight),
}));
