import { memo, useEffect, useState } from 'react';
import { ChannelsWrapper, HelperText } from '../styled';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ChannelSet } from 'types/alerters';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import { HelpIcon } from 'components/HelpIcon';

const Channels = ({
  error,
  readOnly,
}: {
  error?: string;
  readOnly?: boolean;
}) => {
  const { setValue, getValues } = useFormContext<SettingsForm>();

  const [channels, setChannels] = useState(getValues().channels);

  const handleChange = (value: ChannelSet, checked: boolean) => {
    if (checked) {
      updateValues(channels.concat(value));
      return;
    }
    if (value === ChannelSet.PUSH && !checked) {
      updateValues(
        channels.filter(
          (channel) => channel !== ChannelSet.CRITICAL && channel !== value,
        ),
      );
      return;
    }
    updateValues(channels.filter((c) => c !== value));
  };

  const updateValues = (values: ChannelSet[]) => {
    setValue('channels', values, { shouldDirty: true });
    setChannels(values);
  };

  useEffect(() => {
    if (channels.join() !== getValues().channels.join()) {
      setChannels(getValues().channels);
    }
  }, [getValues().channels]);

  const isCriticalDisabled = !channels.includes(ChannelSet.PUSH);

  return (
    <div>
      <ChannelsWrapper row>
        <FormControlLabel
          control={<Checkbox checked={channels.includes(ChannelSet.EMAIL)} />}
          label="Email"
          labelPlacement="end"
          disabled={readOnly}
          onChange={(e) =>
            handleChange(
              ChannelSet.EMAIL,
              (e.target as HTMLInputElement).checked,
            )
          }
          value={ChannelSet.EMAIL}
        />
        <FormControlLabel
          label="SMS"
          labelPlacement="end"
          disabled={readOnly}
          control={<Checkbox checked={channels.includes(ChannelSet.SMS)} />}
          onChange={(e) =>
            handleChange(ChannelSet.SMS, (e.target as HTMLInputElement).checked)
          }
          value={ChannelSet.SMS}
        />
        <FormControlLabel
          label="Automated Call"
          labelPlacement="end"
          disabled={readOnly}
          control={
            <Checkbox checked={channels.includes(ChannelSet.AUTOMATED_CALL)} />
          }
          onChange={(e) =>
            handleChange(
              ChannelSet.AUTOMATED_CALL,
              (e.target as HTMLInputElement).checked,
            )
          }
          value={ChannelSet.AUTOMATED_CALL}
        />
        <Box
          sx={{
            marginTop: { xxs: 1, xs: 0 },
          }}
        >
          <FormControlLabel
            sx={{ display: 'flex' }}
            label="Push Notifications"
            labelPlacement="end"
            disabled={readOnly}
            control={<Checkbox checked={channels.includes(ChannelSet.PUSH)} />}
            onChange={(e) =>
              handleChange(
                ChannelSet.PUSH,
                (e.target as HTMLInputElement).checked,
              )
            }
            value={ChannelSet.PUSH}
          />
          <Box display="flex">
            <FormControlLabel
              control={
                <Checkbox checked={channels.includes(ChannelSet.CRITICAL)} />
              }
              sx={{
                pl: 4,
                mr: 1,
                pt: {
                  xxs: 2,
                  sm: 0,
                },
                display: 'flex',
                maxHeight: ({ spacing }) => spacing(3),
              }}
              label="Critical Alerts"
              labelPlacement="end"
              disabled={isCriticalDisabled || readOnly}
              onChange={(e) =>
                handleChange(
                  ChannelSet.CRITICAL,
                  (e.target as HTMLInputElement).checked,
                )
              }
              value={ChannelSet.CRITICAL}
            />
            <HelpIcon
              message="Critical alerts ignore the mute switch and Do Not Disturb; the system plays a critical alert's sound regardless of the device's mute or Do Not Disturb settings."
              tooltipPlacement="top-start"
            />
          </Box>
        </Box>
      </ChannelsWrapper>
      <HelperText error>{error}</HelperText>
    </div>
  );
};

export default memo(Channels);
