import { useConfirmation } from 'hooks/use-confirmation';
import { FieldsData } from 'hooks/use-metric-rule';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetReminderTriggerQuery } from 'store/api/reminders.api';
import { cancelConditionEditing } from 'store/reducers/reminder/reminder.slice';
import { isRuleDifferentSelector } from 'store/selectors';

export const useReminderConditionList = () => {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const {
    triggerId,
    trigger: { editedRule, rules },
  } = useAppSelector((state) => state.reminder);
  const { data, isLoading } = useGetReminderTriggerQuery(triggerId, {
    skip: triggerId === null,
  });
  const isConditionModified = useAppSelector(
    isRuleDifferentSelector('reminder'),
  );

  const functionsRef = useRef<{
    getFieldsData: () => FieldsData;
    reset: () => void;
  }>(null);

  const discard = () => {
    functionsRef.current?.reset();
    dispatch(cancelConditionEditing());
  };

  const handleBackdropClick = () => {
    if (isConditionModified) {
      confirm(
        {
          title: 'You have unsaved changes',
          message: 'Do you want to save or discard them?',
          rejectButtonLabel: 'Go back',
          confirmButtonLabel: 'Discard',
        },
        discard,
      );
      return;
    }
    dispatch(cancelConditionEditing());
  };

  return {
    functionsRef,
    editedRule,
    conditions: rules,
    handleBackdropClick,
    isLoading: isLoading || typeof data === 'undefined',
  };
};
