import { heartRate } from 'assets/iconify/heart-rate';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';
import { HeartStatus } from '../types/enum/heart-status.enum';

export const getHeartRateStatus = (
  status: HeartStatus | undefined,
  value?: number,
): DataCellProps & { value?: string | number } => {
  const roundedValue = value ? Math.round(value) : undefined;

  if (status === HeartStatus.NORMAL) {
    return {
      icon: heartRate.icons.normal,
      status: DataStatus.POSITIVE,
      label: 'Normal',
      value: roundedValue,
    };
  }

  if (status === HeartStatus.HIGH) {
    return {
      icon: heartRate.icons.high,
      status: DataStatus.NEGATIVE,
      label: 'High',
      value: roundedValue,
    };
  }

  if (status === HeartStatus.LOW) {
    return {
      icon: heartRate.icons.low,
      status: DataStatus.NEGATIVE,
      label: 'Low',
      value: roundedValue,
    };
  }

  return {
    icon: heartRate.icons.disabled,
    status: DataStatus.DISABLED,
    label: 'Disabled',
    value: roundedValue,
  };
};
