import { Icon } from '@iconify/react';
import { IconButton, Tooltip } from '@mui/material';

const ExpandButton = ({
  onClick,
  isExpanded,
}: {
  onClick: () => void;
  isExpanded: boolean;
}) => {
  return (
    <Tooltip title="Expand table">
      <IconButton
        sx={{
          width: ({ spacing }) => spacing(5),
          height: ({ spacing }) => spacing(5),
        }}
        onClick={onClick}
        color="primary"
        size="small"
      >
        <Icon
          icon={`material-symbols:${
            isExpanded ? 'collapse' : 'expand'
          }-content`}
        />
      </IconButton>
    </Tooltip>
  );
};

export default ExpandButton;
