import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const safeZone: IconifyJSON = {
  prefix: 'safe-zone',
  icons: {
    'not-set': {
      ...defaultSizes,
      body: '<path d="M12 15.625C12.2333 15.625 12.425 15.5457 12.575 15.387C12.725 15.229 12.8 15.0334 12.8 14.8C12.8 14.5834 12.725 14.396 12.575 14.238C12.425 14.0794 12.2333 14 12 14C11.7667 14 11.575 14.0794 11.425 14.238C11.275 14.396 11.2 14.5834 11.2 14.8C11.2 15.0334 11.275 15.229 11.425 15.387C11.575 15.5457 11.7667 15.625 12 15.625ZM11.25 12.2H12.75V7.37505H11.25V12.2ZM12 21.475C9.83333 20.8917 8.04167 19.621 6.625 17.663C5.20833 15.7044 4.5 13.5167 4.5 11.1V5.35005L12 2.55005L19.5 5.35005V11.1C19.5 13.5167 18.7917 15.7044 17.375 17.663C15.9583 19.621 14.1667 20.8917 12 21.475Z" fill="currentColor"/>',
    },
    outside: {
      ...defaultSizes,
      body: '<path d="M9.9 15.125L12 13.025L14.1 15.125L15.15 14.05L13.05 11.95L15.15 9.85005L14.1 8.80005L12 10.9L9.9 8.80005L8.85 9.85005L10.95 11.95L8.85 14.05L9.9 15.125ZM12 21.475C9.83333 20.8917 8.04167 19.621 6.625 17.663C5.20833 15.7044 4.5 13.5167 4.5 11.1V5.35005L12 2.55005L19.5 5.35005V11.1C19.5 13.5167 18.7917 15.7044 17.375 17.663C15.9583 19.621 14.1667 20.8917 12 21.475Z" fill="currentColor"/>',
    },
    inside: {
      ...defaultSizes,
      body: '<path d="M10.95 15.2L16.25 9.90005L15.175 8.82505L10.95 13.075L8.825 10.95L7.75 12.025L10.95 15.2ZM12 21.475C9.83333 20.8917 8.04167 19.621 6.625 17.663C5.20833 15.7044 4.5 13.5167 4.5 11.1V5.35005L12 2.55005L19.5 5.35005V11.1C19.5 13.5167 18.7917 15.7044 17.375 17.663C15.9583 19.621 14.1667 20.8917 12 21.475Z" fill="currentColor"/>',
    },
    disabled: {
      ...defaultSizes,
      body: '<path d="M12 21.475C9.83333 20.8917 8.04167 19.621 6.625 17.663C5.20833 15.7043 4.5 13.5167 4.5 11.1V5.34999L12 2.54999L19.5 5.34999V11.1C19.5 13.5167 18.7917 15.7043 17.375 17.663C15.9583 19.621 14.1667 20.8917 12 21.475Z" fill="currentColor"/>',
    },
  },
};
