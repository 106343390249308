import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import CalendarTrigger from './components/CalendarTrigger';
import CalendarModal from './components/CalendarModal';

interface Props {
  onChange: (newValue: null | Dayjs) => void;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableWeekends?: boolean;
  size?: 'small' | 'medium';
  defaultValue?: Dayjs;
}

const DateInput = ({
  onChange,
  disableFuture,
  disablePast,
  disableWeekends,
  maxDate,
  minDate,
  size = 'small',
  defaultValue,
}: Props) => {
  const [value, setValue] = useState<Dayjs>(defaultValue ?? dayjs());

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOkClick = () => {
    onChange(value);
    onClose();
  };

  const onResetClick = () => {
    setValue(defaultValue ?? dayjs());
  };

  const handleValueChange = (newValue: Dayjs | null) => {
    setValue(newValue ? newValue : dayjs().add(1, 'day'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CalendarTrigger
        inputProps={{
          size,
          onClick: onOpen,
          value: value.format('MM/DD/YYYY'),
        }}
      />
      <CalendarModal
        onChange={handleValueChange}
        open={open}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        onResetClick={onResetClick}
        onClose={onClose}
        onOkClick={onOkClick}
        disableFuture={disableFuture}
        disablePast={disablePast}
        disableWeekends={disableWeekends}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
