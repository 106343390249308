import { Box, Button, styled, Typography } from '@mui/material';
import { routes } from 'nav';
import LoginMessage from 'pages/ForgotPassword/components/LoginMessage';
import ResendMessage from 'pages/Mfa/components/ResendMessage';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRequestPasswordResetMutation } from 'store/api/auth.api';

const Span = styled('span')(({ theme }) => ({
  color: theme.palette.black.main,
}));

const PasswordReseted = () => {
  const remainingSeconds = useRef(0);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isResent, setIsResent] = useState(false);
  const [resendLink, sentLinkState] = useRequestPasswordResetMutation();
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as { email: string };

  if (typeof state.email !== 'string') {
    navigate(routes.public.forgot.href);
  }

  const setSeconds = (newValue: number) => {
    remainingSeconds.current = newValue;
    setIsSaveDisabled(newValue >= 1);
  };

  const handleResendClick = () => {
    resendLink({ email: state.email });
    setIsResent(true);
  };

  useEffect(() => {
    const { data, isError, isSuccess, isLoading } = sentLinkState;

    if (!isLoading && data?.result.reset) {
      toast.success('The link was sent to your email address.');
      return;
    }

    if (!isLoading && ((isSuccess && !data?.result.reset) || isError)) {
      navigate(routes.public.forgot.href);
    }
  }, [sentLinkState.isLoading]);

  return (
    <Box>
      <Box
        px={1}
        pb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h5">Check your email</Typography>
        <Typography variant="body1" align="center" color="blue.main">
          We sent an email to{' '}
          <Span>{state?.email ? state.email : 'your email address'}</Span>
        </Typography>
        <Typography variant="body1" align="center" color="blue.main">
          Please follow the instructions sent in the email to reset your
          password.
        </Typography>
      </Box>
      <ResendMessage isCodeResent={isResent} setTimer={setSeconds} />
      <Button
        onClick={handleResendClick}
        disabled={isSaveDisabled}
        size="large"
        fullWidth
        sx={{ mt: 2 }}
      >
        Resend Link
      </Button>
      <LoginMessage />
    </Box>
  );
};

export default PasswordReseted;
