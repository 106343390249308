import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 20, height: 20 };

export const info: IconifyJSON = {
  prefix: 'info',
  icons: {
    normal: {
      ...defaultSizes,
      body: `<svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_10432_9446"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_10432_9446)">
        <path
          d="M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28848C12.2289 9.28848 12.4207 9.21108 12.5755 9.05628C12.7303 8.90148 12.8077 8.70965 12.8077 8.4808C12.8077 8.25197 12.7303 8.06014 12.5755 7.90533C12.4207 7.75053 12.2289 7.67313 12 7.67313C11.7712 7.67313 11.5793 7.75053 11.4245 7.90533C11.2697 8.06014 11.1923 8.25197 11.1923 8.4808C11.1923 8.70965 11.2697 8.90148 11.4245 9.05628C11.5793 9.21108 11.7712 9.28848 12 9.28848ZM12.0017 21.5C10.6877 21.5 9.45271 21.2506 8.29658 20.752C7.14043 20.2533 6.13475 19.5766 5.27953 18.7217C4.4243 17.8669 3.74724 16.8616 3.24836 15.706C2.74947 14.5504 2.50003 13.3156 2.50003 12.0017C2.50003 10.6877 2.74936 9.45271 3.24803 8.29658C3.7467 7.14042 4.42345 6.13474 5.27828 5.27953C6.13313 4.42429 7.13837 3.74723 8.29401 3.24835C9.44962 2.74947 10.6844 2.50002 11.9983 2.50002C13.3123 2.50002 14.5473 2.74936 15.7034 3.24803C16.8596 3.74669 17.8653 4.42344 18.7205 5.27828C19.5757 6.13313 20.2528 7.13837 20.7517 8.294C21.2505 9.44962 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5766 17.8653 18.7217 18.7205C17.8669 19.5757 16.8616 20.2528 15.706 20.7517C14.5504 21.2505 13.3156 21.5 12.0017 21.5Z"
          fill="currentColor"
        />
      </g>
    </svg>`,
    },
  },
};
