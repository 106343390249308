import config from 'env';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Link,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from '@mui/material';
import TitleLogo from 'components/TitleLogo';
import VInput from 'components/VInput';
import EmailField from 'pages/Login/components/EmailField';

import { FormProvider } from 'react-hook-form';
import { PasswordRegistration } from './components';
import PolicyCheck from './components/PolicyCheck';
import { useRegistrationForm } from 'hooks/use-registration-form';
import { routes } from 'nav';
import EnvChoose, { ProdEnv } from 'pages/Login/components/EnvChoose';
import { BCLocalStorage } from 'utils/storage';
import { clearAllProdCookies } from 'utils/cookie-tools';

const Registration = () => {
  const { state } = useLocation();

  const { methods, onSubmit, errors, onError, handlePasswordStrengthChange } =
    useRegistrationForm();

  const navigate = useNavigate();
  const routerDomLocation = useLocation();

  const [isEnvChooseOpen, setEnvChooseOpen] = useState(false);
  const [isReady, setReady] = useState(false);

  const handleNavigateToLogin = () => {
    navigate(routes.public.login.href);
  };

  const onEnvChoose = (value: ProdEnv) => {
    if (value === 'hipaa') {
      clearAllProdCookies();
    }

    BCLocalStorage.hipaa = value === 'hipaa';
    setEnvChooseOpen(false);
  };

  const onEnvChooseCancel = () => {
    handleNavigateToLogin();
    setEnvChooseOpen(false);
  };

  useEffect(() => {
    const email = new URLSearchParams(routerDomLocation.search).get('email');

    if (email) {
      methods.setValue('email', email);
    }

    if (
      config.REACT_APP_NODE_ENV === 'cordova_production' &&
      window.hasOwnProperty('cordova') &&
      !state?.shouldNotOpenEnvModal
    ) {
      setEnvChooseOpen(true);
    }

    setReady(true);
  }, []);

  return isReady ? (
    <>
      <Box display="flex" justifyContent="center" pb={4} px={1} gap={1}>
        <Typography color="primary" variant="h3">
          Sign up
        </Typography>
        <TitleLogo />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
          <Box display="flex" sx={{ flexFlow: { xxs: 'wrap', md: 'nowrap' } }}>
            <VInput
              type="text"
              size="large"
              placeholder="Enter your first name"
              label="First name"
              width="100%"
              name="firstname"
              inputProps={{ ...methods.register('firstName') }}
              error={!!errors.firstName}
              helperText={errors.firstName?.message || undefined}
            />
            <VInput
              type="text"
              size="large"
              placeholder="Enter your last name"
              label="Last name"
              width="100%"
              name="lastname"
              inputProps={{ ...methods.register('lastName') }}
              error={!!errors.lastName}
              helperText={errors.lastName?.message || undefined}
            />
          </Box>
          <EmailField
            validationError={errors.email}
            type="email"
            label="Email"
            name="email"
            defaultValue={state?.email ?? ''}
          />
          <PasswordRegistration
            errors={errors}
            onPasswordStrengthChange={handlePasswordStrengthChange}
          />
          <PolicyCheck validationError={errors.isAccepted} />
          <Box p={1} pt={3}>
            <Button size="large" fullWidth type="submit">
              Sign Up
            </Button>
          </Box>
        </form>
      </FormProvider>

      <Typography sx={{ px: 1, pt: 4 }} variant="body2" textAlign="center">
        Already have an account?{' '}
        <Link
          onClick={handleNavigateToLogin}
          color="secondary"
          fontWeight={600}
        >
          Login
        </Link>
      </Typography>

      <Modal open={isEnvChooseOpen}>
        <Box
          sx={{
            p: ({ spacing }) => spacing(2),
            backgroundColor: ({ palette }) => palette.white.main,
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            overflowY: 'auto',
            flexShrink: '0',
          }}
        >
          <EnvChoose
            onChoose={onEnvChoose}
            onCancel={onEnvChooseCancel}
            registrationPage
          />
        </Box>
      </Modal>
    </>
  ) : (
    <Box margin="0 auto" width="min-content">
      <CircularProgress size={60} />
    </Box>
  );
};

Registration.displayName = 'Registration';

export default Registration;
