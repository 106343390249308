import { Box, OutlinedInput, Slider, Typography } from '@mui/material';
import {
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

interface RadiusSliderProps {
  onChange: (value: number | number[]) => void;
  defaultRadius?: number;
}

const MIN = 50;
const MAX = 500;

const getValueInRange = (value: number) => {
  if (value < MIN) return MIN;
  if (value > MAX) return MAX;
  return value;
};

const RadiusSlider = ({ onChange, defaultRadius }: RadiusSliderProps) => {
  const [value, setValue] = useState<number>(defaultRadius ?? MIN);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (value: number | number[]) => {
    const finalValue = getValueInRange(
      Array.isArray(value) ? value[0] : Number.isNaN(value) ? 0 : value,
    );
    setValue(finalValue);
    onChange(finalValue);
    if (inputRef.current) {
      inputRef.current.value = finalValue.toString();
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    handleChange(getValueInRange(parseInt(e.target.value)));
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
      handleChange(
        getValueInRange(parseInt((e.target as HTMLInputElement).value)),
      );
    }
  };

  const handleMouseUp = (e: MouseEvent<HTMLInputElement>) => {
    handleChange(
      getValueInRange(parseInt((e.target as HTMLInputElement).value)),
    );
  };

  useEffect(() => {
    if (defaultRadius !== value) {
      handleChange(defaultRadius ?? MIN);
    }
  }, [defaultRadius]);

  return (
    <Box display="flex" gap={2} alignItems="center">
      <Typography variant="subtitle2">Radius</Typography>
      <Slider
        value={value}
        onChange={(e, v) => handleChange(v)}
        sx={({ spacing }) => ({
          maxWidth: spacing(28.5),
          padding: spacing(2, 0),
        })}
        min={MIN}
        max={MAX}
      />
      <Box display="flex" gap={1} alignItems="center">
        <OutlinedInput
          type="number"
          size="small"
          inputRef={inputRef}
          defaultValue={value.toString()}
          sx={({ spacing }) => ({ width: spacing(9) })}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          inputProps={{
            onMouseUp: handleMouseUp,
            min: MIN,
            max: MAX,
          }}
        />
        <Typography color="text.secondary" variant="body1">
          m
        </Typography>
      </Box>
    </Box>
  );
};

export default RadiusSlider;
