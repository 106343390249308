import { Box, Button, Typography } from '@mui/material';
import { useHierarchy } from 'pages/Alerters/hooks/use-hierarchy';
import RecipientLevel from './RecipientLevel';
import { Icon } from '@iconify/react';
import { memo } from 'react';
import { AlerterRecipientSet, PatternType } from 'types/alerters';

export type Recipient = {
  id: number;
  type: PatternType | 'User' | 'PatientProfile';
};

export interface Level {
  recipients: Recipient[];
  toRemove?: Recipient[];
  priority: number;
  id?: number;
}

const Hierarchy = ({
  sets,
  error,
  readOnly,
}: {
  sets: AlerterRecipientSet[];
  error?: string;
  readOnly?: boolean;
}) => {
  const {
    isAddNewLayerDisabled,
    addNewLayer,
    layers,
    handleRecipientsSelection,
    handleRemove,
  } = useHierarchy(sets);

  return (
    <Box
      flexGrow={1}
      sx={{
        p: 2,
        px: { xxs: 0, newMd: 2 },
        maxWidth: {
          newXl: '516px',
        },
      }}
    >
      <Typography color="primary" pb={1.5}>
        Levels
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        alignItems="start"
        justifyContent="start"
        width="100%"
      >
        {layers.map((layer, index) => (
          <RecipientLevel
            key={layer.layerId}
            onRemove={() => handleRemove(index)}
            isRemoveDisabled={layers.length === 1}
            level={index + 1}
            error={error}
            readOnly={readOnly}
            recipients={layer.recipients}
            selectedRecipients={layer.selected}
            onChange={handleRecipientsSelection}
          />
        ))}
        <Button
          color="primary"
          disabled={isAddNewLayerDisabled || readOnly}
          onClick={addNewLayer}
          startIcon={<Icon icon="material-symbols:add-box-outline" />}
        >
          Add Level
        </Button>
      </Box>
    </Box>
  );
};

export default memo(Hierarchy);
