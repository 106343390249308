import * as yup from 'yup';
import config from 'env';
import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAuthenticateMutation,
  useVerifyEmailHipaaMutation,
  useVerifyEmailMutation,
} from 'store/api/auth.api';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'nav';
import { useAppDispatch } from 'store';
import { setAuthSuccess } from 'store/reducers/user/user.slice';
import { ProdEnv } from '../components/EnvChoose';
import { BCLocalStorage } from 'utils/storage';
import { clearAllProdCookies } from 'utils/cookie-tools';
import { changeEnvironment } from 'utils/change-environment';

export interface LoginFormData {
  email: string;
  password: string;
  isRememberMeChecked: boolean;
}

const loginSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string(),
  isRememberMeChecked: yup.boolean(),
});

export const useLoginForm = () => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEnvChooseOpen, setEnvChooseOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();
  const [isReady, setIsReady] = useState(params.get('email') ? false : true);

  const methods = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onSubmit',
  });

  const [verifyEmail, { isLoading: isEmailLoading }] = useVerifyEmailMutation();
  const [verifyEmailHipaa] = useVerifyEmailHipaaMutation();
  const [
    authenticate,
    { isLoading: isAuthLoading, data: authResponse, error: authError },
  ] = useAuthenticateMutation();

  const onSubmit = (data: LoginFormData) => {
    if (
      !(isAuthLoading || isEmailLoading || methods.getFieldState('email').error)
    ) {
      if (!isEmailValid) {
        verifyEmail({ email: data.email })
          .unwrap()
          .then(async ({ result: { exists: isValid, pending: isPending } }) => {
            const {
              result: { exists: isHipaaValid, pending: isHipaaPending },
            } = await verifyEmailHipaa({
              email: data.email,
            }).unwrap();

            setIsEmailValid(isValid || isHipaaValid);

            if (!isValid && isHipaaValid) {
              changeEnvironment('hipaa', data.email);
            }

            if (isValid && !isHipaaValid) {
              changeEnvironment('personal', data.email);
            }

            if (isValid && isHipaaValid && !params.get('redirected')) {
              setEnvChooseOpen(true);
            }

            if (!isValid && !isHipaaValid) {
              if (isPending) {
                BCLocalStorage.hipaa = false;
                navigate(routes.public.register.href, {
                  state: {
                    shouldNotOpenEnvModal: true,
                    email: data.email,
                  },
                });
              }

              if (isHipaaPending) {
                BCLocalStorage.hipaa = true;
                navigate(routes.public.register.href, {
                  state: {
                    shouldNotOpenEnvModal: true,
                    email: data.email,
                  },
                });
              }

              onError({
                email: {
                  type: 'verify',
                },
              });
            }

            setIsReady(true);
            return;
          });

        if (
          config.REACT_APP_NODE_ENV === 'cordova_production' &&
          window.hasOwnProperty('cordova')
        ) {
          verifyEmailHipaa({ email: data.email });
        }
        return;
      }

      if (data.password) {
        authenticate({
          email: data.email,
          password: data.password,
        });
        return;
      }
      onError({
        password: { type: 'required', message: 'Password is required.' },
      });
    }
  };

  useEffect(() => {
    const email = params.get('email');
    if (email) {
      methods.setValue('email', email);
      onSubmit({
        email,
        password: '',
        isRememberMeChecked: false,
      });
      setParams({});
      return;
    }

    setIsReady(true);
  }, []);

  useEffect(() => {
    if (authResponse) {
      if (authResponse.meta.credential.is_confirmed !== null) {
        navigate(routes.mfa.selectCredential.href);
      } else {
        dispatch(setAuthSuccess());
      }
    }

    if (authError) {
      onError({
        password: { type: 'auth' },
      });
    }
  }, [authResponse, authError]);

  const [errors, setErrors] = useState<FieldErrorsImpl<LoginFormData>>({});

  const onError: SubmitErrorHandler<LoginFormData> = (newErrors) => {
    setErrors(newErrors);
  };

  const goBack = () => {
    setIsEmailValid(false);
    methods.reset();
    methods.setFocus('email');
  };

  const onEnvChoose = (value: ProdEnv) => {
    if (value === 'hipaa') {
      clearAllProdCookies();
    }
    changeEnvironment(value, methods.getValues().email);
    setEnvChooseOpen(false);
  };

  const onEnvChooseCancel = () => {
    setEnvChooseOpen(false);
    setIsEmailValid(false);
  };

  return {
    methods,
    authenticate,
    isAuthLoading,
    isEnvChooseOpen,
    onSubmit,
    onEnvChoose,
    onEnvChooseCancel,
    onError,
    errors,
    isEmailLoading,
    isEmailValid,
    goBack,
    isReady,
  };
};
