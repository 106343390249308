import { Box, Paper, Typography, useTheme } from '@mui/material';
import { AddProfileButton } from './AddProfile';

const ConnectProfile = () => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: theme.spacing(40),
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
        <Typography variant="h5">No Profile Connected</Typography>
        <AddProfileButton />
      </Box>
    </Paper>
  );
};

export default ConnectProfile;
