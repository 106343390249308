/* eslint-disable @typescript-eslint/no-empty-function */
import { Skeleton } from '@mui/material';
import { reduceCapacity } from 'assets/iconify';
import {
  RecipientManagerCard,
  RecipientSetModal,
  Summary,
} from 'components/_extra';
import SharedAlertersDetails from 'components/_extra/Alerter/Cards/SharedAlertersDetails';
import { EmptyList } from 'components/_extra/EmptyList';
import { GridWrapper } from 'components/_extra/generic';
import { useCopyRecipientManager } from 'hooks/use-copy-recipient-manager';
import { useRecipientSets } from 'hooks/use-recipient-sets';
import { useEditRecipientsSetModal } from 'pages/AlerterDetails/hooks/use-edit-recipients-set-modal';
import { useRecipientSetSwitch } from 'pages/AlerterDetails/hooks/use-recipient-set-switch';
import CreateRecipientSetButton from 'pages/Alerters/components/RecipientSets/CreateRecipientSetButton';

const RecipientSets = () => {
  const {
    selectedIndex,
    isRecipientManagerLoading,
    isOpen,
    onClose,
    functionRef,
    handleClick: handleEdit,
  } = useEditRecipientsSetModal(true);
  const { sets, activeSetsId, isLoading } = useRecipientSets(true);
  const handleToggle = useRecipientSetSwitch(true);
  const { handleCopy } = useCopyRecipientManager(true);

  return (
    <>
      <GridWrapper
        sx={{
          gap: 3,
          gridTemplateColumns:
            sets.length === 0 && !isLoading ? '100%' : undefined,
        }}
      >
        {isLoading ? (
          <>
            <Skeleton variant="rounded" height={132} />
            <Skeleton variant="rounded" height={132} />
          </>
        ) : sets.length > 0 ? (
          sets.map((set, index) => (
            <RecipientManagerCard
              key={set.id}
              title={set.name ?? 'Untitled'}
              isActive={activeSetsId?.includes(set.id)}
              onCopy={() => handleCopy(set.id)}
              onEdit={() => handleEdit(index)}
              hideDelete
              onSwitchToggle={(checked) => handleToggle(checked, set.id)}
            >
              {set.alerters.length > 0 && (
                <Summary
                  title="Used by"
                  details={
                    <SharedAlertersDetails
                      alerters={set.alerters?.map(
                        ({ name }) => name ?? 'Untitled',
                      )}
                    />
                  }
                />
              )}
              <Summary
                title="Recipients"
                details={
                  <>
                    {set.recipientsCount} Caregiver
                    {set.recipientsCount !== 1 && 's'}
                    {set.patientsCount && set.patientsCount > 0 ? (
                      <>
                        <br />
                        {set.patientsCount} Patient
                        {set.patientsCount !== 1 && 's'}
                      </>
                    ) : null}
                  </>
                }
              />
            </RecipientManagerCard>
          ))
        ) : (
          <EmptyList
            title="Start adding recipient sets"
            message={
              <>
                Use recipient sets to group the people who will receive
                notifications from <strong>BoundaryCare</strong> reminders, to
                set schedules and shifts, and to develop hierarchical systems.
                Create a few recipient sets and mix and match them with
                reminders as needed.
              </>
            }
            icon={reduceCapacity.icons.normal}
          >
            <CreateRecipientSetButton isReminder />
          </EmptyList>
        )}
      </GridWrapper>
      {selectedIndex !== null && sets[selectedIndex] && (
        <RecipientSetModal
          isOpen={isOpen}
          withScheduler={false}
          defaultValues={sets[selectedIndex]}
          onClose={onClose}
          submitFormRef={functionRef}
          selectedIndex={selectedIndex}
          isLoading={isRecipientManagerLoading}
        />
      )}
    </>
  );
};

export default RecipientSets;
