import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const vo2Max: IconifyJSON = {
  prefix: 'vo2-max',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M5.49 13.66L2 5.14H4.08L6.35 10.82L8.6 5.14H10.7L7.21 13.66H5.49ZM13.39 11.2C13.88 11.7 14.47 11.96 15.15 11.96C15.83 11.96 16.42 11.71 16.9 11.2C17.38 10.7 17.62 10.1 17.62 9.4C17.62 8.7 17.38 8.1 16.9 7.6C16.42 7.1 15.84 6.84 15.15 6.84C14.46 6.84 13.87 7.09 13.39 7.6C12.91 8.1 12.67 8.7 12.67 9.4C12.67 10.1 12.91 10.7 13.39 11.2ZM18.26 12.51C17.4 13.37 16.37 13.81 15.15 13.81C13.93 13.81 12.89 13.38 12.03 12.53C11.17 11.67 10.75 10.63 10.75 9.41C10.75 8.19 11.18 7.16 12.03 6.3C12.89 5.44 13.93 5 15.15 5C16.37 5 17.4 5.43 18.26 6.3C19.12 7.16 19.56 8.2 19.56 9.41C19.56 10.62 19.13 11.65 18.26 12.52V12.51ZM21.95 14.86V15.6H19.5V15.34C19.5 15.24 19.51 15.15 19.53 15.06C19.55 14.97 19.57 14.89 19.6 14.82C19.63 14.75 19.67 14.68 19.72 14.6C19.77 14.52 19.82 14.47 19.87 14.42C19.91 14.37 19.97 14.32 20.05 14.26C20.13 14.19 20.19 14.15 20.24 14.12C20.28 14.09 20.35 14.04 20.44 13.97C20.53 13.9 20.6 13.86 20.63 13.83C20.99 13.58 21.16 13.35 21.16 13.15C21.16 13.03 21.12 12.93 21.05 12.85C20.98 12.77 20.88 12.73 20.74 12.73C20.6 12.73 20.48 12.77 20.39 12.86C20.3 12.95 20.26 13.05 20.26 13.17H19.5C19.5 12.82 19.62 12.54 19.86 12.32C20.1 12.1 20.4 11.98 20.76 11.98C21.08 11.98 21.35 12.08 21.58 12.28C21.8 12.47 21.91 12.75 21.91 13.09C21.91 13.29 21.88 13.48 21.81 13.64C21.74 13.8 21.66 13.93 21.57 14.03C21.48 14.13 21.35 14.23 21.19 14.35C21.19 14.35 21.12 14.4 20.99 14.48C20.87 14.56 20.77 14.63 20.7 14.68C20.63 14.73 20.59 14.79 20.56 14.84H21.95V14.86ZM5.4 16H6.2L7.42 17.68L8.65 16H9.45V19.9H8.57V17.5L7.43 19.07L6.29 17.49V19.9H5.42V16H5.4ZM9.84 19.9L11.51 16H12.3L13.97 19.9H13.06L12.71 19.08H11.09L10.74 19.9H9.83H9.84ZM11.91 17.14L11.42 18.31H12.4L11.91 17.14ZM14.95 19.9H13.93L15.39 17.93L14.01 16H15.02L15.91 17.25L16.8 16H17.81L16.44 17.93L17.9 19.9H16.88L15.91 18.61L14.95 19.9Z" fill="currentColor"/>',
    },
  },
};
