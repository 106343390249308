import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { formatTimeFrom } from 'utils/time-format';
import { ContentWrapper, Paper, Skeleton } from './WidgetBase.styled';
import { Link } from 'react-router-dom';
import { PropsWithChildren } from 'react';

export interface WidgetBaseProps {
  title?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  lastUpdate?: string | number;
  headerContent?: React.ReactNode;
  padding?: string;
  isLoading?: boolean;
  sx?: SxProps;
  skeletonSx?: SxProps;
  unlockedHeight?: boolean;
  href?: string;
}

const Wrapper = ({
  href,
  children,
}: PropsWithChildren<{ href?: string }>): JSX.Element => {
  if (href) {
    return <Link to={href}>{children}</Link>;
  }

  return <>{children}</>;
};

const WidgetBase = ({
  fullWidth,
  title,
  children,
  lastUpdate,
  headerContent,
  padding,
  isLoading,
  unlockedHeight,
  href,
  sx = {},
  skeletonSx = {},
}: WidgetBaseProps) => {
  const { shadows, spacing, palette, transitions } = useTheme();

  const rootSx = {
    width: fullWidth ? '100%' : 'auto',
    transition: `box-shadow ${transitions.duration.short}`,
  };
  const hoverSx = href ? { boxShadow: shadows[6] } : {};

  if (isLoading) {
    return <Skeleton variant="rectangular" sx={skeletonSx} />;
  }
  return (
    <Wrapper href={href}>
      <Paper
        elevation={2}
        sx={{
          ...rootSx,
          ...sx,
          ':hover': {
            ...hoverSx,
          },
        }}
        unlockedHeight={unlockedHeight}
      >
        {title && (
          <Box display="flex">
            <Typography flexGrow={1} variant="subtitle2">
              {title}
            </Typography>
            {headerContent}
          </Box>
        )}
        <ContentWrapper
          padding={padding || spacing(0)}
          unlockedHeight={unlockedHeight}
        >
          {children}
        </ContentWrapper>
        <Typography
          display="block"
          variant="caption"
          textAlign="right"
          color={palette.blue.main}
        >
          {lastUpdate && <>Last update: {formatTimeFrom(lastUpdate)}</>}
        </Typography>
      </Paper>
    </Wrapper>
  );
};

export default WidgetBase;
