import { Box } from '@mui/material';
import TriggerList from './TriggerList';

const Triggers = () => {
  return (
    <Box display="flex" flexDirection="column" gap={3} alignItems="start">
      <TriggerList />
    </Box>
  );
};

export default Triggers;
