import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChangeEventHandler } from 'react';
import { CredentialType } from 'models/auth.model';

interface IProps {
  checked: boolean;
  value: number;
  identifier: string;
  schema: CredentialType;
  handleChange: ChangeEventHandler<HTMLInputElement>;
}

const VERIFICATION_METHODS = {
  email: 'email',
  phone: 'phone number',
};

const ConfirmationMethod = ({
  value,
  checked,
  identifier,
  schema,
  handleChange,
}: IProps) => {
  return (
    <>
      <Box display="flex" alignItems="center" pb={2}>
        <Radio
          color="secondary"
          value={value}
          checked={checked}
          onChange={handleChange}
        />

        <Box pl={1}>
          <Typography variant="body2">
            Your current{' '}
            {VERIFICATION_METHODS[
              schema as keyof typeof VERIFICATION_METHODS
            ] || ''}
          </Typography>
          <Typography variant="body1" color="blue.main">
            {identifier}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ConfirmationMethod;
