import { Box, InputBaseProps, TextFieldProps } from '@mui/material';
import Input from 'components/DateRangeInput/components/Input';
import { forwardRef } from 'react';

interface Props {
  inputProps?: TextFieldProps;
  onClick?: () => void;
  disabled?: boolean;
}

const CalendarTrigger = forwardRef<HTMLDivElement, Props>(
  ({ inputProps = {}, onClick, disabled }, ref) => {
    return (
      <Box ref={ref} display="flex" alignItems="center" flexWrap="wrap">
        <Input
          onClick={onClick}
          disabled={disabled}
          size="small"
          sx={{ cursor: 'pointer' }}
          {...(inputProps as InputBaseProps)}
        />
      </Box>
    );
  },
);

export default CalendarTrigger;
