export const FilterAppleWatchDeviceStatus = {
  DISCONNECTED: 'disconnected',
  MISCONFIGURED: 'misconfigured',
  LOCKED: 'device_locked',
  NOT_WORN: 'not_worn',
  OK: 'ok',
} as const;

export type FilterAppleWatchDeviceStatus =
  (typeof FilterAppleWatchDeviceStatus)[keyof typeof FilterAppleWatchDeviceStatus];

export const FilterAppleWatchBatteryStatus = {
  CRITICAL: 'must_charge',
  LOW: 'low',
  GOOD: 'good',
  CHARGING: 'charging',
} as const;

export type FilterAppleWatchBatteryStatus =
  (typeof FilterAppleWatchBatteryStatus)[keyof typeof FilterAppleWatchBatteryStatus];

export const FilterAppleWatchSafeZoneStatus = {
  NOT_SET: 'not_set',
  OUTSIDE: 'outside',
  INSIDE: 'inside',
  DISABLED: 'disabled',
} as const;

export type FilterAppleWatchSafeZoneStatus =
  (typeof FilterAppleWatchSafeZoneStatus)[keyof typeof FilterAppleWatchSafeZoneStatus];

export const FilterAppleWatchFallDetectionStatus = {
  DETECTED: 'detected',
  OK: 'ok',
} as const;

export type FilterAppleWatchFallDetectionStatus =
  (typeof FilterAppleWatchFallDetectionStatus)[keyof typeof FilterAppleWatchFallDetectionStatus];

export const FilterAppleWatchHeartRateStatus = {
  HIGH: 'high',
  LOW: 'low',
  // GOOD: 'good',
  DISABLED: 'disabled',
} as const;

export type FilterAppleWatchHeartRateStatus =
  (typeof FilterAppleWatchHeartRateStatus)[keyof typeof FilterAppleWatchHeartRateStatus];

export const FilterAppleWatchHeartRhythmStatus = {
  IRREGULAR: 'irregular',
} as const;

export type FilterAppleWatchHeartRhythmStatus =
  (typeof FilterAppleWatchHeartRhythmStatus)[keyof typeof FilterAppleWatchHeartRhythmStatus];

export const FilterAppleWatchBloodOxygenStatus = {
  LOW: 'low',
  NORMAL: 'normal',
  DISABLED: 'disabled',
} as const;

export type FilterAppleWatchBloodOxygenStatus =
  (typeof FilterAppleWatchBloodOxygenStatus)[keyof typeof FilterAppleWatchBloodOxygenStatus];

export const FilterAppleWatchRespiratoryRateStatus = {
  LOW: 'low',
  NORMAL: 'normal',
  DISABLED: 'disabled',
} as const;

export type FilterAppleWatchRespiratoryRateStatus =
  (typeof FilterAppleWatchRespiratoryRateStatus)[keyof typeof FilterAppleWatchRespiratoryRateStatus];

export interface FilterItem<TChips> {
  isInvisible?: boolean;
  selected: TChips[];
  exclusiveFor?: 'history' | 'profile-overview';
}

export interface Filters {
  appleWatch: {
    device: FilterItem<FilterAppleWatchDeviceStatus>;
    battery: FilterItem<FilterAppleWatchBatteryStatus>;
    safeZone: FilterItem<FilterAppleWatchSafeZoneStatus>;
    fallDetection: FilterItem<FilterAppleWatchFallDetectionStatus>;
    heartRate: FilterItem<FilterAppleWatchHeartRateStatus>;
    heartRhythm: FilterItem<FilterAppleWatchHeartRhythmStatus>;
    bloodOxygen: FilterItem<FilterAppleWatchBloodOxygenStatus>;
    respiratoryRate: FilterItem<FilterAppleWatchRespiratoryRateStatus>;
    sleepTime: FilterItem<never>;
    helpRequested: FilterItem<FilterAppleWatchFallDetectionStatus>;
    seizureDetected: FilterItem<FilterAppleWatchFallDetectionStatus>;
  };
  patientDetails: undefined;
  surveys: undefined;
}

export type FiltersAppleWatchKeys = keyof Filters['appleWatch'];
