import {
  IHistoryItem,
  IHistoryRequest,
  IHistoryResponse,
} from 'models/history.model';
import { bcApi } from './_api';

export const historyApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchHistory: builder.query<IHistoryItem[], IHistoryRequest>({
      query: (data) => ({
        url: '/records/range/synthesized',
        method: 'GET',
        params: data,
      }),
      transformResponse: (response: IHistoryResponse) => response.results,
    }),
  }),
});

export const { useFetchHistoryQuery } = historyApi;
