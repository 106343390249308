import {
  Autocomplete,
  Checkbox,
  Chip,
  MenuItem,
  TextField,
  Tooltip,
  inputBaseClasses,
  outlinedInputClasses,
  styled,
} from '@mui/material';
import { useSafeZonesSelector } from 'pages/AlerterDetails/hooks/use-safe-zones-selector';
import React, { Dispatch, SetStateAction } from 'react';
import { SafeZone } from 'types/safe-zones';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  minWidth: 'min-content',
  [`.${inputBaseClasses.root}`]: {
    maxHeight: theme.spacing(5),
    flexWrap: 'nowrap',
    padding: theme.spacing(0, 1),
    [`.${inputBaseClasses.input}`]: {
      padding: theme.spacing(1, 0, 1, 1.5),
      height: theme.spacing(3),
      maxWidth: theme.spacing(21),
      whiteSpace: 'no-wrap',
      textOverflow: 'ellipsis',
    },
    [`&.${inputBaseClasses.focused}`]: {
      [`.${outlinedInputClasses.notchedOutline}`]: {
        border: `1px solid ${theme.palette.blue.light}`,
      },
    },
  },
}));

const getNameList = (
  options: (SafeZone | { id: number; label: string })[],
  startIndex?: number,
) => {
  return (
    <>
      {options.slice(startIndex ?? 0).map(({ label, id }) => (
        <React.Fragment key={id ?? 'all'}>
          {label} <br />
        </React.Fragment>
      ))}
    </>
  );
};

interface SafeZoneSelectorProps {
  id?: number;
  value: number[];
  setValue: Dispatch<SetStateAction<number[]>>;
  onOpen?: () => void;
  readOnly?: boolean;
}

const SafeZoneSelector = ({
  id,
  value,
  setValue,
  onOpen,
  readOnly,
}: SafeZoneSelectorProps) => {
  const { options, handleChange } = useSafeZonesSelector(id, value, setValue);

  const isAllSelected = value.includes(-1);

  return (
    <Autocomplete
      options={options}
      value={options.filter(({ id }) => value.includes(id ?? -1))}
      multiple
      onOpen={onOpen}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableListWrap
      ChipProps={{ size: 'small' }}
      limitTags={3}
      readOnly={readOnly}
      groupBy={(option) => (option.id === -1 ? 'Preset' : 'Safe Zones')}
      disableCloseOnSelect
      onChange={(e, values) => handleChange(values)}
      renderOption={(props, option, { selected }) => {
        return (
          <MenuItem
            {...props}
            selected={isAllSelected || selected}
            sx={{
              pointerEvents:
                isAllSelected && option.id !== -1 ? 'none' : 'auto',
            }}
          >
            <Checkbox
              checked={isAllSelected || selected}
              sx={{ mr: 1, py: 0 }}
              size="small"
            />
            {option.label}
          </MenuItem>
        );
      }}
      renderTags={(options, getTagProps) => {
        const numTags = options.length;
        const limitTags = 1;

        return (
          <>
            {options.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={`option.id${index}`}
                color={option.id === -1 ? 'secondary' : 'default'}
                size="small"
                label={option.label}
              />
            ))}

            {numTags > limitTags && (
              <span>
                <Tooltip
                  title={getNameList(options, 1)}
                  PopperProps={{ sx: { pb: 0.5 } }}
                >
                  <Chip
                    sx={{ m: '3px' }}
                    size="small"
                    label={`+${numTags - limitTags}`}
                  />
                </Tooltip>
              </span>
            )}
          </>
        );
      }}
      sx={{ maxWidth: '100%' }}
      renderInput={(props) => (
        <StyledTextField
          {...props}
          placeholder={value.length === 0 ? 'Select safe zones' : undefined}
        />
      )}
    />
  );
};

export default SafeZoneSelector;
