import {
  Box,
  Tabs,
  Tab as MuiTab,
  styled,
  TabProps,
  SxProps,
  Theme,
} from '@mui/material';
import { Link } from 'react-router-dom';

export const FilterTabIndex = {
  APPLE_WATCH: 0,
  PATIENT_DETAILS: 1,
  SURVEYS: 2,
} as const;

export type FilterTabIndex =
  (typeof FilterTabIndex)[keyof typeof FilterTabIndex];

export interface FilterTab {
  name: string;
  value: number | string;
  href: string;
  isDisabled?: boolean;
}

const Tab = styled(
  <C extends React.ElementType>(
    props: TabProps<C, { isModified?: boolean }>,
  ) => <MuiTab {...props} />,
  { shouldForwardProp: (prop) => prop !== 'isModified' },
)(({ theme, isModified }) => ({
  position: 'relative',
  ...(isModified && {
    '&::before': {
      position: 'absolute',
      left: theme.spacing(1),
      content: '""',
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.error.main,
    },
  }),
}));

export interface FilterTabsProps {
  value: number;
  tabs: FilterTab[];
  onChange: (_e: React.SyntheticEvent, newValue: number) => void;
  modifiedTabIds?: (string | number)[];
  rootSx?: SxProps<Theme>;
}

const FilterTabs = ({
  value,
  onChange,
  modifiedTabIds = [],
  tabs = [],
  rootSx = {},
}: FilterTabsProps) => {
  return (
    <Box sx={{ width: '100%', ...rootSx }}>
      <Tabs onChange={onChange} value={value}>
        {tabs.map(
          (item) =>
            item.href !== '#' && (
              <Tab
                key={item.value}
                label={item.name}
                value={item.value}
                disabled={item.isDisabled}
                isModified={modifiedTabIds.includes(item.value)}
                component={Link}
                to={item.href}
              />
            ),
        )}
      </Tabs>
    </Box>
  );
};

export default FilterTabs;
