import { Icon } from '@iconify/react';
import { Box, Button, ButtonProps, Collapse, styled } from '@mui/material';
import { FilterItem, FilterItemProps } from './components';
import { useCollapse } from './hooks/use-collapse';

const StyledButton = styled((props: ButtonProps) => (
  <Button variant="text" disableRipple {...props} />
))(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0, 0.5, 0, 0.25),
  textTransform: 'none',
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2),
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: 'unset',
    textDecoration: 'underline',
    textUnderlineOffset: theme.spacing(0.5),
  },
}));

interface Props {
  resetFilters?: () => void;
  filters: FilterItemProps[];
  isUnset?: boolean;
  withoutChips?: boolean;
}

const Filters = ({ filters, resetFilters, isUnset, withoutChips }: Props) => {
  const { boxRef, isExpanded, setExpanded, collapsedSize, isCollapsible } =
    useCollapse();

  return (
    <Box width="100%" pt={withoutChips ? 4 : 0}>
      {!withoutChips && (
        <Box display="flex" justifyContent="end">
          <StyledButton
            color="error"
            onClick={resetFilters}
            sx={{ visibility: isUnset ? 'hidden' : 'visible', mt: 0.5 }}
          >
            <Icon icon="material-symbols:close" width={20} height={20} />
            Clear All
          </StyledButton>
        </Box>
      )}
      <Collapse in={isExpanded} collapsedSize={collapsedSize}>
        <Box ref={boxRef} display="flex" flexShrink="0" flexWrap="wrap">
          {filters.map((props, index) => (
            <FilterItem
              withoutChips={withoutChips}
              withoutBorder={index === filters.length - 1}
              {...props}
            />
          ))}
        </Box>
      </Collapse>
      <Box display="flex" justifyContent="center" paddingY={2}>
        {isCollapsible && (
          <StyledButton onClick={() => setExpanded((prev) => !prev)}>
            <Icon
              icon="material-symbols:expand-more"
              rotate={isExpanded ? 90 : 0}
              width={20}
              height={20}
            />
            Show {isExpanded ? 'Less' : 'More'}
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};

export default Filters;
