import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const addKey: IconifyJSON = {
  prefix: 'add-key',
  icons: {
    normal: {
      ...defaultSizes,
      body: `<path d="M10.8848 7C10.8848 6.5525 11.0421 6.17142 11.3568 5.85675C11.6714 5.54208 12.0525 5.38475 12.5 5.38475C12.9475 5.38475 13.3286 5.54208 13.6432 5.85675C13.9579 6.17142 14.1152 6.5525 14.1152 7C14.1152 7.4475 13.9579 7.82858 13.6432 8.14325C13.3286 8.45792 12.9475 8.61525 12.5 8.61525C12.0525 8.61525 11.6714 8.45792 11.3568 8.14325C11.0421 7.82858 10.8848 7.4475 10.8848 7ZM12.5 23.3943L8.67325 19.548L10.0673 17.6923L8.654 15.7885L10 13.8365V11.8923C9.11283 11.4359 8.39108 10.7744 7.83475 9.90775C7.27825 9.04108 7 8.07183 7 7C7 5.47433 7.53525 4.17625 8.60575 3.10575C9.67625 2.03525 10.9743 1.5 12.5 1.5C14.0257 1.5 15.3238 2.03525 16.3943 3.10575C17.4648 4.17625 18 5.47433 18 7C18 8.07183 17.7234 9.04108 17.1702 9.90775C16.6169 10.7744 15.8935 11.4359 15 11.8923V20.8943L12.5 23.3943ZM8.5 7C8.5 7.959 8.78975 8.78942 9.36925 9.49125C9.94875 10.1933 10.659 10.6513 11.5 10.8655V14.3077L10.5037 15.748L11.9328 17.702L10.6345 19.4L12.5 21.2655L13.5 20.2655V10.8655C14.341 10.6513 15.0512 10.1933 15.6307 9.49125C16.2102 8.78942 16.5 7.959 16.5 7C16.5 5.9 16.1083 4.95833 15.325 4.175C14.5417 3.39167 13.6 3 12.5 3C11.4 3 10.4583 3.39167 9.675 4.175C8.89167 4.95833 8.5 5.9 8.5 7Z" fill="currentColor"/>
      <path d="M19 22.6152V20.5H16.8845V19H19V16.8845H20.5V19H22.6152V20.5H20.5V22.6152H19Z" fill="currentColor"/>`,
    },
  },
};
