/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';
import { ClipboardEvent, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import Cell from './CodeCell';

const InputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& input:nth-of-type(3)': {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
  },
}));

interface IProps {
  onComplete?: (newValue: string) => void;
  onRemoving?: () => void;
  onSubmit?: () => void;
  error?: FieldError;
}

const CodeInput = ({
  onComplete = () => {},
  onRemoving = () => {},
  onSubmit,
  error,
}: IProps) => {
  const [code, setCode] = useState<(string | null)[]>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const handleChange = (value: string | null, index: number) => {
    if (index < 0) {
      return;
    }

    if (
      value === null &&
      (code[index] === '' || code[index] === null) &&
      index > 0
    ) {
      handleChange(value, index - 1);
      return;
    }

    setCode((code) => {
      const copy = [...code];
      copy[index] = value || null;
      value === null && document.getElementById(`code-input-${index}`)?.focus();
      return copy;
    });
  };

  const handlePaste = (
    e: ClipboardEvent<HTMLInputElement>,
    startFrom: number,
  ) => {
    e.preventDefault();
    let data = e.clipboardData?.getData('text') || '';

    if (data) {
      data = data.replace(/\D/g, '');
      data.split('').forEach((el, index) => {
        if (index + startFrom > 5) {
          return;
        }
        handleChange(el, index + startFrom);
      });
      data.length > 0 && document.getElementById('code-input-5')?.focus();
    }
  };

  useEffect(() => {
    if (code.indexOf(null) === -1) {
      onComplete(code.join(''));
      return;
    }
    if (code.filter((e) => e === null).length === 6) {
      document.getElementById('code-input-0')?.focus();
    }

    onRemoving();
  }, [code]);

  return (
    <Box pb={4} mx="auto">
      <InputWrapper>
        {code.map((el, index) => (
          <Cell
            key={index}
            externalValue={el}
            isError={!!error}
            onPaste={handlePaste}
            onChange={(value) => handleChange(value, index)}
            onSubmit={onSubmit}
            index={index}
          />
        ))}
      </InputWrapper>
      <Box display="flex" justifyContent="center">
        {!!error && (
          <FormHelperText
            sx={({ palette, spacing }) => ({
              pt: 1,
              maxWidth: { xxs: spacing(30), sm: spacing(34), md: spacing(40) },
              color:
                error.type === 'validate'
                  ? palette.primary.main
                  : palette.red.main,
            })}
          >
            {error.message}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default CodeInput;
