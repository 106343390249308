import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 20, height: 20 };

export const done: IconifyJSON = {
  prefix: 'done',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M7.95874 14.7083L3.52124 10.2708L4.39624 9.37499L7.95874 12.9375L15.6046 5.29166L16.4796 6.18749L7.95874 14.7083Z" fill="currentColor"/>',
    },
  },
};
