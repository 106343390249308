import logo from 'assets/boundary-care.svg';
import { Box, BoxProps } from '@mui/material';
import pxToEm from 'utils/px-to-em';
import styled from '@emotion/styled';

const Img = styled.img`
  width: ${pxToEm(50)};
  height: ${pxToEm(50)};

  animation: pulse 2s linear infinite;

  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.1, 1.1);
    }

    100% {
      transform: scale(1, 1);
    }
  }
`;

type Props = BoxProps & {
  isLoading?: boolean;
};

const VLoader: React.FC<Props> = ({ isLoading, ...rest }) =>
  isLoading ? (
    <Box
      display="flex"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor="white"
      position="absolute"
      zIndex={1}
      {...rest}
    >
      <Img src={logo} alt="" />
    </Box>
  ) : null;

export default VLoader;
