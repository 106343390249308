import { Box, IconButton, Paper, styled } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-pro';
import { Table } from 'components/Table';
import { AlerterEvent } from 'types/alerters';
import { ALERT_HISTORY_COLUMNS } from 'utils/alert-history-columns';
import AlerterEventDetailPanel from './AlerterEventDetailPanel';
import { Icon } from '@iconify/react';
import {
  collapseContent,
  expandContent,
  expandLess,
  expandMore,
} from 'assets/iconify';
import { useAlerterHistory } from '../../hooks/use-alerter-history';
import ExportCsvButton from './ExportCsvButton';

const StyledTable = styled(Table)(({ theme }) => ({
  maxHeight: '100%',
  [`.${gridClasses.cell}:first-of-type`]: {
    padding: theme.spacing(1.5),
    minWidth: theme.spacing(8),
  },
  [`.${gridClasses.detailPanelToggleCell}`]: {
    padding: theme.spacing(0.5),
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  minWidth: theme.spacing(3),
  minHeight: theme.spacing(3),
  color: theme.palette.primary.main,
}));

const ExpandIcon = () => <StyledIcon icon={expandMore.icons.normal} />;
const CollapseIcon = () => <StyledIcon icon={expandLess.icons.normal} />;

const AlertHistoryTable = ({
  alertId,
  isViewCollapsed,
  toggleCollapse,
}: {
  alertId?: string;
  toggleCollapse: () => void;
  isViewCollapsed: boolean;
}) => {
  const {
    rows,
    rowCount,
    isLoading,
    handlePaginationModelChange,
    page,
    limit,
  } = useAlerterHistory(alertId);

  return (
    <Paper
      sx={{
        mt: 3,
        height: '100%',
        flexGrow: 1,
        flexBasis: 0,
        position: 'relative',
      }}
    >
      <StyledTable
        loading={isLoading}
        columns={ALERT_HISTORY_COLUMNS}
        rows={rows ?? []}
        rowCount={rowCount}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={({ row }: { row: AlerterEvent }) => (
          <AlerterEventDetailPanel row={row} />
        )}
        slots={{
          detailPanelExpandIcon: ExpandIcon,
          detailPanelCollapseIcon: CollapseIcon,
        }}
        paginationMode="server"
        paginationModel={{
          pageSize: limit,
          page,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight={false}
        rowHeight={64}
      />
      <Box
        sx={{
          position: 'absolute',
          left: {
            xxs: 8,
            newMd: 16,
          },
          bottom: 6,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton color="primary" onClick={toggleCollapse}>
          <Icon
            icon={
              isViewCollapsed
                ? collapseContent.icons.normal
                : expandContent.icons.normal
            }
          />
        </IconButton>
        <ExportCsvButton alertId={alertId} />
      </Box>
    </Paper>
  );
};

export default AlertHistoryTable;
