import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const list: IconifyJSON = {
  prefix: 'list',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M7.32696 9.0577V7.55773H20.5V9.0577H7.32696ZM7.32696 12.75V11.25H20.5V12.75H7.32696ZM7.32696 16.4423V14.9423H20.5V16.4423H7.32696ZM4.30773 9.1154C4.07888 9.1154 3.88706 9.03707 3.73226 8.88042C3.57744 8.72377 3.50003 8.52967 3.50003 8.2981C3.50003 8.07198 3.57744 7.88244 3.73226 7.72948C3.88706 7.57651 4.07888 7.50002 4.30773 7.50002C4.53656 7.50002 4.72838 7.57651 4.88318 7.72948C5.038 7.88244 5.11541 8.07198 5.11541 8.2981C5.11541 8.52967 5.038 8.72377 4.88318 8.88042C4.72838 9.03707 4.53656 9.1154 4.30773 9.1154ZM4.30773 12.798C4.07888 12.798 3.88706 12.7216 3.73226 12.5686C3.57744 12.4157 3.50003 12.2261 3.50003 12C3.50003 11.7615 3.57744 11.5657 3.73226 11.4125C3.88706 11.2593 4.07888 11.1827 4.30773 11.1827C4.53656 11.1827 4.72838 11.2593 4.88318 11.4125C5.038 11.5657 5.11541 11.7615 5.11541 12C5.11541 12.2261 5.038 12.4157 4.88318 12.5686C4.72838 12.7216 4.53656 12.798 4.30773 12.798ZM4.30773 16.5C4.07888 16.5 3.88706 16.4216 3.73226 16.265C3.57744 16.1084 3.50003 15.9142 3.50003 15.6827C3.50003 15.4566 3.57744 15.267 3.73226 15.1141C3.88706 14.9611 4.07888 14.8846 4.30773 14.8846C4.53656 14.8846 4.72838 14.9611 4.88318 15.1141C5.038 15.267 5.11541 15.4566 5.11541 15.6827C5.11541 15.9142 5.038 16.1084 4.88318 16.265C4.72838 16.4216 4.53656 16.5 4.30773 16.5Z" fill="currentColor"/>',
    },
  },
};
