/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { TriggerGroup, getTriggerType } from 'utils/triggers';

export default (id?: number) => {
  return createDraftSafeSelector(
    (state: RootState) => state.alerter.trigger.rules,
    (rules) => {
      const rule = rules.find((rule) => rule.id === id);
      if (!rule) {
        const metricType = getTriggerType(TriggerGroup.DEVICE)[0];
        return {
          metricType: metricType.value,
          comparator: metricType.defaultRule,
          value: 0,
        };
      }
      return rule;
    },
  );
};
