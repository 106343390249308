import { Box, Switch, Typography, styled } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { useEffect, useState } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.up('newMd')]: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  toggleValue: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  setOnReset?: (value: () => void) => void;
}

const MuteToggle = ({ toggleValue, disabled, onChange, setOnReset }: Props) => {
  const [checked, setChecked] = useState(toggleValue);

  const resetMuteSwitch = () => {
    setChecked(toggleValue);
  };

  const handleChange = (value: boolean) => {
    onChange?.(value);
    setChecked(value);
  };

  useEffect(() => {
    setOnReset?.(resetMuteSwitch);
  }, [toggleValue]);

  return (
    <Wrapper sx={{ justifyContent: 'space-between', pb: 0 }}>
      <Box display="flex" gap={1} alignItems="center">
        <Typography
          variant="body1"
          component="label"
          htmlFor="muteswitch"
          py={1}
          color="primary"
        >
          Mute all notifications
        </Typography>
        <HelpIcon
          tooltipPlacement="top-start"
          message="This feature allows you to mute all incoming notifications. When enabled, you won't receive any alerts, messages, or updates."
        />
      </Box>
      <Switch
        onChange={(e, v) => handleChange(v)}
        disabled={disabled}
        checked={checked}
        id="muteswitch"
      />
    </Wrapper>
  );
};

export default MuteToggle;
