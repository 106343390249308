import { styled } from '@mui/material';

export const GridWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}));

export { default as PageContent } from './PageContent';
