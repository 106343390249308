import { Box, Paper, styled } from '@mui/material';
import { FiltersToolbar } from 'components/_extra';
import {
  DataTable,
  DatePicker,
  ExpandButton,
  ExportCsvButton,
} from './components';
import { SubscriptionRequired } from 'pages/Dashboard/components';
import { useParams } from 'react-router-dom';
import useDashboard from 'pages/Dashboard/hooks/use-dashboard';
import { useState } from 'react';

const ButtonsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(2),
  bottom: theme.spacing(0.75),
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('newMd')]: {
    left: theme.spacing(1),
  },
}));

const DashboardData = () => {
  const profileId = Number(useParams().profileId);
  const { hasActiveSubscription } = useDashboard(profileId);
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsTableExpanded((value) => !value);
  };

  if (!hasActiveSubscription) {
    return <SubscriptionRequired />;
  }

  return (
    <Box
      maxHeight={({ spacing }) => ({
        xxs: `calc(100dvh - ${spacing(12)})`,
        newMd: `calc(100dvh - ${spacing(14)})`,
      })}
      display="grid"
      gridTemplateRows={isTableExpanded ? '0 0 1fr' : 'auto auto 1fr'}
    >
      <Paper elevation={2}>
        <FiltersToolbar
          withoutChips
          routerTabState="apple-watch"
          withoutSearch
        />
      </Paper>
      <DatePicker />
      <Paper
        elevation={2}
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        <DataTable />
        <ButtonsWrapper>
          <ExpandButton
            isExpanded={isTableExpanded}
            onClick={handleExpandToggle}
          />
          <ExportCsvButton />
        </ButtonsWrapper>
      </Paper>
    </Box>
  );
};

export default DashboardData;
