import { Icon } from '@iconify/react';
import { Box, Button, IconButton } from '@mui/material';
import { addSquare, edit } from 'assets/iconify';
import { CustomPhoneInput } from 'components/CustomPhoneInput';
import { useState } from 'react';
import NumberSetupModal from './NumberSetupModal';
import DeleteButton from './DeleteButton';

const PhoneNumber = ({
  phoneNumber = '',
  credentialId,
}: {
  phoneNumber?: string;
  credentialId?: number;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Box marginLeft={2} marginTop={2.5} width="100%">
        {phoneNumber && credentialId ? (
          <Box position="relative">
            <CustomPhoneInput
              readOnly
              value={phoneNumber}
              label="Phone number"
            />
            <Box display="flex" position="absolute" right={8} bottom={8}>
              <IconButton
                onClick={handleModalOpen}
                color="secondary"
                sx={{ p: 0.5 }}
              >
                <Icon icon={edit.icons.normal} />
              </IconButton>
              <DeleteButton id={credentialId} />
            </Box>
          </Box>
        ) : (
          <Button
            variant="text"
            color="secondary"
            size="large"
            sx={{ px: 1 }}
            onClick={handleModalOpen}
            startIcon={<Icon icon={addSquare.icons.normal} />}
          >
            Add phone number
          </Button>
        )}
      </Box>
      <NumberSetupModal
        phoneNumber={phoneNumber}
        existingCredentialId={credentialId}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default PhoneNumber;
