import { styled } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import pxToEm from 'utils/px-to-em';

const formatTime = (value: number | undefined) => {
  if (typeof value !== 'undefined') {
    return value.toString().padStart(2, '0');
  }
  return '';
};

const getHourDefaultValue = (
  defaultValue: number | undefined,
  value: number | undefined,
) => {
  if (value) {
    return formatTime(Math.floor(value / 3600));
  }

  if (defaultValue) {
    return formatTime(Math.floor(defaultValue / 3600));
  }

  return '';
};

const getMinuteDefaultValue = (
  defaultValue: number | undefined,
  value: number | undefined,
) => {
  if (value) {
    return formatTime((value % 3600) / 60);
  }

  if (defaultValue) {
    return formatTime((defaultValue % 3600) / 60);
  }

  return '';
};

interface TimeInputProps {
  readOnly?: boolean;
  value?: number;
  // The `defaultValue` prop is used to set the initial value of the input. It should be a numeric value in seconds.
  defaultValue?: number;
  onChange?: (seconds: number) => void;
  // The `max` prop is used to set the maximum value of the input. They should be numeric values in seconds.
  max?: number;
  onFocus?: () => void;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.blue.light}`,
  width: theme.spacing(11),
  overflow: 'hidden',
  '&.readOnly': {
    input: {
      color: theme.palette.text.secondary,
      pointerEvents: 'none',
    },
  },
}));

const UnstyledInput = styled('input')(({ theme }) => ({
  border: 'none',
  outline: 'none',
  fontSize: pxToEm(16),
  overflow: 'visible',
  '&::placeholder': {
    color: theme.palette.disabled.main,
  },
  '&.readOnly': {
    color: theme.palette.text.secondary,
    pointerEvents: 'none',
  },
}));

const TimeInput = ({
  onChange,
  defaultValue,
  value,
  readOnly,
  max,
  onFocus,
}: TimeInputProps) => {
  const minuteInputRef = useRef<HTMLInputElement>(null);
  const hourInputRef = useRef<HTMLInputElement>(null);

  const [hours, setHours] = useState(getHourDefaultValue(defaultValue, value));
  const [minutes, setMinutes] = useState(
    getMinuteDefaultValue(defaultValue, value),
  );

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (!/^\d*$/.test(newValue)) {
      return;
    }

    if (max && Number(newValue) * 3600 + parseInt(minutes) * 60 > max) {
      newValue = Math.floor((max - parseInt(minutes) * 60) / 3600).toString();
    } else {
      if (newValue.length === 1 && Number(newValue) > 2) {
        minuteInputRef.current?.focus();
        if (newValue.length === 1) {
          newValue = `0${newValue}`;
        }
      } else {
        if (Number(newValue) > 23) {
          newValue = '23';
          minuteInputRef.current?.focus();
        } else {
          if (newValue.length > 2) {
            newValue = newValue.slice(newValue.length - 2);
          }
        }
      }
      setHours(newValue);
      const hours = parseInt(newValue);
      if (!isNaN(hours)) {
        onChange?.(hours * 3600 + parseInt(minutes) * 60);
      }
    }
  };

  const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (!/^\d*$/.test(newValue)) {
      return;
    }

    if (newValue.length > 2) {
      newValue = newValue.slice(newValue.length - 2);
    }

    if (Number(newValue) > 59) {
      newValue = '59';
    }

    if (max && parseInt(hours) * 3600 + Number(newValue) * 60 > max) {
      newValue = Math.floor((max - parseInt(hours) * 3600) / 60).toString();
    }

    setMinutes(newValue);
    const minutes = parseInt(newValue);
    if (!isNaN(minutes)) {
      onChange?.(parseInt(hours) * 3600 + minutes * 60);
    }
  };

  const handleHoursBlur = () => {
    setHours(hours.padStart(2, '0'));
  };

  const handleMinutesBlur = () => {
    setMinutes(minutes.padStart(2, '0'));
  };

  useEffect(() => {
    if (value) {
      setHours(formatTime(Math.floor(value / 3600)));
      setMinutes(formatTime(Math.floor((value % 3600) / 60)));
    }
  }, [value]);

  return (
    <Root className={readOnly ? 'readOnly' : undefined} onFocus={onFocus}>
      <UnstyledInput
        value={hours}
        onChange={handleHoursChange}
        className={max && max < 3600 ? 'readOnly' : undefined}
        maxLength={2}
        placeholder="hh"
        onBlur={handleHoursBlur}
        ref={hourInputRef}
        sx={{ pr: 0.5, maxWidth: '2.5ch' }}
      />
      :
      <UnstyledInput
        value={minutes}
        onChange={handleMinutesChange}
        maxLength={2}
        placeholder="mm"
        onBlur={handleMinutesBlur}
        ref={minuteInputRef}
        sx={{ pl: 0.5, maxWidth: '3.5ch' }}
      />
    </Root>
  );
};

export default TimeInput;
