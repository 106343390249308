import { AlerterEvent, AlerterEventsWidgets } from 'types/alerters';

export const getUnacknowledgedEvents = (
  events: Record<AlerterEventsWidgets, AlerterEvent[]>,
): AlerterEvent[] => {
  const unacknowledgedEvents: AlerterEvent[] = Object.keys(events).reduce(
    (acc, event) => {
      const unacknowledged = events[event as AlerterEventsWidgets]
        .filter((e) => e.requiresAcknowledgement && !e.acknowledgedAt)
        .map((e) => ({ ...e, widget: event as AlerterEventsWidgets }));
      return [...acc, ...unacknowledged];
    },
    [] as AlerterEvent[],
  );

  return unacknowledgedEvents;
};
