import {
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { SearchDevicesParams } from 'models/admin.model';
import { useRef, useState } from 'react';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
  },
}));

type SearchType = keyof SearchDevicesParams;
export const searchTypes: SearchType[] = [
  'device_id',
  'account_id',
  'imei',
  'serial_number',
  'linking_token',
  'iccid',
];

type Props = {
  onSearch: (params: SearchDevicesParams) => void;
};

const DevicesSearchBar = ({ onSearch }: Props) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchType, setSearchType] = useState<SearchType>('device_id');

  const handleSearchTypeValueChange = (e: SelectChangeEvent<SearchType>) => {
    setSearchType(e.target.value as SearchType);
  };

  const handleSearch = () => {
    const searchValue = searchInputRef.current?.value;
    if (searchValue) {
      if (['account_id', 'device_id'].includes(searchType)) {
        onSearch({ [searchType]: Number(searchValue) });
        return;
      }
      onSearch({ [searchType]: searchValue });
    }
  };

  return (
    <Root>
      <OutlinedInput
        sx={{ flexGrow: 1 }}
        size="small"
        inputRef={searchInputRef}
        placeholder={`Search devices based on ${searchType.replace('_', ' ')}`}
      />
      <Select
        value={searchType}
        onChange={handleSearchTypeValueChange}
        sx={{
          width: ({ spacing }) => spacing(20),
          height: ({ spacing }) => spacing(5),
        }}
      >
        {searchTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {type.replace('_', ' ')}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={handleSearch} variant="contained">
        Search
      </Button>
    </Root>
  );
};

export default DevicesSearchBar;
