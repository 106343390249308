import { Chip, ChipProps } from '@mui/material';

interface Props extends Omit<ChipProps, 'onClick'> {
  onClick?: (metricRule: number) => void;
  value: number;
  selected?: boolean;
}

const MetricRuleChip = ({ onClick, value, selected, ...props }: Props) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <Chip
      onClick={handleClick}
      color={selected ? 'primary' : 'default'}
      variant="filled"
      clickable
      size="small"
      {...props}
    />
  );
};

export default MetricRuleChip;
