import { Dispatch, SetStateAction, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import { useRemoveEmergencyContactMutation } from 'store/api/account.api';
import { profilesApi } from 'store/api/profiles.api';

export const useRemoveEmergencyContact = (
  setCurrentId: Dispatch<SetStateAction<number>>,
  profileId: number,
) => {
  const dispatch = useAppDispatch();
  const [removeContact, { data, isSuccess, isError, isLoading }] =
    useRemoveEmergencyContactMutation();

  useEffect(() => {
    if (data?.result.subscriber_is_emergency_contact === false && isSuccess) {
      toast('Emergency contact removed successfully!', { type: 'success' });
      setCurrentId(0);
      dispatch(
        profilesApi.util.updateQueryData(
          'getLinkedCaregivers',
          { profileId },
          (response) => {
            response.results.forEach((caregiver) => {
              caregiver.subscriber_is_emergency_contact = false;
            });
          },
        ),
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast('An error occured. Please try again.', { type: 'error' });
    }
  }, [isError]);

  return [removeContact, isLoading] as const;
};
