import RetrieveCode from './RetrieveCode';
import SetupMessage from './SetupMessage';
import ConfigurationLoader from './ConfigurationLoader';
import { styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const Bold = styled('span')`
  font-weight: 600;
`;

export const getSteps = (
  handleNextStep: () => void,
  sentAt: string,
  setSentAt: Dispatch<SetStateAction<string>>,
) => {
  return [
    {
      title: 'Add a device',
      subTitle: (
        <>
          Open <Bold>BoundaryCare</Bold> on the Apple Watch to view the Apple
          Watch’s linking code.
        </>
      ),
      content: (
        <RetrieveCode setSentAt={setSentAt} handleNextStep={handleNextStep} />
      ),
    },
    {
      title: 'Set up your device',
      subTitle: (
        <>
          Continue setup on the Apple Watch. For best results,{' '}
          <Bold>allow all system permissions requests</Bold> from BoundaryCare
          on your device.
          <br />
          <br />
          You can always change and customize your selections later.
        </>
      ),
      content: <SetupMessage handleNextStep={handleNextStep} />,
    },
    {
      title: 'Configuring...',
      subTitle:
        'This could take a while. Please wait until your device will be fully configured.',
      content: (
        <ConfigurationLoader sentAt={sentAt} handleNextStep={handleNextStep} />
      ),
    },
  ];
};
