import { DateRange } from 'types/date-time';
import { FocusedInputIndex } from '../components/CalendarModal';

export const useModalInputs = (
  value: DateRange,
  focusedInput: FocusedInputIndex,
) => {
  const getProps = (nr: FocusedInputIndex) => {
    return {
      value: value[nr]?.format('MM/DD/YYYY') ?? '',
      focused: focusedInput === nr,
    };
  };

  return {
    startProps: getProps(FocusedInputIndex.START),
    endProps: getProps(FocusedInputIndex.END),
  };
};
