import { IAccountRelationships } from 'models/profiles.model';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useLazySearchByEmailQuery,
  useLazySearchByImeiQuery,
  useLazySearchByNameQuery,
  useLazySearchByPhoneQuery,
  useLazySearchByProfileIdQuery,
  useLazySearchByTpUpiQuery,
  useLazySearchByUpiQuery,
} from 'store/api/admin-search.api';
import { useLazyGetLinkedProfilesQuery } from 'store/api/profiles.api';
import { LookupTableRow, SearchByOption } from './types';

export const useSearch = () => {
  const [rows, setRows] = useState<LookupTableRow[]>([]);

  const [fetchLovedOnes, { isLoading: isLinkedProfilesLoading }] =
    useLazyGetLinkedProfilesQuery();
  const [searchByEmail, { isLoading: isEmailLoading }] =
    useLazySearchByEmailQuery();
  const [searchByImei, { isLoading: isImeiLoading }] =
    useLazySearchByImeiQuery();
  const [searchByName, { isLoading: isNameLoading }] =
    useLazySearchByNameQuery();
  const [searchByPhone, { isLoading: isPhoneLoading }] =
    useLazySearchByPhoneQuery();
  const [searchByProfileId, { isLoading: isProfileIdLoading }] =
    useLazySearchByProfileIdQuery();
  const [searchByTpUpi, { isLoading: isTpUpiLoading }] =
    useLazySearchByTpUpiQuery();
  const [searchByUpi, { isLoading: isUpiLoading }] = useLazySearchByUpiQuery();

  const fetchLovedOnesEntries = async (
    caregiverId: number,
  ): Promise<LookupTableRow[]> =>
    fetchLovedOnes({ caregiverId })
      .unwrap()
      .then(({ accounts }) =>
        Object.values(accounts).map((account) => ({
          id: account.id,
          isLovedOne: account.is_loved_one,
          name: account.first_name,
        })),
      );

  const search = async (
    searchString: string,
    searchByOption: SearchByOption,
  ): Promise<void> => {
    try {
      switch (searchByOption) {
        case 'email': {
          await searchByEmail({ email: searchString })
            .unwrap()
            .then(async ({ results }) => {
              if (!results.length) {
                setRows([]);
                return;
              }

              const finalArr: LookupTableRow[] = [];

              await Promise.all(
                results.map(async (item) => {
                  const allLovedOnes = await fetchLovedOnesEntries(
                    item.account_id,
                  );

                  finalArr.push(
                    {
                      id: item.account_id,
                      email: item.identifier,
                      isLovedOne: false,
                    },
                    ...allLovedOnes,
                  );
                }),
              );

              setRows(finalArr);
            });

          break;
        }

        case 'imei': {
          await searchByImei({ imei: searchString })
            .unwrap()
            .then(async (data) => {
              const finalArr: LookupTableRow[] = await Promise.all(
                data.results.map(async (item) =>
                  searchByProfileId({ profileId: item.account_id })
                    .unwrap()
                    .then(({ result }) => ({
                      id: result.id,
                      isLovedOne: result.is_loved_one,
                      name: result.first_name,
                    })),
                ),
              );

              setRows(finalArr);
            });

          break;
        }

        case 'name': {
          await searchByName({ name: searchString })
            .unwrap()
            .then(async (data) => {
              setRows([
                ...data.results.patient_profiles.map((item) => ({
                  id: item.id,
                  isLovedOne: item.is_loved_one,
                  name: item.first_name,
                })),
                ...data.results.users.map((item) => ({
                  id: item.id,
                  isLovedOne: item.is_loved_one,
                  name: item.first_name,
                })),
              ]);
            });

          break;
        }

        case 'phone': {
          await searchByPhone({ phone: searchString })
            .unwrap()
            .then(async ({ results }) => {
              if (!results.length) {
                setRows([]);
                return;
              }

              const finalArr: LookupTableRow[] = [];

              await Promise.all(
                results.map(async (item) => {
                  const allLovedOnes = await fetchLovedOnesEntries(
                    item.account_id,
                  );

                  finalArr.push(
                    {
                      id: item.account_id,
                      phoneNumber: item.identifier,
                      isLovedOne: false,
                    },
                    ...allLovedOnes,
                  );
                }),
              );

              setRows(finalArr);
            });

          break;
        }

        case 'profileId': {
          await searchByProfileId({ profileId: +searchString })
            .unwrap()
            .then(async ({ result }) => {
              let allProfiles: IAccountRelationships | undefined;

              if (!result.is_loved_one) {
                allProfiles = await fetchLovedOnes({
                  caregiverId: +searchString,
                }).unwrap();
              }

              const finalArr: LookupTableRow[] = [];

              finalArr.push({
                id: result.id,
                name: result.first_name,
                isLovedOne: result.is_loved_one,
              });

              if (allProfiles) {
                Object.values(allProfiles.accounts).forEach((item) => {
                  finalArr.push({
                    id: item.id,
                    isLovedOne: item.is_loved_one,
                    name: item.first_name,
                  });
                });
              }

              setRows(finalArr);
            });

          break;
        }

        case 'tpUpi': {
          await searchByTpUpi({ tpUpi: searchString })
            .unwrap()
            .then(async (data) => {
              setRows(
                data.results.patient_profiles.map((item) => ({
                  id: item.id,
                  isLovedOne: item.is_loved_one,
                  name: item.first_name,
                })),
              );
            });

          break;
        }

        case 'upi': {
          await searchByUpi({ upi: searchString })
            .unwrap()
            .then(async (data) => {
              setRows(
                data.results.patient_profiles.map((item) => ({
                  id: item.id,
                  isLovedOne: item.is_loved_one,
                  name: item.first_name,
                })),
              );
            });

          break;
        }
      }
    } catch (err) {
      if ((err as Record<string, unknown>).status === 404) {
        setRows([]);
        return;
      }

      toast('A server error has occurred.', { type: 'error' });
    }
  };

  const isLoading =
    isEmailLoading ||
    isImeiLoading ||
    isLinkedProfilesLoading ||
    isNameLoading ||
    isPhoneLoading ||
    isProfileIdLoading ||
    isTpUpiLoading ||
    isUpiLoading;

  return { isLoading, rows, search };
};
