import React, { MouseEventHandler } from 'react';

import VCheckBoxView from './VCheckBox.view';

interface Props {
  checkBoxContent?: string | JSX.Element;
  value?: boolean;
  onChangeValue?: MouseEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  className?: string;
}

const VCheckBox = (props: Props) => {
  return (
    <VCheckBoxView
      isChecked={props.value}
      handleCheckedClick={props.onChangeValue}
      checkBoxContent={props.checkBoxContent}
      style={props.style}
      className={props.className}
    />
  );
};

VCheckBox.displayName = 'VCheckBox';

export default VCheckBox;
