import { Icon } from '@iconify/react';
import { Typography, styled } from '@mui/material';
import { error } from 'assets/iconify';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: theme.palette.disabled.main,
  svg: {
    color: theme.palette.error.main,
  },
}));

const SupportLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  cursor: 'pointer',
}));

const ErrorElement = () => {
  return (
    <Wrapper>
      <Icon icon={error.icons.normal} width={124} height={124} />
      <Typography textAlign="center" mt={2} variant="h5">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        Oops! An unexpected error occurred.
      </Typography>
      <Typography
        pt={2}
        pb={8}
        fontSize={18}
        variant="body1"
        color="blue.main"
        textAlign="center"
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {(window as any).cordova ? (
          <>
            Please restart the <strong>BoundaryCare</strong> App.
          </>
        ) : (
          'Please reload this page in your web browser.'
        )}
        <br />
        If the problem persists,{' '}
        <SupportLink
          target="_blank"
          href="https://www.boundarycare.com/contact/"
        >
          contact our support team
        </SupportLink>
        .
      </Typography>
    </Wrapper>
  );
};

export default ErrorElement;
