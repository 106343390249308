import { useAppSelector } from 'store';
import { columns as ALL_COLUMNS } from 'utils/patiens-overview-columns';
import { visibleColumnsSelector } from 'store/selectors';
import { GridColDef } from '@mui/x-data-grid-pro';

const DEFAULT_REQUEST_FIELDS = ['settings_record', 'health_record'];

export const useColumns = () => {
  const visibleColumns = ['name', ...useAppSelector(visibleColumnsSelector)];

  const requestFields = [...DEFAULT_REQUEST_FIELDS];

  if (visibleColumns.includes('battery')) {
    requestFields.push('device_info_record');
  }

  if (visibleColumns.includes('device')) {
    requestFields.push('usage_record');
  }

  if (visibleColumns.includes('seizureDetected')) {
    requestFields.push('last_seizure_detection_event');
  }

  if (visibleColumns.includes('helpRequested')) {
    requestFields.push('last_help_requested_event');
  }

  const columns = ALL_COLUMNS.filter((column: GridColDef) =>
    visibleColumns.includes(column.field),
  );

  return {
    columns,
    visibleColumns,
    requestFields,
  };
};
