import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRetrieveMutation, useUpdateMutation } from 'store/api/devices.api';
import { useAppSelector } from 'store';
import { NewDeviceContext } from '../components/Devices/ConnectDeviceModal/ConnectDeviceModal';

interface FormData {
  linkingCode: string;
}

const addDeviceSchema = yup.object({
  linkingCode: yup
    .string()
    .required('Device code is required.')
    .matches(/^[0-9\s]+$/, 'Invitation code must contain only numbers.'),
});

export const useDeviceCodeInput = (
  handleNextStep: () => void,
  setSentAt: Dispatch<SetStateAction<string>>,
) => {
  const codeRef = useRef('');

  const { setIsError: displayErrorModal, setDeviceId } =
    useContext(NewDeviceContext);

  const { profileId } = useAppSelector((state) => state.dashboard);

  const [retrieveCode, { data, isLoading, isError, isSuccess, reset }] =
    useRetrieveMutation();
  const [assignDevice, { isSuccess: isAssigned, isError: isAssignError }] =
    useUpdateMutation();

  const { register, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(addDeviceSchema),
  });

  const [errors, setErrors] = useState<FieldErrorsImpl<FormData>>({});

  const onSubmit = ({ linkingCode }: FormData) => {
    const formattedCode = linkingCode.replaceAll(' ', '');

    if (formattedCode.length !== 8) {
      onError({
        linkingCode: {
          type: 'pattern',
          message: 'Invitation code must be 8 digits long.',
        },
      });
      return;
    }

    codeRef.current = formattedCode;
    retrieveCode({ code: formattedCode });
  };

  const onError: SubmitErrorHandler<FormData> = (errors) => {
    setErrors(errors);
  };

  useEffect(() => {
    if (isError) {
      onError({
        linkingCode: {
          type: 'request',
          message: 'Please check if you typed your code correctly.',
        },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && data) {
      assignDevice({
        deviceId: data.result.id,
        accountId: profileId,
      });

      handleNextStep();
      setSentAt(data.result.updated_at);
      setDeviceId(data.result.id);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isAssigned) {
      handleNextStep();
      reset();
    }
  }, [isAssigned]);

  useEffect(() => {
    if (isAssignError) {
      displayErrorModal(true);
    }
    displayErrorModal(false);
  }, [isAssignError]);

  return {
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit, onError),
    isLoading,
    id: data?.result.id,
  };
};
