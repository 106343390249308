export const UsageRecord = {
  DEVICE_WORN: 'usage_record.device_worn',
} as const;
export type UsageRecord = (typeof UsageRecord)[keyof typeof UsageRecord];

export const SettingsRecord = {
  SAFE_ZONE: 'settings_record.lo_in_zone',
  CONFIGURATION_OK: 'settings_record.configuration_ok',
  ACTIVE_DEVICE: 'settings_record.any_device_active',
} as const;
export type SettingsRecord =
  (typeof SettingsRecord)[keyof typeof SettingsRecord];

export const DeviceRecord = {
  DEVICE_LOCKED: 'device_info_record.device_locked',
  BATTERY_LEVEL: 'device_info_record.battery_level',
  BATTERY_STATE: 'device_info_record.battery_state',
} as const;
export type DeviceRecord = (typeof DeviceRecord)[keyof typeof DeviceRecord];

export const HealthRecord = {
  HEART_RATE: 'health_record.heart_rate',
  HIGH_HEART_RATE: 'health_record.apple_high_heart_rate_events',
  LOW_HEART_RATE: 'health_record.apple_low_heart_rate_events',
  IRREGULAR_HEART_RYTHM: 'health_record.apple_irregular_heart_rhythm_events',
  BLOOD_OXYGEN: 'health_record.blood_oxygen',
  FALL_DETECTION: 'health_record.fall_detection',
  DAILY_SLEEP: 'health_record.daily_sleep_time',
} as const;
export type HealthRecord = (typeof HealthRecord)[keyof typeof HealthRecord];

export const LocationRecord = {
  PRIMARY_LOCATION: 'location_record.primary_location',
} as const;
export type LocationRecord =
  (typeof LocationRecord)[keyof typeof LocationRecord];

export const DataTableColumn = {
  ...UsageRecord,
  ...DeviceRecord,
  ...SettingsRecord,
  ...DeviceRecord,
  ...HealthRecord,
  ...LocationRecord,
} as const;
export type DataTableColumn =
  (typeof DataTableColumn)[keyof typeof DataTableColumn];
