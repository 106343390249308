import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { FocusEventHandler, HTMLProps, useState } from 'react';

const IntervalSelector = ({
  defaultValue,
  inputProps = {},
  error,
  readOnly,
}: {
  defaultValue?: number;
  inputProps?: HTMLProps<HTMLInputElement>;
  error?: string;
  readOnly?: boolean;
}) => {
  const [value, setValue] = useState<'s' | 'm'>('m');

  const handleChange = (e: SelectChangeEvent<'s' | 'm'>) => {
    setValue(e.target.value as 's' | 'm');
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length === 0) {
      e.target.value = '2';
    }
    if (parseInt(e.target.value) > 15) {
      e.target.value = '15';
      return;
    }
    if (parseInt(e.target.value) < 1) {
      e.target.value = '1';
      return;
    }
  };

  return (
    <Box pb={2}>
      <Typography color="primary" pb={1.5}>
        Time interval
      </Typography>
      <Box display="flex" gap={1} alignItems="center">
        <TextField
          size="medium"
          defaultValue={defaultValue ?? 10}
          type="number"
          disabled={readOnly}
          inputProps={{ min: 1, max: 15, ...inputProps }}
          onBlur={handleBlur}
          sx={({ spacing }) => ({
            maxHeight: spacing(5),
            maxWidth: spacing(8),
            input: {
              padding: 0,
            },
          })}
          // helperText={error}
          error={!!error}
        />
        <Select readOnly size="medium" value={value} onChange={handleChange}>
          <MenuItem value="s">sec</MenuItem>
          <MenuItem value="m">min</MenuItem>
        </Select>
        <HelpIcon
          tooltipPlacement="top-end"
          tooltipSx={{ pb: 1 }}
          message={
            <>
              Amount of time between <br /> attempts.
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default IntervalSelector;
