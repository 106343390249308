import { useEffect, useRef, useState } from 'react';
import { useAddRuleToAlerterTriggerMutation } from 'store/api/alerters.api';
import { getComparatorWithValue } from 'utils/triggers';
import { toast } from 'react-toastify';
import { useConfirmation } from 'hooks/use-confirmation';
import { FieldsData } from 'hooks/use-metric-rule';
import { useAppDispatch } from 'store';
import { updateMetricRuleList } from 'store/reducers/reminder/reminder.slice';
import { getRulesFromRawAlerterTrigger } from 'utils/data-transformation';
import { setEmptyZonesFlag } from 'store/reducers/alerter/alerter.slice';

export const useAddRuleToTrigger = (
  triggerId: number,
  isReminder?: boolean,
) => {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const isModifiedRef = useRef(false);

  const handleClick = () => {
    isModifiedRef.current = false;
    setIsOpen(true);
  };
  const handleClose = () => {
    if (isModifiedRef.current) {
      confirm(
        {
          title: 'Are you sure you want to cancel adding a condition?',
          message: 'If you cancel now, you will lose all of your progress.',
          variant: 'danger',
          rejectButtonLabel: 'Go back',
        },
        () => setIsOpen(false),
      );
      return;
    }

    setIsOpen(false);
  };
  const handleFormChange = () => {
    isModifiedRef.current = true;
  };

  const functionRef = useRef<{ getFieldsData: () => FieldsData }>(null);
  const [addRule, { isError, isSuccess, isLoading, originalArgs, data }] =
    useAddRuleToAlerterTriggerMutation();

  const handleSave = () => {
    const fieldsData = functionRef.current?.getFieldsData();

    if (fieldsData?.zones && fieldsData.zones.length === 0) {
      dispatch(setEmptyZonesFlag());
      return;
    }

    if (fieldsData) {
      addRule({
        id: triggerId,
        is_reminder: isReminder,
        metric_rule: {
          ...getComparatorWithValue({
            comparator: fieldsData.rule,
            value: fieldsData.value,
            zones: fieldsData.zones,
          }),
          metric_type: fieldsData.type.value,
          enabled: true,
        },
      });
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (!isLoading && isError) {
      toast('An error occured...', { type: 'error' });
    }

    if (!isLoading && isSuccess) {
      toast('The condition was added successfully', { type: 'success' });
      if (originalArgs?.is_reminder === true && data) {
        dispatch(
          updateMetricRuleList(getRulesFromRawAlerterTrigger(data.result)),
        );
      }
    }
  }, [isLoading]);

  return {
    handleSave,
    functionRef,
    isOpen,
    handleClick,
    handleClose,
    handleFormChange,
  };
};
