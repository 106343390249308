import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const help: IconifyJSON = {
  prefix: 'help',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M11.989 17.6153C12.2745 17.6153 12.5157 17.5168 12.7125 17.3196C12.9093 17.1225 13.0077 16.8811 13.0077 16.5956C13.0077 16.31 12.9091 16.0689 12.7119 15.8721C12.5148 15.6753 12.2734 15.5769 11.9879 15.5769C11.7024 15.5769 11.4612 15.6755 11.2644 15.8726C11.0676 16.0698 10.9692 16.3111 10.9692 16.5967C10.9692 16.8822 11.0678 17.1234 11.265 17.3202C11.4621 17.5169 11.7034 17.6153 11.989 17.6153ZM11.2808 14.0346H12.6884C12.7012 13.5423 12.7734 13.149 12.9048 12.8548C13.0362 12.5606 13.3551 12.1705 13.8615 11.6846C14.3012 11.2449 14.6381 10.8388 14.8721 10.4663C15.106 10.0939 15.223 9.65407 15.223 9.14685C15.223 8.28613 14.9137 7.61378 14.2952 7.1298C13.6766 6.64583 12.9449 6.40385 12.1 6.40385C11.2654 6.40385 10.5747 6.62661 10.0279 7.07212C9.48109 7.51763 9.09103 8.04229 8.8577 8.64613L10.1423 9.1615C10.2641 8.82945 10.4724 8.50606 10.7673 8.19133C11.0622 7.87658 11.5 7.7192 12.0808 7.7192C12.6718 7.7192 13.1086 7.88107 13.3913 8.2048C13.674 8.52852 13.8154 8.88461 13.8154 9.27308C13.8154 9.61281 13.7186 9.9237 13.525 10.2057C13.3314 10.4878 13.0846 10.7602 12.7846 11.0231C12.1282 11.6154 11.7135 12.0878 11.5404 12.4404C11.3673 12.7929 11.2808 13.3243 11.2808 14.0346ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5Z" fill="currentColor"/>',
    },
    outlined: {
      ...defaultSizes,
      body: '<path d="M11.25 16.75H12.75V11H11.25V16.75ZM12 9.2885C12.2288 9.2885 12.4207 9.21108 12.5755 9.05625C12.7303 8.90142 12.8077 8.70958 12.8077 8.48075C12.8077 8.25192 12.7303 8.06008 12.5755 7.90525C12.4207 7.75058 12.2288 7.67325 12 7.67325C11.7712 7.67325 11.5793 7.75058 11.4245 7.90525C11.2697 8.06008 11.1923 8.25192 11.1923 8.48075C11.1923 8.70958 11.2697 8.90142 11.4245 9.05625C11.5793 9.21108 11.7712 9.2885 12 9.2885ZM12.0017 21.5C10.6877 21.5 9.45267 21.2507 8.2965 20.752C7.14033 20.2533 6.13467 19.5766 5.2795 18.7218C4.42433 17.8669 3.74725 16.8617 3.24825 15.706C2.74942 14.5503 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45267 3.248 8.2965C3.74667 7.14033 4.42342 6.13467 5.27825 5.2795C6.13308 4.42433 7.13833 3.74725 8.294 3.24825C9.44967 2.74942 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7035 3.248C16.8597 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.13308 20.2528 7.13833 20.7518 8.294C21.2506 9.44967 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2507 14.5473 20.752 15.7035C20.2533 16.8597 19.5766 17.8653 18.7218 18.7205C17.8669 19.5757 16.8617 20.2528 15.706 20.7518C14.5503 21.2506 13.3156 21.5 12.0017 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="currentColor"/>',
    },
  },
};
