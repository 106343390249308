import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { bcApi } from './api/_api';

import dashboard from './reducers/dashboard/dashboard.slice';
import filters from './reducers/filters/filters.slice';
import mfa from './reducers/mfa/mfa.slice';
import user, { logout } from './reducers/user/user.slice';
import alerter from './reducers/alerter/alerter.slice';
import reminder from './reducers/reminder/reminder.slice';

const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(logout());
    }

    return next(action);
  };

const getMiddlewares = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  const middlewares = getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([unauthenticatedMiddleware, bcApi.middleware]);

  return middlewares;
};

const rootReducer = combineReducers({
  [bcApi.reducerPath]: bcApi.reducer,
  alerter,
  dashboard,
  // no breadcrumbs on the app right now, but will be needed if they eventually come back
  // navigation,
  filters,
  mfa,
  reminder,
  user,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getMiddlewares,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
