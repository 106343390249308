import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const idCard: IconifyJSON = {
  prefix: 'id-card',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M14.077 12.7308H18.6152V11.2308H14.077V12.7308ZM14.077 9.84625H18.6152V8.34625H14.077V9.84625ZM5.38475 15.6538H12.6923V15.2963C12.6923 14.6296 12.3624 14.1058 11.7028 13.725C11.0433 13.3442 10.1552 13.1538 9.0385 13.1538C7.92183 13.1538 7.03367 13.3442 6.374 13.725C5.7145 14.1058 5.38475 14.6296 5.38475 15.2963V15.6538ZM9.0385 11.8463C9.52433 11.8463 9.93742 11.676 10.2777 11.3355C10.6182 10.9952 10.7885 10.5821 10.7885 10.0962C10.7885 9.61025 10.6182 9.19708 10.2777 8.85675C9.93742 8.51642 9.52433 8.34625 9.0385 8.34625C8.55267 8.34625 8.1395 8.51642 7.799 8.85675C7.45867 9.19708 7.2885 9.61025 7.2885 10.0962C7.2885 10.5821 7.45867 10.9952 7.799 11.3355C8.1395 11.676 8.55267 11.8463 9.0385 11.8463ZM4.30775 19.5C3.80258 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6923V6.30775C2.5 5.80258 2.675 5.375 3.025 5.025C3.375 4.675 3.80258 4.5 4.30775 4.5H19.6923C20.1974 4.5 20.625 4.675 20.975 5.025C21.325 5.375 21.5 5.80258 21.5 6.30775V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.30775ZM4.30775 18H19.6923C19.7693 18 19.8398 17.9679 19.9038 17.9038C19.9679 17.8398 20 17.7692 20 17.6923V6.30775C20 6.23075 19.9679 6.16025 19.9038 6.09625C19.8398 6.03208 19.7693 6 19.6923 6H4.30775C4.23075 6 4.16025 6.03208 4.09625 6.09625C4.03208 6.16025 4 6.23075 4 6.30775V17.6923C4 17.7692 4.03208 17.8398 4.09625 17.9038C4.16025 17.9679 4.23075 18 4.30775 18Z" fill="currentColor"/>',
    },
  },
};
