import { Icon } from '@iconify/react';
import { Badge, Box, Tooltip, Typography, useTheme } from '@mui/material';
import { done } from 'assets/iconify';
import dayjs from 'dayjs';
import { wasBefore } from 'utils/datetime-tools';
import { formatTimeFrom } from 'utils/time-format';

interface AcknowledgementStatusProps {
  alertedAt: number | string | undefined;
  acknowledgedAt: number | string | undefined;
  lastUpdate: number | string | undefined;
}

const AcknowledgementStatus = ({
  alertedAt,
  acknowledgedAt,
  lastUpdate,
}: AcknowledgementStatusProps) => {
  const { palette } = useTheme();

  if (acknowledgedAt && (!alertedAt || wasBefore(alertedAt, acknowledgedAt))) {
    if (
      dayjs(acknowledgedAt).isBefore(lastUpdate) ||
      dayjs(acknowledgedAt).isBefore(dayjs().subtract(7, 'days'))
    ) {
      return null;
    }

    return (
      <Box display="flex" alignItems="center">
        <Icon
          width={20}
          height={20}
          color={palette.secondary.main}
          icon={done.icons.normal}
        />
        <Typography variant="caption" color={palette.secondary.main}>
          <Tooltip title="Acknowledged">
            <span>Ack. {formatTimeFrom(acknowledgedAt)}</span>
          </Tooltip>
        </Typography>
      </Box>
    );
  }

  if (alertedAt) {
    return (
      <Box display="flex" alignItems="center">
        <Badge
          sx={({ spacing, palette }) => ({
            width: spacing(1.25),
            height: spacing(1.25),
            mr: spacing(1),
            borderRadius: '50%',
            backgroundColor: palette.red.main,
          })}
        />
        <Typography variant="caption" color={palette.red.main}>
          Alerted {formatTimeFrom(alertedAt)}
        </Typography>
      </Box>
    );
  }

  return null;
};

export default AcknowledgementStatus;
