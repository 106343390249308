import {
  Box,
  MenuItem,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import {
  BorderWrapper,
  SelectWrapper,
} from 'pages/PatientAccount/components/DataSettings/styled';
import { INTERVAL_OPTIONS } from 'utils/interval-options';

const options = INTERVAL_OPTIONS;

type Props = {
  switchProps?: SwitchProps;
  selectProps?: SelectProps;
};

const LocationData = ({
  switchProps,
  selectProps,
}: Props & { switchProps?: SwitchProps; selectProps?: SelectProps }) => {
  return (
    <Box
      px={{ xxs: 1, newMd: 2 }}
      pt={2}
      pb={1}
      display="flex"
      flexDirection="column"
      gap={1}
      maxWidth={536}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1" color="primary" py={1}>
            Location Data
          </Typography>
          <HelpIcon
            tooltipPlacement="top-start"
            message={
              <>
                When disabled, <strong>BoundaryCare</strong> will not collect or
                display any location data for the patient. This disables all
                safe zone alerts, emergency tracking, and 911 address
                information. You must relaunch <strong>BoundaryCare</strong> on
                the Apple Watch for changes to this setting to fully take
                effect.
              </>
            }
          />
        </Box>
        <Switch
          {...switchProps}
          size="medium"
          sx={{
            ml: 2,
          }}
        />
      </Box>
      <BorderWrapper>
        <SelectWrapper>
          <Typography variant="body2" color="primary">
            Refresh Rate
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Select
              size="small"
              sx={({ spacing }) => ({
                width: spacing(21.25),
                maxHeight: spacing(4),
              })}
              {...selectProps}
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <HelpIcon
              tooltipPlacement="top-start"
              message="Update location data and fire any related alerts within the selected interval of an actual change."
            />
          </Box>
        </SelectWrapper>
      </BorderWrapper>
    </Box>
  );
};

export default LocationData;
