import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDeregisterMutation } from 'store/api/devices.api';
import { DeregistrationStatus } from 'utils/deregistration-status';

export const useDeregistration = () => {
  const [deregister, { isLoading, isSuccess, isUninitialized }] =
    useDeregisterMutation();

  const status: DeregistrationStatus = isSuccess ? 'success' : 'error';

  const [params] = useSearchParams();
  const deviceId = params.get('device_id');

  useEffect(() => {
    if (deviceId) {
      deregister({ deviceId: Number(deviceId) });
    }
  }, []);

  return {
    isLoading: (isLoading || isUninitialized) && !!deviceId,
    status,
  };
};
