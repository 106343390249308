import {
  Box,
  CircularProgress,
  Link,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomPhoneInput } from 'components/CustomPhoneInput';
import { CredentialType } from 'models/auth.model';
import { useLocation } from 'react-router-dom';
import SubmitButton from './components/SubmitButton';
import { useMFASetup } from './hooks/use-mfa-setup';

const MFA = () => {
  const { palette } = useTheme();
  const location = useLocation();
  const state = location.state as {
    identifier?: string;
    schema?: CredentialType;
    seconds?: number;
  };

  const {
    onSubmit,
    error,
    email,
    handleRadioChange,
    handlePhoneNumberChange,
    selectedSchema,
    defaultPhoneNumber,
    canSkipMfa,
    isLoading,
    handleSkipMfaSetup,
  } = useMFASetup(state?.schema, state?.identifier);

  if (isLoading) {
    return <CircularProgress sx={{ mx: 'auto' }} />;
  }

  return (
    <Box>
      <Box pb={4} px={1}>
        <Typography variant="h5" textAlign="center">
          Setup two-factor authetication
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          color={palette.blue.main}
        >
          Choose one of the verification methods below
        </Typography>
      </Box>
      <form>
        {email && (
          <Box display="flex" py={2} pr={1} alignItems="center">
            <Radio
              color="secondary"
              name="mfa"
              value="email"
              checked={selectedSchema === 'email'}
              onChange={handleRadioChange}
            />
            <Box pl={1}>
              <Typography variant="body2">Your current email</Typography>
              <Typography variant="body1" color={palette.blue.main}>
                {email}
              </Typography>
            </Box>
          </Box>
        )}
        <Box display="flex" py={2} pr={1} alignItems="center">
          <Radio
            color="secondary"
            name="mfa"
            value="phone"
            checked={selectedSchema === 'phone'}
            onChange={handleRadioChange}
          />
          <Box pl={1} width={1}>
            <CustomPhoneInput
              inputProps={{ placeholder: 'Enter your phone number' }}
              value={defaultPhoneNumber}
              onChange={handlePhoneNumberChange}
              label="Phone number"
              error={selectedSchema === 'phone' ? error : undefined}
            />
          </Box>
        </Box>
        <Typography
          variant="body2"
          color={palette.red.main}
          textAlign="center"
          sx={{ pb: 2 }}
        >
          We will send a 6 digit code on your{' '}
          {selectedSchema === 'email' ? 'email' : 'phone number'}.
        </Typography>
        <SubmitButton onClick={onSubmit} remainingTime={state?.seconds} />
      </form>
      {canSkipMfa && (
        <Box px={1} pt={4} display="flex" justifyContent="center">
          <Link
            color="secondary"
            onClick={handleSkipMfaSetup}
            fontSize={14}
            fontWeight={600}
          >
            Skip for now
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default MFA;
