import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export default createDraftSafeSelector(
  (state: RootState) => state.user.name,
  (name) => {
    if (typeof name === 'undefined') {
      return false;
    }

    return name?.length > 0;
  },
);
