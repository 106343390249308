import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { trash } from 'assets/iconify';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import { useDisconnectCaregiverMutation } from 'store/api/account.api';

type Role = 'viewer' | 'admin';

interface Props {
  id: number;
  accountId: number;
  name?: string;
  isCurrentUserAdmin?: boolean;
  isAdmin?: boolean;
  isDisabled?: boolean;
  onDelete?: () => void;
  onRoleChange?: (role: Role) => void;
}

const CaregiverItem = ({
  id,
  accountId,
  name = 'Caregiver',
  isCurrentUserAdmin,
  isAdmin,
  isDisabled,
  onDelete,
  onRoleChange,
}: Props) => {
  const theme = useTheme();

  const currentAccountId = useAppSelector(
    (state) => state.user.accountId as number,
  );

  const [role, setRole] = useState<Role>(isAdmin ? 'admin' : 'viewer');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [disconnectCaregiver] = useDisconnectCaregiverMutation();

  const handleDelete = () => {
    setDeleteModalOpen(false);

    toast
      .promise(disconnectCaregiver({ relationshipId: id }).unwrap, {
        pending: `Removing "${name}" from the caregiver group...`,
        success: `"${name}" was removed succesfully!`,
        error: `Could not remove "${name}". Please try again.`,
      })
      .then(() => onDelete?.());
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as Role);
    onRoleChange?.(role);
  };

  const isDeleteEnabled = isCurrentUserAdmin || currentAccountId === accountId;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap={{ xxs: 'wrap', newLg: 'nowrap' }}
        border={`${theme.spacing(0.125)} solid ${
          theme.palette.borderGray.main
        }`}
        borderRadius={theme.spacing(1)}
        width="100%"
        p={{ xxs: 1.5, sm: 2 }}
      >
        <Box
          display="flex"
          flexGrow={1}
          rowGap={1}
          sx={{
            flexDirection: { xxs: 'column', sm: 'row' },
          }}
        >
          <Typography flexGrow={1} lineHeight="32px" variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Select
            value={role}
            onChange={handleChange}
            sx={({ spacing }) => ({
              mr: 2,
              minWidth: spacing(14),
              maxWidth: spacing(14),
            })}
            disabled={isDisabled || !isCurrentUserAdmin}
          >
            <MenuItem value="viewer">Viewer</MenuItem>
            <MenuItem value="admin">Manager</MenuItem>
          </Select>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="red"
          disabled={!isDeleteEnabled}
          onClick={() => setDeleteModalOpen(true)}
          sx={{
            maxWidth: theme.spacing(4),
            p: theme.spacing(0.5),
          }}
        >
          <Icon
            width={theme.spacing(2.5)}
            height={theme.spacing(2.5)}
            icon={trash.icons.normal}
          />
        </Button>
      </Box>
      <ConfirmationDialog
        open={isDeleteModalOpen}
        onConfirm={handleDelete}
        onReject={() => setDeleteModalOpen(false)}
        rejectButtonLabel="Go back"
        confirmButtonProps={{ color: 'red' }}
        title={`Are you sure you want to remove “${name}” from the caregiver group?`}
        subtitle="If you remove this caregiver, they will no longer have access to data or alerts from the current patient and any linked devices."
      />
    </>
  );
};

export default CaregiverItem;
