import { useTheme } from '@mui/material';
import { VInputProps } from 'components/VInput/VInput';
import VInputPassword from 'components/VInputPassword/VInputPassword';
import { FieldError, useFormContext } from 'react-hook-form';
import PasswordError from './PasswordError';

interface IProps extends VInputProps {
  validationError?: FieldError;
}

const ERROR_MESSAGES: Record<string, React.ReactNode> = {
  'no-error': undefined,
  auth: <PasswordError />,
  required: 'Password is required.',
};

const PasswordField: React.FC<IProps> = ({ validationError, ...rest }) => {
  const { register } = useFormContext();
  const { palette } = useTheme();

  return (
    <VInputPassword
      label="Password"
      name="password"
      inputProps={{ ...register('password'), ...rest.inputProps }}
      error={!!validationError}
      helperText={ERROR_MESSAGES[validationError?.type || 'no-error']}
      formHelperTextProps={{
        sx: {
          color:
            validationError?.type === 'auth'
              ? palette.primary.main
              : palette.grapeFruit.main,
          pb: 1,
        },
      }}
      {...rest}
    />
  );
};

export default PasswordField;
