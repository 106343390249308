import {
  Box,
  Switch,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { RemoveButton, SaveButton, CancelButton } from 'components/_extra';
import {
  CardControlsWrapper,
  CardWrapper,
  FormWrapper,
  GeneralControls,
} from './styled';
import { ReactNode } from 'react';
import { triggerDescriptions } from 'utils/triggers';
import { Icon } from '@iconify/react';
import { help } from 'assets/iconify';
import { caregiverRole } from 'services/caregiver-role';

interface Props {
  enabled?: boolean;
  children?: ReactNode;
  title?: string;
  isEdited?: boolean;
  isSaveActive?: boolean;
  isSwitchDisabled?: boolean;
  onSwitchToggle?: (checked: boolean) => void;
  onRemove?: () => void;
  onGoBack?: () => void;
  onSave?: () => void;
  sx?: SxProps<Theme>;
  metricType: string;
}

interface CardControlsProps {
  isInEditMode: boolean;
  isSaveActive: boolean;
  onRemoveClick?: () => void;
  onGoBackClick?: () => void;
  onSaveClick?: () => void;
  disabled?: boolean;
}

const CardControls = ({
  isInEditMode,
  onRemoveClick,
  onGoBackClick,
  onSaveClick,
  isSaveActive,
  disabled,
}: CardControlsProps) => {
  if (isSaveActive && isInEditMode) {
    return (
      <CardControlsWrapper>
        <SaveButton onClick={onSaveClick} disabled={!isSaveActive} />
        <CancelButton onClick={onGoBackClick} />
      </CardControlsWrapper>
    );
  }

  return (
    <CardControlsWrapper>
      <RemoveButton
        tooltipText="Delete"
        disabled={disabled}
        onClick={onRemoveClick}
      />
    </CardControlsWrapper>
  );
};

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  min-width: ${({ theme }) => theme.spacing(2.5)};
  min-height: ${({ theme }) => theme.spacing(2.5)};
`;

const MetricRuleCard = ({
  title,
  enabled,
  onGoBack,
  onRemove,
  onSave,
  isSaveActive,
  isEdited,
  isSwitchDisabled,
  onSwitchToggle,
  children,
  sx,
  metricType,
}: Props) => {
  const readOnly = caregiverRole.value === 'viewer';

  return (
    <CardWrapper isEdited={!!isEdited} sx={sx}>
      <GeneralControls>
        <Box
          display="grid"
          gridTemplateColumns="auto 1fr"
          alignItems="center"
          gap={1}
          height="32px"
          overflow="hidden"
          width="100%"
        >
          <Switch
            disabled={isSwitchDisabled || readOnly}
            checked={enabled}
            size="small"
            onChange={(e, checked) => onSwitchToggle?.(checked)}
          />
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            overflow="hidden"
            gap={0.5}
          >
            <Typography fontWeight={600} color="primary" noWrap>
              {title}
            </Typography>
            {metricType && triggerDescriptions[metricType] && (
              <Tooltip title={triggerDescriptions[metricType]}>
                <StyledIcon icon={help.icons.outlined} />
              </Tooltip>
            )}
          </Box>
        </Box>
        <CardControls
          isInEditMode={!!isEdited}
          onSaveClick={onSave}
          onGoBackClick={onGoBack}
          onRemoveClick={onRemove}
          isSaveActive={!!isSaveActive}
          disabled={readOnly}
        />
      </GeneralControls>
      <FormWrapper>{children}</FormWrapper>
    </CardWrapper>
  );
};

export default MetricRuleCard;
