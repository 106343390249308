import React, { MouseEventHandler } from 'react';

import * as Styled from './VCheckBox.styles';

interface Props {
  isChecked?: boolean;
  checkBoxContent?: string | JSX.Element;
  handleCheckedClick?: MouseEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  className?: string;
}

const VCheckBoxView = (props: Props) => {
  return (
    <div style={props.style} className={props.className}>
      <Styled.VLabel>
        <Styled.VInput type="checkbox" onClick={props.handleCheckedClick} />

        <Styled.VSvg
          isChecked={props.isChecked}
          aria-hidden="true"
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25 4.35714L3.5 6.5L8.75 1.5"
            stroke={props.isChecked ? '#fff' : 'none'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Styled.VSvg>

        {props.checkBoxContent}
      </Styled.VLabel>
    </div>
  );
};

VCheckBoxView.displayName = 'VCheckBoxView';

export default VCheckBoxView;
