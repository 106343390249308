import {
  Box,
  Button,
  CircularProgress,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { useCredentialSelect } from '../hooks';
import dayjs from 'dayjs';

const VERIFICATION_METHODS = {
  email: 'email',
  phone: 'phone number',
};

const MethodSelect = () => {
  const theme = useTheme();

  const [checkedId, setCheckedId] = useState<number | null>(null);
  const [resendSecondsLeft, setResendSecondsLeft] = useState(0);

  const codeRequestedAt = useAppSelector((state) => state.mfa.codeRequestedAt);

  const { methods, isFetching, isLoading, setActiveMethod } =
    useCredentialSelect();

  useEffect(() => {
    setTimeout(() => {
      if (resendSecondsLeft > 0) {
        setResendSecondsLeft((prev) => prev - 1);
      }
    }, 1000);
  }, [resendSecondsLeft]);

  useEffect(() => {
    if (codeRequestedAt) {
      const seconds = dayjs(codeRequestedAt)
        .add(60, 'seconds')
        .diff(dayjs(), 'seconds');

      setResendSecondsLeft(seconds);
    }
  }, [codeRequestedAt]);

  useEffect(() => {
    if (methods.length > 0) {
      setCheckedId(methods[0].id);
    }
  }, [methods]);

  const handleMethodClick = (methodId: number) => {
    setCheckedId(methodId);
  };

  const handleSendCode = () => {
    if (checkedId) {
      setActiveMethod(checkedId);
    }
  };

  const canResend = resendSecondsLeft <= 0;
  const isDisabled = !canResend || isLoading;

  return (
    <Box>
      <Typography variant="h5" textAlign="center">
        Verify it's you
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color={theme.palette.blue.main}
        mx={2}
        mb={4}
      >
        Choose one of the verification methods below:
      </Typography>
      {!isFetching ? (
        <>
          {methods.map((method) => (
            <Box
              display="flex"
              py={2}
              pr={1}
              alignItems="center"
              key={method.id}
            >
              <Radio
                color="secondary"
                name="mfa"
                value="email"
                checked={method.id === checkedId}
                onChange={() => handleMethodClick(method.id)}
              />
              <Box pl={1}>
                <Typography variant="body2">
                  Your current{' '}
                  {VERIFICATION_METHODS[
                    method.type as keyof typeof VERIFICATION_METHODS
                  ] || ''}
                </Typography>
                <Typography variant="body1" color={theme.palette.blue.main}>
                  {method.credential}
                </Typography>
              </Box>
            </Box>
          ))}
          <Typography
            variant="body2"
            textAlign="center"
            mt={2}
            mx={2}
            color="error"
          >
            {methods && checkedId ? (
              'We will send a 6 digit code to your ' +
              (VERIFICATION_METHODS[
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                methods.find((item) => item.id === checkedId)!
                  .type as keyof typeof VERIFICATION_METHODS
              ] || 'chosen method') +
              '.'
            ) : (
              <>&nbsp;</>
            )}
          </Typography>
          <Button
            fullWidth
            sx={{ mt: 3 }}
            disabled={isDisabled}
            onClick={handleSendCode}
          >
            Send code {!canResend && `in ${resendSecondsLeft} seconds`}
          </Button>
        </>
      ) : (
        <Box my={6} display="flex" justifyContent="center">
          <CircularProgress size={theme.spacing(9.5)} color="primary" />
        </Box>
      )}
    </Box>
  );
};

export default MethodSelect;
