import { Box, Checkbox, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const RememberMeCheckbox = () => {
  const { register } = useFormContext();

  return (
    <Box display="flex" alignItems="center">
      <Checkbox id="remember" {...register('isRememberMeChecked')} />
      <Typography htmlFor="remember" component="label" color="primary">
        Remember me
      </Typography>
    </Box>
  );
};

export default RememberMeCheckbox;
