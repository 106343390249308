import { IHealthRecordData, ISettingsRecord } from 'models/dashboard.model';
import dayjs from 'dayjs';
import { HeartStatus } from '../types/enum/heart-status.enum';

interface HeartAnomaly {
  alertedAt: string;
  status: HeartStatus;
  acknowledgedAt?: string;
}

export const getHeartStatus = (
  healthData: IHealthRecordData,
  settingsRecordData: ISettingsRecord['record_data'],
): HeartAnomaly => {
  if (typeof healthData.formatted_health_data === 'undefined') {
    return { alertedAt: '', status: HeartStatus.NO_DATA };
  }

  const { high_heart_rate_event, low_heart_rate_event } =
    healthData.formatted_health_data;
  const {
    high_heart_rate_event: highHeartRateLastAck,
    low_heart_rate_event: lowHeartRateLastAck,
  } = settingsRecordData.last_health_acks;

  const heartAnomalies: HeartAnomaly[] = [
    {
      status: HeartStatus.LOW,
      alertedAt: low_heart_rate_event.start_date,
      acknowledgedAt: lowHeartRateLastAck,
    },
    {
      status: HeartStatus.HIGH,
      alertedAt: high_heart_rate_event.start_date,
      acknowledgedAt: highHeartRateLastAck,
    },
  ];

  const latestAnomaly = heartAnomalies.reduce(
    (latest, current) => {
      if (
        (!!current.alertedAt && !latest.alertedAt) ||
        dayjs(current.alertedAt).isAfter(latest.alertedAt)
      ) {
        if (
          !current.acknowledgedAt ||
          dayjs(current.alertedAt).isAfter(current.acknowledgedAt)
        ) {
          return current;
        }
      }
      return latest;
    },
    { status: HeartStatus.NORMAL, alertedAt: '' },
  );

  if (!!latestAnomaly.alertedAt) {
    return latestAnomaly;
  }

  if (healthData.formatted_health_data.heart_rate.value) {
    return { alertedAt: '', status: HeartStatus.NORMAL };
  }

  return { alertedAt: '', status: HeartStatus.NO_DATA };
};
