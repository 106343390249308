import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const visibility: IconifyJSON = {
  prefix: 'visibility',
  icons: {
    shown: {
      ...defaultSizes,
      body: '<path d="M12.0023 15.5769C13.1354 15.5769 14.0977 15.1803 14.8894 14.3871C15.6811 13.594 16.0769 12.6308 16.0769 11.4977C16.0769 10.3646 15.6803 9.40227 14.8872 8.6106C14.094 7.81893 13.1308 7.4231 11.9977 7.4231C10.8647 7.4231 9.90228 7.81968 9.11061 8.61285C8.31895 9.40603 7.92311 10.3692 7.92311 11.5023C7.92311 12.6354 8.3197 13.5977 9.11286 14.3894C9.90605 15.1811 10.8692 15.5769 12.0023 15.5769ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.56251 12.8875 9.30001 12.25 9.30001 11.5C9.30001 10.75 9.56251 10.1125 10.0875 9.5875C10.6125 9.0625 11.25 8.8 12 8.8C12.75 8.8 13.3875 9.0625 13.9125 9.5875C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12.0014 18.5C9.70177 18.5 7.60645 17.8657 5.71541 16.5971C3.8244 15.3285 2.4321 13.6295 1.53851 11.5C2.4321 9.37051 3.82395 7.67148 5.71406 6.4029C7.60416 5.13432 9.69902 4.50002 11.9986 4.50002C14.2983 4.50002 16.3936 5.13432 18.2846 6.4029C20.1756 7.67148 21.5679 9.37051 22.4615 11.5C21.5679 13.6295 20.1761 15.3285 18.286 16.5971C16.3959 17.8657 14.301 18.5 12.0014 18.5ZM12 17C13.8833 17 15.6125 16.5042 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7625 8.47917 17.1875 7.4875C15.6125 6.49583 13.8833 6 12 6C10.1167 6 8.38751 6.49583 6.81251 7.4875C5.23751 8.47917 4.03335 9.81667 3.20001 11.5C4.03335 13.1833 5.23751 14.5208 6.81251 15.5125C8.38751 16.5042 10.1167 17 12 17Z" fill="currentColor"/>',
    },
    hidden: {
      ...defaultSizes,
      body: '<path d="M15.7731 12.9731L14.65 11.85C14.8 11.0218 14.5638 10.2772 13.9414 9.61635C13.3189 8.95545 12.5551 8.7 11.65 8.85L10.527 7.72695C10.7526 7.62567 10.984 7.54971 11.2212 7.49908C11.4584 7.44843 11.718 7.4231 12 7.4231C13.1346 7.4231 14.0977 7.81893 14.8894 8.6106C15.6811 9.40227 16.0769 10.3654 16.0769 11.5C16.0769 11.7821 16.0516 12.0449 16.0009 12.2885C15.9503 12.532 15.8743 12.7602 15.7731 12.9731ZM18.9538 16.0846L17.85 15.05C18.4833 14.5667 19.0458 14.0375 19.5375 13.4625C20.0292 12.8875 20.45 12.2333 20.8 11.5C19.9667 9.81667 18.7708 8.47917 17.2125 7.4875C15.6542 6.49583 13.9167 6 12 6C11.5167 6 11.0417 6.03333 10.575 6.1C10.1083 6.16667 9.65001 6.26667 9.20001 6.4L8.03466 5.23465C8.66671 4.98337 9.31222 4.79811 9.97119 4.67888C10.6302 4.55964 11.3064 4.50003 12 4.50003C14.3436 4.50003 16.457 5.14618 18.3404 6.43848C20.2237 7.73079 21.5974 9.41797 22.4615 11.5C22.091 12.3936 21.6125 13.2276 21.0259 14.0019C20.4394 14.7763 19.7487 15.4705 18.9538 16.0846ZM19.7615 21.8692L15.7154 17.8538C15.2026 18.0436 14.6343 18.1987 14.0106 18.3192C13.3869 18.4397 12.7167 18.5 12 18.5C9.65003 18.5 7.53658 17.8538 5.65966 16.5615C3.78275 15.2692 2.40903 13.582 1.53851 11.5C1.90775 10.6167 2.38467 9.79263 2.96929 9.02788C3.55391 8.26314 4.1975 7.60001 4.90006 7.03848L2.13081 4.23848L3.18466 3.18465L20.8154 20.8154L19.7615 21.8692ZM5.95389 8.09228C5.42569 8.51279 4.91191 9.01825 4.41254 9.60865C3.91317 10.199 3.509 10.8295 3.20001 11.5C4.03335 13.1833 5.22918 14.5208 6.78751 15.5125C8.34585 16.5042 10.0833 17 12 17C12.4551 17 12.9084 16.9615 13.3597 16.8846C13.8109 16.8077 14.1936 16.7282 14.5077 16.6462L13.2423 15.35C13.0718 15.4192 12.8744 15.4743 12.65 15.5154C12.4257 15.5564 12.209 15.5769 12 15.5769C10.8654 15.5769 9.90228 15.1811 9.11061 14.3894C8.31895 13.5977 7.92311 12.6346 7.92311 11.5C7.92311 11.2974 7.94363 11.0856 7.98466 10.8644C8.0257 10.6433 8.08082 10.441 8.15004 10.2577L5.95389 8.09228Z" fill="currentColor"/>',
    },
  },
};
