import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const heartRate: IconifyJSON = {
  prefix: 'heart-rate',
  icons: {
    high: {
      ...defaultSizes,
      body: '<path d="M20.0599 4.59C19.5965 4.11931 19.0415 3.74867 18.4292 3.50099C17.8169 3.25331 17.1603 3.13385 16.4999 3.15C15.6287 3.14153 14.7693 3.35123 13.9999 3.76C13.2065 4.18908 12.5223 4.79465 11.9999 5.53C11.4747 4.79724 10.7911 4.19225 9.99993 3.76C9.22951 3.35399 8.37077 3.14446 7.49993 3.15C6.84034 3.13919 6.18531 3.26113 5.57379 3.50857C4.96228 3.75602 4.40677 4.12391 3.9403 4.59038C3.47384 5.05684 3.10594 5.61235 2.8585 6.22387C2.61105 6.83538 2.48911 7.49041 2.49993 8.15C2.49846 8.8696 2.62371 9.58384 2.86993 10.26C3.20015 11.0867 3.64811 11.8614 4.19993 12.56C4.98983 13.5837 5.85204 14.5495 6.77993 15.45C7.85993 16.53 9.22993 17.83 10.8999 19.33L11.9999 20.33L13.0999 19.33C14.7699 17.83 16.0999 16.53 17.2299 15.45C18.1522 14.5471 19.0109 13.5814 19.7999 12.56C20.349 11.8613 20.7937 11.0866 21.1199 10.26C21.3694 9.5844 21.498 8.87018 21.4999 8.15C21.5161 7.48968 21.3966 6.83306 21.1489 6.22074C20.9013 5.60842 20.5306 5.0534 20.0599 4.59ZM14.5499 11.59L12.7499 9.8V17.1H11.2499V9.8L9.44993 11.6L8.34993 10.6L11.9999 6.9L15.6499 10.6L14.5499 11.59Z" fill="currentColor"/>',
    },
    low: {
      ...defaultSizes,
      body: '<path d="M20.0599 4.59C19.5965 4.11931 19.0415 3.74867 18.4292 3.50099C17.8169 3.25331 17.1603 3.13385 16.4999 3.15C15.6287 3.14153 14.7693 3.35123 13.9999 3.76C13.2065 4.18908 12.5223 4.79465 11.9999 5.53C11.4747 4.79724 10.7911 4.19225 9.99993 3.76C9.22951 3.35399 8.37077 3.14446 7.49993 3.15C6.84034 3.13919 6.18531 3.26113 5.57379 3.50857C4.96228 3.75602 4.40677 4.12391 3.9403 4.59038C3.47384 5.05684 3.10594 5.61235 2.8585 6.22387C2.61105 6.83538 2.48911 7.49041 2.49993 8.15C2.49846 8.8696 2.62371 9.58384 2.86993 10.26C3.20015 11.0867 3.64811 11.8614 4.19993 12.56C4.98983 13.5837 5.85204 14.5495 6.77993 15.45C7.85993 16.53 9.22993 17.83 10.8999 19.33L11.9999 20.33L13.0999 19.33C14.7699 17.83 16.0999 16.53 17.2299 15.45C18.1522 14.5471 19.0109 13.5814 19.7999 12.56C20.349 11.8613 20.7937 11.0866 21.1199 10.26C21.3694 9.5844 21.498 8.87018 21.4999 8.15C21.5161 7.48968 21.3966 6.83306 21.1489 6.22074C20.9013 5.60842 20.5306 5.0534 20.0599 4.59ZM11.9999 17.1L8.34993 13.4L9.44993 12.4L11.2499 14.2V6.9H12.7499V14.2L14.5499 12.4L15.6499 13.4L11.9999 17.1Z" fill="currentColor"/>',
    },
    irregular: {
      ...defaultSizes,
      body: '<path d="M20.0599 4.59C19.5965 4.11931 19.0415 3.74867 18.4292 3.50099C17.8169 3.25331 17.1603 3.13385 16.4999 3.15C15.6287 3.14153 14.7693 3.35123 13.9999 3.76C13.2065 4.18908 12.5223 4.79465 11.9999 5.53C11.4747 4.79724 10.7911 4.19225 9.99993 3.76C9.22951 3.35399 8.37077 3.14446 7.49993 3.15C6.84034 3.13919 6.18531 3.26113 5.57379 3.50857C4.96228 3.75602 4.40677 4.12391 3.9403 4.59038C3.47384 5.05684 3.10594 5.61235 2.8585 6.22387C2.61105 6.83538 2.48911 7.49041 2.49993 8.15C2.49846 8.8696 2.62371 9.58384 2.86993 10.26C3.20015 11.0867 3.64811 11.8614 4.19993 12.56C4.98983 13.5837 5.85204 14.5495 6.77993 15.45C7.85993 16.53 9.22993 17.83 10.8999 19.33L11.9999 20.33L13.0999 19.33C14.7699 17.83 16.0999 16.53 17.2299 15.45C18.1522 14.5471 19.0109 13.5814 19.7999 12.56C20.349 11.8613 20.7937 11.0866 21.1199 10.26C21.3694 9.5844 21.498 8.87018 21.4999 8.15C21.5161 7.48968 21.3966 6.83306 21.1489 6.22074C20.9013 5.60842 20.5306 5.0534 20.0599 4.59ZM17.2999 12.5H14.2099L13.3699 10.25L10.6299 17.45L8.74993 12.45H6.74993V11H9.74993L10.5899 13.24L13.3699 6L15.2499 11H17.2999V12.5Z" fill="currentColor"/>',
    },
    normal: {
      ...defaultSizes,
      body: '<path d="M12 20.325L10.9 19.325C9.23333 17.825 7.85833 16.5334 6.775 15.45C5.69167 14.3667 4.83333 13.404 4.2 12.562C3.56667 11.7207 3.125 10.954 2.875 10.262C2.625 9.57069 2.5 8.86669 2.5 8.15002C2.5 6.73336 2.97933 5.54569 3.938 4.58702C4.896 3.62902 6.08333 3.15002 7.5 3.15002C8.36667 3.15002 9.19167 3.35402 9.975 3.76202C10.7583 4.17069 11.4333 4.75836 12 5.52502C12.5667 4.75836 13.2417 4.17069 14.025 3.76202C14.8083 3.35402 15.6333 3.15002 16.5 3.15002C17.9167 3.15002 19.104 3.62902 20.062 4.58702C21.0207 5.54569 21.5 6.73336 21.5 8.15002C21.5 8.86669 21.375 9.57069 21.125 10.262C20.875 10.954 20.4333 11.7207 19.8 12.562C19.1667 13.404 18.3083 14.3667 17.225 15.45C16.1417 16.5334 14.7667 17.825 13.1 19.325L12 20.325Z" fill="currentColor"/>',
    },
    disabled: {
      ...defaultSizes,
      body: '<path d="M12 20.325L10.9 19.325C9.23333 17.825 7.85833 16.5334 6.775 15.45C5.69167 14.3667 4.83333 13.404 4.2 12.562C3.56667 11.7207 3.125 10.954 2.875 10.262C2.625 9.57069 2.5 8.86669 2.5 8.15002C2.5 6.73336 2.97933 5.54569 3.938 4.58702C4.896 3.62902 6.08333 3.15002 7.5 3.15002C8.36667 3.15002 9.19167 3.35402 9.975 3.76202C10.7583 4.17069 11.4333 4.75836 12 5.52502C12.5667 4.75836 13.2417 4.17069 14.025 3.76202C14.8083 3.35402 15.6333 3.15002 16.5 3.15002C17.9167 3.15002 19.104 3.62902 20.062 4.58702C21.0207 5.54569 21.5 6.73336 21.5 8.15002C21.5 8.86669 21.375 9.57069 21.125 10.262C20.875 10.954 20.4333 11.7207 19.8 12.562C19.1667 13.404 18.3083 14.3667 17.225 15.45C16.1417 16.5334 14.7667 17.825 13.1 19.325L12 20.325Z" fill="currentColor"/>',
    },
  },
};
