import { bloodOxygen } from 'assets/iconify/blood-oxygen';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';

export const getBloodOxygenStatus = (
  value?: number | null,
): DataCellProps & { value: number | string } => {
  if (value === null || typeof value === 'undefined') {
    return {
      icon: bloodOxygen.icons.normal,
      status: DataStatus.DISABLED,
      label: 'Disabled',
      value: value ? `${Math.floor(value)}%` : 'N/A',
    };
  }

  return {
    icon: bloodOxygen.icons.normal,
    status: DataStatus.POSITIVE,
    label: 'Normal',
    value: value ? `${Math.floor(value)}%` : 'N/A',
  };
};
