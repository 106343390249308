import { SxProps, Theme, Tooltip } from '@mui/material';
import { MouseEventHandler } from 'react';
import { IconOnlyButton, StyledIcon } from './styled';
import { trash } from 'assets/iconify';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'medium' | 'small';
  toRemove?: string;
  disabled?: boolean;
  tooltipText?: string;
  buttonSx?: SxProps<Theme>;
}

const RemoveButton = ({
  onClick,
  size = 'small',
  toRemove,
  disabled,
  tooltipText = 'Remove',
  buttonSx,
}: Props) => {
  return (
    <Tooltip title={`${tooltipText} ${toRemove ?? ''}`} placement="top">
      <span>
        <IconOnlyButton
          size={size}
          disabled={disabled}
          variant="outlined"
          color="red"
          onClick={onClick}
          sx={buttonSx}
        >
          <StyledIcon icon={trash.icons.normal} />
        </IconOnlyButton>
      </span>
    </Tooltip>
  );
};

export default RemoveButton;
