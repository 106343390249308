import { Box, Link, Typography, useTheme } from '@mui/material';
import { routes } from 'nav';
import { useNavigate } from 'react-router-dom';

const RegistrationMessage: React.FC = () => {
  const { spacing } = useTheme();
  const navigate = useNavigate();

  const handleNavigateToRegistration = (_event?: unknown, email?: string) => {
    navigate(routes.public.register.href + (email ? '?email=' + email : ''));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={spacing(0.5)}
      pt={4}
      px={1}
    >
      <Typography variant="body2">Don&apos;t have an account?</Typography>
      <Link
        variant="body2"
        fontWeight={600}
        color="secondary"
        onClick={handleNavigateToRegistration}
      >
        Sign up
      </Link>
    </Box>
  );
};

export default RegistrationMessage;
