import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  inputBaseClasses,
  styled,
  useTheme,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { setSearchPhrase } from 'store/reducers/filters/filters.slice';
import { search } from 'assets/iconify';

const SearchInputWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.common.white,
  minWidth: theme.spacing(15),
  maxWidth: theme.spacing(50),
  maxHeight: theme.spacing(5),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  border: 'none',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),

  [`.${inputBaseClasses.input}`]: {
    fontSize: theme.spacing(2),
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 1),
  padding: theme.spacing(1),
  height: theme.spacing(5),
  minWidth: theme.spacing(5),
  maxWidth: theme.spacing(5),
}));

interface Props {
  onChange?: (value: string) => void;
}

const Search = ({ onChange }: Props) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const searchPhrase = useAppSelector((state) => state.filters.searchPhrase);

  const [isEmpty, setIsEmpty] = useState(searchPhrase.length === 0);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }

    if (e.target.value.length) {
      setIsEmpty(false);
      return;
    }
    setIsEmpty(true);
  };

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    if (typeof onChange === 'function') {
      onChange('');
    }

    setIsEmpty(true);
    dispatch(setSearchPhrase(''));
  };

  const handleSearch = () => {
    dispatch(setSearchPhrase(inputRef.current?.value ?? ''));
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box display="flex" alignItems="center" width="100%" flexGrow={1}>
      <SearchInputWrapper>
        <SearchInput
          placeholder="Patient name"
          fullWidth
          inputRef={inputRef}
          size="medium"
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          defaultValue={searchPhrase}
        />
        {!isEmpty && (
          <IconButton
            size="medium"
            sx={{
              mr: 1.5,
              p: 0.25,
            }}
            onClick={handleClear}
          >
            <Icon
              icon="material-symbols:cancel"
              color={palette.lightBlue.main}
            />
          </IconButton>
        )}
      </SearchInputWrapper>
      <SearchButton onClick={handleSearch}>
        <Icon width={24} height={24} icon={search.icons.normal} />
      </SearchButton>
    </Box>
  );
};

export default Search;
