import { useConfirmation } from 'hooks/use-confirmation';
import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AlerterDetailsTabIndex } from '../AlerterDetails';
import { setSettingsAsUntouched } from 'store/reducers/alerter/alerter.slice';
import { useAlerterDetails } from './use-alerter-details';

export const useAlerterDetailsTabs = () => {
  const { areSettingsModified } = useAlerterDetails();

  const [isViewCollapsed, setIsViewCollapsed] = useState(false);
  const toggleView = () => {
    setIsViewCollapsed((prev) => !prev);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [submit, setSubmit] = useState<() => void | 0>(() => () => {});
  const [currentTab, setCurrentTab] = useState<AlerterDetailsTabIndex>(
    AlerterDetailsTabIndex.TRIGGERS,
  );

  const dispatch = useDispatch();
  const confirm = useConfirmation();

  const handleTabChange = (e: SyntheticEvent, tab: AlerterDetailsTabIndex) => {
    setIsViewCollapsed(false);
    if (currentTab === AlerterDetailsTabIndex.SETTINGS && areSettingsModified) {
      confirm(
        {
          title: 'You have unsaved changes',
          message: 'Do you want to save or discard them?',
          confirmButtonLabel: 'Save',
          rejectButtonLabel: 'Discard',
        },
        () => {
          const result = submit();
          if (result === 0) {
            setCurrentTab(tab);
          }
        },
        () => {
          dispatch(setSettingsAsUntouched());
          setCurrentTab(tab);
        },
      );
      return;
    }

    setCurrentTab(tab);
  };

  const handleSubmitChange = (value: () => void) => {
    setSubmit(() => value);
  };

  return {
    handleSubmitChange,
    currentTab,
    handleTabChange,
    areSettingsModified,
    isViewCollapsed,
    toggleView,
  };
};
