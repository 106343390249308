import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const clearFilters: IconifyJSON = {
  prefix: 'clear-filters',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M11.38 19.5C11.13 19.5 10.92 19.42 10.75 19.25C10.58 19.08 10.5 18.87 10.5 18.62V12.83L4.89997 5.72C4.70997 5.46 4.67997 5.2 4.81997 4.92C4.95997 4.64 5.18997 4.5 5.50997 4.5H18.49C18.81 4.5 19.04 4.64 19.18 4.92C19.32 5.2 19.29 5.46 19.1 5.72L13.5 12.83V18.62C13.5 18.87 13.42 19.08 13.25 19.25C13.08 19.42 12.87 19.5 12.62 19.5H11.39H11.38ZM12 12.3L16.95 6H7.04997L12 12.3ZM15.31 20.7L17.43 18.58L15.31 16.47L16.38 15.4L18.5 17.52L20.62 15.4L21.67 16.47L19.55 18.59L21.65 20.71L20.61 21.76L18.48 19.64L16.36 21.76L15.32 20.69L15.31 20.7Z" fill="currentColor"/>',
    },
  },
};
