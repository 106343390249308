import { useState } from 'react';

export const useConnectDeviceModal = () => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return {
    open,
    isDialogOpen,
    handleButtonClick,
    handleModalClose,
    openDialog,
    closeDialog,
  };
};
