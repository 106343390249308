import theme from 'styles/theme';

export const getStyles = (finished: boolean, isIncorrect?: boolean) =>
  ({
    strokeOpacity: 1,
    strokeColor:
      theme.palette[finished ? 'secondary' : isIncorrect ? 'error' : 'primary']
        .main,
    fillColor:
      theme.palette[finished ? 'secondary' : isIncorrect ? 'error' : 'primary']
        .main,
    fillOpacity: 0.2,
    lineWidth: 3,
  } as mapkit.StyleConstructorOptions);

export const getPolygonStyles = (finished: boolean) =>
  ({
    strokeColor: theme.palette[finished ? 'secondary' : 'primary'].main,
    lineWidth: 3,
  } as mapkit.StyleConstructorOptions);

export const getPathStyles = (finished: boolean) =>
  ({
    strokeColor: theme.palette[finished ? 'secondary' : 'primary'].main,
    strokeOpacity: 0.2,
  } as mapkit.StyleConstructorOptions);

export const getAdditionalPathStyles = (finished: boolean) =>
  ({
    strokeColor: theme.palette[finished ? 'secondary' : 'primary'].main,
    lineDash: finished ? [1, 0] : [5, 10],
    lineWidth: 3,
  } as mapkit.StyleConstructorOptions);
