import {
  IAcknowledgeRequest,
  IDashboardRequest,
  IDashboardResponse,
  IGetCustomRecordsRequest,
  IGetCustomRecordsResponse,
  IGetCustomRecordsTransformedResponse,
} from 'models/dashboard.model';
import { PatientData } from 'types/patient';
import { bcApi } from './_api';
import { transformPatientData } from 'utils/transform-patient-data';
import { IAccountRequest } from 'models/account.model';
import { transformCustomRecords } from 'utils/transform-custom-records';

export const dashboardApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query<PatientData | null, IDashboardRequest>({
      providesTags: ['DashboardData'],
      query: (data) => ({
        url: '/records/synthesized',
        method: 'GET',
        params: data,
      }),
      transformResponse: ({ result, meta }: IDashboardResponse) => {
        if (result === null) {
          return result;
        }

        return transformPatientData({
          id: result.id,
          accountId: result.account_id,
          deviceId: result.device_id,
          generatedAt: result.generated_at,
          createdAt: result.created_at,
          healthRecord: result.health_record,
          locationRecord: result.location_record || undefined,
          settingsRecord: result.settings_record,
          usageRecord: result.usage_record,
          activityRecord: result.activity_record,
          deviceInfoRecord: result.device_info_record,
          helpRequest: meta.last_help_requested_event,
          seizureDetected: meta.last_tonic_clonic_seizure_event,
          widgetsWithAlerts: meta.alerter_targeted_widgets,
        });
      },
    }),
    acknowledge: builder.mutation<IAccountRequest, IAcknowledgeRequest>({
      invalidatesTags: ['DashboardData'],
      query: (data) => {
        const currentTimestamp = new Date().toISOString();

        return {
          url: `/accounts/${data.id}`,
          method: 'PUT',
          data: {
            ...(data.includeFallDetection && {
              last_fall_ack: currentTimestamp,
            }),
            ...(data.includeHighHeartRate && {
              last_high_heart_ack: currentTimestamp,
            }),
            ...(data.includeLowHeartRate && {
              last_low_heart_ack: currentTimestamp,
            }),
            ...(data.includeIrregularHeartRate && {
              last_irregular_heart_ack: currentTimestamp,
            }),
          },
        };
      },
    }),
    getCustomRecord: builder.query<
      IGetCustomRecordsTransformedResponse,
      IGetCustomRecordsRequest
    >({
      query: (data) => ({
        url: '/records/fields_search',
        method: 'GET',
        params: data,
      }),
      transformResponse: ({ results, meta }: IGetCustomRecordsResponse) => {
        return {
          rows: results.map((result, id) => transformCustomRecords(result, id)),
          totalNumber: meta.total_rows,
          displayedNumber: meta.total_displayed_rows,
        };
      },
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
  useLazyGetDashboardDataQuery,
  useAcknowledgeMutation,
  useGetCustomRecordQuery,
  useLazyGetCustomRecordQuery,
} = dashboardApi;
