import { Box, Tab, Tabs, styled, tabClasses } from '@mui/material';
import RemindersList from './components/RemindersList';
import { useState } from 'react';
import SharedRecipientSets from './components/SharedRecipientSets';
import CreateReminderButton from './components/CreateReminderButton';
import { PageContent } from 'components/_extra/generic';
import { useRemindersList } from './hooks/use-reminders-list';
import CreateRecipientSetButton from 'pages/Alerters/components/RecipientSets/CreateRecipientSetButton';
import { useRecipientSets } from 'hooks/use-recipient-sets';

const RemindersTabIndex = {
  REMINDERS: 0,
  RECIPIENTS_SETS: 1,
} as const;

type RemindersTabIndex =
  (typeof RemindersTabIndex)[keyof typeof RemindersTabIndex];

const getTabPanelContent = (index: RemindersTabIndex) => {
  switch (index) {
    case RemindersTabIndex.REMINDERS:
      return <RemindersList />;
    case RemindersTabIndex.RECIPIENTS_SETS:
      return <SharedRecipientSets />;
    default:
      <></>;
  }
};

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [`.${tabClasses.root}:not(.${tabClasses.selected})`]: {
    color: theme.palette.gray.light,
    opacity: 0.7,
  },
}));

const Reminders = () => {
  const { reminderCount } = useRemindersList();
  const { sets } = useRecipientSets(true);
  const [currentTab, setCurrentTab] = useState<RemindersTabIndex>(
    RemindersTabIndex.REMINDERS,
  );

  return (
    <PageContent>
      <Wrapper>
        <Tabs
          sx={{ px: { xxs: 0, newLg: 3 } }}
          value={currentTab}
          onChange={(e, tab) => setCurrentTab(tab)}
        >
          <Tab
            sx={{ px: 2 }}
            value={RemindersTabIndex.REMINDERS}
            label="Reminder List"
          />
          <Tab
            value={RemindersTabIndex.RECIPIENTS_SETS}
            label="Recipient Sets"
          />
        </Tabs>
      </Wrapper>
      <Box
        sx={{
          padding: ({ spacing }) => {
            const withoutPadding =
              (currentTab === RemindersTabIndex.REMINDERS &&
                reminderCount > 0) ||
              (currentTab === RemindersTabIndex.RECIPIENTS_SETS &&
                sets.length > 0);
            return {
              xxs: spacing(2, 1.5, withoutPadding ? 0 : 2),
              xs: spacing(2, 2, withoutPadding ? 0 : 2),
              sm: spacing(3, 3, withoutPadding ? 0 : 3),
              newMd: spacing(4, 4, withoutPadding ? 0 : 4),
            };
          },
        }}
        overflow="auto"
      >
        {getTabPanelContent(currentTab)}
      </Box>
      {currentTab === RemindersTabIndex.REMINDERS && (
        <Box
          padding={
            reminderCount > 0
              ? ({ spacing }) => ({
                  xxs: spacing(2, 1.5),
                  xs: spacing(2),
                  sm: spacing(2, 3, 3),
                  newMd: spacing(2, 4, 4),
                })
              : 0
          }
          display="flex"
          justifyContent="end"
        >
          {reminderCount > 0 && <CreateReminderButton />}
        </Box>
      )}
      {currentTab === RemindersTabIndex.RECIPIENTS_SETS && (
        <Box
          padding={
            sets.length > 0
              ? ({ spacing }) => ({
                  xxs: spacing(2, 1.5),
                  xs: spacing(2),
                  sm: spacing(2, 3, 3),
                  newMd: spacing(2, 4, 4),
                })
              : 0
          }
          mr={1}
          display="flex"
          justifyContent="end"
        >
          {sets.length > 0 && <CreateRecipientSetButton isReminder />}
        </Box>
      )}
    </PageContent>
  );
};

export default Reminders;
