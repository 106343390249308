import { useConfirmation } from 'hooks/use-confirmation';
import { useRecipientSets } from 'hooks/use-recipient-sets';
import { useRef, useState } from 'react';
import { useAppSelector } from 'store';
import {
  useGetRecipientManagerQuery,
  useLazyGetAlerterSchedulerInfoQuery,
} from 'store/api/alerters.api';
import { getLocalInterval } from 'utils/timezones';

export const useRecipientsSetModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const functionRef = useRef<{ submitForm: () => void; isError: boolean }>(
    null,
  );

  const { sets } = useRecipientSets();
  const wasModified = useAppSelector(
    (state) => state.alerter.isModifiedRecipientSet,
  );
  const { isLoading } = useGetRecipientManagerQuery(
    sets[selectedIndex ?? 0]?.id,
    {
      skip: selectedIndex === null,
    },
  );

  const [
    getAlerterScheduler,
    { isLoading: isScheduleLoading, data: scheduleData },
  ] = useLazyGetAlerterSchedulerInfoQuery();

  const confirm = useConfirmation();

  const handleClick = (index: number) => {
    setIsOpen(true);
    if (index) {
      getAlerterScheduler(sets[index].schedulerId);
    }
    setSelectedIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedIndex(null);
  };

  const onSubmit = () => {
    functionRef.current?.submitForm();
  };

  const confirmSubmit = () => {
    confirm(
      {
        title: 'You have unsaved changes',
        message: 'Do you want to save or discard them?',
        rejectButtonLabel: 'Discard',
        confirmButtonLabel: 'Save',
      },
      onSubmit,
      onClose,
    );
  };

  const confirmDiscard = () => {
    confirm(
      {
        title: 'You have unsaved changes',
        message: 'Do you want to discard your changes or go back to editing??',
        rejectButtonLabel: 'Go back',
        confirmButtonLabel: 'Discard',
        variant: 'danger',
      },
      onClose,
    );
  };

  const handleClose = () => {
    if (wasModified) {
      if (functionRef.current?.isError) {
        confirmDiscard();
        return;
      }
      confirmSubmit();
      return;
    }

    onClose();
  };

  return {
    isOpen,
    handleClick,
    handleClose,
    onClose,
    selectedIndex,
    functionRef,
    isRecipientManagerLoading: isLoading || isScheduleLoading,
    schedule: scheduleData
      ? getLocalInterval(
          scheduleData.result.serialized_schedule.start_time,
          scheduleData.result.serialized_schedule.end_time,
          scheduleData.result.serialized_schedule.days_of_week,
        )
      : {},
  };
};
