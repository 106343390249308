import { Layout } from 'layouts/Private';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { useAdminStatusQuery } from 'store/api/auth.api';
import {
  getLastPathname,
  removeLastPathname,
  setLastPathname,
} from 'utils/storage';
import { VLoader } from './VLoader';
import { useMemo } from 'react';
import { useGetDashboardDataQuery } from 'store/api/dashboard.api';

const PrivateRoute = ({
  redirectPath,
  outlet,
  withoutLayout = false,
  adminOnly,
}: PrivateRouteProps) => {
  const { pathname: currentPathname, search: searchQuery } = useLocation();
  const { pathname: savedPathname, search: savedSearch } = getLastPathname();
  const { profileId } = useParams();

  const { isAuthenticated, accountId } = useAppSelector((state) => state.user);
  const stateProfileId = useAppSelector((state) => state.dashboard.profileId);

  const {
    data: isAdmin = false,
    isLoading,
    isFetching,
  } = useAdminStatusQuery(
    { userId: accountId as number },
    {
      skip: !isAuthenticated || !accountId || !adminOnly,
    },
  );

  useGetDashboardDataQuery(
    { account_id: Number(profileId) },
    {
      skip: !profileId || stateProfileId !== 0,
    },
  );

  const adminCheck = useMemo(
    () => (adminOnly ? isAdmin : true),
    [adminOnly, isAdmin],
  );

  if (isLoading || isFetching) {
    return <VLoader />;
  }

  if (isAuthenticated && adminCheck) {
    if (savedPathname) {
      removeLastPathname();
      return <Navigate to={{ pathname: savedPathname, search: savedSearch }} />;
    }
    if (withoutLayout) {
      return outlet;
    }
    return <Layout>{outlet}</Layout>;
  }

  !adminOnly && setLastPathname(currentPathname, searchQuery);
  return <Navigate to={{ pathname: redirectPath }} />;
};

export default PrivateRoute;

export type PrivateRouteProps = {
  redirectPath: string;
  outlet: JSX.Element;
  withoutLayout?: boolean;
  adminOnly?: boolean;
};
