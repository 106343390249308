import {
  alpha,
  autocompleteClasses,
  backdropClasses,
  buttonClasses,
  checkboxClasses,
  chipClasses,
  formControlLabelClasses,
  inputBaseClasses,
  listItemClasses,
  menuItemClasses,
  modalClasses,
  outlinedInputClasses,
  radioClasses,
  selectClasses,
  sliderClasses,
  switchClasses,
  tabsClasses,
  tooltipClasses,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { palette } from './palette';

declare module '@mui/material/styles' {
  interface Palette {
    grapeFruit: Palette['primary'];
    orange: Palette['primary'];
    blue: Palette['primary'] & { darkHover: string };
    skin: Palette['primary'];
    red: Palette['primary'];
    palePink: Palette['primary'];
    black: Palette['primary'];
    gray: Palette['primary'];
    white: Palette['primary'];
    paleWhite: Palette['primary'];
    paleGray: Palette['primary'];
    backgroundWhite: Palette['primary'];
    backgroundLinear: Palette['primary'];
    borderGray: Palette['primary'];
    lastGray: Palette['primary'];
    primaryBackground: Palette['primary'];
    lightBlue: Palette['primary'];
    divider: string;
    hover: Palette['primary'] & { darkBlue: string };
    disabled: Palette['primary'] & { blue: string };
  }

  interface PaletteOptions {
    grapeFruit?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    skin?: PaletteOptions['primary'];
    palePink?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
    gray?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    paleWhite?: PaletteOptions['primary'];
    paleGray?: PaletteOptions['primary'];
    backgroundWhite?: PaletteOptions['primary'];
    backgroundLinear?: PaletteOptions['primary'];
    borderGray?: PaletteOptions['primary'];
    lastGray?: PaletteOptions['primary'];
    divider?: string;
    lightBlue?: PaletteOptions['primary'];
    hover?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grapeFruit: true;
    red: true;
    skin: true;
    palePink: true;
    black: true;
    gray: true;
    white: true;
    paleWhite: true;
    paleGray: true;
    backgroundWhite: true;
    backgroundLinear: true;
    borderGray: true;
    lastGray: true;
  }

  interface ButtonPropsVariantOverrides {
    'icon-text': true;
    'icon-contained': true;
    'icon-outlined': true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    '2xl': true;
    '3xl': true;
    '4xl': true;
    '5xl': true;
    newMd: true;
    newLg: true;
    newXl: true;
    new2Xl: true;
    new3Xl: true;
    new4Xl: true;
  }
}

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xxs: 0,
      xs: 320,
      sm: 480,
      md: 600,
      lg: 768,
      xl: 900,
      '2xl': 1024,
      '3xl': 1280,
      '4xl': 1600,
      '5xl': 1920,
      newMd: 768,
      newLg: 834,
      newXl: 1024,
      new2Xl: 1280,
      new3Xl: 1600,
      new4Xl: 1920,
    },
  },
  typography: {
    fontFamily: '"Euclid Circular B", "euclid"',
    h1: {
      fontFamily: '"Euclid Circular B", "euclid"',
      fontWeight: 700,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: { disableRipple: true },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: palette.disabled.main,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '4px 24px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableRipple: false,
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.Mui-disabled': {
            backgroundColor: palette['bc-light-blue'],
            opacity: 0.4,
            color: palette['bc-dark-blue'],
            [`&.${buttonClasses.text}`]: {
              backgroundColor: 'transparent',
              color: palette.disabled.blue,
              opacity: 1,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: palette.secondary.main,
            ':hover': {
              backgroundColor: palette.secondary.hover,
            },
            ':active': {
              backgroundColor: palette.secondary.main,
            },
            '& .MuiCircularProgress-svg': {
              color: palette.paleWhite.main,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            boxSizing: 'border-box',
            ':disabled': {
              borderColor: palette.disabled.main,
              background: 'none',
              opacity: 1,
              color: palette.disabled.blue,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: palette.primary.main,
            ':hover': {
              backgroundColor: palette.hover.darkBlue,
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            ':hover': {
              backgroundColor: palette.hover.darkBlue,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'red' },
          style: {
            color: palette.common.white,
          },
        },
        {
          props: { variant: 'text' },
          style: {
            fontWeight: 500,
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: '16px',
            lineHeight: '24px',
            padding: '12px 24px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            fontSize: '16px',
            lineHeight: '24px',
            padding: '8px 16px',
            maxHeight: '40px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: '14px',
            lineHeight: '24px',
            padding: '4px 10px',
            minWidth: 'auto',
            maxHeight: '32px',
          },
        },
        {
          props: { variant: 'icon-text' },
          style: {
            minWidth: '20px',
            padding: '10px',
            svg: {
              width: '20px',
              height: '20px',
            },
            ':hover': {
              backgroundColor: palette.hover.darkBlue,
            },
          },
        },
        {
          props: { variant: 'icon-text', size: 'small' },
          style: {
            padding: '6px',
          },
        },
        {
          props: { fullWidth: true },
          style: {
            width: '100%',
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h3' },
          style: {
            fontWeight: '700',
            fontSize: 32,
            lineHeight: '40px',
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontWeight: '600',
            fontSize: 24,
            color: palette.primary.main,
            lineHeight: '30px',
            paddingBottom: '8px',
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            fontWeight: '600',
            fontSize: 20,
            color: palette.primary.main,
            lineHeight: '30px',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: 16,
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontSize: 14,
            lineHeight: '20px',
            color: palette.primary.main,
          },
        },
        {
          props: { variant: 'caption' },
          style: {
            fontSize: 12,
            lineHeight: '20px',
            letterSpacing: '0.4px',
          },
        },
        {
          props: { variant: 'subtitle2' },
          style: {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 600,
            color: palette.primary.main,
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'transparent',
          },
          [`&.${checkboxClasses.disabled}`]: {
            color: palette.blue.light,
            svg: {
              fill: palette.blue.light + '!important',
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            [`:not(.${checkboxClasses.checked}):not(.${checkboxClasses.indeterminate})`]:
              {
                '& svg': {
                  fill: palette.borderGray.main,
                },
              },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: 8,
            '& svg': {
              width: 24,
              height: 24,
            },
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
        [`&.${radioClasses.disabled}`]: {
          color: palette.blue.light,
          svg: {
            fill: palette.blue.light + '!important',
          },
        },
      },
      variants: [
        {
          props: { square: false },
          style: {
            borderRadius: 8,
          },
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            [`:not(.${radioClasses.checked})`]: {
              '& svg': {
                fill: palette.borderGray.main,
              },
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '& svg': {
              fill: palette.secondary.main,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: 8,
            '& svg': {
              width: 24,
              height: 24,
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: alpha(palette.primary.main, 0.6),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [`&.${menuItemClasses.selected}.${autocompleteClasses.option}`]: {
            backgroundColor: palette.white.main,
          },
          [`&.${listItemClasses.selected}`]: {
            [`&.${autocompleteClasses.option}:hover`]: {
              backgroundColor: palette.action.hover + '!important',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`:hover`]: {
            outlineColor: palette.primary.main,
          },
          [`&.${outlinedInputClasses.focused}`]: {
            outlineColor: palette.primary.main,
          },
          [`&.${outlinedInputClasses.sizeSmall}`]: {
            fontSize: 16,
            lineHeight: 1.5,
            height: '40px',
            paddingLeft: 12,
            paddingRight: 12,
            [`&.${outlinedInputClasses.adornedEnd}`]: {
              paddingRight: 6,
            },
            [`.${inputBaseClasses.input}`]: {
              padding: 0,
            },
            [`&.${inputBaseClasses.multiline}`]: {
              height: 'auto',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`.${inputBaseClasses.sizeSmall}`]: {
            padding: 0,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: { size: 'medium', fullWidth: true, color: 'primary' },
      styleOverrides: {
        root: {
          '&:before': {
            borderColor: `${palette.blue.light} !important`,
          },
          [`&.${inputBaseClasses.focused}:before`]: {
            borderColor: `${palette.primary.main} !important`,
          },
          [`.${autocompleteClasses.endAdornment}`]: {
            [`.${autocompleteClasses.popupIndicator}`]: {
              color: palette.primary.main,
            },
          },
          [`&.${inputBaseClasses.disabled}`]: {
            '* > svg': {
              color: palette.disabled.main,
            },
          },
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.borderGray.main,
            [`.${outlinedInputClasses.focused}`]: {
              borderWidth: '1px',
            },
            ':hover': {
              borderColor: palette.borderGray.main,
            },
          },
          [`.${inputBaseClasses.input}`]: {
            '&::placeholder': {
              color: palette.disabled.main,
              opacity: 1,
            },
          },
          [`&.${inputBaseClasses.readOnly}`]: {
            cursor: 'default',
          },
          [`&.${inputBaseClasses.readOnly}.${inputBaseClasses.focused}`]: {
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: `${palette.borderGray.main} !important`,
            },
          },
        },
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            borderRadius: '4px',
            padding: '12px 16px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            borderRadius: '4px',
            padding: '8px 16px',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: {
            variant: 'standard',
            size: 'medium',
          },
          style: {
            [`.${inputBaseClasses.root}`]: {
              padding: 0,
              fontSize: '20px',
              paddingBottom: '6px',
              '&::after': {
                borderBottom: `1px solid ${palette.borderGray.main}`,
              },
            },
          },
        },
      ],
    },
    MuiTab: {
      variants: [
        {
          props: {},
          style: {
            color: palette.common.white,
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            padding: '16px 24px',
            '&.Mui-selected': {
              color: palette.common.white,
              fontWeight: 600,
            },
            '&.Mui-disabled': {
              color: palette.gray.light,
              opacity: 0.7,
            },
          },
        },
      ],
    },
    MuiTabs: {
      variants: [
        {
          props: {},
          style: {
            [`.${tabsClasses.indicator}`]: {
              backgroundColor: palette.secondary.main,
              height: '4px',
            },
          },
        },
      ],
    },
    MuiSlider: {
      variants: [
        {
          props: {},
          style: {
            [`.${sliderClasses.valueLabel}`]: {
              backgroundColor: palette.common.black,
              fontSize: 11,
              fontWeight: 500,
              borderRadius: 4,
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
            },
            [`.${sliderClasses.rail}`]: {
              height: '8px',
              color: palette.borderGray.main,
              opacity: 1,
            },
            [`.${sliderClasses.track}`]: {
              height: '8px',
              color: palette.secondary.main,
            },
            [`.${sliderClasses.thumb}`]: {
              color: palette.secondary.main,
              transition: 'color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

              '&:hover': {
                color: palette.secondary.hover,
              },
              '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'none',
              },
            },
            [`&.${sliderClasses.disabled}`]: {
              [`.${sliderClasses.rail}`]: {
                color: palette.blue.light,
              },
              [`.${sliderClasses.track}`]: {
                color: palette.blue.light,
              },
              [`.${sliderClasses.thumb}`]: {
                color: palette.blue.light,
              },
            },
          },
        },
      ],
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.common.black,
          fontSize: '12px',
          [`&.${tooltipClasses.tooltipPlacementTop}`]: {
            marginBottom: '8px !important',
          },
        },
        arrow: {
          color: palette.common.black,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(palette.primary.main, 0.14),
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          [`.${modalClasses.backdrop}:not(.${backdropClasses.invisible})`]: {
            backgroundColor: alpha(palette.common.black, 0.1),
            backdropFilter: 'blur(2px)',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(palette.common.black, 0.1),
          backdropFilter: 'blur(2px)',
          [`&.${backdropClasses.invisible}`]: {
            backdropFilter: 'unset',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          [`&.${inputBaseClasses.readOnly}`]: {
            cursor: 'default',
          },
        },
      },
      variants: [
        {
          props: {},
          style: {
            // color: palette.primary.main,
            border: 'none',
            padding: '8px 12px',
            fontSize: 16,
            lineHeight: '16px',
            height: 32,

            [`.${selectClasses.outlined}`]: {
              padding: 0,
            },

            [`.${selectClasses.select}`]: {
              display: 'flex',
              alignItems: 'center',
            },
            [`.${selectClasses.icon}`]: {
              color: palette.primary.main,
            },
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.borderGray.main,

              '&:hover': {
                borderColor: palette.borderGray.main,
              },
            },
            '&.Mui-readOnly': {
              color: alpha(palette.blue.dark, 0.6),
            },
            '&.Mui-focused': {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: palette.primary.main,
                borderWidth: '1px',
              },
            },
            '&.Mui-disabled': {
              color: palette.disabled.blue,
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: palette.borderGray.main,
              },
              [`.${selectClasses.icon}`]: {
                color: palette.disabled.blue,
              },
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: 40,
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            [`.${chipClasses.deleteIcon}`]: {
              color: palette.primary.main,
              opacity: 0.26,
              ':hover': {
                color: palette.primary.main,
                opacity: 0.4,
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: palette.white.main,
            [`.${chipClasses.deleteIcon}`]: {
              color: palette.white.main,
              opacity: 0.7,
              ':hover': {
                opacity: 0.9,
              },
            },
          },
        },
      ],
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
        disableRipple: true,
      },
      variants: [
        {
          props: {},
          style: {
            [`.${switchClasses.track}`]: {
              backgroundColor: palette.primary.main,
            },
            [`.${switchClasses.disabled}:not(.${switchClasses.checked}) + .${switchClasses.track}`]:
              {
                backgroundColor: palette.disabled.main,
                opacity: 1,
              },
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          [`&.${formControlLabelClasses.disabled}`]: {
            [`.${checkboxClasses.root}`]: {
              svg: {
                fill: palette.text.disabled,
              },
            },
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: alpha(palette.blue.dark, 0.6),
          fontWeight: 400,
          lineHeight: 1.5,
          padding: '6px 16px',
        },
      },
    },
  },
});

export default theme;
