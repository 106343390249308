import { useState } from 'react';
import { useAppSelector } from 'store';
import { getPatientDataCsv } from 'utils/get-patient-data-csv';
import { useRecordsColumns } from './use-records-columns';

export const useCsvDownload = () => {
  const { dateRange, pagination } = useAppSelector((state) => state.filters);
  const { deviceId, profileId } = useAppSelector((state) => state.dashboard);
  const { token } = useAppSelector((state) => state.user);

  const { requestFields } = useRecordsColumns();
  const [isLoading, setIsLoading] = useState(false);
  const [beginsAt, endsAt] = dateRange;

  const handleDownload = async () => {
    setIsLoading(true);
    const data = await getPatientDataCsv({
      patient_profile_id: profileId,
      device_id: deviceId,
      begins_at: beginsAt,
      ends_at: endsAt,
      display_columns: requestFields,
      total: pagination.total,
      token,
    });
    setIsLoading(false);

    downloadFile(data);
  };

  const downloadFile = (data?: Blob) => {
    if (data) {
      const hiddenElement = document.createElement('a');
      const url = window.URL || window.webkitURL;
      const blobCsv = url.createObjectURL(data);
      hiddenElement.href = blobCsv;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${profileId}_${beginsAt.slice(
        0,
        10,
      )}_${endsAt.slice(0, 10)}.csv`;
      hiddenElement.click();
      hiddenElement.remove();
    }
  };

  return {
    handleDownload,
    disabled: isLoading || pagination.total === 0,
  };
};
