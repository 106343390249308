import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const calendar: IconifyJSON = {
  prefix: 'calendar',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M14.7 18C14.05 18 13.5 17.775 13.05 17.325C12.6 16.875 12.375 16.3333 12.375 15.7C12.375 15.05 12.6 14.5 13.05 14.05C13.5 13.6 14.05 13.375 14.7 13.375C15.3333 13.375 15.875 13.6 16.325 14.05C16.775 14.5 17 15.05 17 15.7C17 16.3333 16.775 16.875 16.325 17.325C15.875 17.775 15.3333 18 14.7 18ZM5.3 21.5C4.8 21.5 4.375 21.325 4.025 20.975C3.675 20.625 3.5 20.2 3.5 19.7V6.3C3.5 5.8 3.675 5.375 4.025 5.025C4.375 4.675 4.8 4.5 5.3 4.5H6.7V2.375H8.225V4.5H15.8V2.375H17.3V4.5H18.7C19.2 4.5 19.625 4.675 19.975 5.025C20.325 5.375 20.5 5.8 20.5 6.3V19.7C20.5 20.2 20.325 20.625 19.975 20.975C19.625 21.325 19.2 21.5 18.7 21.5H5.3ZM5.3 20H18.7C18.7667 20 18.8333 19.9667 18.9 19.9C18.9667 19.8333 19 19.7667 19 19.7V10.3H5V19.7C5 19.7667 5.03333 19.8333 5.1 19.9C5.16667 19.9667 5.23333 20 5.3 20Z" fill="currentColor"/>',
    },
  },
};
