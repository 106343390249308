import { Icon, IconifyIconProps } from '@iconify/react';
import { Box, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

const Root = styled('div')(({ theme: { spacing, palette, breakpoints } }) => ({
  border: `1px solid ${palette.gray.light}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: spacing(1),
  padding: spacing(4),
  [breakpoints.down('newMd')]: {
    padding: spacing(3),
  },
  [breakpoints.down('sm')]: {
    padding: spacing(2),
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

interface Props {
  title: string;
  message?: ReactNode;
  children?: ReactNode;
  icon?: IconifyIconProps['icon'];
}

const EmptyList = ({ icon, title, message, children }: Props) => {
  return (
    <Root>
      {icon && <StyledIcon icon={icon} />}
      <Typography
        textAlign="center"
        variant="h6"
        sx={{ my: 1, fontWeight: 500 }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        textAlign="center"
        sx={{ maxWidth: ({ spacing }) => spacing(75) }}
      >
        {message}
      </Typography>
      {children && <Box mt={3}>{children}</Box>}
    </Root>
  );
};

export default EmptyList;
