import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const moreVert: IconifyJSON = {
  prefix: 'more-vert',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M12 19.2692C11.5875 19.2692 11.2344 19.1223 10.9407 18.8286C10.6469 18.5348 10.5 18.1817 10.5 17.7692C10.5 17.3567 10.6469 17.0036 10.9407 16.7099C11.2344 16.4161 11.5875 16.2692 12 16.2692C12.4125 16.2692 12.7656 16.4161 13.0594 16.7099C13.3531 17.0036 13.5 17.3567 13.5 17.7692C13.5 18.1817 13.3531 18.5348 13.0594 18.8286C12.7656 19.1223 12.4125 19.2692 12 19.2692ZM12 13.5C11.5875 13.5 11.2344 13.3531 10.9407 13.0593C10.6469 12.7656 10.5 12.4125 10.5 12C10.5 11.5875 10.6469 11.2344 10.9407 10.9406C11.2344 10.6469 11.5875 10.5 12 10.5C12.4125 10.5 12.7656 10.6469 13.0594 10.9406C13.3531 11.2344 13.5 11.5875 13.5 12C13.5 12.4125 13.3531 12.7656 13.0594 13.0593C12.7656 13.3531 12.4125 13.5 12 13.5ZM12 7.73075C11.5875 7.73075 11.2344 7.58387 10.9407 7.29012C10.6469 6.99639 10.5 6.64327 10.5 6.23077C10.5 5.81829 10.6469 5.46517 10.9407 5.17142C11.2344 4.87769 11.5875 4.73082 12 4.73082C12.4125 4.73082 12.7656 4.87769 13.0594 5.17142C13.3531 5.46517 13.5 5.81829 13.5 6.23077C13.5 6.64327 13.3531 6.99639 13.0594 7.29012C12.7656 7.58387 12.4125 7.73075 12 7.73075Z" fill="currentColor"/>',
    },
  },
};
