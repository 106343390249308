import styled from '@emotion/styled';

import theme from 'styles/theme';

import pxToEm from 'utils/px-to-em';

type VCheckboxProps = {
  isChecked?: boolean;
};

export const VLabel = styled.label`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  color: ${theme.palette.gray.main};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${pxToEm(10)};
`;

export const VInput = styled.input`
  &[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: ${pxToEm(1)};
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: ${pxToEm(1)};
  }
`;

export const VSvg = styled.svg<VCheckboxProps>`
  height: ${pxToEm(20)};
  width: ${pxToEm(20)};
  border-radius: ${pxToEm(4)};
  border: ${(props) =>
    props.isChecked
      ? `2px solid ${theme.palette.grapeFruit.main}`
      : '2px solid'};
  background: ${(props) =>
    props.isChecked ? theme.palette.grapeFruit.main : theme.palette.white.main};
`;
