import { Icon } from '@iconify/react';
import { Button, alpha, buttonClasses, styled } from '@mui/material';

export const StyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
}));

export const IconOnlyButton = styled(Button)(({ theme, size }) => ({
  maxWidth: theme.spacing(size === 'medium' ? 5 : 4),
  maxHeight: theme.spacing(size === 'medium' ? 5 : 4),
  minWidth: theme.spacing(size === 'medium' ? 5 : 4),
  minHeight: theme.spacing(size === 'medium' ? 5 : 4),
  padding: theme.spacing(0.5),
  [`&.${buttonClasses.outlinedPrimary}:not(.${buttonClasses.disabled})`]: {
    borderColor: alpha(theme.palette.primary.main, 0.5),
  },
}));
