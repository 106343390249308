import { Button } from '@mui/material';
import { useAppSelector } from 'store';

const SaveButton = () => {
  const isDisabled = !useAppSelector(
    (state) => state.reminder.areSettingsModified,
  );

  return (
    <Button
      disabled={isDisabled}
      variant="contained"
      color="primary"
      type="submit"
    >
      Save
    </Button>
  );
};

export default SaveButton;
