import { Box, Skeleton, Typography } from '@mui/material';
import MuteToggle from './MuteToggle';
import Channels from './Channels';
import { useNotificationsSettings } from 'pages/CaregiverSettings/hooks/use-notifications-settings';

interface Props {
  isMute?: boolean;
  channelSetId?: number;
}

const Notifications = ({ isMute, channelSetId }: Props) => {
  const {
    channels,
    isLoading,
    handleChannelsChange,
    handleMuteSwitchChange,
    handleChannelResetChange,
    handleMuteResetChange,
  } = useNotificationsSettings(channelSetId);

  return (
    <div>
      <Typography variant="body1" fontWeight={600} color="primary">
        Notifications
      </Typography>
      <MuteToggle
        setOnReset={handleMuteResetChange}
        onChange={handleMuteSwitchChange}
        toggleValue={!!isMute}
      />
      {isLoading ? (
        <Box display="flex" flexDirection="column">
          <Skeleton variant="text" height={36} width={120} />
          <Skeleton variant="text" height={36} width={400} />
          <Skeleton
            variant="text"
            height={36}
            width={160}
            sx={{ alignSelf: 'end ' }}
          />
        </Box>
      ) : (
        <Channels
          setOnReset={handleChannelResetChange}
          onChange={handleChannelsChange}
          defaultChannels={channels}
        />
      )}
    </div>
  );
};

export default Notifications;
