import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import AddSafeZoneButton from './AddSafeZoneModal';
import { shield } from 'assets/iconify';
import { useState } from 'react';
import { EmptyList } from 'components/_extra/EmptyList';
import { SafeZoneParams } from 'types/safe-zones';

const SafeZonesEmptyList = ({
  onSave,
}: {
  onSave?: (zone: SafeZoneParams) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <Box p={{ xxs: 0, newLg: 1 }}>
      <EmptyList
        icon={shield.icons.normal}
        title="Start adding safe zones"
        message="This feature will help to see if your loved one is in a safe place. In a few easy clicks you can add different zones for a home, a park or neighborhood, or a route to the grocery store."
      >
        <Button
          sx={{ m: 1 }}
          onClick={handleClick}
          startIcon={<Icon icon="material-symbols:add-box-outline" />}
        >
          Add Zone
        </Button>
        <AddSafeZoneButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSave={onSave}
        />
      </EmptyList>
    </Box>
  );
};

export default SafeZonesEmptyList;
