import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const arrowDropdown: IconifyJSON = {
  prefix: 'arrow-dropdown',
  icons: {
    normal: {
      ...defaultSizes,
      body: `
      <mask id="mask0_9024_28847" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_9024_28847)">
        <path d="M12 14.6538L7.59619 10.25H16.4038L12 14.6538Z" fill="currentColor"/>
      </g>
      `,
    },
  },
};
