import { useAppDispatch } from 'store';
import { resetState as resetAlerterState } from 'store/reducers/alerter/alerter.slice';
import { resetState as resetDashboardState } from 'store/reducers/dashboard/dashboard.slice';
import { resetState as resetFiltersState } from 'store/reducers/filters/filters.slice';
import { resetState as resetMfaState } from 'store/reducers/mfa/mfa.slice';
import { resetState as resetReminderState } from 'store/reducers/reminder/reminder.slice';
import { logout as reduxLogout } from 'store/reducers/user/user.slice';
export const useLogout = () => {
  const dispatch = useAppDispatch();

  const logout = () => {
    document.BCFcm?.deregisterDevice();

    dispatch(reduxLogout());
    dispatch(resetAlerterState());
    dispatch(resetDashboardState());
    dispatch(resetFiltersState());
    dispatch(resetMfaState());
    dispatch(resetReminderState());
  };

  return { logout };
};
