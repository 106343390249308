export interface CaregiverDraft {
  id: number | string;
  firstName: string;
  lastName?: string;
  email: string;
}

export interface WizardForm {
  delay: boolean;
  trackingNumber: string;
}

export const convertFormToServerPayload = (
  data: WizardForm,
  caregivers: CaregiverDraft[],
  patientProfileId: number,
  trackingService: 'fedex' | 'ups',
): Record<string, unknown> => {
  return {
    delay: data.delay,
    patient_profile_id: patientProfileId,
    email_recipients: caregivers.map((item) => ({
      first_name: item.firstName,
      last_name: item.lastName,
      email: item.email,
    })),
    ...(trackingService === 'fedex' && {
      ups_tracking_number: null,
      fedex_tracking_number: data.trackingNumber,
    }),
    ...(trackingService === 'ups' && {
      ups_tracking_number: data.trackingNumber,
      fedex_tracking_number: null,
    }),
  };
};
