import { SxProps, useTheme } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { VButton } from 'components/VButton';
import { ILookupSearchResult } from 'models/admin.model';

const commonColumnProperties = {
  editable: false,
  filterable: false,
  sortable: false,
  hideable: false,
  disableColumnMenu: true,
};

const gridColDefFactory = <T extends object>(
  items: GridColDef<T>[],
): GridColDef<T>[] =>
  items.map((item) => ({
    ...commonColumnProperties,
    ...item,
  }));

interface Props {
  rows: ILookupSearchResult[];
  onRowBtnClick: (id: number) => void;
  isLoading?: boolean;
  sx?: SxProps;
}

const ResultsTable = ({ rows, onRowBtnClick, isLoading, sx }: Props) => {
  const theme = useTheme();

  const columns: GridColDef<ILookupSearchResult>[] = gridColDefFactory([
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone number',
      width: 150,
    },
    {
      field: 'isLovedOne',
      headerName: 'Is Loved One',
      width: 150,
      renderCell: ({ row }) => (row.isLovedOne ? 'Yes' : 'No'),
    },
    {
      field: 'Details',
      renderCell: ({ row }) => (
        <VButton
          width={200}
          style={{ height: 36 }}
          bgcolor={theme.palette.secondary.main}
          text="View"
          type="button"
          onClick={() => onRowBtnClick(row.id)}
        />
      ),
    },
  ]);

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      autoHeight
      hideFooter
      loading={isLoading}
      sx={sx}
    />
  );
};

export default ResultsTable;
