import {
  Box,
  Button,
  ListSubheader,
  MenuItem,
  MenuItemProps,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
  Skeleton,
  Typography,
} from '@mui/material';
import { HelperText } from 'pages/AlerterDetails/components/Settings/styled';
import { useRecipientManagerOptions } from 'hooks/use-recipient-manager-options';
import { useTemplateForm } from 'pages/Alerters/hooks/use-template-form';
import { ReactNode, useRef } from 'react';

export interface CreateAlerterFormProps {
  onClose: () => void;
}

interface StyledSelectProps extends SelectProps {
  label?: string;
  isLoading?: boolean;
  options?: (Omit<MenuItemProps, 'children'> & {
    label: string;
    value: string | number;
    children?: ReactNode;
  })[];
}

export const StyledSelect = ({
  options,
  isLoading,
  label,
  children,
  ...rest
}: StyledSelectProps) => {
  return (
    <div>
      <Typography pb={label ? 1 : 0} color="primary">
        {label}
      </Typography>
      {isLoading ? (
        <Skeleton height={40} variant="rounded" />
      ) : (
        <Select sx={{ width: '100%' }} size="medium" {...rest}>
          {options
            ? options.map(({ label, value }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))
            : children}
        </Select>
      )}
    </div>
  );
};

const TemplateForm = ({ onClose }: CreateAlerterFormProps) => {
  const {
    recipientSetOptions,
    isLoading: isRecipientsLoading,
    presets,
    nextTitle: nextManagerTitle,
  } = useRecipientManagerOptions();

  const {
    defaultTemplate,
    templateOptions,
    defaultSource,
    sourceOptions,
    isLoading,
    register,
    handleSubmit,
    handleCancel,
    setValue,
    titleError,
    setAsDirty,
  } = useTemplateForm(onClose, nextManagerTitle);

  const titleInputRef = useRef<HTMLInputElement>(null);

  const handleTemplateChange = (event: SelectChangeEvent<unknown>) => {
    if (titleInputRef.current) {
      const newValue =
        templateOptions.find(
          ({ value }) => value === (event.target.value as string),
        )?.label ?? 'Untitled';

      titleInputRef.current.value = newValue;
      setValue('title', newValue);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      <StyledSelect
        label="Condition Template"
        defaultValue={defaultTemplate}
        options={[
          {
            value: 'none',
            label: 'None',
          },
          ...templateOptions,
        ]}
        inputProps={{ ...register('template', { onChange: setAsDirty }) }}
        isLoading={isLoading}
        MenuProps={{
          sx: {
            maxHeight: ({ spacing }) => spacing(60),
          },
        }}
        onChange={handleTemplateChange}
      />
      <StyledSelect
        label="Recipient Set"
        isLoading={isLoading || isRecipientsLoading}
        defaultValue={recipientSetOptions[0]?.value ?? presets[0].value}
        inputProps={{ ...register('recipientSet', { onChange: setAsDirty }) }}
      >
        <ListSubheader>New from preset</ListSubheader>
        {presets.map(({ label, value }) => (
          <MenuItem sx={{ px: 3 }} key={value} value={value}>
            {label}
          </MenuItem>
        ))}

        {recipientSetOptions.length > 0 && (
          <ListSubheader>Existing Sets</ListSubheader>
        )}
        {recipientSetOptions.map(({ label, value }) => (
          <MenuItem sx={{ px: 3 }} key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect
        label="Data Source"
        readOnly
        defaultValue={defaultSource}
        options={sourceOptions}
        inputProps={{ ...register('source', { onChange: setAsDirty }) }}
        isLoading={isLoading}
      />

      <div>
        <Typography pb={1} color="primary">
          Title
        </Typography>
        {isLoading ? (
          <Skeleton height={40} variant="rounded" />
        ) : (
          <OutlinedInput
            fullWidth
            size="small"
            defaultValue={
              templateOptions.find(({ value }) => value === defaultTemplate)
                ?.label ?? 'Untitled'
            }
            placeholder="Add title"
            inputRef={titleInputRef}
            error={!!titleError}
            inputProps={{ ...register('title', { onChange: setAsDirty }) }}
          />
        )}
        <HelperText error>{titleError}</HelperText>
      </div>
      <Box display="flex" justifyContent="end" gap={2} pt={1}>
        <Button color="primary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" size="medium" variant="contained" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default TemplateForm;
