import { FormHelperText, styled, useTheme } from '@mui/material';
import VInputPassword from 'components/VInputPassword/VInputPassword';
import { PasswordStrength } from 'models/auth.model';
import { ChangeEvent, useState } from 'react';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import { RegisterFormData } from 'hooks/use-registration-form';
import { checkPasswordStrength } from 'utils/password-check';

interface IProps {
  errors: FieldErrorsImpl<RegisterFormData>;
  onPasswordStrengthChange?: (strength: PasswordStrength) => void;
}

const NoWrapSpan = styled('span')`
  white-space: nowrap;
`;

const PasswordRegistration = ({
  errors,
  onPasswordStrengthChange = () => {
    /* empty function */
  },
}: IProps) => {
  const { register } = useFormContext();
  const { palette } = useTheme();
  const [strength, setStrength] = useState<PasswordStrength>('too short');

  const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStrength((oldStrength) => {
      const newStrength = checkPasswordStrength(e.target.value);
      if (oldStrength !== newStrength) {
        onPasswordStrengthChange(newStrength);
      }
      return newStrength;
    });
  };

  return (
    <>
      <VInputPassword
        inputProps={{ ...register('password') }}
        label="Password"
        size="large"
        onChange={handlePassChange}
        name="password"
        placeholder="Create your password"
        passwordStrength={strength}
        error={!!errors.password}
      />
      {['too short', 'weak'].includes(strength) && (
        <FormHelperText sx={{ px: 2, pb: 1 }}>
          Password must have have minimum 8 characters, contain at least{' '}
          <NoWrapSpan> 1 uppercase,</NoWrapSpan>{' '}
          <NoWrapSpan>1 lowercase,</NoWrapSpan>{' '}
          <NoWrapSpan>1 number.</NoWrapSpan>
        </FormHelperText>
      )}
      <VInputPassword
        name="confirm"
        label="Confirm password"
        size="large"
        inputProps={{ ...register('confirmedPassword') }}
        error={!!errors.confirmedPassword}
        helperText={errors.confirmedPassword?.message as string}
        formHelperTextProps={{
          sx: {
            color:
              errors.confirmedPassword?.type === 'match'
                ? palette.primary.main
                : palette.grapeFruit.main,
          },
        }}
      />
    </>
  );
};

export default PasswordRegistration;
