import {
  FormattedRegions,
  Overlay,
  RawSafeZone,
  SafeZone,
} from 'types/safe-zones';
import { splitGeofence } from './split-geofence';
import { RegionsType } from 'types/safe-zones';

export const getZones = (data: FormattedRegions | undefined) => {
  if (typeof data === 'undefined') return [];
  return [...data.circleRegions, ...data.pathRegions, ...data.polygonRegions];
};

export const transformSafeZone = ({
  shape_type,
  account_id,
  updated_at,
  created_at,
  geofence,
  preview_snapshot_url,
  ...zone
}: RawSafeZone) => {
  return {
    ...zone,
    shapeType: shape_type,
    accountId: account_id,
    updatedAt: updated_at,
    createdAt: created_at,
    snapshotUrl: preview_snapshot_url,
    geofence: geofence ? splitGeofence(geofence) : [[zone.lat, zone.lng]],
  };
};

export const getCircleOverlay = (zone: SafeZone) => {
  const { lat, lng } = zone;
  return {
    points: [`${lat},${lng}`],
    radius: zone.radius,
    lineWidth: 8,
    strokeColor: '429580',
  };
};

export const getPathOverlay = (zone: SafeZone) => {
  const points = zone.geofence.reduce(
    (acc, [lat, lng]) => [...acc, `${lat},${lng}`],
    [] as string[],
  );

  return {
    points,
    lineWidth: zone.radius / 3,
    strokeColor: '429580',
    lineOpacity: 0.5,
  } as Overlay;
};

export const getPolygonOverlay = (zone: SafeZone) => {
  const points = zone.geofence.reduce(
    (acc, [lat, lng]) => [...acc, `${lat},${lng}`],
    [] as string[],
  );

  return {
    points: [...points, points[0]],
    lineWidth: 2,
    strokeColor: '429580',
  } as Overlay;
};

export const getOverlayForSnapshot = (zone: SafeZone) => {
  switch (zone.shapeType) {
    case RegionsType.CIRCLE_TYPE:
      return [getCircleOverlay(zone)];
    case RegionsType.POLYGON_TYPE:
      return [getPolygonOverlay(zone)];
    case RegionsType.PATH_TYPE:
      return [getPathOverlay(zone)];
    default:
      return undefined;
  }
};
