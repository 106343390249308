import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const check: IconifyJSON = {
  prefix: 'check',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M9.55002 17.6538L4.21552 12.3193L5.28452 11.25L9.55002 15.5155L18.7155 6.35001L19.7845 7.41926L9.55002 17.6538Z" fill="currentColor"/>',
    },
  },
};
